import React, { Component } from 'react';
import { BiCommentAdd, BiRefresh } from "react-icons/bi"
import { Button, Select, Tooltip, Menu } from "antd";
import { TbPin, TbPinnedOff } from 'react-icons/tb';
import i18n from '../../../../../Utils/i18next';
import Search from "../../../../GeneralComponents/Search/Search";
import { Divider } from "antd";

const { Option } = Select;

class DashboardCommentsHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchCategory: {},
    };
  }

  /**
   * Set search category.
   */
  searchCategory = (item) => {
    this.setState({
      searchCategory: { ...item },
    });
  };

  /**
   * On change search input.
   */
  handleSearch = (e) => {
    this.props.commentFilter(e.target.value, this.state.searchCategory.name);
  };

  /**
   * Toggle function for search plugin.
   */
  toggleSearch = () => {
    this.props.toggleSearch();
    this.setState({
      searchCategory: {},
    });
  };

  render() {
    let pincheck = this.props.isPinned;

    return (
      <div className="dashboard-comment-header-area">
        <div className="comment-text-area">
          <BiCommentAdd className="add-comment-button-mobile" />
          <p className="comment-text">
            <BiCommentAdd className="add-comment-button" />
            {i18n.t("DashboardComments.Comments")}
          </p>
          <Select className='db-comment-sort' defaultValue="updatedDesc" style={{ width: 165, marginLeft: 0, fontSize: 12 }} onChange={this.props.handleChangeSortDir}>
            <Option value="updatedDesc" style={{ fontSize: 12 }}>{i18n.t("DashboardComments.LUDESC")}</Option>
            <Option value="updatedAsc" style={{ fontSize: 12 }}>{i18n.t("DashboardComments.LUASC")}</Option>
            <Option value="desc" style={{ fontSize: 12 }}>{i18n.t("DashboardComments.DESC")}</Option>
            <Option value="asc" style={{ fontSize: 12 }}>{i18n.t("DashboardComments.ASC")}</Option>
          </Select>
          <div className="dashboard-comment-buttons">
            <Tooltip
              title={i18n.t("DashboardComments.Close")}
              placement={"bottom"}
              overlayClassName={"pin-tooltip dashboard-comment-tooltip"}>
              <Button
                id={"dashboard-comment-add-comment-button"}
                style={{
                  borderRadius: "40px",
                  padding: "6px 11px",
                  backgroundColor: "white",
                  zIndex: 1
                }}
                type={"default"}
                onClick={() => {
                  this.props.closeCommentPopup(false);
                }}
              >
                X
              </Button>
            </Tooltip>
            <Tooltip
              title={i18n.t("DashboardComments.Refresh")}
              placement={"bottom"}
              overlayClassName={"pin-tooltip dashboard-comment-tooltip"}>
              <Button id={"dashboard-comment-add-comment-button"}
                style={{
                  borderRadius: "40px",
                  padding: "6px 11px",
                  backgroundColor: "white",
                  zIndex: 1,
                  padding: 0,
                  width: "32px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "",
                  fontSize: "23px"
                }}
                type={"default"}
                onClick={() => {
                  this.props.getDashboardComments(undefined, this.props)
                }}
              >
                <BiRefresh />
              </Button>
            </Tooltip>
            <Tooltip
              title={i18n.t(pincheck ? "Model.UnPin" : "Model.Pin")}
              placement={"bottom"}
              overlayClassName={"pin-tooltip dashboard-comment-tooltip"}>
              <Button
                id="pinTop"
                className="pin-icon-comment"
                style={
                  pincheck
                    ? {
                      border: "1px solid #1890ff",
                      color: "#1890ff",
                      position: "unset!important"
                    }
                    : {
                      border: "1px solid rgb(217,217,217)",
                      color: "rgba(0,0,0,0.65)",
                      position: "unset!important"
                    }
                }
                type={"default"}
                onClick={this.props.changePinStatus}
              >
                {this.props.isPinned ? <TbPin size={22} /> : <TbPinnedOff size={22} />}
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="comment-filter">
          <Divider
            className="search-area-divider"
            orientation="left"
          ></Divider>
          <Search
            paddingNone={true}
            inputStyle={{ minWidth: "75px" }}
            id={"searchData"}
            width="100%"
            searchBarVisible={true}
            onChange={this.handleSearch}
            searchItemList={[
              {
                name: "Users",
                translate: "DashboardComments.Users",
              },
              {
                name: "Context",
                translate: "DashboardComments.Context",
              },
            ]}
            searchCategory={this.searchCategory}
            selectedSearchCategory={this.state.searchCategory}
          />
        </div>
      </div>
    );
  }
}

export default DashboardCommentsHeader;