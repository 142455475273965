import i18n from "../../../Utils/i18next";
import * as agePyramid from "./conversionMap/agePyramid";
import * as chordDiagram from "./conversionMap/chordDiagram";
import * as custom from "./conversionMap/custom";
import * as cylinderBar from "./conversionMap/cylinderBar";
import * as gaugeChart from "./conversionMap/gaugeChart";
import * as heatmap from "./conversionMap/heatmap";
import * as liquidGauge from "./conversionMap/liquidGauge";
import * as mapBubbles from "./conversionMap/mapBubbles";
import * as mapChoropleth from "./conversionMap/mapChoropleth";
import * as mapCluster from "./conversionMap/mapCluster";
import * as measureTile from "./conversionMap/measureTile";
import * as multiAxisLineChart from "./conversionMap/multiAxisLineChart";
import * as networkGraph from "./conversionMap/networkGraph";
import * as pieChart from "./conversionMap/pieChart";
import * as pivotTable from "./conversionMap/pivotTable";
import * as radar from "./conversionMap/radarChart";
import * as radialProgress from "./conversionMap/radialProgress";
import * as routeMap from "./conversionMap/routeMap";
import * as sankeyChart from "./conversionMap/sankeyChart";
import * as scatterChart from "./conversionMap/scatterChart";
import * as sunburst from "./conversionMap/sunburst";
import * as table from "./conversionMap/table";
import * as timelineChart from "./conversionMap/timelineChart";
import * as treeMap from "./conversionMap/treeMap";
import * as turkeyMap from "./conversionMap/turkeyMap";
import * as wordCloud from "./conversionMap/wordCloud";
import * as worldMap from "./conversionMap/worldMap";

/**
 * Configurations that plugins must have
 */
export const configurations = {
    "measure-tile": measureTile.configuration,
    "liquid-gauge":  liquidGauge.configuration,
    "word-cloud": wordCloud.configuration,
    "gauge-chart": gaugeChart.configurations,
    "network-graph": networkGraph.configuration,
    "timeline-chart": timelineChart.configuration,
    "age-pyramid": agePyramid.configuration,
    "pivot-table": pivotTable.configuration,
    "sunburst": sunburst.configuration,
    "multi-axis-line-chart": multiAxisLineChart.configuration,
    "radar": radar.configuration,
    "chord-diagram": chordDiagram.configuration,
    "sankey-chart": sankeyChart.configuration,
    "tree-map": treeMap.treeMapConfiguration,
    "radial-progress": radialProgress.configuration,
    "scatter-chart": scatterChart.configuration,
    "table": table.configuration,
    "map-bubbles": mapBubbles.configuration,
    "map-choropleth": mapChoropleth.configuration,
    "map-cluster": mapCluster.configuration,
    "heatmap": heatmap.configuration,
    "route-map": routeMap.configuration,
    "turkey-map": turkeyMap.configuration,
    "world-map": worldMap.configuration
};

/**
 * Plugin actions
 */
export const actions = {
    "measure-tile": measureTile.actions,
    "liquid-gauge":  liquidGauge.actions,
    "word-cloud": wordCloud.actions,
    "gauge-chart": gaugeChart.actions,
    "network-graph": networkGraph.actions,
    "timeline-chart": timelineChart.actions,
    "age-pyramid": agePyramid.actions,
    "pie-chart": pieChart.actions,
    "pie-chart-enhanced": pieChart.actions,
    "pivot-table": pivotTable.actions,
    "sunburst": sunburst.actions,
    "multi-axis-line-chart": multiAxisLineChart.actions,
    "radar": radar.radarActions,
    "chord-diagram": chordDiagram.actions,
    "sankey-chart": sankeyChart.actions,
    "tree-map": treeMap.actions,
    "cylinder-bar": [],
    "radial-progress": radialProgress.actions,
    "scatter-chart": scatterChart.actions,
    "table": table.actions,
    "map-bubbles": mapBubbles.actions,
    "map-choropleth": mapChoropleth.actions,
    "map-cluster": mapCluster.actions,
    "heatmap": heatmap.actions,
    "route-map": routeMap.actions,
    "turkey-map": turkeyMap.actions,
    "world-map": worldMap.actions,
}

/**
 * Plugin reactions
 */
export const reactions = {
    "custom": custom.reactions,
    "measure-tile": measureTile.reactions,
    "liquid-gauge":  liquidGauge.reactions,
    "word-cloud": wordCloud.reactions,
    "gauge-chart": gaugeChart.reactions,
    "network-graph": networkGraph.reactions,
    "timeline-chart": timelineChart.reactions,
    "age-pyramid": agePyramid.reactions,
    "pie-chart": pieChart.reactions,
    "pie-chart-enhanced": pieChart.reactions,
    "pivot-table": pivotTable.reactions,
    "sunburst": sunburst.reactions,
    "multi-axis-line-chart": multiAxisLineChart.reactions,
    "radar": radar.reactions,
    "chord-diagram": chordDiagram.reactions,
    "sankey-chart": sankeyChart.reactions,
    "tree-map": treeMap.reactions,
    "cylinder-bar": cylinderBar.reactions,
    "radial-progress": radialProgress.reactions,
    "scatter-chart": scatterChart.reactions,
    "table": table.tableReactions,
    "map-bubbles": mapBubbles.reactions,
    "map-choropleth": mapChoropleth.reactions,
    "map-cluster": mapCluster.reactions,
    "heatmap": heatmap.reactions,
    "route-map": routeMap.reactions,
    "turkey-map": turkeyMap.reactions,
    "world-map": worldMap.reactions,
}

export const titleReactions = [
    {
        id: "none",
        name: i18n.t("Dashboard.Configuration.Fields.None"),
        description: "desc232",
        type: "private",
        method: "none"
      },
      {
        id: "updateTitle",
        name: i18n.t("Interaction.UpdateTitle"),
        description: "desc232",
        type: "private",
        method: "updateTitle"
      },
      {
        id: "resetTitle",
        name: i18n.t("Interaction.ResetTitle"),
        description: "desc233",
        type: "private",
        method: "resetTitle"
      }
];

/**
 * Plugin conversion map
 */
export const plugins = {
    "measure-tile": measureTile.conversionMap,
    "liquid-gauge":  liquidGauge.conversionMap,
    "word-cloud": wordCloud.conversionMap,
    "gauge-chart": gaugeChart.conversionMap,
    "network-graph": networkGraph.conversionMap,
    "timeline-chart": timelineChart.conversionMap,
    "age-pyramid": agePyramid.conversionMap,
    "pie-chart": pieChart.conversionMap,
    "pie-chart-enhanced": pieChart.conversionMap,
    "pivot-table": pivotTable.conversionMap,
    "sunburst": sunburst.conversionMap,
    "multi-axis-line-chart": multiAxisLineChart.conversionMap,
    "radar": radar.conversionMap,
    "chord-diagram": chordDiagram.conversionMap,
    "sankey-chart": sankeyChart.conversionMap,
    "tree-map": treeMap.conversionMap,
    "cylinder-bar": cylinderBar.conversionMap,
    "radial-progress": radialProgress.conversionMap,
    "scatter-chart": scatterChart.conversionMap,
    "map-bubbles": mapBubbles.conversionMap,
    "map-choropleth": mapChoropleth.conversionMap,
    "map-cluster": mapCluster.conversionMap,
    "heatmap": heatmap.conversionMap,
    "route-map": routeMap.conversionMap,
    "turkey-map": turkeyMap.conversionMap,
    "world-map": worldMap.conversionMap,
    "custom": custom.conversionMap
};