import { store } from "../../..";
import { BASE_URL_UI } from "../../../config";
import { setPluginsDrillDowns } from "../../DrillDown/DrillDownAction";

/* 
* Changes the URL if the report is changed with the switcher and the report is get successfully.
*/
export function pushWindowHistoryIfComesFromSwitchDashboard(
  comeFromSwitchDashboard,
  dashboardId
) {
  if (comeFromSwitchDashboard) {
    let newUrl =
      BASE_URL_UI +
      "/dashboard/" +
      dashboardId +
      "?labelId=" +
      comeFromSwitchDashboard;

    window.history.pushState({}, "", newUrl);
  }
}

/*
* Clears all interactions from plugin
*/
export function clearAllInteractionsOnPlugins() {
  let reduxState = store.getState();
  let pluginInteractionFilters = reduxState.PluginTriggerReducer.pluginInteractionFilters;
  let sourcePluginsWithValues = reduxState.PluginTriggerReducer.sourcePluginsWithValues;
  let pluginPrivateInteractionFilters = reduxState.PluginTriggerReducer.pluginPrivateInteractionFilters;

  pluginInteractionFilters.clear();
  sourcePluginsWithValues.clear();
  pluginPrivateInteractionFilters.clear();
}

/*
* Clears all drilldowns from plugin
*/
export function clearAllDrillDownsOnPlugins () {
  let reduxState = store.getState();
  let drillDowns = reduxState.DrillDownReducer.drillDowns;

  reduxState.DrillDownReducer.triggeredDrillDowns.clear();
  reduxState.DrillDownReducer.pluginColumnClickedAndShouldBeRemoved.clear();
  reduxState.DrillDownReducer.drillDowns.clear()

  store.dispatch(setPluginsDrillDowns(drillDowns));
};