import { Button, Checkbox, Col, Divider, Row, Select } from "antd";
import React, { Component } from "react";
import { Droppable } from "react-drag-and-drop";
import i18n from "../../Utils/i18next";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import Text from "../GeneralComponents/Text/Text";
import { Tooltip } from "antd";
import DragDropForDrilldown from "../GeneralComponents/DragDropForDrilldown";
import { verticalDragDropItem } from "../GeneralComponents/VerticalDragDropItem";
import {
  setPluginsDrillDowns,
  setTriggeredDrillDowns,
} from "./DrillDownAction";
import { store } from "../../index";
import $ from "jquery"
import { showError } from "../../Utils/Notification";

const { Option } = Select;
export default class AddDrillDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      drillDownColumnsForParentColumns: this.getDrillDownColumn(),
      drillDownPopupVisible: false,
      pluginKey: "",
      drillDownType: "normal",
      preserveDefaultFilters: true
    };

    this.positionConfiguration = {
      positionCalibration: "20px",
      width: "250px",
    };
  }

  /*
   * Sets default drilldown type
   */
  componentDidMount() {
    let newState = { ...this.state }
    let reduxState = store.getState();
    let pluginId = this.props.componentTHIS.state.pluginId;
    let plugin = reduxState.PluginTriggerReducer.plugins.get(pluginId);
    let pluginKey = plugin.key;
    let drilldowns = reduxState.DrillDownReducer.drillDowns.get(pluginId);
    let currentPreserveDefaultFilters =
      drilldowns && drilldowns.preserveDefaultFilters && drilldowns.preserveDefaultFilters instanceof Map && drilldowns.preserveDefaultFilters.has(this.props.parentColumn.uniqeColumnId)
        ? drilldowns.preserveDefaultFilters.get(this.props.parentColumn.uniqeColumnId)
        : true;

    newState.pluginKey = pluginKey;
    newState.preserveDefaultFilters = currentPreserveDefaultFilters;

    newState.drillDownType = "normal";

    if (plugin.key === "pie-chart-enhanced" || plugin.key == "pie-chart") {
      let pluginCategoryId = this.props.componentTHIS.state.columnMap.category.data[0].uniqeColumnId;
      let currentDrilldownType =
        drilldowns && drilldowns.drillDownColumnsForParentColumns.size > 0 && drilldowns.drillDownColumnsForParentColumns.has(pluginCategoryId)
          ? drilldowns.drillDownTypes
          : "normal";

      newState.drillDownType = currentDrilldownType;
    }

    this.setState(newState);

    document.addEventListener("mouseup", this.checkIsDrillDownColumns);
  }

  componentWillReceiveProps(nextProps) {
    let isCloseAllDrillDown = nextProps.componentTHIS.state.isCloseAllDrillDownPopup === true;
    let isExceptDrillDownPopupIndexDiff = nextProps.componentTHIS.state.exceptDrillDownPopupIndex !== this.props.columnIndex;
    let isComesFromThreePoint = nextProps.componentTHIS.state.comesFrom === "three-point";
    let closeDrillDownPopup = isCloseAllDrillDown && (isExceptDrillDownPopupIndexDiff || isComesFromThreePoint);

    if (closeDrillDownPopup) {
      this.setState({
        drillDownPopupVisible: false,
      });
    }
  }

  componentDidUpdate() {
    let isDrilldownColumnsUndefined = this.state.drillDownColumnsForParentColumns == undefined ? true : false
    let isDrilldownListChanged = this.state.drillDownColumnsForParentColumns.has(this.props.parentColumn.uniqeColumnId) ? true : false
    let pluginId = this.props.componentTHIS.state.pluginId;
    let reduxState = store.getState()
    let pluginKey = reduxState.PluginTriggerReducer.plugins.get(pluginId).key
    let isPluginPieChartEnhanced = pluginKey === "pie-chart-enhanced" || pluginKey === "pie-chart" ? true : false
    let allDrillDownsMap = reduxState.DrillDownReducer.drillDowns;

    if (isPluginPieChartEnhanced && !isDrilldownColumnsUndefined && isDrilldownListChanged) {
      let categoryId = this.props.parentColumn.uniqeColumnId

      if (this.state.drillDownColumnsForParentColumns.has(categoryId)) {
        let isCurrentDrillObjectAvailable = allDrillDownsMap.has(pluginId) ? true : false
        let isDrilldownColumnTypeAvailable = isCurrentDrillObjectAvailable ? allDrillDownsMap.get(pluginId) : []
        let isDrilldownColumnTypeEqualState = isDrilldownColumnTypeAvailable.drillDownTypes === this.state.drillDownType ? true : false

        if (isCurrentDrillObjectAvailable && !isDrilldownColumnTypeEqualState) {
          let currentDrillObject = allDrillDownsMap.get(pluginId);
          let settedDrillDown = allDrillDownsMap.set(pluginId, {
            ...currentDrillObject,
            drillDownTypes: this.state.drillDownType,
          });

          if (this.state.drillDownType !== "normal") {
            $("#" + pluginId).find(".left-tools").css("display", "none")
          }

          store.dispatch(setPluginsDrillDowns(settedDrillDown));
        }
      }
    }
  }
  
  componentWillUnmount() {
    document.removeEventListener("mouseup", this.checkIsDrillDownColumns);
  }
  
  //Closes the popup when click out
  checkIsDrillDownColumns = (e) => {
    if (e.target.tagName !== 'BUTTON') {
      this.setState({
        drillDownPopupVisible: false
      })
    }
  }

  /**
   *
   * @returns
   * Get  drill down columns with parent columns
   */
  getDrillDownColumn = () => {
    let reduxState = store.getState();
    let drillDowns = reduxState.DrillDownReducer.drillDowns.get(
      this.props.componentTHIS.state.pluginId
    );

    if (drillDowns && drillDowns.drillDownColumnsForParentColumns) {
      return drillDowns.drillDownColumnsForParentColumns;
    } else {
      return new Map();
    }
  };

  onDrop = (dataColumns, mapObject, key, aggr = false) => {
    let proccessingData = JSON.parse(dataColumns[key]);
    let column = proccessingData.column;
    let pluginId = this.props.plugin?.id;

    let reduxState = store.getState();
    let drillDowns = reduxState.DrillDownReducer.drillDowns;
    let allDrillDownColumnsInPlugin = drillDowns?.get(pluginId)?.allDrillDownColumnsInPlugin?.values() || [];
    let sortedColumnList = this.props.plugin?.sortedColumnList;
    let sortedColumnIds = sortedColumnList.map(c => c.uniqeColumnId);

    allDrillDownColumnsInPlugin = [...allDrillDownColumnsInPlugin].flat();
    allDrillDownColumnsInPlugin = allDrillDownColumnsInPlugin.filter(c => c.drillDownParentColumnId && sortedColumnIds.includes(c.drillDownParentColumnId));


    let allColumns =  [...sortedColumnList, ...allDrillDownColumnsInPlugin];
    let columnDisplayNames = allColumns?.map(c => c.displayName);

    if (columnDisplayNames?.includes(column.displayName)) {
      let number = 1;

      while (columnDisplayNames.includes(`${column.displayName} (${number})`)) {
        number++;
      }

      column.displayName = `${column.displayName} (${number})`;
    }

    for (let col of allColumns) {
      if (col.displayName === column.displayName) {

      }
    }


    column.tableAliasName = proccessingData.tableAliasName;
    column.tableDisplayName = proccessingData.tableDisplayName;
    column.uniqeColumnId = proccessingData.uniqeColumnId;
    column.locationFieldName = mapObject;
    column.drillDownParentColumnId = this.props.parentColumn.uniqeColumnId;

    this.setDrillDowns(column);
  };

  validationControlAndConvertDataDrillDown = (
    drillDownsMap,
    convertedDrillDownMap
  ) => {
    if (
      drillDownsMap.get(this.props.componentTHIS.state.pluginId) &&
      typeof drillDownsMap.get(this.props.componentTHIS.state.pluginId)
        .allDrillDownColumnsInPlugin === "string"
    ) {
      convertedDrillDownMap.set(
        this.props.componentTHIS.state.pluginId,
        this.convertStringToMap(
          drillDownsMap.get(this.props.componentTHIS.state.pluginId)
        )
      );
    } else if (drillDownsMap.get(this.props.componentTHIS.state.pluginId)) {
      convertedDrillDownMap = drillDownsMap;
    }
  };

  setDrillDowns = (column) => {
    let pluginId = this.props.componentTHIS.state.pluginId;
    let reduxState = store.getState();
    let drillDowns = reduxState.DrillDownReducer.drillDowns;
    let drillDownColumnsForParentColumns = drillDowns.get(pluginId)
      ? drillDowns.get(pluginId).drillDownColumnsForParentColumns
      : new Map();

    let hasPluginDrillDown = drillDowns.get(
      this.props.componentTHIS.state.pluginId
    );

    let allDrillDownColumnsInPlugin = hasPluginDrillDown
      ? drillDowns.get(pluginId).allDrillDownColumnsInPlugin
      : new Map();

    let drillDownLayerMap = hasPluginDrillDown
      ? drillDowns.get(pluginId).drillDownLayerMap
      : new Map();

    let preserveDefaultFilters = hasPluginDrillDown
      ? drillDowns.get(pluginId).preserveDefaultFilters
      : new Map();

    if (column.duplicated === true || column.name !== column.displayName) {
      column.multiple = true;
    }

    if (!hasPluginDrillDown) {
      if (this.props.parentColumn.name === column.name) {
        column.multiple = true;
      }

      drillDownLayerMap.set(
        this.props.parentColumn.aliasName +
        "#" +
        this.props.parentColumn.uniqeColumnId,
        {
          nextDrillDownColumnId: column.uniqeColumnId,
          hasDrillDownOperation: false,
          nextDrillDownColumnAliasName: column.aliasName,
          parentColumnId: this.props.parentColumn.uniqeColumnId,
        }
      );

      allDrillDownColumnsInPlugin.set(column.uniqeColumnId, column);
      let columnList = [];
      columnList.push(column);
      drillDownColumnsForParentColumns.set(
        this.props.parentColumn.uniqeColumnId,
        columnList
      );
    } else {
      let selectedColumnInDrillDownColumns =
        drillDownColumnsForParentColumns.get(
          this.props.parentColumn.uniqeColumnId
        );

      let lastAddedColumn;
      if (
        selectedColumnInDrillDownColumns &&
        selectedColumnInDrillDownColumns.length > 0
      ) {
        lastAddedColumn =
          selectedColumnInDrillDownColumns[
          selectedColumnInDrillDownColumns.length - 1
          ];
      } else {
        selectedColumnInDrillDownColumns = [];
        lastAddedColumn = this.props.parentColumn;
      }

      // If more than one is discarded from the same column, it should be marked as multiple.
      if (this.props.parentColumn.name === column.name) {
        column.multiple = true;
      } else if (selectedColumnInDrillDownColumns.length > 0) {
        selectedColumnInDrillDownColumns.map((item) => {
          if (item.name === column.name) {
            column.multiple = true;
            item.multiple = true;
          }
        });
      }

      allDrillDownColumnsInPlugin.set(column.uniqeColumnId, column);

      drillDownLayerMap.set(
        lastAddedColumn.aliasName + "#" + lastAddedColumn.uniqeColumnId,
        {
          nextDrillDownColumnId: column.uniqeColumnId,
          hasDrillDownOperation: false,
          nextDrillDownColumnAliasName: column.aliasName,
          parentColumnId: this.props.parentColumn.uniqeColumnId,
        }
      );

      selectedColumnInDrillDownColumns.push(column);

      drillDownColumnsForParentColumns.set(
        this.props.parentColumn.uniqeColumnId,
        selectedColumnInDrillDownColumns
      );
    }

    if (
      !allDrillDownColumnsInPlugin.get(this.props.parentColumn.uniqeColumnId)
    ) {
      allDrillDownColumnsInPlugin.set(
        this.props.parentColumn.uniqeColumnId,
        this.props.parentColumn
      );
    }

    let pluginDrillDowns = {
      drillDownLayerMap: drillDownLayerMap,
      allDrillDownColumnsInPlugin: allDrillDownColumnsInPlugin,
      drillDownColumnsForParentColumns: drillDownColumnsForParentColumns,
      preserveDefaultFilters: preserveDefaultFilters
    };

    drillDowns.set(this.props.componentTHIS.state.pluginId, pluginDrillDowns);

    // set to Reducer
    store.dispatch(setPluginsDrillDowns(drillDowns));

    // Save to Plugin
    this.props.componentTHIS.updateDrillDowns(
      drillDowns.get(this.props.componentTHIS.state.pluginId)
    );

    this.setState({
      drillDownColumnsForParentColumns: drillDownColumnsForParentColumns
    });
  };

  updateDrillDowns = (data, type) => {
    let reduxState = store.getState();
    let drillDownsReducer = reduxState.DrillDownReducer.drillDowns;
    let drillDowns = drillDownsReducer.get(
      this.props.componentTHIS.state.pluginId
    );

    let drillDownColumnsForParentColumns =
      drillDowns.drillDownColumnsForParentColumns;

    if (
      drillDownColumnsForParentColumns.get(
        this.props.parentColumn.uniqeColumnId
      )
    ) {
      drillDownColumnsForParentColumns.set(
        this.props.parentColumn.uniqeColumnId,
        data
      );

      let newDrillDownLayerMap = this.createMapToDrillDownLayer(
        drillDownColumnsForParentColumns,
        drillDowns.drillDownLayerMap
      );

      drillDowns.drillDownLayerMap = newDrillDownLayerMap;
      drillDowns.drillDownColumnsForParentColumns =
        drillDownColumnsForParentColumns;

      drillDownsReducer.set(
        this.props.componentTHIS.state.pluginId,
        drillDowns
      );

      store.dispatch(setPluginsDrillDowns(drillDownsReducer));

      this.props.componentTHIS.updateDrillDowns(drillDowns);

      this.setState({
        drillDownColumnsForParentColumns: drillDownColumnsForParentColumns,
      });
    }
  };

  /**
   *
   * @param {*} drillDownColumnsForParentColumns
   * @param {*} drillDownLayerMap
   * @returns
   *
   * Arranges the map layer according to deleted or updated columns
   */
  createMapToDrillDownLayer = (
    drillDownColumnsForParentColumns,
    drillDownLayerMap
  ) => {
    let reduxState = store.getState();
    let drillDowns = reduxState.DrillDownReducer.drillDowns.get(
      this.props.componentTHIS.state.pluginId
    );
    let parentColumnDrillDownColumns = drillDownColumnsForParentColumns.get(
      this.props.parentColumn.uniqeColumnId
    );

    if (parentColumnDrillDownColumns) {
      for (
        let index = 0;
        index <= parentColumnDrillDownColumns.length - 1;
        index++
      ) {
        let key;

        if (index === 0) {
          key =
            this.props.parentColumn.aliasName +
            "#" +
            this.props.parentColumn.uniqeColumnId;
        } else {
          key =
            parentColumnDrillDownColumns[index - 1].aliasName +
            "#" +
            parentColumnDrillDownColumns[index - 1].uniqeColumnId;
        }

        if (index === parentColumnDrillDownColumns.length - 1) {
          let lastKey =
            parentColumnDrillDownColumns[index].aliasName +
            "#" +
            parentColumnDrillDownColumns[index].uniqeColumnId;

          if (drillDownLayerMap.get(lastKey)) {
            drillDownLayerMap.delete(lastKey);
          }
        }

        if (drillDownLayerMap.get(key)) {
          if (
            parentColumnDrillDownColumns[index].uniqeColumnId !==
            drillDownLayerMap.get(key).nextDrillDownColumnId
          ) {
            drillDownLayerMap.delete(key);

            let drillDownLayerItem = {};

            drillDownLayerItem.nextDrillDownColumnAliasName =
              parentColumnDrillDownColumns[index].aliasName;
            drillDownLayerItem.nextDrillDownColumnId =
              parentColumnDrillDownColumns[index].uniqeColumnId;
            drillDownLayerItem.hasDrillDownOperation = false;
            drillDownLayerItem.parentColumnId =
              this.props.parentColumn.uniqeColumnId;

            drillDownLayerMap.set(key, drillDownLayerItem);
          }
        } else {
          let drillDownLayerItem = {};

          drillDownLayerItem.nextDrillDownColumnAliasName =
            parentColumnDrillDownColumns[index].aliasName;
          drillDownLayerItem.nextDrillDownColumnId =
            parentColumnDrillDownColumns[index].uniqeColumnId;
          drillDownLayerItem.hasDrillDownOperation = false;
          drillDownLayerItem.parentColumnId =
            this.props.parentColumn.uniqeColumnId;

          drillDownLayerMap.set(key, drillDownLayerItem);
        }
      }
    }

    drillDowns.drillDownLayerMap = drillDownLayerMap;

    return drillDowns.drillDownLayerMap;
  };

  toogleDrillDown = () => {
    /** Open column config and set fields. */
    if (this.state.drillDownPopupVisible === false) {
      this.setState({
        drillDownPopupVisible: true,
        columnIndex: this.props.columnIndex,
      }, () => {
        this.props.closeOtherPopups(true, this.props.columnIndex, "drilldown")
      });

      this.props.componentTHIS.setDroppableVisibleChange(
        this.props.parentColumn.locationFieldName,
        false
      );
    }

    // TODO:acıldığında locationFieldName e göre ilgili location droppable ı disable yapacak olan props u çağır
    // this.props.isCloseOthers(true, this.props.columnIndex);
  };

  /** Close column config. */
  closeDrillDownPopup = () => {
    this.setState({
      drillDownPopupVisible: false,
    });

    this.props.componentTHIS.setDroppableVisibleChange(
      this.props.parentColumn.locationFieldName,
      true
    );
  };

  /**
   *
   * @param {*} columnProperties
   * @param {*} type
   * @param {*} column
   *
   * Updates the column information and accordingly updates the necessary data
   */
  changeColumnProperties = (columnProperties, type, column) => {
    let reduxState = store.getState();
    let drillDownsReducer = reduxState.DrillDownReducer.drillDowns;
    let drillDowns = drillDownsReducer.get(
      this.props.componentTHIS.state.pluginId
    );
    let editColumn = drillDowns.allDrillDownColumnsInPlugin.get(
      column.uniqeColumnId
    );
    let drillDownColumnsForParentColumns =
      drillDowns.drillDownColumnsForParentColumns;

    if (editColumn) {
      if (column.displayName !== columnProperties.displayName) {
        editColumn.multiple = true;
      }

      editColumn.displayName = columnProperties.displayName;
      editColumn.DataFormat = columnProperties.DataFormat;
      editColumn.DataFormatType = columnProperties.DataFormatType;
      editColumn.DecimalPlaces = columnProperties.DecimalPlaces;
      editColumn.Use1000Separator = columnProperties.Use1000Separator;
      editColumn.DataFormatTypeSubTitle = columnProperties.DataFormatTypeSubTitle;
      editColumn.FormatExample = columnProperties.FormatExample;
      editColumn.RoundingNumber = columnProperties.RoundingNumber;
      editColumn.affixValue = columnProperties.affixValue;
      editColumn.affixAlign = columnProperties.affixAlign;
      editColumn.Code = columnProperties.Code;
      editColumn.locale = columnProperties.locale;
      editColumn.Locale = columnProperties.locale;
      editColumn.value = columnProperties.Code;
      editColumn.code = columnProperties.code;
      editColumn.locale = columnProperties.locale;
      editColumn.align = columnProperties.align;
      editColumn.dataType = columnProperties.dataType
      editColumn.originalDataType = columnProperties.originalDataType

      drillDowns.allDrillDownColumnsInPlugin.set(
        column.uniqeColumnId,
        editColumn
      );
    }

    if (drillDownColumnsForParentColumns.get(column.drillDownParentColumnId)) {
      let parentColumns = drillDownColumnsForParentColumns.get(
        column.drillDownParentColumnId
      );

      parentColumns.map((item) => {
        if (item.uniqeColumnId === column.uniqeColumnId) {
          if (column.displayName !== columnProperties.displayName) {
            editColumn.multiple = true;
          }

          item.displayName = columnProperties.displayName;
          item.DataFormat = columnProperties.DataFormat;
          item.DataFormatType = columnProperties.DataFormatType;
          item.DecimalPlaces = columnProperties.DecimalPlaces;
          item.Use1000Separator = columnProperties.Use1000Separator;
          item.DataFormatTypeSubTitle = columnProperties.DataFormatTypeSubTitle;
          item.FormatExample = columnProperties.FormatExample;
          item.RoundingNumber = columnProperties.RoundingNumber;
          item.Code = columnProperties.Code;
          item.affixValue = columnProperties.affixValue;
          item.affixAlign = columnProperties.affixAlign;
          item.locale = columnProperties.locale;
          item.Locale = columnProperties.locale;
          item.value = columnProperties.Code;
          item.code = columnProperties.code;
          item.locale = columnProperties.locale;
          item.align = columnProperties.align;
        }
      });

      drillDownColumnsForParentColumns.set(
        column.drillDownParentColumnId,
        parentColumns
      );
    }

    drillDownsReducer.set(this.props.componentTHIS.state.pluginId, drillDowns);

    store.dispatch(setPluginsDrillDowns(drillDownsReducer));

    this.setState({
      drillDownColumnsForParentColumns:
        drillDowns.drillDownColumnsForParentColumns,
    });

    this.props.componentTHIS.updateDrillDowns(drillDowns);
  };

  closeOtherThreePointPopup = (status, index) => {
    this.props.componentTHIS.closeOtherThreePointPopup(status, index);
  };

  removeColumnOnMapping = (column, type, conditionalFormats) => {
    let reduxState = store.getState();
    let drillDownsReducer = reduxState.DrillDownReducer.drillDowns;
    let drillDowns = drillDownsReducer.get(
      this.props.componentTHIS.state.pluginId
    );

    if (
      drillDowns &&
      drillDowns.drillDownColumnsForParentColumns.get(
        this.props.parentColumn.uniqeColumnId
      )
    ) {
      let selectedColumnDrillDownColumns =
        drillDowns.drillDownColumnsForParentColumns.get(
          column.drillDownParentColumnId
        );

      let newDrillDownColumn = selectedColumnDrillDownColumns.filter(
        (item) => item.uniqeColumnId !== column.uniqeColumnId
      );

      if (newDrillDownColumn.length === 0) {
        drillDowns.drillDownColumnsForParentColumns.delete(
          column.drillDownParentColumnId
        );

        drillDowns.drillDownLayerMap.delete(
          this.props.parentColumn.aliasName +
          "#" +
          this.props.parentColumn.uniqeColumnId
        );

        drillDowns.drillDownLayerMap.delete(
          column.aliasName + "#" + column.uniqeColumnId
        );

        drillDowns.allDrillDownColumnsInPlugin.delete(column.uniqeColumnId);
        drillDowns.allDrillDownColumnsInPlugin.delete(
          this.props.parentColumn.uniqeColumnId
        );

        drillDowns.locationFieldName = undefined;
      } else {
        drillDowns.drillDownColumnsForParentColumns.set(
          column.drillDownParentColumnId,
          newDrillDownColumn
        );

        drillDowns.drillDownLayerMap.delete(
          column.aliasName + "#" + column.uniqeColumnId
        );

        let newDrillDownLayerMap = this.createMapToDrillDownLayer(
          drillDowns.drillDownColumnsForParentColumns,
          drillDowns.drillDownLayerMap
        );

        drillDowns.drillDownLayerMap = newDrillDownLayerMap;
        drillDowns.allDrillDownColumnsInPlugin.delete(column.uniqeColumnId);
      }

      drillDownsReducer.set(
        this.props.componentTHIS.state.pluginId,
        drillDowns
      );

      store.dispatch(setPluginsDrillDowns(drillDownsReducer));

      this.setState({
        drillDownColumnsForParentColumns:
          drillDowns.drillDownColumnsForParentColumns,
      });

      this.props.componentTHIS.updateDrillDowns(drillDowns);
    }
  };

  /**
   *
   * @returns
   * Returns the drill down information of the column by checking
   */
  getDrillDownColumnsData = () => {
    let reduxState = store.getState();
    let drillDownsReducer = reduxState.DrillDownReducer.drillDowns;
    let drillDowns = drillDownsReducer.get(
      this.props.componentTHIS.state.pluginId
    );

    if (drillDowns && drillDowns.drillDownColumnsForParentColumns) {
      return drillDowns.drillDownColumnsForParentColumns.get(
        this.props.parentColumn.uniqeColumnId
      ) || [];
    }

    return [];
  };

  /**
   * Sets transfer default filters status
   * @param {*} value 
   */
  setPreserveDefaultFilters = (value) => {
    let reduxState = store.getState();
    let pluginId = this.props.componentTHIS.state.pluginId;
    let allDrillDownsMap = reduxState.DrillDownReducer.drillDowns;
    let currentDrillObject = allDrillDownsMap.get(pluginId);

    if (currentDrillObject.preserveDefaultFilters instanceof Map !== true) {
      currentDrillObject.preserveDefaultFilters = new Map();
    }

    let preserveDefaultFilters = currentDrillObject.preserveDefaultFilters.set(this.props.parentColumn.uniqeColumnId, value);
    let settedDrillDown = allDrillDownsMap.set(pluginId, {
      ...currentDrillObject,
      preserveDefaultFilters: preserveDefaultFilters
    });

    store.dispatch(setPluginsDrillDowns(settedDrillDown));

    this.setState({
      ...this.state,
      preserveDefaultFilters: value
    });
  }

  /*
   * Changes drilldown type
   */
  changeDrilldownType = (value) => {
    let reduxState = store.getState();
    let pluginId = this.props.componentTHIS.state.pluginId;
    let allDrillDownsMap = reduxState.DrillDownReducer.drillDowns;
    let isCurrentDrillObjectAvailable = allDrillDownsMap.has(pluginId) && allDrillDownsMap.get(pluginId).allDrillDownColumnsInPlugin.size > 0 ? true : false

    if (isCurrentDrillObjectAvailable) {
      let currentDrillObject = allDrillDownsMap.get(pluginId);
      let settedDrillDown = allDrillDownsMap.set(pluginId, {
        ...currentDrillObject,
        drillDownTypes: value,
      });
      let triggeredDrilldowns = reduxState.DrillDownReducer.triggeredDrillDowns;
      let settedDrillDownLayerMap =
        settedDrillDown.get(pluginId).drillDownLayerMap;
      let drilldownLayerMapKeys = Array.from(settedDrillDownLayerMap.keys());
      let pluginColumnClickedAndShouldBeRemoved =
        reduxState.DrillDownReducer.pluginColumnClickedAndShouldBeRemoved;

      if (pluginColumnClickedAndShouldBeRemoved.has(pluginId)) {
        pluginColumnClickedAndShouldBeRemoved.delete(pluginId);
      }

      for (let i = 0; i < drilldownLayerMapKeys.length; i++) {
        settedDrillDownLayerMap.get(
          drilldownLayerMapKeys[i]
        ).hasDrillDownOperation = false;
      }

      store.dispatch(setPluginsDrillDowns(settedDrillDown));

      if (triggeredDrilldowns.has(pluginId)) {
        triggeredDrilldowns.delete(pluginId);

        store.dispatch(setTriggeredDrillDowns(triggeredDrilldowns));
      }
    }

    this.setState({
      ...this.state,
      drillDownType: value,
    });

    let columnMap = this.props.componentTHIS.state.columnMap
    let isColumnMapRequiredFieldsFilled = columnMap && columnMap.category.data.length > 0 && columnMap.measure.data.length > 0 ? true : false

    if (isColumnMapRequiredFieldsFilled) {
      this.props.refreshPlugin(
        true,
        this.props.componentTHIS.state.pluginId,
        true,
        true
      );
    } else {
      let errorMessage = i18n.t("ColumnMapValidError", {
        pluginName: i18n.t("Plugins." + "pie-chart-enhanced" + ".Name"),
        interpolation: { escapeValue: false }
      })

      showError(errorMessage);
    }
  };

  render() {
    let drillDownColumnsData = this.getDrillDownColumnsData();
    let isDrillDownTypeAllowed = drillDownColumnsData.length > 0;
    let isPreserveDefaultFiltersAllowed = drillDownColumnsData.length > 0 && this.state.drillDownType?.length > 0 && this.state.drillDownType === "normal";

    return (
      <div
        href="javascript:;"
        class="btn btn-sm moreIcon"
        onClick={() => this.props.disabled ? null : this.toogleDrillDown()}
        style={{
          fontSize: "12px",
          top: "calc(50% - 14px)",
          position: "relative",
          color: "#484848",
          cursor: this.props.disabled ? "not-allowed" : "pointer"
        }}
      >
        <Tooltip
          placement="right"
          title={
            this.props.disabled && this.props.fieldWarning
              ? i18n.t("DrillDown.FieldWarning")
              : null
          }
        >
          <div>
            <i
              name={this.props.parentColumn.displayName + " Content Of Column"}
              class="fas fa-angle-double-down"
              style={{
                color: this.props.disabled ? "#4848485F" : "#484848",
              }}
            ></i>
          </div>
        </Tooltip>
        <div
          style={{
            display:
              this.state.drillDownPopupVisible === true ? "block" : "none",
          }}
        >
          <PopupContainer
            top={"33px"}
            position={"right"}
            width={"300px"}
            positionCalibration={"45px"}
            marginBottom={"20px"}
          >
            <Droppable
              types={["column"]}
              onDrop={(val) => this.onDrop(val, this.props.type, "column")}
            >
              <div
                className={"accordion-header card-header"}
                style={{ background: "#fff" }}
              >
                {i18n.t("DrillDown.DrillDownColumns")}
                <Tooltip tooltip={i18n.t("Dashboard.Data.MultipleColumn")}>
                  <Text
                    style={{
                      fontSize: "11px",
                      float: "right",
                      top: "3px",
                      position: "relative",
                      color: "#8e8d8d",
                    }}
                  >
                    ({i18n.t("Dashboard.Data.MultiColumn")})
                  </Text>
                </Tooltip>
              </div>
              <div
                name={"pivotRowArea"}
                className={"card-body"}
                style={{ minHeight: "20px" }}
              >
                {drillDownColumnsData.length > 0 ? (
                  <DragDropForDrilldown
                    includesDrillDown={true}
                    content={verticalDragDropItem}
                    componentTHIS={this}
                    type={"drilldown"}
                    data={drillDownColumnsData}
                    updateColumnMapData={this.updateDrillDowns}
                    isDrilldownColumn={true}
                  ></DragDropForDrilldown>
                ) : (
                  <Text
                    type={"span"}
                    style={{
                      color: "#a2a2a2",
                      fontSize: "14px",
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    {i18n.t("DrillDown.RowsDesc")}
                  </Text>
                )}
              </div>
            </Droppable>

            <Divider className="drilldown-divider" orientation="left"></Divider>

            {this.state.pluginKey === "pie-chart-enhanced" || this.state.pluginKey === "pie-chart" ? (
              <>
                <Row
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    textAlign: "left"
                  }}
                >
                  <Col
                    span={8}
                    style={{ fontSize: "11px", color: "rgb(162, 162, 162)" }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.DrilldownType")}
                  </Col>
                  <Col span={16}>
                    <Select
                      style={{ width: "100%", fontSize: "11px" }}
                      onChange={isDrillDownTypeAllowed ? this.changeDrilldownType : null}
                      defaultValue={this.state.drillDownType}
                      disabled={!isDrillDownTypeAllowed}
                    >
                      <Option className="inside-drilldown-type" value="normal">
                        {i18n.t(
                          "Dashboard.Configuration.Fields.PieChartNormalDrilldown"
                        )}
                      </Option>
                      <Option
                        className="inside-drilldown-type"
                        value="inside-plugin"
                      >
                        {i18n.t(
                          "Dashboard.Configuration.Fields.PieChartInsideDrilldown"
                        )}
                      </Option>
                      <Option
                        className="inside-drilldown-type"
                        value="explode-pie-chart"
                      >
                        {i18n.t(
                          "Dashboard.Configuration.Fields.DoublePieDrilldown"
                        )}
                      </Option>
                    </Select>
                  </Col>
                </Row>
                {this.state.drillDownType === "inside-plugin" ||
                  this.state.drillDownType === "explode-pie-chart" ? (
                  <Row className="piechart-drill-warn">
                    {i18n.t(
                      "Dashboard.Configuration.Fields.PieChartDrilldownWarning"
                    )}
                  </Row>
                ) : null}
              </>
            ) : null}

            <Row
              style={{
                marginTop: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                textAlign: "left"
              }}
            >
              <Col
                style={{ marginRight: "16px", fontSize: "11px", color: "rgb(162, 162, 162)" }}
              >
                {i18n.t("DrillDown.PreserveDefaultFilters")}
              </Col>
              <Col
              >
                <Checkbox
                  disabled={!isPreserveDefaultFiltersAllowed}
                  onChange={isPreserveDefaultFiltersAllowed ? (e => this.setPreserveDefaultFilters(e.target.checked)) : null}
                  checked={
                    this.state.drillDownType === "normal" ? this.state.preserveDefaultFilters : true
                  }
                />
              </Col>
            </Row>

            <Button
              id={"closeContentOfColumn " + this.props.parentColumn.displayName}
              style={{
                position: "absolute",
                top: "3px",
                right: "15px",
                borderRadius: "40px",
                padding: "6px 11px",
                backgroundColor: "white",
                zIndex: 2,
              }}
              type={"default"}
              onClick={() => this.closeDrillDownPopup()}
            >
              X
            </Button>
          </PopupContainer>
        </div>
      </div>
    );
  }
}
