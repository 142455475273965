/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import $ from "jquery";
import i18n from "../../../../Utils/i18next";
import PieChartEnhancedConfiguration from "./PieChartEnhancedConfiguration"
import PieChartEnhancedData from "./PieChartEnhancedData"
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  getCurrentDateTime,
  getContrastColor,
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData, renderConditionalFormatting, renderNavigation } from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import { InsightsConfig } from "../../RenderJs/config";
import { getFormattedValue } from "../format";
import { calculatePluginInlineHeight } from "../../../../../src/ui/DrillDown/PluginHeightWithDrilldown"
import { createTrigger } from "../../../Interaction/CreateTrigger";
import NavigationContent from "../../../Navigation/NavigationContent";
import { compare } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { deepCopy, loadingScreen } from "../../../../Utils/Global";
import { shortMonths, longMonths, bigNumberPrefixes, decimalSeperators, thousandSeperators } from "../../../GeneralComponents/PublicSortItems";
import { calculateDataWithHiddenArea, decideCopiedData } from "./MergeDatas"
import { store } from "../../../..";
import { setPluginsDrillDowns, setTriggeredDrillDowns } from "../../../DrillDown/DrillDownAction";
import { convertHTMLRuletoRule } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { vispeahenLogo } from "../Table/TablePdfContent";
import { rmvpp } from "../../RenderJs/rmvpp";
import { changePluginLoaderVisibility } from "../../../GeneralComponents/PluginLoader/PluginLoaderAction";
import _ from "lodash";

const condFormats = [];
const filters = [];
const pluginName = "pie-chart-enhanced";
const data = JSON.parse(
    '[{"category":2014,"measure":4045081712},{"category":2015,"measure":4296005250},{"category":2016,"measure":4037735418}]'
  );
  const config = JSON.parse(
    '{"innerRadius":0,"legend":true,"showHideButton":false,"colours":"Flat-UI","toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","refresh":0}'
  );
  const columnMap = JSON.parse(
    `{"category":{"Code":"\'ucus_PBWWSJXEAR\'.\'yil\'","Name":"yil","DataType":"double","Table":"ucus_PBWWSJXEAR","Measure":"none","ID":"ucus_PBWWSJXEAR.yil","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""},"measure":{"Code":"\'ucus_PBWWSJXEAR\'.\'bagajtoplam\'","Name":"bagajtoplam","DataType":"double","Table":"ucus_PBWWSJXEAR","Measure":"sum('ucus'.'bagajtoplam')","ID":"ucus_PBWWSJXEAR.bagajtoplam","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""},"hidden":[]}`
  ); 

const configurationParameters = [
    {
      targetProperty: "innerRadius",
      label: "InnerRadius",
      inputType: "textbox",
      inputOptions: {
        defaultValue: 0
      },
      scalable: "size",
      desc: "desc151"
    },
    {
      targetProperty: "condFormat",
      label: "CondFormat",
      inputType: "checkbox",
      inputOptions: {
        defaultValue: true
      },
      desc: "desc162"
    },
    {
      targetProperty: "legend",
      label: "Legend",
      inputType: "checkbox",
      inputOptions: {
        defaultValue: true
      },
      desc: "desc98"
    },
    {
      targetProperty: "legendPos",
      label: "Legend Position",
      inputType: "dropdown",
      inputOptions: {
        defaultValue: "bottom"
      },
      desc: "desc98"
    },
    {
      targetProperty: "showHideButton",
      label: "Show Hide Button",
      inputType: "checkbox",
      inputOptions: {
        defaultValue: false
      },
      desc: "desc230"
    },
    {
      targetProperty: "colours",
      label: "Colours",
      inputType: "palette",
      inputOptions: {
        defaultValue: "Flat-UI"
      },
      desc: "desc208"
    },
    {
      targetProperty: "toggleCriteria",
      label: "ToggleCriteria",
      inputType: "textbox",
      inputOptions: { defaultValue: "" },
      desc: "desc59"
    },
    {
      targetProperty: "title",
      label: "Title",
      inputType: "textbox",
      inputOptions: { defaultValue: "" },
      desc: "desc94"
    },
    {
      targetProperty: "summary",
      label: "Summary",
      inputType: "textbox",
      inputOptions: { defaultValue: "" },
      desc: "desc61"
    },
    {
      targetProperty: "backgroundColor",
      label: "BackgroundColor",
      //inputType: 'colour',
      inputType: "textbox",
      inputOptions: { defaultValue: "rgb(255,255,255)" },
      desc: "desc62"
    },
    {
      targetProperty: "refresh",
      label: "RefreshPeriod",
      inputType: "textbox",
      inputOptions: {
        subtype: "number",
        min: 0,
        defaultValue: 0
      },
      desc: "desc89"
    },
    {
      targetProperty: "dataType",
      label: "MeasureVisualization",
      inputType: "dropdown",
      inputOptions: {
        defaultValue: "numeric",
        values: ["percent", "numeric", "percent-numeric"]
      },
      desc: "pie-chart data type"
    },
    {
      targetProperty: "legendType",
      label: "LegendVisulisation",
      inputType: "dropdown",
      inputOptions: {
        defaultValue: "percent-numeric",
        values: ["percent", "numeric", "percent-numeric"]
      },
      desc: "pie-chart data type"
    },
    {
      targetProperty: "showMeasures",
      label: "showMeasures",
      inputType: "checkbox",
      inputOptions: {
        defaultValue: true,
      },
      desc: "pie-chart show measures"
    },
    {
      targetProperty: "showMeasuresWith",
      label: "showMeasuresWith",
      inputType: "dropdown",
      inputOptions: {
        defaultValue: "showMeasuresWithLine",
        values: ["showMeasuresWithPie", "showMeasuresWithLine"]
      },
    },
    {
      targetProperty: "selectMultiple",
      label: "Select Multiple",
      inputType: "checkbox",
      inputOptions: {
        defaultValue: true
      },
      desc: "desc230"
    },
    {
      targetProperty: "titleAlign",
      label: "titleAlign",
      inputType: "textbox",
      inputOptions: {
        defaultValue: "center"
      },
      desc: "titleAlign"
    },
    {
      targetProperty: "titleFont",
      label: "titleFont",
      inputType: "textbox",
      inputOptions: {
        defaultValue: "Verdana"
      },
      desc: "titleFont"
    },
    {
      targetProperty: "titleFontStyle",
      label: "titleFontStyle",
      inputType: "textbox",
      inputOptions: {
        defaultValue: false
      },
      desc: "titleFontStyle"
    },
    {
      targetProperty: "titleFontWeight",
      label: "titleFontWeight",
      inputType: "textbox",
      inputOptions: {
        defaultValue: false
      },
      desc: "titleFontWeight"
    },
    {
      targetProperty: "titleTextDecor",
      label: "titleTextDecor",
      inputType: "textbox",
      inputOptions: {
        defaultValue: false
      },
      desc: "titleTextDecor"
    },
    {
      targetProperty: "titleFontSize",
      label: "titleFontSize",
      inputType: "textbox",
      inputOptions: {
        subtype: "number",
        min: 10,
        max: 30,
        defaultValue: 15
      },
      desc: "titleFontSize"
    },
    {
      targetProperty: "titleColour",
      label: "titleColour",
      inputType: "textbox",
      inputOptions: {
        defaultValue: "black"
      },
      desc: "titleColour"
    },    
      {
        targetProperty: "threeDVisulisation",
        label: "3D Visulisation",
        inputType: "checkbox",
        inputOptions: {
          defaultValue: false
        },
        desc: "desc230"
      },
      {
        targetProperty: "visuliateByRadius",
        label: "Visuilate By Radius",
        inputType: "checkbox",
        inputOptions: {
          defaultValue: false
        },
        desc: "desc230"
      }
];
const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

const actions = [
  {
    trigger: "clickSlice",
    type: "click",
    name: "Click Slice",
    output: ["category"],
    description: "ActionClickDesc"
  },
]

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const pluginConditionalFormatOptions = {
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe"
  }
};

const conditionalFormatColumnMap = new Set(["category", "measure"]);
const conditionalFormatTargetMap = new Set(["category"])

// Value is converted to affix version
function affixFormat(value, columnMap) {
  if (columnMap.affixAlign === "right") {
    return value + columnMap.affixValue;
  } else if (columnMap.affixAlign === "left") {
    return columnMap.affixValue + value;
  }

  return value;
}

export default class PieChartEnhanced extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      defaultConditionalFormatColumn: {},
      isLockedTargetValue: true,
    })

    this.rerenderProcessStarted = false;
    this.callBackObject = {};
    this.drillDowns = {};
  }

  calculatePluginHeight = (plugin, settings) => {
    let pluginHeight = settings.grid.rowHeight * plugin.h; // multiply plugin.height and grid's row height
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginMinHeightDifference = 30;
    let maxHeight =
      pluginHeight -
      (pluginMinHeightDifference +
        pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    this.drillDowns = this.props.plugin.drillDowns;

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  /**
 * 
 * @param {*} e 
 * @returns 
 * 
 * According to the incoming event, it finds the clicked column and returns it.
 */
  getClickedColumn = (columnMap) => {
    return [columnMap["category"]];
  };

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );

    if (this.props.plugin.drillDowns !== nextProps.plugin.drillDowns) {
      this.drillDowns = this.props.plugin.drillDowns;
    }
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <PieChartEnhancedConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: "Plugins.pie-chart-enhanced.ColumnMap.Hidden.Desc",
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: "Plugins.pie-chart-enhanced.ColumnMap.Hidden.Name",
      }
    }

    return (
      <PieChartEnhancedData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        refreshPlugin={props.refreshPlugin}
        navigations={props.navigations}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  /*
  * Converts columnMap with data object for conditional format component
  */
  convertColumnMapForConditionalFormat = (cmMap) => {
    let newColumnMap = []
    let reduxState = store.getState()
    let category = cmMap.category.data.length > 0 ? cmMap.category.data[0] : null

    if (cmMap.category.data.length > 0) {
      newColumnMap.push(cmMap.category.data[0])
    } 

    if (cmMap.measure.data.length > 0) {
      newColumnMap.push(cmMap.measure.data[0])
    }

     if (reduxState.DrillDownReducer.drillDowns.has(this.props.plugin.id) && category) {
       let drillDownsReducer = reduxState.DrillDownReducer.drillDowns.get(this.props.plugin.id).drillDownColumnsForParentColumns;
       let drillCol = drillDownsReducer.has(category.uniqeColumnId)
         ? drillDownsReducer.get(category.uniqeColumnId)
         : []

      if (drillCol) {
        newColumnMap = newColumnMap.concat(drillCol)
      }
    }

    return newColumnMap
  }

  getConditionalFormattingComponent = props => {
    let columnMap = props.plugin.columnMap
  
    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        pluginId={props.plugin.id}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
        isNecessaryAllColumns={this.isNecessaryAllColumns}
        defaultConditionalFormatColumn={this.state.defaultConditionalFormatColumn}
        isLockedTargetValue={this.state.isLockedTargetValue}
        columnMapWithDrills={this.convertColumnMapForConditionalFormat(columnMap)}
      />
    );
  };

  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      category: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Category.Name"),
        type: "dim",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Category.Desc"),
        required: true,
        minimumColumnSize: 1,
        conditionalFormat: true,
        data: []
      },
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        type: "dim",
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        conditionalFormat: "icon",
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        type: "hidden",
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  convertFormatConditionalFormatting = (condFormats, columns) => {
    let condFormatList = [];

    condFormats.map(condItem => {
      condItem.targetColumns.map(targetColumn => {
        let conditionalFormat = {};
        let columnsMap = new Map();

        columns.map(column => {
          columnsMap.set(column.uniqeColumnId, column);
        })

        conditionalFormat.RightRule = condItem.rule.rightRule.rule;
        conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
        conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName  === "{undefined}" ? convertHTMLRuletoRule(condItem.rule.leftRule).ruleColumnName : condItem.rule.leftRule.ruleColumnName;
        conditionalFormat.RightRuleColumnName = condItem.rule.rightRule.ruleColumnName
        conditionalFormat.Columns = columnsMap;
        conditionalFormat.TargetID = targetColumn.TargetID;
        conditionalFormat.TargetName = targetColumn.TargetName;
        conditionalFormat.Operator = condItem.rule.operator;
        conditionalFormat.id = condItem.id;

        conditionalFormat.Style = {
          colour: condItem.options.color,
          icon: "",
          background: { colour: condItem.options.backgroundColor }
        };

        condFormatList.push(conditionalFormat);
      });
    });

    return condFormatList;
  };

  condFormatsLength

  /**
   * Creates chart legend
   * 
   * @param {*} chart 
   * @param {*} config 
   */
  setChartLegend = (chart, config, pluginInlineHeight, columnMap) => {
    if (chart.legend) {
      chart.legend.dispose();
    }

    if (config.legend) {
      let pluginWidth = this.props.plugin.w;
      let pluginHeight = this.props.plugin.h;
      let isPluginBigEnough = pluginWidth > 3 && (pluginHeight > 3 || pluginWidth >= 4) ? true : false
      let isChartPosLeftOrRight = config.legendPos === "right" || config.legendPos === "left" ? true : false
      let legendHeight = isChartPosLeftOrRight ? pluginInlineHeight : pluginInlineHeight / 2.7 > 80 ? 80 : pluginInlineHeight / 2.7;

      chart.legend = new am4charts.Legend();
      chart.legend.disabled = !isPluginBigEnough
      chart.legend.fontSize = 10;
      chart.legend.position = config.legendPos;
      chart.legend.maxHeight = legendHeight;
      chart.legend.minHeight = legendHeight;
      chart.legend.scrollable = true;
      chart.legend.scrollbar.exportable = false;
      chart.legend.markers.template.children.getIndex(0).cornerRadius(7, 7, 7, 7);
      chart.legend.markers.template.width = 10;
      chart.legend.markers.template.height = 10;
      chart.legend.valueLabels.template.text = config.dataType === "percent" ? "{value.percent}%" : (config.dataType === "numeric" || config.dataType == undefined) ? `${affixFormat("{value}", columnMap["measure"])}`: `${affixFormat("{value}", columnMap["measure"])}, {value.percent}%`
      chart.legend.labels.template.fill = am4core.color(this.contrastColor)
      chart.legend.valueLabels.template.fill = am4core.color(this.contrastColor)
    }
  }

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    let reduxState = store.getState()
    
    let pluginId = this.props.plugin.id;
    let categoryId = columnMap.category.uniqeColumnId;
    
    let hasPluginDrilldown = reduxState.DrillDownReducer.drillDowns.has(pluginId) ? true : false
    let drillDownsReducer = hasPluginDrilldown && reduxState.DrillDownReducer.drillDowns.get(pluginId) ? reduxState.DrillDownReducer.drillDowns.get(pluginId) : {}
    let hasDrillSubData = drillDownsReducer.drillDownLayerMap && drillDownsReducer.drillDownLayerMap.size > 0 ? true : false 
    let hasCategorysDrillAvailable = drillDownsReducer.allDrillDownColumnsInPlugin && drillDownsReducer.allDrillDownColumnsInPlugin.has(categoryId) ? true : false

    let isDrillExplodePieChart = drillDownsReducer.drillDownTypes && drillDownsReducer.drillDownTypes === "explode-pie-chart" && hasDrillSubData && hasCategorysDrillAvailable ? true : false
    let isDrilInsidePieChart = drillDownsReducer.drillDownTypes && drillDownsReducer.drillDownTypes === "inside-plugin" && hasDrillSubData && hasCategorysDrillAvailable ? true : false

    let pluginHeight = this.props.plugin.h;
    let pluginWidth = this.props.plugin.w;

    let isPluginWidthGreaterThanSeven = pluginWidth >= 7 ? true : false
    let isPluginHeightGreaterThanFour = pluginHeight >= 4 ? true : false
    let isPluginSizeFitForExplodePie = isPluginWidthGreaterThanSeven && isPluginHeightGreaterThanFour ? true : false

    let pieContainer = am4core.create(pluginId, am4core.Container);

    this.contrastColor = getContrastColor(config.backgroundColor)

    pieContainer.width = am4core.percent(100);
    pieContainer.height = am4core.percent(100);
    pieContainer.layout = "horizontal";
    
    data = decideCopiedData(deepCopy(data), this.props.plugin, new Set(), condFormats);

    let showCondForm = $('<div id="showCondForm" style="display:flex; position:relative; bottom:5px; flex-wrap:wrap;"></div>')
    let isPluginBigEnough = pluginWidth > 3 && (pluginHeight > 3 || pluginWidth >= 4) ? true : false
    this.condFormatsLength = condFormats ? condFormats.length : 0

    /*
    * Converts data for conditional format
    */
    const convertDataToColumnMap = (data) => {
      let newData = {}

      newData[columnMap.category.displayName] = data.category
      newData[columnMap.measure.displayName] = data.measure

      return newData
    }

    /*
    * Converts sub data to columnmap
    */
    const convertSubDataToColumnMap = (data) => {
      let drillCol
      let newData = {}

      if (reduxState.DrillDownReducer.drillDowns.has(pluginId)) {
        let drillDownsReducer = reduxState.DrillDownReducer.drillDowns.get(pluginId).drillDownColumnsForParentColumns;

        drillCol = drillDownsReducer.has(columnMap.category.uniqeColumnId) 
          ? drillDownsReducer.get(columnMap.category.uniqeColumnId)[0] 
          : drillDownsReducer.get(columnMap.category.drillDownParentColumnId)[0] 
      }

      newData[drillCol.displayName] = data.category
      newData["measure"] = data.measure

      return newData
    }

    /*
    * Converts data to columnMap for conditional formatting
    */
    const convertColumnMapForConditionalFormat = (cmMap) => {
      let newColumnMap = []

      newColumnMap.push(cmMap.category)
      newColumnMap.push(cmMap.measure)

      if (reduxState.DrillDownReducer.drillDowns.has(pluginId)) {
        let drillDownsReducer = reduxState.DrillDownReducer.drillDowns.get(pluginId).drillDownColumnsForParentColumns;
        let drillCol = drillDownsReducer.has(cmMap.category.uniqeColumnId) 
          ? drillDownsReducer.get(cmMap.category.uniqeColumnId)[0] 
          : cmMap.category.drillDownParentColumnId && drillDownsReducer.has(cmMap.category.drillDownParentColumnId)
            ? drillDownsReducer.get(cmMap.category.drillDownParentColumnId)[0]
            : null

        if (drillCol) {
          newColumnMap.push(drillCol)
        }
      }

      return newColumnMap
    }

    if (columnMap.category !== undefined && columnMap.category !== null && columnMap.category.uniqeColumnId !== undefined) {
      let columnMapCategory = columnMap.category
      this.setState({
        defaultConditionalFormatColumn: columnMapCategory
      })
    }

    if (condFormats !== undefined && typeof columnMap.category === 'object') {
      let convertedColumnMap = convertColumnMapForConditionalFormat(columnMap)
      condFormats = this.convertFormatConditionalFormatting(condFormats, convertedColumnMap);
    } else {
      condFormats = [];
    }

    let container = $("#" + divId)[0];
    let chartContainer = $("#" + divId)
    let chartWidth = chartContainer.width()
    let pluginInlineHeight = condFormats.length === 0 ? calculatePluginInlineHeight(pluginId) - 20 : calculatePluginInlineHeight(pluginId) - 50
    let chart = config.threeDVisulisation && !isDrillExplodePieChart && pluginHeight > 3 ? pieContainer.createChild(am4charts.PieChart3D) : pieContainer.createChild(am4charts.PieChart);//For 3d am3charts.PieChart3D
    
    $(container).find("[shape-rendering='auto']").parent().parent().css("display", "none")

    let THIS = this;

    chart.width = am4core.percent(50);
    chart.radius = am4core.percent(10);
    chart.dateFormatter.monthsShort = shortMonths
    chart.dateFormatter.months = longMonths
    chart.numberFormatter.bigNumberPrefixes = bigNumberPrefixes
    chart.language.locale["_decimalSeparator"] = decimalSeperators;
    chart.language.locale["_thousandSeparator"] = thousandSeperators; 
    
    const am4themes_myTheme = (target) => {
        if (target instanceof am4core.ColorSet) {
          let paletteColours = 
          (Array.isArray(THIS.props.plugin.config.colours) && THIS.props.plugin.config.colours) || 
          InsightsConfig.Palettes[THIS.props.plugin.config.colours] ||
          InsightsConfig.Palettes[THIS.props.plugin.config.paletteColours] || 
          (Array.isArray(THIS.props.plugin.config.paletteColours) && THIS.props.plugin.config.paletteColours)

          target.list = paletteColours.map(colour => {
            return am4core.color(colour)
        })
      }

      if (target instanceof am4core.Scrollbar) {
        target.thumb.background.fillOpacity = 0.2
        target.thumb.background.fill = am4core.color(this.contrastColor)
        target.background.fillOpacity = 0.1
        target.background.fill = am4core.color(this.contrastColor)
        target.minWidth = 5
        target.minHeight = 5
        target.thumb.background.strokeOpacity = 0
      }
    }

    am4core.useTheme(am4themes_myTheme);
    am4core.useTheme(am4themes_animated);

    let copiedData = deepCopy(data)

    const sum = data.reduce((accumulator, object) => {
      return accumulator + object.measure;
    }, 0);

    for (let i = 0; i < copiedData.length; i++) {
      let paletteColours = 
          (Array.isArray(THIS.props.plugin.config.colours) && THIS.props.plugin.config.colours) || 
          InsightsConfig.Palettes[THIS.props.plugin.config.colours] ||
          InsightsConfig.Palettes[THIS.props.plugin.config.paletteColours] ||
          (Array.isArray(THIS.props.plugin.config.paletteColours) && THIS.props.plugin.config.paletteColours)

      if (paletteColours[i]) {
        copiedData[i]["color"] = paletteColours[i]
        copiedData[i]["contrastColor"] = getContrastColor(paletteColours[i])
      } else {
        let randomColorList = [    
          "#67b7dc",
          "#6771dc",
          "#a367dc",
          "#dc67ce",
          "#dc6788",
          "#dc8c67",
          "#dcaf67",
          "#dcd267",
          "#c3dc67",
          "#a0dc67",
          "#7cdc67",
          "#67dc75",
          "#67dc98",
          "#67dcbb",
          "#67dadc"
        ]

        let newRandomColor = randomColorList[i] ? randomColorList[i] : randomColorList[i % randomColorList]

        copiedData[i]["color"] = newRandomColor
        copiedData[i]["contrastColor"] = getContrastColor(newRandomColor)
      }

      if (condFormats.length > 0) {
        for (let j = 0; j < condFormats.length; j++) {
          let convertedData = convertDataToColumnMap(copiedData[i])
          let comparedData = compare(convertedData, condFormats[j])

          if (comparedData.status === true) {
            copiedData[i]["color"] = condFormats[j].Style.background.colour
            copiedData[i]["contrastColor"] = getContrastColor(condFormats[j].Style.background.colour)
          }
        }
      }

      copiedData[i]["radiusValue"] = copiedData[i].measure / sum
      copiedData[i]["id"] = i

      let measureDataFormat = columnMap["measure"].DataFormat
      let formattedMeasureValue = getFormattedValue(columnMap["measure"], data[i].measure)
      let formattedCategoryValue = getFormattedValue(columnMap["category"], data[i].category)
      let isDataFormatDotCommaNumberF = measureDataFormat[0] === "," && measureDataFormat[1] === "." && measureDataFormat[measureDataFormat.length - 1] === "f" ? true : false
      let isDataFormatDotCommaF = measureDataFormat[0] === "." && measureDataFormat[measureDataFormat.length - 1] === "f"

      copiedData[i]["formattedCategory"] = formattedCategoryValue

      if (columnMap["measure"].DataFormat === ".3s") { //for .3s
        chart.numberFormatter.numberFormat = "#.0a";
      } else if (measureDataFormat !== ".f" && isDataFormatDotCommaNumberF) { //for ,.5s
        let copiedMeasureValue = formattedMeasureValue;
        let zeroCount = parseInt(measureDataFormat.substring(measureDataFormat.indexOf(".") + 1, measureDataFormat.lastIndexOf("f")))

        zeroCount = isNaN(zeroCount) ? 0 : zeroCount
        
        let zeroArr = Array(zeroCount + 1).join("0")

        copiedMeasureValue = copiedMeasureValue.split(".").length > 0 ? copiedMeasureValue.split(".").join("") : copiedMeasureValue
        copiedMeasureValue = copiedMeasureValue.replace(",", ".")

        copiedData[i]["formattedMeasure"] = copiedMeasureValue
        chart.numberFormatter.numberFormat = "#,###." + zeroArr;
      } else if (measureDataFormat === ".f") { //for .f
        chart.numberFormatter.numberFormat = "####.########";
      } else if (isDataFormatDotCommaF) {
        let copiedMeasureValue = formattedMeasureValue;
        let zeroCount = parseInt(measureDataFormat.substring(measureDataFormat.indexOf(".") + 1, measureDataFormat.lastIndexOf("f")))
        let zeroArr = Array(zeroCount + 1).join("0")

        copiedMeasureValue = copiedMeasureValue.split(".").length > 1 ? copiedMeasureValue.split(".").join("") : copiedMeasureValue
        copiedMeasureValue = copiedMeasureValue.replace(",", ".")

        copiedData[i]["formattedMeasure"] = copiedMeasureValue
        chart.numberFormatter.numberFormat = "####." + zeroArr;
      }
    }

    let percent = pluginHeight > 3 && pluginWidth > 3 ? 80 : 90

    chart.responsive.enabled = false;
    chart.data = copiedData;
    chart.align = "center"
    chart.radius = am4core.percent(percent)
    chart.background.fill = config.backgroundColor;

    if (config.innerRadius && config.innerRadius > 0) {
        chart.innerRadius = am4core.percent(percent * config.innerRadius / 100); //inner radius for pie-chart
    } else {
        chart.innerRadius = am4core.percent(0); //inner radius for pie-chart
    }

    this.setChartLegend(chart, config, pluginInlineHeight, columnMap);

    const pieSeries = config.threeDVisulisation ? chart.series.push(new am4charts.PieSeries3D) : chart.series.push(new am4charts.PieSeries) //For 3d am3charts.PieSeries3D()

    pieSeries.dataFields.value = "measure"; //value field
    pieSeries.dataFields.category = "formattedCategory"; //category field
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.propertyFields.isActive = "pulled";
    pieSeries.slices.template.strokeWidth = 0;
    pieSeries.slices.template.stroke = am4core.color("#fff"); //side stroke color
    pieSeries.slices.template.strokeWidth = 2; //side stroke width
    pieSeries.slices.template.strokeOpacity = .3; //side stroke opacity
    pieSeries.slices.template.fontSize = 10;
    pieSeries.tooltip.fontSize = 10;
    pieSeries.slices.template.tooltipText = config.dataType === "percent" ? "{category}: {value.percent}%" : (config.dataType === "numeric" || config.dataType == undefined) ? `{category}: ${affixFormat("{value}", columnMap["measure"])}`: `{category}: ${affixFormat("{value}", columnMap["measure"])} ({value.percent}%)`
    pieSeries.tooltip.exportable = false;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.labels.template.fontSize = 10;
    pieSeries.calculatePercent = true;
    pieSeries.labels.template.fill = am4core.color(this.contrastColor)
    pieSeries.ticks.template.stroke = am4core.color(this.contrastColor)
    
    if (isDrillExplodePieChart) {
      pieSeries.slices.template.states.getKey(
        "active"
      ).properties.shiftRadius = 0;
      pieSeries.slices.template.events.on("hit", function (event) {
        let allDrillDownsMap = reduxState.DrillDownReducer.drillDowns
        let currentDrillObject = allDrillDownsMap.get(THIS.props.plugin.id)
        let settedDrillDown = allDrillDownsMap.set(THIS.props.plugin.id, {...currentDrillObject, callback: () => selectSlice(event.target.dataItem)})
        let drilldownObj = reduxState.DrillDownReducer.triggeredDrillDowns
        let mousePosition = { x: window.event.pageX, y: window.event.pageY }

        drilldownObj.delete(THIS.props.plugin.id)

        store.dispatch(setPluginsDrillDowns(settedDrillDown))
        store.dispatch(setTriggeredDrillDowns(drilldownObj))

        createTrigger(
          actions,
          columnMap,
          container,
          "clickSlice",
          event.target.dataItem._dataContext,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition,
          null,
          THIS.drillDowns,
          [columnMap["category"]],
          THIS.props.plugin,
          THIS.props.model,
        ); // Trigger event

        let tempPlugin = {...THIS.props.plugin};

        tempPlugin["clickedData"] = event.target.dataItem._dataContext
        THIS.props.updatePlugin(tempPlugin)
      });

      var drilldownChart = pieContainer.createChild(am4charts.PieChart);  //For 3d am3charts.PieChart3D
  
      let percent = (pluginHeight > 3 && pluginWidth > 3 ? 80 : 90) * 0.7

      drilldownChart.responsive.enabled = false;
      drilldownChart.align = "center"
      drilldownChart.background.fill = config.backgroundColor;
      drilldownChart.width = am4core.percent(50)
      drilldownChart.radius = am4core.percent(percent)

      this.setChartLegend(drilldownChart, config, pluginInlineHeight, columnMap);

      if (config.innerRadius && config.innerRadius > 0) {
        drilldownChart.innerRadius = am4core.percent(percent * config.innerRadius / 100); //inner radius for pie-chart
    } else {
      drilldownChart.innerRadius = am4core.percent(0); //inner radius for pie-chart
    }
  
      // Add and configure Series
      var pieSeries2 = drilldownChart.series.push(new am4charts.PieSeries());
      pieSeries2.dataFields.value = "measure";
      pieSeries2.dataFields.category = "category";
      pieSeries2.slices.template.states.getKey(
        "active"
      ).properties.shiftRadius = 0;
      pieSeries2.labels.template.radius = am4core.percent(3);
      pieSeries2.alignLabels = false;
      pieSeries2.events.on("positionchanged", updateLines);
  
      pieSeries2.hiddenState.properties.endAngle = -90;
  
      pieSeries2.labels.template.disabled = true;
      pieSeries2.ticks.template.disabled = false;
      pieSeries2.labels.template.maxWidth = chartWidth / 6;
      pieSeries2.alignLabels = true; //aligns labels
      pieSeries2.labels.template.fontSize = 10;
      pieSeries2.slices.template.fontSize = 10;
      pieSeries2.tooltip.fontSize = 10;
      pieSeries2.tooltip.exportable = false;
      pieSeries2.slices.template.stroke = am4core.color("#fff"); //side stroke color
      pieSeries2.ticks.template.stroke = am4core.color(this.contrastColor)
      pieSeries2.labels.template.fill = am4core.color(this.contrastColor)
      pieSeries2.slices.template.strokeWidth = 2
      pieSeries2.slices.template.strokeOpacity = 0.3

      let interfaceColors = new am4core.InterfaceColorSet();
  
      let line1 = pieContainer.createChild(am4core.Line);
      line1.strokeDasharray = "2,2";
      line1.strokeOpacity = 0.5;
      line1.stroke = am4core.color(this.contrastColor)
      line1.isMeasured = false;
  
      let line2 = pieContainer.createChild(am4core.Line);
      line2.strokeDasharray = "2,2";
      line2.strokeOpacity = 0.5;
      line2.stroke = am4core.color(this.contrastColor)
      line2.isMeasured = false;
  
      var selectedSlice;
      let selectedSliceEvent;

      function selectSlice(dataItem) {
        if (selectedSliceEvent) {
          selectedSliceEvent.dispose();
        }

        selectedSlice = dataItem.slice;
        selectedSliceEvent = selectedSlice.events.onAll(updateLines);

        let fill = selectedSlice.fill;
  
        if (dataItem.dataContext.subs) {
          let count = dataItem.dataContext.subs.length;
  
          pieSeries2.colors.list = [];
  
          for (let i = 0; i < count; i++) {
            pieSeries2.colors.list.push(fill.brighten((i * 2) / count));

            let subsDataItem = dataItem.dataContext.subs[i]
            let mainData = dataItem.dataContext
            let mainDataTotalMeasure = mainData.totalMeasure
            let dataItemRadius = subsDataItem.measure / mainDataTotalMeasure

            dataItem.dataContext.subs[i]["radiusValue"] = dataItemRadius

            for (let j = 0; j < condFormats.length; j++) {
              let convertedData = convertSubDataToColumnMap(dataItem.dataContext.subs[i])
              let comparedData = compare(convertedData, condFormats[j])
    
              if (comparedData.status === true) {
                pieSeries2.colors.list[i] = am4core.color(condFormats[j].Style.background.colour);
              }
            }
          }
    
          drilldownChart.data = dataItem.dataContext.subs;

          if (config.showMeasuresWith === "showMeasuresWithPie") {
            drilldownChart.data?.forEach((d,i) => {
              if (d && d.this && d.this.contrastColor) {
                d.this.contrastColor = getContrastColor(pieSeries2.colors.list[i].hex)
              }
            })
  
            pieSeries2.labels.template.adapter.add("fill", (color, target) => {
              return target?.dataItem?.dataContext?.this?.contrastColor || color
            })
          }
          
          pieSeries2.appear();
        }
  
        let middleAngle = selectedSlice.middleAngle;
        let firstAngle = pieSeries.slices.getIndex(0).startAngle;
        let animation = pieSeries.animate(
          [
            { property: "startAngle", to: firstAngle - middleAngle },
            { property: "endAngle", to: firstAngle - middleAngle + 360 }
          ],
          600,
          am4core.ease.sinOut
        );

        animation.events.on("animationprogress", updateLines);
      }
  
      function updateLines() {
        if (selectedSlice) {
          let p11 = {
            x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle),
            y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle)
          };
          let p12 = {
            x:
              selectedSlice.radius *
              am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc),
            y:
              selectedSlice.radius *
              am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc)
          };
  
          p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
          p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);
  
          let p21 = { x: 0, y: -pieSeries2.pixelRadius };
          let p22 = { x: 0, y: pieSeries2.pixelRadius };
  
          p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
          p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);
  
          line1.x1 = p11.x;
          line1.x2 = p21.x;
          line1.y1 = p11.y;
          line1.y2 = p21.y;
  
          line2.x1 = p12.x;
          line2.x2 = p22.x;
          line2.y1 = p12.y;
          line2.y2 = p22.y;
        }
      }
  
      chart.events.on("datavalidated", function () {
        setTimeout(function () {
          selectSlice(pieSeries.dataItems.getIndex(0));
        }, 1000);
      });

      pieSeries.events.on("positionchanged", updateLines);
    }

    if (config.visuliateByRadius) {
      pieSeries.dataFields.radiusValue = "radiusValue"
    }

    let isShowMeasuresOpen = config.showMeasures || config.showMeasures == undefined ? true : false

    if (isShowMeasuresOpen) {
      pieSeries.labels.template.disabled = !isPluginBigEnough;
      pieSeries.labels.template.truncate = true
      pieSeries.labels.template.wrap = true;
      pieSeries.labels.template.text = config.dataType === "percent" ? "{value.percent}%" : (config.dataType === "numeric" || config.dataType == undefined) ? `${affixFormat("{value}", columnMap["measure"])}` : `${affixFormat("{value}", columnMap["measure"])} \n {value.percent}%`
      pieSeries.labels.template.radius = am4core.percent(0);

      if (isDrillExplodePieChart) {
        if (isPluginSizeFitForExplodePie) {
          pieSeries2.labels.template.truncate = true
          pieSeries2.labels.template.wrap = true;
          pieSeries2.labels.template.text = config.dataType === "percent" ? "{value.percent}%" : (config.dataType === "numeric" || config.dataType == undefined) ? `${affixFormat("{value}", columnMap["measure"])}`: `${affixFormat("{value}", columnMap["measure"])} \n {value.percent}%`
          pieSeries2.labels.template.disabled = false; //ticks and labels
        } else {
          pieSeries.ticks.template.disabled = true;
          pieSeries.labels.template.disabled = true;
          pieSeries2.labels.template.disabled = true;
          pieSeries2.ticks.template.disabled = true;
        }
      }

      if (config.showMeasuresWith === "showMeasuresWithLine") {
        pieSeries.ticks.template.disabled = !isPluginBigEnough;
        pieSeries.labels.template.maxWidth = chartWidth / 6;
        pieSeries.alignLabels = true; //aligns labels

        if (isDrillExplodePieChart) {
          if (isPluginSizeFitForExplodePie) {
            pieSeries2.ticks.template.disabled = false;
            pieSeries2.labels.template.maxWidth = chartWidth / 6;
            pieSeries2.alignLabels = true; //aligns labels
          } else {
            pieSeries.ticks.template.disabled = true;
            pieSeries.labels.template.disabled = true;
            pieSeries2.labels.template.disabled = true;
            pieSeries2.ticks.template.disabled = true;
          }
        } 
      } else if (config.showMeasuresWith === "showMeasuresWithPie") {
        pieSeries.alignLabels = false; //aligns labels
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.radius = am4core.percent(-30);
        pieSeries.labels.template.maxWidth = chartWidth / 15;
        
        pieSeries.labels.template.adapter.add("fill", (color, target) => {
          return target?.dataItem?.dataContext?.this?.contrastColor || color
        })

        if (isDrillExplodePieChart) {  
          if (isPluginSizeFitForExplodePie) {
            pieSeries2.alignLabels = false; //aligns labels
            pieSeries2.ticks.template.disabled = true;
            pieSeries2.labels.template.radius = am4core.percent(-30);
            pieSeries2.labels.template.maxWidth = chartWidth / 15;
          } else {
            pieSeries.ticks.template.disabled = true;
            pieSeries.labels.template.disabled = true;
            pieSeries2.labels.template.disabled = true;
            pieSeries2.ticks.template.disabled = true;
          }
        } 
      }
    } else {
      pieSeries.labels.template.disabled = true; //ticks and labels
      pieSeries.ticks.template.disabled = true;

      if (isDrillExplodePieChart) {  
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
        pieSeries2.labels.template.disabled = true;
        pieSeries2.ticks.template.disabled = true;
      }
    }

    pieSeries.slices.template.events.on("hit", function(ev) {
      let series = ev.target.dataItem.component;
      let serie = []
      let clickedSlice
      let selectedKeys = new Set()
      let removedSubSeries = new Set()

      /*
      * Prepares chart data, controls is pie slice must close or open
      */
      const prepareChartData = (plugin, selected, isPluginContentClosed = false) => {
        if (isPluginContentClosed) {
          let chartData = chart.data

          let closedData = chartData.find(d => _.isEqual(ev.target.dataItem.dataContext, d))
          
          closedData["pulled"] = false

          chart.data = chartData
        } else {
          chart.data = decideCopiedData(copiedData, plugin, selected, condFormats, chart.data);
        }
      }

      /*
      * When trigger interaction, closes pie chart enhanced inside plugin drilldown's pie slices.
      */
      const deleteAllDrilldowns = (selected) => {
        let clonedCopiedData = deepCopy(copiedData)

        for (let i = 0; i < selected.length; i++) {
          let selectedCol = clonedCopiedData.filter(d => `${d.category}___${d.measure}` === `${selected[i].category}___${selected[i].measure}`)[0]

          if (selectedCol) {
            selectedCol["pulled"] = true

            let selectedColCopiedData = copiedData.filter(c => `${c.category}___${c.measure}` === `${selectedCol.category}___${selectedCol.measure}`)[0]

            selectedColCopiedData["interactionType"] = "interaction"
          }
        }

        chart.data = clonedCopiedData
      }

      series.slices.each(function(item, i) {
        if (!config.selectMultiple && config.selectMultiple != undefined) {
          if (item.isActive && item != ev.target) {
            item.isActive = false;
          } else if (item.isActive && item == ev.target) {
            serie.push(item.dataItem.dataContext)

            selectedKeys.add(ev.target.dataItem.dataContext.id)
          }
        } else {
          if ((item.isActive || item.dataItem.dataContext.pulled) && (!item.dataItem.dataContext.isSub || item != ev.target) && !removedSubSeries.has(item.dataItem.dataContext.id)) {
            serie.push(item.dataItem.dataContext)

            selectedKeys.add(item.dataItem.dataContext.id)
          } else if (!item.isActive && item.dataItem.dataContext.isSub) {
            selectedKeys.delete(item.dataItem.dataContext.id)
            removedSubSeries.add(item.dataItem.dataContext.id)
          }
        }
      })

      let mousePosition = { x: window.event.pageX, y: window.event.pageY }
      let clickedColumn = THIS.getClickedColumn(columnMap, clickedSlice, data);

      if (isDrilInsidePieChart) {
        let allDrillDownsMap = reduxState.DrillDownReducer.drillDowns
        let currentDrillObject = allDrillDownsMap.get(THIS.props.plugin.id)
        let settedDrillDown = allDrillDownsMap.set(THIS.props.plugin.id, {...currentDrillObject, callback: (status) => prepareChartData(THIS.props.plugin, selectedKeys, status)})
        let drilldownObj = reduxState.DrillDownReducer.triggeredDrillDowns

        drilldownObj.delete(THIS.props.plugin.id)

        store.dispatch(setPluginsDrillDowns(settedDrillDown))
        store.dispatch(setTriggeredDrillDowns(drilldownObj))
      }
      
      let filteredSerie = serie.filter(s => !s.mainData)

      if (!isDrillExplodePieChart) {
        if (filteredSerie.length > 0 && !ev.target.dataItem.dataContext.isSub) {
          createTrigger(
            actions,
            columnMap,
            container,
            "clickSlice",
            filteredSerie,
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            mousePosition,
            null,
            THIS.drillDowns,
            clickedColumn,
            THIS.props.plugin,
            THIS.props.model,
            filteredSerie,
            () => deleteAllDrilldowns(filteredSerie)
          ); // Trigger event
        } else {
          let chartData = deepCopy(chart.data)
          let targetSub = ev.target.dataItem.dataContext
          let subs = []
          let mainDatas = []
          let targetDataFirstIndex
  
          if (targetSub.isSub) {
            for (let i = 0; i < chartData.length; i++) {
              if (_.isEqual(targetSub.mainData, chartData[i].mainData)) {
                subs.push(targetSub)
  
                if (targetDataFirstIndex == undefined) {
                  targetDataFirstIndex = i
                }
              } else {
                mainDatas.push(chartData[i])
              }
            }
          
            mainDatas.splice(targetDataFirstIndex, 0, targetSub.mainData);
  
            chart.data = mainDatas
          }
        }
      }
    });
    
    pieSeries.slices.template.propertyFields.fill = "color"; //requires for conditional format

    if (condFormats.length > 0 && config.condFormat) {
      addCondFormLegend();
    }

    function prepareConditionalLegends(conditionalLegendDiv) {
      //sets conditional format div's for find legend div
      conditionalLegendDiv.setAttribute("class", "apexcharts-legend-series");
      conditionalLegendDiv.setAttribute("style", "margin: 0px 5px;");
    }

    function prepareConditionalLegendMarkers(conditionalLegendMarkersDiv, d) {
      //sets conditional format legend marker's
      conditionalLegendMarkersDiv.setAttribute("class", "dot");
      conditionalLegendMarkersDiv.setAttribute(
        "style",
        "background: " +
        d.Style.colour +
        "; color:" +
        d.Style.colour +
        "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 10px; border-color: rgb(255, 255, 255); border-radius: 10px; display: inline-block; border: 1px solid white;"
      );
    }

    function addCondFormLegend() {
      //adds conditional format legends to plugins bottom
      let usedCondFormatIds = new Set();

      condFormats.forEach(function (d, i) {
        if (usedCondFormatIds.has(d.id)) {
          return;
        } else {
          usedCondFormatIds.add(d.id);
        }

        let condLegend = document.createElement("div");
        prepareConditionalLegends(condLegend)

        let condLegendMarker = document.createElement("span");
        // prepareConditionalLegendMarkers(condLegendMarker, d) //for conditional format color dot.

        let condLegendMarkerBackground = document.createElement("span");

        if (d.Style.background) {
          condLegendMarkerBackground.setAttribute("class", "dot");
          condLegendMarkerBackground.setAttribute(
            "style",
            "background: " +
            d.Style.background.colour +
            "; color:" +
            d.Style.background.colour +
            "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 10px; border-color: rgb(255, 255, 255); border-radius: 10px; display: inline-block; margin-left: 2px; border: 1px solid white;"
          );
        }

        let condLegendText = document.createElement("span");
        condLegendText.setAttribute("class", "text");
        condLegendText.setAttribute(
          "style",
          "color:" + 
          getContrastColor(config.backgroundColor) +
          "; font-size: 12px; font-family: Helvetica, Arial, sans-serif;"
        );

        let ruleDescription = THIS.props.plugin.conditionalFormats[i]?.rule?.conditionalFormatRule;
        // if rule description is defined in text area, legend will use user input.

        let textNode = document.createTextNode(
          ruleDescription ? " " + ruleDescription  : " " + d.LeftRuleColumnName + " " + d.Operator + " " + d.RightRuleColumnName + ""
        );
        condLegendText.appendChild(textNode);

        condLegend.appendChild(condLegendMarker);
        condLegend.appendChild(condLegendMarkerBackground);
        condLegend.appendChild(condLegendText);

        showCondForm.append(condLegend);
      });
    }

    $(container).append(showCondForm);

    let exportDownloadSupport = pieContainer.exporting.downloadSupport();

    if (exportDownloadSupport) {
      let title = `${this.props.title}`;

      let exportImageOptions = pieContainer.exporting.getFormatOptions("png");
      let exportPDFOptions = pieContainer.exporting.getFormatOptions("pdf");

      // Image exporting properties
      exportImageOptions.quality = 1;
      exportImageOptions.minWidth = 4320;
      exportImageOptions.minHeight = 4320;

      // PDF exporting properties
      exportPDFOptions.quality = 1;
      exportPDFOptions.addURL = false;
      exportPDFOptions.minWidth = 4320;
      exportPDFOptions.minHeight = 4320;
      exportPDFOptions.align = "center";

      // General exporting properties
      pieContainer.exporting.title = title;
      pieContainer.exporting.filePrefix = title;
      pieContainer.exporting.useRetina = true;
      pieContainer.exporting.showTimeout = () => {};

      pieContainer.exporting.setFormatOptions("png", exportImageOptions);
      pieContainer.exporting.setFormatOptions("pdf", exportPDFOptions);

      container.PNG = () => pieContainer.exporting.export("png");
      container.PDF = () => pieContainer.exporting.export("pdf");
      container.XLSX = () => this.props.excelExport();

      /**
       * Chart PDF document handler
       */
      pieContainer.exporting.adapter.add("pdfmakeDocument", function (pdf, target) {
        let { content } = pdf.doc;
        let [title, image] = content;

        if (pdf.options.pageOrientation === "landscape") {
          image.fit[1] = image.fit[0] - 60;
          image.fit[0] = 782;
        }

        pdf.doc.header = {
          columns: [
            {
              text: `${i18n.t("Dashboard.Configuration.Fields.ExportDate")}: ${rmvpp.generateDate()}`,
              bold: false,
              margin: [0, 5, 30, 0],
              alignment: 'right'
            }
          ]
        }

        pdf.doc.content[0] = title;
        pdf.doc.content[1] = image;

        pdf.doc.footer = {
          image: vispeahenLogo,
          margin: [0, 0, 15, 5],
          alignment: 'right',
        };

        pdf.compress = false;

        return pdf;
      });

      chart.exporting.adapter.add("xlsxWorkbook", workbook => {
        if (drilldownChart) {
          let sheetName = Object.keys(workbook.workbook.Sheets)[0];
          let worksheet = workbook.workbook.Sheets[sheetName];
          
          if (reduxState.DrillDownReducer.drillDowns.has(pluginId)) {
            let drilldownData = deepCopy(drilldownChart.data);
            let drillDownsReducer = reduxState.DrillDownReducer.drillDowns.get(pluginId).drillDownColumnsForParentColumns;
            let drilldownMeasure = columnMap.measure;
            let parentCategory = selectedSlice.dataItem.dataContext.formattedCategory;
            let drilldownCategory = drillDownsReducer.has(columnMap.category.uniqeColumnId)
              ? drillDownsReducer.get(columnMap.category.uniqeColumnId)[0] 
              : drillDownsReducer.get(columnMap.category.drillDownParentColumnId)[0];
            
            worksheet["D1"] = {v: `${columnMap.category.displayName}-${drilldownCategory.displayName}`, t: "s"};
            worksheet["E1"] = {v: drilldownMeasure.displayName, t: "s"};

            /**
             * Returns a cell type according to the given data type
             * 
             * @param {*} dataType 
             * @returns 
             */
            const cellType = dataType => {
              if (["double", "number"].includes(dataType)) return "n";
              
              if (["date", "timestamp"].includes(dataType)) return "d";
              
              return "s";
            }

            let categoryType = cellType(drilldownCategory.dataType);
            let measureType = cellType(drilldownMeasure.dataType);
            
            for (let index = 0; index < drilldownData.length; index++) {
              let row = index + 2;
              let categoryValue = drilldownData[index]["formattedCategory"];
              let measureValue = drilldownData[index]["measure"];
              
              worksheet["D" + row] = { t: categoryType, v: `${parentCategory}-${categoryValue}`};
              worksheet["E" + row] = { t: measureType, v: measureValue };
            }

            worksheet["!ref"] = `A1:E${drilldownData.length + 1}`;
          }

          workbook.workbook.Sheets[sheetName] = worksheet;
        }
        let sheetName = Object.keys(workbook.workbook.Sheets)[0];
        let worksheet = workbook.workbook.Sheets[sheetName];

        let datetime = getCurrentDateTime();

        if (columnMap.category.Name) {
          worksheet["!ref"] = `A1:C${data.length + 3}`;
        } else {
          worksheet["!ref"] = `A1:B${data.length + 3}`;
        }

        worksheet["A1"] = { v: datetime, t: "s" };
        worksheet["A2"] = { v: config.title, t: "s" }; 
        
        if (!worksheet['!merges']) worksheet['!merges'] = [];

        worksheet['!merges'].push({s: {r: 0, c: 0}, e: {r: 0, c: columnMap.category.Name ? 2 : 1}});
        worksheet['!merges'].push({s: {r: 1, c: 0}, e: {r: 1, c: columnMap.category.Name ? 2 : 1}});

        worksheet["A3"] = { v: columnMap.category.Name, t: "s" };
        worksheet["B3"] = { v: columnMap.measure.Name, t: "s" };
      
        data.forEach((e,i) => {
          let cell = String.fromCharCode(65);

          worksheet[cell+""+(i+4)] = {v: e.category, t:"s"};

          cell = String.fromCharCode(66);
          worksheet[cell+""+(i+4)] = {v: e.measure, t:"s"};
        })
        
        return workbook;     
      });

      /**
       * Export only visible datafields with display name
       */
      chart.exporting.adapter.add("formatDataFields", data => {
        data.dataFields = {};

        chart.series.each(function (series) {
          data.dataFields[series.dataFields.category] = columnMap.category.displayName;

          if (series.visible) {
            data.dataFields[series.dataFields.value] = columnMap.measure.displayName;
          }
        });

        return data;
      });

      /**
       * Before the export operation handler
       */
      pieContainer.exporting.events.on("exportstarted", (event) => {
        let headerSection = document.getElementsByClassName("header-section")[0];
        let toolsSection = document.getElementsByClassName("dashboard-setting")[0];

        headerSection.style.position = "relative";
        headerSection.style.zIndex = 1000;

        toolsSection.style.position = "relative";
        toolsSection.style.zIndex = 1000;

        container.style.visibility = "hidden";
        container.style.position = "fixed";

        changePluginLoaderVisibility(pluginId, true)
        
        loadingScreen(true);

        let height = $(container).height();
        let width = $(container).width();

        let legendHeight = 0;
        let isChartPosLeftOrRight = config.legendPos === "right" || config.legendPos === "left";

        pieSeries.tooltip.disabled = true;
        pieSeries.labels.template.truncate = false;

        if (typeof pieSeries2 !== "undefined") {
          pieSeries2.tooltip.disabled = true;
          pieSeries2.labels.template.truncate = false;
        }

        let chartNode = chart.element.node;
        let chartRect = chartNode.getBoundingClientRect();
        let chartHeight = chartRect.height;
        let chartWidth = chartRect.width;

        let measuredHeight = chartHeight;
        let measuredWidth = chartWidth;

        if (drilldownChart) {
          let drilldownChartNode = drilldownChart.element.node;
          let drilldownChartRect = drilldownChartNode.getBoundingClientRect();

          let drilldownChartHeight = drilldownChartRect.height;
          let drilldownChartWidth = drilldownChartRect.width;

          measuredHeight = drilldownChartHeight > chartHeight ? drilldownChartHeight : chartHeight;
          measuredWidth += drilldownChartWidth;
        }

        if (config.legend) {
          if (chart.legend.disabled) return;

          switch (config.legendPos) {
            case "right":
              chart.legend.marginLeft = 30;
              chart.legend.marginRight = 0;
              
              break;
            case "left":
              chart.legend.marginRight = 30;
              chart.legend.marginLeft = 0;
              
              break;
            case "top":
              chart.legend.marginBottom = 0;
              chart.legend.marginTop = 30;
              
              break;
            case "bottom":
              chart.legend.marginBottom = 30;
              chart.legend.marginTop = 0;
              
              break;
            default:
              break;
          }

          legendHeight = chart.legend.contentHeight;

          if (drilldownChart) {
            if (drilldownChart.legend.contentHeight > legendHeight) legendHeight = drilldownChart.legend.contentHeight;

            drilldownChart.legend.maxHeight = "";
            drilldownChart.legend.height = legendHeight;
            drilldownChart.legend.marginBottom = chart.legend.marginBottom;
            drilldownChart.legend.marginTop = chart.legend.marginTop;
            drilldownChart.legend.marginLeft = chart.legend.marginLeft;
            drilldownChart.legend.marginRight = chart.legend.marginRight;
          }
          
          chart.legend.height = legendHeight;

          if (!isChartPosLeftOrRight && height / 2.7 < legendHeight) {
            height = legendHeight * 2.7
          } else if (isChartPosLeftOrRight) {
            if (height < legendHeight) height = legendHeight;
            
            measuredHeight = legendHeight;

            let legendWidth = chart.legend.contentWidth;
            let legendWidthRatio = drilldownChart ? 5.4 : 2.7;

            if (width / legendWidthRatio < legendWidth) {
              width = legendWidth * legendWidthRatio;
            }
          };
        }

        width = Math.max(width, measuredWidth) + 60;
        height = Math.max(height, measuredHeight) + 60;

        let radiusRef = Math.min(width, height);
        let newRadius = radiusRef / 4;

        if (drilldownChart) {
          drilldownChart.radius = newRadius * 0.7;
        }

        chart.radius = newRadius;

        if (config.innerRadius && config.innerRadius > 0) {
          chart.innerRadius = newRadius * config.innerRadius / 100;

          if (drilldownChart) drilldownChart.innerRadius = newRadius * config.innerRadius / 180;
        }
        
        height += width > height && !isChartPosLeftOrRight ? 60 : 0;
        width += height > width && isChartPosLeftOrRight ? 60 : 0;

        container.style.minHeight = height + "px";
        container.style.minWidth = width + "px";

        if (event.format === "pdf") pieContainer.exporting.getFormatOptions("pdf").pageOrientation = height > width ? "portrait" : "landscape";
      });

      /**
       * After the export operation handler
       */
      pieContainer.exporting.events.on("exportfinished", event => {
        setTimeout(() => {
          let headerSection = document.getElementsByClassName("header-section")[0];
          let toolsSection = document.getElementsByClassName("dashboard-setting")[0];

          headerSection.style.position = "";
          headerSection.style.zIndex = "";

          toolsSection.style.position = "";
          toolsSection.style.zIndex = "";

          container.style.minHeight = "";
          container.style.minWidth = "";
          container.style.position = "";
          container.style.zIndex = "";

          if (event.format !== "xlsx") {
            pieSeries.tooltip.disabled = false;

            this.setChartLegend(chart, config, pluginInlineHeight, columnMap);

            if (typeof pieSeries2 !== "undefined") {
              pieSeries2.tooltip.disabled = false;
            }

            if (typeof drilldownChart !== "undefined") {
              this.setChartLegend(drilldownChart, config, pluginInlineHeight, columnMap);
            }

            let percent = pluginWidth > 3 && pluginHeight > 3 ? 80 : 90;

            chart.radius = am4core.percent(percent);

            if (drilldownChart) drilldownChart.radius = am4core.percent(percent * 0.7);

            if (config?.innerRadius > 0) {
              chart.innerRadius = am4core.percent(percent * config.innerRadius / 100);

              if (drilldownChart) drilldownChart.innerRadius = am4core.percent((percent * 0.7) * config.innerRadius / 100);
            }
          }

          store.dispatch(changePluginLoaderVisibility(pluginId, false));
          loadingScreen(false);

          container.style.visibility = "";
        });
      });
    }

    this.props.setPluginRerender(false, pluginId, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let conditionalFormatComponent = null;
    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        this.calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    const calculatePluginInlineHeightWithConditional = (plugin) => {
      if (this.condFormatsLength === 0) {
        return calculatePluginInlineHeight(this.props.plugin.id)
      } else {
        return calculatePluginInlineHeight(this.props.plugin.id) - 20
      }
    }

    return (
      <>
        <div style={{ height: "100%" }}>
          <div id={this.props.plugin.id} style={{ height: calculatePluginInlineHeightWithConditional(this.props.plugin) }}>
          </div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            this.props.plugin.conditionalFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
          {navigationComponent}
          {conditionalFormatComponent}
        </div>
      </>
    );
  }
}
