import i18n from "./i18next";

//convert the plugin names depends on i18n
export const pluginTranslateNameSet = data => {
  let labels = [];

  data.map(d => {
    d.translateName = i18n.t("PluginsName.names." + d.key);
  
    labels.push(d);
  });

  return labels;
};

//convert the plugin group names depends on i18n
export const pluginGroupTranslateNameSet = d => {
  let labels = [];
  
  d.translateGroupName = i18n.t("PluginsName.PluginGroup." + d.group);
  
  labels.push(d);

  return d;
};

/*
* Gets all columns by plugin columnMap. Returns hash or array
*/
export const getPluginsAllColumnsByField = (columnMap, type = "array") => {
  let columnMapKeys = columnMap ? Object.keys(columnMap) : []
  let columnMapArr = []
  let columnMapHash = new Map()

  for (let i = 0; i < columnMapKeys.length; i++) {
    let columnMapField = columnMap[columnMapKeys[i]]

    if (columnMapField.data) {
      for (let j = 0; j < columnMapField.data.length; j++) {
        columnMapArr.push(columnMapField.data[j])
        columnMapHash.set(columnMapField.data[j].uniqeColumnId, columnMapField.data[j])
      }
    }
  }

  if (type === "array") {
    return columnMapArr;
  } else if (type === "hash") {
    return columnMapHash
  }
}