import { combineReducers } from "redux";
import CreateModelReducer from "../modelPage/createModel/CreateModelReducer";
import TreeModelReducer from "../components/treeModel/TreeModelReducer";
import ModelContentReducer from "../modelPage/ModelContentReducer";
import PagePopupReducer from "../../../ui/GeneralComponents/PagePopup/PagePopupReducer";
import PluginTriggerReducer from "../../../ui/Plugins/PluginTriggerReducer";
import NavigationReducer from "../../../ui/Navigation/NavigationReducer";
import DefaultPluginsReducer from "../../../ui/Plugins/DefaultPluginsStore/DefaultPluginsReducer";
import DrillDownReducer from "../../../ui/DrillDown/DrillDownReducer";
import WhatIfReducer from "../../../ui/DashboardPage/DashboardCrud/DashboardTools/WhatIf/WhatIfReducer";
import LoginPopupReducer from "../../../ui/GeneralComponents/LoginPopup/LoginPopupReducer";
import FormulaEditorReducer from "../../../ui/GeneralComponents/FormulaEditor/FormulaEditorReducer";
import PluginLoaderReducer from "../../../ui/GeneralComponents/PluginLoader/PluginLoaderReducer";
import FilterAutoInteractionReducer from "../../../ui/Interaction/FilterAutoInteractionReducer";
import ContextMenuReducer from "../../../ui/GeneralComponents/ContextMenu/ContextMenuReducer";
import FileUploadReducer from "../../../ui/FileUpload/FileUploadReducer";
import CustomDashboardReducer from "../../../ui/GeneralComponents/CustomDashboard/CustomDashboardReducer";
import SettingNoDataTitleReducer from "../../../ui/DashboardPage/DashboardCrud/DashboardTools/Settings/SettingList/SettingNoDataTitleReducer";
import MapChoroplethDetailReducer from "../../../ui/Plugins/PluginComponents/MapChoropleth/Redux/MapChoroplethDetailReducer"
import CacheModeReducer from "../../../ui/CacheMode/CacheModeReducer"
import SelfServiceReducer from "../../../ui/Datasources/SelfServiceReducer/SelfServiceReducer"
import OpenPluginListReducer from "../../../ui/Plugins/PluginListReducer/OpenPluginListReducer"
import SessionVariableReducer from "../../../ui/GeneralComponents/SessionVariable/SessionVariableReducer";
import NewColumnEditorReducer from "../../../ui/GeneralComponents/NewColumn/NewColumnEditorReducer";

export default combineReducers({
  CreateModelReducer,
  TreeModelReducer,
  ModelContentReducer,
  PagePopupReducer,
  PluginTriggerReducer,
  NavigationReducer,
  DefaultPluginsReducer,
  DrillDownReducer,
  WhatIfReducer,
  LoginPopupReducer,
  FormulaEditorReducer,
  PluginLoaderReducer,
  FilterAutoInteractionReducer,
  ContextMenuReducer,
  FileUploadReducer,
  CustomDashboardReducer,
  SettingNoDataTitleReducer,
  CacheModeReducer,
  MapChoroplethDetailReducer,
  SelfServiceReducer,
  OpenPluginListReducer,
  NewColumnEditorReducer,
  SessionVariableReducer
});
