import React, { Component } from "react";
import $ from "jquery";
import i18n from "../../../../Utils/i18next";
import TimelineChartConfiguration from "./TimelineChartConfiguration"
import TimelineChartData from "./TimelineChartData"
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  getContrastColor
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData, renderConditionalFormatting } from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import { InsightsConfig } from "../../RenderJs/config";
import { calculatePluginInlineHeight } from "../../../../../src/ui/DrillDown/PluginHeightWithDrilldown"
import { compare } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { shortMonths, longMonths, bigNumberPrefixes, decimalSeperators, thousandSeperators } from "../../../GeneralComponents/PublicSortItems";
import { store } from "../../../..";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

am4core.useTheme(am4themes_animated);

const condFormats = [];
const filters = [];
const pluginName = "timeline-chart";
const config = {
  "showHideButton": false,
  "colours": "Flat-UI",
  "toggleCriteria": "",
  "title": "",
  "summary": "",
  "backgroundColor": "rgb(255,255,255)",
  "refresh": 0,
  "xZoom": true,
  "yZoom": false,
  "xZoomAlignment": "bottom",
  "yZoomAlignment": "right",
  "singleAxis": false,
  "areaChart": true,
  "continuousChart": false,
  "smoothedLines": true,
  "navigator": true,
  "bullets": true,
  "legend": true,
  "groupData": true,
  "groupCount": "200",
  "groupAggrRule": "average",
  "showTargets": false,
  "fillTargets": false,
  "targets": [{
    name: "",
    value: "",
    color: "red"
  }]
};
const columnMap = {
  "date": {
    "name": "tarih",
    "aliasName": "tarih",
    "displayName": "DATE",
    "description": null,
    "nullable": true,
    "dataType": "timestamp",
    "aggregatable": false,
    "aggrRule": null,
    "hidden": false,
    "columnType": null,
    "isEnable": true,
    "duplicated": false,
    "doubleColumn": false,
    "windowFunction": false,
    "tableAliasName": "tablotest",
    "dataSourceKey": "jojyew_ycywupwt.public",
    "tableDisplayName": "tablotest",
    "uniqeColumnId": "4d15cd4-51b7-8dfa-50b8-4b6dfdc6d867",
    "locationFieldName": "date",
    "sortDir": "ASC",
    "orderBy": {
      "direction": "ASC",
      "order": 1,
      "columnIndex": 1
    },
    "Measure": "none",
    "Locale": "EN",
    "DataType": "timestamp",
    "Name": "tarih",
    "Code": "\"tablotest\".\"tarih\"",
    "Type": "Column",
    "DataFormat": "%d/%m/%Y %H:%M:%S"
  },
  "measure": [
    {
      "name": "deger",
      "aliasName": "deger",
      "displayName": "VALUE",
      "description": null,
      "nullable": true,
      "dataType": "double",
      "aggregatable": true,
      "aggrRule": "sum(`tablotest`.`deger`)",
      "hidden": false,
      "columnType": null,
      "isEnable": true,
      "duplicated": false,
      "doubleColumn": false,
      "windowFunction": false,
      "tableAliasName": "tablotest",
      "dataSourceKey": "jojyew_ycywupwt.public",
      "tableDisplayName": "tablotest",
      "uniqeColumnId": "00000-00000-00000-00000",
      "locationFieldName": "measure",
      "orderBy": null,
      "multiple": true,
      "Measure": "sum(`tablotest`.`deger`)",
      "Locale": "EN",
      "DataType": "double",
      "Name": "deger",
      "Config": {
        "Plugin": "pivot-table",
        "aggregator": "Sum",
        "hide": false
      },
      "Code": "\"tablotest\".\"deger\"",
      "Type": "Column",
      "DataFormat": ".3s"
    },
    {
      "name": "age",
      "aliasName": "age",
      "displayName": "AGE",
      "description": null,
      "nullable": true,
      "dataType": "double",
      "aggregatable": true,
      "aggrRule": "sum(`tablotest`.`age`)",
      "hidden": false,
      "columnType": null,
      "isEnable": true,
      "duplicated": false,
      "doubleColumn": false,
      "windowFunction": false,
      "tableAliasName": "tablotest",
      "dataSourceKey": "jojyew_ycywupwt.public",
      "tableDisplayName": "tablotest",
      "uniqeColumnId": "11111-11111-11111-11111",
      "locationFieldName": "measure",
      "orderBy": null,
      "multiple": true,
      "Measure": "sum(`tablotest`.`age`)",
      "Locale": "EN",
      "DataType": "double",
      "Name": "age",
      "Config": {
        "Plugin": "pivot-table",
        "aggregator": "Sum",
        "hide": false
      },
      "Code": "\"tablotest\".\"age\"",
      "Type": "Column",
      "DataFormat": ".3s"
    }
  ]
};
const data = [
  {
    "VALUE": "63921.0",
    "AGE": "24",
    "date": "2012-04-19 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 63921
      },
      {
        "name": "AGE",
        "value": 24
      }
    ],
  },
  {
    "VALUE": "55337.0",
    "AGE": "61",
    "date": "2012-04-20 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 55337
      },
      {
        "name": "AGE",
        "value": 61
      }
    ],
  },
  {
    "VALUE": "39245.0",
    "AGE": "79",
    "date": "2012-04-21 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 39245
      },
      {
        "name": "AGE",
        "value": 79
      }
    ],
  },
  {
    "VALUE": "44676.0",
    "AGE": "28",
    "date": "2012-04-22 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 44676
      },
      {
        "name": "AGE",
        "value": 28
      }
    ],
  },
  {
    "VALUE": "77050.0",
    "AGE": "44",
    "date": "2012-04-23 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 77050
      },
      {
        "name": "AGE",
        "value": 44
      }
    ],
  },
  {
    "VALUE": "97989.0",
    "AGE": "30",
    "date": "2012-04-24 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 97989
      },
      {
        "name": "AGE",
        "value": 30
      }
    ],
  },
  {
    "VALUE": "78281.0",
    "AGE": "31",
    "date": "2012-04-25 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 78281
      },
      {
        "name": "AGE",
        "value": 31
      }
    ],
  },
  {
    "VALUE": "6142.0",
    "AGE": "14",
    "date": "2012-04-26 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 6142
      },
      {
        "name": "AGE",
        "value": 14
      }
    ],
  },
  {
    "VALUE": "81808.0",
    "AGE": "32",
    "date": "2012-04-27 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 81808
      },
      {
        "name": "AGE",
        "value": 32
      }
    ],
  },
  {
    "VALUE": "70225.0",
    "AGE": "24",
    "date": "2012-04-28 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 70225
      },
      {
        "name": "AGE",
        "value": 24
      }
    ],
  },
  {
    "VALUE": "51837.0",
    "AGE": "12",
    "date": "2012-04-29 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 51837
      },
      {
        "name": "AGE",
        "value": 12
      }
    ],
  },
  {
    "VALUE": "93053.0",
    "AGE": "100",
    "date": "2012-04-30 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 93053
      },
      {
        "name": "AGE",
        "value": 100
      }
    ],
  },
  {
    "VALUE": "83992.0",
    "AGE": "16",
    "date": "2012-05-01 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 83992
      },
      {
        "name": "AGE",
        "value": 16
      }
    ],
  },
  {
    "VALUE": "91314.0",
    "AGE": "31",
    "date": "2012-05-02 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 91314
      },
      {
        "name": "AGE",
        "value": 31
      }
    ],
  },
  {
    "VALUE": "76916.0",
    "AGE": "83",
    "date": "2012-05-03 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 76916
      },
      {
        "name": "AGE",
        "value": 83
      }
    ],
  },
  {
    "VALUE": "27208.0",
    "AGE": "95",
    "date": "2012-05-04 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 27208
      },
      {
        "name": "AGE",
        "value": 95
      }
    ],
  },
  {
    "VALUE": "51163.0",
    "AGE": "38",
    "date": "2012-05-05 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 51163
      },
      {
        "name": "AGE",
        "value": 38
      }
    ],
  },
  {
    "VALUE": "88282.0",
    "AGE": "87",
    "date": "2012-05-06 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 88282
      },
      {
        "name": "AGE",
        "value": 87
      }
    ],
  },
  {
    "VALUE": "47189.0",
    "AGE": "81",
    "date": "2012-05-07 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 47189
      },
      {
        "name": "AGE",
        "value": 81
      }
    ],
  },
  {
    "VALUE": "42491.0",
    "AGE": "69",
    "date": "2012-05-08 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 42491
      },
      {
        "name": "AGE",
        "value": 69
      }
    ],
  },
  {
    "VALUE": "55908.0",
    "AGE": "12",
    "date": "2012-05-09 00:00:00.0",
    "measure": [
      {
        "name": "VALUE",
        "value": 55908
      },
      {
        "name": "AGE",
        "value": 12
      }
    ],
  }
];
const configurationParameters = [
  {
    targetProperty: "singleAxis",
    label: "SingleAxis",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc162"
  },
  {
    targetProperty: "groupAggrRule",
    label: "GroupAggrRule",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "average"
    },
    desc: "desc162"
  },
  {
    targetProperty: "groupCount",
    label: "GroupCount",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "200"
    },
    desc: "desc162"
  },
  {
    targetProperty: "groupData",
    label: "GroupData",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "fillTargets",
    label: "FillTargets",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc162"
  },
  {
    targetProperty: "showTargets",
    label: "ShowTargets",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc162"
  },
  {
    targetProperty: "targets",
    label: "Targets",
    inputType: "textbox",
    inputOptions: {
      defaultValue: [{
        name: "",
        value: "",
        color: "red"
      }]
    },
    desc: "desc162"
  },
  {
    targetProperty: "smoothedLines",
    label: "SmoothedLines",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "legend",
    label: "Legend",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "bullets",
    label: "Bullets",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "navigator",
    label: "Navigator",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "xZoom",
    label: "ZoomX",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "yZoom",
    label: "ZoomY",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc162"
  },
  {
    targetProperty: "xZoomAlignment",
    label: "ZoomXAlignment",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "bottom"
    },
    desc: "desc162"
  },
  {
    targetProperty: "yZoomAlignment",
    label: "ZoomYAlignment",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "right"
    },
    desc: "desc162"
  },
  {
    targetProperty: "areaChart",
    label: "AreaChart",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "continuousChart",
    label: "ContinuousChart",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc162"
  },
  {
    targetProperty: "condFormat",
    label: "CondFormat",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: {
      defaultValue: "Flat-UI"
    },
    desc: "desc208"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "selectMultiple",
    label: "Select Multiple",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc230"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  }
];
const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];
const actions = [
  {
    trigger: "clickSlice",
    type: "click",
    name: "Click Slice",
    output: ["date"],
    description: "ActionClickDesc"
  },
]

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const pluginConditionalFormatOptions = {
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe"
  }
};

const conditionalFormatColumnMap = new Set(["date", "measure"]);
const conditionalFormatTargetMap = new Set(["measure"])

/**
 * Timeline chart plugin component
 */
export default class TimelineChart extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      defaultConditionalFormatColumn: {},
      isLockedTargetValue: true,
    })

    this.rerenderProcessStarted = false;
    this.callBackObject = {};
    this.drillDowns = {};
    this.minMeasure = {};
    this.maxMeasure = {};
    this.defaultLineColors = {};
    this.measures = columnMap.measure;
  }

  calculatePluginHeight = (plugin, settings) => {
    let pluginHeight = settings.grid.rowHeight * plugin.h; // multiply plugin.height and grid's row height
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginMinHeightDifference = 30;
    let maxHeight =
      pluginHeight -
      (pluginMinHeightDifference +
        pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    this.drillDowns = this.props.plugin.drillDowns;

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  /**
 * 
 * @param {*} e 
 * @returns 
 * 
 * According to the incoming event, it finds the clicked column and returns it.
 */
  getClickedColumn = (columnMap) => {
    return [columnMap["date"]];
  };

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );

    if (this.props.plugin.drillDowns !== nextProps.plugin.drillDowns) {
      this.drillDowns = this.props.plugin.drillDowns;
    }
  }

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: "Plugins.timeline-chart.ColumnMap.Hidden.Desc",
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: "Plugins.timeline-chart.ColumnMap.Hidden.Name",
      }
    }

    return (
      <TimelineChartData
        plugin={props.plugin}
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        refreshPlugin={props.refreshPlugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <TimelineChartConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig={props.setCurrentAppliedConfig}
          currentAppliedConfig={props.currentAppliedConfig}
          measures={this.measures}
          minMeasure={this.minMeasure}
          maxMeasure={this.maxMeasure}
          measureColors={this.defaultLineColors}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  /*
  * Converts columnMap with data object for conditional format component
  */
  convertColumnMapForConditionalFormat = (cmMap) => {
    let newColumnMap = []
    let reduxState = store.getState()
    let date = cmMap.date.data.length > 0 ? cmMap.date.data : null

    if (cmMap.date.data.length > 0) {
      for (let data of cmMap.date.data) {
        newColumnMap.push(data);
      }
    }

    if (cmMap.measure.data.length > 0) {
      for (let data of cmMap.measure.data) {
        newColumnMap.push(data);
      }
    }

    if (reduxState.DrillDownReducer.drillDowns.has(this.props.plugin.id) && date) {
      let drillDownsReducer = reduxState.DrillDownReducer.drillDowns.get(this.props.plugin.id).drillDownColumnsForParentColumns;
      let drillCol = drillDownsReducer.has(date.uniqeColumnId)
        ? drillDownsReducer.get(date.uniqeColumnId)[0]
        : date.drillDownParentColumnId && drillDownsReducer.has(date.drillDownParentColumnId)
          ? drillDownsReducer.get(date.drillDownParentColumnId)[0]
          : null

      if (drillCol) {
        newColumnMap.push(drillCol)
      }
    }

    return newColumnMap
  }

  getConditionalFormattingComponent = props => {
    let columnMap = props.plugin.columnMap

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        pluginId={props.plugin.id}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
        isNecessaryAllColumns={this.isNecessaryAllColumns}
        defaultConditionalFormatColumn={this.state.defaultConditionalFormatColumn}
        isLockedTargetValue={this.state.isLockedTargetValue}
        columnMapWithDrills={this.convertColumnMapForConditionalFormat(columnMap)}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      date: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Date.Name"),
        type: "dim",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Date.Desc"),
        required: true,
        data: []
      },
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        multiple: true,
        minimumColumnSize: 1,
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        required: true,
        conditionalFormat: true,
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        type: "hidden",
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  convertFormatConditionalFormatting = (condFormats, measure, date) => {
    let condFormatList = [];
    let columnsMap = new Map();
    let columnForConditionalFormatting = [...measure];

    columnForConditionalFormatting.push(date);

    columnForConditionalFormatting.map(column => {
      columnsMap.set(column.uniqeColumnId, column);
    });

    for (let condItem of condFormats) {
      for (let targetColumn of condItem.targetColumns) {
        let conditionalFormat = {};
        let controlTargetIsAllColumns = targetColumn.TargetName === "AllColumns" ? true : false;

        if (!controlTargetIsAllColumns && !columnsMap.get(targetColumn.uniqeColumnId)) continue;

        conditionalFormat.RightRule = condItem.rule.rightRule.rule;
        conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
        conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName;
        conditionalFormat.RightRuleColumnName = condItem.rule.rightRule.ruleColumnName;
        conditionalFormat.Columns = columnsMap;
        conditionalFormat.TargetName = controlTargetIsAllColumns ? targetColumn.TargetName : columnsMap.get(targetColumn.uniqeColumnId).displayName;
        conditionalFormat.TargetAliasName = controlTargetIsAllColumns ? targetColumn.TargetName : columnsMap.get(targetColumn.uniqeColumnId).aliasName;
        conditionalFormat.LocationFieldName = targetColumn.locationFieldName;
        conditionalFormat.Operator = condItem.rule.operator;
        conditionalFormat.id = condItem.id;

        conditionalFormat.Style = {
          background: { colour: condItem.options.backgroundColor },
          textColor: condItem.options.color
        };

        condFormatList.push(conditionalFormat);
      }
    }

    return condFormatList;
  };

  condFormatsLength;

  // Converts the given data format to a chart supported format
  convertNumberFormat = (dataFormat) => {
    dataFormat = dataFormat.toLowerCase();

    let isDataFormatDotNumberS = dataFormat[0] === "." && dataFormat[dataFormat.length - 1] === "s";
    let isDataFormatDotCommaNumberS = dataFormat[0] === "," && dataFormat[1] === "." && dataFormat[dataFormat.length - 1] === "s";

    let isDataFormatDotNumberF = dataFormat[0] === "." && dataFormat[dataFormat.length - 1] === "f";
    let isDataFormatDotCommaNumberF = dataFormat[0] === "," && dataFormat[1] === "." && dataFormat[dataFormat.length - 1] === "f";

    if (dataFormat !== ".s" && isDataFormatDotNumberS) {
      // for .Ns
      return "#.0a";
    } else if (isDataFormatDotCommaNumberF) {
      // for ,.Nf
      let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("f"))

      if (!isNaN((Number(subString)))) {
        let zeroCount = parseInt(subString);
        let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

        return "#,###." + zeroArr;
      }
    } else if (isDataFormatDotCommaNumberS) {
      // for ,.Ns
      let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("s"));

      if (!isNaN(Number(subString))) {
        let zeroCount = parseInt(subString);
        let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

        return `#,###.${zeroArr}a`;
      }
    } else if (dataFormat === ".f") {
      //for .f
      return "#.";
    } else if (dataFormat !== ".f" && isDataFormatDotNumberF) {
      // for .Nf
      let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("f"));

      if (!isNaN(Number(subString))) {
        let zeroCount = parseInt(subString);
        let zeroArr = Array(zeroCount + 1).join("0")

        return "#." + zeroArr;
      }
    }

    return "#.";
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {    
    var THIS = this
    
    // Set chart color theme
    const am4themes_myTheme = target => {
      if (target instanceof am4core.ColorSet) {
        let paletteColours = 
          (Array.isArray(THIS.props.plugin.config.colours) && THIS.props.plugin.config.colours) || 
          InsightsConfig.Palettes[THIS.props.plugin.config.colours] ||
          InsightsConfig.Palettes[THIS.props.plugin.config.paletteColours] ||
          (Array.isArray(THIS.props.plugin.config.paletteColours) && THIS.props.plugin.config.paletteColours)

        target.list = paletteColours?.map(colour => {
          return am4core.color(colour)
        })
      }
    }

    // Set chart color theme
    am4core.useTheme(am4themes_myTheme);

    let showCondForm = $('<div id="showCondForm" style="display:flex; position:relative; bottom:5px; flex-wrap:wrap;"></div>')
    this.condFormatsLength = condFormats ? condFormats.length : 0

    /*
    * Converts data for conditional format
    */
    const convertDataToColumnMap = (data) => {
      let newData = {};

      newData[columnMap.date.displayName] = data.date;

      for (let column of data.measure) {
        newData[column.name] = column.value;
      }

      return newData;
    }

    if (columnMap.measure.length > 0) {
      let isMeasureNotUndefined = typeof columnMap.measure !== "undefined";
      let isMeasureNotNull = columnMap.measure !== null;
      let isFirstColumnHasUniqueId = columnMap.measure[0]?.uniqeColumnId ? true : false;
      let isColumnsReady = isMeasureNotUndefined && isMeasureNotNull && isFirstColumnHasUniqueId ? true : false;

      /* If columns has one column, target column locked. */
      if (isColumnsReady) {
        this.measures = columnMap.measure;

        if (columnMap.measure.length === 1) {
          this.setState({
            defaultConditionalFormatColumn: columnMap.measure[0],
            isLockedTargetValue: true
          })
        } else if (columnMap.measure.length >= 2) {
          this.setState({
            defaultConditionalFormatColumn: [],
            isLockedTargetValue: false
          })
        }
      }
    }

    if (condFormats !== undefined) {
      condFormats = this.convertFormatConditionalFormatting(
        condFormats,
        columnMap.measure,
        columnMap.date
      );
      this.setState({ condFormats })
    } else {
      condFormats = [];
    }

    let timelineContainer = am4core.create(this.props.plugin.id, am4core.Container);

    timelineContainer.width = am4core.percent(100);
    timelineContainer.height = am4core.percent(100);

    let container = $("#" + divId)[0];
    let chart = timelineContainer.createChild(am4charts.XYChart);

    // Disable amCharts icon
    $(container).find("[shape-rendering='auto']").parent().parent().css("display", "none");
    $(container).addClass("chartDiv");

    chart.width = am4core.percent(100);
    chart.height = am4core.percent(100);

    chart.modal.events.on("opened", e => {
      chart.closeModal();
    });

    // Chart formats
    chart.dateFormatter.monthsShort = shortMonths
    chart.dateFormatter.months = longMonths
    chart.numberFormatter.bigNumberPrefixes = bigNumberPrefixes
    chart.language.locale["_decimalSeparator"] = decimalSeperators;
    chart.language.locale["_thousandSeparator"] = thousandSeperators;

    // Set default line and text colors by selected color palette
    for (let index = 0; index < columnMap.measure.length; index++) {
      let color;
      let column = columnMap.measure[index];

      if (index < chart.colors.list.length) {
        color = chart.colors.list[index];
      } else {
        color = chart.colors.generate(1);
      }

      this.defaultLineColors[`colorOf${column.uniqeColumnId}`] = color;
    }

    let availableTargets = config.targets.filter(target => {
      let { name, value, color } = target;

      let isNameValid = name && name.length > 0;
      let isValueValid = value !== "" && !isNaN(Number(value));
      let isColorValid = color && color.length > 0;

      return isValueValid && isNameValid && isColorValid;
    });

    for (let target of availableTargets) {
      target.value = Number(target.value)
    }

    let sortedPositiveTargets = availableTargets.filter(target => target.value >= 0);
    let sortedNegativeTargets = availableTargets.filter(target => target.value < 0);

    sortedPositiveTargets = sortedPositiveTargets.sort((target1, target2) => target2.value - target1.value);

    for (let index = 0; index < sortedPositiveTargets.length; index++) {
      sortedPositiveTargets[index].endValue = sortedPositiveTargets[index + 1] ? Number(sortedPositiveTargets[index + 1].value) : 0;
    }

    sortedNegativeTargets = sortedNegativeTargets.sort((target1, target2) => target2.value - target1.value);

    for (let index = sortedNegativeTargets.length - 1; index >= 0; index--) {
      sortedNegativeTargets[index].endValue = sortedNegativeTargets[index - 1] ? Number(sortedNegativeTargets[index - 1].value) : 0;
    }

    let sortedTargets = [...sortedPositiveTargets, ...sortedNegativeTargets];

    // Set chart data with cond formats and data formats
    chart.data = data.map(d => {
      d = { ...d, ...this.defaultLineColors }

      d.id = data.indexOf(d);
      d.date = new Date(d.date);

      if (condFormats?.length   ) {
        for (let condFormat of condFormats) {
          let convertedData = convertDataToColumnMap(d)
          let comparedData = compare(convertedData, condFormat)

          if (comparedData.status === true) {
            let color = am4core.color(condFormat.Style.background.colour);

            if (condFormat.TargetName === "AllColumns") {
              for (let column of columnMap.measure) {
                d[`colorOf${column.uniqeColumnId}`] = color;
              }
            } else {
              d[`colorOf${condFormat.TargetName}`] = color;
            }
          }
        }
      }

      return d;
    });

    // Background color
    chart.background.fill = config.backgroundColor;

    let scrollbarForeground = am4core.color("#ccc");
    let scrollbarBackground = am4core.color("#ddd");

    // Enable Y Zoom
    if (config.yZoom) {
      let scrollbarY = new am4core.Scrollbar();

      // Scrollbar width
      scrollbarY.minWidth = 5;

      // Disable grip icons
      scrollbarY.startGrip.icon.disabled = true;
      scrollbarY.endGrip.icon.disabled = true;

      // Scrollbar colors
      scrollbarY.startGrip.background.fill = scrollbarForeground;
      scrollbarY.endGrip.background.fill = scrollbarForeground;
      scrollbarY.thumb.background.fill = scrollbarForeground;
      scrollbarY.background.fill = scrollbarBackground;

      // Set scrollbar
      chart.scrollbarY = scrollbarY;

      // Y Zoom Position (left or right)
      chart.scrollbarY.parent = config.yZoomAlignment === "left" ? chart.leftAxesContainer : chart.rightAxesContainer;
    }

    // Enable X Zoom
    if (config.xZoom) {
      let scrollbarX;

      if (config.navigator) {
        // Navigator Zoom
        scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.background.fill = scrollbarBackground;
        scrollbarX.thumb.background.fill = scrollbarForeground;

        // Auto height (max: 60, min: 10)
        scrollbarX.minHeight = config.height >= 80 ? config.height / 8 < 56 ? config.height / 8 : 56 : 8;
      } else {
        // Normal Zoom
        scrollbarX = new am4core.Scrollbar();
        scrollbarX.minHeight = 5;
        scrollbarX.thumb.background.fill = scrollbarForeground;
        scrollbarX.background.fill = scrollbarBackground;
      }

      // Disable grip icons
      scrollbarX.startGrip.icon.disabled = true;
      scrollbarX.endGrip.icon.disabled = true;

      // Grip colors
      scrollbarX.startGrip.background.fill = scrollbarForeground;
      scrollbarX.endGrip.background.fill = scrollbarForeground;

      // Set scrollbar
      chart.scrollbarX = scrollbarX;

      // X Zoom Position (top or bottom)
      chart.scrollbarX.parent = config.xZoomAlignment === "top" ? chart.topAxesContainer : chart.bottomAxesContainer;
    }

    // Create date axis (x)
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    // Date axis properties
    dateAxis.fontSize = 12;
    dateAxis.renderer.labels.template.fill = this.getContrastColor(chart.background.fill.rgb);
    dateAxis.renderer.grid.template.stroke = this.getContrastColor(chart.background.fill.rgb);
    // dateAxis.skipEmptyPeriods = true;

    /**
     * Grouping data items
     * 
     * If day count is more than groupCount, group data by weeks,
     * If week count is still more than groupCount, group by months,
     * If month count is still more than groupCount, group by years.
     */
    if (config.groupData) {
      dateAxis.groupData = config.groupData;
      dateAxis.groupCount = config.groupCount;
    }

    // Date axis tooltip properties
    let dateAxisTooltipColor = am4core.color(this.getContrastColor(chart.background.fill.rgb));
    let dateAxisTooltipTextColor = am4core.color(this.getContrastColor(dateAxisTooltipColor.rgb));

    dateAxis.tooltip.fontSize = 12;
    dateAxis.tooltip.label.textAlign = "left"
    dateAxis.tooltip.label.fill = dateAxisTooltipTextColor;
    dateAxis.tooltip.background.fill = dateAxisTooltipColor;
    dateAxis.tooltip.background.strokeWidth = 1;
    dateAxis.tooltip.background.strokeOpacity = 0.2;
    dateAxis.tooltip.background.cornerRadius = 3;
    dateAxis.tooltip.background.fillOpacity = 0.8;

    // Date axis formats
    let dateFormat = columnMap.date.DataFormat ? columnMap.date.DataFormat : "%d/%m/%Y %H:%M:%S";
    let seperators = ["-", ":", "/", ".", "_", " ", ""]

    let selectedDateFormat = "%d/%m/%Y";
    let selectedDateSeperator = "/"

    let selectedTimeFormat = "%H:%M:%S";
    let selectedTimeSeperator = ":"

    let defaultDateFormats = [
      "%d/%m/%Y",
      "%m/%d/%Y",
      "%Y/%m/%d",
      "%Y/%d/%m",
    ];
    let defaultTimeFormats = [
      "%H:%M:%S",
      "%S:%M:%H",
    ];

    // Get selected date format
    for (let format of defaultDateFormats) {
      if (dateFormat.includes(format)) {
        selectedDateFormat = format;

        break;
      }

      for (let seperator of seperators) {
        let result = format.replaceAll("/", seperator);

        if (dateFormat.includes(result)) {
          selectedDateFormat = result;
          selectedDateSeperator = seperator;

          break;
        }
      }
    }

    // Get selected time format
    for (let format of defaultTimeFormats) {
      if (dateFormat.includes(format)) {
        selectedTimeFormat = format;

        break;
      }

      for (let seperator of seperators) {
        let result = format.replaceAll(":", seperator);

        if (dateFormat.includes(result)) {
          selectedTimeFormat = result;
          selectedTimeSeperator = seperator;

          break;
        }
      }
    }

    let seperatorBetweenDateAndTime = dateFormat;
    seperatorBetweenDateAndTime = seperatorBetweenDateAndTime.replace(selectedDateFormat, "");
    seperatorBetweenDateAndTime = seperatorBetweenDateAndTime.replace(selectedTimeFormat, "");

    let firstFormatPart = (dateFormat.includes(selectedDateFormat + seperatorBetweenDateAndTime)
      ? selectedDateFormat
      : selectedTimeFormat
    );

    let formatUnits = {
      day: "dd",
      month: "MM",
      year: "yyyy",
      hour: "HH",
      minute: "mm",
      second: "ss"
    };

    let parsedDateFormat = dateFormat.split(/[_\-./: ]/);
    let dateIndex = {
      day: parsedDateFormat.indexOf("%d"),
      month: parsedDateFormat.indexOf("%m"),
      year: parsedDateFormat.indexOf("%Y"),
      hour: parsedDateFormat.indexOf("%H"),
      minute: parsedDateFormat.indexOf("%M"),
      second: parsedDateFormat.indexOf("%S")
    };

    const getFormat = (keys = [], seperator = "") => {
      let parts = [];

      for (let key of Object.keys(dateIndex)) {
        if (keys.includes(key) && dateIndex[key] !== -1) {
          parts.push({
            format: formatUnits[key],
            index: dateIndex[key]
          })
        }
      }

      parts = parts.sort((p1, p2) => p1.index - p2.index);

      let format = parts.map(part => part.format).join(seperator);

      return format;
    }

    let year = getFormat(["year"]);
    let month = getFormat(["month", "year"], selectedDateSeperator);
    let week = getFormat(["day", "month", "year"], selectedDateSeperator);
    let day = getFormat(["day", "month", "year"], selectedDateSeperator);
    let hour = (
      firstFormatPart === selectedDateFormat
        ? day + seperatorBetweenDateAndTime + getFormat(["hour", "minute"], selectedTimeSeperator)
        : getFormat(["hour", "minute"], selectedTimeSeperator) + seperatorBetweenDateAndTime + day
    );
    let minute = (
      firstFormatPart === selectedDateFormat
        ? day + seperatorBetweenDateAndTime + getFormat(["hour", "minute"], selectedTimeSeperator)
        : getFormat(["hour", "minute"], selectedTimeSeperator) + seperatorBetweenDateAndTime + day
    );
    let second = (
      firstFormatPart === selectedDateFormat
        ? day + seperatorBetweenDateAndTime + getFormat(["hour", "minute", "second"], selectedTimeSeperator)
        : getFormat(["hour", "minute", "second"], selectedTimeSeperator) + seperatorBetweenDateAndTime + day
    );

    // Date axis formats
    dateAxis.dateFormats.setKey("second", second);
    dateAxis.dateFormats.setKey("minute", minute);
    dateAxis.dateFormats.setKey("hour", hour);
    dateAxis.dateFormats.setKey("day", day);
    dateAxis.dateFormats.setKey("week", week);
    dateAxis.dateFormats.setKey("month", month);
    dateAxis.dateFormats.setKey("year", year);

    // Date axis periodic formats
    dateAxis.periodChangeDateFormats.setKey("second", second);
    dateAxis.periodChangeDateFormats.setKey("minute", minute);
    dateAxis.periodChangeDateFormats.setKey("hour", hour);
    dateAxis.periodChangeDateFormats.setKey("year", year);
    dateAxis.periodChangeDateFormats.setKey("month", month);
    dateAxis.periodChangeDateFormats.setKey("week", week);
    dateAxis.periodChangeDateFormats.setKey("day", day);

    let measures = chart.data.flatMap(item => item.measure);

    if (measures.length > 0) {
      if (config.singleAxis || this.measures.length === 1) {
        let id = config.singleAxis === true ? "single" : this.measures[0].uniqeColumnId;

        let measureValues = new Set(measures.map(measure => measure.value));
        let sortedMeasures = Array.from(measureValues).sort((a, b) => a - b);

        let minMeasure = sortedMeasures[0];
        let maxMeasure = sortedMeasures[sortedMeasures.length - 1];

        let minTarget = sortedTargets.length > 0 ? sortedTargets[sortedTargets.length - 1].value : undefined;
        let maxTarget = sortedTargets.length > 0 ? sortedTargets[0].value : undefined;

        this.minMeasure[id] = config.showTargets && minTarget !== undefined ? Math.min(minMeasure, minTarget) : minMeasure;
        this.maxMeasure[id] = config.showTargets && maxTarget !== undefined ? Math.max(maxMeasure, maxTarget) : maxMeasure;
      } else {
        let axes = {};

        for (let measure of measures) {
          let columns = this.measures.filter(c => c.displayName === measure.name);

          for (let column of columns) {
            axes[column.uniqeColumnId] = axes[column.uniqeColumnId] || new Set();
            axes[column.uniqeColumnId].add(measure.value);
          }
        }

        for (let axis of Object.keys(axes)) {
          let values = Array.from(axes[axis]);

          this.minMeasure[axis] = Math.min(...values);
          this.maxMeasure[axis] = Math.max(...values);
        }
      }
    }

    /**
     * Sets the given Axis min max values
     * 
     * @param {*} axis 
     * @param {*} config 
     */
    const setAxisMinMax = (axis, config, id) => {
      if (!axis) return;

      let maxMeasure = this.maxMeasure[id];
      let minMeasure = this.minMeasure[id];

      let isMinValid = false;
      let isMaxValid = false;
      let min, max;

      let axisMinMax = config?.axisMinMax?.[id];

      if (axisMinMax) {
        min = axisMinMax.min;
        max = axisMinMax.max;

        isMinValid = typeof min !== "undefined" && min.length > 0 && isNaN(Number(min)) === false;
        isMaxValid = typeof max !== "undefined" && max.length > 0 && isNaN(Number(max)) === false;

        min = Number(min);
        max = Number(max);

        if (config.showTargets && availableTargets.length > 0) {
          isMinValid = isMinValid && min >= maxMeasure ? max > min : isMinValid;
          isMaxValid = isMaxValid && max <= minMeasure ? min < max : isMaxValid;
        } else {
          isMinValid = isMinValid && min >= maxMeasure ? max > min : isMinValid;
          isMaxValid = isMaxValid && max <= minMeasure ? min < max : isMaxValid;
        }
      }

      axis.min = isMinValid
        ? min >= maxMeasure ? max > min ? min : minMeasure : min
        : minMeasure;

      axis.max = isMaxValid
        ? max <= minMeasure ? min < max ? max : maxMeasure : max
        : maxMeasure;
    }

    /**
     * Creating Measure Axis
     * 
     * If single axis feature is activated,
     * All lines connet to ONE measure axis.
     */
    let singleMeasureAxis;
    if (config.singleAxis) {
      singleMeasureAxis = chart.yAxes.push(new am4charts.ValueAxis());

      if (config.strictMinMax === true) setAxisMinMax(singleMeasureAxis, config, "single");

      let color = am4core.color(this.getContrastColor(chart.background.fill.rgb));
      let textColor = am4core.color(this.getContrastColor(color.rgb));

      // Axis properties
      singleMeasureAxis.renderer.line.stroke = color;
      singleMeasureAxis.tooltip.label.fill = textColor;
      singleMeasureAxis.tooltip.background = new am4core.RoundedRectangle();
      singleMeasureAxis.tooltip.background.fill = color;
    }

    // Create series
    for (let index in columnMap["measure"]) {
      let measureAxis;
      let column = columnMap["measure"][index];

      /**
       * Creating Measure Axis
       * 
       * If single axis feature is not activated,
       * Each line connects ITS OWN measure axis.
       */
      if (config.singleAxis !== true) {
        // Create measure axis (y)
        measureAxis = chart.yAxes.push(new am4charts.ValueAxis());

        if (config.strictMinMax === true) setAxisMinMax(measureAxis, config, column.uniqeColumnId);
      }

      // Set colors
      let interfaceColors = new am4core.InterfaceColorSet();
      let lineColor = `colorOf${column.displayName}`;

      // Create series
      let series = chart.series.push(new am4charts.LineSeries());

      series.dataFields.dateX = "date";
      series.dataFields.valueY = column.displayName;
      series.yAxis = config.singleAxis === true ? singleMeasureAxis : measureAxis;
      series.name = column.displayName;
      series.strokeWidth = 2;
      series.connect = config.continuousChart;
      series.tensionX = !config.continuousChart && config.smoothedLines ? 0.8 : 1;
      series.fillOpacity = config.areaChart ? 0.5 : 0;
      series.showOnInit = true;
      series.propertyFields.stroke = lineColor;
      series.propertyFields.fill = lineColor;

      if (config.singleAxis !== true) {
        // Axis properties
        measureAxis.renderer.line.stroke = series.stroke;
        measureAxis.tooltip.label.fill = am4core.color(this.getContrastColor(series.stroke.rgb));
        measureAxis.tooltip.background = new am4core.RoundedRectangle();
        measureAxis.tooltip.background.fill = series.stroke;

        // Set measure axis number format
        measureAxis.numberFormatter = new am4core.NumberFormatter();
        measureAxis.numberFormatter.numberFormat = series.numberFormatter.numberFormat;
      }

      if (config.groupData) {
        series.groupFields.valueY = config.groupAggrRule;
      }

      let numberFormat = this.convertNumberFormat(column.DataFormat);

      if (numberFormat) {
        // Set series number format
        series.numberFormatter = new am4core.NumberFormatter();
        series.numberFormatter.numberFormat = numberFormat;
      }

      // Add series to navigator
      if (config.xZoom && config.navigator) {
        chart.scrollbarX.series.push(series);
      }

      // Bullets
      if (config.bullets) {
        let bullet = series.bullets.push(new am4charts.CircleBullet());

        bullet.width = 1;
        bullet.height = 1;
        bullet.horizontalCenter = "middle";
        bullet.verticalCenter = "middle";
        bullet.stroke = interfaceColors.getFor("background");
        bullet.propertyFields.fill = lineColor;
        bullet.strokeWidth = 1;
      }

      // Series tooltip
      series.tooltip.fontSize = 12;
      series.tooltipText = `{name}:\t[bold]{valueY.formatNumber('${numberFormat}')}`;
      series.tooltip.label.fontWeight = "normal"
      series.tooltip.autoTextColor = true;
    }

    // Measure axis common properties
    for (let measureAxis of chart.yAxes) {
      // Measure axis line
      measureAxis.strictMinMax = config.strictMinMax || false;
      measureAxis.gridCount = 10;
      measureAxis.autoGridCount = false;
      measureAxis.renderer.line.strokeOpacity = 0.5;
      measureAxis.renderer.line.strokeWidth = 2;
      measureAxis.renderer.labels.template.fontSize = 12;
      measureAxis.renderer.labels.template.fill = this.getContrastColor(chart.background.fill.rgb);
      measureAxis.renderer.grid.template.stroke = this.getContrastColor(chart.background.fill.rgb);
      measureAxis.renderer.opposite = config.yZoomAlignment === "left" ? true : false;

      // Measure axis tooltip
      measureAxis.tooltip.getFillFromObject = false;
      measureAxis.tooltip.autoTextColor = false;
      measureAxis.tooltip.fontSize = 12;
      measureAxis.tooltip.background.fillOpacity = 0.8;
      measureAxis.tooltip.background.pointerLength = 0;
      measureAxis.tooltip.dx = config.yZoomAlignment === "right" ? -8 : 8;
      measureAxis.tooltip.label.padding(2, 4, 2, 4);
    }

    if (columnMap.measure.length === 1 || config.singleAxis) {
      let measureAxis = chart.yAxes.getIndex(0);
      /**
     * Creating Targets
     * 
     * Targets are created if:
     * - Show targets is activated
     * - There is only one line or single axis feature is activated
     */
      if (config.showTargets) {
        for (let target of sortedTargets) {
          let value = target.value;
          let endValue = target.endValue;

          if (value > 0 && endValue < 0) {
            endValue = 0;
          }

          // Target color
          let color = am4core.color(target.color);

          // Target line
          let range = measureAxis.axisRanges.create();
          range.value = value;

          // Line properties
          range.grid.above = true
          range.grid.stroke = color
          range.grid.strokeWidth = 2;
          range.grid.strokeOpacity = 1;
          range.grid.strokeDasharray = "6";

          // Label text properties
          range.label.text = target.name;
          range.label.fill = this.getContrastColor(color.rgb);
          range.label.fontSize = 12;
          range.label.background = new am4core.RoundedRectangle()
          range.label.background.fill = color;
          range.label.background.fillOpacity = 0.8;
          range.label.background.stroke = color;
          range.label.padding(2, 4, 2, 4);
          range.label.margin(0, 8, 0, 8);

          // Target area
          if (config.fillTargets) {
            let rangeFill = measureAxis.axisRanges.create();

            rangeFill.value = value;
            rangeFill.endValue = endValue;
            rangeFill.axisFill.above = true;
            rangeFill.axisFill.fill = color;
            rangeFill.axisFill.fillOpacity = 0.1;
          }

          let id = config.singleAxis === true ? "single" : this.measures[0].uniqeColumnId;

          let minMeasure = this.minMeasure[id];
          let maxMeasure = this.maxMeasure[id];

          let min = measureAxis.minDefined || measureAxis.minDefined === 0 ? measureAxis.minDefined : minMeasure;
          let max = measureAxis.maxDefined || measureAxis.maxDefined === 0 ? measureAxis.maxDefined : maxMeasure;

          if (value >= max) {
            measureAxis.max = value;
          } else if (value <= min) {
            measureAxis.min = value;
          }
        }
      }
    }

    // Legends
    if (config.legend) {
      chart.legend = new am4charts.Legend();
      chart.legend.fontSize = 12;
      chart.legend.markers.template.height = 10;
      chart.legend.labels.template.fill = this.getContrastColor(chart.background.fill.rgb)
    }

    // Cursor
    chart.cursor = new am4charts.XYCursor();

    // Cursor behavior according to xZoom and yZoom status
    if (config.xZoom && config.yZoom) {
      chart.cursor.behavior = "zoomXY";
    } else if (config.yZoom) {
      chart.cursor.behavior = "zoomY";
    } else if (config.xZoom) {
      chart.cursor.behavior = "zoomX";
    } else {
      chart.cursor.behavior = "none";
    }

    

    function prepareConditionalLegends(conditionalLegendDiv) {
      //sets conditional format div's for find legend div
      conditionalLegendDiv.setAttribute("class", "apexcharts-legend-series");
      conditionalLegendDiv.setAttribute("style", "margin: 0px 5px;");
    }

    let addCondFormLegend = () => {
      //adds conditional format legends to plugins bottom
      let usedCondFormatIds = new Set();
      
      condFormats.forEach((d, i) => {
        if (usedCondFormatIds.has(d.id)) {
          return;
        } else {
          usedCondFormatIds.add(d.id);
        }

        let condLegend = document.createElement("div");
        prepareConditionalLegends(condLegend)

        let condLegendMarker = document.createElement("span");
        let condLegendMarkerBackground = document.createElement("span");

        if (d.Style.background) {
          condLegendMarkerBackground.setAttribute("class", "dot");
          condLegendMarkerBackground.setAttribute(
            "style",
            "background: " +
            d.Style.background.colour +
            "; color:" +
            d.Style.background.colour +
            "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 10px; border-color: rgb(255, 255, 255); border-radius: 10px; display: inline-block; margin-left: 2px; border: 1px solid white;"
          );
        }

        let condLegendText = document.createElement("span");
        condLegendText.setAttribute("class", "text");
        condLegendText.setAttribute(
          "style",
          "color:" + 
          getContrastColor(config.backgroundColor) +
          "; font-size: 12px; font-family: Helvetica, Arial, sans-serif;"
        );

        let ruleDescription = this.props.plugin.conditionalFormats[i]?.rule?.conditionalFormatRule;
        // if rule description is defined in text area, legend will use user input.

        let textNode = document.createTextNode(
          ruleDescription ? " " + ruleDescription  : " " + d.LeftRuleColumnName + " " + d.Operator + " " + d.RightRuleColumnName + ""
        );
        condLegendText.appendChild(textNode);

        condLegend.appendChild(condLegendMarker);
        condLegend.appendChild(condLegendMarkerBackground);
        condLegend.appendChild(condLegendText);

        showCondForm.append(condLegend);
      });
    }

    if (condFormats?.length > 0 && config.condFormat) {
      addCondFormLegend();
    }
    $(container).append(showCondForm);

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  };

  getContrastColor = (colorRGB) => {
    const yiq = (colorRGB.r * 299 + colorRGB.g * 587 + colorRGB.b * 114) / 1000;

    return yiq >= 128 ? "#000000" : "#FFFFFF"
  };

  render() {
    let configComponent = null;
    let dataComponent = null;
    let conditionalFormatComponent = null;

    // Configuration component
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    // Data component
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    // Conditional formatting component
    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    if (!this.props.plugin.config) {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    let pluginContainerPadding = parseInt(
      $("#grid-" + this.props.plugin.id).css("padding")
    );

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    pluginConfig.height =
      this.calculatePluginHeight(this.props.plugin, this.props.settings) -
      pluginContainerPadding * 2;

    if (isNaN(pluginConfig.height)) {
      pluginConfig.height = this.currentHeight;
    }

    if (pluginConfig.height !== this.currentHeight) {
      this.currentHeight = pluginConfig.height;
      isRerender = true;
    }

    const calculatePluginInlineHeightWithConditional = plugin => {
      if (this.condFormatsLength === 0) {
        return calculatePluginInlineHeight(this.props.plugin.id)
      } else {
        return calculatePluginInlineHeight(this.props.plugin.id) - 20
      }
    }

    return (
      <div style={{ height: "100%" }}>
        <div id={this.props.plugin.id} style={{ height: calculatePluginInlineHeightWithConditional(this.props.plugin) }} />
        {
          renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            this.props.plugin.conditionalFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )
        }
        {configComponent}
        {dataComponent}
        {conditionalFormatComponent}
      </div>
    );
  }
}
