import React, { Component } from "react";
import PopupContainer from "../GeneralComponents/PopupContainer/PopupContainer";
import { Badge, CloseCircleOutlined } from "antd";
import { dateFormat } from "../../Utils/Global";
import i18n from "../../Utils/i18next";
import reportThumbnail from "../../images/report-thumbnail.png";

class DashboardPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  Close = () => {
    this.props.onClose();
  };

  showLastUpdatedDate = () => {
    if (this.props.data.lastUpdatedDate) {
      return (
        <>
          <div style={{ margin: "3px 0" }} className={"col-lg-4"}>
            <span className={"constant-text"}>{i18n.t("LandingPage.Preview.UpdateDate")}: </span>
          </div>
          <div style={{ margin: "3px 0" }} className={"col-lg-8"}>
            <span>{dateFormat(this.props.data.lastUpdatedDate, "/")}</span>
          </div>
        </>
      )
    }
  }

  render() {
    let thumbnail = "url(/resource/api/resource/" + this.props.data.thumbnail + ")";
    let viewCountText = i18n.t("LandingPage.Preview.ViewCount");

    if (this.props.data && this.props.data.type === "report") {
      thumbnail = "url(" + reportThumbnail + ") center top / contain no-repeat";
      viewCountText = i18n.t("LandingPage.Preview.TimesGenerated");
    }

    return Object.keys(this.props.data).length > 0 ? (
      <PopupContainer width={this.props.width} background={"#f9f9f9"} shadow={"0px 0px 4px 2px #226090"} position={this.props.position}>
        <div class="preview" style={{ width: "100%" }}>
          <span className={"preview-close"} onClick={this.Close}>
            <i className={"fa fa-times"}></i>
          </span>
          <h4 type="h4" style={{ marginBottom: "5px" }}>
            {this.props.data.settings.title}
          </h4>
          <div
            className={"preview-thumb"}
            style={{ background: thumbnail }}
          ></div>

          <div className={"row"} style={{ fontSize: "14px" }}>
            <div style={{ margin: "3px 0" }} className={"col-lg-4"}>
              <span className={"constant-text"}>{i18n.t("LandingPage.Preview.CreatedUser")}: </span>
            </div>
            <div style={{ margin: "3px 0" }} className={"col-lg-8"}>
              <span>{this.props.data.createdUser}</span>
            </div>

            <div style={{ margin: "3px 0" }} className={"col-lg-4"}>
              <span className={"constant-text"}>{i18n.t("LandingPage.Preview.Description")}: </span>
            </div>
            <div style={{ margin: "3px 0" }} className={"col-lg-8"}>
              <span className={"styledScroll"} style={{ maxHeight: "85px", display: "block", overflow: "auto" }}>{this.props.data.settings.description}</span>
            </div>

            <div style={{ margin: "3px 0" }} className={"col-lg-4"}>
              <span className={"constant-text"}>{i18n.t("LandingPage.Preview.CreationDate")}: </span>
            </div>
            <div style={{ margin: "3px 0" }} className={"col-lg-8"}>
              <span>{dateFormat(this.props.data.creationDate, "/")}</span>
            </div>

            {this.showLastUpdatedDate()}

            {!this.props.disableViewCount
              ? <>
                <div style={{ margin: "3px 0" }} className={"col-lg-4"}>
                  <span className={"constant-text"}>{viewCountText}: </span>
                </div>
                <div style={{ margin: "3px 0" }} className={"col-lg-8"}>
                  <span>{this.props.data.viewCount}</span>
                </div>
              </>
              : null}

          </div>
          <div style={{ marginTop: "10px" }}>
            {this.props.data.labels.length > 0
              ? this.props.data.labels.map(l => {
                return (
                  <Badge
                    count={i18n.t(l.displayName)}
                    style={{ backgroundColor: l.color, marginRight: "3px" }}
                  />
                );
              })
              : null}
          </div>
        </div>
      </PopupContainer>
    ) : null;
  }
}

export default DashboardPreview;
