import $ from "jquery";
import { getFormattedValue } from "../PluginComponents/format";
import { convertHTMLRuletoRule } from "../../ConditionalFormatting/ConditionalFormattingCommon";
import i18n from "../../../Utils/i18next";

/**
 * Set default value
 * @param {*} params
 * @param {*} gridOptionsTop
 * @param {*} gridOptionsBottom
 */
export const setWhatIfDefault = function(
  params,
  gridOptionsTop,
  gridOptionsBottom,
  columnMap,
  enableTotal,
  column
) {
  let originalValue = params.data.columns.filter(
    p => p.name === params.colDef.headerName
  )[0].value;

  let difference = originalValue - convertValueToZeroIfNaN(params.value);

  params.setValue(originalValue);
  whatIfOutputDataChange(
    params,
    columnMap,
    gridOptionsTop,
    gridOptionsBottom,
    enableTotal,
    difference
  );

  gridOptionsTop.api.redrawRows(params.node);
};

/**
 * Compare values for is value changed
 * @param {*} originalValue
 * @param {*} value
 */
const checkIsValueChanged = (originalValue, value) => {
  if (originalValue !== value) {
    return true;
  }

  return false;
};

/**
 * Draw what if input cell
 * @param {*} params
 * @param {*} column
 * @param {*} gridOptionsTop
 */
export const whatIfInput = function(
  params,
  column,
  gridOptionsTop,
  gridOptionsBottom,
  columnMap,
  enableTotal,
  conditionalFormatIcon
) {
  let html = $(
    "<div id='what-if-" +
      column.uniqeColumnId +
      "' class='table-what-if-input-area '></div>"
  );

  let value = params.value;

  if (value === "" || value === null) {
    value = 0;
  }

  if (column.dataType === "double") {
    value = Number.parseFloat(value);
  } else if (column.dataType === "integer") {
    value = Number.parseInt(value);
  }

  let editableValue = $(
    "<i style='margin: auto; font-size: .65rem; color: #92acc5; margin-right: 3px; position: absolute; left: 3px; top: 8px;' class='fa fa-pencil-alt'></i> " +
      "<span style='padding-left: 15px;' title='" +
      getFormattedValue(column, value) +
      "'>" +
      getFormattedValue(column, value) +
      "</span>"
  );

  let originalValue = params.data.columns.filter(
    p => p.name === params.colDef.headerName
  )[0].value;

  let isValueChanged = checkIsValueChanged(originalValue, value);

  let defaultBtn = $(
    "<span id='remove-what-if-" +
      column.uniqeColumnId +
      "'" +
      "title='" +
      i18n.t("Dashboard.Data.WhatIf.SetDefault") +
      "' class='what-if-set-default'>" +
      "<i style='margin: auto' class='fa fa-undo'></i>" +
      "</span>"
  )
    .css("display", isValueChanged ? "flex" : "none")
    .on("click", function() {
      setWhatIfDefault(
        params,
        gridOptionsTop,
        gridOptionsBottom,
        columnMap,
        enableTotal,
        column
      );
    });

  html.append(editableValue);
  html.append(defaultBtn);
  html.append(conditionalFormatIcon)

  return $(html)[0];
};

const operators = new Set(["+", "-", "*", "/", "%"]);

/**
 * Count operators in string
 * @param {*} string
 */
const getOperatorCount = string => {
  let operatorCount = 0;

  for (let i = 0; i < string.length; i++) {
    if (operators.has(string.charAt(i))) {
      operatorCount += 1;
    }
  }

  return operatorCount;
};

/**
 * Returns column count in rule
 * @param {*} rule
 */
const getColumnCountInRule = rule => {
  let columnCount = (rule.match(new RegExp("{", "g")) || []).length;

  return columnCount;
};

/**
 * Draw what if output cell
 * @param {*} params
 * @param {*} column
 * @param {*} gridOptionsTop
 */
export const whatIfOutput = function(
  params,
  column,
  gridOptionsTop,
  columnMap,
  conditionalFormatIcon
) {
  let evaluatedValue = whatIfOutputCalculation(params, column, gridOptionsTop);

  if (evaluatedValue !== undefined) {
    return (
      "<span style='padding-left: 15px;'><i style='color: #92d0ab; position: absolute; left: 3px; top: 8px;' class='fa fa-calculator'></i> " +
      getFormattedValue(column, params.value) + conditionalFormatIcon +
      "</span>"
    );
  } else {
    return (
      getFormattedValue(column, params.value) + conditionalFormatIcon +
      "<i title='" +
      i18n.t("Dashboard.Data.WhatIf.CouldntEvaluate") +
      "' class='fa fa-exclamation-triangle' style='color: red; margin-left: 2px;'></i>"
    );
  }
};

export const whatIfOutputCalculation = (params, column, gridOptionsTop) => {
  let outputFormula = column.whatIf.formula;
  let formula = convertHTMLRuletoRule(outputFormula);
  let columnToMap = new Map();
  columnToMap.set(column.uniqeColumnId, column);
  let calculationForEval = outputFormulaEval(
    formula.ruleColumnName,
    column,
    params
  );
  let evaluatedValue = undefined;

  if (calculationForEval !== undefined && calculationForEval !== false) {
    try {
      evaluatedValue = eval(calculationForEval);
    } catch (error) {
      console.error(error);
    }
  }

  return evaluatedValue;
};

/**
 * Update output data if necessary
 * @param {*} event
 * @param {*} columnMap
 * @param {*} gridOptionsTop
 * @param {*} gridOptionsBottom
 * @param {*} enableTotal
 */
export const whatIfOutputDataChange = (
  event,
  columnMap,
  gridOptionsTop,
  gridOptionsBottom,
  enableTotal,
  calculatedDifference
) => {
  let newData = [...gridOptionsTop.rowData];

  for (let i = 0; i < gridOptionsTop.columnDefs.length; i++) {
    let column = gridOptionsTop.columnDefs[i];
    let columnInColumnMap = columnMap.columns[i];
    let oldValue = newData[event.node.id][column.headerName];
    let newSumData = [...gridOptionsBottom.rowData];
    let isTotalChanged = false;

    if (column.isWhatIfOutput === true) {
      newData[event.node.id][column.headerName] = whatIfOutputCalculation(
        event,
        columnInColumnMap,
        gridOptionsTop
      );

      if (enableTotal) {
        let difference = newData[event.node.id][column.headerName] - oldValue;
        newSumData[0][column.headerName] += difference;
        isTotalChanged = true;
      }
    }

    if (enableTotal && column.headerName === event.colDef.headerName) {
      newSumData[0][event.colDef.headerName] += calculatedDifference;
      isTotalChanged = true;
    }

    if (isTotalChanged) {
      gridOptionsBottom.api.setRowData(newSumData);
    }
  }

  gridOptionsTop.api.setRowData(newData);
};

/**
 * checks the columns count and operators count for evaluate to rule.
 * @param {*} ruleColumnName
 */
const canTheOutputBeCalculate = ruleColumnName => {
  let columnCount = getColumnCountInRule(ruleColumnName);
  let operatorCount = getOperatorCount(ruleColumnName);

  if (columnCount === 1 && operatorCount === 0) {
    return true;
  }

  if (columnCount - 1 > operatorCount) {
    return false;
  }

  return true;
};

/** Check value is number */
export const valueIsNumber = (value) => {
  return Number.isNaN(parseFloat(value))
}

/** If number value is nan, we have to use zero for calculations */
export const convertValueToZeroIfNaN = (value) => {
  return valueIsNumber(value) ? 0 : parseFloat(value);
} 

/**
 * Convert rule for what if output evaluation
 * @param {*} ruleColumnName
 * @param {*} column
 * @param {*} params
 */
export const outputFormulaEval = (ruleColumnName, column, params) => {
  let withoutCloseBraces = ruleColumnName.split("}");
  let tableAliasName = column.tableAliasName;
  let data = params.data;
  let calculationForEval = "";

  if (!canTheOutputBeCalculate(ruleColumnName)) {
    return;
  }

  for (let i = 0; i < withoutCloseBraces.length; i++) {
    let codeAndOpreation = withoutCloseBraces[i].split("{");

    for (let j = 0; j < codeAndOpreation.length; j++) {
      let value = codeAndOpreation[j];

      if (value.includes(tableAliasName + ".")) {
        let columnName = value.replace(tableAliasName + ".", "");
        let dataValue = data[columnName];

        if (valueIsNumber(dataValue)) {
          dataValue = 0;
        }

        let checkDataValue = dataValue !== undefined && dataValue !== null && !Number.isNaN(dataValue);

        if (checkDataValue) {
          calculationForEval += dataValue;
        }
      } else {
        calculationForEval += value;
      }
    }
  }

  return calculationForEval;
};
