import React from "react";
import BoxItem from "../BoxItem/BoxItem";
import Search from "../Search/Search";
import Accordion from "../Accordion/Accordion";
import AccordionContent from "../Accordion/AccordionContent";
import i18n from "../../../Utils/i18next";
import { store } from "../../..";
import Text from "../Text/Text";
import { sortByLanguage } from "../../../Utils/SortByLanguage";
import { deepCopy } from "../../../Utils/Global";
import { setNewColumnEditorContent } from "../NewColumn/NewColumnEditorAction";
import { formulas } from "../FormulaEditor/formulas";

/**
 * This component consists of Formulas and search bar.
 */
export default class NewColumnFormulaList extends React.Component {
    constructor() {
        super();
        this.state = {
            searchTempData: formulas,
        };
    }

    /**
     * Filters formulas by given search category
     */
    search = (searchCategory, functionType, e) => {
        let searchResult = deepCopy(functionType);

        searchResult.formulas = functionType.formulas.filter(formula => {
            if (formula[searchCategory]) {
                return formula[searchCategory]
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase());
            }

            return null;
        });

        return searchResult;
    };

    /**
     * Search bar change event
     */
    handleSearch = e => {
        let searchedFormulas = deepCopy(formulas);

        if (e.target.value && e.target.value !== "") {
            for (let functionType of Object.keys(searchedFormulas)) {
                let functionTypeTemp;

                // Search by name
                functionTypeTemp = this.search("name", searchedFormulas[functionType], e);

                // If cannot find any formula, search by key
                if (functionTypeTemp.formulas.length === 0) {
                    functionTypeTemp = this.search("key", searchedFormulas[functionType], e);
                }

                // If cannot find any formula, search by insert text
                if (functionTypeTemp.formulas.length === 0) {
                    functionTypeTemp = this.search("insertText", searchedFormulas[functionType], e);
                }

                // If cannot find any formula, search by documentation
                if (functionTypeTemp.formulas.length === 0) {
                    functionTypeTemp = this.search("documentationContent", searchedFormulas[functionType], e);
                }

                searchedFormulas[functionType] = functionTypeTemp;
            }
        }

        this.setState({
            ...this.state,
            searchTempData: searchedFormulas
        });
    };

    /**
     * Start dragging event
     */
    onDragStart = (event, data) => {
        let dropArea = document.getElementById("formula-editor-drop-area");

        dropArea.style.border = "dashed 2px #b7b7b7";

        event.target.style.opacity = '0.4';
        event.dataTransfer.setData("text/plain", data);
        event.dataTransfer.setDragImage(event.target, 0, 0);
    }

    /**
     * End dragging event 
     */
    onDragEnd = (event) => {
        let dropArea = document.getElementById("formula-editor-drop-area");

        dropArea.style.border = "solid 2px transparent";

        event.target.style.opacity = '1';
    }

    /**
     * Appends formula to code 
     */
    appendCode = (formula) => {
        const reduxState = store.getState();
        const { contentObject } = reduxState.NewColumnEditorReducer;

        contentObject.code += (formula.insertText);

        store.dispatch(setNewColumnEditorContent(contentObject));
    }

    /**
     * Sets selected formula for the description area
     */
    selectFormula = (formula) => {
        let newObject = {
            selectedFormula: formula
        }

        store.dispatch(setNewColumnEditorContent(newObject));
    }

    /**
     * Sorts formulas
     */
    sortFormulas = (tempFormulas) => {
        let sortedFormulas = {};
        let data = deepCopy(tempFormulas);

        let functionTypes = Object.keys(data).sort((a, b) => sortByLanguage(
            i18n.t(`FormulaEditor.FunctionTypes.${a}`),
            i18n.t(`FormulaEditor.FunctionTypes.${b}`)
        ));

        for (let functionType of functionTypes) {
            sortedFormulas[functionType] = {};
            sortedFormulas[functionType].formulas = data[functionType].formulas.sort((a, b) => {
                return sortByLanguage(a.name, b.name);
            });
        }

        return sortedFormulas;
    };

    render() {
        let sortedFormulas = this.sortFormulas(this.state.searchTempData);

        return (
            <div
                style={{
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignContent: "center",
                    justifyItems: "center"
                }}
            >
                <div style={{ padding: "0 8px" }}>
                    <Text
                        style={{
                            fontSize: "1rem",
                            margin: "16px 0 8px 0",
                            display: "block",
                            width: "100%",
                            textAlign: "center",
                            color: "#225f8f"
                        }}
                    >
                        {i18n.t("FormulaEditor.Titles.Formulas")}
                    </Text>
                    <Search
                        preventDrop={true}
                        paddingNone={true}
                        marginBottom={"8px"}
                        inputStyle={{ minWidth: "auto" }}
                        id={"searchFormula"}
                        width="100%"
                        searchBarVisible={true}
                        onChange={this.handleSearch}
                    ></Search>
                </div>
                <div style={{ overflowY: "auto", padding: "8px 0" }}>
                    {
                        this.state.searchTempData
                            ? Object.keys(sortedFormulas)
                                .map((functionType, index) => (
                                    functionType &&
                                        sortedFormulas[functionType].formulas.length > 0
                                        ? (
                                            <Accordion
                                                key={index + 1}
                                                accordionStyle={{
                                                    padding: "0 8px",
                                                }}
                                                titleClass={"dataAccordionHeader"}
                                                headerStyle={{
                                                    background: "#fff",
                                                    borderBottom: "1px solid #dadada"
                                                }}
                                                collapseStyle={{ background: "#fff" }}
                                            >
                                                <AccordionContent
                                                    pluginId={this.props.pluginId}
                                                    eventKey={index + 1}
                                                    title={i18n.t(`FormulaEditor.FunctionTypes.${functionType}`)}
                                                    headerStyle={{
                                                        background: "#fff",
                                                        borderBottom: "1px solid #dadada"
                                                    }}
                                                    collapseStyle={{ background: "#fff" }}
                                                    tooltipPlacement="topRight"
                                                >
                                                    {
                                                        sortedFormulas[functionType].formulas.map((formula) => {
                                                            let translateKey = `FormulaEditor.Formulas.${formula.key}.Description`;
                                                            let description = i18n.t(translateKey);

                                                            formula.description = description !== translateKey ? description : null;

                                                            return (
                                                                <div
                                                                    draggable="true"
                                                                    onDragStart={(event) => this.onDragStart(event, formula.insertText)}
                                                                    onDragEnd={(event) => this.onDragEnd(event)}
                                                                    onClick={() => this.selectFormula(formula)}
                                                                    onDoubleClick={() => this.appendCode(formula)}
                                                                >
                                                                    <BoxItem
                                                                        id={formula.name}
                                                                        title={formula.name}
                                                                        tooltipTitle={formula.name}
                                                                        divClassName={"noStyle formulaBox"}
                                                                        style={{ cursor: "grab" }}
                                                                        tooltipPlacement="topRight"
                                                                    />
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </AccordionContent>
                                            </Accordion>
                                        ) : null
                                )) : null
                    }
                </div>
            </div>
        )
    }
}
