import { FORMULA_EDITOR_CONTENT } from "./FormulaEditorAction";

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 * 
 * Reducer for Formula Editor content
 */
export default function FormulaEditorReducer(
  state = {
    contentObject: {
      visible: false,
      code: "",
      column: {},
      selectedFormula: {},
      editorTitle: ""
    }
  },
  action
) {
  switch (action.type) {
    case FORMULA_EDITOR_CONTENT :
      return {
        ...state,
        contentObject: {
          visible: action.payload.visible ? action.payload.visible : state.contentObject.visible,
          code: action.payload.code ? action.payload.code : state.contentObject.code,
          column: action.payload.column ? action.payload.column : state.contentObject.column,
          selectedFormula: action.payload.selectedFormula ? action.payload.selectedFormula : state.contentObject.selectedFormula,
          editorTitle: action.payload.editorTitle ? action.payload.editorTitle : state.contentObject.editorTitle
        }
      };
    default:
      return state;
  }
}
