import "antd/dist/antd.css";
import "../css/bootstrap.min.css";
import React, { Component } from "react";
import { Layout } from "antd";
import SplashScreen from "../SplashScreen/SplashScreen";
import TopMenu from "../TopMenu/TopMenu";
import DashboardArea from "../DashboardPage/DashboardArea";
import { navigate } from "../../Utils/Global"

import "../css/main.css";
import "../css/main-responsive.css";
import "../css/lightTheme.css";
import "../lib/fontawesome/css/fontawesome.css";
import "../lib/fontawesome/css/all.min.css";
import "../lib/owlcarousel/css/owl.carousel.min.css";
import "../lib/owlcarousel/css/owl.theme.default.min.css";
import Copyright from "../GeneralComponents/Copyright";

class LandingPage extends Component {
  constructor (props) {
    super(props);

    this.state = {
      themeObj: {}
    }
  }

  componentDidMount() {
    this.applyThemeStyleToLandingPage();
    
    document.addEventListener('contextmenu', this.handleContextMenu, true);
  }

  /**
   * Applies the theme style to the landing page.
   */
  applyThemeStyleToLandingPage = () => {
    let theme = sessionStorage.getItem("theme-default");

    if (theme) {
      this.setState({
        themeObj: JSON.parse(theme)
      });
    }
  }

  //Disallows to open context menu on some top menu buttons
  handleContextMenu = (e) => {
    let elementIds = ["dashboardHelp", "userNotifications", "dashboardProfile","dashboardLogOut"];
    let isButton = e.target.closest("a");
    let clickControl = isButton && elementIds.includes(isButton.id);
    
    if (clickControl) {
      e.preventDefault();
    }
  };

  render() {
    return (
      <Layout
        className="layout"
        hasSider={true}
        style={{ width: "100%", height: "100%", backgroundColor: "#fff", flexDirection: "column" }}
      >
        <SplashScreen />
        
        <div id="landingPage" style={{ width: "100%", height: "calc(100dvh - 30px)", overflow: "auto" }} page={"landing-page"}>
          <DashboardArea themeObj={this.state.themeObj}/>
        </div>
        
        <Copyright/>
        
        <style id="landingPageCSS" theme={this.state.themeObj?.name}>
          {this.state.themeObj?.landingPage?.css}
        </style>
      </Layout> 
    );
  }
}

export default LandingPage;
