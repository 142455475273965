import React, { Component } from "react";
import DashboardLabel from "./DashboardLabel";
import DashboardPreview from "./DashboardPreview";
import $ from "jquery";
import LandingPageSkeleton from "../GeneralComponents/LandingPageSkeleton/LandingPageSkeleton";
import EmptyDashboardLabel from "./EmptyDashboardLabel";
import { labelTranslateNameSet } from "../../Utils/LabelOperations";
import { deepCopy } from "../../Utils/Global";

const dataLabel = [
  { title: "Favorites", icon: "star" },
  { title: "Most Viewed (Last Day)", icon: "eye" },
  { title: "Most Viewed (Last Week)", icon: "eye" }
];

export default class DashboardList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedReport: {},
      previewX: 0,
      previewY: 0,
      disableViewCount: false
    };
  }

  /**
   * select report for preview and prepare to show preview
   * @param {*} data 
   * @param {*} x 
   * @param {*} y 
   */
  selectReport = (data, x, y, disableViewCount) => {
    let labels = labelTranslateNameSet(data.labels);
    let copiedState = deepCopy(this.state)

    copiedState["selectedReport"] = data
    copiedState["previewX"] = x
    copiedState["previewY"] = y
    copiedState["disableViewCount"] = disableViewCount

    this.setState(copiedState);
  };

  /**
   * close dashboard preview
   */
  previewClose = () => {
    this.setState({
      ...this.state,
      selectedReport: {},
    });
    $(".report").removeClass("active");
  }

  render() {
    return (
      <div class="dashboard" >
        {this.props.dashboardsByLabel.sort((a,b) => a.label.displayName > b.label.displayName ? 1 : -1).map(dashboardByLabel => {
          if (this.props.selectedLabels && this.props.selectedLabels.has(dashboardByLabel.label.id)) {
            if(dashboardByLabel.visible === false
              || dashboardByLabel.dashboards.length == 0) { 
              return <EmptyDashboardLabel label={dashboardByLabel.label.translateName}/>; 
            }
  
            return (
              <DashboardLabel
                key={dashboardByLabel.labelId}
                dashboardByLabel={dashboardByLabel}
                data={dashboardByLabel.dashboards}
                dataLabel={{id: dashboardByLabel.label.id, displayName: dashboardByLabel.label.displayName, translateName: dashboardByLabel.label.translateName, color: dashboardByLabel.label.color, sortKey: dashboardByLabel.sortKey, sortType: dashboardByLabel.sortType, name: dashboardByLabel.label.name}}
                selectReport={this.selectReport}
                refresh={this.props.refresh}
                dashboardDelete={this.props.dashboardDelete}
                dashboardReloaded={this.props.dashboardReloaded}
                dashboardDeletedPermanently={this.props.dashboardDeletedPermanently}
              />)
          } else {
            return null;
          }
        })}
        {this.props.loadingDashboards.map(dashboard => {
          return (
          <LandingPageSkeleton label={dashboard.label.translateName} color={dashboard.label.color} />
          )
        })}

        <div
          className={
            Object.keys(this.state.selectedReport).length > 0
              ? "preview-area active"
              : "preview-area"
          }
          style={{
            top: this.state.previewX + "px",
            left: this.state.previewY + "px"
          }}
        >
          <DashboardPreview
            onClose={this.previewClose}
            previewX={this.state.previewX}
            previewY={this.state.previewY}
            position="right"
            width={"600px"}
            data={this.state.selectedReport}
            disableViewCount={this.state.disableViewCount}
          />
        </div>
      </div>
    );
  }
}
