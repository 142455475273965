import React, { Component } from "react";
import Cookies from "js-cookie";
import {
  Tooltip,
  Form,
  Icon,
  Input,
  Select,
  Drawer,
  Switch,
  Row,
  Col,
} from "antd";
import FooterVispeahenLogo from "../GeneralComponents/FooterVispeahenLogo";
import { post } from "../../Utils/WebService";
import { showError, showNotificationWithIcon } from "../../Utils/Notification";
import {
  eraseCookie,
  createCookie,
  navigate,
} from "../../Utils/Global";
import { setAuthentication, removeAuthentication } from "../../Utils/Auth";
import i18n from "../../Utils/i18next";
import Text from "../GeneralComponents/Text/Text";
import SubmitButton from "./SubmitButton";
import { getCustomization } from "../../Utils/Customization"
import { checkLocationForRegister } from "../../config";

const { Option } = Select;

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rememberMe: false,
      canISubmit: true,
      username: "",
      password: "",
    };
  }

  componentDidMount() {
    if (Cookies.get("i18next") == undefined) {
      Cookies.set("i18next", "en");
    }

    this.setState({
      username:
        Cookies.get("rememberMe") == undefined ? "" : Cookies.get("rememberMe"),
      rememberMe: Cookies.get("rememberMe") == undefined ? false : true,
    });
  }

  /**
   * Login submit and set cookies.
   */
  login = (e) => {
    if (this.state.canISubmit === false) {
      return;
    }

    e.preventDefault();

    if (this.areInputsValid() === false) {
      return;
    }

    const THIS = this;
    removeAuthentication();

    let url = `/security/oauth/token`;
    let params = new URLSearchParams();
    params.append("username", this.state.username);
    params.append("password", this.state.password);
    params.append("grant_type", "password");
    params.append("client_id", "mobile");

    let lastState = this.state;

    const successFunc = (result) => {
      let token = result.data;

      setAuthentication(token);

      if (lastState.rememberMe) {
        createCookie("rememberMe", lastState.username, null, "/");
      }

      getCustomization(token);

      if (sessionStorage.getItem("targetLocation")) {
        let targetLocation = sessionStorage.getItem("targetLocation")

        sessionStorage.removeItem("targetLocation")

        navigate(targetLocation);
      } else {
        navigate("/vispeahen/ui")
      }


      this.setState({
        ...this.state,
        canISubmit: true,
      });
    };

    const errorFunc = (error, isDescription) => {
      if (isDescription) {
        if (error === "Bad credentials") {
          showError(i18n.t("UserEntrance.Login.WrongUsernameOrPassword"));
        } else if (error === "User is disabled") {
          showError(i18n.t("UserEntrance.Login.UserDisabled"));
        } else {
          showNotificationWithIcon(i18n.t("SomethingWentWrong"), error, "error");
        }
      } else {
        showNotificationWithIcon(i18n.t("SomethingWentWrong"), error, "error");
      }

      this.setState({
        ...this.state,
        canISubmit: true,
      });
    };

    let headers = {
      Authorization: "Basic " + btoa("mobile:pin"),
      "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
    };

    post(url, params, successFunc, errorFunc, false, false, headers, false);

    this.setState({
      ...this.state,
      canISubmit: false,
    });
  };

  /**
   * Validates given inputs
   */
  areInputsValid = () => {
    if (
      !this.state.username ||
      this.state.username === "" ||
      this.state.username.trim() === ""
    ) {
      showNotificationWithIcon(
        i18n.t("Error"),
        i18n.t("UserEntrance.Login.UserNameEmpty"),
        "error"
      );
      return false;
    }

    if (
      !this.state.password ||
      this.state.password === "" ||
      this.state.password.trim() === ""
    ) {
      showNotificationWithIcon(
        i18n.t("Error"),
        i18n.t("UserEntrance.Login.PasswordEmpty"),
        "error"
      );
      return false;
    }

    return true;
  };

  /**
   * Handle state inputs change.
   */
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  /**
   * Change language.
   */
  changeLanguage = (value) => {
    Cookies.set("i18next", value);
    i18n.changeLanguage(value);
    this.forceUpdate();
  };

  handleKeyDown = (e) => {
    // if user presses enter button on keyboard
    if (e.key === "Enter") {
      this.login(e);
    }
  };

  /**
   * Calls when remember me switch is changed.
   * Sets or removes cookie
   */
  onRememberMeChange = () => {
    if (this.state.rememberMe === true) {
      eraseCookie("rememberMe", "/");
    }

    this.setState({ rememberMe: !this.state.rememberMe });
  };

  render() {
    return (
      <Drawer
        style={{ display: this.props.visibilityLogin ? "block" : "none" }}
        className={"noBackground"}
        placement={this.props.customization.layout}
        closable={false}
        visible={true}
        width={400}
        height={""}
      >
        <Form className="login-form" onSubmit={this.login}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              src={this.props.customization.logo}
              alt="Image"
              class="login-logo"
            />
          </div>
          <Form.Item>
            <Input
              id={"username"}
              value={this.state.username}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              className={"circled-no-border login-input"}
              maxLength={50}
              placeholder={i18n.t("UserEntrance.Login.Username")}
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              id={"password"}
              value={this.state.password}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              className={"circled-no-border login-input"}
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              maxLength={25}
              placeholder={i18n.t("UserEntrance.Login.Password")}
              suffix={
                <Tooltip title="Extra information">
                  <Icon
                    type="info-circle"
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item>
            <Row gutter={6}>
              <Col span={16}>
                <Text style={{ textAlign: "right", display: "block" }}>
                  {i18n.t("SelectLanguage")}
                </Text>
              </Col>
              <Col span={8}>
                <Select
                  className={"login-select"}
                  defaultValue={
                    Cookies.get("i18next") == "en" ||
                    Cookies.get("i18next") == "tr"
                      ? Cookies.get("i18next")
                      : "en"
                  }
                  onChange={this.changeLanguage}
                >
                  <Option value="en">English</Option>
                  <Option value="tr">Türkçe</Option>
                </Select>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <SubmitButton
              id="loginButton"
              canISubmit={this.state.canISubmit}
              text={i18n.t("UserEntrance.Login.Button")}
            />
          </Form.Item>
          <Form.Item>
            <Switch
              id={"rememberMe"}
              style={{ marginRight: "10px" }}
              className={"green-switcher"}
              onChange={this.onRememberMeChange}
              defaultChecked={this.state.rememberMe}
            />
            {i18n.t("UserEntrance.Login.RememberMe")}
            <span
              id="forgotPassword"
              className="login-form-forgot"
              style={{ color: "#1b6094", cursor: "pointer" }}
              onClick={() =>
                this.props.showComponent("visibilityPasswordForget")
              }
            >
              {i18n.t("UserEntrance.Login.ForgotPassword")}
            </span>
            <div
              style={{
                width: "100%",
                textAlign: "left",
                display: "flex",
                fontSize: "12px",
                height: checkLocationForRegister ? "inherit" : "30px",
              }}
            >
              {checkLocationForRegister ? (
                <span style={{ lineHeight: "20px" }}>
                  {i18n.t("UserEntrance.Login.DontYouHaveAnAccountYet")} <br />
                  <div
                    style={{
                      padding: "5px 5px 5px 0px",
                    }}
                  >
                    <span
                      id={"freeRegister"}
                      style={{ color: "#1b6094", cursor: "pointer" }}
                      onClick={() =>
                        this.props.showComponent("visibilityRegister")
                      }
                    >
                      {i18n.t("UserEntrance.Login.FreeRegister")}
                    </span>
                  </div>
                </span>
              ) : null}
            </div>
          </Form.Item>
        </Form>
        <FooterVispeahenLogo position={"absolute"} />
      </Drawer>
    );
  }
}
