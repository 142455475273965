export const configuration = {
    RowHeight: 28,
    RowWidth: 100,
    autoSize: false,
    columnHeight: 35,
    enableTotal: false,
    font: "Verdana",
    fontSize: 12,
    hideFiltering: false,
    imgField: "",
    linkDesc: "",
    linkField: "",
    pageNumber: 50000,
    pagination: false,
    pageOffset: 0,
    paginationPageSize: 100,
    tableDescription: "",
    wrapCells: false,
    wrapHeader: true,
    condFormat: true,
    columnHeaderTextFontSize: 12,
    columnHeaderTextFont: "Verdana",
    columnHeaderTextColor: "#000000",
    themeColour: "#67b7dc"
};

export const actions = [
    {
        trigger: "rowClick",
        type: "click",
        output: ["columns"],
        name: "ClickRow",
        description: "TableRowClickDesc"
    },
    {
        trigger: "rowHover",
        type: "hover",
        output: ["columns"],
        name: "HoverRow",
        description: "TableRowHoverDesc"
    }
];

export const tableReactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    },
    {
        id: "search",
        name: "Arama",
        description: "desc88",
        type: "private",
        method: "search"
    }
];

export const tableConversion = {};