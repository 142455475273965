import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "./pivot-table.css";

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { LicenseManager } from "ag-grid-enterprise";

import { onComponentWillReceiveProps, onComponentWillMount, getColumnMapping } from "../common";
import { getFormattedValue } from "../format";
import { Button } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import Tooltip from "../../../GeneralComponents/Tooltip/Tooltip";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import $ from "jquery";
import {
  renderConfig,
  renderData,
  renderNavigation,
  renderConditionalFormatting
} from "../PluginsCommonComponents";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join";
import PivotTableData from "./PivotTableData";
import PivotTableEnhanceConfiguration from "./PivotTableEnhanceConfiguration";
import i18n from "../../../../Utils/i18next";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import NoDataContent from "../../NoDataContent/NoDataContent";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

LicenseManager.setLicenseKey(
  "CompanyName=BiSOFT InformationTechnologies,LicensedApplication=vispeahen,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-014843,ExpiryDate=19_January_2023_[v2]_MTY3NDA4NjQwMDAwMA==b55932de571b520b25644dba80a8d095"
);

const pluginName = "pivot-table-enhance";

//Default column definition
const tempColumnDef = [
  {
    field: "satir",
    rowGroup: true,
    enableRowGroup: true
  },
  {
    field: "kolon",
    pivot: true,
    enablePivot: true
  },
  {
    field: "deger",
    aggFunc: "sum"
  }
];

//Default data
const data = [
  {
    satir: "Demo",
    kolon: "Kolon1",
    deger: 100
  },
  {
    satir: "Deneme",
    kolon: "Kolon1",
    deger: 200
  },
  {
    satir: "Test",
    kolon: "Kolon1",
    deger: 300
  },
  {
    satir: "Demo",
    kolon: "Kolon2",
    deger: 400
  },
  {
    satir: "Deneme",
    kolon: "Kolon2",
    deger: 500
  },
  {
    satir: "Test",
    kolon: "Kolon2",
    deger: 600
  },
  {
    satir: "Demo",
    kolon: "Kolon3",
    deger: 700
  },
  {
    satir: "Deneme",
    kolon: "Kolon3",
    deger: 800
  },
  {
    satir: "Test",
    kolon: "Kolon3",
    deger: 900
  }
];

/**
 * Formats value for column values
 * @params
 * @column column object
 */
const formatValue = (params, column, config) => {
  let isValueEmpty = (params.value === null || params.value === "")
  let isNullValueAvailable = config.nullValue

  if (isValueEmpty && isNullValueAvailable) {
    return config.nullValue
  } else {
    return getFormattedValue(column, params.value)
  }
};

const actions = [
  {
    trigger: "click",
    type: "click",
    output: ["columns", "rows"],
    name: "Click",
    description: "PivotTableClickDesc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filter",
    description: "desc87",
    type: "general"
  }
];

const pluginConditionalFormatOptions = {
  color: {
    title: i18n.t("Dashboard.ConditionalFormatting.Color"),
    type: "COLOR",
    defaultValue: "#000001"
  },
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe"
  },
  icon: {
    title: i18n.t("Dashboard.ConditionalFormatting.Icon"),
    type: "ICON",
    defaultValue: ""
  },
  text: {
    title: i18n.t("Dashboard.ConditionalFormatting.Font"),
    type: "TEXT",
    defaultValue: {
      italic: false,
      bold: false
    }
  }
};

const conditionalFormatColumnMap = new Set(["rows", "columns", "measures"]);
const conditionalFormatTargetMap = new Set(["measures"]);

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "nullValue",
    label: "nullValue",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "nullValue"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "subTotal",
    label: "pivotTableSubTotal",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "pivotTableSubTotal"
  },
  {
    targetProperty: "showSum",
    label: "ShowSum",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "ShowHideTotal"
  },
  {
    targetProperty: "groupDefaultExpanded",
    label: "GroupDefaultExpanded",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "GroupDefaultExpanded"
  },
  {
    targetProperty: "rowSum",
    label: "RowSum",
    inputType: "dropdown",
    inputOptions: {
      defaultValue: "after",
      multiSelect: false,
      values: [undefined, "after", "before"]
    },
    desc: "RowSum"
  },
  {
    targetProperty: "groupName",
    label: "GroupName",
    inputType: "input",
    inputOptions: {
      defaultValue: ""
    },
    desc: "GroupName"
  },
  {
    targetProperty: "pivotTheme",
    label: "PivotTheme",
    inputType: "dropdown",
    inputOptions: {
      defaultValue: "ag-theme-balham",
      multiSelect: false,
      values: ["ag-theme-alphine", "ag-theme-balham", "ag-theme-material"]
    },
    desc: "PivotTheme"
  }
];

export default class PivotTableEnhance extends Component {
  constructor(props) {
    super(props);

    this.rerenderProcessStarted = false;
    this.callBackObject = {};
    this.columnMap = {};
    this.container = {};
    this.pluginId = "";
    this.interactions = [];
    this.navigations = [];
    this.drillDowns = {};

    this.initialState = {
      pivotMode: true,
      suppressDragLeaveHidesColumns: true,
      suppressMakeColumnVisibleAfterUnGroup: true,
      suppressExpandablePivotGroups: true,
      enableRangeSelection: true,
      rowDragManaged: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        sortable: true,
        resizable: true,
        filter: true,

        headerClass: function(params) {
          // logic to return the correct class
          // return "pivot-enhance-header";
        }
      },
      processSecondaryColDef: function(colDef) {
        colDef.headerName = colDef.headerName;
      },
      pivotRowTotals: "after",
      statusBar: {
        statusPanels: [
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ]
      },
      rowGroupPanelShow: "always",
      groupDisplayType: "singleColumn" //multipleColumns
    };

    this.state = {
      ...this.initialState,
      columnDefs: tempColumnDef,
      rowData: this.props.plugin.columnMap.measures.data.length > 0 ? [] : data,
      groupIncludeFooter:
        this.props.plugin.config && this.props.plugin.config.subTotal
          ? this.props.plugin.config.subTotal
          : true,
      groupIncludeTotalFooter:
        this.props.plugin.config && this.props.plugin.config.showSum
          ? this.props.plugin.config.showSum
          : true,
      groupDefaultExpanded:
        this.props.plugin.config &&
        this.props.plugin.config.groupDefaultExpanded
          ? 20
          : 0,
      autoGroupColumnDef: {
        headerName: i18n.t("Group"),
        minWidth: 150,
        width: 150,
        cellRendererParams: {
          innerRenderer: params => {
            if (params.node.footer) {
              const isRootLevel = params.node.level === -1;
              if (isRootLevel) {
                // Grand Total Cells
                return (
                  `<span style="color:navy; font-weight:bold">` +
                  i18n.t("GeneralTotal") +
                  `</span>`
                );
              }

              // Subtotal Cells
              return (
                `<span style="color:navy;">` +
                i18n.t("SubTotal") +
                ` - ${params.value}</span>`
              );
            }
            // Non-Footer Group Cells
            return params.value;
          }
        }
      },
      defaultExportParams: {
        processRowGroupCallback: this.rowGroupCallbackForExcelExport
      }
    };
  }

  /**
   * Excel export group styling and indentation
   */
  rowGroupCallbackForExcelExport = params => {
    let string = "";

    for (let i = 0; i < params.node.level; i++) {
      string += "   "; //add three space for indent for every group level
    }

    string += params.node.key;

    return string;
  };

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = callBackObject => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({});

    return tmpCallBackObject;
  };

  /**
   ** Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };
    this.setPluginInformation(this.props);

    if (tempPlugin.config) {
      this.setState({
        ...this.state
      });
    }

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping
    );
  }

  /**
   *
   * @param {*} props
   * Sets some plugin information for trigger operation
   */
  setPluginInformation = props => {
    this.pluginId = props.plugin.id;
    this.interactions = props.interactions;
    this.navigations = props.navigations;
    this.drillDowns = props.plugin.drillDowns;
  };

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );

    if (this.props.plugin.interactions !== nextProps.plugin.interactions) {
      this.setPluginInformation(nextProps);
    }

    //Renders configuration changings
    if (this.props.plugin.config !== nextProps.plugin.config) {
      this.configurationChange(this.props, nextProps);
    }

    let isDataChanged = this.props.plugin.data !== nextProps.plugin.data;
    let isColumnMapChanged =
      this.props.plugin.columnMap !== nextProps.plugin.columnMap;

    if (isDataChanged || isColumnMapChanged) {
      this.onGridReady(this.gridParams, nextProps.plugin.data);
    }
  }

  /**
   * When configuration change, handles this changing and applies to plugin
   * @props this.props
   * @nextProps nextProps
   */
  configurationChange = (props, nextProps) => {
    let tempAutoGroupColumnDef = { ...this.state.autoGroupColumnDef };
    let groupName =
      nextProps.plugin.config.groupName !== undefined &&
      nextProps.plugin.config.groupName !== ""
        ? nextProps.plugin.config.groupName
        : i18n.t("Group");
    tempAutoGroupColumnDef.headerName = groupName;

    let isThemeChanged = false;
    let isExpandStatusChanged = false;

    if (props.plugin.config && nextProps.plugin.config) {
      isThemeChanged =
        props.plugin.config.pivotTheme !== nextProps.plugin.config.pivotTheme;

      isExpandStatusChanged =
        props.plugin.config.groupDefaultExpanded !==
        nextProps.plugin.config.groupDefaultExpanded;
    }

    this.setState(
      {
        ...this.state,
        groupIncludeTotalFooter: nextProps.plugin.config.showSum,
        groupIncludeFooter: nextProps.plugin.config.subTotal,
        pivotRowTotals: nextProps.plugin.config.rowSum,
        groupDefaultExpanded: nextProps.plugin.config.groupDefaultExpanded
          ? 20
          : 0,
        autoGroupColumnDef: { ...tempAutoGroupColumnDef }
      },
      () => {
        this.updateTableAfterConfigurationChange(
          isThemeChanged,
          isExpandStatusChanged,
          nextProps.plugin.config.groupDefaultExpanded
        );
      }
    );
  };

  /**
   * For apply configuration changes to table
   * @isThemeChanged bool
   * @isExpandStatusChanged bool
   * @groupDefaultExpanded integer //depth of expanded group
   */
  updateTableAfterConfigurationChange = (
    isThemeChanged,
    isExpandStatusChanged,
    groupDefaultExpanded
  ) => {
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.state.columnDefs);

      if (isExpandStatusChanged) {
        if (groupDefaultExpanded > 0) {
          this.gridApi.expandAll();
        } else {
          this.gridApi.collapseAll();
        }
      }

      if (isThemeChanged) {
        let rowGroupColumns = [];
        let pivotColumns = [];
        let measureColumns = [];

        this.state.columnDefs.map(column => {
          if (column.rowGroup === true) {
            rowGroupColumns.push(column.field);
          }

          if (column.pivot === true) {
            pivotColumns.push(column.field);
          }

          if (column.enableValue === true) {
            measureColumns.push(column.field);
          }
        });

        this.gridColumnApi.columnModel.setRowGroupColumns(rowGroupColumns);
        this.gridColumnApi.columnModel.setPivotColumns(pivotColumns);

        let sizes = this.gridApi.getSizesForCurrentTheme();
        this.gridApi.setPivotHeaderHeight(sizes.headerHeight);
        this.gridApi.resetRowHeights();
      }
    }
  };

  /**
   * Checks the column map data for plugin default render or render with data.
   * @param {*} plugin
   */
  findColumnMapHasAnyColumn = plugin => {
    let status = false;

    if (plugin.columnMap === undefined) {
      return status;
    }

    let keys = Object.keys(plugin.columnMap);

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];

      if (plugin.columnMap[key].data && plugin.columnMap[key].data.length > 0) {
        status = true;
        break;
      }
    }

    return status;
  };

  usedColumns = new Map();

  /**
   * Convert column map to column defination for ag grid pivot table.
   * @columnMap object
   */
  createColumnDefFromColumnMap = columnMap => {
    if (columnMap === undefined) {
      return;
    }

    let newColumnDef = [];

    columnMap["columns"]["data"].map(column => {
      newColumnDef.push({
        field: column.displayName,
        pivot: true,
        enableRowGroup: true,
        enablePivot: true,
        keyCreator: params => formatValue(params, column, this.props.plugin.config),
        columnObject: { ...column }
      });

      this.usedColumns.set(column.displayName, "columns");
    });

    columnMap["rows"]["data"].map(column => {
      newColumnDef.push({
        field: column.displayName,
        rowGroup: true,
        enablePivot: true,
        enableRowGroup: true,
        rowDrag: true,
        keyCreator: params => formatValue(params, column, this.props.plugin.config),
        columnObject: { ...column }
      });

      this.usedColumns.set(column.displayName, "rows");
    });

    /**
     * Decides cells align
     * If column def is not exist, returns left
     * If number and align didn't set, returns right
     * If aling set, return align
     * @param {*} columnDef
     */
    const decideTextAlign = columnDef => {
      if (columnDef === undefined || columnDef === null) {
        return "left";
      }

      let column = columnDef.pivotValueColumn ? columnDef.pivotValueColumn.userProvidedColDef.columnObject : undefined;

      if (column === undefined || column === null) {
        return "left";
      } else {
        let isNumber =
          column.dataType === "integer" || column.dataType === "double";

        //checks is column align set
        if (column.align) {
          return column.align;
        } else {
          if (isNumber) {
            return "right";
          } else {
            return "left";
          }
        }
      }
    };

    columnMap["measures"]["data"].map(column => {
      newColumnDef.push({
        field: column.displayName,
        aggFunc: "sum",
        enableValue: true,
        columnObject: { ...column },

        cellStyle: params => {
          let align = decideTextAlign(params.colDef);
          let styleObject = {};

          styleObject["textAlign"] = align;

          // TODO: Use for conditional formatting
          // if (params.value > 4000000) {
          //   return { color: "red", backgroundColor: "green" };
          // }

          return styleObject;
        },
        valueFormatter: params => formatValue(params, column, this.props.plugin.config)
      });

      this.usedColumns.set(column.displayName, "measure");
    });

    return newColumnDef;
  };

  /**
   * To set default customization
   */
  setDefaultPluginCustomize = () => {
    this.setState(
      {
        ...this.initialState,
        columnDefs: this.state.originalColumnDefs,
        rowData: this.state.rowData
      },
      () => this.setApiObjects()
    );
  };

  /*
   * Set api objects to grid api for clear changes
   */
  setApiObjects = () => {
    this.gridColumnApi.columnModel.setPivotMode(this.state.pivotMode);

    let currentColumnDefs = this.gridColumnApi.columnModel.getColumnDefs();
    let isColumnDefsChanged =
      this.state.columnDefs &&
      currentColumnDefs.length !== this.state.columnDefs.length;

    if (isColumnDefsChanged) {
      this.gridColumnApi.columnModel.setColumnDefs([]);
    }

    let newColumnDef = this.createColumnDefFromColumnMap(
      this.props.plugin.columnMap
    );

    let rowGroupColumns = [];
    let pivotColumns = [];
    let measureColumns = [];

    newColumnDef.map(column => {
      if (column.rowGroup === true) {
        rowGroupColumns.push(column.field);
      }

      if (column.pivot === true) {
        pivotColumns.push(column.field);
      }

      if (column.enableValue === true) {
        measureColumns.push(column.field);
      }
    });

    this.gridColumnApi.columnModel.setColumnDefs(newColumnDef);
    this.gridColumnApi.columnModel.setRowGroupColumns(rowGroupColumns);
    this.gridColumnApi.columnModel.setPivotColumns(pivotColumns);

    this.gridApi.filterManager.setFilterModel(null);
    this.gridColumnApi.columnModel.autoSizeAllColumns();
  };

  //Runs when component mount and get data
  onGridReady = (params, newData = undefined) => {
    let data = this.props.plugin.data;

    if (newData) {
      data = newData;
    }

    if (params !== undefined) {
      this.gridParams = params;
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }

    let columnMapHasAnyColumn = this.findColumnMapHasAnyColumn(
      this.props.plugin
    );

    let pluginWithDataCheck =
      columnMapHasAnyColumn && data !== undefined && data.length > 0;

    let newColumnDef = this.createColumnDefFromColumnMap(
      this.props.plugin.columnMap
    );

    if (pluginWithDataCheck) {
      let dataForPlugin = [];

      data.map(datum => {
        let dataObject = {};

        Object.keys(datum).map(key => {
          let typeOfDatum = this.usedColumns.get(key);

          if (typeOfDatum) {
            let d = datum[key];

            if (typeOfDatum === "measure") {
              d = Number.parseFloat(d);
            }

            dataObject[key] = d;
          }
        });

        dataForPlugin.push(dataObject);
      });

      let states = { ...this.initialState, ...this.state };
      this.setState({
        ...states,
        columnDefs: [...newColumnDef],
        rowData: dataForPlugin,
        originalColumnDefs: [...newColumnDef]
      });
    }
  };

  // checks if plugin is rendered
  isPluginRender = (plugin, pluginConfig) => {
    if (plugin.data && plugin.data.length === 0) {
      let noDataTitle = pluginConfig.noDataTitle ? pluginConfig.noDataTitle.trim() : ""
      
      if (noDataTitle === "") {
        return {
          status: false,
          errorMessage: (
            <span>
              {i18n.t("NoDataContent.NoData")} <br></br>{" "}
              {i18n.t("NoDataContent.PluginCouldNotBeVisualized")} 
            </span>
          )
        };
      } else {
        return {
          status: false,
          errorMessage: (
            <span>
              {i18n.t(pluginConfig.noDataTitle)}
            </span>
          )
        }
      }
    }

    return { status: true };
  };

  /**
   * Plugin render function
   * TODO: Parameters will use for cond format, interaction and drilldown integration
   */
  pluginRender = (
    divId,
    data,
    columnMap,
    config,
    condFormats,
    filters,
    drillDownColumnMap
  ) => {
    let theme =
      this.props.plugin.config && this.props.plugin.config.pivotTheme
        ? this.props.plugin.config.pivotTheme
        : "ag-theme-balham";
    let pivotClass = "pivot-table-enhance " + theme;

    let renderStatus = this.isPluginRender(this.props.plugin, columnMap);

    if (!renderStatus.status) {
      return (
        <NoDataContent
          plugin={this.props.plugin}
          content={renderStatus.errorMessage}
          pluginImage={this.props.plugin.image}
        />
      );
    } else {
      return (
        <div style={{ width: "100%", height: "calc(100% - 15px)" }}>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div
              id={"pivot-enhance-" + this.props.plugin.id}
              style={{
                height: "100%",
                width: "100%"
              }}
              className={pivotClass} //ag-theme-material
            >
              <Tooltip tooltip={"Reset"}>
                <Button
                  className={"pivot-clear-button"}
                  onClick={this.setDefaultPluginCustomize}
                >
                  <ClearOutlined style={{ margin: "auto" }} />
                </Button>
              </Tooltip>
              <AgGridReact
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                autoGroupColumnDef={this.state.autoGroupColumnDef}
                suppressDragLeaveHidesColumns={
                  this.state.suppressDragLeaveHidesColumns
                }
                suppressMakeColumnVisibleAfterUnGroup={
                  this.state.suppressMakeColumnVisibleAfterUnGroup
                }
                suppressExpandablePivotGroups={
                  this.state.suppressExpandablePivotGroups
                }
                pivotMode={this.state.pivotMode}
                enableRangeSelection={this.state.enableRangeSelection}
                groupIncludeFooter={this.state.groupIncludeFooter}
                groupIncludeTotalFooter={this.state.groupIncludeTotalFooter}
                rowDragManaged={this.state.rowDragManaged}
                onGridReady={this.onGridReady}
                pivotRowTotals={this.state.pivotRowTotals}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                rowData={this.state.rowData}
                rowStyle={this.state.rowStyle}
                animateRows={this.state.animateRows}
                rowGroupPanelShow={this.state.rowGroupPanelShow}
                groupDisplayType={this.state.groupDisplayType}
                groupDefaultExpanded={this.state.groupDefaultExpanded}
                suppressAggFuncInHeader={this.state.suppressAggFuncInHeader}
                processSecondaryColDef={this.state.processSecondaryColDef}
                excelStyles={this.state.excelStyles}
                defaultExportParams={this.state.defaultExportParams}
                processSecondaryColGroupDef={
                  this.state.processSecondaryColGroupDef
                }
              />
            </div>
          </div>
        </div>
      );
    }
  };

  /**
   * Config component content
   */
  getConfigComponent = props => {
    if (props.config) {
      return (
        <PivotTableEnhanceConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
        />
      );
    }

    return null;
  };

  /**
   * Data component content
   */
  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <PivotTableData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={props.plugin.columnMap}
        pluginId={props.plugin.id}
        plugin={props.plugin}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        didNotJoinedTables={checkTableJoins(
          this.props.join,
          this.props.plugin.columnMap,
          this.props.refreshedPluginId,
          this.props.plugin.id,
          true
        )}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={
          props.changeDoesPluginHasNotJoinedTable
        }
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        drillDowns={this.props.plugin.drillDowns}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * Conditional formatting component content
   */
  getConditionalFormattingComponent = props => {
    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        pluginId={props.plugin.id}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={props.plugin.columnMap}
        updateConditionalFormat={props.updatePlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   * @tempPlugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      rows: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Rows.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Rows.Desc"),
        type: "any",
        data: []
      },
      columns: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Columns.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Columns.Desc"),
        type: "any",
        data: []
      },
      measures: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measures.Name"),
        multiple: true,
        minimumColumnSize: 1,
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measures.Desc"),
        required: true,
        conditionalFormat: true,
        config: [
          {
            targetProperty: "aggregator",
            label: "Default Aggregator",
            inputType: "dropdown",
            inputOptions: {
              multiSelect: false,
              values: [
                "Count",
                "Count Unique Values",
                "List Unique Values",
                "Sum",
                "Integer Sum",
                "Mean",
                "Median",
                "Minimum",
                "Maximum",
                "Sum as % of Total",
                "Sum as % of Rows",
                "Sum as % of Columns",
                "Count as % of Total",
                "Count as % of Rows",
                "Count as % of Columns"
              ],
              defaultValue: "Sum"
            },
            desc: "desc172"
          },
          {
            targetProperty: "hide",
            label: "Hide Values",
            inputType: "checkbox",
            inputOptions: {
              defaultValue: false
            },
            desc: "desc173"
          }
        ],
        data: [],
        dataField: {
          Config: {
            //TODO: This area is set statically and will be changed later.
            Plugin: "pivot-table-enhance",
            aggregator: "Sum",
            hide: false
          }
        }
      },
      hidden: {
        name: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.HiddenMeasures.Name"
        ),
        type: "hidden",
        desc: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.HiddenMeasures.Desc"
        ),
        multiple: true,
        minimumColumnSize: 0,
        data: []
      }
    };
    tempPlugin.columnMap = columnMapping;

    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  render() {
    let pluginConfig = { ...this.props.plugin.config }; 
    let configComponent = null;
    let sortControlErr = true;
    
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent,
        sortControlErr
      );
    }

    let conditionalFormatComponent = null;
    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    return (
      <div style={{height: "100%"}}>
        {this.pluginRender(this.props, this.state, pluginConfig)}
        {configComponent}
        {dataComponent}
        {conditionalFormatComponent}
      </div>
    );
  }
}
