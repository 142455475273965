import {
  BgColorsOutlined,
  ColumnHeightOutlined,
  EyeInvisibleOutlined,
  FontSizeOutlined,
  FormatPainterOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, Row, Select, Tooltip } from "antd";
import $ from "jquery";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import { showError, showNotificationWithIcon } from "../../../../Utils/Notification";
import { get } from "../../../../Utils/WebService";
import i18n from "../../../../Utils/i18next";
import { API_BASE } from "../../../../config";
import { getAdminRoles } from "../../../DashboardPage/RoleStore";
import { TOPOJSON_PATH, validCheck, wellDefinedTopojsonOldPaths } from "../../../GeneralComponents/MapTopoJsonOperations/DefaultTopojsons";
import MapTopoJsonOperations from "../../../GeneralComponents/MapTopoJsonOperations/MapTopoJsonOperations";
import Text from "../../../GeneralComponents/Text/Text";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";
import { InsightsConfig } from "../../RenderJs/config";

const { TextArea } = Input;
const { Option, OptGroup } = Select;

/**
 * Configuration file of MapChoropleth plugin
 */
export default class MapChoroplethConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.config,
      StrokeWidth: this.props.config.StrokeWidth,
      backgroundColor: this.props.config.backgroundColor,
      colours: this.props.config.colours,
      featureCode: this.props.config.featureCode,
      featureDesc: this.props.config.featureDesc,
      height: this.props.config.height,
      legend: this.props.config.legend,
      legendLocation: this.props.config.legendLocation,
      mapTile: this.props.config.mapTile,
      mapTileSelection: this.props.config.mapTileSelection,
      nullColour: this.props.config.nullColour,
      opacity: this.props.config.opacity,
      scaleType: this.props.config.scaleType,
      selectedColor: this.props.config.selectedColor,
      seriesScale: this.props.config.seriesScale,
      showHideButton: this.props.config.showHideButton,
      styleType: this.props.config.styleType,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      topojson: wellDefinedTopojsonOldPaths[this.props.config.topojson]
        ? wellDefinedTopojsonOldPaths[this.props.config.topojson]
        : this.props.config.topojson,
      topojson2: wellDefinedTopojsonOldPaths[this.props.config.topojson2]
        ? wellDefinedTopojsonOldPaths[this.props.config.topojson2]
        : this.props.config.topojson2,
      width: this.props.config.width,
      featuresForSelectedTopoJson: this.props.config
        .featuresForSelectedTopoJson
        ? this.props.config.featuresForSelectedTopoJson
        : [],
      topoJsonList: this.props.config.topoJsonList
        ? this.props.config.topoJsonList
        : [1],
      paletteColours: InsightsConfig.Palettes[this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours]
        : this.props.config.colours,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      allTopoJsonList: [],
      uploadVisible: this.props.uploadVisible,
      condFormat: this.props.config.condFormat,
      condFormatLegend: this.props.config.condFormatLegend,
      wellDefinedTopoJsons: [],
      nullColourChange: false,
      titleColourChange: false,
      selectedColorChange: false,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
    };
  }

  componentWillMount() {
    this.getTopoJsonListForMap();
    this.getAllWellDefinedTopoJsonList();
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        ...nextProps.config,
        featuresForSelectedTopoJson: nextProps.config
          .featuresForSelectedTopoJson
          ? nextProps.config.featuresForSelectedTopoJson
          : [],
        featuresForSelectedTopoJson: nextProps.config
          .featuresForSelectedTopoJson
          ? nextProps.config.featuresForSelectedTopoJson
          : [],
        topoJsonList: nextProps.config.topoJsonList
          ? nextProps.config.topoJsonList
          : [1],
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours]
          : nextProps.config.colours,
        featureCode: nextProps.config.featureCode ? nextProps.config.featureCode : "",
        featureDesc: nextProps.config.featureDesc ? nextProps.config.featureDesc : "",
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        nullColourChange: nextProps.config.nullColourChange,
        titleColourChange: nextProps.config.titleColourChange,
        selectedColorChange: nextProps.config.selectedColorChange,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle
      });
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  /** Remove topojson attribute values for deleted maps */
  checkLayerForEmpty = (topojson, newState, key) => {
    if (validCheck(topojson) && topojson.includes("mapOperations#")) {
      let hasTopojsonLayer1 = newState.allTopoJsonList.filter(map => "mapOperations#" + map.id === topojson);

      if (hasTopojsonLayer1.length === 0) {
        newState["topojson" + key] = [];
        newState["featureCode" + key] = [];
        newState["featureDesc" + key] = [];
      }
    }
  }

  /** After delete any map, check selected topojsons and removes if used. */
  checksTopojsonLayersFromAllList = (newState) => {
    let topojson = this.state.topojson;
    let topojson2 = this.state.topojson2;

    this.checkLayerForEmpty(topojson, newState, "");
    this.checkLayerForEmpty(topojson2, newState, "2");
  }

  /** Get all topojson list */
  getTopoJsonListForMap = () => {
    let url = `${API_BASE}/map/`;

    const successFunc = response => {
      let newState = { ...this.state };

      newState.allTopoJsonList = [...response.data];

      this.checksTopojsonLayersFromAllList(newState)

      this.setState(newState);
    };

    get(url, successFunc);
  };

  /** Get all well defined topojson list */
  getAllWellDefinedTopoJsonList = () => {
    let url = `${TOPOJSON_PATH}`;

    const successFunc = response => {
      let newState = { ...this.state };

      newState.wellDefinedTopoJsons = [...response.data];

      this.setState(newState);
    };

    get(url, successFunc);
  }

  /**
   * Changes the value of text attributes in MapChoropleth plugin use handleChange
   */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  /**
   * Checks whether checkbox is checked or not in MapChoropleth plugin use handleCheck
   */
  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Updates state attributes in MapChoropleth plugin
   */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Changes the title attribute in MapChoropleth plugin use titleAlignOnChange
   */
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Changes the color attribute in MapChoropleth plugin use colorOnChange
   */
  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in MapChoropleth plugin
   */
  colorChangeVisible = () => {
    this.setState({
      ...this.state,
      colorChange: !this.state.colorChange
    });
  };

  nullColourOnChange = obj => {
    this.setState({
      ...this.state,
      nullColour: obj.color
    });

    this.setConfigToPlugin({ type: "nullColour", value: obj.color });
  };

  nullColourChangeVisible = () => {
    this.setState({
      ...this.state,
      nullColourChange: !this.state.nullColourChange
    });
  };

  selectedColorOnChange = obj => {
    this.setState({
      ...this.state,
      selectedColor: obj.color
    });

    this.setConfigToPlugin({ type: "selectedColor", value: obj.color });
  };

  selectedColorChangeVisible = () => {
    this.setState({
      ...this.state,
      selectedColorChange: !this.state.selectedColorChange
    });
  };

  mapTileOnChange = obj => {
    this.setState({
      ...this.state,
      mapTile: obj
    });

    this.setConfigToPlugin({ type: "mapTile", value: obj });
  };

  /**
   * Changes the legend location attribute in MapChoropleth plugin use legendLocationOnChange
   */
  legendLocationOnChange = obj => {
    this.setState({
      ...this.state,
      legendLocation: obj
    });

    this.setConfigToPlugin({ type: "legendLocation", value: obj });
  }

  /**
   * Changes the scale type attribute in MapChoropleth plugin use scaleTypeOnChange
   */
  scaleTypeOnChange = obj => {
    this.setState({
      ...this.state,
      scaleType: obj
    });

    this.setConfigToPlugin({ type: "scaleType", value: obj });
  }

  /** Get single topojson - for defaults */
  getTopoJson = (successFunc, errorFunc, mapId, index, THIS, newState) => {
    if (wellDefinedTopojsonOldPaths[mapId]) {
      mapId = wellDefinedTopojsonOldPaths[mapId];
    }

    let url = `${TOPOJSON_PATH}/${mapId}`;

    const success = response => {
      this.getTopoJsonSuccess(response.data, mapId, index, THIS, newState);
    };

    const error = err => {
      this.getTopoJsonError(err, mapId, index, THIS, newState)
    }

    get(url, success, error, false);
  };

  /** Get single topojson - for tenants */
  getTopoJsonForTenant = (obj, index, THIS, newState) => {
    let mapId = obj.split("mapOperations#")[1];
    let url = `${API_BASE}/map/${mapId}`;

    const success = response => {
      this.getTopoJsonSuccess(response.data, obj, index, THIS, newState);
    };

    const error = err => {
      this.getTopoJsonError(err, obj, index, THIS, newState)
    }

    get(url, success, error, false);
  };

  /**
   * 
   * @param {*} result 
   * @returns 
   * Topojson undefined check
   */
  topojsonValidationCheck = (result) => {
    return (
      result.objects !== undefined &&
      result.objects[Object.keys(result.objects)[0]].geometries !== undefined
    );
  };

  /** Success function for single topojson request */
  getTopoJsonSuccess = (result, obj, index, THIS, newState) => {
    if (typeof result === "object") {
      if (this.topojsonValidationCheck(result)) {
        if ("objects" in result) {
          result = result.objects[Object.keys(result.objects)[0]].geometries[0];

          if ("properties" in result) {
            let propertiesKeys = Object.keys(
              result.properties
            );
            newState["featuresForSelectedTopoJson" + index] = propertiesKeys
            newState["featureCode" + index] = propertiesKeys[0]
            newState["featureDesc" + index] = propertiesKeys[0]
          }
        }
      } else {
        showError(i18n.t("PluginErrors.TopojsonNotValidFromat"));

        newState["featuresForSelectedTopoJson" + index] = []
        newState["featureCode" + index] = ""
        newState["featureDesc" + index] = ""
      }
    } else {
      showError(i18n.t("PluginErrors.TopojsonNotValidFromat"));

      newState["featuresForSelectedTopoJson" + index] = []
      newState["featureCode" + index] = ""
      newState["featureDesc" + index] = ""
    }

    THIS.setState(newState);
    THIS.setConfigToPlugin([
      { type: "topojson" + index, value: obj },
      {
        type: "featuresForSelectedTopoJson" + index,
        value: newState["featuresForSelectedTopoJson" + index]
      },
      { type: "featureCode" + index, value: newState["featureCode" + index] },
      { type: "featureDesc" + index, value: newState["featureDesc" + index] },
    ]);
  };

  /** Error function for single topojson request */
  getTopoJsonError = (err, obj, index, THIS, newState) => {
    showNotificationWithIcon(i18n.t("Error"), err, "error");

    THIS.setState(newState);
    THIS.setConfigToPlugin({ type: "topojson" + index, value: obj });
  };

  /** TopoJson dropdown on change */
  topojsonOnChange = (obj, index = "") => {
    let THIS = this;
    let newState = { ...this.state };

    newState["topojson" + index] = obj;

    let successFunc = this.getTopoJsonSuccess;
    let errorFunc = this.getTopoJsonError;

    let isTopojsonForTenant = obj.includes("mapOperations#");

    if (isTopojsonForTenant) {
      this.getTopoJsonForTenant(obj, index, THIS, newState); // 1 for real id
    } else {
      this.getTopoJson(successFunc, errorFunc, obj, index, THIS, newState);
    }
  };

  isEmptyOrUndefinedValue = value => {
    return value === "" || value === undefined;
  };

  areTheTopojsonFieldsFull = (changingField, index) => {
    if (!("topojson" + index === changingField)) {
      if (this.isEmptyOrUndefinedValue(this.state["topojson" + index])) {
        return false;
      }
    }

    if (!("featureCode" + index === changingField)) {
      if (this.isEmptyOrUndefinedValue(this.state["featureCode" + index])) {
        return false;
      }
    }

    if (!("featureDesc" + index === changingField)) {
      if (this.isEmptyOrUndefinedValue(this.state["featureDesc" + index])) {
        return false;
      }
    }

    return true;
  };

  featureCodeOnChange = (obj, index = "") => {
    let isRerender = this.areTheTopojsonFieldsFull(
      "featureCode" + index,
      index
    );

    this.setState({
      ...this.state,
      ["featureCode" + index]: obj
    });

    if (isRerender) {
      this.setConfigToPlugin({ type: "featureCode" + index, value: obj });
    }
  };

  featureDescOnChange = (obj, index = "") => {
    let isRerender = this.areTheTopojsonFieldsFull(
      "featureDesc" + index,
      index
    );

    this.setState({
      ...this.state,
      ["featureDesc" + index]: obj
    });

    if (isRerender) {
      this.setConfigToPlugin({ type: "featureDesc" + index, value: obj });
    }
  };

  addNewTopojson = () => {
    let tempTopoJsonList = [...this.state.topoJsonList];
    tempTopoJsonList.push(1);

    this.setState({
      ...this.state,
      topoJsonList: tempTopoJsonList
    });

    this.setConfigToPlugin({ type: "topoJsonList", value: tempTopoJsonList });
  };

  removeLastTopojson = () => {
    let newState = { ...this.state };
    let propertyListToBeDelete = undefined;

    if (newState.topoJsonList.length > 1) {
      let indexToBeDeleted = newState.topoJsonList.length;

      newState["topojson" + indexToBeDeleted] = ""
      newState["featureDesc" + indexToBeDeleted] = ""
      newState["featureCode" + indexToBeDeleted] = ""
      newState["featuresForSelectedTopoJson" + indexToBeDeleted] = []

      propertyListToBeDelete = [
        "topojson" + indexToBeDeleted,
        "featureDesc" + indexToBeDeleted,
        "featureCode" + indexToBeDeleted,
        "featuresForSelectedTopoJson" + indexToBeDeleted
      ];

      newState.topoJsonList.splice(-1, 1);
    }

    this.setState(newState);

    this.setConfigToPlugin(
      {
        type: "topoJsonList",
        value: newState.topoJsonList
      },
      propertyListToBeDelete
    );
  };

  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };

  /** Render uploaded map options in topojson list */
  topoJsonOptionsForUploaded = allTopoJsons => {
    if (allTopoJsons.length === 0) {
      return null
    }

    return (<OptGroup label={i18n.t("Dashboard.Settings.TopoJSON.UploadedTopoJsons")}>
      {allTopoJsons.map(topojson => (
        <Option name={topojson.id} value={"mapOperations#" + topojson.id}>
          {topojson.name}
        </Option>
      ))}
    </OptGroup>)
  };

  changeUploadVisible = (status) => {
    this.props.changeUploadVisible(status)
  }

  render() {
    let isUserHasAdminRole = getAdminRoles();

    return (
      <div style={{ color: "#505050" }}>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        {isUserHasAdminRole ?
          <MapTopoJsonOperations
            allTopoJsons={this.state.allTopoJsonList}
            getTopoJsonListForMap={this.getTopoJsonListForMap}
            changeUploadVisible={this.changeUploadVisible}
            visible={this.props.uploadVisible} />
          : null
        }

        <Divider className={"config-divider"} orientation="left">
          {isUserHasAdminRole ?
            <Button
              size={"small"}
              style={{ position: "absolute", right: "20px", zIndex: "1", top: "1px" }}
              onClick={() => this.changeUploadVisible(true)}>
              {i18n.t("Dashboard.Settings.TopoJSON.UploadEdit")}
            </Button>
            : null}

          {i18n.t("Dashboard.Configuration.Fields.Topojson")}
        </Divider>
        {this.state.topoJsonList.map((topojson, index) => (
          <div>
            {index > 0 ? (
              <Divider
                className={"config-divider"}
                orientation="left"
              ></Divider>
            ) : null}
            <Row style={{ marginBottom: "5px" }}>
              <Col style={{ textAlign: "center" }} span={3}>
                <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
              </Col>
              <Col style={{ textAlign: "left" }} span={9}>
                <Text
                  style={{
                    fontSize: "16px",
                    display: "inline-block",
                    marginTop: "2px"
                  }}
                >
                  {i18n.t("Dashboard.Configuration.Fields.Topojson")}
                </Text>
              </Col>
              <Col span={12}>
                <Select
                  showSearch
                  id={"topojson"}
                  style={{ width: "100%" }}
                  placeholder={i18n.t(
                    "Dashboard.Configuration.Fields.SelectATopojson"
                  )}
                  optionFilterProp="children"
                  onChange={val =>
                    this.topojsonOnChange(val, index === 0 ? "" : index + 1)
                  }
                  defaultValue={
                    this.state["topojson" + (index === 0 ? "" : index + 1)]
                  }
                  value={
                    this.state["topojson" + (index === 0 ? "" : index + 1)]
                  }
                >
                  {this.topoJsonOptionsForUploaded(this.state.allTopoJsonList)}

                  <OptGroup label={i18n.t("Dashboard.Settings.TopoJSON.DefaultTopoJsons")}>
                    {this.state.wellDefinedTopoJsons.map(topojson => (
                      <Option name={topojson.name} value={topojson.name}>
                        {i18n.t("Dashboard.Settings.TopoJSON." + topojson.translateKey)}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </Col>
            </Row>
            <Row style={{ marginBottom: "5px" }}>
              <Col style={{ textAlign: "center" }} span={3}>
                <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
              </Col>
              <Col style={{ textAlign: "left" }} span={9}>
                <Text
                  style={{
                    fontSize: "16px",
                    display: "inline-block",
                    marginTop: "2px"
                  }}
                >
                  {i18n.t("Dashboard.Configuration.Fields.FeatureCode")}
                </Text>
              </Col>
              <Col span={12}>
                <Select
                  showSearch
                  id={"featureCode"}
                  style={{ width: "100%" }}
                  placeholder={i18n.t(
                    "Dashboard.Configuration.Fields.SelectFeatureCode"
                  )}
                  optionFilterProp="children"
                  onChange={val =>
                    this.featureCodeOnChange(val, index === 0 ? "" : index + 1)
                  }
                  defaultValue={
                    this.state["featureCode" + (index === 0 ? "" : index + 1)]
                  }
                  value={this.state["featureCode" + (index === 0 ? "" : index + 1)]
                  }
                >
                  {this.state[
                    "featuresForSelectedTopoJson" +
                    (index === 0 ? "" : index + 1)
                  ]
                    ? this.state[
                      "featuresForSelectedTopoJson" +
                      (index === 0 ? "" : index + 1)
                    ].map(featureCode => (
                      <Option name={featureCode} key={featureCode}>{featureCode}</Option>
                    ))
                    : null}
                </Select>
              </Col>
            </Row>
            <Row style={{ marginBottom: "5px" }}>
              <Col style={{ textAlign: "center" }} span={3}>
                <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
              </Col>
              <Col style={{ textAlign: "left" }} span={9}>
                <Text
                  style={{
                    fontSize: "16px",
                    display: "inline-block",
                    marginTop: "2px"
                  }}
                >
                  {i18n.t("Dashboard.Configuration.Fields.FeatureDesc")}
                </Text>
              </Col>
              <Col span={12}>
                <Select
                  showSearch
                  id={"featureDesc"}
                  style={{ width: "100%" }}
                  placeholder={i18n.t(
                    "Dashboard.Configuration.Fields.SelectFeatureDesc"
                  )}
                  optionFilterProp="children"
                  onChange={val =>
                    this.featureDescOnChange(val, index === 0 ? "" : index + 1)
                  }
                  defaultValue={
                    this.state["featureDesc" + (index === 0 ? "" : index + 1)]
                  }
                  value={this.state["featureDesc" + (index === 0 ? "" : index + 1)]
                  }
                >
                  {this.state[
                    "featuresForSelectedTopoJson" +
                    (index === 0 ? "" : index + 1)
                  ]
                    ? this.state[
                      "featuresForSelectedTopoJson" +
                      (index === 0 ? "" : index + 1)
                    ].map(featureDesc => (
                      <Option name={featureDesc} key={featureDesc}>{featureDesc}</Option>
                    ))
                    : null}
                </Select>
              </Col>
            </Row>
          </div>
        ))}
        <Row gutter={15} style={{ marginBottom: "5px", marginTop: "15px" }}>
          <Col span={12}>
            {this.state.topoJsonList.length > 1 ? (
              <Button
                id={"removeTopojson"}
                className={"general-button"}
                onClick={this.removeLastTopojson}
                style={{
                  width: "100%",
                  borderBottom: "0",
                  height: "fit-content",
                  padding: "4px 0",
                  marginBottom: "0",
                  top: "1px",
                  backgroundColor: "#ec6647"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.RemoveLastTopojson")}
              </Button>
            ) : null}
          </Col>
          <Col span={12}>
            <Button
              id={"addNewTopojson"}
              className={"general-button"}
              onClick={this.addNewTopojson}
              style={{
                width: "100%",
                borderBottom: "0",
                height: "fit-content",
                padding: "4px 0",
                marginBottom: "0",
                top: "1px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AddNewTopojson")}
            </Button>
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ScaleType")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              id={"scaleType"}
              style={{ width: "100%" }}
              placeholder={i18n.t(
                "Dashboard.Configuration.Fields.SelectScaleType"
              )}
              optionFilterProp="children"
              onChange={this.scaleTypeOnChange}
              defaultValue={this.state.scaleType}
            >
              <Option name={"Linear"} value="Linear">
                {i18n.t("Dashboard.Configuration.Fields.Linear")}
              </Option>
              <Option name={"Quantile"} value="Quantile">
                {i18n.t("Dashboard.Configuration.Fields.Quantile")}
              </Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnHeightOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Opacity")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"opacity"}
              defaultValue={this.state.opacity}
              value={this.state.opacity}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Legend")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"legend"}
              defaultChecked={this.state.legend}
              checked={this.state.legend}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.SelectLegend")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              id={"selectLegend"}
              style={{ width: "100%" }}
              placeholder={i18n.t(
                "Dashboard.Configuration.Fields.SelectLegend"
              )}
              optionFilterProp="children"
              onChange={this.legendLocationOnChange}
              defaultValue={this.state.legendLocation}
            >
              <Option name={"mapLegendUp"} value="up">
                {i18n.t("Dashboard.Configuration.Fields.Up")}
              </Option>
              <Option name={"mapLegendDown"} value="down">
                {i18n.t("Dashboard.Configuration.Fields.Down")}
              </Option>
              <Option name={"mapLegendLeft"} value="left">
                {i18n.t("Dashboard.Configuration.Fields.Left")}
              </Option>
              <Option name={"mapLegendRight"} value="right">
                {i18n.t("Dashboard.Configuration.Fields.Right")}
              </Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.StrokeWidth")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"StrokeWidth"}
              defaultValue={this.state.StrokeWidth}
              value={this.state.StrokeWidth}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.NullColour")}
            </Text>
          </Col>
          <Col span={12}>
            <Button id={"nullColorOpen"} onClick={this.nullColourChangeVisible} style={{ width: "100%" }}>
              {this.state.nullColourChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.nullColourChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.nullColour}
                onChange={this.nullColourOnChange}
                id={"nullColour"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.SelectedColor")}
            </Text>
          </Col>
          <Col span={12}>
            <Button id={"selectedColorOpen"} onClick={this.selectedColorChangeVisible} style={{ width: "100%" }}>
              {this.state.selectedColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.selectedColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.selectedColor}
                onChange={this.selectedColorOnChange}
                id={"selectedColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Palette")}
            </Text>
          </Col>
          <Col span={4} style={{ paddingRight: "10px" }}>
            <Select
              id={"colours"}
              defaultValue={this.state.colours}
              value={this.state.colours}
              style={{ width: "100%" }}
              onChange={this.coloursOnChange}>
              <Option id={"mapCFlatUIPalette"} value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
              <Option id={"mapCFlatUISoftPalette"} value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
              <Option id={"mapCCoolScalePalette"} value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
              <Option id={"mapCWarmScalePalette"} value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
              <Option id={"mapCHeatmapPalette"} value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
            </Select>
          </Col>
          <Col span={8}>
            <ThemePalette
              colours={this.state.paletteColours}
              update={this.setConfigToPlugin}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.MapTile")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              id={"mapTile"}
              style={{ width: "100%" }}
              placeholder="Select a mapTile"
              optionFilterProp="children"
              onChange={this.mapTileOnChange}
              defaultValue={this.state.mapTile}
            >
              <Option name={"default"} value="Default">Default</Option>
              <Option name={"emptyTile"} value="EmptyTile">EmptyTile</Option>
              <Option name={"basarSoftTile"} value="BasarSoftTile">BasarSoftTile</Option>
              <Option name={"osm"} value="OSM">OSM</Option>
              <Option name={"atlasOsm"} value="AtlasOSM">AtlasOSM</Option>
              <Option name={"topology"} value="Topology">Topology</Option>
              <Option name={"hydda"} value="Hydda">Hydda</Option>
              <Option name={"toner"} value="Toner">Toner</Option>
              <Option name={"waterColour"} value="Watercolour">Watercolour</Option>
              <Option name={"esri"} value="ESRI">ESRI</Option>
              <Option name={"satellite"} value="Satellite">Satellite</Option>
              <Option name={"night"} value="Night">Night</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.MapTileSelection")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              id={"mapTileSelection"}
              style={{ width: "100%" }}
              placeholder={i18n.t(
                "Dashboard.Configuration.Fields.SelectMapTile"
              )}
              optionFilterProp="children"
              onChange={this.mapTileSelectionOnChange}
              defaultValue={this.state.mapTileSelection}
            >
              <Option name={"atlasMapTile"} value="atlas-map-tile">
                {i18n.t("Dashboard.Configuration.Fields.AtlasMapTile")}
              </Option>
              <Option name={"openStreetMapTile"} value="open-street-map-tile">
                {i18n.t("Dashboard.Configuration.Fields.OpenStreetMapTile")}
              </Option>
              <Option name={"emptyTile"} value="empty-tile">
                {i18n.t("Dashboard.Configuration.Fields.EmptyTile")}
              </Option>
              <Option name={"basarSoftTile"} value="BasarSoft-tile">
                {i18n.t("Dashboard.Configuration.Fields.BasarSoftTile")}
              </Option>
            </Select>
          </Col>
        </Row>
        <TitleSettingsOfPlugin
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          changedTitleFontSize={this.state.changedTitleFontSize}
          handleChangeBothOfValues={this.handleChangeBothOfValues}
        />
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"condFormat"}
              defaultChecked={this.state.condFormat}
              checked={this.state.condFormat}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip
              title={
                <>
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
