import { Component } from "react";
import React from "react"
import MeasureHeader from "./MeasureItems/MeasureHeader";
import MeasureBody from "./MeasureItems/MeasureBody";
import $ from "jquery"
import _ from "lodash";
import { compare } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { shadeColor } from "../ButtonFilter/getButtonComponent";
import MeasureDefaultHeader from "./MeasureItems/MeasureDefaultHeader";
import MeasureDefaultBody from "./MeasureItems/MeasureDefaultBody";
import { store } from "../../../..";

// Returns measure tile all components
export default class MeasureTileMainContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    // Checks is pagination equals two compares
    isPaginationEqualsTwoCompares = (i) => {
        if (i === this.props.paginationIndex || (i === this.props.paginationIndex + 1)) {
            return true
        }

        return false
    }

    // Gets button height
    getButtonHeight = () => {
        return $($("#measure-" + this.props.pluginId).find(".measure-row").children()[0]).height()
    }

    // Gets config object
    getConfigObj = (realConfig, data) => {
        let copiedConfig = _.cloneDeep(realConfig)

        if (this.props.condFormats) {
            $("#plugin-" + this.props.pluginId).css("background", copiedConfig?.backgroundColor)

            for (let i = 0; i < this.props.condFormats.length; i++) {
                let condFormat = this.props.condFormats[i]

                if (data[0] && condFormat.TargetName == undefined || condFormat.TargetName === "AllColumns" || condFormat.TargetName === data[0].category) {
                    let compareObj = compare(data[0], condFormat)

                    if (compareObj.status) {
                        copiedConfig["colour"] = condFormat.Style.colour
                        copiedConfig["secondaryColour"] = shadeColor(condFormat.Style.colour, 0, 70)
                        copiedConfig["icon"] = condFormat.Style.icon == undefined ? "" : condFormat.Style.icon
                        copiedConfig["isConfigComesFromConditional"] = copiedConfig["icon"] === "" ? false : true

                        if (this.props.compareType === "single-column") {
                            $("#plugin-" + this.props.pluginId).css("background", condFormat.Style.background.colour)
                        } else {
                            $("#plugin-" + this.props.pluginId).css("background", copiedConfig.backgroundColor)
                        }
                    }
                }
            }
        } else {
            $("#plugin-" + this.props.pluginId).css("background", copiedConfig?.backgroundColor)
        }

        return copiedConfig
    }

    isMeasureDataNotDefaultData = (data) => {
        if (Object.keys(data).length > 4 && data.isDefaultData !== undefined && !data.isDefaultData) {
            return true
        }

        return false
    }

    dumpData = [{
        "category": "Kargo Toplam",
        "categoryDisplayName": "Kargo Toplam",
        "categoryValue": "Kargo Toplam",
        "measureValue": "561.007.292,62",
        "measurePureValue": "561.007.292,62",
        "comesFromDumpData": true,
        "isDefaultData": true
    }]

    /*
    * Returns default measure tile with default data
    */
    defaultMeasureTile = (index) => {
        let reduxState = store.getState()
        let isPluginLoading = reduxState.PluginLoaderReducer.waitForLoadPlugins.has(this.props.pluginId)
        let isDataAvailable = (this.props.columnMap.measure.data.length === 0 && this.props.columnMap.hidden.data.length === 0) || this.props.pluginData[index].isDefaultData === true
        let mustDefaultDataShow = !isPluginLoading && this.props.configArray[index] && isDataAvailable ? true : false

        if (mustDefaultDataShow) {
            return <>
                {this.props.pluginH > 1
                    ? <MeasureDefaultHeader
                        plugin={this.props.plugin}
                        condFormats={this.props.condFormats}
                        pluginHeight={this.props.pluginHeight}
                        pluginW={this.props.pluginW}
                        pluginH={this.props.pluginH}
                        compareType={this.props.compareType}
                        pluginData={this.dumpData}
                        config={this.getConfigObj(this.props.configArray[index], this.dumpData)}
                        pluginId={this.props.pluginId} />
                    : null}
                <MeasureDefaultBody
                    plugin={this.props.plugin}
                    condFormats={this.props.condFormats}
                    triggerNavigation={this.props.triggerNavigation}
                    pluginHeight={this.props.pluginHeight}
                    pluginW={this.props.pluginW}
                    pluginH={this.props.pluginH}
                    compareType={this.props.compareType}
                    pluginData={this.dumpData}
                    config={this.getConfigObj(this.props.configArray[index], this.dumpData)}
                    pluginId={this.props.pluginId}
                    animation={this.props.config.animation} />
            </>
        }
    }

    // Renders measure tile
    renderMeasureTile = () => {
        let isMeasurePaginationAndRenderStatusEnded = (this.props.config.pagination || this.props.config.showPaginationButtons) && this.props.pluginData.length > 2 && !this.props.isRenderStatusStarted
        
        if (isMeasurePaginationAndRenderStatusEnded) {
            return <>
                {this.props.pluginData[this.props.paginationIndex] || this.props.pluginData[this.props.paginationIndex + 1]
                    ? <>
                        {this.props.config.pagination ? <>
                            <button className="measure-pagination-button" onClick={(e) => this.props.increaseDecreasePaginationIndex("dec", e)} style={{ width: "5%", height: this.getButtonHeight() + "px" }}>{`${"<"}`}</button>
                        </> : null}
                        <div style={{ width: `44%`, display: "inline-block" }} className="measure-row">
                            <>
                                {this.props.pluginData[this.props.paginationIndex] ? <>
                                    {this.isMeasureDataNotDefaultData(this.props.pluginData[this.props.paginationIndex]) ?
                                        <>
                                            {this.props.pluginH > 1
                                                ? <MeasureHeader
                                                    plugin={this.props.plugin}
                                                    condFormats={this.props.condFormats}
                                                    pluginHeight={this.props.pluginHeight}
                                                    pluginW={this.props.pluginW}
                                                    pluginH={this.props.pluginH}
                                                    compareType={this.props.compareType}
                                                    pluginData={[this.props.pluginData[this.props.paginationIndex]]}
                                                    config={this.getConfigObj({ ...this.props.configArray[this.props.paginationIndex] }, [this.props.pluginData[this.props.paginationIndex]])}
                                                    pluginId={this.props.pluginId} />
                                                : null}
                                            <MeasureBody
                                                plugin={this.props.plugin}
                                                condFormats={this.props.condFormats}
                                                triggerNavigation={this.props.triggerNavigation}
                                                pluginHeight={this.props.pluginHeight}
                                                pluginW={this.props.pluginW}
                                                pluginH={this.props.pluginH}
                                                compareType={this.props.compareType}
                                                pluginData={[this.props.pluginData[this.props.paginationIndex]]}
                                                config={this.getConfigObj(this.props.paginationIndex === 0 ? { ...this.props.config, ...this.props.configArray[this.props.paginationIndex] } : this.props.configArray[this.props.paginationIndex], [this.props.pluginData[this.props.paginationIndex]])}
                                                pluginId={this.props.pluginId}
                                                animation={this.props.config.duration == undefined ? 1 : this.props.config.duration}
                                                compareLength={this.props.compareLength} />
                                        </> : null}
                                </> : this.defaultMeasureTile(this.props.paginationIndex)}
                            </>
                        </div>
                        <div style={{ width: `44%`, display: "inline-block" }} className="measure-row">
                            <>
                                {this.props.pluginData[this.props.paginationIndex + 1] ? <>
                                    {this.isMeasureDataNotDefaultData(this.props.pluginData[this.props.paginationIndex + 1]) ?
                                        <>
                                            {this.props.pluginH > 1
                                                ? <MeasureHeader
                                                    plugin={this.props.plugin}
                                                    condFormats={this.props.condFormats}
                                                    pluginHeight={this.props.pluginHeight}
                                                    pluginW={this.props.pluginW}
                                                    pluginH={this.props.pluginH}
                                                    compareType={this.props.compareType}
                                                    pluginData={[this.props.pluginData[this.props.paginationIndex + 1]]}
                                                    config={this.getConfigObj({ ...this.props.configArray[this.props.paginationIndex + 1] }, [this.props.pluginData[this.props.paginationIndex + 1]])}
                                                    pluginId={this.props.pluginId} />
                                                : null}
                                            <MeasureBody
                                                plugin={this.props.plugin}
                                                condFormats={this.props.condFormats}
                                                triggerNavigation={this.props.triggerNavigation}
                                                pluginHeight={this.props.pluginHeight}
                                                pluginW={this.props.pluginW}
                                                pluginH={this.props.pluginH}
                                                compareType={this.props.compareType}
                                                pluginData={[this.props.pluginData[this.props.paginationIndex + 1]]}
                                                config={this.getConfigObj({ ...this.props.configArray[this.props.paginationIndex + 1] }, [this.props.pluginData[this.props.paginationIndex + 1]])}
                                                pluginId={this.props.pluginId}
                                                animation={this.props.config.duration == undefined ? 1 : this.props.config.duration}
                                                compareLength={this.props.compareLength} />
                                        </> : null}
                                </> : this.defaultMeasureTile(this.props.paginationIndex + 1)}
                            </>
                        </div>
                        {this.props.config.pagination ? <>
                            <button className="measure-pagination-button" onClick={(e) => this.props.increaseDecreasePaginationIndex("inc", e)} style={{ width: "5%", height: this.getButtonHeight() + "px" }}>{`${">"}`}</button>
                        </> : null}
                    </>
                    : null
                }
            </>
        } else if (!this.props.isRenderStatusStarted) {
            return <>
                {this.props.pluginData.map((data, i) => (
                    <div style={{ width: 100 / this.props.pluginData.length + "%", display: "inline-block", paddingLeft: "5px", paddingRight: "5px" }}>
                        {this.props.configArray[i] ?
                            <>
                                {this.isMeasureDataNotDefaultData(data) ?
                                    <>
                                        {this.props.pluginH > 1 ?
                                            <MeasureHeader
                                                plugin={this.props.plugin}
                                                condFormats={this.props.condFormats}
                                                pluginHeight={this.props.pluginHeight}
                                                pluginW={this.props.pluginW}
                                                pluginH={this.props.pluginH}
                                                compareType={this.props.compareType}
                                                pluginData={[data]}
                                                config={this.getConfigObj({ ...this.props.config, ...this.props.configArray[i] }, [data])}
                                                pluginId={this.props.pluginId} /> : null}
                                        <MeasureBody
                                            plugin={this.props.plugin}
                                            condFormats={this.props.condFormats}
                                            triggerNavigation={this.props.triggerNavigation}
                                            pluginHeight={this.props.pluginHeight}
                                            pluginW={this.props.pluginW}
                                            pluginH={this.props.pluginH}
                                            compareType={this.props.compareType}
                                            pluginData={[data]}
                                            config={this.getConfigObj(i === 0 ? { ...this.props.config, ...this.props.configArray[i] } : this.props.configArray[i], [data])}
                                            pluginId={this.props.pluginId}
                                            animation={this.props.config.duration == undefined ? 1 : this.props.config.duration}
                                            compareLength={this.props.compareLength} />
                                    </>
                                    : this.defaultMeasureTile(i)}
                            </>
                            : null}
                    </div>
                ))}
            </>
        }
    }

    render() {
        return (
            <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} id={"measure-" + this.props.pluginId}>
                {this.renderMeasureTile()}
            </div>
        );
    }
}
