import React, { Component } from "react";
import { Modal, Form, Button, Select, Cascader } from "antd";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import { post } from "../../../Utils/WebService";
import { API_BASE } from "../../../config";
import i18n from "../../../Utils/i18next";
import uuid from "react-uuid";

const host = API_BASE;
const api = "/join";
const url = host + api;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class JoinCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      factTableAliasName: "",
      factDataSourceKey: "",
      dimensionTableAliasName: "",
      dimensionDataSourceKey: "",
      joinType: ""
    }

    this.handleCancel = this.handleCancel.bind(this); 
    this.onModelSelected = this.onModelSelected.bind(this);
    this.factTableChanged = this.factTableChanged.bind(this);
    this.dimensionTableChanged = this.dimensionTableChanged.bind(this);
  }

  onModelSelected(modelAlias) {
    let selectedTables = this.props.selectedModel.tables;
    this.props.onModelChanged(selectedTables);
  }

  createNewTableList(tables) {
    let selectedTables = [];

    tables.forEach((t) => {
      let newColumns = [];
      t.columns.forEach((c) => {
        newColumns.push({
          ...c,
        });
      });

      let newTable = {
        ...t,
      };
      newTable.columns = newColumns;

      selectedTables.push(newTable);
    });

    return selectedTables;
  }

  // handle cancel activity
  handleCancel = () => {
    this.props.onCancel();

    this.setState({
      ...this.state,
      factTableAliasName: "",
      factColumn: "",
      factDataSourceKey: "",
      dimensionTableAliasName: "",
      dimensionColumn: "",
      dimensionDataSourceKey: "",
      joinType: "",
      modelId: "",
      joinId: "",
    })
  };

  componentWillMount() {
    if (this.props.selectedModel) {
      let selectedTables = this.props.selectedModel;
      this.props.onModelChanged(selectedTables);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.factColumn && nextProps.factTable) {
      this.setState({
        ...this.state,
        factDataSourceKey: nextProps.factDataSourceKey,
        factTableAliasName: nextProps.factTableAlias,
      })
    }
    if (this.props.selectedModel != nextProps.selectedModel) {
      let selectedTables = this.props.selectedModel;
      this.props.onModelChanged(selectedTables);
    }

    if (this.props.operationType !== undefined && this.props.operationType !== nextProps.operationType && nextProps.operationType === "update") {
      let joinType = nextProps.joinType === "İç" || nextProps.joinType === "Inner" ? "Inner" : "Outer";

      this.setState({
        factTableAliasName: nextProps.factTableAlias,
        initialFactTableAliasName: nextProps.factTableAlias,
        factColumn: nextProps.factTableDataSourceKey + "?" + nextProps.factTableAlias + ":" + nextProps.factColumnAlias + ":" + nextProps.factTableDisplayName + ":" + nextProps.factColumnDisplayName,
        initialFactColumn: nextProps.factTableDataSourceKey + "?" + nextProps.factTableAlias + ":" + nextProps.factColumnAlias + ":" + nextProps.factTableDisplayName + ":" + nextProps.factColumnDisplayName,
        factDataSourceKey: nextProps.factTableDataSourceKey,
        dimensionTableAliasName: nextProps.dimensionTableAlias,
        initialDimensionTableAliasName: nextProps.dimensionTableAlias,
        dimensionColumn: nextProps.dimensionDataSourceKey + "?" + nextProps.dimensionTableAlias + ":" + nextProps.dimensionColumnAlias + ":" + nextProps.dimensionTableDisplayName + ":" + nextProps.dimensionColumnDisplayName,
        initialDimensionColumn: nextProps.dimensionDataSourceKey + "?" + nextProps.dimensionTableAlias + ":" + nextProps.dimensionColumnAlias + ":" + nextProps.dimensionTableDisplayName + ":" + nextProps.dimensionColumnDisplayName,
        dimensionDataSourceKey: nextProps.dimensionDataSourceKey,
        joinType: joinType,
        initialJoinType: joinType,
        modelId: nextProps.selectedModel.id,
        joinId: nextProps.joinId,
      })
    }
  }

  componentDidMount() {
    this.props.form.validateFields();
  }

  // checks if any value is changed during update operation 
  isUpdateChanged = () => {
    let statement = this.state.initialDimensionColumn === this.state.dimensionColumn && 
                    this.state.initialFactColumn === this.state.factColumn &&
                    this.state.initialJoinType === this.state.joinType &&
                    this.state.initialFactTableAliasName === this.state.factTableAliasName &&
                    this.state.initialDimensionTableAliasName === this.state.dimensionTableAliasName;

    return statement;
  }

  // handleSubmit is triggered when the join is editted or created
  handleSubmit = (e) => {
    e.preventDefault();

    if(this.isUpdateChanged()) {
      showNotificationWithIcon(
        i18n.t("Model.JoinNoChange"),
        null,
        "warning"
      );
      return;
    }
    
    let modelId = this.props.selectedModel.id;

    let joins = [];
    let factTable = this.state.factColumn.split(":")[0];
    let factTableDataSourceKey = this.state.factDataSourceKey;
    let factTableAlias = this.state.factTableAliasName;
    let factColumn = this.state.factColumn.split(":")[1];

    let dimensionTable = this.state.dimensionColumn.split(":")[0];
    let dimensionTableDataSourceKey = this.state.dimensionDataSourceKey;
    let dimensionTableAlias = this.state.dimensionTableAliasName;
    let dimensionColumn = this.state.dimensionColumn.split(":")[1];

    let factTableDisplayName = this.state.factColumn.split(":")[2];
    let factColumnDisplayName = this.state.factColumn.split(":")[3];
    let dimensionTableDisplayName = this.state.dimensionColumn.split(":")[2];
    let dimensionColumnDisplayName = this.state.dimensionColumn.split(":")[3];

    let joinType = this.state.joinType;
    let joinId = this.state?.joinId;

    joins.push({
      modelId: modelId,
      factTableAlias: factTableAlias,
      factTableDataSourceKey: factTableDataSourceKey,
      factColumnAlias: factColumn,
      dimensionTableAlias: dimensionTableAlias,
      dimensionTableDataSourceKey: dimensionTableDataSourceKey,
      dimensionColumnAlias: dimensionColumn,
      id: joinId ? joinId : uuid(),

      factTableDisplayName: factTableDisplayName,
      factColumnDisplayName: factColumnDisplayName,
      dimensionTableDisplayName: dimensionTableDisplayName,
      dimensionColumnDisplayName: dimensionColumnDisplayName,
      joinType: joinType,
    });

    let joinDataForUpdateModelTables = {
      factTableAlias : factTable,
      dimensionTableAlias: dimensionTable,
    }

    let notificationMessage;

    if (this.props.operationType === "update") {
      notificationMessage = i18n.t("Model.JoinEditSuccessfull")
    }

    if (this.props.operationType === "create") {
      notificationMessage = i18n.t("Model.JoinCreateSuccessfull")
    }

    // triggered if submition is valid for backend
    const success = () => {
      showNotificationWithIcon(
        notificationMessage,
        null,
        "success"
      );
      
      if (this.props.comeFromPluginData) {
        this.props.addJoinToJoinList(joins[0])
      }

      this.props.updateModelTablesForJoin(joinDataForUpdateModelTables)
      this.props.getModelTables(modelId)
      
      this.handleCancel()
    }

    // triggered if submition is invalid for backend
    const error = (error) => {
      showNotificationWithIcon(
        i18n.t("Model.JoinCreateFailed"),
        error,
        "error"
      );
    }

    post(
      url,
      joins,
      success,
      error
    );
  };

  // factTableChange is triggered when fact table is change on form
  factTableChanged(factValue, item) {
    let factAlias = "";
    let factDataSourceKey = item[0]?.dataSourceKey;
    let isDimensionMustEmpty = false;
    let factColumn = factValue[1]

    if (item[0]) {
      this.setState({
        ...this.state,
        factTableAliasName: factValue[0],
        factDataSourceKey: factDataSourceKey,
        factColumn: factColumn
      })
    } else {
      isDimensionMustEmpty = true

      this.setState({
        ...this.state,
        factTableAliasName: "",
        factDataSourceKey: "",
        factColumn: ""
      })

      this.props.form.resetFields(["dimensionColumns"])
    }

    if (factValue.length > 0) {
      factAlias = factValue[0];
    }

    let selectedTables = this.createNewTableList(this.props.tables);

    selectedTables.forEach((s) => {
      if (s.aliasName === factAlias && s.tableType === "None") {
        s.tempTableType = "Fact";
      }

      if (
        s.aliasName === this.props.prevFactAlias &&
        s.tempTableType !== "None"
      ) {
        s.tableType = "None";
      }
    });

    this.props.onModelChanged(selectedTables, factAlias, true);
  }

  // dimensionTableChange is triggered when dimension table is change on form
  dimensionTableChanged(dimensionValue) {
    let dimensionAlias = "";
    let dimensionDataSourceKey = "";
    let dimensionColumn = dimensionValue[1];

    if (dimensionValue.length > 0) {
      dimensionAlias = dimensionValue[0];
      dimensionDataSourceKey = dimensionValue[1].split("?")[0];
      this.setState({
        ...this.state,
        dimensionTableAliasName: dimensionValue[0],
        dimensionDataSourceKey: dimensionDataSourceKey,
        dimensionColumn: dimensionColumn
      })
    }

    let selectedTables = this.createNewTableList(this.props.tables);

    selectedTables.forEach((s) => {
      if (s.aliasName === dimensionAlias && s.tableType === "None") {
        s.tempTableType = "Dimension";
      }

      if (s.aliasName === this.props.prevDimensionAlias && s.tempTableType !== "None") {
        s.tableType = "None";
      }
    });

    this.props.onModelChanged(selectedTables, dimensionAlias, false);
  }

  // Handle join type select change.
  handleJoinTypeChange(joinValue) {
    if(joinValue) {
      this.setState({
        ...this.state,
        joinType: joinValue
      })
    }
  }

  // Check if submit button is disabled.
  handleSubmitButtonDisable(targetDataWithoutSelectedFactTable) {
    if (this.state.factTableAliasName && this.state.dimensionTableAliasName && this.state.joinType && targetDataWithoutSelectedFactTable?.length > 0) {
      return false;
    }
    return true;
  }

  render() {
    const { Option } = Select;

    const factTables = this.props.join ? this.props.join
      .filter((s) => {
        if (s.factTableAlias)
          return true;
        return false;
      })
      .map((s) => {
        return s.factTableAlias;
      }) : [];

    const dimensionTables = this.props.join ? this.props.join
      .filter((s) => {
        if (s.dimensionTableAlias)
          return true;
        return false;
      })
      .map((s) => {
        return s.dimensionTableAlias;
      }) : [];

    const sourceData =
      this.props.tables &&
      this.props.tables
        .filter((s) => {
          if (s.tempTableType || !dimensionTables.includes(s.aliasName)) {
            if (s.tableType !== "Dimension") {
              return true;
            }
          }
          return false;
        })
        .map((s) => {
          return {
            label: s.displayName,
            value: s.aliasName,
            dataSourceKey: s.dataSourceKey,
            children: s.columns.map((c) => {
              return {
                label: c.displayName,
                value:
                  s.dataSourceKey +
                  "?" +
                  s.aliasName +
                  ":" +
                  c.aliasName +
                  ":" +
                  s.displayName +
                  ":" +
                  c.displayName,
              };
            }),
          };
        });

    const targetData =
      this.props.tables &&
      this.props.tables
        .filter((s) => {
          if (s.tempTableType || !factTables.includes(s.aliasName)) {
            if (s.tableType !== "Fact") {
              return  true;
            }
          }
          return false;
        })
        .map((s) => {
          return {
            label: s.displayName,
            value: s.aliasName,
            dataSourceKey: s.dataSourceKey,
            children: s.columns.map((c) => {
              return {
                label: c.displayName,
                value:
                  s.dataSourceKey +
                  "?" +
                  s.aliasName +
                  ":" +
                  c.aliasName +
                  ":" +
                  s.displayName +
                  ":" +
                  c.displayName,
              };
            }),
          };
        });

    const targetDataWithoutSelectedFactTable = (targetData !== undefined && this.state.factDataSourceKey) ? targetData.filter(data => data.dataSourceKey + "_" + data.value !== this.state.factDataSourceKey + "_" + this.state.factTableAliasName && this.state.factDataSourceKey.split(".")[0] === data.dataSourceKey.split(".")[0]) : targetData

    const sourceDataWithoutSelectedDimensionTable = (sourceData !== undefined && this.state.dimensionDataSourceKey) ? sourceData.filter(data => data.dataSourceKey + "_" + data.value !== this.state.dimensionDataSourceKey + "_" + this.state.dimensionTableAliasName && this.state.dimensionDataSourceKey.split(".")[0] === data.dataSourceKey.split(".")[0]) : sourceData;

    const {
      getFieldError,
      isFieldTouched,
    } = this.props.form;

    const factColumnError =
      isFieldTouched("factColumn") && getFieldError("factColumn");
    const dimensionColumnsError =
      isFieldTouched("dimensionColumns") && getFieldError("dimensionColumns");
    const joinTypeError =
      isFieldTouched("joinType") && getFieldError("joinType");

    const filter = (inputValue, path) => {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    };

    return (
      <Modal
        title={this.props.operationType === "update" ? i18n.t("Model.JoinEdition") : i18n.t("Model.JoinCreation")}
        centered
        visible={this.props.visible}
        onCancel={() => this.handleCancel()}
        footer={null}
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item
            validateStatus={factColumnError ? "error" : ""}
            help={factColumnError || ""}
          >
            <span style={{color: "red"}}>* </span> {i18n.t("Model.FactTableColumn")}
            <Cascader
              options={sourceDataWithoutSelectedDimensionTable}
              allowClear={false}
              showSearch={{
                filter,
              }}
              onChange={(value, item) => this.factTableChanged(value, item)}
              value={this.state.factTableAliasName && this.state.factColumn ? [this.state.factTableAliasName, this.state.factColumn] : undefined}
              placeholder={i18n.t("JoinTable.SelectPlaceholder")}
            />
          </Form.Item>
          <Form.Item
            validateStatus={dimensionColumnsError ? "error" : ""}
            help={dimensionColumnsError || ""}
          >
            <span style={{color: "red"}}>* </span> {i18n.t("Model.DimensionTablesColumns")}
            <Cascader
              options={targetDataWithoutSelectedFactTable}
              allowClear={false}
              disabled={(this.state.factDataSourceKey === "" && this.state.factTableAliasName === "")}
              showSearch={{
                filter,
              }}
              onChange={this.dimensionTableChanged}
              value={this.state.dimensionTableAliasName && this.state.dimensionColumn ? [this.state.dimensionTableAliasName, this.state.dimensionColumn] : undefined}
              placeholder={i18n.t("JoinTable.SelectPlaceholder")}
            />
          </Form.Item>
          <Form.Item
            validateStatus={joinTypeError ? "error" : ""}
            help={joinTypeError || ""}
          >
            <span style={{color: "red"}}>* </span> {i18n.t("Model.JoinType")}
            <Select
              value={this.state.joinType ? i18n.t(this.state.joinType) : undefined}
              showSearch
              placeholder={i18n.t("JoinTable.SelectPlaceholder")}
              onSelect={(value) => this.handleJoinTypeChange(value)}
              style={{
                width: "100%",
              }}
            >
              <Option name={"joinInner"} value="Inner"> {i18n.t("Inner")} </Option>
              <Option name={"joinOuter"} value="Outer"> {i18n.t("Outer")} </Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              id="createJoinButton"
              type="primary"
              htmlType="submit"
              disabled={this.handleSubmitButtonDisable(targetDataWithoutSelectedFactTable)}
            >
              {this.props.operationType === "create" ? i18n.t("Model.Create") : i18n.t("Model.Edit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedHorizontalLoginForm = Form.create({
  name: "horizontal_login",
})(JoinCreate);

export default WrappedHorizontalLoginForm;
