import React, { Component } from "react";
import i18n from "../../Utils/i18next";
import { ErrorBoundary } from "react-error-boundary";
import { LimitedStack } from "../../Utils/LimitedStack";
import NavigationDashboard from "../NavigationDashboard/NavigationDashboard";

const clone = require("rfdc")();

/** 
 * It does the operations of opening a saved report and navigation from one report to other reports.
 */
export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stateHistory: new LimitedStack(20),
      errorFallback: false
    };
  }

  /**
   * TODO: When error boundry add, function will be use. 
   * Error boundry fallback method. This method catches errors and revert to before states.
   * @param {*} param0 
   * @param {*} THIS 
   */
  errorFallback({ error, resetErrorBoundary }, THIS) {
    let stateHistory = this.state.stateHistory;
    stateHistory.pop();

    THIS.setState({
      ...THIS.state,
      stateHistory: stateHistory,
      errorFallback: true
    });

    resetErrorBoundary();

    return null;
  }

  /**
   * Collects the states we want in a limited stack.
   */
  addStateToHistory = state => {
    /*let stateHistory = this.state.stateHistory;
    const isStateWillStore =
      stateHistory.isEmpty() || stateHistory.peek().value !== state;

    if (state !== undefined && isStateWillStore) {
      stateHistory.push(clone(state));
    }

    this.setState({
      ...this.state,
      stateHistory: stateHistory,
      errorFallback: false
    });
    */
  };

  updateErrorFallbackStatus = status => {
    this.setState({
      ...this.state,
      errorFallback: status
    });
  };

  /**
   * TODO error boundary will add.
   * <ErrorBoundary
          FallbackComponent={error => this.errorFallback(error, this)}
        >
        </ErrorBoundary>
   */

  render() {
    return (
          <NavigationDashboard
            errorFallback={this.state.errorFallback}
            lastState={this.state.stateHistory.peek().value} // knownBestLastState
            updateErrorFallbackStatus={this.updateErrorFallbackStatus}
            addStateToHistory={this.addStateToHistory}
          />
    );
  }
}
