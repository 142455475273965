import React, { Component } from "react";
import { Button, Modal, Input, Layout, Row, Select } from "antd";
import { get } from "../../../Utils/WebService";
import TreeModel from "../../components/treeModel/TreeModel";
import { getModelRealDb, getDataSourceSchemaNames, getDataSourceSchema, removeDataSourceSchemaFromModel, removeAllDataSourceSchemaFromModel } from "./CreateModelAction";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import { connect } from "react-redux";
import { API_BASE } from "../../../config";
import { post } from "../../../Utils/WebService";
import "./CreateModel.css";

const { Option } = Select;

class CreateModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.visible,
      modelName: "",
      model: {},
      checkedModelItem: [],
      selectedSchemas: [],
      schema: {}, // reducerdan okunan model datası
      modelList: []
    };
  }

  componentWillMount() {
    // this.props.getModelRealDb();
    this.props.getDataSourceSchemaNames();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.visible != nextProps.visible) {
      this.setState({
        visible: nextProps.visible
      });
      if (nextProps.visible) {
        // this.props.getModelRealDb();
        this.props.getDataSourceSchemaNames();
      }
      else{
        this.props.removeAllDataSourceSchemaFromModel();
        this.cleanState();
      }
    }

    if (this.props.state.CreateModelReducer.schema != nextProps.state.CreateModelReducer.schema) {
      this.setState({
        schema: nextProps.state.CreateModelReducer.schema
      });
    }
  }

  cleanState = () => {
    this.setState({
      modelName: "",
      model: {},
      checkedModelItem: [],
      selectedSchemas: [],
      schema: {}, // reducerdan okunan model datası
      modelList: []
    })
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({
      modelName: e.target.value
    });
  };

  changeTreeModel = (check, model) => {
    model.businessModel = this.state.modelName;
    model.displayName = this.state.modelName;
    model.name = this.state.modelName;

    this.setState({
      checkedModelItem: check,
      model: model
    });
  };

  saveTreeModel = action => {
    this.setState({
      model: {
        name: this.state.modelName,
        displayName: this.state.modelName
      }
    });

    if (this.state.checkedModelItem.length == 0) {
      showNotificationWithIcon(
        "No selected table or column",
        "You must select columns you want to add to the model",
        "error"
      );
    } else {
      let checkedItemList = [];
      let checkedItemObject = {};
      let checkArray = [];
      for (let obj of this.state.checkedModelItem) {
        let itemKeys = obj.split(",");
        checkArray.push(itemKeys);

        if (Object.keys(itemKeys).length === 3) {
          checkedItemObject = { ...this.state.model.tables[itemKeys[0]] };
          checkedItemObject.tables = {
            ...this.state.model.tables[itemKeys[0]].tables[itemKeys[1]]
          };
          checkedItemObject.tables.columns = {
            ...this.state.model.tables[itemKeys[0]].tables[itemKeys[1]].columns[
            itemKeys[2]
            ]
          };

          checkedItemList.push(checkedItemObject.tables);
        } else if (Object.keys(itemKeys).length === 2) {
          checkedItemObject = { ...this.state.model.tables[itemKeys[0]] };
          checkedItemObject.tables = {
            ...this.state.model.tables[itemKeys[0]].tables[itemKeys[1]]
          };
        }
      }

      let selectedArrayList = [];
      checkedItemList.map(tbl => {
        if (selectedArrayList[tbl.name] == undefined) {
          selectedArrayList[tbl.name] = { ...tbl };
          selectedArrayList[tbl.name].columns = [];
          selectedArrayList[tbl.name].columns.push(tbl.columns);
        } else {
          selectedArrayList[tbl.name].columns.push(tbl.columns);
        }
      });

      let modelClone = JSON.parse(JSON.stringify(this.state.model));
      modelClone.tables = [];

      for (let key in selectedArrayList) {
        modelClone.tables.push(selectedArrayList[key]);
      }
      this.setState({
        model: modelClone
      });

      let status, title, message;
      const successFunc = response => {
        if (response.data == "OK") {
          status = "success";
          title = "Model successfully added";
          message = null;

          if (action === "saveAndGoJoining") {
            this.navigateToJoining();
          }
        }

        showNotificationWithIcon(title, message, status);
        this.props.onConfirm();
      };

      const errorFunc = response => {
        console.trace("response err=", response);
      };

      let url = `${API_BASE}/subjectArea`;
      post(url, modelClone, successFunc, errorFunc);

      this.props.onChangeVisible();
    }
  };

  navigateToJoining = () => {
    const { model } = this.state;
    this.props.history.push({
      pathname: "/join",
      search: `?model=newModel`,
      state: { model }
    });
  };

  menu = () => {
    return this.props.state.CreateModelReducer.dataSourceSchemaNames.map(item => <Option value={item.SCHEMA_NAME}>{item.SCHEMA_NAME}</Option>)
  }

  handleSelectDataSourceSchema = selectedSchemas => {
    if(selectedSchemas.length > 0 && selectedSchemas.length > this.state.selectedSchemas.length){
      this.props.getDataSourceSchema(selectedSchemas[selectedSchemas.length-1])
      this.setState({
        selectedSchemas
      })
    }
  }

  handleDeselectDataSourceSchema = deselectedSchema => {
    let {selectedSchemas, schema} = this.state;
    if(selectedSchemas.length > 0 && deselectedSchema.length > 0){
      selectedSchemas.splice(selectedSchemas.indexOf(deselectedSchema), 1); 

      this.setState({
        selectedSchemas
      })

      this.props.removeDataSourceSchemaFromModel(deselectedSchema)
    }
  }

  addSchemaToModel = value => {
    // this.props.getDataSourceSchemaColumns(this.state.selectedSchemas);
  }

  render() {
    return (
      <div>
        <Modal
          title="Create Model"
          visible={this.props.visible}
          // onOk={() => this.saveTreeModel("save")}
          onCancel={this.props.onChangeVisible}
          // okText="Save"
          footer={
            <div>
              <Button
                key="save"
                type="primary"
                ghost
                onClick={() => this.saveTreeModel("save")}
              >
                Save
              </Button>
              <Button
                key="saveAndGoJoining"
                type="primary"
                onClick={() => this.saveTreeModel("saveAndGoJoining")}
              >
                Save & Go Joining
              </Button>
              <Button key="cancel" onClick={this.props.onChangeVisible}>
                Cancel
              </Button>
            </div>
          }
        >
          <Row>
            <label>Model Name: &nbsp;</label>
          </Row>
          <Row>
            <Input
              id="modelName"
              name="modelName"
              value={this.state.modelName}
              onChange={this.handleChange}
              placeholder="Enter model name"
              required
            />
          </Row>
          <Row>
            <Select 
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="select datasource to create model"
              optionLabelProp="value"
              allowClear="true"
              value={this.state.selectedSchemas}
              onDeselect={this.handleDeselectDataSourceSchema}
              onChange={this.handleSelectDataSourceSchema}>
              {this.menu()}
            </Select>
            {/* <Button type="primary" shape="circle" icon="plus-square" onClick={this.addSchemaToModel} /> */}
          </Row>
          <Row>
            <Layout>
              {/* checked keys props olarak alınacak */}
              <TreeModel
                createModel={true}
                changeTreeModel={this.changeTreeModel}
                // modelData={schema}
                modelData={this.state.schema}
              />
            </Layout>
          </Row>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = { getModelRealDb, getDataSourceSchema, getDataSourceSchemaNames, removeDataSourceSchemaFromModel, removeAllDataSourceSchemaFromModel };

const mapStateToProps = state => {
  return {
    state: state
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateModel);
