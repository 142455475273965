import { SET_PLUGIN_UTILS } from './MapChoroplethDetailAction';

const initialState = {
    pluginLayers: {},
    detailCallback: "",
    detailCallbackForInteraction: "",
    event: "",
    listOfActionsVisibility: false,
    mousePositionLayer: {},
    pluginId: "",
    currentIndex: 1
};

/**
 * Reducer for managing map choropleth action states
 */
const setChoroplethUtils = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLUGIN_UTILS:
      return { 
        pluginLayers: action.payload.pluginLayers ? {...state.pluginLayers, ...action.payload.pluginLayers} : state.pluginLayers,
        detailCallback: action.payload.detailCallback, 
        event: action.payload.event, 
        listOfActionsVisibility: action.payload.listOfActionsVisibility, 
        mousePositionLayer: action.payload.mousePositionLayer, 
        pluginId: action.payload.pluginId,
        currentIndex: action.payload.currentIndex,
        detailCallbackForInteraction: action.payload.detailCallbackForInteraction
    };
    default:
      return state;
  }
};

export default setChoroplethUtils;