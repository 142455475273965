import React from "react";
import i18n from "../../../Utils/i18next";
import Text from "../Text/Text";
import { Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

/**
 * Session Variable Description
 */
export default class SessionVariableFooter extends React.Component {
    /**
     * Prepare message to show in HTML
     * 
     * @param {*} text 
     * @returns 
     */
    messageInHTML = (text) => {
        text = `${text}`
            .replaceAll(" ", "&nbsp;")
            .replaceAll("\n", "<br />");


        return text;
    }

    /**
     * Prepare resut to show in HTML
     * @param {*} payload 
     * @returns 
     */
    resultInHTML = (payload) => {        
        let text = payload instanceof Array
            ? payload?.join("<br/>")
            : `${payload}`

        text = text
            .replaceAll("\\", "\\\\")
            .replaceAll(" ", "&nbsp;");

        return text;
    }

    render() {
        let testResult = this.props.testResult;

        return (
            <div style={{ width: "100%", padding: "0 2px 8px 2px" }}>
                <div
                    style={{
                        width: "100%",
                        height: "40px",
                        padding: "8px 14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    <Text
                        style={{
                            fontSize: "1rem",
                            display: "block",
                            color: "#225f8f"
                        }}
                    >
                        {i18n.t("SessionVariables.TestQueryResult")}
                    </Text>
                </div>
                <div
                    style={{
                        width: "100%",
                        height: "calc(100% - 40px)",
                        padding: " 0px 14px 8px 14px",
                        overflow: "auto"
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "fit-content",
                            fontFamily: "monospace",
                        }}
                    >
                        {
                            testResult?.status === "processing" ? (
                                <Text
                                    style={{
                                        display: "block",
                                        width: "100%",
                                        color: "#555"
                                    }}
                                >
                                    {i18n.t("EXECUTING")}
                                </Text>
                            ) : testResult?.result ? (
                                <>
                                    <span
                                        style={{
                                            width: "100%",
                                            fontWeight: "bold",
                                            wordBreak: "break-all"
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: this.resultInHTML(testResult.result)
                                        }}
                                    />
                                    <br />
                                    {"-".repeat(5)}
                                    <br />
                                    {
                                        testResult?.status === "success" ||
                                            (testResult?.status === "error" && testResult?.message) ? (
                                            <span
                                                style={{
                                                    color: testResult?.status === "error" ? "red" : "green"
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html: testResult.status === "success"
                                                        ? i18n.t("Success").toUpperCase()
                                                        : this.messageInHTML(testResult?.message)
                                                }}
                                            />
                                        ) : null
                                    }
                                </>
                            ) : (
                                <Text
                                    style={{
                                        display: "block",
                                        width: "100%",
                                        color: "#555"
                                    }}
                                >
                                    {i18n.t("SessionVariables.TestQueryDescription")}
                                </Text>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}