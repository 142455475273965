import React from "react";
import DataTableList from "./DataTableList";
import FormulaList from "./FormulaList";
import FormulaCodeEditor from "./FormulaCodeEditor";
import FormulaDescription from "./FormulaDescription";
import { Modal } from "antd";
import { connect } from "react-redux";
import { setFormulaEditorContent } from "./FormulaEditorAction";
import i18n from "../../../Utils/i18next";
import "./FormulaEditor.css";
import { store } from "../../..";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import initVispLang from "./visp-lang";
import SessionVariableList from "./SessionVariableList";
import _ from "lodash";

/**
 * Formula Editor's main component
 *
 * This component consists of:
 *  - `DataTableList`
 *  - `FormulaCodeEditor`
 *  - `FormulaDescription`
 *  - `FormulaList`
 */
class FormulaEditor extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      column: null,
      model: null,
      languageId: null,
      collapsedSessionVariables: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    let flag = false;
    let newState = { ...this.state };

    if (nextProps.editorContent.visible !== this.state.visible) {
      newState.visible = nextProps.editorContent.visible;
      newState.collapsedSessionVariables = false;

      flag = true;
    }

    if (nextProps.editorContent.column !== this.state.column) {
      newState.column = nextProps.editorContent.column;
      flag = true;
    }

    if (nextProps.model?.id !== this.state.model?.id) {
      flag = true;

      newState.model = nextProps.model;
      newState.languageId = initVispLang(nextProps.model);
    }

    if (!_.isEqual(nextProps.sessionVariables, this.state.sessionVariables)) {
      flag = true;
      
      newState.sessionVariables = nextProps.sessionVariables;
      newState.isSessionVariableAreaValid = nextProps.sessionVariables?.size;
    }

    if (flag === true) {
      this.setState(newState);
    }
  }

  /**
   * Closes and resets formula editor
   */
  resetFormulaEditor = () => {
    this.props.editorContent.visible = false;
    this.props.editorContent.code = "";
    this.props.editorContent.selectedFormula = null;

    store.dispatch(setFormulaEditorContent(this.props.editorContent));
  };

  /**
   * Toggles CollapsedSessionVariable status
   */
  toggleCollapsedSessionVariables = () => {
    this.setState({
      ...this.state,
      collapsedSessionVariables: !this.state.collapsedSessionVariables,
    });
  };

  /**
   * Shows confirm cancel modal
   */
  showConfirm = () => {
    Modal.confirm({
      title: i18n.t("FormulaEditor.Titles.AreYouSureCancel"),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("FormulaEditor.YourChangesWillBeLost"),
      centered: true,
      zIndex: "1001",
      cancelText: i18n.t("Cancel"),
      cancelButtonProps: {
        className: "general-button-outlined",
        style: {
          padding: "4px 12px",
          marginRight: "6px",
        },
      },
      okText: i18n.t("OK"),
      okButtonProps: {
        className: "general-button",
        style: {
          padding: "4px 12px !important",
          margin: "0",
          marginLeft: "6px",
          width: "fit-content",
        },
      },
      onOk: () => {
        Modal.destroyAll();
        this.resetFormulaEditor();
      },
    });
  };

  /**
   * Checks for changes
   * It opens the confirm cancel modal if there is any change
   */
  exitFormulaEditor = () => {
    let column = this.state.column;

    if (column) {
      if (this.props.editorContent.code !== column.state.columnProperties.Code) {
        this.showConfirm();
      } else {
        this.resetFormulaEditor();
      }
    }
  };

  /**
   * Sets column code, resets and closes formula editor
   */
  applyChanges = () => {
    let column = this.state.column;

    if (column) {
      column.state.columnProperties.Code = this.props.editorContent.code;
      column.props.updateColumnProperties(column.state.columnProperties);

      this.resetFormulaEditor();
    }
  };

  render() {
    return (
      <Modal
        visible={this.state.visible}
        closable={false}
        maskClosable={true}
        onCancel={this.exitFormulaEditor}
        destroyOnClose={true}
        width="90%"
        centered={true}
        footer={false}
        className="formula-editor-modal"
        bodyStyle={{
          height: "80vh",
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          alignContent: "start",
          padding: "0",
          overflow: "hidden",
        }}
      >
        {this.state.visible && (
          <div
            style={{
              minHeight: "450px",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyItems: "center",
            }}
          >
            <div
              style={{
                order: "1",
                width: "20%",
                height: "100%",
                borderRight: "solid 1px #dadada",
              }}
            >
              <div style={{ height: "100%", paddingBottom: "8px" }}>
                <DataTableList
                  model={this.props.model}
                  collapsed={this.state.collapsedSessionVariables}
                  isSessionVariableAreaValid={this.state.isSessionVariableAreaValid}
                />
                {
                  this.state.isSessionVariableAreaValid && (
                    <SessionVariableList
                      sessionVariables={this.state.sessionVariables}
                      toggleCollapse={this.toggleCollapsedSessionVariables}
                      collapsed={this.state.collapsedSessionVariables}
                    />
                  )
                }
              </div>
            </div>
            <div
              style={{
                order: "2",
                width: "60%",
                height: "100%",
              }}
            >
              <FormulaCodeEditor
                height="80%"
                onCancel={this.exitFormulaEditor}
                onApply={this.applyChanges}
                language={this.state.languageId}
              />
              <div
                style={{
                  display: "flex",
                  overflow: "auto",
                  height: "20%",
                  borderTop: "solid 1px #dadada",
                }}
              >
                <FormulaDescription />
              </div>
            </div>
            <div
              style={{
                order: "3",
                width: "20%",
                height: "100%",
                borderLeft: "solid 1px #dadada",
              }}
            >
              <div style={{ height: "100%", paddingBottom: "8px" }}>
                <FormulaList />
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

/**
 * Set action method to props
 */
const mapDispatchToProps = {
  setFormulaEditorContent,
};

/**
 * Set store objects to state
 *
 * @param {*} state
 * @returns
 */
const mapStateToProps = (state) => {
  return {
    editorContent: state.FormulaEditorReducer.contentObject,
    sessionVariables: state.SessionVariableReducer.sessionVariables,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormulaEditor);
