import _ from "lodash"
import { store } from "../../../.."
import { compare } from "../../../ConditionalFormatting/ConditionalFormattingCommon"
import { getPluginsAllColumnsByField } from "../../../../Utils/PluginOperations"
import { deepCopy } from "../../../../Utils/Global"

/*
* If plugin is inside plugin drilldown plugin, merges sub and main datas
*/
export const decideCopiedData = (copiedData, plugin, selected, condFormats) => {
    if (plugin.sortedColumnList) {
        let reduxState = store.getState()
        let allDrillDownsMap = reduxState.DrillDownReducer.drillDowns
        let currentDrillObject = allDrillDownsMap.get(plugin.id)
        let category = plugin.sortedColumnList.filter(col => col.locationFieldName === "category")[0]
        let controlCategory = category ? category : plugin.sortedColumnList[0]
        let categoryId = controlCategory.uniqeColumnId
        let cols = getPluginsAllColumnsByField(plugin.columnMap).filter(column => !column.isDisabledColumn)
        let indexOfDrillCol = cols.length + 1

        let drillColumn = currentDrillObject && currentDrillObject.drillDownColumnsForParentColumns.has(categoryId) ? currentDrillObject.drillDownColumnsForParentColumns.get(categoryId)[0] : null
        let isPluginPieChart = plugin.key === "pie-chart-enhanced" || plugin.key === "pie-chart" ? true : false
        let isPluginInsideDrilldown = currentDrillObject && (currentDrillObject.drillDownTypes === "inside-plugin" || currentDrillObject.drillDownTypes === "explode-pie-chart") ? true : false
        let isPieChartStatusTrue = isPluginPieChart && isPluginInsideDrilldown && drillColumn !== null ? true : false

        if (isPieChartStatusTrue) {
            let newDataObj = new Map();
            let newData = [];
            let currentData = []

            for (let i = 0; i < copiedData.length; i++) {
                let key = `${copiedData[i]["category"]}___${copiedData[i].radiusValue}`

                if (!newDataObj.has(key) && !copiedData[i]["isDataProcess"]) {
                    let subsObj = {}

                    subsObj["formattedCategory"] = copiedData[i][indexOfDrillCol] ? copiedData[i][indexOfDrillCol] : `${copiedData[i].formattedCategory}`
                    subsObj["category"] = copiedData[i][indexOfDrillCol] ? copiedData[i][indexOfDrillCol] : `${copiedData[i].formattedCategory}`
                    subsObj["measure"] = copiedData[i]["measure"]
                    subsObj["color"] = copiedData[i]["color"]
                    subsObj["pulled"] = true

                    copiedData[i]["subs"] = [subsObj]
                    copiedData[i]["totalMeasure"] = copiedData[i]["measure"]
                    copiedData[i]["firstPulled"] = true

                    copiedData[i]["sum"] = copiedData[i].measure
                    copiedData[i]["avg"] = copiedData[i].measure
                    copiedData[i]["min"] = copiedData[i].measure
                    copiedData[i]["max"] = copiedData[i].measure
                    copiedData[i]["count"] = 1
        
                    if ((plugin.config.targetSumOperation && plugin.config.targetSumOperation !== "none") || plugin.config.targetSumOperation == undefined) {
                        copiedData[i]["measure"] = copiedData[i][plugin.config.targetSumOperation ? plugin.config.targetSumOperation : "sum"]
                        copiedData[i]["totalMeasure"] = copiedData[i][plugin.config.targetSumOperation ? plugin.config.targetSumOperation : "sum"]
                    }

                    newDataObj.set(key, copiedData[i]);

                    newData.push(copiedData[i]);
                } else if (newDataObj.has(key) && !copiedData[i]["isDataProcess"]) {
                    let mergedData = newDataObj.get(key);
                    let subs = mergedData.subs

                    let subsObj = {}

                    subsObj["formattedCategory"] = copiedData[i][indexOfDrillCol] ? copiedData[i][indexOfDrillCol] : `${copiedData[i].formattedCategory}`
                    subsObj["category"] = copiedData[i][indexOfDrillCol] ? copiedData[i][indexOfDrillCol] : `${copiedData[i].formattedCategory}`
                    subsObj["measure"] = +copiedData[i]["measure"]
                    subsObj["pulled"] = true
                    
                    if (subs.filter(sub => _.isEqual(sub, subsObj)).length === 0) {
                        subs.push(subsObj)

                        mergedData["totalMeasure"] = copiedData[i]["measure"] + mergedData["totalMeasure"]

                        mergedData.sum += copiedData[i]["measure"]
                        mergedData.count++
                        mergedData.avg = mergedData.sum / mergedData.count
            
                        if (mergedData.min > copiedData[i]["measure"]) {
                            mergedData.min = copiedData[i]["measure"]
                        }
            
                        if (mergedData.max < copiedData[i]["measure"]) {
                            mergedData.max = copiedData[i]["measure"]
                        }
            
                        if ((plugin.config.targetSumOperation && plugin.config.targetSumOperation !== "none") || plugin.config.targetSumOperation == undefined) {
                            mergedData["measure"] = mergedData[plugin.config.targetSumOperation ? plugin.config.targetSumOperation : "sum"]
                            mergedData["totalMeasure"] = mergedData[plugin.config.targetSumOperation ? plugin.config.targetSumOperation : "sum"]
                        }
                    }

                    newDataObj.set(key, mergedData);
                } else {
                    newDataObj.set(key, copiedData[i]);
                }
            }

            let dataKeys = Array.from(newDataObj.keys())

            for (let i = 0; i < dataKeys.length; i++) {
                let gettedData = newDataObj.get(dataKeys[i])
                
                gettedData["measure"] = gettedData["totalMeasure"]
                gettedData["isDataProcess"] = true

                currentData.push(gettedData)
            }
            
            let chartData = [];

            for (let i = 0; i < currentData.length; i++) {
                if ((!currentData[i].interactionType || currentData[i].interactionType !== "interaction")) {
                    if (selected && selected.has(i)) {
                        for (let x = 0; x < currentData[i].subs.length; x++) {
                            let radiusValue = currentData[i].subs[x].measure / currentData[i].totalMeasure
                            let mainRadiusValue = radiusValue * currentData[i].radiusValue * currentData[i].subs.length 
                            let isRadiusGreaterThanMain = mainRadiusValue >= currentData[i].radiusValue ? currentData[i].radiusValue : mainRadiusValue
                            
                            chartData.push({
                                formattedCategory: `${currentData[i].formattedCategory}-${currentData[i].subs[x].category}`,
                                measure: currentData[i].subs[x].measure,
                                color: decidePieColour(currentData[i].subs[x], condFormats, currentData[i].color, drillColumn),
                                pulled: true,
                                id: currentData[i].id,
                                mainData: currentData[i],
                                radiusValue: isRadiusGreaterThanMain,
                                isSub: true,
                            });
                        }
                    } else {
                        chartData.push({
                            formattedCategory: currentData[i].formattedCategory,
                            category: currentData[i].category,
                            measure: currentData[i].measure,
                            color: currentData[i].color,
                            mainDataKey: `${currentData[i].formattedCategory}___${currentData[i].measure}`,
                            radiusValue: currentData[i].radiusValue,
                            id: i,
                        });
                    }
                } else {
                    chartData.push({
                        formattedCategory: currentData[i].formattedCategory,
                        category: currentData[i].category,
                        measure: currentData[i].measure,
                        color: currentData[i].color,
                        mainDataKey: `${currentData[i].formattedCategory}___${currentData[i].measure}`,
                        id: i,
                        pulled: false,
                    })

                    currentData[i]["interactionType"] = "drilldown"
                }
            }

            if (selected == undefined || selected.size === 0) {
                if ((plugin.config.targetSumOperation && plugin.config.targetSumOperation !== "none") || plugin.config.targetSumOperation == undefined) {
                    for (let i = 0; i < currentData.length; i++) {
                        let perData = currentData[i]
                        let subs = perData.subs
                        let newSubs = calculateDataWithHiddenArea(subs, plugin.config, true)

                        perData.subs = newSubs
                    }
                }

                return currentData;
            } else {
                return chartData;
            }
        } else {
            if ((plugin.config.targetSumOperation && plugin.config.targetSumOperation !== "none") || plugin.config.targetSumOperation == undefined) {
                return calculateDataWithHiddenArea(copiedData, plugin.config)
            }

            return copiedData
        }
    } else {
        return copiedData
    }
}

/*
* Controls columns and returns color
*/
const decidePieColour = (data, condFormats, color, drillColumn) => {
    if (condFormats.length > 0) {
        for (let i = 0; i < condFormats.length; i++) {
          data[drillColumn.aliasName] = data["category"]
          
          let comparedData = compare(data, condFormats[i])

          if (comparedData.status === true) {
            return condFormats[i].Style.background.colour
          }
        }

        return color
    }

    return color
}

/*
* Calculates data area with hidden area
*/
export const calculateDataWithHiddenArea = (data, config, comesFromInlineDrillDown = false) => {
    let categoryMap = new Map()

    for (let i = 0; i < data.length; i++) {
        let currentData = data[i]
        let category = comesFromInlineDrillDown ? currentData.formattedCategory : currentData.category
    
        if (categoryMap.has(category)) {
            let gettedData = deepCopy(categoryMap.get(category))

            gettedData.sum += currentData.measure
            gettedData.count++
            gettedData.avg = gettedData.sum / gettedData.count

            if (gettedData.min > currentData.measure) {
                gettedData.min = currentData.measure
            }

            if (gettedData.max < currentData.measure) {
                gettedData.max = currentData.measure
            }

            if ((config.targetSumOperation && config.targetSumOperation !== "none") || config.targetSumOperation == undefined) {
                gettedData["measure"] = gettedData[config.targetSumOperation ? config.targetSumOperation : "sum"]
                gettedData["totalMeasure"] = gettedData[config.targetSumOperation ? config.targetSumOperation : "sum"]
            }

            categoryMap.set(category, gettedData)
        } else {
            currentData["sum"] = currentData.measure
            currentData["avg"] = currentData.measure
            currentData["min"] = currentData.measure
            currentData["max"] = currentData.measure
            currentData["count"] = 1

            if ((config.targetSumOperation && config.targetSumOperation !== "none") || config.targetSumOperation == undefined) {
                currentData["measure"] = currentData[config.targetSumOperation ? config.targetSumOperation : "sum"]
                currentData["totalMeasure"] = currentData[config.targetSumOperation ? config.targetSumOperation : "sum"]
            }

            categoryMap.set(category, currentData)
        }
    }

    return Array.from(categoryMap.values())
}