import {
  ApartmentOutlined,
  BgColorsOutlined,
  ColumnWidthOutlined,
  DragOutlined,
  EyeInvisibleOutlined,
  FontSizeOutlined,
  InfoCircleOutlined,
  NodeIndexOutlined,
  PartitionOutlined,
  RadiusSettingOutlined,
  SubnodeOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, Row, Select, Tooltip } from "antd";
import $ from "jquery";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import i18n from "../../../../Utils/i18next";
import Text from "../../../GeneralComponents/Text/Text";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import { InsightsConfig } from "../../RenderJs/config";
import { themeFields } from "../common";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { TextArea } = Input;
const { Option } = Select;

/**
 * Custom configuration component for network-graph
 */
export default class NetworkGraphConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: this.props.config.backgroundColor,
      showHideButton: this.props.config.showHideButton,
      size: this.props.config.size,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      paletteColours: InsightsConfig.Palettes[this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours]
        : this.props.config.colours,
      condFormat: this.props.config.condFormat,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      colours: this.props.config.colours === undefined ? "Flat-UI" : this.props.config.colours,
      keyForTitleSize: null,
      minRadiusInPx: this.props.config.minRadiusInPx === undefined ? 15 : this.props.config.minRadiusInPx,
      minRadiusInPercent: this.props.config.minRadiusInPercent === undefined ? 5 : this.props.config.minRadiusInPercent,
      minRadiusUnit: this.props.config.minRadiusUnit === undefined ? "percent" : this.props.config.minRadiusUnit,
      maxRadiusInPx: this.props.config.maxRadiusInPx === undefined ? 40 : this.props.config.maxRadiusInPx,
      maxRadiusInPercent: this.props.config.maxRadiusInPercent === undefined ? 15 : this.props.config.maxRadiusInPercent,
      maxRadiusUnit: this.props.config.maxRadiusUnit === undefined ? "percent" : this.props.config.maxRadiusUnit,
      nodePadding: this.props.config.nodePadding === undefined ? 10 : this.props.config.nodePadding,
      tree: this.props.config.tree === undefined ? false : this.props.config.tree,
      treeRadius: this.props.config.treeRadius === undefined ? 15 : this.props.config.treeRadius,
      treeRadiusTemp: this.props.config.treeRadius === undefined ? 15 : this.props.config.treeRadius,
      treeHorizontal: this.props.config.treeHorizontal === undefined ? false : this.props.config.treeHorizontal,
      collapsibility: this.props.config.collapsibility === undefined ? true : this.props.config.collapsibility,
      draggability: this.props.config.draggability === undefined ? true : this.props.config.draggability,
      linkHighlight: this.props.config.linkHighlight === undefined ? true : this.props.config.linkHighlight,
      rootName: this.props.config.rootName === undefined ? "ROOT" : this.props.config.rootName,
      showRoot: this.props.config.showRoot === undefined ? false : this.props.config.showRoot,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        backgroundColor: nextProps.config.backgroundColor,
        showHideButton: nextProps.config.showHideButton,
        size: nextProps.config.size,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours]
          : nextProps.config.colours,
        condFormat: nextProps.config.condFormat,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        colours: nextProps.config.colours === undefined ? "Flat-UI" : nextProps.config.colours,
        keyForTitleSize: null,
        minRadiusInPx: nextProps.config.minRadiusInPx === undefined ? 15 : nextProps.config.minRadiusInPx,
        minRadiusInPercent: nextProps.config.minRadiusInPercent === undefined ? 5 : nextProps.config.minRadiusInPercent,
        minRadiusUnit: nextProps.config.minRadiusUnit === undefined ? "percent" : nextProps.config.minRadiusUnit,
        maxRadiusInPx: nextProps.config.maxRadiusInPx === undefined ? 40 : nextProps.config.maxRadiusInPx,
        maxRadiusInPercent: nextProps.config.maxRadiusInPercent === undefined ? 15 : nextProps.config.maxRadiusInPercent,
        maxRadiusUnit: nextProps.config.maxRadiusUnit === undefined ? "percent" : nextProps.config.maxRadiusUnit,
        nodePadding: nextProps.config.nodePadding === undefined ? 10 : nextProps.config.nodePadding,
        tree: nextProps.config.tree === undefined ? false : nextProps.config.tree,
        treeRadius: nextProps.config.treeRadius === undefined ? 15 : nextProps.config.treeRadius,
        treeHorizontal: nextProps.config.treeHorizontal === undefined ? false : nextProps.config.treeHorizontal,
        collapsibility: nextProps.config.collapsibility === undefined ? true : nextProps.config.collapsibility,
        draggability: nextProps.config.draggability === undefined ? true : nextProps.config.draggability,
        linkHighlight: nextProps.config.linkHighlight === undefined ? true : nextProps.config.linkHighlight,
        rootName: nextProps.config.rootName === undefined ? "ROOT" : nextProps.config.rootName,
        showRoot: nextProps.config.showRoot === undefined ? false : nextProps.config.showRoot,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle
      });
    }
  }

  /**
   * Timer for change events
   */
  changeTimer;

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  handleChangeTreeRadius = async (event) => {
    clearTimeout(this.changeTimer);

    let value = event.target.value;

    this.setState({
      ...this.state,
      treeRadiusTemp: value
    });

    if (value < 1) {
      value = 1;

      $("#treeRadius").css("background", "#ff000042");
    } else if (value > 20) {
      value = 20;

      $("#treeRadius").css("background", "#ff000042");
    } else {
      $("#treeRadius").css("background", "");

      this.changeTimer = setTimeout(() => {
        this.setConfigToPlugin({ type: "treeRadius", value: value });
      }, 500);
    }
  };

  /**
   * Returns warning if `value` is greater than `max` or value is smaller than `min`
   * 
   * @returns 
   */
  sizeWarning = (size, min, max) => {
    let isSizeInWrongInterval = size !== null && (size === "" || Number(size) < min || Number(size) > max);
    let tooSmallWarning = "* " + i18n.t("Dashboard.Configuration.Fields.CantReduceMeasureSize", { size: min });
    let tooBigWarning = "* " + i18n.t("Dashboard.Configuration.Fields.CantChangeMeasureSize", { size: max });

    return (isSizeInWrongInterval ? (
      <Row style={{ height: "11px" }}>
        <Col span={12}></Col>
        <Col span={12} style={{ fontSize: "11px", textAlign: "left", color: "red" }}>
          {Number(size) < min ? tooSmallWarning : tooBigWarning}
        </Col>
      </Row>
    ) : null);
  }

  handleChange = async (event) => {
    clearTimeout(this.changeTimer);

    let id = event.target.id;
    let value = event.target.value;

    this.setState({
      ...this.state,
      [id]: value
    });

    this.changeTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: id, value: value });
    }, 500);
  };

  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  colorChangeVisible = () => {
    this.setState({ ...this.state, colorChange: !this.state.colorChange });
  };

  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /*
* handle palette selection func.
*/
  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };


  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  render() {
    return (
      <>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />
        
        <div style={{ color: "#505050" }}>
          {/* General Appearance */}
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Palette")}
              </Text>
            </Col>
            <Col span={4} style={{ paddingRight: "10px" }}>
              <Select
                id={"colours"}
                defaultValue={this.state.colours}
                value={this.state.colours}
                style={{ width: "100%" }}
                onChange={this.coloursOnChange}>
                <Option value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
                <Option value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
                <Option value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
                <Option value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
                <Option value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
              </Select>
            </Col>
            <Col span={8}>
              <ThemePalette
                colours={this.state.paletteColours}
                update={this.setConfigToPlugin}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.backgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.backgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}

              </Button>
              {this.state.backgroundColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.backgroundColor}
                  onChange={this.backgroundColorOnChange}
                  id={"backgroundColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
          <Row style={{ height: "11px" }} />
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <SubnodeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Collapsibility")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"collapsibility"}
                defaultChecked={this.state.collapsibility}
                checked={this.state.collapsibility}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <DragOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Draggability")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"draggability"}
                defaultChecked={this.state.draggability}
                checked={this.state.draggability}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <NodeIndexOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.LinkHightlight")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"linkHighlight"}
                defaultChecked={this.state.linkHighlight}
                checked={this.state.linkHighlight}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          {/* Root Options */}
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col span={14}>
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Dashboard.Configuration.Fields.Root")}
              </Divider>
            </Col>
            <Col span={10} style={{ fontSize: "11px", color: "red" }}> {`*${i18n.t("Dashboard.Configuration.Fields.ActionsWarningRoot")}`}</Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowRoot")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"showRoot"}
                checked={this.state.tree ? true : this.state.showRoot}
                disabled={this.state.tree}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.RootName")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id="rootName"
                value={this.state.rootName}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          {/* Network Graph Options */}
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.NetworkGraph")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <RadiusSettingOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.MinimumRadius")}
              </Text>
            </Col>
            <Col span={12}>
              <Input.Group compact style={{ textAlign: "left" }}>
                <Input
                  id={this.state.minRadiusUnit === "percent"
                    ? "minRadiusInPercent"
                    : "minRadiusInPx"
                  }
                  value={this.state.minRadiusUnit === "percent"
                    ? this.state.minRadiusInPercent
                    : this.state.minRadiusInPx
                  }
                  type={"number"}
                  min={1}
                  onChange={this.handleChange}
                  disabled={this.state.tree}
                  style={{ width: "80%" }}
                />
                <Select
                  id={"minRadiusUnit"}
                  defaultValue={this.state.minRadiusUnit}
                  value={this.state.minRadiusUnit}
                  onChange={(value) => this.handleChange({
                    target: { id: "minRadiusUnit", value: value }
                  })}
                  disabled={this.state.tree}
                  style={{ width: "20%" }}
                >
                  <Option value="percent">%</Option>
                  <Option value="pixel">px</Option>
                </Select>
              </Input.Group>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <RadiusSettingOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.MaximumRadius")}
              </Text>
            </Col>
            <Col span={12}>
              <Input.Group compact style={{ textAlign: "left" }}>
                <Input
                  id={this.state.maxRadiusUnit === "percent"
                    ? "maxRadiusInPercent"
                    : "maxRadiusInPx"
                  }
                  value={this.state.maxRadiusUnit === "percent"
                    ? this.state.maxRadiusInPercent
                    : this.state.maxRadiusInPx
                  }
                  type={"number"}
                  min={1}
                  onChange={this.handleChange}
                  disabled={this.state.tree}
                  style={{ width: "80%" }}
                />
                <Select
                  id={"maxRadiusUnit"}
                  defaultValue={this.state.maxRadiusUnit}
                  value={this.state.maxRadiusUnit}
                  onChange={(value) => this.handleChange({
                    target: { id: "maxRadiusUnit", value: value }
                  })}
                  disabled={this.state.tree}
                  style={{ width: "20%" }}
                >
                  <Option value="percent">%</Option>
                  <Option value="pixel">px</Option>
                </Select>
              </Input.Group>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <ColumnWidthOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.NodePadding")}
              </Text>
            </Col>
            <Col span={12}>
              <Input.Group compact style={{ textAlign: "left" }}>
                <Input
                  id="nodePadding"
                  placeholder={""}
                  value={this.state.nodePadding}
                  type={"number"}
                  min={0}
                  onChange={this.handleChange}
                  disabled={this.state.tree}
                  style={{ width: "80%" }}
                />
                <Input
                  placeholder={"px"}
                  value={"px"}
                  disabled={true}
                  style={{ width: "20%" }}
                />
              </Input.Group>
            </Col>
          </Row>
          {/* Tree Chart Options */}
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.TreeChart")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <ApartmentOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.TreeChart")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"tree"}
                defaultChecked={this.state.tree}
                checked={this.state.tree}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <RadiusSettingOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Radius")}
              </Text>
            </Col>
            <Col span={12}>
              <Input.Group compact style={{ textAlign: "left" }}>
                <Input
                  id="treeRadius"
                  placeholder={""}
                  value={this.state.treeRadiusTemp}
                  type={"number"}
                  onChange={this.handleChangeTreeRadius}
                  disabled={!this.state.tree}
                  style={{ width: "80%" }}
                />
                <Input
                  placeholder={"px"}
                  value={"px"}
                  disabled={true}
                  style={{ width: "20%" }}
                />
              </Input.Group>
            </Col>
          </Row>
          {this.sizeWarning(this.state.treeRadiusTemp, 1, 20)}
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <PartitionOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Horizontal")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"treeHorizontal"}
                defaultChecked={this.state.treeHorizontal}
                checked={this.state.treeHorizontal}
                disabled={!this.state.tree}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          {/* Title */}
          <TitleSettingsOfPlugin
            plugin={this.props.plugin}
            handleChange={this.handleChange}
            commonTitleConfig={this.props.commonTitleConfig}
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
            setDefaultsForTitle={this.setDefaultsForTitle}
            title={this.state.title}
            titleAlign={this.state.titleAlign}
            titleColour={this.state.titleColour}
            titleColourChange={this.state.titleColourChange}
            titleColourChangeVisible={this.titleColourChangeVisible}
            titleFont={this.state.titleFont}
            titleFontSize={this.state.titleFontSize}
            keyForTitleSize={this.state.keyForTitleSize}
            titleFontStyle={this.state.titleFontStyle}
            titleFontWeight={this.state.titleFontWeight}
            titleTextDecor={this.state.titleTextDecor}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            changedTitleFontSize={this.state.changedTitleFontSize}
            handleChangeBothOfValues={this.handleChangeBothOfValues}
          />
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.Others")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left", display: "flex" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Settings.NoDataConfiguration")}
              </Text>
              <Tooltip
                title={
                  <>
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                  </>
                }
              >
                <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <TextArea
                allowClear
                id={"noDataTitle"}
                defaultValue={this.state.noDataTitle}
                value={this.state.noDataTitle}
                onChange={this.handleChange}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={i18n.t("NoDataContent.NoData") + " " +
                  i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
