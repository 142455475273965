import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import L from "leaflet";
import * as topojson from "topojson";
import "leaflet-easybutton";
import "leaflet/dist/leaflet.css";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import {
  renderConfig,
  renderData,
  renderNavigation,
  renderConditionalFormatting
} from "../PluginsCommonComponents";
import i18n from "../../../../Utils/i18next";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import MapChoroplethConfiguration from "./MapChoroplethConfiguration";
import MapChoroplethData from "./MapChoroplethData";
import { showError, showNotificationWithIcon } from "../../../../Utils/Notification";
import { renderContent } from "../renderContent";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import NavigationContent from "../../../Navigation/NavigationContent";
import { API_BASE } from "../../../../config";
import Cookies from "js-cookie";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { compare } from "../../../ConditionalFormatting/ConditionalFormattingCommonForChoropleth";
import { TOPOJSON_PATH, wellDefinedTopojsonOldPaths, validCheck } from "../../../GeneralComponents/MapTopoJsonOperations/DefaultTopojsons";
import { convertHTMLRuletoRule } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { store } from "../../../..";
import { setChoroplethUtils } from "./Redux/MapChoroplethDetailAction";
import { connect } from "react-redux";
import { setPluginTriggerObject } from "../../PluginTriggerAction";
import { deepCopy } from "../../../../Utils/Global";

const data = [
  {
    varis_plaka: "1.0",
    varis_sehir: "Adana",
    bagajtoplam: "274514657",
    code: [{ name: "varis_plaka", value: 1 }],
    desc: [{ name: "varis_sehir", value: "Adana" }],
    measure: [{ name: "bagajtoplam", value: 274514657 }]
  },
  {
    varis_plaka: "2.0",
    varis_sehir: "Adıyaman",
    bagajtoplam: "1.245943E+7",
    code: [{ name: "varis_plaka", value: 2 }],
    desc: [{ name: "varis_sehir", value: "Adıyaman" }],
    measure: [{ name: "bagajtoplam", value: 12459430 }]
  },
  {
    varis_plaka: "4.0",
    varis_sehir: "Ağrı",
    bagajtoplam: "21697834",
    code: [{ name: "varis_plaka", value: 4 }],
    desc: [{ name: "varis_sehir", value: "Ağrı" }],
    measure: [{ name: "bagajtoplam", value: 21697834 }]
  },
  {
    varis_plaka: "5.0",
    varis_sehir: "Amasya",
    bagajtoplam: "8741292.0",
    code: [{ name: "varis_plaka", value: 5 }],
    desc: [{ name: "varis_sehir", value: "Amasya" }],
    measure: [{ name: "bagajtoplam", value: 8741292 }]
  },
  {
    varis_plaka: "6.0",
    varis_sehir: "Ankara",
    bagajtoplam: "653795333",
    code: [{ name: "varis_plaka", value: 6 }],
    desc: [{ name: "varis_sehir", value: "Ankara" }],
    measure: [{ name: "bagajtoplam", value: 653795333 }]
  },
  {
    varis_plaka: "7.0",
    varis_sehir: "Antalya",
    bagajtoplam: "4.8926391E+8",
    code: [{ name: "varis_plaka", value: 7 }],
    desc: [{ name: "varis_sehir", value: "Antalya" }],
    measure: [{ name: "bagajtoplam", value: 489263910 }]
  },
  {
    varis_plaka: "10.0",
    varis_sehir: "Balıkesir",
    bagajtoplam: "16859655",
    code: [{ name: "varis_plaka", value: 10 }],
    desc: [{ name: "varis_sehir", value: "Balıkesir" }],
    measure: [{ name: "bagajtoplam", value: 16859655 }]
  },
  {
    varis_plaka: "12.0",
    varis_sehir: "Bingöl",
    bagajtoplam: "10117418",
    code: [{ name: "varis_plaka", value: 12 }],
    desc: [{ name: "varis_sehir", value: "Bingöl" }],
    measure: [{ name: "bagajtoplam", value: 10117418 }]
  },
  {
    varis_plaka: "16.0",
    varis_sehir: "Bursa",
    bagajtoplam: "12658161",
    code: [{ name: "varis_plaka", value: 16 }],
    desc: [{ name: "varis_sehir", value: "Bursa" }],
    measure: [{ name: "bagajtoplam", value: 12658161 }]
  },
  {
    varis_plaka: "17.0",
    varis_sehir: "Çanakkale",
    bagajtoplam: "6796646.0",
    code: [{ name: "varis_plaka", value: 17 }],
    desc: [{ name: "varis_sehir", value: "Çanakkale" }],
    measure: [{ name: "bagajtoplam", value: 6796646 }]
  },
  {
    varis_plaka: "20.0",
    varis_sehir: "Denizli",
    bagajtoplam: "26506181",
    code: [{ name: "varis_plaka", value: 20 }],
    desc: [{ name: "varis_sehir", value: "Denizli" }],
    measure: [{ name: "bagajtoplam", value: 26506181 }]
  },
  {
    varis_plaka: "21.0",
    varis_sehir: "Diyarbakır",
    bagajtoplam: "122974546",
    code: [{ name: "varis_plaka", value: 21 }],
    desc: [{ name: "varis_sehir", value: "Diyarbakır" }],
    measure: [{ name: "bagajtoplam", value: 122974546 }]
  },
  {
    varis_plaka: "23.0",
    varis_sehir: "Elazığ",
    bagajtoplam: "64550599",
    code: [{ name: "varis_plaka", value: 23 }],
    desc: [{ name: "varis_sehir", value: "Elazığ" }],
    measure: [{ name: "bagajtoplam", value: 64550599 }]
  },
  {
    varis_plaka: "24.0",
    varis_sehir: "Erzincan",
    bagajtoplam: "25717691",
    code: [{ name: "varis_plaka", value: 24 }],
    desc: [{ name: "varis_sehir", value: "Erzincan" }],
    measure: [{ name: "bagajtoplam", value: 25717691 }]
  },
  {
    varis_plaka: "25.0",
    varis_sehir: "Erzurum",
    bagajtoplam: "74954227",
    code: [{ name: "varis_plaka", value: 25 }],
    desc: [{ name: "varis_sehir", value: "Erzurum" }],
    measure: [{ name: "bagajtoplam", value: 74954227 }]
  },
  {
    varis_plaka: "26.0",
    varis_sehir: "Eskişehir",
    bagajtoplam: "200115.0",
    code: [{ name: "varis_plaka", value: 26 }],
    desc: [{ name: "varis_sehir", value: "Eskişehir" }],
    measure: [{ name: "bagajtoplam", value: 200115 }]
  },
  {
    varis_plaka: "27.0",
    varis_sehir: "Gaziantep",
    bagajtoplam: "137529095",
    code: [{ name: "varis_plaka", value: 27 }],
    desc: [{ name: "varis_sehir", value: "Gaziantep" }],
    measure: [{ name: "bagajtoplam", value: 137529095 }]
  },
  {
    varis_plaka: "28.0",
    varis_sehir: "Giresun",
    bagajtoplam: "32662714",
    code: [{ name: "varis_plaka", value: 28 }],
    desc: [{ name: "varis_sehir", value: "Giresun" }],
    measure: [{ name: "bagajtoplam", value: 32662714 }]
  },
  {
    varis_plaka: "30.0",
    varis_sehir: "Hakkari",
    bagajtoplam: "7128387.0",
    code: [{ name: "varis_plaka", value: 30 }],
    desc: [{ name: "varis_sehir", value: "Hakkari" }],
    measure: [{ name: "bagajtoplam", value: 7128387 }]
  },
  {
    varis_plaka: "31.0",
    varis_sehir: "Hatay",
    bagajtoplam: "68709111",
    code: [{ name: "varis_plaka", value: 31 }],
    desc: [{ name: "varis_sehir", value: "Hatay" }],
    measure: [{ name: "bagajtoplam", value: 68709111 }]
  },
  {
    varis_plaka: "32.0",
    varis_sehir: "Isparta",
    bagajtoplam: "3882400.0",
    code: [{ name: "varis_plaka", value: 32 }],
    desc: [{ name: "varis_sehir", value: "Isparta" }],
    measure: [{ name: "bagajtoplam", value: 3882400 }]
  },
  {
    varis_plaka: "34.0",
    varis_sehir: "İstanbul",
    bagajtoplam: "2607283368",
    code: [{ name: "varis_plaka", value: 34 }],
    desc: [{ name: "varis_sehir", value: "İstanbul" }],
    measure: [{ name: "bagajtoplam", value: 2607283368 }]
  },
  {
    varis_plaka: "35.0",
    varis_sehir: "İzmir",
    bagajtoplam: "546264677",
    code: [{ name: "varis_plaka", value: 35 }],
    desc: [{ name: "varis_sehir", value: "İzmir" }],
    measure: [{ name: "bagajtoplam", value: 546264677 }]
  },
  {
    varis_plaka: "36.0",
    varis_sehir: "Kars",
    bagajtoplam: "41027884",
    code: [{ name: "varis_plaka", value: 36 }],
    desc: [{ name: "varis_sehir", value: "Kars" }],
    measure: [{ name: "bagajtoplam", value: 41027884 }]
  },
  {
    varis_plaka: "37.0",
    varis_sehir: "Kastamonu",
    bagajtoplam: "3612234.0",
    code: [{ name: "varis_plaka", value: 37 }],
    desc: [{ name: "varis_sehir", value: "Kastamonu" }],
    measure: [{ name: "bagajtoplam", value: 3612234 }]
  },
  {
    varis_plaka: "38.0",
    varis_sehir: "Kayseri",
    bagajtoplam: "128532252",
    code: [{ name: "varis_plaka", value: 38 }],
    desc: [{ name: "varis_sehir", value: "Kayseri" }],
    measure: [{ name: "bagajtoplam", value: 128532252 }]
  },
  {
    varis_plaka: "41.0",
    varis_sehir: "Kocaeli",
    bagajtoplam: "3145848.0",
    code: [{ name: "varis_plaka", value: 41 }],
    desc: [{ name: "varis_sehir", value: "Kocaeli" }],
    measure: [{ name: "bagajtoplam", value: 3145848 }]
  },
  {
    varis_plaka: "42.0",
    varis_sehir: "Konya",
    bagajtoplam: "61286525",
    code: [{ name: "varis_plaka", value: 42 }],
    desc: [{ name: "varis_sehir", value: "Konya" }],
    measure: [{ name: "bagajtoplam", value: 61286525 }]
  },
  {
    varis_plaka: "43.0",
    varis_sehir: "Kütahya",
    bagajtoplam: "4003590.0",
    code: [{ name: "varis_plaka", value: 43 }],
    desc: [{ name: "varis_sehir", value: "Kütahya" }],
    measure: [{ name: "bagajtoplam", value: 4003590 }]
  },
  {
    varis_plaka: "44.0",
    varis_sehir: "Malatya",
    bagajtoplam: "52046903",
    code: [{ name: "varis_plaka", value: 44 }],
    desc: [{ name: "varis_sehir", value: "Malatya" }],
    measure: [{ name: "bagajtoplam", value: 52046903 }]
  },
  {
    varis_plaka: "46.0",
    varis_sehir: "Kahramanmaraş",
    bagajtoplam: "15016171",
    code: [{ name: "varis_plaka", value: 46 }],
    desc: [{ name: "varis_sehir", value: "Kahramanmaraş" }],
    measure: [{ name: "bagajtoplam", value: 15016171 }]
  },
  {
    varis_plaka: "47.0",
    varis_sehir: "Mardin",
    bagajtoplam: "41816838",
    code: [{ name: "varis_plaka", value: 47 }],
    desc: [{ name: "varis_sehir", value: "Mardin" }],
    measure: [{ name: "bagajtoplam", value: 41816838 }]
  },
  {
    varis_plaka: "48.0",
    varis_sehir: "Muğla",
    bagajtoplam: "261534481",
    code: [{ name: "varis_plaka", value: 48 }],
    desc: [{ name: "varis_sehir", value: "Muğla" }],
    measure: [{ name: "bagajtoplam", value: 261534481 }]
  },
  {
    varis_plaka: "49.0",
    varis_sehir: "Muş",
    bagajtoplam: "28965388",
    code: [{ name: "varis_plaka", value: 49 }],
    desc: [{ name: "varis_sehir", value: "Muş" }],
    measure: [{ name: "bagajtoplam", value: 28965388 }]
  },
  {
    varis_plaka: "50.0",
    varis_sehir: "Nevşehir",
    bagajtoplam: "2.456474E+7",
    code: [{ name: "varis_plaka", value: 50 }],
    desc: [{ name: "varis_sehir", value: "Nevşehir" }],
    measure: [{ name: "bagajtoplam", value: 24564740 }]
  },
  {
    varis_plaka: "55.0",
    varis_sehir: "Samsun",
    bagajtoplam: "9.82738E+7",
    code: [{ name: "varis_plaka", value: 55 }],
    desc: [{ name: "varis_sehir", value: "Samsun" }],
    measure: [{ name: "bagajtoplam", value: 98273800 }]
  },
  {
    varis_plaka: "56.0",
    varis_sehir: "Siirt",
    bagajtoplam: "3087519.0",
    code: [{ name: "varis_plaka", value: 56 }],
    desc: [{ name: "varis_sehir", value: "Siirt" }],
    measure: [{ name: "bagajtoplam", value: 3087519 }]
  },
  {
    varis_plaka: "57.0",
    varis_sehir: "Sinop",
    bagajtoplam: "7575552.0",
    code: [{ name: "varis_plaka", value: 57 }],
    desc: [{ name: "varis_sehir", value: "Sinop" }],
    measure: [{ name: "bagajtoplam", value: 7575552 }]
  },
  {
    varis_plaka: "58.0",
    varis_sehir: "Sivas",
    bagajtoplam: "32070161",
    code: [{ name: "varis_plaka", value: 58 }],
    desc: [{ name: "varis_sehir", value: "Sivas" }],
    measure: [{ name: "bagajtoplam", value: 32070161 }]
  },
  {
    varis_plaka: "59.0",
    varis_sehir: "Tekirdağ",
    bagajtoplam: "4722225.0",
    code: [{ name: "varis_plaka", value: 59 }],
    desc: [{ name: "varis_sehir", value: "Tekirdağ" }],
    measure: [{ name: "bagajtoplam", value: 4722225 }]
  },
  {
    varis_plaka: "60.0",
    varis_sehir: "Tokat",
    bagajtoplam: "1658123.0",
    code: [{ name: "varis_plaka", value: 60 }],
    desc: [{ name: "varis_sehir", value: "Tokat" }],
    measure: [{ name: "bagajtoplam", value: 1658123 }]
  },
  {
    varis_plaka: "61.0",
    varis_sehir: "Trabzon",
    bagajtoplam: "217476029",
    code: [{ name: "varis_plaka", value: 61 }],
    desc: [{ name: "varis_sehir", value: "Trabzon" }],
    measure: [{ name: "bagajtoplam", value: 217476029 }]
  },
  {
    varis_plaka: "63.0",
    varis_sehir: "Şanlıurfa",
    bagajtoplam: "40533092",
    code: [{ name: "varis_plaka", value: 63 }],
    desc: [{ name: "varis_sehir", value: "Şanlıurfa" }],
    measure: [{ name: "bagajtoplam", value: 40533092 }]
  },
  {
    varis_plaka: "64.0",
    varis_sehir: "Uşak",
    bagajtoplam: "625147.0",
    code: [{ name: "varis_plaka", value: 64 }],
    desc: [{ name: "varis_sehir", value: "Uşak" }],
    measure: [{ name: "bagajtoplam", value: 625147 }]
  },
  {
    varis_plaka: "65.0",
    varis_sehir: "Van",
    bagajtoplam: "108136671",
    code: [{ name: "varis_plaka", value: 65 }],
    desc: [{ name: "varis_sehir", value: "Van" }],
    measure: [{ name: "bagajtoplam", value: 108136671 }]
  },
  {
    varis_plaka: "67.0",
    varis_sehir: "Zonguldak",
    bagajtoplam: "188344.0",
    code: [{ name: "varis_plaka", value: 67 }],
    desc: [{ name: "varis_sehir", value: "Zonguldak" }],
    measure: [{ name: "bagajtoplam", value: 188344 }]
  },
  {
    varis_plaka: "72.0",
    varis_sehir: "Batman",
    bagajtoplam: "35288262",
    code: [{ name: "varis_plaka", value: 72 }],
    desc: [{ name: "varis_sehir", value: "Batman" }],
    measure: [{ name: "bagajtoplam", value: 35288262 }]
  },
  {
    varis_plaka: "73.0",
    varis_sehir: "Şırnak",
    bagajtoplam: "23560324",
    code: [{ name: "varis_plaka", value: 73 }],
    desc: [{ name: "varis_sehir", value: "Şırnak" }],
    measure: [{ name: "bagajtoplam", value: 23560324 }]
  },
  {
    varis_plaka: "76.0",
    varis_sehir: "Iğdır",
    bagajtoplam: "18998067",
    code: [{ name: "varis_plaka", value: 76 }],
    desc: [{ name: "varis_sehir", value: "Iğdır" }],
    measure: [{ name: "bagajtoplam", value: 18998067 }]
  },
  {
    varis_plaka: "",
    varis_sehir: "",
    bagajtoplam: "373438.0",
    code: [{ name: "varis_plaka", value: 0 }],
    desc: [{ name: "varis_sehir", value: "" }],
    measure: [{ name: "bagajtoplam", value: 373438 }]
  }
];
const config = {
  width: 500,
  height: 400,
  topojson: "turkey/Iller.topojson",
  featureCode: "ILKOD",
  featureDesc: "NAME",
  scaleType: "Linear",
  opacity: 0.75,
  legend: true,
  showHideButton: false,
  legendLocation: "down",
  styleType: "Series Picker",
  seriesScale: "Individual",
  nullColour: "#CCCCCC",
  StrokeWidth: 2,
  colours: "Flat-UI",
  mapTile: "Default",
  toggleCriteria: "",
  title: "",
  mapTileSelection: "Atlas Map Tile",
  summary: "",
  backgroundColor: "rgb(255,255,255)",
  selectedColor: "#333333",
  refresh: 0
};
const columnMap = {
  code: [
    {
      Code: "'rota_bilgisi'.'varis_plaka'",
      Name: "varis_plaka",
      DataType: "double",
      Table: "rota_bilgisi",
      Measure: "none",
      ID: "rota_bilgisi.varis_plaka",
      SubjectArea: "calisan",
      SortKey: false,
      Sorting: false,
      SortDirection: "asc",
      SortOrder: 0,
      Locale: "TR",
      DataFormat: ".3s",
      Config: {},
      Verified: false,
      Type: "Column",
      Description: ""
    }
  ],
  desc: [
    {
      Code: "'rota_bilgisi'.'varis_sehir'",
      Name: "varis_sehir",
      DataType: "varchar",
      Table: "rota_bilgisi",
      Measure: "none",
      ID: "rota_bilgisi.varis_sehir",
      SubjectArea: "calisan",
      SortKey: false,
      Sorting: false,
      SortDirection: "asc",
      SortOrder: 0,
      Locale: "TR",
      DataFormat: "%s",
      Config: {},
      Verified: false,
      Type: "Column",
      Description: ""
    }
  ],
  measure: [
    {
      Code: "'rota_bilgisi'.'bagajtoplam'",
      Name: "bagajtoplam",
      DataType: "double",
      Table: "rota_bilgisi",
      Measure: "sum(`rota_bilgisi`.`bagajtoplam`)",
      ID: "rota_bilgisi.bagajtoplam",
      SubjectArea: "calisan",
      SortKey: false,
      Sorting: false,
      SortDirection: "asc",
      SortOrder: 0,
      Locale: "TR",
      DataFormat: ".3s",
      Config: {},
      Verified: false,
      Type: "Column",
      Description: ""
    }
  ],
  vary: {
    Code: "",
    Name: "",
    DataType: "varchar",
    Table: "Unspecified",
    Measure: "none",
    ID: "Unspecified.",
    SubjectArea: "",
    SortKey: false,
    Sorting: false,
    SortDirection: "asc",
    SortOrder: 0,
    Locale: "TR",
    DataFormat: "%s",
    Config: {},
    Verified: false,
    Type: "Column"
  }
};

const condFormats = [];
const filters = [];
const pluginName = "map-choropleth";

let controlState;
let angular;

var innerExtraFields = [];
const description =
  "Map visualisation using open source plugin [LeafletJS](http://leafletjs.com/). This shows regions coloured as a heatmap, darker colours for higher values and vice versa. Hovering over the regions will display a tooltip. Works by using a [topoJSON](https://github.com/mbostock/topojson) file uploaded to the deployment on the server (in the `topojson` folder). This file will have the description of the regions as well as an attribute that can be used to tie the OBIEE dataset to the map.";

const configurationParameters = [
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      defaultValue: 500,
      subtype: "number"
    },
    desc: "desc89"
  },
  {
    targetProperty: "condFormat",
    label: "CondFormat",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "CondFormat"
  },
  {
    targetProperty: "condFormatLegend",
    label: "CondFormatLegend",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "CondFormatLegend"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      defaultValue: 400,
      subtype: "number"
    },
    desc: "desc90"
  },
  {
    targetProperty: "topojson",
    label: "TopoJSON",
    inputType: "map",
    inputOptions: {
      defaultValue: "turkey/Iller.topojson"
    },
    desc: "desc202"
  },
  {
    targetProperty: "featuresForSelectedTopoJson",
    label: "featuresForSelectedTopoJson",
    inputType: "map",
    inputOptions: {
      defaultValue: ['NAME', 'PLAKA', 'ILKOD', 'UserID', 'ID', 'FEATTYP', 'ORDER00', 'FEATAREA', 'FEATPERIM']
    },
    desc: "desc202"
  },
  {
    targetProperty: "featureCode",
    label: "FeatureCode",
    inputType: "mapcode",
    inputOptions: {
      defaultValue: "ILKOD",
      mapProperty: "topojson"
    },
    desc: "desc203"
  },
  {
    targetProperty: "featureDesc",
    label: "FeatureDesc",
    inputType: "mapcode",
    inputOptions: {
      defaultValue: "NAME",
      mapProperty: "topojson"
    },
    desc: "desc204"
  },
  {
    targetProperty: "scaleType",
    label: "ScaleType",
    inputType: "radio",
    inputOptions: {
      values: ["Linear", "Quantile"],
      defaultValue: "Linear"
    },
    desc: "desc205"
  },
  {
    targetProperty: "opacity",
    label: "Opacity",
    inputType: "range",
    inputOptions: {
      min: 0,
      max: 1,
      step: 0.01,
      defaultValue: 0.75
    },
    desc: "desc206"
  },
  {
    targetProperty: "legend",
    label: "Legend",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc193"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "legendLocation",
    label: "legendLocation",
    inputType: "dropdown",
    inputOptions: {
      defaultValue: "down",
      multiSelect: false,
      values: ["left", "right", "up", "down"]
    },
    desc: "legendLocation"
  },
  {
    targetProperty: "styleType",
    label: "StyleType",
    inputType: "radio",
    inputOptions: {
      values: ["Series Picker", "Split Series"],
      defaultValue: "Series Picker"
    },
    desc: "desc195"
  },
  {
    targetProperty: "seriesScale",
    label: "SeriesScale",
    inputType: "radio",
    inputOptions: {
      values: ["Individual", "Shared"],
      defaultValue: "Individual"
    },
    desc: "desc194"
  },
  {
    targetProperty: "nullColour",
    label: "NullColour",
    inputType: "colour",
    inputOptions: {
      defaultValue: "#CCCCCC"
    },
    desc: "desc207"
  },
  {
    targetProperty: "StrokeWidth",
    label: "StrokeWidth",
    inputType: "textbox",
    inputOptions: {
      defaultValue: 2
    },
    desc: "desc231"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: {
      defaultValue: "Flat-UI"
    },
    desc: "desc208"
  },
  {
    targetProperty: "mapTile",
    label: "MapTile",
    inputType: "maptile",
    inputOptions: {
      defaultValue: "Default"
    },
    desc: "desc191"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc94"
  },
  {
    targetProperty: "mapTileSelection",
    label: "mapTileSelection",
    inputType: "dropdown",
    inputOptions: {
      multiSelect: false,
      values: [
        "Atlas Map Tile",
        "Open Street Map Tile",
        "Empty Tile",
        "BasarSoft Tile"
      ],
      defaultValue: "Atlas Map Tile"
    },
    desc: "mapTileSelection"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgb(255,255,255)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "selectedColor",
    label: "SelectedColor",
    inputType: "colour",
    inputOptions: {
      defaultValue: "#333333"
    },
    desc: "desc134"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
];

const actions = [
  {
    trigger: "clickFeature",
    type: "click",
    name: "Tıklama Feature",
    output: ["code", "desc"],
    description: "ClickFeatureDesc"
  },
  {
    trigger: "hoverFeature",
    type: "hover",
    name: "Hover Feature",
    output: ["code"],
    description: "HoverMapObjectDesc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  },
  {
    id: "highlightFeatures",
    name: "Featureları Vurgula",
    description: "desc220",
    type: "private",
    method: "highlightFeatures"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const pluginConditionalFormatOptions = {
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe",
  },
};

const conditionalFormatColumnMap = new Set(["measure", "desc", "code", "hidden"]);
const conditionalFormatTargetMap = new Set(["measure"])

// Highlight features
const highlightFeatures = function (output, container) {
  if (output.length > 0) {
    var config = output[0].config;
    var layers = $(container)
      .find(".mapData")
      .data();

    layers.eachLayer(function (layer) {
      revert(layer, +config.opacity);

      // Look for output matches
      output.forEach(function (o) {
        if (layer.feature.data) {
          // Ignore unmatched features
          var target = layer.feature.data[o.targetId];
          if ($.inArray(target, o.values) > -1) {
            highlight(layer); // Highlight on match
          }
        }
      });
    });
  }
};

// Highlight selected elements
const highlight = function (layer, strokeWidth) {
  layer.setStyle({
    weight: strokeWidth,
    color: "#000000",
    opacity: 1,
    fillOpacity: 1
  });
};

// Revert selected elements
const revert = function (layer, opacity) {
  layer.setStyle({
    opacity: opacity,
    color: "#333",
    weight: 1,
    fillOpacity: opacity
  });
};

const startLayer = function (
  topojsonExists,
  featureLayerArray,
  config,
  map,
  mapContainer,
  processFeatures,
  container,
  height,
  measureNames,
  colour,
  selectedFeatureCode,
  vis,
  tooltip
) {
  var layerNo;
  var selectedDatum;
  var featureLayer;
  var horizontal;

  let findLayerAndDatum = checkLayerAndDatum(vis);
  layerNo = findLayerAndDatum.layerNo;
  selectedDatum = findLayerAndDatum.selectedDatum;

  if (layerNo == 0 && selectedDatum == undefined) {
    // Draw legend
    if (config.legend) {
      let legendCount = measureNames.length + 1;
      //Estimated height by the length of a legend and the number of legend
      let legendHeight = 20 * legendCount;
      //to find the height of the legends from above
      let legendMarginTop = height / 2 - legendHeight;

      let style;

      if (config.legendLocation == "right") {
        //if legends height is bigger than center height of the map
        if (legendHeight >= height / 2) legendMarginTop = 0;
        style = {
          float: "left",
          "margin-left": "10px",
          "margin-top": legendMarginTop + "px",
          display: "inline-block",
          font: "10px sans-serif"
        };
        horizontal = false;
        height = legendHeight;
      } else if (config.legendLocation == "left") {
        if (legendHeight >= height / 2) legendMarginTop = 0;
        style = {
          float: "left",
          "margin-right": "10px",
          "margin-top": legendMarginTop + "px",
          display: "inline-block",
          font: "10px sans-serif"
        };
        horizontal = false;
        height = legendHeight;
      } else {
        height = legendHeight;
        style = {
          display: "block",
          font: "10px sans-serif"
        };
        horizontal = true;
      }

      // Create SVG container for the legend
      var legendContainer = d3
        .select(container)
        .append("svg")
        .classed("legendContainer", true)
        .classed("do-not-print", true)
        .style(style)
        .attr("height", height)
        .append("g");

      var legend = new rmvpp.Legend(
        d3.select(container).select(".legendContainer>g"),
        measureNames,
        i18n.t("Measures"),
        0,
        null,
        false,
        horizontal
      );
      let mapSpace = $($(container).children()[0]);
      let legendSpace = $($(container).children()[1]);

      if (config.legendLocation == "right") {
        legendSpace.css("float", "right");
      }

      if (config.legendLocation == "left" || config.legendLocation == "right") {
        let legendLongestString = rmvpp.longestString(measureNames);
        mapSpace.css(
          "width",
          "calc(100%  - " + legendLongestString + "px - 20px)"
        );
        legendSpace.css("width", legendLongestString + "px");
      }

      if (config.legendLocation == "up") {
        let legendPerLine = rmvpp.setLegendPerLine(
          measureNames,
          mapSpace.width()
        );

        legendSpace.prev().before(legendSpace);
        legendSpace.css("width", mapSpace.width() + "px");
        legendSpace.css(
          "height",
          (measureNames.length / legendPerLine + 2) * 20 + 10 + "px"
        );
      }

      if (config.legendLocation == "down") {
        let legendPerLine = rmvpp.setLegendPerLine(
          measureNames,
          mapSpace.width()
        );

        legendSpace.css("width", mapSpace.width() + "px");
        legendSpace.css(
          "height",
          (measureNames.length / legendPerLine + 2) * 20 + 10 + "px"
        );
      }

      legend.addColourKey(
        measureNames,
        colour,
        horizontal,
        measureNames,
        legendSpace.width()
      );

      // Make legend a measure selector
      if (config.styleType == "Series Picker") {
        d3.select(container)
          .selectAll(".legendContainer .key")
          .on("click", function (d, i) {
            tooltip.hide();
            processFeatures(featureLayer, i);
          })
          .style("cursor", "pointer");
      }
    }

    let tileLayer = new L.TileLayer[config.mapTile]();
    featureLayerArray[0] = new L.TopoJSON();
    featureLayerArray[0].addData(topojsonExists[0]);
    map.fitBounds(featureLayerArray[0].getBounds());

    tileLayer.addTo(map); // Render map
    $(mapContainer).data({
      mapObject: map
    }); // Add map object to the map itself

    featureLayer = featureLayerArray[0];
    processFeatures(featureLayer, 0);
    featureLayer.addTo(map);

    // Store feature layer in an element on the page so that it can be referenced by other visualisations
    if ($(container).find(".mapData").length == 0) {
      $(container).append($("<span>").addClass("mapData"));
    }
    $(container)
      .find(".mapData")
      .data(featureLayer);
  } else {
    var tileLayer = new L.TileLayer[config.mapTile]();
    var topojsonExistsTmp = JSON.parse(JSON.stringify(topojsonExists));
    for (let key in topojsonExistsTmp[layerNo].objects) {
      var obj = [];
      // Topojson's filtering for the selected location
      if (layerNo > 0) {
        for (
          let j = 0;
          j < topojsonExistsTmp[layerNo].objects[key].geometries.length;
          j++
        ) {
          if (selectedDatum[selectedFeatureCode[layerNo - 1]]) {
            if (
              topojsonExistsTmp[layerNo].objects[key].geometries[j].properties[
              selectedFeatureCode[layerNo - 1]
              ] == selectedDatum[selectedFeatureCode[layerNo - 1]]
            ) {
              obj.push(topojsonExistsTmp[layerNo].objects[key].geometries[j]);
            }
          } else {
            if (
              topojsonExistsTmp[layerNo].objects[key].geometries[j].properties[
              selectedFeatureCode[layerNo - 1]
              ] == selectedDatum[selectedFeatureCode[layerNo - 1]]
            ) {
              obj.push(topojsonExistsTmp[layerNo].objects[key].geometries[j]);
            }
          }
        }
      } else {
        for (
          let j = 0;
          j < topojsonExistsTmp[layerNo].objects[key].geometries.length;
          j++
        ) {
          if (selectedDatum[selectedFeatureCode[layerNo]]) {
            if (
              topojsonExistsTmp[layerNo].objects[key].geometries[j].properties[
              selectedFeatureCode[layerNo]
              ] == selectedDatum[selectedFeatureCode[layerNo]]
            ) {
              obj.push(topojsonExistsTmp[layerNo].objects[key].geometries[j]);
            }
          } else {
            if (
              topojsonExistsTmp[layerNo].objects[key].geometries[j].properties[
              selectedFeatureCode[layerNo]
              ] == selectedDatum[selectedFeatureCode[layerNo]]
            ) {
              obj.push(topojsonExistsTmp[layerNo].objects[key].geometries[j]);
            }
          }
        }
      }
      topojsonExistsTmp[layerNo].objects[key].geometries = [...obj];
    }
    //Create new layer
    featureLayerArray[layerNo] = new L.TopoJSON();
    featureLayerArray[layerNo].addData(topojsonExistsTmp[layerNo]);
    map.fitBounds(featureLayerArray[layerNo].getBounds());

    tileLayer.addTo(map); // Render map
    $(mapContainer).data({
      mapObject: map
    }); // Add map object to the map itself
    featureLayer = featureLayerArray[layerNo];
    controlState = true;
    processFeatures(featureLayer, 0);
    featureLayer.addTo(map);

    if ($(container).find(".mapData").length == 0) {
      $(container).append($("<span>").addClass("mapData"));
    }
    $(container)
      .find(".mapData")
      .data(featureLayer);

    // Draw legend
    if (config.legend) {
      // Create SVG container for the legend
      var legendContainer = d3
        .select(container)
        .append("svg")
        .classed("legendContainer", true)
        .classed("do-not-print", true)
        .style({
          "margin-left": "10px",
          display: "inline-block",
          font: "10px sans-serif"
        })
        .attr("height", height)
        .append("g");

      var legend = new rmvpp.Legend(
        d3.select(container).select(".legendContainer>g"),
        measureNames,
        i18n.t("Measures"),
        0
      );
      legend.addColourKey(measureNames, colour);

      // Make legend a measure selector
      if (config.styleType == "Series Picker") {
        d3.select(container)
          .selectAll(".legendContainer .key")
          .on("click", function (d, i) {
            tooltip.hide();
            processFeatures(featureLayer, i);
          })
          .style("cursor", "pointer");
      }
    }
  }
};

const getExtraFields = function () {
  return innerExtraFields;
};

const clearExtraFields = function () {
  if (!innerExtraFields) {
    return;
  }

  var plugin = rmvpp.Plugins[pluginName];
  var configurationParameters = plugin.configurationParameters;

  for (let i = 0; i < plugin.configurationParameters.length; i++) {
    var fieldName = configurationParameters[i].targetProperty;

    for (var j = 0; j < innerExtraFields.length; j++) {
      let shouldCountinue = true;

      for (var k = 0; k < innerExtraFields[j].fields.length; k++) {
        if (innerExtraFields[j].fields[k].targetProperty === fieldName) {
          plugin.configurationParameters.splice(i, 1);
          i = i - 1;
          shouldCountinue = false;
          break;
        }
      }

      if (shouldCountinue == false) {
        break;
      }
    }
  }

  innerExtraFields = [];
};

const setExtraFields = function (extraFields) {
  var indexOfTopojson = -1;
  var plugin = rmvpp.Plugins[pluginName];

  for (var i = 0; i < plugin.configurationParameters.length; i++) {
    if (
      plugin.configurationParameters[i].targetProperty.startsWith("topojson")
    ) {
      indexOfTopojson = i;
    }
  }

  indexOfTopojson += 3;

  if (extraFields) {
    for (var i = 0; i < extraFields.length; i++) {
      if (extraFields[i].visName === pluginName) {
        for (var j = 0; j < extraFields[i].fields.length; j++) {
          var fieldName = extraFields[i].fields[j].targetProperty;
          var hasField = false;

          var plugin = rmvpp.Plugins[pluginName];

          for (var k in plugin.configurationParameters) {
            if (plugin.configurationParameters[k].targetProperty == fieldName) {
              hasField = true;
              break;
            }
          }

          if (hasField == false) {
            plugin.configurationParameters.splice(
              indexOfTopojson++,
              0,
              extraFields[i].fields[j]
            );
          }
        }
      }
    }

    var existingExtraFields = [...getExtraFields()];

    if (existingExtraFields) {
      for (var i = 0; i < extraFields.length; i++) {
        existingExtraFields.push(extraFields[i]);
      }
    } else {
      existingExtraFields = extraFields;
    }

    innerExtraFields = existingExtraFields;

    //sessionStorage.setItem(pluginName, JSON.stringify(existingExtraFields));
  }
};

// Haritanın katmanını sessionda tutup, rapor değişimlerinde set eden metod.
function checkLayerAndDatum(vis) {
  return {
    layerNo: 0,
    selectedDatum: undefined
  };
}

class MapChoropleth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadVisible: false,
      currIndex: 1
     }
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  isLegendSizeWillCalculate = config => {
    return config.legend
      ? config.legendLocation === "up" ||
        config.legendLocation === "down"
        ? true
        : false
      : false;
  };

  /*
  * Changes upload visible status
  */
  changeUploadVisible = (status) => {
    this.setState({
      uploadVisible: status
    })
  }

  calculatePluginHeight = (plugin, settings) => {
    let legendHeight = this.isLegendSizeWillCalculate(plugin.config) ? 70 : 0;
    let containerHeight = settings.grid.rowHeight * plugin.h;
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginMinHeightDifference = 10;
    let maxHeight =
      containerHeight -
      (pluginMinHeightDifference +
        pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        parseInt(pluginTitleContainer.css("padding-bottom")) +
        pluginContainerBorder +
        legendHeight);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    tempPlugin.highlightFeatures = highlightFeatures

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }
  
  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <MapChoroplethConfiguration
          config={{ ...props.config }}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          uploadVisible={this.state.uploadVisible}
          changeUploadVisible={this.changeUploadVisible}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  /* 
  * Opens conditional formatting component, (add and edit rules area etc.)
  */
  getConditionalFormattingComponent = props => {
    let columnMap = this.props.plugin.columnMap
    
    // Eski silinen kolonlar id ile geldiği için comment yapılmıştır. #1112 issue. 
    // let hasColumnMapAnyColumn = findColumnMapHasAnyColumn(props.plugin.columnMap)
    // let isPluginNewPlugin = this.props.isNewPlugin ? true : false

    // if (hasColumnMapAnyColumn) {
    //   if (isPluginNewPlugin) {
    //     columnMap = props.plugin.columnMap
    //   } else {

    //     columnMap = this.props.columnMapWithLocationFieldName
    //   }
    // } else {
    //   columnMap = props.plugin.columnMap
    // }

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        pluginId={props.plugin.id}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
        isTargetColumnAllColumns={true}
        isLockedTargetValue={true}
      />
    );
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <MapChoroplethData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      code: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Code.Name"),
        type: "dim",
        required: true,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Code.Desc"),
        multiple: true,
        minimumColumnSize: 1,
        data: []
      },
      desc: {
        name: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.Description.Name"
        ),
        type: "dim",
        desc: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.Description.Desc"
        ),
        required: true,
        minimumColumnSize: 1,
        multiple: true,
        data: []
      },
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        type: "fact",
        required: true,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        multiple: true,
        minimumColumnSize: 1,
        data: []
      },
      vary: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Vary.Name"),
        type: "fact",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Vary.Desc"),
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        type: "hidden",
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  /* 
  * Converts columnMap a conditional format object {targetcolumns: "allcolumns", leftRule: "mahalle"} etc.
  */
  convertFormatConditionalFormatting = (condFormats, columns) => {
    let condFormatList = [];

    condFormats.map(condItem => {
      condItem.targetColumns.map(targetColumn => {
        let conditionalFormat = {};
        let columnsMap = new Map();

        columns.map(column => {
          columnsMap.set(column.uniqeColumnId, column);
        })

        conditionalFormat.RightRule = condItem.rule.rightRule.rule;
        conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
        conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName  === "{undefined}" ? convertHTMLRuletoRule(condItem.rule.leftRule).ruleColumnName : condItem.rule.leftRule.ruleColumnName;
        conditionalFormat.RightRuleColumnName = condItem.rule.rightRule.ruleColumnName
        conditionalFormat.Columns = columnsMap;
        conditionalFormat.TargetID = targetColumn.TargetID;
        conditionalFormat.TargetName = targetColumn.TargetName;
        conditionalFormat.Operator = condItem.rule.operator;
        conditionalFormat.id = condItem.id;

        conditionalFormat.Style = {
          colour: condItem.options.color,
          icon: condItem.options.icon,
          background: { colour: condItem.options.backgroundColor },
          text: {
            bold: condItem.options.text.bold,
            italic: condItem.options.text.italic,
          },
        };

        condFormatList.push(conditionalFormat);
      });
    });

    return condFormatList;
  };

  pluginRender = (divId, originalData, columnMap, config, condFormats, filters) => {
    let data = deepCopy(originalData)

    this.setState({
      ...this.state,
      currIndex: 1
    })

    let choropletUtils = {
      pluginLayers: {[this.props.plugin.id]: {layer: 1, value: undefined}},
      pluginId: this.props.plugin.id,
      currentIndex: this.state.currIndex
    }

    store.dispatch(setChoroplethUtils(choropletUtils))

    if (condFormats !== undefined) {
      let choroplethColumnMap = []

      for (let i = 0; i < columnMap.measure.length; i++) {
        choroplethColumnMap.push(columnMap.measure[i])
      }

      for (let i = 0; i < columnMap.desc.length; i++) {
        choroplethColumnMap.push(columnMap.desc[i])
      }

      for (let i = 0; i < columnMap.code.length; i++) {
        choroplethColumnMap.push(columnMap.code[i])
      }

      condFormats = this.convertFormatConditionalFormatting(condFormats, choroplethColumnMap);
    } else {
      condFormats = [];
    }

    const THIS = this;
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];
    let vis;

    let selectedName;
    let tmpMap;
    let dataSum;
    let tooltip;

    /*
    * Adds condformat legend to mapchoropleth div.
    */
    function addCondFormLegend() {
      let usedCondFormatIds = new Set();

      condFormats.forEach(function (d, i) {
        if (usedCondFormatIds.has(d.id)) {
          return;
        } else {
          usedCondFormatIds.add(d.id);
        }

        let condLegend = document.createElement("div");
        prepareConditionalLegends(condLegend);

        let condLegendMarker = document.createElement("span");
        prepareConditionalLegendMarkers(condLegendMarker, d);

        let condLegendMarkerBackground = document.createElement("span");

        if (d.Style.background) {
          condLegendMarkerBackground.setAttribute("class", "dot");
          condLegendMarkerBackground.setAttribute(
            "style",
            "background: " +
            d.Style.background.colour +
            "; color:" +
            d.Style.background.colour +
            "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 10px; border-color: rgb(255, 255, 255); border-radius: 10px; display: inline-block; margin-left: 2px; border: 1px solid white;"
          );
        }

        let condLegendText = document.createElement("span");
        condLegendText.setAttribute("class", "text");
        condLegendText.setAttribute(
          "style",
          "color: rgb(55, 61, 63); font-size: 12px; font-family: Helvetica, Arial, sans-serif;"
        );

        let ruleDescription = THIS.props.plugin.conditionalFormats[i]?.rule?.conditionalFormatRule;
        // if rule description is defined in text area, legend will use user input.

        let textNode = document.createTextNode(
          ruleDescription ? " " + ruleDescription  : " " + d.LeftRuleColumnName + " " + d.Operator + " " + d.RightRuleColumnName + ""
        );
        
        condLegendText.appendChild(textNode);
        condLegend.appendChild(condLegendMarker);
        condLegend.appendChild(condLegendMarkerBackground);
        condLegend.appendChild(condLegendText);

        $(container).find("#showCondForm").append(condLegend);
      });
    }

    function prepareConditionalLegends(conditionalLegendDiv) {
      conditionalLegendDiv.setAttribute("class", "apexcharts-legend-series");
      conditionalLegendDiv.setAttribute("style", "margin: 0px 5px;");
    }

    function prepareConditionalLegendMarkers(conditionalLegendMarkersDiv, d) {
      conditionalLegendMarkersDiv.setAttribute("class", "dot");
      conditionalLegendMarkersDiv.setAttribute(
        "style",
        "background: " +
        d.Style.colour +
        "; color:" +
        d.Style.colour +
        "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 10px; border-color: rgb(255, 255, 255); border-radius: 10px; display: inline-block; border: 1px solid white;"
      );
    }

    rmvpp.leafletExtension(L, topojson, THIS.props.plugin);

    data.sort(function (a, b) {
      return parseInt(a.code[0].value) - parseInt(b.code[0].value);
    });

    var controlState = false;
    var homeButton = false;
    var selected;
    var changeLayer = false;

    var width = +config.width;
    var height = +config.height - 35;
    var visWidth = "100%";
    var visHeight = height + "px";

    if (typeof JSON.parse(sessionStorage.getItem("layerNo")) !== "object") {
      sessionStorage.setItem("layerNo", JSON.stringify({}));
    }

    if (
      typeof JSON.parse(sessionStorage.getItem("selectedDatum")) !== "object"
    ) {
      sessionStorage.setItem("selectedDatum", JSON.stringify({}));
    }

    if (config.mapTileSelection == "Atlas Map Tile") {
      L.TileLayer.Default = L.TileLayer["AtlasOSM"];
    } else if (config.mapTileSelection == "Open Street Map Tile") {
      L.TileLayer.Default = L.TileLayer["OSM"];
    } else if (config.mapTileSelection == "Empty Tile") {
      L.TileLayer.Default = L.TileLayer["EmptyTile"];
    } else if (config.mapTileSelection == "BasarSoft Tile") {
      L.TileLayer.Default = L.TileLayer["BasarSoftTile"];
    }

    if (config.featureCode == "") {
      showNotificationWithIcon(
        i18n.t("Error"),
        i18n.t("PluginErrors.CannotRenderWithoutTopoJson"),
        "error"
      );
    }

    var varyColour = false; // Check if vary by colour has been defined
    if (columnMap.vary && columnMap.vary.Code && columnMap.vary.Code != "") {
      varyColour = true;
    }

    // Throw error if incompatible options set
    if (varyColour && columnMap.measure.length > 1) {
      showNotificationWithIcon(
        i18n.t("Error"),
        i18n.t("PluginErrors.CannotRenderVisualisation"),
        "error"
      );
    }

    if (columnMap.code && columnMap.code.length > 1 && !data[0].isCopied) {
      var resultObjectData = [];
      var parentData = [];
      // tmpMap = [...new Set(data.map(function (a) { return a["Ilce kodu"] }))]
      tmpMap = [
        ...new Set(
          data.map(function (a) {
            return a.code[0].value;
          })
        )
      ];

      tmpMap.forEach(e => {
        // dataSum = data.filter(a => a["Ilce kodu"] == e).map(a => parseInt(a["Abone #"])).reduce((acc, score) => acc + score, 0)
        // dataSum = data.filter(a => a.code[0].value == e).map(a => parseInt(a["Abone #"])).reduce((acc, score) => acc + score, 0)
        dataSum = data.filter(a => a.code[0].value == e).map(a => parseInt(a.measure[0].value)).reduce((acc, score) => acc + score, 0);
        // resultObjectData.push({ e: dataSum });
        resultObjectData.push({
          key: e,
          value: dataSum
        });
        // Object.values(object)
      });

      var subdata = JSON.parse(JSON.stringify(data));
      if (layerNumber == 0 || layerNumber == undefined) {
        resultObjectData.forEach(e => {
          var veri = data.filter(a => a.code[0].value == e.key);
          for (var i = 0; i < data.length; i++) {
            if (data[i].code[0].value == e.key) {
              data[i].measure[0].value = e.value;
              parentData.push(data[i]);
              break;
            }
          }
        });
          data = parentData;
          data[0]["isCopied"] = true
      }
    }

    //Selected maps
    var selectedMaps = [];
    var selectedFeatureCode = [];
    var selectedFeatureDesc = [];

    for (let key in config) {
      if (key.includes("topojson")) {
        if (validCheck(config[key])) {
          if (wellDefinedTopojsonOldPaths[config[key]]) {
            config[key] = wellDefinedTopojsonOldPaths[config[key]];
          }

          selectedMaps.push(config[key]);
        }
      }
      
      if (key.includes("featureCode")) {
        if (validCheck(config[key])) {
          selectedFeatureCode.push(config[key]);
        }
      }
      
      if (key.includes("featureDesc")) {
        if (validCheck(config[key])) {
          selectedFeatureDesc.push(config[key]);
        }
      }
    }

    // Create container for map
    var mapContainer = d3
      .select(container)
      .append("div")
      .attr("class", "map print-as-map")
      .style({
        width: visWidth,
        height: visHeight,
        display: "inline-block"
      })[0][0];

    tooltip = new rmvpp.Tooltip(mapContainer); // Create tooltip object
    rmvpp.loadingScreen(mapContainer, "#1695f0", "Loading TopoJSON...");

    //Load Topojson file if it exists
    var topojsonExists = [];

    for (let i = 0; i < selectedMaps.length; i++) {
      let topojsonPath = selectedMaps[i];
      let isTopojsonForTenant = topojsonPath.includes("mapOperations#");
      let url = TOPOJSON_PATH + "/" + topojsonPath;
      
      if (isTopojsonForTenant) {
        url = `${API_BASE}/map/${topojsonPath.split("mapOperations#")[1]}`;
      }

      $.ajax({
        dataType: "json",
        async: false,
        url: url,
        headers: {
          "Authorization": `Bearer ${Cookies.get("ORA_BIPS_NQID")}`
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error("topojson error")
        },
        success: function (result) {
          let json = result;
          topojsonExists.push(json);
        }
      });
    }

    var colour = rmvpp.colourScale(measureNames, config.colours);
    var map;
    if (topojsonExists.length != 0) {
      processLayer(topojsonExists);
    }

    // Restructure data frame if vary by colour being used
    var featureLayer;
    var featureLayerArray = [];

    if (varyColour) {
      var layerNumber;
      for (let i = 0; i < featureLayerArray.length; i++) {
        if (map.hasLayer(featureLayerArray[i])) {
          layerNumber = i;
        }
      }

      if (layerNumber == undefined) {
        layerNumber = checkLayerAndDatum(vis).layerNo;
      }

      const columnMapTmp = {
        ...columnMap
      };
      columnMapTmp.code = columnMapTmp.code[layerNumber];
      columnMapTmp.desc = columnMapTmp.desc[layerNumber];
      var pivotData = rmvpp.pivotData(
        data,
        columnMapTmp,
        "vary",
        "code",
        "measure",
        ["desc"],
        true
      );
      var dataTemp = [];
      let dataCopy = JSON.parse(JSON.stringify(data));
      let temp = dataCopy[0].code[0].value;
      let check = temp;
      let sole = -1;

      for (let i = 0; i < dataCopy.length; i++) {
        if (temp == dataCopy[i].code[0].value) {
          if (temp == check) {
            dataTemp.push(dataCopy[i]);
            check++;
            sole++;
          }
          if (
            data[i].vary == dataTemp[sole].vary &&
            data[i].desc[0].value == dataTemp[sole].desc[0].value
          ) {
            data[i].measure[0].name = dataCopy[i].vary;
            dataTemp[sole].measure.splice(0, 1);
          }
          data[i].measure[0].name = dataCopy[i].vary;
          dataTemp[sole].measure.push(data[i].measure[0]);
          dataTemp[sole].vary = "";
        } else {
          temp = dataCopy[i].code[0].value;
          check = temp;
          i--;
        }
      }

      data = [...dataTemp];
      // var datam = pivotData.data;
      var measureNames = pivotData.colNames;
      var legendTitle = columnMap.vary.Name;
    } else {
      var measureNames = columnMap.measure.map(function (m) {
        return m.Name;
      });
      var legendTitle = i18n.t("Measures");
    }

    // Process JSON features once map is loaded
    function processLayer(topojsonExists) {
      // Create a map
      map = L.map(mapContainer, {
        zoomAnimation: true, // Removing the zoom animation makes D3 overlay work more nicely when zooming
        fadeAnimation: true, // Fade animation is ok
        scrollWheelZoom: false
      });

      // var stateRefershButton = L.easyButton({
      // 	states: [{
      // 		stateName: 'back-to-home',        // name the state
      // 		icon: 'fa-home',               // and define its properties
      // 		title: 'back to home',      // like its title
      // 		onClick: function (btn, map) {
      // 			var scope = angular.element($(".visBuilder")).scope();
      // 			scope.reloadDB();
      // 		}
      // 	}]
      // });
      //stateRefershButton.addTo(map);

      var stateChangingButton = L.easyButton({
        states: [
          {
            stateName: "back-to-home", // name the state
            icon: "fa-sync", // and define its properties
            title: "back to home", // like its title
            onClick: function (btn, map) {
              for (let i = 0; i < featureLayerArray.length; i++) {
                if (map.hasLayer(featureLayerArray[i])) {
                  map.removeLayer(featureLayerArray[i]);

                  THIS.setState({
                    ...THIS.state,
                    currIndex: 1
                  })

                  store.dispatch(setPluginTriggerObject({}, {}, false, false))

                  let choropletUtils = {
                    pluginLayers: {[THIS.props.plugin.id]: {layer: 1, value: undefined}},
                    pluginId: THIS.props.plugin.id,
                    currentIndex: 1
                  }
    
                  store.dispatch(setChoroplethUtils(choropletUtils))

                  break;
                }
              }

              var tileLayer = new L.TileLayer[config.mapTile]();
              featureLayerArray[0] = new L.TopoJSON();
              featureLayerArray[0].addData(topojsonExists[0]);
              map.fitBounds(featureLayerArray[0].getBounds());

              tileLayer.addTo(map); // Render map
              $(mapContainer).data({
                mapObject: map
              }); // Add map object to the map itself

              featureLayer = featureLayerArray[0];
              homeButton = true;

              if (parentData) {
                data = JSON.parse(JSON.stringify(parentData));
              }

              processFeatures(featureLayer, 0);

              featureLayer.addTo(map);
            }
          }
        ]
      });

      stateChangingButton.addTo(map);
    }

    layerNumber = checkLayerAndDatum(vis).layerNo;

    if (layerNumber != 0 && layerNumber != undefined) {
      if (subdata) {
        data = JSON.parse(JSON.stringify(subdata));
      }
    }

    startLayer(
      topojsonExists,
      featureLayerArray,
      config,
      map,
      mapContainer,
      processFeatures,
      container,
      height,
      measureNames,
      colour,
      selectedFeatureCode,
      vis,
      tooltip
    );

    /*  */
    function zoomToFeature(e) {
      map.fitBounds(e.target.getBounds());
    }

    function getGradColour(min, max, minColour, currColour, maxColour) {
      var gradColour;
      if (config.scaleType == "Linear") {
        gradColour = d3.scale
          .linear()
          .domain([min, min + (max - min) / 2, max])
          .range([minColour, currColour, maxColour]);
      } else {
        try {
          var colourRange = [
            rmvpp.setBrightness(currColour, 80),
            rmvpp.setBrightness(currColour, 70),
            rmvpp.setBrightness(currColour, 60),
            rmvpp.setBrightness(currColour, 50),
            rmvpp.setBrightness(currColour, 40),
            rmvpp.setBrightness(currColour, 30),
            rmvpp.setBrightness(currColour, 20) // A seperate color scale is determined for each percentage
          ];

          gradColour = [];
          measureNames.forEach(function (m, i) {
            var qScale = d3.scale.quantile()
              .domain(data.map(function (d) {
                return +d.measure[i].value;
              }))
              .range(colourRange);
            gradColour.push(qScale);
          });
        } catch {
          gradColour = d3.scale.linear()
            .domain([min, (min + (max - min) / 1000), max]) // The scale of the given color scale is increased
            .range([minColour, currColour, maxColour]);
        }
      }
      return gradColour;
    }

    function getColourScales(idx) {
      var currColour = colour(measureNames[idx]); // Colour of the data series
      if (config.seriesScale == "Individual") {
        if (idx < 0) {
          idx = 0;
        }
        // Define range for the gradient
        var min = d3.min(
          data.map(function (d) {
            return +d.measure[idx].value;
          })
        );
        var max = d3.max(
          data.map(function (d) {
            return +d.measure[idx].value;
          })
        );
      } else {
        // Define range for the gradient across all values
        var currColour = colour(measureNames[idx]);
        var min = d3.min(
          data.map(function (d) {
            return d3.min(
              d.measure.map(function (m) {
                return +m.value;
              })
            );
          })
        );
        var max = d3.max(
          data.map(function (d) {
            return d3.max(
              d.measure.map(function (m) {
                return +m.value;
              })
            );
          })
        );
      }
      var minColour = rmvpp.setBrightness(currColour, 80);
      var maxColour = rmvpp.setBrightness(currColour, 20);
      return getGradColour(min, max, minColour, currColour, maxColour);
    }

    // Process each feature in layer
    function processFeatures(fLayer, i) {
      $(mapContainer)
        .find(".loading")
        .remove();
      var gradColour;
      if (config.styleType == "Series Picker") {
        gradColour = getColourScales(i);
      }

      for (let k = 0; k < featureLayerArray.length; k++) {
        if (map.hasLayer(featureLayerArray[k])) {
          layerNumber = k;
          if (controlState == true) {
            layerNumber = k + 1;
            controlState = false;
          }
        }
      }

      if (homeButton == true) {
        layerNumber = 0;
        homeButton = false;
      }

      if (layerNumber == undefined) {
        layerNumber = checkLayerAndDatum(vis).layerNo;
      }

      fLayer.eachLayer(function (layer) {
        var code = layer.feature.properties[selectedFeatureCode[layerNumber]];
        var datum = data.filter(function (d) {
          if (d.code[layerNumber]) {
            return d.code[layerNumber].value == code;
          }

          return null
        })[0];

        if (datum !== undefined && datum !== null) {
          for (let perDatum = 0; perDatum < datum.measure.length; perDatum++) {
            datum[datum.measure[perDatum].name] = datum.measure[perDatum].value
          }

          for (let perCode = 0; perCode < datum.code.length; perCode++) {
            datum[datum.code[perCode].name] = datum.code[perCode].value
          }

          if (datum[datum.desc[layerNumber].name] === "notCurrentTopojson") {
            datum = undefined;
          }
        }

        layer.feature.data = datum; // Add OBIEE datum to the feature layer
        let isConditionalExist = false
        let conditionalColor

        if (datum !== undefined) {
          for (let i = 0; i < condFormats.length; i++) {
            let isCurrentLayerNumberZero = layerNumber === 0 ? true : false
            let isDatumDescriptionLengthGreaterOne = datum.desc.length > 1 ? true : false
            let isDatumCodeLengthGreaterOne = datum.code.length > 1 ? true : false

            if (isCurrentLayerNumberZero && isDatumDescriptionLengthGreaterOne && isDatumCodeLengthGreaterOne) {
              let currentTopojson = datum.desc[layerNumber].name
              let currentCode = datum.code[layerNumber].name
              let outerData = datum.desc.filter(d => d.name !== currentTopojson)[0].name
              let outerCode = datum.code.filter(d => d.name !== currentCode)[0].name
              datum[outerData] = 'notCurrentTopojson'
              datum[outerCode] = '"notCurrentCode"'
            }

            let comparedObj = compare(datum, condFormats[i])

            if (comparedObj.status) {
              isConditionalExist = true
              conditionalColor = condFormats[i].Style.background.colour
            }
          }
        }

        if (config.styleType == "Split Series") {
          if (datum) {
            var sorted = datum.measure.sort(function (a, b) {
              return d3.descending(+a.value, +b.value);
            });
            if (varyColour) {
              gradColour = isConditionalExist ? conditionalColor : getColourScales(i);
            } else {
              gradColour = isConditionalExist ? conditionalColor : getColourScales(
                measureNames.indexOf(sorted[0].name)
              );
            }
          }
        }

        var fillColour = config.nullColour,
          nullData = false;
        if (datum) {
          if (config.scaleType == "Linear") {
            fillColour = isConditionalExist ? conditionalColor : gradColour(datum.measure[i].value);

            if (isConditionalExist) {
              isConditionalExist = false
            }
          } else {
            try {
              fillColour = isConditionalExist ? conditionalColor : gradColour[i](datum.measure[i].value);

              if (isConditionalExist) {
                isConditionalExist = false
              }
            } catch {
              fillColour = isConditionalExist ? conditionalColor : gradColour(datum.measure[i].value);

              if (isConditionalExist) {
                isConditionalExist = false
              }
            } // Works when there is no multi colour scale
          }
        } else {
          // Cater for nulls
          datum = {
            code:
              layer.feature.properties[
              selectedFeatureCode[featureLayerArray.length - 1]
              ]
          };
          nullData = true;
        }

        var style = {
          color: "#333",
          weight: 1,
          opacity: +config.opacity,
          fillColor: fillColour,
          fillOpacity: +config.opacity,
          className: "fillTransition"
        };
        layer.setStyle(style);

        let isLayerExist = layer ? true : false
        let isLayerOptionsExist = isLayerExist && layer["options"] ? true : false
        let isLayerOptionClickableExist = isLayerOptionsExist && layer.options.clickable ? true : false
        let canChangeClickableOption = isLayerExist && isLayerOptionClickableExist ? true : false

        if (layer.feature.data === undefined && canChangeClickableOption) {
          layer.options.clickable = false;
        }

        layer
          .off("mouseover")
          .on("mouseover", function (e) {
            highlight(layer, config.StrokeWidth);
            for (let i = 0; i < data.length; i++) {
              if (code == data[i].code[0].value) {
                datum.desc = data[i].desc;
              }
            }

            for (var k = 0; k < featureLayerArray.length; k++) {
              if (map.hasLayer(featureLayerArray[k])) {
                layerNumber = k;
              }
            }
            if (layerNumber == undefined) {
              layerNumber = 0;
            }

            if (nullData) {
              tooltip.displayFull(
                ["code"],
                columnMap,
                datumTmp,
                e.originalEvent
              );
            } else {
              var datumTmp = {
                ...datum
              };

              let datumForDisplay = {
                ...datum
              }

              datumForDisplay.code = datumForDisplay.code[layerNumber].value;
              datumForDisplay.desc = datumForDisplay.desc[layerNumber].value;

              datumTmp.code = [datumTmp.code[layerNumber]];
              datumTmp.desc = [datumTmp.desc[layerNumber]];

              const columnMapTmp = {
                ...columnMap
              };

              let columnMapFilter = {
                ...columnMap
              }

              columnMapTmp.code = columnMapTmp.code[layerNumber];
              columnMapTmp.desc = columnMapTmp.desc[layerNumber];

              columnMapFilter.code = [columnMapFilter.code[layerNumber]];
              columnMapFilter.desc = [columnMapFilter.desc[layerNumber]];

              tooltip.displayList(
                datumForDisplay,
                "desc",
                "measure",
                columnMapTmp,
                e.originalEvent,
                colour,
                datum.measure[i].name
              );

              // This area for if customer wants to show conditional format tooltip
              // Bu alan müşteri conditional format tooltipini görmek isterse açılacaktır.
              // if (config.condFormatLegend) {
              //   condFormats.map(cf => {
              //     $(tooltip.Element[0]).find("ul").append(`<li class='measure'><div class='legend' style='background: ${cf.Style.background.colour};'></div><span style="font-weight: bold;">${cf.LeftRuleColumnName + " " + cf.Operator + " " + cf.RightRuleColumnName}</span></li>`)
              //   })
              // }

              let mousePosition = { x: window.event.pageX, y: window.event.pageY }
              let isPluginHasInteraction = THIS.props.interactions.filter(interaction => interaction.sourceId === THIS.props.plugin.id).length > 0 ? true : false
              let isPluginInteractionHover = THIS.props.interactions.filter(interaction => interaction.sourceId === THIS.props.plugin.id && interaction.actions.filter(action => action.trigger === "hoverFeature").length > 0).length > 0 ? true : false

              if (isPluginHasInteraction && isPluginInteractionHover) {
                createTrigger(
                  actions,
                  columnMapFilter,
                  container,
                  "hoverFeature",
                  datumTmp,
                  THIS.props.plugin.id,
                  THIS.props.interactions,
                  THIS.props.navigations,
                  mousePosition
                );
              }
            }
          })
          .off("mouseout")
          .on("mouseout", function (e) {
            revert(layer, +config.opacity);
            tooltip.hide();
          }) 
          .off("click")
          .on("click", function (e) {
            /*
            * Create trigger callback. For trigger action detail or first index
            */
            var createTriggerWithIndex = (e, type, currIndex) => {
              var i;
              var obj = [];
              var tileLayer = new L.TileLayer[config.mapTile]();
              var topojsonExistsTmp = JSON.parse(JSON.stringify(topojsonExists));
              var topoJsonLength = type === "detail" && currIndex === 1 ? 2 : 1
              var selected;
              var changeLayer = false;
              
              if(isPluginHasInteraction){
                let isSelectedColorEqualsFillColor = e.target.options.fillColor !== config.selectedColor ? true : false

                processFeatures(featureLayerArray[layerNumber], layerNumber)

                if (isSelectedColorEqualsFillColor) {
                  e.target.setStyle({
                  fillColor: config.selectedColor,
                  });
                }
              }
                            
            
              tooltip.hide();

              if (subdata) {
                data = JSON.parse(JSON.stringify(subdata));
              }

              for (i = 0; i < featureLayerArray.length; i++) {
                if (map.hasLayer(featureLayerArray[i])) {
                  if (
                    topoJsonLength > 1 &&
                    i < topoJsonLength - 1
                  ) {
                    selected =
                      e.target.feature.properties[selectedFeatureDesc[0]];
                    for (let key in topojsonExistsTmp[i + 1].objects) {
                      var obj = [];
                      // Topojson's filtering for the selected location
                      for (
                        let j = 0;
                        j <
                        topojsonExistsTmp[i + 1].objects[key].geometries.length;
                        j++
                      ) {
                        if ($.isArray(datum.code)) {
                          if (
                            topojsonExistsTmp[i + 1].objects[key].geometries[j]
                              .properties[selectedFeatureCode[i]] ==
                            datum.code[i].value
                          ) {
                            obj.push(
                              topojsonExistsTmp[i + 1].objects[key].geometries[j]
                            );
                          }
                        } else {
                          if (
                            topojsonExistsTmp[i + 1].objects[key].geometries[j]
                              .properties[selectedFeatureCode[i]] == datum.code
                          ) {
                            obj.push(
                              topojsonExistsTmp[i + 1].objects[key].geometries[j]
                            );
                          }
                        }
                      }
                      topojsonExistsTmp[i + 1].objects[key].geometries = [...obj];
                    }
                    //Create new layer
                    featureLayerArray[i + 1] = new L.TopoJSON();
                    featureLayerArray[i + 1].addData(topojsonExistsTmp[i + 1]);
                    //map.fitBounds(featureLayerArray[i + 1].getBounds()); // we changed behaviour of chropleth,
                    // so, double click to zoom and fitbounds action dont necessary. Now, at inner layer dbl click triggers zoom and fitbounds functions

                    tileLayer.addTo(map); // Render map
                    $(mapContainer).data({
                      mapObject: map
                    }); // Add map object to the map itself
                    featureLayer = featureLayerArray[i + 1];

                    if (Object.keys(featureLayer._layers).length === 0) {
                      showError(i18n.t("Errors.TopojsonErrorLayer"));
                      return;
                    }

                    controlState = true;
                    processFeatures(featureLayer, 0);

                    if ($(container).find(".mapData").length == 0) {
                      $(container).append($("<span>").addClass("mapData"));
                    }
                    $(container)
                      .find(".mapData")
                      .data(featureLayer);


                    //Add the new layer to the map
                    if (
                      !map.hasLayer(featureLayerArray[i + 1]) &&
                      featureLayerArray[i + 1] != undefined
                    ) {
                      map.removeLayer(featureLayerArray[i]);
                      map.addLayer(featureLayerArray[i + 1]);
                      changeLayer = true;
                      map.fitBounds(featureLayerArray[layerNumber].getBounds());
                      var selectedDatum = {
                        ...datum
                      };
                      for (i = 0; i < featureLayerArray.length; i++) {
                        if (map.hasLayer(featureLayerArray[i])) {
                          layerNumber = i;
                        }
                      }
                      if (layerNumber == undefined) {
                        layerNumber = 0;
                      }

                      break;
                    }
                  }
                }
              }

              layer.on("dblclick", function (e) {
                zoomToFeature(e);
              });

              //learning which layer is on the map
              for (i = 0; i < featureLayerArray.length; i++) {
                if (map.hasLayer(featureLayerArray[i])) {
                  layerNumber = i;
                }
              }
              if (layerNumber == undefined) {
                layerNumber = 0;
              }
              //Edit columns and descriptions according to the layer in the map
              const columnMapTmp = {
                ...columnMap
              };
              var datumTmp = {
                ...datum
              };
              if ($.isArray(datumTmp.desc)) {
                datumTmp.code = [datumTmp.code[layerNumber]];
                datumTmp.desc = datumTmp.desc[layerNumber] ? datumTmp.desc[layerNumber].value : datumTmp.desc[layerNumber];
              }

              if (!$.isArray(columnMapTmp.code)) {
                columnMapTmp.code = columnMap.code[layerNumber];
                columnMapTmp.desc = columnMap.desc[layerNumber];
              }

              if ($.isArray(columnMapTmp.code)) {
                if (columnMapTmp.code.length > 1) {
                  if (changeLayer) {
                    columnMapTmp.code = [columnMap.code[layerNumber - 1]];
                    columnMapTmp.desc = [columnMap.desc[layerNumber - 1]];
                    changeLayer = false;
                  } else {
                    columnMapTmp.code = [columnMap.code[layerNumber]];
                    columnMapTmp.desc = [columnMap.desc[layerNumber]];
                  }
                }
              }

              if (selected != undefined) {
                selectedName = selected;
                $(container).append(
                  '<label id="mapTitle" style="display:none">' +
                  selected +
                  "</label>"
                );
                datumTmp = {
                  ...datum
                };

                if (Array.isArray(datumTmp.code)) {
                  datumTmp.code = [datumTmp.code[layerNumber - 1]];
                  datumTmp.desc = datumTmp.desc[layerNumber - 1].value;
                } else {
                  datumTmp.code = datumTmp.code;

                  if (datumTmp["desc"] && datumTmp.desc["value"]) {
                    datumTmp.desc = datumTmp.desc.value;
                  }
                }
              } else {
                // for title interaction
                let hasFeature = 0; // checks if "data" contains selected or not

                for (let loopIndex = 0; loopIndex < data.length; loopIndex++) {
                  //iterate over data to check selected
                  if (
                    typeof datumTmp.code != "undefined" &&
                    data[loopIndex][datumTmp.desc[layerNumber].name] ===
                    datumTmp.desc[layerNumber].value
                  ) {
                    hasFeature = 1;
                  }
                }

                if (hasFeature == 1) {
                  //if "data" contains selected
                  $(container).append(
                    '<label id="mapTitle" style="display:none">' +
                    datumTmp.desc +
                    "</label>"
                  );
                } else {
                  let copyData = data[0]; //get dummy suitable object from "data"
                  copyData.desc[layerNumber].value = "   "; // set value to 3 spaces because title.js checks its length and perform something and value is changing.
                  //we dont want title.js to perform that. So, setted it 3 spaces.
                  datumTmp = {
                    ...copyData
                  };

                  $(container).append(
                    '<label id="mapTitle" style="display:none">' +
                    selectedName +
                    "</label>"
                  ); //append new value to display
                }
              }
              
              let descriptionData = { name: columnMap.desc[layerNumber] ? columnMap.desc[layerNumber].name : "", value: datumTmp.desc }
              datumTmp.desc = [descriptionData];

              let mousePosition = { x: window.event.pageX, y: window.event.pageY }

              THIS.setState({
                ...THIS.state,
                currIndex: 2
              })

              if (columnMap?.code?.length === 2) {
                let choropletUtils = {
                  pluginLayers: {[THIS.props.plugin.id]: {layer: 2, value: e.target.feature.data}},
                  pluginId: THIS.props.plugin.id,
                  currentIndex: THIS.state.currIndex
                }
  
                store.dispatch(setChoroplethUtils(choropletUtils))
              }


              if (type !== "detail") {
                createTrigger(
                  actions,
                  columnMapTmp,
                  container,
                  "clickFeature",
                  datumTmp,
                  THIS.props.plugin.id,
                  THIS.props.interactions,
                  THIS.props.navigations,
                  mousePosition
                ); //Default createTrigger for map-choropleth
              } else {
                store.dispatch(setPluginTriggerObject({}, {}, false, false))
              }

              var elem = document.getElementById("mapTitle");
              elem.parentNode.removeChild(elem);
            }
            
            //Creates trigger for interaction of coloring the filtered layer component with selected color    
            let createTriggerWithIndexForInteraction = (e, detail, type) => {
              let isSelectedColorEqualsFillColor = e.target.options.fillColor !== config.selectedColor ? true : false
              let featureLayerArrayDetail = 0

              if (featureLayerArray.length === 2 && detail === 2) {
                featureLayerArrayDetail = 1
              } else if (featureLayerArray.length === 1 && detail === 1) {
                featureLayerArrayDetail = 0
              }
  
              detail = 0

              processFeatures(featureLayerArray[featureLayerArrayDetail], detail)

              if (isSelectedColorEqualsFillColor) {
                e.target.setStyle({
                  fillColor: config.selectedColor,
                });
              }
            }

            let isPluginHasInteraction = THIS.props.interactions.filter(interaction => interaction.sourceId === THIS.props.plugin.id).length > 0 ? true : false
            let isPluginHasNavigation = THIS.props.plugin.navigations?.length > 0
            let isPluginHasMultipleTopoJson = JSON.parse(JSON.stringify(topojsonExists)).length > 1
            let isPluginHasAction = isPluginHasNavigation || isPluginHasInteraction

            if (isPluginHasAction && isPluginHasMultipleTopoJson) {
              let mousePositionLayer = { x: window.event.offsetX - 90, y: window.event.offsetY + 10 }
              let mousePositionPage = { x: window.event.pageX, y: window.event.pageY }
              let event = e
              let pluginId = THIS.props.plugin.id;
              
              let obj = {
                pluginLayers: {[pluginId]: {layer: THIS.state.currIndex, value: e.target.feature.data}},
                listOfActionsVisibility: true,
                mousePositionLayer: mousePositionLayer,
                mousePositionPage: mousePositionPage,
                event: event,
                pluginId: THIS.props.plugin.id,
                detailCallback: (e, detail, currIndex) => createTriggerWithIndex(e, detail, currIndex),
                detailCallbackForInteraction: (e, detail, currIndex) => createTriggerWithIndexForInteraction(e, detail, currIndex),
                currentIndex: THIS.state.currIndex
              }

              let pluginContent = {
                plugin: THIS.props.plugin,
                model: THIS.props.model,
                columnMap: THIS.props.plugin.columnMapForPlugin,
                container: $("#" + THIS.props.plugin.id),
                event: "clickFeature",
                datum: [{...e.target.feature.data, filter: "=", filterPredicate: "="}],
                pluginId: THIS.props.plugin.id,
                interactions: THIS.props.interactions,
                navigations: THIS.props.plugin.navigations,
                datumForNavigation: [e.target.feature.data],
                id: THIS.props.plugin.id,
                filterOperator: "=",
                drillDowns: [],
              };

              store.dispatch(setChoroplethUtils(obj))
              store.dispatch(setPluginTriggerObject(
                pluginContent,
                mousePositionPage,
                true,
                true
              ))
            } else if (!isPluginHasAction && isPluginHasMultipleTopoJson) {
              createTriggerWithIndex(e, "detail", 1)
            } else {
              createTriggerWithIndex(e)
            }
          });
      });
    }

    if (config.showHideButton) {
      let hideButtonToggleKey = [".map", ".legendContainer"];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }

    if (condFormats.length > 0 && config.condFormat) {
      $(container).append('<div id="showCondForm" style="display:flex; flex-wrap:wrap;"></div>');
      addCondFormLegend();
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let navigationComponent = null;
    
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let conditionalFormatComponent = null;
    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    let isPluginConfigNotUndefined = pluginConfig != undefined ? true : false
    let isLegendLocationLeftOrRight = pluginConfig.legendLocation === "right" || pluginConfig.legendLocation === "left" ? true : false

    if (isPluginConfigNotUndefined && isLegendLocationLeftOrRight) {
      let legendContainer = $("#" + this.props.plugin.id).find(".legendContainer")
      legendContainer.children().children().attr("transform", "translate(-40,0)")
    }

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        this.calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            this.props.plugin.conditionalFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
          {navigationComponent}
          {conditionalFormatComponent}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  setChoroplethUtils,
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapChoropleth);
