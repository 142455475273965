import Cookies from "js-cookie";

/**
 * Retunes the selected model written in the cookie
 */
export const getSelectedModel = () => {
   return Cookies.get("selectedModel");
}

/**
 * 
 * @param {*} id 
 * Sets the id of the selected model, which comes as a parameter, to the cookie.
 */
export const setSelectedModel = (id) => {
     Cookies.set("selectedModel", id);
 }

 /**
  * selected model is delete
  */
 export const removeSelectedModel = () => {
    Cookies.remove("selectedModel")
 }
