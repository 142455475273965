import React from "react";
import DataTableList from "../FormulaEditor/DataTableList";
import FormulaList from "../FormulaEditor/FormulaList";
import FormulaCodeEditor from "../FormulaEditor/FormulaCodeEditor";
import FormulaDescription from "../FormulaEditor/FormulaDescription";
import { Modal } from "antd";
import { connect } from "react-redux";
import { setFormulaEditorContent } from "../FormulaEditor/FormulaEditorAction";
import i18n from "../../../Utils/i18next";
import "./newColumnEditor.css"
import { store } from "../../..";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import initVispLang from "../FormulaEditor/visp-lang";
import { setNewColumnEditorContent, setNewColumnList } from "./NewColumnEditorAction";
import _ from "lodash";
import NewColumnFormulaList from "./NewColumnFormulaList";
import NewColumnFormulaDescription from "./NewColumnFormulaDescription";
import NewColumnCodeEditor from "./NewColumnCodeEditor";
import NewColumnDataTableList from "./NewColumnDataTableList";
import { deepCopy } from "../../../Utils/Global";
import { get, post } from "../../../Utils/WebService";
import { API_BASE } from "../../../config";
import { showNotificationWithIcon } from "../../../Utils/Notification";

/**
 * Formula Editor's main component
 * 
 * This component consists of:
 *  - `DataTableList`
 *  - `FormulaCodeEditor`
 *  - `FormulaDescription`
 *  - `FormulaList`
 */
class NewColumnEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.newColumn.visible,
            column: {
                Code: ""
            },
            model: [],
            languageId: null
        };
    }

    componentWillReceiveProps(nextProps) {
        let flag = false;
        let newState = deepCopy(this.state);

        if (!_.isEqual(nextProps.newColumn.visible, this.props.newColumn.visible)) {
            newState.visible = nextProps.newColumn.visible;

            flag = true
        }

        if (!_.isEqual(nextProps.model, this.state.model)) {
            newState.model = nextProps.model;
            newState.languageId = initVispLang(nextProps.model);

            flag = true;
        }

        if (!_.isEqual(nextProps.newColumn.currentTable, this.props.newColumn.currentTable)) {
            if (nextProps.newColumn.mode !== "add") {
                newState.column = nextProps.newColumn.currentTable;

                flag = true;
            }
        }

        if (flag === true) {
            this.setState(newState);
        }
    }

    /**
     * Closes and resets formula editor
     */
    resetFormulaEditor = () => {
        let newColumnObject = {}

        newColumnObject.table = ""
        newColumnObject.visible = false
        newColumnObject.selectedTable = ""
        newColumnObject.mode = ""
        newColumnObject.code = ""

        store.dispatch(setNewColumnEditorContent(newColumnObject))
    }

    /**
     * Shows confirm cancel modal
     */
    showConfirm = () => {
        Modal.confirm({
            title: i18n.t("FormulaEditor.Titles.AreYouSureCancel"),
            icon: <ExclamationCircleOutlined />,
            content: i18n.t("FormulaEditor.YourChangesWillBeLost"),
            centered: true,
            zIndex: "1001",
            cancelText: i18n.t("Cancel"),
            cancelButtonProps: {
                className: "general-button-outlined",
                style: {
                    padding: "4px 12px",
                    marginRight: "6px",
                }
            },
            okText: i18n.t("OK"),
            okButtonProps: {
                className: "general-button",
                style: {
                    padding: "4px 12px !important",
                    margin: "0",
                    marginLeft: "6px",
                    width: "fit-content"
                }
            },
            onOk: () => {
                Modal.destroyAll();
                this.resetFormulaEditor();
            }
        });
    }

    /**
     * Checks for changes
     * It opens the confirm cancel modal if there is any change
     */
    exitFormulaEditor = () => {
        let column = this.state.column;

        if (column) {
            if (this.props.newColumn.code !== "" && this.props.newColumn.mode === "add") {
                this.showConfirm();
            } else {
                this.resetFormulaEditor();
            }
        }
    }

    // Controls is string only white space
    isWhitespaceString = str => !str.replace(/\s/g, '').length

    /*
    * Controls new column changes
    */
    controlNewColumnChanges = (mode = "add") => {
        let column = mode === "edit" ? deepCopy(this.props.newColumn.edittedColumn) : deepCopy(this.props.newColumn.column)
        let hasError = false

        if (column.aliasName == undefined || column.aliasName === "") {
            hasError = true

            showNotificationWithIcon(i18n.t("NewColumn.NewColumnCantBeEmpty"), null, "warning")
        } 

        if (this.isWhitespaceString(this.props.newColumn.code) || !this.props.newColumn.code) {
            hasError = true

            showNotificationWithIcon(i18n.t("NewColumn.NewColumnCantBeEmpty"), null, "warning")
        }
        
        if (column.aliasName && column.aliasName !== "") {
            let tableKey = this.props.newColumn.selectedTable
            let tables = this.props.model.tables
            let currentTable = tables.find(tb => tb.dataSourceKey + "_" + tb.aliasName === tableKey)
            let canNameChange = currentTable && currentTable.columns.filter(col => col.aliasName === this.props.newColumn.column.aliasName).length > 0
            let isEditMode = this.props.newColumn?.column?.aliasName !== this.props.newColumn?.edittedColumn?.aliasName

            if (canNameChange && isEditMode) {
                hasError = true 

                showNotificationWithIcon(i18n.t("NewColumn.ColumnNamedBefore", { column: this.props.newColumn.column.aliasName }), null, "warning")
            }
        }

        if (this.props.newColumn.column.aliasName.includes(".")) {
            hasError = true 

            showNotificationWithIcon(i18n.t("NewColumn.CantUseDot", { column: this.props.newColumn.column.aliasName }), null, "warning")
        }

        return hasError
    }

    // When column update, gets newColumList and sets Redux
    getAndSetColumnsToFormula = (modelId) => {
        let url = `${API_BASE}/newColumn/${modelId}`
    
        let succesFunc = (res) => {
            store.dispatch(setNewColumnList(res.data))
        }
    
        let errorFunc = (err) => {
            console.log(err)
        }
    
        get(url, succesFunc, errorFunc)
    }

    /**
     * Sets column code, resets and closes formula editor
     */
    applyChanges = () => {
        let hasError = this.controlNewColumnChanges()

        if (hasError) {
            return
        }

        let url = `${API_BASE}/newColumn/add`;
        let model = this.getCopiedModelForNewColumn()
        let column = deepCopy(this.props.newColumn.column)

        column["columnDefaultFormula"] = this.props.newColumn.code

        let postObj = {
            modelId: this.props.model.id,
            column: column,
            table: model.tables[0]
        }

        let succesFunc = (response) => {
            this.props.getModel(model.id, "comesFromNewColumn")

            let afterAddedFunction = {
                visible: false,
                code: "",
            }

            store.dispatch(setNewColumnEditorContent(afterAddedFunction))

            this.getAndSetColumnsToFormula(this.props.model.id)
        }

        let errorFunc = (e) => {
            console.log(e)
        }

        post(url, postObj, succesFunc, errorFunc, false)
    }

    /*
    * Edits column
    */
    editNewColumn = () => {
        let hasError = this.controlNewColumnChanges("edit")

        if (hasError) {
            return
        }

        let url = `${API_BASE}/newColumn/edit`;
        let model = this.getCopiedModelForNewColumn()
        let column = deepCopy(this.props.newColumn.column)
        let edittedColumn = deepCopy(this.props.newColumn.edittedColumn)

        column["columnDefaultFormula"] = this.props.newColumn.code

        let postObj = {
            modelId: this.props.model.id,
            column: column,
            table: model.tables[0],
            edittedColumn: edittedColumn
        }

        let succesFunc = (response) => {
            this.props.getModel(model.id, "comesFromNewColumn")

            let afterEdittedFunction = {
                visible: false,
                code: "",
                edittedColumn: null
            }

            store.dispatch(setNewColumnEditorContent(afterEdittedFunction))

            this.getAndSetColumnsToFormula(this.props.model.id)
        }

        let errorFunc = (e) => {
            console.log(e)
        }

        post(url, postObj, succesFunc, errorFunc, false)
    }

    /*
    * Returns copied model 
    */
    getCopiedModelForNewColumn = () => {
        let copiedModel = deepCopy(this.state.model)

        copiedModel.tables = copiedModel?.tables?.filter(table => table.dataSourceKey + "_" + table.aliasName === this.props.newColumn.selectedTable)

        return copiedModel
    }

    render() {
        return (
            <Modal
                visible={this.state.visible}
                closable={false}
                maskClosable={true}
                onCancel={this.exitFormulaEditor}
                destroyOnClose={true}
                width="90%"
                centered={true}
                footer={false}
                className="formula-editor-modal"
                bodyStyle={{
                    height: "80vh",
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignContent: "start",
                    padding: "0",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        minHeight: "450px",
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyItems: "center"
                    }}
                >
                    <div
                        style={{
                            order: "1",
                            width: "20%",
                            height: "100%",
                            borderRight: "solid 1px #dadada"
                        }}
                    >
                        <div style={{ height: "100%", paddingBottom: "8px" }}>
                            <NewColumnDataTableList model={this.getCopiedModelForNewColumn()} comesFromNewColumn={true} />
                        </div>
                    </div>
                    <div
                        style={{
                            order: "2",
                            width: "60%",
                            height: "100%"
                        }}
                    >
                        <NewColumnCodeEditor
                            height="80%"
                            onCancel={this.exitFormulaEditor}
                            onApply={this.applyChanges}
                            onEdit={this.editNewColumn}
                            language={this.state.languageId}
                        />
                        <div
                            style={{
                                display: "flex",
                                overflow: "auto",
                                height: "20%",
                                borderTop: "solid 1px #dadada"
                            }}
                        >
                            <NewColumnFormulaDescription />
                        </div>
                    </div>
                    <div
                        style={{
                            order: "3",
                            width: "20%",
                            height: "100%",
                            borderLeft: "solid 1px #dadada"
                        }}
                    >
                        <div style={{ height: "100%", paddingBottom: "8px" }}>
                            <NewColumnFormulaList />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

/**
 * Set action method to props
 */
const mapDispatchToProps = {
    setNewColumnEditorContent,
};

/**
 * Set store objects to state
 * 
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
    return {
        newColumn: state.NewColumnEditorReducer.contentObject,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewColumnEditor);