export const configuration = {
    enhanceMode: false,
    columnWidth: 100,
    isRowSizeMustConstant: true,
    subTotal: false,
    defaultDrilldownColor: true,
    rowWidth: 100,
    rowHeight: 14,
    headerFont: "Verdana",
    headerFontSize: 12,
    colColor: "rgba(0,0,1,1)",
    totalFontSize: 12,
    totalFontColour: "rgba(0,0,1,1)",
    rowColor: "rgba(0,0,1,1)",
    valueFontSize: 11,
    valueFontFamily: "Verdana",
    totalFontFamily: "Verdana",
    valueColor: "rgba(0,0,1,1)",
    columnHeight: 14,
    autoSize: true,
    controls: true,
    valuesAsCols: true,
    showSum: true,
    wrapHeader: false,
    sumSelector: "ShowBoth",
    showHideButton: false,
    renderer: "Table",
    themeColour: "#67b7dc",
    pivotTableDescription: "",
    condFormat: true,
};

export const actions = [
    {
        trigger: "click",
        type: "click",
        output: ["columns", "rows"],
        name: "Click",
        description: "PivotTableClickDesc",
    },
];

export const reactions = [
    {
        id: "filter",
        name: "Filter",
        description: "desc87",
        type: "general",
    },
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["rows", "columns", "measures"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
};