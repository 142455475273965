export const configuration = {
    distanceWithGrid: 30,
    showCondForm: true,
    strokeWidth: 2,
    showValuesOnRadar: false,
    ranges: [],
    legendPosition: "right",
    rangeFontSize: 12,
    categoryFontSize: 11,
    size: 400,
    levels: 5,
    pointSize: 4,
    fillOpacity: 0.15,
    strokeOpacity: 1,
    ownScale: false,
    legend: true,
};

export const radarActions = [
    {
        trigger: "clickSlice",
        type: "click",
        output: ["category"],
        name: "PointClick",
        description: "clickSlice"
    },
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87", //'Accepts a column map and value and filters the report if the subject area matches.',
        type: "general"
    },
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["category", "measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
};