export const treeMapConfiguration = {
    legend: true,
    legendPos: "bottom",
    condFormat: true,
    roundCorners: true,
    showValuesOnTree: true,
    fontSize: 12,
};

export const actions = [
    {
        trigger: "tileClick",
        type: "click",
        name: "Click - Tile",
        output: ["group", "vary"],
        description: "TitleClickDesc"
    },
    {
        trigger: "tileHover",
        type: "hover",
        name: "Hover - Tile",
        output: ["group", "vary"],
        description: "TitleHoverDesc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["group", "measure", "vary"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
};