import React, { Component } from "react";
import { Row, Col } from "antd";
import Plugin from "../../Plugins/Plugin";
import Button from "../../GeneralComponents/Button/Button";
import { API_BASE, fullViewWidth } from "../../../config";
import "./grid.css";
import Responsive, { RGL, WidthProvider } from "react-grid-layout";
import _ from "lodash";
import $ from "jquery";
import { absorbEvent, deepCopy } from "../../../Utils/Global";
import i18n from "../../../Utils/i18next";
import InteractionsAndNavigationsList from "../../Plugins/InteractionsAndNavigations/InteractionsAndNavigationsList";
import EmptyDashboard from "../EmptyDashboard";
import {
  changeVisiblePopupContent
} from "../../Plugins/PluginTriggerAction";
import { store } from "../../../index";
import PluginContextMenu from "../../GeneralComponents/ContextMenu/ContextMenu";
import { addPluginFunction, rightClickCopiedPluginStatus } from "../../GeneralComponents/ContextMenu/ContextMenuAction";
import { calculateAndAppendPluginHeight, calculatePluginInlineHeight } from "../../DrillDown/PluginHeightWithDrilldown";
import { changeCustomDashboardStatus } from "../../GeneralComponents/CustomDashboard/CustomDashboardAction"
import { connect } from "react-redux";
import { isValidWriteRolesOrCustomDashboard } from "../../GeneralComponents/CustomDashboard/customDashboardControls";
import { getCopiedPluginObj } from "../../GeneralComponents/PluginCopyPaste/pluginCopyPaste";
import "../../Styles/theme.css"

const ResponsiveReactGridLayout = WidthProvider(Responsive);

//TODO: bu kısım pluginlere configuration ekleme işleminden sonra kaldırılacaktır
const columnMap = {
  measure: {
    name: "Ölçü Değeri",
    type: "dim",
    required: true,
    desc: "Performans döşeme ölçü değeri açıklaması",
    conditionalFormat: "icon",
    data: [
      {
        name: "deger",
        aliasName: "deger",
        displayName: "deger",
        nullable: true,
        isDoubleColumn: false,
        dataType: "double",
        aggregatable: true,
        aggrRule: "",
        hidden: false,
        isDuplicated: false,
        tableName: "ankara_mahalle_ilce_tümveri",
        uniqeColumnId: "dad4-e6a3-837-372-b788e05a57e"
      }
    ]
  },
  hidden: {
    name: "Saklı Alan",
    type: "hidden",
    desc: "Performans döşeme saklı alan açıklaması",
    multiple: true,
    data: [
      {
        name: "mahalle",
        aliasName: "mahalle",
        displayName: "mahalle",
        nullable: true,
        isDoubleColumn: false,
        dataType: "varchar",
        aggregatable: false,
        hidden: false,
        isDuplicated: false,
        tableName: "ankara_mahalle_ilce_tümveri",
        uniqeColumnId: "1bda24-fc4f-2bdc-20d-bc5644ea5120"
      },
      {
        name: "ilce_kodu",
        aliasName: "ilce_kodu",
        displayName: "ilce_kodu",
        nullable: true,
        isDoubleColumn: false,
        dataType: "double",
        aggregatable: false,
        hidden: false,
        isDuplicated: false,
        tableName: "ankara_mahalle_ilce_tümveri",
        uniqeColumnId: "687718-00a-e53a-8d70-f26cc16c714f"
      },
      {
        name: "mahalle_kodu",
        aliasName: "mahalle_kodu",
        displayName: "mahalle_kodu",
        nullable: true,
        isDoubleColumn: false,
        dataType: "double",
        aggregatable: false,
        hidden: false,
        isDuplicated: false,
        tableName: "ankara_mahalle_ilce_tümveri",
        uniqeColumnId: "f0cd801-5a3a-4221-a4e7-bc3b20ffb23"
      },
      {
        name: "ilce",
        aliasName: "ilce",
        displayName: "ilce",
        nullable: true,
        isDoubleColumn: false,
        dataType: "varchar",
        aggregatable: false,
        hidden: false,
        isDuplicated: false,
        tableName: "ankara_mahalle_ilce_tümveri",
        uniqeColumnId: "a4b464-88d-0111-e26d-5533124fa2"
      }
    ]
  }
};

/**
 * Plugins can be placed, dragged and dropped, organized in the grid area
 * This is one of the main part making e report process
 */
// TODO by Tayfun : This components responsibility and structure will be evaluated later because of interaction.
class GridSystem extends Component {
  static defaultProps = {
    className: "layout",
    margin: [1, 1],
    onLayoutChange: function () { }
  };

  constructor(props) {
    super(props);

    this.state = {
      guideLines: [],
      initialeRowCount: 0,
      shouldPluginReRender: false,
      processingPluginId: null,
      activeInteractionPluginObject: {},
      interactionsHashMapForSource: new Map(),
      pluginZIndexForPopup: "",
      refreshedPluginId: "",
      lastRefreshedPlugin: "",
      mobileSize: false,
      isListVisibleActive: false,
      commonTitleConfig: null, // holds the plugin which its title features shared
      defaultPlugins: new Map(), // (plugin id, plugin config)
      beforeApplyPlugins: new Map(), // (plugin id, plugin config)
      currentAppliedConfig: {},
      appliedConfigStatus: false,
      beforeImmobileDashboardStatus: false,
      theme: this.props.theme
    };

    this.multiTriggerPopupRef = React.createRef();
  }

  /*
  * Sets default to plugin
  */
  setDefaultPlugins = (mapObj) => {
    this.setState({
      defaultPlugins: mapObj
    })
  }

  /*
  * Sets current applied config to head of plugins
  */
  setCurrentAppliedConfig = (newState, pluginId, returnToDefaultStatus = false) => {
    let configs = {}
    let config = newState.commonTitleConfig !== null && newState.commonTitleConfig !== undefined
      ? newState.commonTitleConfig.config
      : Object.keys(newState).length > 0
        ? newState
        : null

    if (config !== "none" && config !== undefined && config !== null && returnToDefaultStatus === false) {
      configs["title"] = config.title
      configs["titleAlign"] = config.titleAlign
      configs["titleColour"] = config.titleColour
      configs["titleColourChange"] = config.titleColourChange
      configs["titleFont"] = config.titleFont
      configs["titleFontSize"] = config.titleFontSize
      configs["titleFontStyle"] = config.titleFontStyle
      configs["titleFontWeight"] = config.titleFontWeight
      configs["titleTextDecor"] = config.titleTextDecor
      configs["currentlyAppliedConfigsPluginId"] = pluginId

      newState.appliedConfigStatus = true

      newState["currentAppliedConfig"] = configs
    } else if (pluginId === this.state.currentlyAppliedConfigsPluginId) {
      newState["currentAppliedConfig"] = {}
    }

    this.setState(newState, () => this.setState({
      ...this.state,
      appliedConfigStatus: false
    }))
  }

  /*
  * Changes current applied config
  */
  changeCurrentAppliedConfigParameters = () => {
    let configs = {}

    this.setState({
      ...this.state,
      currentAppliedConfig: configs
    })
  }

  /*
  * Changes interaction and navigation list condition to open or close condition for control
  */
  setListVisible = (status) => {
    this.setState({
      isListVisibleActive: status
    })
  }

  /** If clicked outside close interaction and navigation list */
  handleClickOutside = (event) => {
    // let isFormulaEditorVisible = false;
    // let reduxState = store.getState();

    // if (
    //   reduxState.FormulaEditorReducer &&
    //   reduxState.FormulaEditorReducer.contentObject
    // ) {
    //   isFormulaEditorVisible = reduxState.FormulaEditorReducer.contentObject.visible;
    // }

    // if (
    //   this.multiTriggerPopupRef &&
    //   this.multiTriggerPopupRef.current &&
    //   isFormulaEditorVisible === false
    // ) {
    //   let isMultiTriggerPopupRefAvailable = this.multiTriggerPopupRef ? true : false
    //   let isMultiTriggerPopupContainsEventTarget = !this.multiTriggerPopupRef.current.contains(event.target) ? true : false
    //   let isListVisibleActive = this.state.isListVisibleActive === true ? true : false
    //   let mustListClose = isMultiTriggerPopupRefAvailable && isMultiTriggerPopupContainsEventTarget && isListVisibleActive

    //   if (mustListClose) {
    //     store.dispatch(changeVisiblePopupContent(false));
    //     this.setListVisible(false)
    //   } else {
    //     this.setListVisible(true)
    //   }
    // }
  };

  /**
   *
   * @param {*} pluginId
   * @param {*} columnMap
   * @param {*} container
   * @param {*} event
   * @param {*} datum
   * Trigger that will occur due to interaction as a result of clicking on the plugins
   */
  createTrigger = function (pluginId, columnMap, container, event, datum) {
    if (this.state.interactionsHashMapForSource.has(pluginId)) {
      let interactionIndex = this.state.interactionsHashMapForSource.get(
        pluginId
      );
      let interaction = this.props.interactions[
        interactionIndex.indexInInteractionList
      ];
    }
  };

  /**
   *
   * @param {*} interactionPlugin Plugin information selected as source for ineraction
   * @param {*} interactions ineractions array with ineraction objects
   * This method creates a hash map with the contents of the ineraction array
   * In the Key field, the plugin selected as source and the ids of the plugins with ineraction are written.
   * Inerations of the selected source; The plugin id is set to the key part and the index in the interaction array is set to the value part.
   */
  createInteractionHashMapForSource = (interactionPlugin, interactions) => {
    let interactionHashMap = new Map();
    interactions &&
      interactions.map((interaction, index) => {
        if (interactionPlugin && interaction.sourceId == interactionPlugin.id) {
          let indexInInteractionList = {};
          let result = interactionHashMap.get(interaction.targetId);
          if (result) {
            indexInInteractionList = result;
          }
          indexInInteractionList = index;
          interactionHashMap.set(interaction.targetId, {
            indexInInteractionList
          });
        }
      });
    return interactionHashMap;
  };

  /**
   * Disables ineraction mode
   */
  closeActiveInteractionPlugin = () => {
    this.setState({
      ...this.state,
      activeInteractionPluginObject: {}
    });
  };

  /**
   *
   * @param {*} target = information of the plugin selected as source
   * Checks whether the plugin that comes as a parameter in the hashmap has any interaction and returns true or false
   */
  interactionHasCheck = target => {
    return this.state.interactionsHashMapForSource.has(target.id);
  };

  /**
   *
   * @param {*} interactionPlugin
   * It sets the plugini state sets selected to create interaction and state sets the interactions of the plugine by creating a hash map
   */
  setActiveInteractionPlugin = interactionPlugin => {
    let reduxState = store.getState();
    let updatedPlugins = reduxState.PluginTriggerReducer.plugins;

    interactionPlugin.plugin = deepCopy(updatedPlugins.get(interactionPlugin.plugin.id))

    let interactionsHashMapForSource = this.createInteractionHashMapForSource(
      interactionPlugin.plugin,
      this.props.interactions
    );

    this.setState({
      ...this.state,
      activeInteractionPluginObject: interactionPlugin,
      interactionsHashMapForSource: interactionsHashMapForSource
    });
  };

  /**
   *
   * @param {*} interactions
   * Checks if there are interactions and if a different interaction is added
   */
  hasInteractions = interactions => {
    return (
      this.props.interactions !== interactions &&
      interactions !== undefined &&
      interactions !== null
    );
  };

  isPluginListSizeChanged = (nextProps, props) => {
    return (
      props.plugins &&
      nextProps.plugins &&
      props.plugins.length != nextProps.plugins.length
    );
  };

  changeRefreshedPluginId = value => {
    if (value !== this.state.refreshedPluginId) {
      this.setState({
        refreshedPluginId: value,
        lastRefreshedPlugin: value
      }, () => {
        this.setState({
          ...this.state,
          lastRefreshedPlugin: ""
        })
      });
    } else {
      this.setState({
        ...this.state,
        lastRefreshedPlugin: value
      }, () => {
        this.setState({
          ...this.state,
          lastRefreshedPlugin: ""
        })
      });
    }

  };

  /**
   *
   * @param {*} nextProps
   * Updates the interactionHashMapForSource based on adding or removing interaction
   */
  componentWillReceiveProps = nextProps => {
    let flag = false;
    let newState = { ...this.state };

    if (
      this.props.selectedPlugin != undefined &&
      nextProps.selectedPlugin.id !== this.props.selectedPlugin.id
    ) {
      this.onAddGridContainer(nextProps.selectedPlugin);
    } else if (this.isPluginListSizeChanged(nextProps, this.props)) {
      /** Fill matrix after plugins load. */
      let rowCount =
        this.generateGuideLines(1).length /
        nextProps.settings.grid.numberOfColumns;

      nextProps.plugins.map(plugin => {
        let pluginBottomLocation = plugin.y + plugin.h;

        if (rowCount < pluginBottomLocation) {
          rowCount = pluginBottomLocation;
        }
      });

      nextProps.gridCalculator.resizeGrid(
        nextProps.plugins,
        rowCount,
        nextProps.settings.grid.numberOfColumns
      );
    }

    if (this.hasInteractions(nextProps.interactions)) {
      let interactionsHashMapForSource = this.createInteractionHashMapForSource(
        this.state.activeInteractionPluginObject.plugin,
        nextProps.interactions
      );

      newState.interactionsHashMapForSource = interactionsHashMapForSource;
      flag = true;
    }

    /**
     * if plugin remove when data or config popup is open, empty relative fields and enable drag drop again.
     */
    if (this.isDraggingAndResizingReactivation(nextProps, this.state)) {
      newState = this.setZIndexForPopup(false, "", false, { ...newState });
      flag = true;
    }

    if (nextProps.dashboardInformation.id !== this.props.dashboardInformation.id) {
      newState.mobileSize = false;
      newState.activeInteractionPluginObject = {};
      newState.interactionsHashMapForSource = new Map();
      newState.commonTitleConfig = null;
      newState.currentAppliedConfig = {}

      flag = true;
    }

    if (this.props.state.CustomDashboardReducer.isCustomDashboard !== nextProps.state.CustomDashboardReducer.isCustomDashboard
      && nextProps.state.CustomDashboardReducer.isCustomDashboard === true) {
      this.props.gridSystemDragDropChangeStatus(false, true, true);

      flag = true
    } else if (this.props.state.CustomDashboardReducer.isCustomDashboard !== nextProps.state.CustomDashboardReducer.isCustomDashboard
      && nextProps.state.CustomDashboardReducer.isCustomDashboard === false) {
      this.props.gridSystemDragDropChangeStatus(false, true)
    }

    if (this.props.forceCloseInteraction !== nextProps.forceCloseInteraction && nextProps.forceCloseInteraction) {
      flag = true

      newState.activeInteractionPluginObject = {}
    }

    if (this.state.theme !== nextProps.theme) {
      flag = true;

      newState.theme = nextProps.theme;
    }

    if (flag === true) {
      nextProps.plugins.forEach(plugin => {
        newState.defaultPlugins.set(plugin.id, plugin.config);
        newState.beforeApplyPlugins.set(plugin.id, plugin.config);
      })

      this.setState(newState, () => {
        if (nextProps.forceCloseInteraction) {
          this.props.setStatusOfForceCloseInteraction(false)
        }
      });
    } else {
      if (nextProps.forceCloseInteraction) {
        this.props.setStatusOfForceCloseInteraction(false)
      }
    }
  };

  isDraggingAndResizingReactivation = (nextProps, state) => {
    return this.pluginZIndexForPopupIsNotEmpty() &&
      nextProps.plugins.filter(p => p.id === state.pluginZIndexForPopup)
        .length === 0
      ? true
      : false;
  };

  pluginZIndexForPopupIsNotEmpty = () => {
    return this.state.pluginZIndexForPopup &&
      this.state.pluginZIndexForPopup !== ""
      ? true
      : false;
  };

  /*
  * Opens copy-paste context menu.
  */
  contextMenu = event => {
    const contextMenu = _.throttle(() => {
      let mainDiv = document.getElementById("mainDiv");
      let target = event.target

      let isTargetOnMainDiv = $(mainDiv).has(target).length > 0;
      let isTargetOutOfPlugin = $(target).closest(".react-grid-item").length === 0;
      let isTargetOutOfContextMenu = $(target).closest(".context-menu").length === 0;
      let isTargetValid = isTargetOnMainDiv && isTargetOutOfPlugin && isTargetOutOfContextMenu;

      if (isTargetValid) {
        let xPosition = event.pageX;
        let yPosition = event.pageY;
        let status = true
        let contextMenuType = "paste"

        let copiedPluginObj = {
          xPosition,
          yPosition,
          status,
          contextMenuType
        };

        store.dispatch(rightClickCopiedPluginStatus(copiedPluginObj))
      }
    }, 500);

    absorbEvent(event);
    contextMenu();
  }

  componentDidMount() {
    let guideLines = this.generateGuideLines(1);

    let initialeRowCount =
      guideLines.length / this.props.settings.grid.numberOfColumns;

    /** Fill matrix after render */
    this.props.gridCalculator.resizeGrid(
      this.props.plugins,
      initialeRowCount,
      this.props.settings.grid.numberOfColumns
    );

    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("contextmenu", this.contextMenu);

    window.addEventListener("resize", this.windowResize);

    store.dispatch(addPluginFunction(this.onAddGridContainer));

    $(document).keydown(async event => {
      let reduxState = store.getState()
      let isValidWriteRolesOrCustomDashboardMode = reduxState.CustomDashboardReducer.isCustomDashboard === false ? true : false
      let isCtrlVOrCmdVPressed = (event.ctrlKey || event.metaKey) && event.keyCode == 86 ? true : false

      // Ctrl+V or Cmd+V pressed?
      if (isCtrlVOrCmdVPressed && isValidWriteRolesOrCustomDashboardMode) {
        let isInputConditional = event.target.className === "rule-input-box"
        let isTargetInput = event.target.nodeName === "INPUT" || event.target.nodeName === "TEXTAREA" || isInputConditional ? true : false
        let copiedPluginObj = getCopiedPluginObj();

        let isPasteProcessesMustStart = (
          copiedPluginObj?.plugin?.id ||
          reduxState.ContextMenuReducer.copiedPlugin?.id
        ) && !isTargetInput ? true : false

        let copiedPlugin = copiedPluginObj?.plugin;
        let interactions = this.props.interactions?.filter(interaction => interaction.sourceId === copiedPlugin?.id) || []

        if (isPasteProcessesMustStart) {
          reduxState.ContextMenuReducer.pastePluginFunction(interactions)
        }
      }
    });

    this.setState({ guideLines, initialeRowCount });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.windowResize);
  }

  // Calculating the heights of plugins in the horizontal part of the mobile 
  mobilHeight = () => {
    if (document.body.clientWidth >= fullViewWidth) {
      if (this.state.mobileSize === false) {
        this.props.plugins.map(function (plugin) {
          calculateAndAppendPluginHeight(plugin.id);
        });
      }
    }
  }

  previousGridSize = undefined

  /** If window size change, check client width and decides to mobile view. */
  windowResize = () => {
    let currentMobileSize = this.state.mobileSize;
    let mobileSize;

    if (document.body.clientWidth < fullViewWidth) {
      this.previousGridSize = undefined;

      let currentDragStatus =
        this.props.settings && this.props.settings.grid
          ? this.props.settings.grid.isDraggableAndResizable
          : true;

      if (currentMobileSize !== true) {
        this.props.gridSystemDragDropChangeStatus(false);
        mobileSize = true;

        this.setState({
          ...this.state,
          mobileSize: mobileSize,
          originalDragStatus: currentDragStatus
        });

        this.renderTypeSelector(currentMobileSize, true, this);
      }
    } else {
      let checksGridSizeDifference = this.previousGridSize != undefined && this.previousGridSize !== $($(".grid-items")[0]).width()

      if (currentMobileSize !== false || checksGridSizeDifference) {
        this.previousGridSize = $($(".grid-items")[0]).width()
        mobileSize = false;

        this.props.gridSystemDragDropChangeStatus(
          this.state.originalDragStatus
        );

        this.setState({
          ...this.state,
          mobileSize: mobileSize
        });

        this.renderTypeSelector(currentMobileSize, false, this);
      }
    }

    return mobileSize;
  };

  /**
   * When a new plugin is added to grid system this method must be called
   * @param {Plugin object that is visualed on grid system} plugin
   */
  onAddGridContainer = (plugin, interactions = undefined) => {
    let THIS = this
    // If plugin does not own X property than it s added from plugin list
    if (plugin.hasOwnProperty("x") === false) {
      plugin["containerHeight"] =
        plugin.h * this.props.settings.grid.rowHeight - (plugin.h - 1) * 2;

      this.props.gridCalculator.resizeGrid(
        this.props.plugins,
        $("#mainDiv").height() / this.props.settings.grid.rowHeight,
        this.props.settings.grid.numberOfColumns
      );

      let position = this.props.gridCalculator.getPosition(plugin);

      plugin["x"] = position.x;
      plugin["y"] = position.y;

      if (position.status === false) {
        plugin.x = position.x;
        plugin.y = position.y;

        $("#mainDiv").css(
          "height",
          (plugin.h + position.y) * this.props.settings.grid.rowHeight + "px"
        );

        this.addMoreSpace();

        this.props.gridCalculator.resizeGrid(
          this.props.plugins,
          plugin.h + position.y,
          this.props.settings.grid.numberOfColumns
        );
      }

      this.props.addPlugin(plugin, interactions);
      this.props.gridCalculator.markNecessaryCells(
        plugin.y,
        plugin.x,
        plugin,
        true
      );
    }
  }

  setZIndexForPopup = (
    status,
    pluginId = "",
    isSetState = true,
    newState = { ...this.state },
    beforeImmobileDashboardStatus
  ) => {
    let isDraggableAndResizable = undefined;
    let state = newState ? newState : { ...this.state }

    if (status === true) {
      state.pluginZIndexForPopup = pluginId;
      isDraggableAndResizable = false;
    } else {
      state.pluginZIndexForPopup = "";
      isDraggableAndResizable = true;
    }

    var THIS = this

    let isDraggableAndResizableUndefined = isDraggableAndResizable !== undefined
    let isDraggableAndResizableStatusChanged = THIS.props.settings.grid.isDraggableAndResizable !== isDraggableAndResizable
    let mustDraggableChange = isDraggableAndResizableUndefined && isDraggableAndResizableStatusChanged ? true : false

    if (mustDraggableChange) {
      if (status) {
        this.props.gridSystemDragDropChangeStatus(isDraggableAndResizable, true);
      } else {
        if (this.state.beforeImmobileDashboardStatus) {
          this.props.gridSystemDragDropChangeStatus(false, true);
        } else {
          this.props.gridSystemDragDropChangeStatus(true, true);
        }
      }
    }

    if (isSetState && status !== false) {
      this.setState({
        ...state,
        beforeImmobileDashboardStatus: beforeImmobileDashboardStatus
      });
    } else if (isSetState) {
      this.setState({
        ...state
      });
    } else {
      return state;
    }
  };

  //sets plugins configs to beforeApplyPlugins map right before features applied
  //updates defaultPlugins if new plugin added
  updateBeforeApplyOrDefaultPlugins = (plugin, beforeApplyOrDefault) => {
    let newState = { ...this.state };

    if (beforeApplyOrDefault === "beforeApply") {
      newState.beforeApplyPlugins.set(plugin.id, plugin.config);
    }
    else if (beforeApplyOrDefault === "default") {
      newState.defaultPlugins.set(plugin.id, plugin.config);
    }
    else if (beforeApplyOrDefault === "both") {
      newState.beforeApplyPlugins.set(plugin.id, plugin.config);
      newState.defaultPlugins.set(plugin.id, plugin.config);
    }

    this.setState(newState);
  }

  //updates common title config plugin
  updateCommonTitleConfig = (newPlugin, pluginId) => {
    let newState = { ...this.state };

    newState.commonTitleConfig = newPlugin;

    this.setCurrentAppliedConfig(newState, pluginId)
  }

  /**
   * Creates plugin object base upon grid system
   * @param {Plugin} plugin
   */
  createElement(plugin) {
    return (
      <div
        key={plugin.id}
        data-grid={plugin}
        className={"grid-items"}
        id={"grid-" + plugin.id}
        style={{
          zIndex: this.state.pluginZIndexForPopup === plugin.id ? "1" : "0"
        }}
      >
        <Plugin
          commonTitleConfig={this.state.commonTitleConfig}
          changeCurrentAppliedConfigParameters={this.changeCurrentAppliedConfigParameters}
          updateCommonTitleConfig={this.updateCommonTitleConfig}
          beforeApplyPlugins={this.state.beforeApplyPlugins}
          defaultPlugins={this.state.defaultPlugins}
          updateBeforeApplyOrDefaultPlugins={this.updateBeforeApplyOrDefaultPlugins}
          join={this.props.join}
          addJoinToJoinList={this.props.addJoinToJoinList}
          readOnly={this.props.readOnly == "WRITE" ? true : false}
          index={plugin.id}
          removePlugin={this.props.removePlugin}
          updatePlugin={this.props.updatePlugin}
          editting={true}
          plugin={plugin}
          title={plugin.config ? plugin.config.title : i18n.t("TitleNotSet")}
          model={this.props.model}
          interactions={this.props.interactions}
          shouldPluginReRender={this.state.shouldPluginReRender}
          processingPluginId={this.state.processingPluginId}
          triggeringPluginInformation={this.props.triggeringPluginInformation}
          drillDownTriggerInformation={this.props.drillDownTriggerInformation}
          interactionsHashMapForSource={this.state.interactionsHashMapForSource}
          activeInteractionPluginObject={
            this.state.activeInteractionPluginObject
          }
          setInteractions={this.props.setInteractions}
          setActiveInteractionPlugin={this.setActiveInteractionPlugin}
          closeActiveInteractionPlugin={this.closeActiveInteractionPlugin}
          createTrigger={this.createTrigger}
          settings={this.props.settings}
          dashboardInformation={this.props.dashboardInformation}
          updateDefaultFilterForPlugin={this.props.updateDefaultFilterForPlugin}
          updateConfig={this.props.updateConfig}
          closeOtherPopup={this.state.pluginZIndexForPopup}
          setZIndexForPopup={this.setZIndexForPopup}
          refreshedPluginId={this.state.refreshedPluginId}
          changeRefreshedPluginId={this.changeRefreshedPluginId}
          updateModelTablesForJoin={this.props.updateModelTablesForJoin}
          getModelTables={this.props.getModelTables}
          pluginAllRender={this.props.pluginAllRender}
          autoRefreshStatus={this.props.autoRefreshStatus}
          autoRefresh={this.props.autoRefresh}
          excelExportClicked={this.props.excelExportClicked}
          excelExporting={this.props.excelExporting}
          setPluginsWithData={this.props.setPluginsWithData}
          currentModelWithTable={this.props.currentModelWithTable}
          cancelRequests={this.props.cancelRequests}
          mobileSize={this.state.mobileSize}
          isDashboardSave={this.props.isDashboardSave}
          setCurrentAppliedConfig={this.setCurrentAppliedConfig}
          currentAppliedConfig={this.state.currentAppliedConfig}
          plugins={this.props.plugins}
          setDefaultPlugins={this.setDefaultPlugins}
          appliedConfigStatus={this.state.appliedConfigStatus}
          draggableResizableStatus={this.props.draggableResizableStatus}
          changeStatusOfHoveredPluginTools={this.props.changeStatusOfHoveredPluginTools}
          theme={this.state.theme}
          applyThemeToPlugin={this.props.applyThemeToPlugin}
          lastRefreshedPlugin={this.state.lastRefreshedPlugin}
          isExcelExportStyled={this.props.isExcelExportStyled}
          isExcelExportBigSized={this.props.isExcelExportBigSized}
          excelExportProgress={this.props.excelExportProgress}
        />
      </div>
    );
  }

  /**
   * When a plugin is resize this method is called
   * @param {layout(plugin) object that is used to generate grid system plugins} lay
   * @param {old layout object} oldi
   * @param {new layour object} newi
   */
  resizeOrDropCompleted = (lay, oldi, newi) => {
    let plugins = [...this.props.plugins];
    let THIS = this;

    let layoutMap = new Map();
    lay.map(layout => {
      layoutMap.set(layout.i, layout);
    });

    plugins.map((plugin, i) => {
      let pluginPositions = layoutMap.get(plugin.id);
      let anyPositionChanged =
        pluginPositions &&
        (plugin.h !== pluginPositions.h ||
          plugin.w !== pluginPositions.w ||
          plugin.x !== pluginPositions.x ||
          plugin.y !== pluginPositions.y);

      if (anyPositionChanged) {
        plugin.h = pluginPositions.h;
        plugin.w = pluginPositions.w;
        plugin.x = pluginPositions.x;
        plugin.y = pluginPositions.y;
        plugin.rerender = true;
        plugin.containerHeight =
          pluginPositions.h * THIS.props.settings.grid.rowHeight -
          (pluginPositions.h - 1) * 2;
        plugins[i] = { ...plugin };
      }
    });

    this.props.updateMultiPluginIfNecessary(plugins);

    let rowCount =
      this.addMoreSpace().length / this.props.settings.grid.numberOfColumns;

    this.props.gridCalculator.resizeGrid(
      plugins,
      rowCount,
      this.props.settings.grid.numberOfColumns
    );
  };

  pushColumnsForGuideLines = guideLineBaseCounter => {
    let guideLines = [];
    if (guideLineBaseCounter) {
      for (
        let i = 0;
        i < this.props.settings.grid.numberOfColumns * guideLineBaseCounter;
        i++
      ) {
        guideLines.push(
          <Col
            className="guidelines-col"
            style={{ height: this.props.settings.grid.rowHeight }}
            span={1}
          ></Col>
        );
      }
    }

    return guideLines;
  };

  /**
   * Guidlines shown on grid system while producing reports
   * @param {Think about this parameter because it looks meaningless} minusOne
   */
  generateGuideLines = minusOne => {
    let newGuideLinesGrid = [];
    let newGuideLinesPage = [];
    let pageHeight = $("#mainDiv").height();
    let gridHeight = $(".react-grid-layout").height();

    newGuideLinesGrid = deepCopy(
      this.pushColumnsForGuideLines(
        parseInt(gridHeight / this.props.settings.grid.rowHeight)
      )
    );

    newGuideLinesPage = deepCopy(
      this.pushColumnsForGuideLines(
        parseInt(pageHeight / this.props.settings.grid.rowHeight) - minusOne
      )
    );

    $("#mainDiv").css("height", this.props.containerHeight + "px");

    return newGuideLinesGrid.length > newGuideLinesPage.length
      ? newGuideLinesGrid
      : newGuideLinesPage;
  };

  /**
   * Adds more guidlines to grid system
   */
  addMoreSpace = () => {
    let guideLines = this.generateGuideLines(0);

    this.setState({
      guideLines: guideLines
    });

    return guideLines;
  };

  pluginAttributes = [];

  /** Change element classes for mobile size or normal size */
  renderTypeSelector = (
    currentStatusForMobileSize,
    newStatusForMobileSize,
    THIS
  ) => {
    let layout = $(".react-grid-layout");
    let plugins = $(".grid-items");
    let responsiveClasses =
      "grid-items mobile-plugin-grid col-lg-12 col-sm-12 col-xs-12";

    if (
      newStatusForMobileSize === true &&
      currentStatusForMobileSize !== newStatusForMobileSize
    ) {
      THIS.pluginAttributes = [];

      plugins.each(function () {
        let pluginClass = $(this).attr("class");
        let pluginStyle = $(this).attr("style");
        let pluginStyleHeight = "height: " + $(this).height() + "px";

        THIS.pluginAttributes.push({ class: pluginClass, style: pluginStyle });
        $(this).attr("class", responsiveClasses);
        $(this).removeAttr("style");
        $(this).attr("style", pluginStyleHeight);
      });
    } else if (
      newStatusForMobileSize === false &&
      currentStatusForMobileSize !== newStatusForMobileSize
    ) {
      if (THIS.pluginAttributes.length > 0) {
        plugins.each(function (index) {
          let attribute = THIS.pluginAttributes[index];
          $(this).attr("class", attribute.class);
          $(this).attr("style", attribute.style);
        });
      }
    }

    THIS.props.plugins.map(function (plugin, index) {
      if (plugin.key === "table") {
        plugin.columnMap.columns.autoSize = true;
        plugin.columnMap.columns.sizeToFit = false;
      }
    })
  };

  render() {
    const THIS = this;
    const { settings = {} } = THIS.props;
    const resourceURL = `resource${API_BASE}/resource/`;

    let isCustomDashboardModeActive = this.props.state.CustomDashboardReducer.isCustomDashboard ? true : false

    let background = "";
    /**
     * set background image or color by data
     */
    if (settings.backgroundColor != "") {
      background = settings.backgroundColor;
    } else if (settings.backgroundImage != "") {
      background = "url(" + resourceURL + settings["backgroundImage"] + ")";
    }

    if (settings.backgroundImageTemp != "") {
      background =
        "url(" +
        settings["backgroundImageTemp"] +
        "," +
        settings["backgroundImage"] +
        ")";
    }

    $("#mainDiv")
      .find(".react-grid-layout")
      .css("background", background);

    let content = (
      <>
        <EmptyDashboard canPluginExportAreaMustShow={this.props.canPluginExportAreaMustShow} />

        <PluginContextMenu
          interactions={this.props.interactions}
          setInteractions={this.props.setInteractions}
          removePlugin={this.props.removePlugin}
          addPlugin={this.props.addPlugin}
          updatePlugin={this.props.updatePlugin}
        />
      </>
    );

    /** When grid items (plugins) ready, run window resize. */
    $(".grid-items").ready(function () {
      if ($(".grid-items").length > 0) {
        THIS.windowResize()
        THIS.mobilHeight()
      }
    });

    if (this.props.plugins && this.props.plugins.length > 0) {
      content = (
        <div id="gridLayout">
          <Row>
            <ResponsiveReactGridLayout
              layout={this.state.layout}
              {...this.props}
              cols={this.props.settings.grid.numberOfColumns}
              rowHeight={this.props.settings.grid.rowHeight}
              preventCollision={this.props.settings.grid.preventCollision}
              compactType={!this.props.settings.grid.preventCollision ? "vertical" : null}
              style={{ zIndex: "1" }}
              onResizeStop={this.resizeOrDropCompleted}
              onDragStop={this.resizeOrDropCompleted}
              isDraggable={this.props.settings.grid.isDraggableAndResizable && !this.props.state.CacheModeReducer.isCacheModeActive && !this.props.hoveredPluginTools}
              isResizable={this.props.settings.grid.isDraggableAndResizable && !this.props.state.CacheModeReducer.isCacheModeActive && !this.props.hoveredPluginTools}
            >
              {_.map(this.props.plugins, el => this.createElement(el))}
            </ResponsiveReactGridLayout>
            <Row gutter="0" className="guidelines-row">
              {this.state.guideLines}
            </Row>
          </Row>

          <div ref={this.multiTriggerPopupRef} id="interactionAndNavigationList">
            <InteractionsAndNavigationsList
              model={this.props.model}
              setNavigations={this.props.setNavigations}
              interactionTrigger={this.props.interactionTrigger}
              drillDownTrigger={this.props.drillDownTrigger}
              dashboardInformation={this.props.dashboardInformation}
              setListVisible={this.setListVisible}
              isListVisibleActive={this.state.isListVisibleActive}
            ></InteractionsAndNavigationsList>
          </div>

          <PluginContextMenu
            interactions={this.props.interactions}
            setInteractions={this.props.setInteractions}
            removePlugin={this.props.removePlugin}
            addPlugin={this.props.addPlugin}
            updatePlugin={this.props.updatePlugin}
          />
        </div>
      );
    }

    return (
      <div
        id="mainDiv"
        className="grid-system"
        ref={this.myRef}
        style={{ width: "100%", minHeight: "100dvh", background: background }}
      >
        {content}

        <style id="pluginCSS" theme={this.props.themeObj?.name}>
          {this.props.themeObj?.plugin?.css}
        </style>
      </div>
    );
  }
}

const mapDispatchToProps = {
  changeCustomDashboardStatus,
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridSystem);