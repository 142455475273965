import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import $ from "jquery";
import React, { Component } from "react";
import { store } from "../../../..";
import { calculatePluginInlineHeight } from "../../../../../src/ui/DrillDown/PluginHeightWithDrilldown";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import i18n from "../../../../Utils/i18next";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { compare, convertHTMLRuletoRule } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join";
import NavigationContent from "../../../Navigation/NavigationContent";
import { renderConditionalFormatting, renderConfig, renderData, renderNavigation } from "../PluginsCommonComponents";
import RadarData from "./RadarData"
import RadarConfiguration from "./RadarConfiguration"
import {
  getColumnMapping,
  getCurrentDateTime,
  onComponentWillMount,
  onComponentWillReceiveProps,
} from "../common";
import { renderContent } from "../renderContent";
import { deepCopy, loadingScreen } from "../../../../Utils/Global";
import { InsightsConfig } from "../../RenderJs/config";
import { getFormattedValue } from "../format";
import { aggregatableDataTypes, dateDataTypes } from "../../DataComponents/DataConfigure";
import { bigNumberPrefixes, decimalSeperators, longMonths, shortMonths, thousandSeperators } from "../../../GeneralComponents/PublicSortItems";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { rmvpp } from "../../RenderJs/rmvpp";
import { vispeahenLogo } from "../Table/TablePdfContent";
import { changePluginLoaderVisibility } from "../../../GeneralComponents/PluginLoader/PluginLoaderAction";

const condFormats = [];
const filters = [];
const pluginName = "radar";
const data = JSON.parse(
  '[{"category":2014,"measure":4045081712},{"category":2015,"measure":4296005250},{"category":2016,"measure":4037735418}]'
);
const config = JSON.parse(
  '{"innerRadius":0,"legend":true,"showHideButton":false,"colours":"Flat-UI","toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","refresh":0}'
);
const columnMap = JSON.parse(
  `{"category":{"Code":"\'ucus_PBWWSJXEAR\'.\'yil\'","Name":"yil","DataType":"double","Table":"ucus_PBWWSJXEAR","Measure":"none","ID":"ucus_PBWWSJXEAR.yil","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""},"measure":{"Code":"\'ucus_PBWWSJXEAR\'.\'bagajtoplam\'","Name":"bagajtoplam","DataType":"double","Table":"ucus_PBWWSJXEAR","Measure":"sum('ucus'.'bagajtoplam')","ID":"ucus_PBWWSJXEAR.bagajtoplam","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""},"hidden":[]}`
);

const configurationParameters = [
  {
    targetProperty: "distanceWithGrid",
    label: "distanceWithGrid",
    inputType: "textbox",
    inputOptions: {
      defaultValue: 30
    },
    desc: "distanceWithGrid"
  },
  {
    targetProperty: "condFormat",
    label: "condFormat",
    inputType: "textbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "condFormat"
  },
  {
    targetProperty: "strokeWidth",
    label: "strokeWidth",
    inputType: "textbox",
    inputOptions: {
      defaultValue: 2
    },
    desc: "strokeWidth"
  },
  {
    targetProperty: "showValuesOnRadar",
    label: "showValuesOnRadar",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "showValuesOnRadar"
  },
  {
    targetProperty: "ranges",
    label: "ranges",
    inputType: "textbox",
    inputOptions: {
      defaultValue: []
    },
    desc: "ranges"
  },
  {
    targetProperty: "legendPosition",
    label: "legendPosition",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "right"
    },
    desc: "legendPosition"
  },
  {
    targetProperty: "rangeFontSize",
    label: "rangeFontSize",
    inputType: "textbox",
    inputOptions: {
      defaultValue: 12
    },
    desc: "rangeFontSize"
  },
  {
    targetProperty: "categoryFontSize",
    label: "categoryFontSize",
    inputType: "textbox",
    inputOptions: {
      defaultValue: 11
    },
    desc: "categoryFontSize"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "size",
    label: "Size",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc92"
  },
  {
    targetProperty: "levels",
    label: "Levels",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 5
    },
    desc: "desc185"
  },
  {
    targetProperty: "pointSize",
    label: "PointSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 4
    },
    desc: "desc186"
  },
  {
    targetProperty: "fillOpacity",
    label: "FillOpacity",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 0.15
    },
    desc: "desc187"
  },
  {
    targetProperty: "strokeOpacity",
    label: "OutlineOpacity",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 1
    },
    desc: "desc188"
  },
  {
    targetProperty: "ownScale",
    label: "OwnScale",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc189"
  },
  {
    targetProperty: "legend",
    label: "Legend",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc190"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: { defaultValue: "Flat-UI" },
    desc: "desc93"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
];

const actions = [
  {
    trigger: "clickSlice",
    type: "click",
    output: ["category"],
    name: "PointClick",
    description: "clickSlice"
  },
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87", //'Accepts a column map and value and filters the report if the subject area matches.',
    type: "general"
  },
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const pluginConditionalFormatOptions = {
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe"
  }
};

const conditionalFormatColumnMap = new Set(["category", "measure"]);
const conditionalFormatTargetMap = new Set(["measure"])

export default class Radar extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      defaultConditionalFormatColumn: {},
      isLockedTargetValue: true,
    })

    this.rerenderProcessStarted = false;
    this.callBackObject = {};
    this.drillDowns = {};
  }

  categorySet = new Set()

  /*
  * Calculates plugin height
  */
  calculatePluginHeight = (plugin, settings) => {
    let pluginHeight = settings.grid.rowHeight * plugin.h; // multiply plugin.height and grid's row height
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginMinHeightDifference = 30;
    let maxHeight =
      pluginHeight -
      (pluginMinHeightDifference +
        pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    this.drillDowns = this.props.plugin.drillDowns;

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <RadarConfiguration
          config={{ ...props.config }}
          columnMap={this.props.plugin.columnMapForPlugin}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          categorySet={this.categorySet}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <RadarData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        refreshPlugin={props.refreshPlugin}
        navigations={props.navigations}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /*
  * Gets navigation component
  */
  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  /*
  * Converts columnMap with data object for conditional format component
  */
  convertColumnMapForConditionalFormat = (cmMap) => {
    let newColumnMap = []
    let reduxState = store.getState()
    let category = cmMap.category.data.length > 0 ? cmMap.category.data[0] : null

    if (cmMap.category.data.length > 0) {
      newColumnMap.push(cmMap.category.data[0])
    }

    if (cmMap.measure.data.length > 0) {
      newColumnMap.push(cmMap.measure.data[0])
    }

    if (reduxState.DrillDownReducer.drillDowns.has(this.props.plugin.id) && category) {
      let drillDownsReducer = reduxState.DrillDownReducer.drillDowns.get(this.props.plugin.id).drillDownColumnsForParentColumns;
      let drillCol = drillDownsReducer.has(category.uniqeColumnId)
        ? drillDownsReducer.get(category.uniqeColumnId)[0]
        : category.drillDownParentColumnId && drillDownsReducer.has(category.drillDownParentColumnId)
          ? drillDownsReducer.get(category.drillDownParentColumnId)[0]
          : null

      if (drillCol) {
        newColumnMap.push(drillCol)
      }
    }

    return newColumnMap
  }

  /*
  * Gets conditional formatting component
  */
  getConditionalFormattingComponent = props => {
    let columnMap = props.plugin.columnMap

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        pluginId={props.plugin.id}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
        isNecessaryAllColumns={true}
        defaultConditionalFormatColumn={this.state.defaultConditionalFormatColumn}
        isLockedTargetValue={false}

      />
    );
  };

  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      category: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Category.Name"),
        type: "dim",
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Category.Desc"),
        conditionalFormat: "icon",
        data: []
      },
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        type: "fact",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        multiple: true,
        required: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  /*
  * Converts condFormats to conditional format object
  */
  convertFormatConditionalFormatting = (condFormats, columns) => {
    let condFormatList = [];

    condFormats.map(condItem => {
      condItem.targetColumns.map(targetColumn => {
        let conditionalFormat = {};
        let columnsMap = new Map();

        columns.map(column => {
          columnsMap.set(column.uniqeColumnId, column);
        })

        conditionalFormat.RightRule = condItem.rule.rightRule.rule;
        conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
        conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName === "{undefined}" ? convertHTMLRuletoRule(condItem.rule.leftRule).ruleColumnName : condItem.rule.leftRule.ruleColumnName;
        conditionalFormat.RightRuleColumnName = condItem.rule.rightRule.ruleColumnName
        conditionalFormat.Columns = columnsMap;
        conditionalFormat.TargetID = targetColumn.TargetID;
        conditionalFormat.TargetName = targetColumn.TargetName;
        conditionalFormat.Operator = condItem.rule.operator;
        conditionalFormat.id = condItem.id;
        conditionalFormat.Targets = condItem.targetColumns
        conditionalFormat.ConditionalFormatRule = condItem.rule.conditionalFormatRule

        conditionalFormat.Style = {
          colour: condItem.options.color,
          icon: "",
          background: { colour: condItem.options.backgroundColor }
        };

        condFormatList.push(conditionalFormat);
      });
    });

    return condFormatList;
  };

  calculatePluginInlineHeightWithConditional = () => {
    return calculatePluginInlineHeight(this.props.plugin.id)
  }

  contrastColor

  /*
  * Gets contrast color
  */
  getContrastColor = (colorRGB) => {
    const yiq = (colorRGB.r * 299 + colorRGB.g * 587 + colorRGB.b * 114) / 1000;

    return yiq >= 128 ? "#000000" : "#FFFFFF"
  };

  /*
  * Sets plugin theme
  */ 
  setTheme = (config, root) => {
    let darkAndLightTheme = am5themes_Animated.new(root);

    let grip = {
      icon: undefined,
      width: 14,
      height: 14
    }

    let buttonBackground = {
      fill: am5.color(config.backgroundColor),
      stroke: am5.color(this.contrastColor),
      strokeOpacity: 0.5
    }

    darkAndLightTheme.rule("Label").setAll({
      fontSize: 12,
      fill: am5.color(this.contrastColor),
      oversizedBehavior: "truncate"
    });

    darkAndLightTheme.rule("Grid").setAll({
      stroke: am5.color(this.contrastColor),
      strokeOpacity: 0.2
    });

    darkAndLightTheme.rule("Button").setup = (button) => {
      button.get("background").setAll(buttonBackground);

      button.get("background").states.create("down", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 1
      });

      button.get("background").states.create("active", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 0.5
      });

      button.get("background").states.create("hover", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 0.8
      });
    };

    darkAndLightTheme.rule("Graphics", ["button", "icon"]).setAll({
      fill: am5.color(this.contrastColor),
      stroke: am5.color(this.contrastColor),
      x: am5.p50,
      centerX: am5.p50,
      y: am5.p50,
      centerY: am5.p50
    });

    darkAndLightTheme.rule("Button", ["zoom"]).setAll({
      width: 32,
      height: 32,
      icon: am5.Graphics.new(root, {
        themeTags: ["icon"],
        fill: am5.color(this.contrastColor),
        stroke: am5.color(this.contrastColor)
      })
    });


    darkAndLightTheme.rule("Scrollbar").setup = (scrollbar) => {
      scrollbar.setAll({
        minWidth: 5,
        minHeight: 5
      });

      scrollbar.get("background").setAll({
        fill: am5.color(this.contrastColor),
        fillOpacity: scrollbar.parent.className === "Legend" ? 0 : 0.1 
      }); 

      scrollbar.thumb.setAll({
        fill: am5.color(this.contrastColor),
        fillOpacity: 0.2
      });

      scrollbar.startGrip.setAll(grip);
      scrollbar.endGrip.setAll(grip);

      scrollbar.startGrip.get("background").setAll(buttonBackground);
      scrollbar.endGrip.get("background").setAll(buttonBackground);
    };

    root.setThemes([darkAndLightTheme]);
  }

  /*
  * Gets plugin category format
  */
  getFormat = (column) => {
    let type = column.DataType;
    let dataFormat = column.DataFormat;
    let format = column.DataFormat;

    if (format) {
      if (dateDataTypes.has(type)) {
        let parts = {
          "%Y": "yyyy",
          "%m": "MM",
          "%d": "dd",
          "%H": "HH",
          "%M": "mm",
          "%S": "ss"
        }

        for (let part of Object.keys(parts)) {
          format = format.replace(part, parts[part]);
        }

        this.root.dateFormatter.set("dateFormat", "format");

        return { format: format, placeHolder: `.formatDate('${format}')` };
      } else if (aggregatableDataTypes.has(type)) {
        dataFormat = dataFormat.toLowerCase();

        let isDataFormatDotNumberS = dataFormat[0] === "." && dataFormat[dataFormat.length - 1] === "s";
        let isDataFormatDotCommaNumberS = dataFormat[0] === "," && dataFormat[1] === "." && dataFormat[dataFormat.length - 1] === "s";

        let isDataFormatDotNumberF = dataFormat[0] === "." && dataFormat[dataFormat.length - 1] === "f";
        let isDataFormatDotCommaNumberF = dataFormat[0] === "," && dataFormat[1] === "." && dataFormat[dataFormat.length - 1] === "f";

        let format = {
          format: "#.",
          placeHolder: `.formatNumber('#.')`
        };

        if (dataFormat !== ".s" && isDataFormatDotNumberS) {
          // for .Ns
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("s"))

          if (!isNaN((Number(subString)))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#.${zeroArr}a')`;
            format.format = `#.${zeroArr}a`
          }
        } else if (isDataFormatDotCommaNumberF) {
          // for ,.Nf
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("f"))

          if (!isNaN((Number(subString)))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#,###.${zeroArr}')`;
            format.format = `#,###.${zeroArr}`
          }
        } else if (isDataFormatDotCommaNumberS) {
          // for ,.Ns
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("s"));

          if (!isNaN(Number(subString))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#,###.${zeroArr}a')`;
            format.format = `#,###.${zeroArr}a`
          }
        } else if (dataFormat === ".f") {
          //for .f
          format.placeHolder = `.formatNumber('#.')`;
          format.format = '#.';
        } else if (dataFormat !== ".f" && isDataFormatDotNumberF) {
          // for .Nf
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("f"));

          if (!isNaN(Number(subString))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(zeroCount + 1).join("0")

            format.placeHolder = `.formatNumber('#.${zeroArr}')`;
            format.format = `#.${zeroArr}`
          }
        }

        return format;
      }
    }

    return {
      placeHolder: "",
      format: null
    };
  }

  condFormatsLength;

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    var THIS = this

    this.contrastColor = this.getContrastColor(am5.color(config.backgroundColor));

    this.categorySet = new Set()

    let isPluginBigEnough = this.props.plugin.w > 5 && this.props.plugin.h >= 3 ? true : false
    let pluginBody = $("#plugin-" + divId)[0]
    let fullPluginHeight = this.calculatePluginInlineHeightWithConditional(this.props.plugin)
    let pluginHeight = fullPluginHeight
    let pluginWidth = $("#plugin-" + divId).width()

    if ($("#" + divId).length > 0) {
      $("#" + divId).remove()

      let mainContainer = document.createElement('div');
      mainContainer.setAttribute("id", divId);
      mainContainer.style.height = pluginHeight + "px"
      mainContainer.style.width = "-webkit-fill-available"

      $("#plugin-" + divId).children().children().append(mainContainer)
    } else {
      let mainContainer = document.createElement('div');
      mainContainer.setAttribute("id", divId);
      mainContainer.style.height = pluginHeight + "px"
      mainContainer.style.width = "-webkit-fill-available"

      $("#plugin-" + divId).children().children().append(mainContainer)
    }

    let convertColumnMapForConditionalFormat = columnMap => {
      let newColMap = []

      newColMap.push(columnMap.category)
      newColMap = newColMap.concat(columnMap.measure)

      return newColMap
    }

    if (condFormats !== undefined && typeof columnMap.category === 'object') {
      let convertedColumnMap = convertColumnMapForConditionalFormat(columnMap)

      condFormats = this.convertFormatConditionalFormatting(condFormats, convertedColumnMap);
    } else {
      condFormats = [];
    }

    let root = null

    if ($("#" + divId).length !== 0 && $("#" + divId).children().length === 0) {
      root = am5.Root.new(divId);
      
      root.autoResize = false;
      root.events.on("frameended", () => root.resize());

      root.container.set("layout", root.verticalLayout)
    } else {
      return null;
    }

    let parent = root.container.children.push(am5.Container.new(root, {
      height: am5.p100,
      width: am5.percent(100),
      background: config.background,
      layout: root.horizontalLayout
    }))

    let chartContainer = am5.Container.new(root, {
      width: am5.percent(70),
      height: am5.percent(100)
    })

    parent.children.push(chartContainer)

    var chart = chartContainer.children.push(am5radar.RadarChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      width: am5.percent(100),
    }));

    chart.zoomOutButton.dispose()

    chartContainer.setAll({
      x: config.legendPosition === "right" || config.legendPosition == undefined ? am5.percent(0) : am5.percent(100),
      centerX: config.legendPosition === "right" || config.legendPosition == undefined ? am5.percent(0) : am5.percent(100),
    })

    if (config.horizontalScrollbar && pluginHeight > 300) {
      let horizontalScrollbar = chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal", height: 5, exportable: false }));
    }

    if (config.verticalScrollbar && this.props.plugin.w > 3) {
      let verticalScrollbar = chart.set("scrollbarY", am5.Scrollbar.new(root, { orientation: "vertical", width: 5, exportable: false }));
      let scrollbarX = chart.get("scrollbarX");

      if (config.legend && (config.legendPosition === "right" || config.legendPosition == undefined)) {
        let grip = {
          icon: undefined,
          width: 14,
          height: 14
        }
  
        let buttonBackground = {
          fill: am5.color(config.backgroundColor),
          stroke: am5.color(this.contrastColor),
          strokeOpacity: 0.5
        }
  
        chart.leftAxesContainer.children.push(verticalScrollbar)
  
        verticalScrollbar.setAll({
          minWidth: 5,
          minHeight: 5
        });
  
        verticalScrollbar.get("background").setAll({
          fill: am5.color(this.contrastColor),
          fillOpacity: 0.1
        });
  
        verticalScrollbar.thumb.setAll({
          fill: am5.color(this.contrastColor),
          fillOpacity: 0.2
        });
  
        verticalScrollbar.startGrip.setAll(grip);
        verticalScrollbar.endGrip.setAll(grip);
  
        verticalScrollbar.startGrip.get("background").setAll(buttonBackground);
        verticalScrollbar.endGrip.get("background").setAll(buttonBackground);
      }
    }

    let conditionalLegendRoot
    let conditionalLegend

    let condForm = typeof config.condFormat === "undefined" ? config.showCondForm : config.condFormat;
    let isConditionalFormatMustShown = (typeof config.condFormat === "undefined" || condForm === true) && condFormats.length > 0 && isPluginBigEnough

    if (isConditionalFormatMustShown) {
      conditionalLegendRoot = root.container.children.push(
        am5.Legend.new(root, {
          width: am5.percent(100),
          height: 30,
          y: am5.percent(100),
          centerY: am5.percent(100),
        })
      );
      let conditionalData = []

      conditionalLegend = conditionalLegendRoot.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          y: am5.percent(100),
          centerY: am5.percent(100),
          nameField: "name",
          fillField: "color",
          strokeField: "color",
          height: am5.percent(100),
          verticalScrollbar: am5.Scrollbar.new(root, {
            orientation: "vertical",
            maxWidth: 5,
          })
        })
      );

      conditionalLegend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 12,
        height: 12,
      });

      conditionalLegend.labels.template.setAll({
        fontSize: 11,
        oversizedBehavior: "truncate",
        fill: am5.color(this.contrastColor),
        textAlign: "left",
      });

      for (let i = 0; i < condFormats.length; i++) {
        let perConditional = condFormats[i]
        let newStr = ""

        if (!condFormats[i].ConditionalFormatRule) {
          let original = " " + perConditional.LeftRuleColumnName + " " + perConditional.Operator + " " + perConditional.RightRuleColumnName + "";
          let decrypted = /[{()}]/g;

          newStr = original.replace(decrypted, '');
        } else {
          newStr = condFormats[i].ConditionalFormatRule
        }

        let perConditionalData = {
          name: newStr,
          color: perConditional.Style.background.colour
        }

        conditionalData.push(perConditionalData)
      }

      conditionalLegend.data.setAll(conditionalData);
    }

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    root.dateFormatter.monthsShort = shortMonths
    root.dateFormatter.months = longMonths
    root.numberFormatter.bigNumberPrefixes = bigNumberPrefixes
    root.locale["_decimalSeparator"] = decimalSeperators;
    root.locale["_thousandSeparator"] = thousandSeperators;

    root._logo.dispose();

    var cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
      behavior: "zoomX"
    }));

    cursor.lineY.set("visible", false);

    var xRenderer = am5radar.AxisRendererCircular.new(root, {});
    xRenderer.labels.template.setAll({
      radius: config.pointSize,
      fontSize: config.categoryFontSize == undefined ? 11 : config.categoryFontSize,
    });

    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      categoryField: "category",
      renderer: xRenderer,
      groupData: true
    }));

    let coloursList = Array.isArray(config.colours) ? config.colours : InsightsConfig.Palettes[config.colours]

    chart.get("colors").set("colors", new Array(100).fill(coloursList).flat());

    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5radar.AxisRendererRadial.new(root, {
        minGridDistance: config.distanceWithGrid == undefined ? 30 : config.distanceWithGrid,
      }),
      groupData: true,
    }));

    let copiedData = deepCopy(data)
    let dataHashMap = new Map()
    let newData = []

    if (Array.isArray(columnMap.measure)) {
      for (let i = 0; i < copiedData.length; i++) {
        for (let j = 0; j < columnMap.measure.length; j++) {
          let newDataObj = {}
          let color = coloursList[j]

          copiedData[i][columnMap.category.displayName] = copiedData[i]["category"]

          if (condFormats && condFormats.length > 0) {
            for (let k = 0; k < condFormats.length; k++) {
              let comparedColumn = compare(copiedData[i], condFormats[k])
              let isConditionalHasCurrentColumn = condFormats[k].Targets.filter(condFormat => condFormat.displayName === columnMap.measure[j].displayName || condFormat.TargetName === "AllColumns").length > 0 ? true : false

              if (comparedColumn.status && isConditionalHasCurrentColumn) {
                color = condFormats[k].Style.background.colour
              }
            }
          }

          this.categorySet.add(copiedData[i]["category"])

          copiedData[i][columnMap.measure[j].displayName] = copiedData[i]["measure"][j]["value"]

          newDataObj["category"] = getFormattedValue(columnMap.category, copiedData[i]["category"])
          newDataObj["pureCategory"] = copiedData[i]["category"]
          newDataObj[columnMap.measure[j].displayName] = copiedData[i]["measure"][j]["value"]
          newDataObj["settings"] = {
            fill: color,
            radius: config.pointSize
          }
          newDataObj["key"] = `${columnMap.measure[j].displayName}_${copiedData[i]["measure"][j]["value"]}`

          dataHashMap.set(`${columnMap.measure[j].displayName}_${copiedData[i]["measure"][j]["value"]}`, color)

          newData.push(newDataObj)
        }
      }
    } else {
      for (let i = 0; i < copiedData.length; i++) {
        let newDataObj = {}

        this.categorySet.add(copiedData[i]["category"])

        copiedData[i][columnMap.measure.displayName ? columnMap.measure.displayName : columnMap.measure.Name] = copiedData[i][columnMap.measure.displayName] ? copiedData[i][columnMap.measure.displayName] : copiedData[i]["measure"]
        newDataObj["category"] = copiedData[i]["category"]
        newDataObj[columnMap.measure.displayName ? columnMap.measure.displayName : columnMap.measure.Name] = copiedData[i][columnMap.measure.displayName] ? copiedData[i][columnMap.measure.displayName] : copiedData[i]["measure"]

        newDataObj["settings"] = {
          fill: coloursList[0],
          radius: config.pointSize
        }

        newData.push(newDataObj)
      }
    }

    let createSerie = (name, index) => {
      let series = null

      series = chart.series.push(am5radar.RadarLineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: name,
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
          orientation: "left"
        })
      }));

      series.strokes.template.setAll({
        strokeWidth: config.strokeWidth == undefined ? 2 : config.strokeWidth, // Stroke Width
        fill: coloursList[index],
        opacity: config.fillOpacity,
        fillOpacity: 1
      });

      let bulletTemplate = am5.Template.new(root, {});

      bulletTemplate.events.on("click", function (ev) {
        let container = $("#" + divId)[0];
        let mousePosition = { x: window.event.pageX, y: window.event.pageY }
        let newQueryData = {...ev.target.dataItem.dataContext}

        newQueryData["category"] = newQueryData["pureCategory"]

        createTrigger(
          actions,
          columnMap,
          container,
          "clickSlice",
          newQueryData,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition,
          null,
          THIS.drillDowns,
          [columnMap["category"]],
          THIS.props.plugin,
          THIS.props.model,
        ); // Trigger event
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            templateField: "settings",
            cursorOverStyle: "pointer"
          }, bulletTemplate)
        });
      });

      series.get("tooltip").label.adapters.add("text", function (text, target) {
        let category = ""

        series.get("tooltip").get("background").setAll({
          fill: "#000000a6",
          tooltipX: 0,
          tooltipY: 0,
        });

        if (chart.series._values[0].get("tooltipDataItem")) {
          category = chart.series._values[0].get("tooltipDataItem").dataContext.category
        }

        text = "[/] [bold fontSize: 14px]" + category;
        let i = 0;

        chart.series.each(function (series) {
          let tooltipDataItem = series.get("tooltipDataItem");

          if (tooltipDataItem) {
            let tooltipCategories = newData.filter(data => data.category === tooltipDataItem.dataContext.category)
            let legendTextColor = Array.isArray(columnMap.measure)
              ? dataHashMap.has(`${columnMap.measure[i].displayName}_${tooltipCategories[i][columnMap.measure[i].displayName]}`)
                ? dataHashMap.get(`${columnMap.measure[i].displayName}_${tooltipCategories[i][columnMap.measure[i].displayName]}`)
                : series.get("fill").toString()
              : series.get("fill").toString()

            if (Array.isArray(columnMap.measure)) {
              text += "\n";
              text += '[' + legendTextColor + ']●[/] [fontSize: 13px]'
                + columnMap.measure[i].displayName + ':[/] [bold fontSize: 13px]'
                + getFormattedValue(columnMap.measure[i], tooltipCategories[i][columnMap.measure[i].displayName]);

              i++;
            } else {
              text += "\n";
              text += '[' + legendTextColor + ']●[/] [fontSize: 13px]'
                + columnMap.measure.Name
                + ':[/] [bold fontSize: 13px]'
                + tooltipCategories[0][columnMap.measure.Name]

              i++;
            }
          }
        })
        return text
      });

      let isLegendAvailable = ((Array.isArray(columnMap.measure) && index === columnMap.measure.length - 1) || !Array.isArray(columnMap.measure)) && config.legend
      let legend
      let legendRoot = null

      if (isLegendAvailable && isPluginBigEnough) {
        if (config.legendPosition == undefined || config.legendPosition === "right") {
          legendRoot = parent.children.push(
            am5.Legend.new(root, {
              y: am5.percent(50),
              centerY: am5.percent(50),
              width: am5.percent(30),
            })
          );
        } else {
          legendRoot = parent.children.unshift(
            am5.Legend.new(root, {
              y: am5.percent(50),
              centerY: am5.percent(50),
              width: am5.percent(30),
            })
          );
        }

        legend = legendRoot.children.push(am5.Legend.new(root, {
          useDefaultMarker: true,
          width: am5.percent(100),
          layout: root.verticalLayout,
          height: pluginHeight / 2,
          verticalScrollbar: am5.Scrollbar.new(root, {
            orientation: "vertical",
            maxWidth: 5,
          })
        }));

        legend.markerRectangles.template.setAll({
          cornerRadiusTL: 10,
          cornerRadiusTR: 10,
          cornerRadiusBL: 10,
          cornerRadiusBR: 10,
          width: 14,
          height: 14,
        });

        legend.labels.template.setAll({
          fontSize: 11,
          maxWidth: legendRoot.innerWidth() - 50,
          width: legendRoot.innerWidth() - 50,
          oversizedBehavior: "truncate",
          fill: am5.color(this.contrastColor),
          textAlign: "left",
        });

        legend.data.setAll(chart.series.values);
      }

      if (config.ranges && index === 0 && config.showRanges) {
        for (let i = 0; i < config.ranges.length; i++) {
          let createdRange = config.ranges[i]

          if (createdRange.startCategory && createdRange.endCategory && !createdRange.error) {
            let category = getFormattedValue(columnMap.category, createdRange.startCategory)
            let endCategory = getFormattedValue(columnMap.category, createdRange.endCategory)

            let range = xAxis.createAxisRange(
              xAxis.makeDataItem(
                {
                  category: category,
                  endCategory: endCategory,
                  above: true,
                  categoryLocation: -columnMap.measure.length + 1
                }));

            range.get("axisFill").setAll({
              visible: createdRange.visible,
              fill: am5.color(createdRange.fill),
              fillOpacity: createdRange.fillOpacity,
              stroke: createdRange.fill,
              strokeOpacity: 1,
            })

            range.get("label").setAll({
              visible: false
            })
          }
        }
      }

      let divContainer = $("#parent-" + divId)

      /*
      * Converts data to export obj
      */
      let controlDataForExcelExport = exportedData => {
        let firstColumn = getCurrentDateTime();
        let secondColumn = "";
        let newData = [];
        let excelDataConcat = {}

        if (Array.isArray(columnMap.measure)) {
          newData = [
            {
              [firstColumn]: config.title,
            },
            {
              [firstColumn]: columnMap.category.Name,
              [secondColumn]: columnMap.measure[0].displayName
            },
          ]
        }

        for (let i = 0; i < exportedData.length; i++) {
          let exportDataObj = {};

          if (Array.isArray(columnMap.measure)) {
              let categoryKey = firstColumn;
              exportDataObj[categoryKey] = getFormattedValue(columnMap.category, exportedData[i]["category"]);
  
              for (let j = 0; j < columnMap.measure.length; j++) {
                  let measureKey = secondColumn;
                  exportDataObj[measureKey] = exportedData[i][columnMap.measure[j].displayName];
              }
  
              newData.push(exportDataObj);
              excelDataConcat = newData.concat(exportDataObj);
          } else {
            newData = [
              {
                [firstColumn]: config.title,
              },
              {
                [firstColumn]: columnMap.category.Name,
                [secondColumn]: columnMap.measure.Name
              },
            ]
             let baseData = [
              {
                [firstColumn]: "2014",
                [secondColumn]: "4045081712"
              },
              {
                [firstColumn]: "2015",
                [secondColumn]: "4296005250"
              },
              {
                [firstColumn]: "2016",
                [secondColumn]: "4037735418"
              }
            ]
            excelDataConcat = newData.concat(baseData);     
                
            break;
          }   
        }

        return excelDataConcat
      }

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: null,
        dataSource: controlDataForExcelExport(copiedData),
        pngOptions: {
          quality: 1,
          minWidth: 4320,
          minHeight: 4320,
          pageOrientation: "portrait"
        },
        pdfOptions: {
          quality: 1,
          addURL: false,
          minWidth: 4320,
          minHeight: 4320,
          align: "center"
        }
      });

      let exportDownloadSupport = exporting.downloadSupport();

      if (exportDownloadSupport) {
        let title = `${this.props.title}`;

        // General exporting properties
        exporting.title = title;
        exporting.filePrefix = title;
        exporting.useRetina = true;
        exporting.showTimeout = () => { };

        let plugin = $("#" + divId)[0]

        plugin.PNG = () => exporting.download("png");
        plugin.PDF = () => exporting.download("pdf");
        plugin.XLSX = () => this.props.excelExport();

        /**
         * Chart PDF document handler
         */
        exporting.events.on("pdfdocready", function (pdf) {
          let { content } = pdf.doc;
          let [title, image] = content;

          pdf.doc.header = {
            columns: [
              {
                text: `${i18n.t("Dashboard.Configuration.Fields.ExportDate")}: ${rmvpp.generateDate()}`,
                bold: false,
                margin: [0, 5, 30, 0],
                alignment: 'right'
              }
            ]
          }

          pdf.doc.content[0] = title;
          pdf.doc.content[1] = image;

          pdf.doc.footer = {
            image: vispeahenLogo,
            margin: [0, 0, 15, 5],
            alignment: 'right',
          };

          pdf.compress = false;

          return pdf;
        });

        /** excel export implemented here */
        exporting.adapters.add("xlsxWorkbook", workbook => {
          let sheetName = Object.keys(workbook.workbook.Sheets)[0];
          let worksheet = workbook.workbook.Sheets[sheetName];

          if (columnMap.vary.displayName) {
            worksheet["!ref"] = `A1:C${data.length + 1}`;
          } else {
            worksheet["!ref"] = `A1:B${data.length + 1}`;
          }

          worksheet["A1"] = { v: columnMap.group.displayName, t: "s" };
          worksheet["B1"] = { v: columnMap.measure.displayName, t: "s" };

          if (columnMap.vary.displayName) {
            worksheet["C1"] = { v: columnMap.vary.displayName, t: "s" };
          }

          data.forEach((e, i) => {
            let cell = String.fromCharCode(65);

            worksheet[cell + "" + (i + 2)] = { v: e.group, t: "s" };

            cell = String.fromCharCode(66);
            worksheet[cell + "" + (i + 2)] = { v: e.measure, t: "s" };

            if (columnMap.vary.displayName) {
              cell = String.fromCharCode(67);
              worksheet[cell + "" + (i + 2)] = { v: e.vary, t: "s" };
            }
          })

          return workbook;
        });

        let constantPluginHeight
        let constantPluginWidth

        /**
         * Before the export operation handler
         */
        exporting.events.on("exportstarted", (event) => {
          let headerSection = document.getElementsByClassName("header-section")[0];
          let toolsSection = document.getElementsByClassName("dashboard-setting")[0];
          let plugin = $("#" + divId)
          let javaSPlugin = $("#" + divId)[0]
          let length = 0

          for (let i = 0; i < columnMap.measure.length; i++) {
            if (columnMap.measure[i].displayName.length > length) {
              length = columnMap.measure[i].displayName.length
            }
          }

          let condForm = typeof config.condFormat === "undefined" ? config.showCondForm : config.condFormat;
          let isConditionalFormatMustShown = (typeof config.condFormat === "undefined" || condForm === true) && condFormats.length > 0 && isPluginBigEnough


          if (isConditionalFormatMustShown) {
            conditionalLegend.get("verticalScrollbar").set("start", 0)
          }

          if (isLegendAvailable && isPluginBigEnough) {
            legend.get("verticalScrollbar").set("start", 0)
          }

          constantPluginHeight = $("#" + divId).height()
          constantPluginWidth = $("#" + divId).width()

          headerSection.style.position = "relative";
          headerSection.style.zIndex = 1000;

          toolsSection.style.position = "relative";
          toolsSection.style.zIndex = 1000;

          pluginBody.style.visibility = "hidden";
          pluginBody.style.position = "fixed";

          changePluginLoaderVisibility(divId, true)

          loadingScreen(true);

          let height = plugin.height();
          let width = plugin.width();

          let parentHeight = chart.parent.height();
          let parentWidth = root.container._contentWidth;

          let legendHeight = 0;
          let legendWidth = 0

          let measuredHeight = parentHeight;
          let measuredWidth = chartContainer.width();

          if (config.legend && isPluginBigEnough) {
            legendHeight = legend._contentHeight;
            legendWidth = length * 11

            legend.set("height", legendHeight);
            legend.parent.set("height", legendHeight + 32);

            legendRoot.set("width", legendWidth + 50);
            legend.set("width", legendWidth + 50)

            legend.labels.template.setAll({
              maxWidth: legendWidth,
              width: legendWidth,
            });

            measuredWidth = measuredWidth + legendWidth
          }

          if (measuredHeight < legendHeight) measuredHeight = legendHeight + 32;

          measuredHeight = measuredHeight + 60;

          chart.parent.set("height", measuredHeight);
          chart.parent.set("width", measuredWidth);

          if (isConditionalFormatMustShown) {
            let condFormatHeight = conditionalLegend._contentHeight;
            let conditionalLegendHeight = conditionalLegend._contentHeight

            conditionalLegendRoot.set("height", conditionalLegendHeight);

            if (condFormatHeight > 30) measuredHeight += conditionalLegendHeight - 30;
          }

          javaSPlugin.preHeight = height;
          javaSPlugin.preWidth = width;

          javaSPlugin.style.height = Math.max(measuredHeight, height) + "px";
          javaSPlugin.style.width = Math.max(measuredWidth, width) + "px";
          javaSPlugin.style.visibility = "hidden";

          if (config.legend && isPluginBigEnough) {
            legend.set("y", 0)
            legend.set("centerY", 0)
          }

          chartContainer.setAll({
            x: config.legendPosition === "right" || config.legendPosition == undefined ? am5.percent(0) : am5.percent(70),
            centerX: config.legendPosition === "right" || config.legendPosition == undefined ? am5.percent(0) : am5.percent(70),
          })

          chartContainer.set("width", am5.percent(70))
          chartContainer.set("height", am5.percent(100))

          root.resize();
        });

        /**
         * After the export operation handler
         */
        exporting.events.on("exportfinished", event => {
          setTimeout(() => {
            let headerSection = document.getElementsByClassName("header-section")[0];
            let toolsSection = document.getElementsByClassName("dashboard-setting")[0];
            let javaSPlugin = $("#" + divId)[0]

            headerSection.style.position = "";
            headerSection.style.zIndex = "";

            toolsSection.style.position = "";
            toolsSection.style.zIndex = "";

            pluginBody.style.minHeight = "";
            pluginBody.style.minWidth = "";
            pluginBody.style.position = "";
            pluginBody.style.zIndex = "";

            store.dispatch(changePluginLoaderVisibility(divId, false));
            loadingScreen(false);

            javaSPlugin.style.height = constantPluginHeight + "px";
            javaSPlugin.style.width = constantPluginWidth + "px";

            let condForm = typeof config.condFormat === "undefined" ? config.showCondForm : config.condFormat;
            let isConditionalFormatMustShown = (typeof config.condFormat === "undefined" || condForm === true) && condFormats.length > 0 && isPluginBigEnough

            if (isConditionalFormatMustShown) {
              conditionalLegendRoot.set("height", 30);
            }

            if (isLegendAvailable && isPluginBigEnough) {
              legend.labels.template.setAll({
                maxWidth: (constantPluginWidth * 30 / 100) - 50,
                width: (constantPluginWidth * 30 / 100) - 50,
              });

              legend.set("height", constantPluginHeight / 2)
              legend.set("y", am5.percent(50))
              legend.set("centerY", am5.percent(50))

              legendRoot.setAll({
                y: am5.percent(50),
                centerY: am5.percent(50),
                width: am5.percent(30),
              })
            }

            chartContainer.setAll({
              x: config.legendPosition === "right" || config.legendPosition == undefined ? am5.percent(0) : am5.percent(100),
              centerX: config.legendPosition === "right" || config.legendPosition == undefined ? am5.percent(0) : am5.percent(100),
            })

            chartContainer.set("width", am5.percent(70))
            chartContainer.set("height", am5.percent(100))

            root.resize();

            javaSPlugin.style.visibility = "";
            $("#plugin-" + divId)[0].style.visibility = ""
          });
        });
      }

      series.data.setAll(newData);
      xAxis.data.setAll(newData);

      series.appear(1000);

      return series
    }

    if (Array.isArray(columnMap.measure)) {
      for (let i = 0; i < columnMap.measure.length; i++) {
        let measure = columnMap.measure[i]

        var serie = createSerie(measure.displayName, i)
      }
    } else {
      var serie = createSerie(columnMap.measure.displayName ? columnMap.measure.displayName : columnMap.measure.Name, 0)
    }

    yAxis.get("renderer").labels.template.setAll({
      visible: config.showValuesOnRadar == undefined ? false : config.showValuesOnRadar,
      fontSize: config.rangeFontSize == undefined ? 12 : config.rangeFontSize
    });

    root.numberFormatter.setAll({
      numberFormat: this.getFormat(Array.isArray(columnMap.measure) ? columnMap.measure[0] : columnMap.measure).format,
      numericFields: ["yAxis"]
    });

    this.setTheme(config, root)

    chart.appear(1000, 100);

    this.props.setPluginRerender(false, divId, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let conditionalFormatComponent = null;
    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        this.calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{ height: "100%" }}>
          <div></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            this.props.plugin.conditionalFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
          {navigationComponent}
          {conditionalFormatComponent}
        </div>
      </>
    );
  }
}
