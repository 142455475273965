import {
  AimOutlined,
  BgColorsOutlined,
  ColumnWidthOutlined,
  DragOutlined,
  EyeInvisibleOutlined,
  FieldTimeOutlined,
  FontSizeOutlined,
  FormatPainterOutlined,
  InfoCircleOutlined,
  NumberOutlined,
  RadiusSettingOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, Row, Select, Tooltip } from "antd";
import $ from "jquery";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import i18n from "../../../../Utils/i18next";
import FontTypeSelector from "../../../GeneralComponents/FontTypeSelector/FontTypeSelector";
import Text from "../../../GeneralComponents/Text/Text";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import { InsightsConfig } from "../../RenderJs/config";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { TextArea } = Input;
const { Option } = Select;

const chartTypes = [
  {
    value: "radial",
    label: "Dashboard.Configuration.Fields.Radial"
  },
  {
    value: "horizontal",
    label: "Dashboard.Configuration.Fields.Horizontal"
  },
  {
    value: "vertical",
    label: "Dashboard.Configuration.Fields.Vertical"
  }
];
const measureTypes = [
  {
    value: "numeric",
    label: "Dashboard.Configuration.Fields.Numeric"
  },
  {
    value: "percentage",
    label: "Dashboard.Configuration.Fields.Percentage"
  },
  {
    value: "numeric-percentage",
    label: "Dashboard.Configuration.Fields.NumericPercentage"
  }
];
const handTypes = [
  {
    value: "classic",
    label: "Dashboard.Configuration.Fields.Classic",
  },
  {
    value: "triangle",
    label: "Dashboard.Configuration.Fields.Triangle",
  }
];
const animationTypes = [
  {
    value: "bounce",
    label: "Dashboard.Configuration.Fields.Bounce"
  },
  {
    value: "circle",
    label: "Dashboard.Configuration.Fields.Circle"
  },
  {
    value: "cubic",
    label: "Dashboard.Configuration.Fields.Cubic"
  },
  {
    value: "elastic",
    label: "Dashboard.Configuration.Fields.Elastic"
  },
  {
    value: "exp",
    label: "Dashboard.Configuration.Fields.Exp"
  },
  {
    value: "linear",
    label: "Dashboard.Configuration.Fields.Linear"
  },
  {
    value: "sine",
    label: "Dashboard.Configuration.Fields.Sine"
  }
];

/**
* Configuration file of GaugeChart plugin
*/
export default class GaugeChartConfiguration extends Component {
  constructor(props) {
    super(props);

    this.inputTimer = null;

    this.state = {
      backgroundColor: this.props.config.backgroundColor,
      colours: this.props.config.colours,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      paletteColours: InsightsConfig.Palettes[this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours]
        : this.props.config.colours,
      titleFontStyle: typeof this.props.config.titleFontStyle === "undefined" ? false : this.props.config.titleFontStyle,
      titleFontWeight: typeof this.props.config.titleFontWeight === "undefined" ? false : this.props.config.titleFontWeight,
      titleFontSize: typeof this.props.config.titleFontSize === "undefined" ? 15 : this.props.config.titleFontSize,
      titleFont: typeof this.props.config.titleFont === "undefined" ? "Verdana" : this.props.config.titleFont,
      titleColour: typeof this.props.config.titleColour === "undefined" ? "black" : this.props.config.titleColour,
      titleTextDecor: typeof this.props.config.titleTextDecor === "undefined" ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: typeof this.props.config.changedTitleFontSize === "undefined" ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      noDataTitle: typeof this.props.config.noDataTitle === "undefined" ? "" : this.props.config.noDataTitle,
      animation: typeof this.props.config.animation === "undefined" ? "cubic" : this.props.config.animation,
      duration: typeof this.props.config.duration === "undefined" ? 1 : this.props.config.duration,
      font: typeof this.props.config.font === "undefined" ? "Verdana" : this.props.config.font,
      axisFontSize: typeof this.props.config.axisFontSize === "undefined" ? 12 : this.props.config.axisFontSize,
      measureFontSize: typeof this.props.config.measureFontSize === "undefined" ? 12 : this.props.config.measureFontSize,
      measureAutoFontSize: typeof this.props.config.measureAutoFontSize === "undefined" ? true : this.props.config.measureAutoFontSize,
      innerRadius: typeof this.props.config.innerRadius === "undefined" ? 90 : this.props.config.innerRadius,
      legend: typeof this.props.config.legend === "undefined" ? true : this.props.config.legend,
      measureType: typeof this.props.config.measureType === "undefined" ? "numeric" : this.props.config.measureType,
      axisMin: typeof this.props.config.axisMin === "undefined" ? "" : this.props.config.axisMin,
      axisMax: typeof this.props.config.axisMax === "undefined" ? "" : this.props.config.axisMax,
      showTargets: typeof this.props.config.showTargets === "undefined" ? true : this.props.config.showTargets,
      targetLabel: typeof this.props.config.targetLabel === "undefined" ? "" : this.props.config.targetLabel,
      targetStart: typeof this.props.config.targetStart === "undefined" ? "" : this.props.config.targetStart,
      targetEnd: typeof this.props.config.targetEnd === "undefined" ? "" : this.props.config.targetEnd,
      targetColor: typeof this.props.config.targetColor === "undefined" ? "#FF0000" : this.props.config.targetColor,
      condFormat: typeof this.props.config.condFormat === "undefined" ? true : this.props.config.condFormat,
      handType: typeof this.props.config.handType === "undefined" ? "triangle" : this.props.config.handType,
      axisType: typeof this.props.config.axisType === "undefined" ? "numeric" : this.props.config.axisType,
      targetDataType: typeof this.props.config.targetDataType === "undefined" ? "numeric" : this.props.config.targetDataType,
      showMeasureName: typeof this.props.config.showMeasureName === "undefined" ? true : this.props.config.showMeasureName,
      showMeasureValue: typeof this.props.config.showMeasureValue === "undefined" ? true : this.props.config.showMeasureValue,
      measuresOnAxis: typeof this.props.config.measuresOnAxis === "undefined" ? false : this.props.config.measuresOnAxis,
      legendPos: typeof this.props.config.legendPos === "undefined"
        ? this.props.measureCount > 1
          ? "Right"
          : "Bottom"
        : this.props.config.legendPos,
      chartType: typeof this.props.config.chartType === "undefined" ? "radial" : this.props.config.chartType,
      staticTarget: typeof this.props.config.staticTarget === "undefined" ? false : this.props.config.staticTarget,
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        backgroundColor: nextProps.config.backgroundColor,
        colours: nextProps.config.colours,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours]
          : nextProps.config.colours,
        titleFontStyle: typeof nextProps.config.titleFontStyle === "undefined" ? false : nextProps.config.titleFontStyle,
        titleFontWeight: typeof nextProps.config.titleFontWeight === "undefined" ? false : nextProps.config.titleFontWeight,
        titleFontSize: typeof nextProps.config.titleFontSize === "undefined" ? 15 : nextProps.config.titleFontSize,
        titleFont: typeof nextProps.config.titleFont === "undefined" ? "Verdana" : nextProps.config.titleFont,
        titleColour: typeof nextProps.config.titleColour === "undefined" ? "black" : nextProps.config.titleColour,
        titleTextDecor: typeof nextProps.config.titleTextDecor === "undefined" ? false : nextProps.config.titleTextDecor,
        changedTitleFontSize: typeof nextProps.config.changedTitleFontSize === "undefined" ? 15 : nextProps.config.changedTitleFontSize,
        keyForTitleSize: null,
        noDataTitle: typeof nextProps.config.noDataTitle === "undefined" ? "" : nextProps.config.noDataTitle,
        animation: typeof nextProps.config.animation === "undefined" ? "cubic" : nextProps.config.animation,
        duration: typeof nextProps.config.duration === "undefined" ? 1 : nextProps.config.duration,
        font: typeof nextProps.config.font === "undefined" ? "Verdana" : nextProps.config.font,
        axisFontSize: typeof nextProps.config.axisFontSize === "undefined" ? 12 : nextProps.config.axisFontSize,
        measureFontSize: typeof nextProps.config.measureFontSize === "undefined" ? 12 : nextProps.config.measureFontSize,
        measureAutoFontSize: typeof nextProps.config.measureAutoFontSize === "undefined" ? true : nextProps.config.measureAutoFontSize,
        innerRadius: typeof nextProps.config.innerRadius === "undefined" ? 90 : nextProps.config.innerRadius,
        legend: typeof nextProps.config.legend === "undefined" ? true : nextProps.config.legend,
        measureType: typeof nextProps.config.measureType === "undefined" ? "numeric" : nextProps.config.measureType,
        axisMin: typeof nextProps.config.axisMin === "undefined" ? "" : nextProps.config.axisMin,
        axisMax: typeof nextProps.config.axisMax === "undefined" ? "" : nextProps.config.axisMax,
        showTargets: typeof nextProps.config.showTargets === "undefined" ? true : nextProps.config.showTargets,
        targetLabel: typeof nextProps.config.targetLabel === "undefined" ? "" : nextProps.config.targetLabel,
        targetStart: typeof nextProps.config.targetStart === "undefined" ? "" : nextProps.config.targetStart,
        targetEnd: typeof nextProps.config.targetEnd === "undefined" ? "" : nextProps.config.targetEnd,
        targetColor: typeof nextProps.config.targetColor === "undefined" ? "#FF0000" : nextProps.config.targetColor,
        condFormat: typeof nextProps.config.condFormat === "undefined" ? true : nextProps.config.condFormat,
        handType: typeof nextProps.config.handType === "undefined" ? "triangle" : nextProps.config.handType,
        axisType: typeof nextProps.config.axisType === "undefined" ? "numeric" : nextProps.config.axisType,
        targetDataType: typeof nextProps.config.targetDataType === "undefined" ? "numeric" : nextProps.config.targetDataType,
        showMeasureName: typeof nextProps.config.showMeasureName === "undefined" ? true : nextProps.config.showMeasureName,
        showMeasureValue: typeof nextProps.config.showMeasureValue === "undefined" ? true : nextProps.config.showMeasureValue,
        measuresOnAxis: typeof nextProps.config.measuresOnAxis === "undefined" ? false : nextProps.config.measuresOnAxis,
        legendPos: typeof nextProps.config.legendPos === "undefined"
          ? nextProps.measureCount > 1
            ? "Right"
            : "Bottom"
          : nextProps.config.legendPos,
        chartType: typeof nextProps.config.chartType === "undefined" ? "radial" : nextProps.config.chartType,
        staticTarget: typeof nextProps.config.staticTarget === "undefined" ? false : nextProps.config.staticTarget,
      });
    }
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  /**
   * Changes the value of text attributes in ScatterChart plugin use handleChange
   */
  handleChange = async e => {
    clearTimeout(this.inputTimer);

    let type = e.target.id
    let value = e.target.value

    this.setState({
      ...this.state,
      [type]: value
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: type, value: value });
    }, 500);
  };

  /**
   * Checks whether checkbox is checked or not in ScatterChart plugin use handleCheck
   */
  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Updates state attributes in GaugeChart plugin
   */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Changes the icon attribute in GaugeChart plugin use iconOnChange
   */
  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  /**
   * Changes the title attribute in GaugeChart plugin use titleAlignOnChange
   */
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Changes the color attribute in GaugeChart plugin use colorOnChange
   */
  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  /**
   * Demonstrates how the choosen titleAlign will affect the interface in GaugeChart plugin
   */
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in GaugeChart plugin
   */
  colorChangeVisible = () => {
    this.setState({ ...this.state, colorChange: !this.state.colorChange });
  };

  /**
   * Changes the background colour attribute in GaugeChart plugin use backgroundColorOnChange
   */
  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in GaugeChart plugin
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };

  fontOnChange = obj => {
    this.setState({
      ...this.state,
      font: obj
    });

    this.setConfigToPlugin({ type: "font", value: obj });
  };

  /**
 * Renders axis min max configuration part
 * 
 * @param {*} measure 
 * @returns 
 */
  renderAxisMinMax = () => {
    let axisMin = this.state.axisMin !== "" ? Number(this.state.axisMin) : undefined;
    let axisMax = this.state.axisMax !== "" ? Number(this.state.axisMax) : undefined;

    let dataMin = this.props.axisMin;
    let dataMax = this.props.axisMax;

    let equalStatus = axisMin !== undefined && axisMax !== undefined
      ? axisMin === axisMax
      : axisMax === undefined
        ? axisMin === dataMax
        : axisMin === undefined
          ? axisMax === dataMin
          : false;

    let minStatus = typeof axisMin === "undefined"
      ? true
      : typeof axisMax === "undefined"
        ? axisMin < dataMax
        : axisMin < axisMax;

    let maxStatus = typeof axisMax === "undefined"
      ? true
      : typeof axisMin === "undefined"
        ? axisMax > dataMin
        : axisMax > axisMin;

    return (
      <>
        <Row
          id={"axis-min-max"}
          key={"axis-min-max"}
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "left",
            marginBottom: 5
          }}
        >
          <Input
            id={"axisMin"}
            placeholder={dataMin}
            value={axisMin}
            type={"number"}
            style={{
              borderColor: !minStatus ? "red" : "",
              color: !minStatus ? "red" : "",
              transition: "all 0.3s",
              marginRight: 4,
              width: "calc(50% - 4px)"
            }}
            onChange={this.handleChange}
          />
          <Input
            id={"axisMax"}
            placeholder={dataMax}
            value={axisMax}
            type={"number"}
            style={{
              borderColor: !maxStatus ? "red" : "",
              color: !maxStatus ? "red" : "",
              transition: "all 0.3s",
              marginLeft: 4,
              width: "calc(50% - 4px)"
            }}
            onChange={this.handleChange}
          />
        </Row>
        {equalStatus || !minStatus || !maxStatus ? (
          <Row
            id={"invalid-min-max"}
            key={"invalid-min-max"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              textAlign: "left",
              marginBottom: 8,
            }}
          >
            <Col span={24}>
              <Text style={{ fontSize: 11, color: "red", width: "100%", display: "flex", flexDirection: "column" }}>
                *
                {
                  equalStatus
                    ? i18n.t("Dashboard.Configuration.Fields.InvalidAxisMinMax")
                    : !minStatus
                      ? i18n.t("Dashboard.Configuration.Fields.InvalidAxisMin")
                      : !maxStatus
                        ? i18n.t("Dashboard.Configuration.Fields.InvalidAxisMax")
                        : null
                }
              </Text>
            </Col>
          </Row>
        ) : null}
      </>
    );
  }


  /**
   * Set target color picker visibility
   * 
   * @param {*} status
   */
  setTargetColorVisible = (status) => {
    this.setState({
      ...this.state,
      targetColorVisible: status
    });
  }

  /**
   * Handle target color change
   * 
   * @param {*} obj 
   */
  handleTargetColorChange = (obj) => {
    clearTimeout(this.inputTimer);

    let type = "targetColor"
    let value = obj.color

    this.setState({
      ...this.state,
      [type]: value
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: type, value: value });
    }, 500);
  }

  handleTargetDataTypeChange = (value) => {
    clearTimeout(this.inputTimer);

    let type = "targetDataType";

    this.setState({
      ...this.state,
      [type]: value,
      targetStart: "",
      targetEnd: ""
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type, value }, ["targetStart", "targetEnd"]);
    }, 500);
  }

  render() {
    let legendPositions = ["Left", "Right"]

    if (this.props.measureCount === 1) {
      legendPositions.unshift("Top", "Bottom");
    } else if (!legendPositions.includes(this.state.legendPos)) {
      this.setState({
        ...this.state,
        legendPos: "Right"
      });
    }

    return (
      <div style={{ color: "#505050" }}>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
            </Text>
          </Col>
          <Col span={12}>
            <Button
              onClick={this.backgroundColorChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.backgroundColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.backgroundColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.backgroundColor}
                onChange={this.backgroundColorOnChange}
                id={"backgroundColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Palette")}
            </Text>
          </Col>
          <Col span={4} style={{ paddingRight: "10px" }}>
            <Select
              id={"colours"}
              defaultValue={this.state.colours}
              value={this.state.colours}
              style={{ width: "100%" }}
              onChange={this.coloursOnChange}>
              <Option value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
              <Option value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
              <Option value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
              <Option value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
              <Option value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
            </Select>
          </Col>
          <Col span={8}>
            <ThemePalette
              colours={this.state.paletteColours}
              update={this.setConfigToPlugin}
            />
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Chart")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ChartType")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"chartType"}
              defaultValue={this.state.chartType}
              value={this.state.chartType}
              style={{ width: "100%" }}
              onChange={value => this.handleChange({ target: { id: "chartType", value: value } })}
            >
              {
                chartTypes.map(chartType => (
                  <Option value={chartType.value}>{i18n.t(chartType.label)}</Option>
                ))
              }
            </Select>
          </Col>
        </Row>
        {
          this.state.chartType === "radial" ? (
            <Row style={{ marginBottom: "5px" }}>
              <Col style={{ textAlign: "center" }} span={3}>
                <RadiusSettingOutlined style={{ fontSize: "16px" }} />{" "}
              </Col>
              <Col style={{ textAlign: "left" }} span={9}>
                <Text
                  style={{
                    fontSize: "16px",
                    display: "inline-block",
                    marginTop: "2px"
                  }}
                >
                  {i18n.t("Dashboard.Configuration.Fields.InnerRadius")}
                </Text>
              </Col>
              <Col span={12}>
                <Input
                  id={"innerRadius"}
                  type="number"
                  min={0}
                  max={99}
                  suffix="%"
                  defaultValue={this.state.innerRadius}
                  value={this.state.innerRadius}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
          ) : null
        }
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <i
              class="fa fa-font"
              aria-hidden="true"
              style={{ position: "relative", bottom: "-5px" }}
            />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.FontStyle")}
            </Text>
          </Col>
          <Col span={12}>
            <FontTypeSelector
              placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
              onChange={this.fontOnChange}
              value={this.state.font}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.dataType")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"axisType"}
              value={this.state.axisType}
              defaultValue={this.state.axisType}
              onChange={value => this.handleChange({ target: { id: "axisType", value: value } })}
              style={{
                width: "100%",
              }}
            >
              <Option value="numeric">{i18n.t("Dashboard.Configuration.Fields.Numeric")}</Option>
              <Option value="percentage">{i18n.t("Dashboard.Configuration.Fields.Percentage")}</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"condFormat"}
              defaultChecked={this.state.condFormat}
              checked={this.state.condFormat}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Axis")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.MeasuresOnAxis")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"measuresOnAxis"}
              defaultChecked={this.state.measuresOnAxis}
              checked={this.state.measuresOnAxis}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.FontSize")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"axisFontSize"}
              type="number"
              min={0}
              suffix="px"
              defaultValue={this.state.axisFontSize}
              value={this.state.axisFontSize}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnWidthOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.MinMax")}
            </Text>
          </Col>
          <Col span={12}>
            {this.renderAxisMinMax()}
          </Col>
        </Row>

        {
          this.state.chartType === "radial" ? (
            <>
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Dashboard.Configuration.Fields.Measures")}
              </Divider>
              <Row style={{ marginBottom: "5px" }}>
                <Col style={{ textAlign: "center" }} span={3}>
                  <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={9}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.MeasureHandType")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Select
                    id={"handType"}
                    value={this.state.handType}
                    defaultValue={this.state.handType}
                    onChange={value => this.handleChange({ target: { id: "handType", value: value } })}
                    style={{
                      width: "100%",
                    }}
                  >
                    {
                      handTypes.map(type => {
                        return (
                          <Option value={type.value}>{i18n.t(type.label)}</Option>
                        )
                      })
                    }
                    <Option value={null}>{i18n.t("Dashboard.Configuration.Fields.None")}</Option>
                  </Select>
                </Col>
              </Row>
            </>
          ) : null
        }

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Legends")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowLegends")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"legend"}
              defaultChecked={this.state.legend}
              checked={this.state.legend}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowMeasureName")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showMeasureName"}
              defaultChecked={this.state.showMeasureName}
              checked={this.state.showMeasureName}
              onChange={this.handleCheck}
              disabled={!this.state.legend}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowMeasureValue")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showMeasureValue"}
              defaultChecked={this.state.showMeasureValue}
              checked={this.state.showMeasureValue}
              onChange={this.handleCheck}
              disabled={!this.state.legend}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.dataType")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"measureType"}
              value={this.state.measureType}
              defaultValue={this.state.measureType}
              onChange={value => this.handleChange({ target: { id: "measureType", value: value } })}
              disabled={!this.state.legend || !this.state.showMeasureValue}
              style={{
                width: "100%",
              }}
            >
              {
                measureTypes.map(type => {
                  return (
                    <Option value={type.value}>{i18n.t(type.label)}</Option>
                  )
                })
              }
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.LegendFontSize")}
            </Text>
          </Col>
          <Col span={12}>
            <Row>
              <Select
                id={"measureAutoFontSize"}
                value={this.state.measureAutoFontSize}
                defaultValue={this.state.measureAutoFontSize}
                onChange={value => this.handleChange({ target: { id: "measureAutoFontSize", value: value } })}
                disabled={!this.state.legend}
                style={{
                  width: "calc(50% - 4px)",
                  marginRight: 4
                }}
              >
                <Option value={true}>{i18n.t("Dashboard.Configuration.Fields.Auto")}</Option>
                <Option value={false}>{i18n.t("Dashboard.Configuration.Fields.Manual")}</Option>
              </Select>
              <Input
                id={"measureFontSize"}
                type="number"
                min={0}
                suffix={this.state.measureAutoFontSize ? "" : "px"}
                defaultValue={this.state.measureAutoFontSize ? "" : this.state.measureFontSize}
                value={this.state.measureAutoFontSize ? "" : this.state.measureFontSize}
                onChange={this.state.measureAutoFontSize || !this.state.legend ? null : this.handleChange}
                disabled={this.state.measureAutoFontSize || !this.state.legend}
                style={{
                  width: "calc(50% - 4px)",
                  marginLeft: 4
                }}
              />
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <DragOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.LegendPosition")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"legendPos"}
              value={this.state.legendPos}
              defaultValue={this.state.legendPos}
              onChange={value => this.handleChange({ target: { id: "legendPos", value: value } })}
              disabled={!this.state.legend}
              style={{
                width: "100%",
              }}
            >
              {
                legendPositions.map(pos => (
                  <Option value={pos}>{i18n.t("Dashboard.Configuration.Fields." + pos)}</Option>
                ))
              }
            </Select>
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Target")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowTarget")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showTargets"}
              defaultChecked={this.state.showTargets}
              checked={this.state.showTargets}
              onChange={this.handleCheck}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <AimOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.StaticTarget")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"staticTarget"}
              defaultChecked={this.state.staticTarget}
              checked={this.state.staticTarget}
              onChange={this.handleCheck}
              disabled={!this.state.showTargets}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col span={3} />
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={6}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Label")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={`targetLabel`}
              value={this.state.targetLabel}
              onChange={this.handleChange}
              disabled={!this.state.showTargets || !this.state.staticTarget}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col span={3} />
          <Col style={{ textAlign: "center" }} span={3}>
            <NumberOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={6}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ValueType")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"targetDataType"}
              value={this.state.targetDataType}
              defaultValue={this.state.targetDataType}
              onChange={this.handleTargetDataTypeChange}
              disabled={!this.state.showTargets || !this.state.staticTarget}
              style={{
                width: "100%",
              }}
            >
              <Option value="numeric">{i18n.t("Dashboard.Configuration.Fields.Numeric")}</Option>
              <Option value="percentage">{i18n.t("Dashboard.Configuration.Fields.Percentage")}</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col span={3} />
          <Col style={{ textAlign: "center" }} span={3}>
            <NumberOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={6}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Values")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={`targetStart`}
              value={this.state.targetStart}
              placeholder={i18n.t("Dashboard.Configuration.Fields.StartLimit")}
              suffix={this.state.targetDataType === "percentage" ? "%" : ""}
              disabled={!this.state.showTargets || !this.state.staticTarget}
              type={"number"}
              onChange={this.handleChange}
              style={{
                width: "calc(50% - 4px)",
                marginRight: 4
              }}
            />
            <Input
              id={`targetEnd`}
              value={this.state.targetEnd}
              placeholder={i18n.t("Dashboard.Configuration.Fields.EndLimit")}
              suffix={this.state.targetDataType === "percentage" ? "%" : ""}
              disabled={!this.state.showTargets || !this.state.staticTarget}
              type={"number"}
              onChange={this.handleChange}
              style={{
                width: "calc(50% - 4px)",
                marginLeft: 4
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col span={3} />
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={6}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Color")}
            </Text>
          </Col>
          <Col style={{ textAlign: "left" }} span={10}>
            <Button
              onClick={() => this.setTargetColorVisible(!this.state.targetColorVisible)}
              disabled={!this.state.showTargets || !this.state.staticTarget}
              style={{ width: "100%" }}
            >
              {this.state.targetColorVisible
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.targetColorVisible ? (
              <ColorPickerPanel
                id={"targetColor"}
                alpha={100}
                color={this.state.targetColor}
                onChange={this.handleTargetColorChange}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
          <Col span={2}>
            <div
              onClick={this.props.titleColourChangeVisible}
              style={{
                marginLeft: "5px",
                height: "32px",
                border: "1px solid #d7d6d6",
                boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                borderRadius: "4px",
                cursor: this.state.showTargets === false ? "default" : "pointer",
                backgroundColor: this.state.showTargets === false ? "#dadada" : this.state.targetColor,
              }}
            ></div>
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Animation")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FieldTimeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Animation")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"animation"}
              value={this.state.animation}
              defaultValue={this.state.animation}
              onChange={value => this.handleChange({ target: { id: "animation", value: value } })}
              style={{
                width: "100%",
              }}
            >
              {
                animationTypes.map(type => {
                  return (
                    <Option value={type.value}>{i18n.t(type.label)}</Option>
                  )
                })
              }
              <Option value={"none"}>{i18n.t("Dashboard.Configuration.Fields.None")}</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FieldTimeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Duration")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"duration"}
              type="number"
              value={this.state.animation === "none" ? "" : this.state.duration}
              min={0}
              step={0.1}
              suffix="s"
              formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
              parser={value => `${value}`.replace(/[^0-9.]/g, '')}
              onChange={this.handleChange}
              disabled={this.state.animation === "none"}
            />
          </Col>
        </Row>

        <TitleSettingsOfPlugin
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          keyForTitleSize={this.state.keyForTitleSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          changedTitleFontSize={this.state.changedTitleFontSize}
          handleChangeBothOfValues={this.handleChangeBothOfValues}
        />
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip
              title={
                <>
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
