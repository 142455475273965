import { Checkbox, Col, Row, Select } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import i18n from "../../Utils/i18next";
import Text from "../GeneralComponents/Text/Text";

const { Option } = Select;

const STATUS = {
  WAITING: {
    key: "WAITING",
    text: i18n.t("FileUpload.LOADING"),
    color: "#7a9ab0",
  },
  ERROR: { key: "ERROR", text: i18n.t("FileUpload.ERROR"), color: "#d45050" },
  ALREADY_EXIST: {
    key: "ALREADY_EXIST",
    text: i18n.t("FileUpload.ALREADY_EXIST"),
    color: "rgb(255, 152, 1)",
  },
  READY_TO_CREATE: {
    key: "READY_TO_CREATE",
    text: i18n.t("FileUpload.READY_TO_CREATE"),
    color: "rgb(86 176 44)",
  },
  ORIGINAL_TABLE: {
    key: "ORIGINAL_TABLE",
    text: i18n.t("FileUpload.ORIGINAL_TABLE"),
    color: "purple",
  },
};

export const OPERATIONS = {
  DO_NOTHING: { key: "DO_NOTHING", value: i18n.t("FileUpload.DO_NOTHING") },
  OVERWRITE: { key: "OVERWRITE", value: i18n.t("FileUpload.OVERWRITE") },
  APPEND: { key: "APPEND", value: i18n.t("FileUpload.APPEND") },
  CREATE: { key: "CREATE", value: i18n.t("FileUpload.CREATE") },
};

/**
 * Check and shows sheet status before upload.
 */
export default class SheetStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: STATUS.WAITING,
    };
  }

  componentWillMount() {
    const { sheet, sheetName, checkResult } = this.props;

    this.setState({
      status: this.sheetStatus(checkResult, sheetName, sheet),
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.checkResult, this.props.checkResult)) {
      let status = this.sheetStatus(
        nextProps.checkResult,
        nextProps.sheetName,
        nextProps.sheet
      );

      this.setState({
        status: status,
      });
    }
  }

  sheetStatus = (checkResult, sheetName, sheet) => {
    if (checkResult === undefined) {
      return STATUS.WAITING;
    }

    if (checkResult.status !== "OK") {
      this.changeSheetOperation(sheet, OPERATIONS.DO_NOTHING.key);
      return STATUS.ERROR;
    }

    if (checkResult.originalTables?.has(sheetName)) {
      this.changeSheetOperation(sheet, OPERATIONS.DO_NOTHING.key);
      return STATUS.ORIGINAL_TABLE;
    }

    if (checkResult.notUniqueTables?.has(sheetName)) {
      this.changeSheetOperation(sheet, OPERATIONS.DO_NOTHING.key);
      return STATUS.ALREADY_EXIST;
    }

    if (!checkResult.notUniqueTables?.has(sheetName)) {
      this.changeSheetOperation(sheet, OPERATIONS.CREATE.key);
      return STATUS.READY_TO_CREATE;
    }

    if (checkResult.notUniqueTables === undefined) {
      this.changeSheetOperation(sheet, OPERATIONS.CREATE.key);
      return STATUS.READY_TO_CREATE;
    }
  };

  applyStatusToSheet = (sheet, status) => {
    sheet.status = status;

    this.props.setSheetConfiguration(sheet);
  };

  changeSheetOperation = (sheet, operation) => {
    sheet.operation = operation;

    this.props.setSheetConfiguration(sheet);
  };

  /**
   * Validation content by status
   * @param {*} status 
   * @param {*} sheet 
   * @param {*} loading 
   * @returns 
   */
  contentByStatus = (status, sheet, loading) => {
    if (status.key === STATUS.ORIGINAL_TABLE.key) {
      return (
        <Text
          type={"p"}
          style={{ display: "block", width: "100%", margin: "6px 0" }}
        >
          {i18n.t("FileUpload.YouCannotChangeOriginalTable")}
        </Text>
      );
    }

    if (status.key === STATUS.ALREADY_EXIST.key) {
      return (
        <Select
          style={{ width: "100%" }}
          disabled={loading === true ? true : false}
          defaultValue={OPERATIONS.DO_NOTHING.key}
          value={sheet.operation ? sheet.operation : OPERATIONS.DO_NOTHING.key}
          onChange={(e) => this.changeSheetOperation(sheet, e)}
        >
          <Option value={OPERATIONS.DO_NOTHING.key}>
            {OPERATIONS.DO_NOTHING.value}
          </Option>
          <Option value={OPERATIONS.OVERWRITE.key}>
            {OPERATIONS.OVERWRITE.value}
          </Option>
          <Option value={OPERATIONS.APPEND.key}>
            {OPERATIONS.APPEND.value}
          </Option>
        </Select>
      );
    }

    if (status.key === STATUS.READY_TO_CREATE.key) {
      return (
        <Select
          style={{ width: "100%" }}
          defaultValue={OPERATIONS.CREATE.key}
          value={sheet.operation ? sheet.operation : OPERATIONS.CREATE.key}
          onChange={(e) => this.changeSheetOperation(sheet, e)}
        >
          <Option value={OPERATIONS.CREATE.key}>
            {OPERATIONS.CREATE.value}
          </Option>
          <Option value={OPERATIONS.DO_NOTHING.key}>
            {OPERATIONS.DO_NOTHING.value}
          </Option>
        </Select>
      );
    }

    if (status.key === STATUS.WAITING.key) {
      return <Text>...</Text>;
    }
  };

  render() {
    const { sheet, loading } = this.props;
    const { status } = this.state;

    return (
      <div className="file-validation-item">
        <Row gutter={6}>
          <Col span={6}>
            <div style={{ marginTop: "5px" }}>{sheet.displayName}</div>
          </Col>
          <Col span={6}>
            <div style={{ marginTop: "5px" }}>
              <Text
                className={"sheet-status-label"}
                style={{ background: status.color }}
              >
                {status.text}
              </Text>
            </div>
          </Col>
          <Col span={6}>{this.contentByStatus(status, sheet, loading)}</Col>
        </Row>
      </div>
    );
  }
}
