import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import { Row, Col, Input, Select, Checkbox, Divider, Tooltip } from "antd";
import { FontSizeOutlined, EyeInvisibleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../Utils/i18next";
import ChangeFontStylePlugin from "../../../ConditionalFormatting/ChangeFontStylePlugin";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import $ from "jquery"

const { TextArea } = Input;
const { Option } = Select;

export default class PivotTableEnhanceConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoSize: this.props.config.autoSize,
      columnHeight: this.props.config.columnHeight,
      columnWidth: this.props.config.columnWidth,
      controls: this.props.config.controls,
      pivotTableDescription: this.props.config.pivotTableDescription,
      renderer: this.props.config.renderer,
      showHideButton: this.props.config.showHideButton,
      sumSelector: this.props.config.sumSelector,
      themeColour: this.props.config.themeColour,
      valuesAsCols: this.props.config.valuesAsCols,
      colColor: this.props.config.colColor == undefined ? "rgba(1,0,0,1)" : this.props.config.colColor,
      isColSizeMustConstant: this.props.config.isColSizeMustConstant == undefined ? true : this.props.config.isColSizeMustConstant,
      isRowSizeMustConstant: this.props.config.isRowSizeMustConstant == undefined ? true : this.props.config.isRowSizeMustConstant,
      rowWidth: this.props.config.rowWidth == undefined ? 100 : this.props.config.rowWidth,
      rowHeight: this.props.config.rowHeight == undefined ? 14 : this.props.config.rowHeight,
      rowColor: this.props.config.rowColor == undefined ? "rgba(1,0,0,1)" : this.props.config.rowColor,
      headerFont: this.props.config.headerFont == undefined ? "Verdana" : this.props.config.headerFont,
      headerFontSize: this.props.config.headerFontSize == undefined ? "10" : this.props.config.headerFontSize,
      totalFontSize: this.props.config.totalFontSize == undefined ? "10" : this.props.config.totalFontSize,
      totalFontColour: this.props.config.totalFontColour == undefined ? "rgba(1,0,0,1)" : this.props.config.totalFontColour,
      valueFontSize: this.props.config.valueFontSize == undefined ? "10" : this.props.config.valueFontSize,
      valueFontFamily: this.props.config.valueFontFamily == undefined ? "Verdana" : this.props.config.valueFontFamily,
      valueColor: this.props.config.valueColor == undefined ? "rgba(1,0,0,1)" : this.props.config.valueColor,
      valueFontWeight: this.props.config.valueFontWeight == undefined ? false : this.props.config.valueFontWeight,
      valueFontStyle: this.props.config.valueFontStyle == undefined ? false : this.props.config.valueFontStyle,
      valueFontDecor: this.props.config.valueFontDecor == undefined ? false : this.props.config.valueFontDecor,
      headerFontWeight: this.props.config.headerFontWeight == undefined ? false : this.props.config.headerFontWeight,
      headerFontStyle: this.props.config.headerFontStyle == undefined ? false : this.props.config.headerFontStyle,
      headerFontDecor: this.props.config.headerFontDecor == undefined ? false : this.props.config.headerFontDecor,
      totalFontWeight: this.props.config.totalFontWeight == undefined ? false : this.props.config.totalFontWeight,
      totalFontStyle: this.props.config.totalFontStyle == undefined ? false : this.props.config.totalFontStyle,
      totalFontDecor: this.props.config.totalFontDecor == undefined ? false : this.props.config.totalFontDecor,
      defaultDrilldownColor: this.props.config.defaultDrilldownColor == undefined ? true : this.props.config.defaultDrilldownColor,
      settedDrilldownColor: this.props.config.settedDrilldownColor == undefined ? "rgba(255, 255, 255, 1)" : this.props.config.settedDrilldownColor,
      
      wrapHeader: this.props.config.wrapHeader,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign || "left",
      condFormat: this.props.config.condFormat,
      titleFontStyle: this.props.config.titleFontStyle || false,
      titleFontWeight: this.props.config.titleFontWeight || false,

      showSum: this.props.config.showSum,
      subTotal: this.props.config.subTotal,
      groupName: this.props.config.groupName,
      rowSum: this.props.config.rowSum,
      pivotTheme: this.props.config.pivotTheme,
      groupDefaultExpanded: this.props.config.groupDefaultExpanded,
      enhanceMode: this.props.config.enhanceMode || true,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      totalFontFamily: this.props.config.totalFontFamily == undefined ? "Verdana" : this.props.config.totalFontFamily,
      keyForTitleSize: null,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle,
      nullValue: this.props.config.nullValue ? this.props.config.nullValue : ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        autoSize: nextProps.config.autoSize,
        columnHeight: nextProps.config.columnHeight,
        columnWidth: nextProps.config.columnWidth,
        controls: nextProps.config.controls,
        pivotTableDescription: nextProps.config.pivotTableDescription,
        renderer: nextProps.config.renderer,
        showHideButton: nextProps.config.showHideButton,
        sumSelector: nextProps.config.sumSelector,
        themeColour: nextProps.config.themeColour,
        valuesAsCols: nextProps.config.valuesAsCols,
        wrapHeader: nextProps.config.wrapHeader,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        condFormat: nextProps.config.condFormat,
        colColor: nextProps.config.colColor == undefined ? "rgba(1,0,0,1)" : nextProps.config.colColor,
        isColSizeMustConstant: nextProps.config.isColSizeMustConstant == undefined ? true : nextProps.config.isColSizeMustConstant,
        isRowSizeMustConstant: nextProps.config.isRowSizeMustConstant == undefined ? true : nextProps.config.isRowSizeMustConstant,
        rowWidth: nextProps.config.rowWidth == undefined ? 100 : nextProps.config.rowWidth,
        rowHeight: nextProps.config.rowHeight == undefined ? 14 : nextProps.config.rowHeight,
        rowColor: nextProps.config.rowColor == undefined ? "rgba(1,0,0,1)" : nextProps.config.rowColor,
        headerFont: nextProps.config.headerFont == undefined ? "Verdana" : nextProps.config.headerFont,
        headerFontSize: nextProps.config.headerFontSize == undefined ? "10" : nextProps.config.headerFontSize,
        totalFontSize: nextProps.config.totalFontSize == undefined ? "10" : nextProps.config.totalFontSize,
        totalFontColour: nextProps.config.totalFontColour == undefined ? "rgba(1,0,0,1)" : nextProps.config.totalFontColour,
        valueFontSize: nextProps.config.valueFontSize == undefined ? "10" : nextProps.config.valueFontSize,
        valueFontFamily: nextProps.config.valueFontFamily == undefined ? "Verdana" : nextProps.config.valueFontFamily,
        valueColor: nextProps.config.valueColor == undefined ? "rgba(1,0,0,1)" : nextProps.config.valueColor,
        valueFontWeight: nextProps.config.valueFontWeight == undefined ? false : nextProps.config.valueFontWeight,
        valueFontStyle: nextProps.config.valueFontStyle == undefined ? false : nextProps.config.valueFontStyle,
        valueFontDecor: nextProps.config.valueFontDecor == undefined ? false : nextProps.config.valueFontDecor,
        headerFontWeight: nextProps.config.headerFontWeight == undefined ? false : nextProps.config.headerFontWeight,
        headerFontStyle: nextProps.config.headerFontStyle == undefined ? false : nextProps.config.headerFontStyle,
        headerFontDecor: nextProps.config.headerFontDecor == undefined ? false : nextProps.config.headerFontDecor,
        totalFontWeight: nextProps.config.totalFontWeight == undefined ? false : nextProps.config.totalFontWeight,
        totalFontStyle: nextProps.config.totalFontStyle == undefined ? false : nextProps.config.totalFontStyle,
        totalFontDecor: nextProps.config.totalFontDecor == undefined ? false : nextProps.config.totalFontDecor,
        totalFontFamily: nextProps.config.totalFontFamily == undefined ? "Verdana" : nextProps.config.totalFontFamily,

        showSum: nextProps.config.showSum,
        subTotal: nextProps.config.subTotal,
        groupName: nextProps.config.groupName,
        rowSum: nextProps.config.rowSum,
        pivotTheme: nextProps.config.pivotTheme,
        groupDefaultExpanded: nextProps.config.groupDefaultExpanded,
        enhanceMode: nextProps.config.enhanceMode,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,
        nullValue: nextProps.config.nullValue ? nextProps.config.nullValue : ""
      });
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        { 
          type: "titleFontSize", 
          value: constantValue
        }, 
        {
          type: "changedTitleFontSize", 
          value: changedValue
        }
      ]);
  }

  /*
  * Changes null value
  */
  handleChangeNullValue = e => {
    this.setState({
      ...this.state,
      nullValue: e.target.value,
    });

    this.setConfigToPlugin({ type: "nullValue", value: e.target.value });
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({type: "changedTitleFontSize", value: newConfig.titleFontSize})
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };  

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Changes the color attribute in MapCluster plugin use colorOnChange
   */
  themeColourOnChange = obj => {
    this.setState({
      ...this.state,
      themeColour: obj.color
    });

    this.setConfigToPlugin({ type: "themeColour", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in MapCluster plugin
   */
  themeColourChangeVisible = () => {
    this.setState({
      ...this.state,
      themeColourChange: !this.state.themeColourChange
    });
  };

   setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
    }
  }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  pivotThemeOnChange = value => {
    this.setState({
      ...this.state,
      pivotTheme: value
    });

    this.setConfigToPlugin({ type: "pivotTheme", value: value });
  };

  rowSumOnChange = value => {
    this.setState({
      ...this.state,
      rowSum: value
    });

    this.setConfigToPlugin({ type: "rowSum", value: value });
  };

  sumSelectorOnChange = value => {
    this.setState({
      ...this.state,
      sumSelector: value
    });

    this.setConfigToPlugin({ type: "sumSelector", value: value });
  };

  rendererOnChange = value => {
    this.setState({
      ...this.state,
      sumSelector: value
    });

    this.setConfigToPlugin({ type: "renderer", value: value });
  };

  render() {
    return (
      <div style={{ color: "#505050" }}>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Attribute")}
        </Divider>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.EnhanceMode")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"enhanceMode"}
              defaultChecked={this.state.enhanceMode}
              checked={this.state.enhanceMode}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.NullValue")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"nullValue"}
              defaultValue={this.state.nullValue}
              value={this.state.nullValue}
              onChange={this.handleChangeNullValue}
            />
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.SumOperations")}
        </Divider>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowSumColumns")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showSum"}
              defaultChecked={this.state.showSum}
              checked={this.state.showSum}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.SubTotal")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"subTotal"}
              defaultChecked={this.state.subTotal}
              checked={this.state.subTotal}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>

        {/** TODO: Row total configuration will handle */}
        {/* <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.RowTotal")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              id={"pivotRowSum"}
              style={{ width: "100%" }}
              placeholder={i18n.t("Dashboard.Configuration.Fields.RowTotal")}
              optionFilterProp="children"
              onChange={this.rowSumOnChange}
              defaultValue={this.state.rowSum}
            >
              <Option name={"pivotRowSumDisable"} value={undefined}>
                {i18n.t("Disable")}
              </Option>
              <Option name={"pivotRowSumAfterFromColumns"} value="after">
                {i18n.t("Dashboard.Configuration.Fields.After")}
              </Option>
              <Option name={"pivotRowSumBeforeFromColumns"} value="before">
                {i18n.t("Dashboard.Configuration.Fields.Before")}
              </Option>
            </Select>
          </Col>
        </Row> */}

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.RowGroupOperations")}
        </Divider>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.GroupName")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"groupName"}
              defaultValue={this.state.groupName}
              value={this.state.groupName}
              onChange={this.handleChange}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.GroupDefaultExpanded")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"groupDefaultExpanded"}
              defaultChecked={this.state.groupDefaultExpanded}
              checked={this.state.groupDefaultExpanded}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <TitleSettingsOfPlugin
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          titleColumn={9} 
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          keyForTitleSize={this.state.keyForTitleSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          changedTitleFontSize= {this.state.changedTitleFontSize}
          handleChangeBothOfValues= {this.handleChangeBothOfValues}
        />
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.PivotTheme")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              id={"pivotTheme"}
              style={{ width: "100%" }}
              placeholder={i18n.t("Dashboard.Configuration.Fields.PivotTheme")}
              optionFilterProp="children"
              onChange={this.pivotThemeOnChange}
              defaultValue={this.state.pivotTheme}
              getPopupContainer={trigger => trigger.parentNode}
              dropdownMenuStyle={{textAlign:"left"}}
            >
              <Option name={"pivotThemeAlpine"} value={"ag-theme-alpine"}>
                Alpine
              </Option>
              <Option name={"pivotThemeBalham"} value={"ag-theme-balham"}>
                Balham
              </Option>
              <Option name={"pivotThemeMaterial"} value={"ag-theme-material"}>
                Material
              </Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip 
              title={
                <>
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} /> 
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
