import React, { Component } from "react";
import { Select } from "antd";
import i18n from "../../../../Utils/i18next";

const { Option } = Select;

/**
 * Common component for preadicate select dropdown.
 * Contains all predicate types
 */
class SelectPredicate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      predicate: this.props.predicate ? this.props.predicate : "="
    };
  }

  /**
   * @param value selected value
   * handle selected value and pass
   */
  onChangePredicate = value => {
    this.setState({
      ...this.state,
      predicate: value
    });

    if (this.props.setPredicateToColumn) {
      this.props.setPredicateToColumn(
        this.props.filterId,
        value,
        this.props.filter
      );
    } else {
      this.props.onChange(value);
    }
    
  };

  filterPredicates = [
    {name: "equals", title: "Equals", value: "="},
    {name: "notEqualsIn", title: "NotEqualsIn", value: "notIn"},
    {name: "greaterThan", title: "GreaterThan", value: ">"},
    {name: "greaterThanOrEqual", title: "GreaterThanOrEqual", value: ">="},
    {name: "lessThan", title: "LessThan", value: "<"},
    {name: "lessThanOrEqual", title: "LessThanOrEqual", value: "<="},
    {name: "isNull", title: "IsNull", value: "is null"},
    {name: "isNotNull", title: "IsNotNull", value: "is not null"},
    {name: "like", title: "Like", value: "like"},
  ]

  render() {
    let defaultValue = this.state.predicate === "in" ? "=" : this.state.predicate

    return (
      <Select
        id={this.props.id}
        key={"default-filter-predicate-" + this.props.id}
        showSearch
        style={{ width: "100%" }}
        placeholder={i18n.t("Dashboard.Configuration.Fields.SelectTitleAlign")}
        optionFilterProp="children"
        onChange={this.onChangePredicate}
        defaultValue={defaultValue}
        dropdownClassName={`default-filter-select-${this.props.pluginId}`}
      >
        {this.filterPredicates.map((filterPredicate, i) => (
          <Option key={i} name={filterPredicate.name} title={i18n.t(`FilterPredicates.${filterPredicate.title}`)} value={filterPredicate.value}>{i18n.t(`FilterPredicates.${filterPredicate.title}`)}</Option>
        ))}
      </Select>
    );
  }
}

export default SelectPredicate;
