import React, { Component } from "react";
import { Select, Icon } from "antd";
import "antd/dist/antd.css";
import i18n from "../../../Utils/i18next";
import _ from "lodash";
import { store } from "../../../../src/index"

const { Option } = Select;

const clone = require("rfdc")();

/** 
 * This component selects a model from the modelList dropdown.
 */
export default class SelectModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modelList: this.props.modelList ? this.props.modelList : [],
      value: null,
    };
  }

  componentWillMount() {
    /** 
     * Dropdown is also selected when a new model is created
     */
    if (
      this.state.modelList.length > 0 &&
      this.state.modelList[this.state.modelList.length - 1].new
    ) {
      this.setValue(this.state.modelList[this.state.modelList.length - 1].name);
    }
  }

  /**
   * 
   * @param {*} nextProps 
   * @returns 
   * 
   * sets the selected model to dropdown
   */
  hasModelChanged = (nextProps) => {
    return (
      nextProps.visible === "enable" &&
      nextProps.modelId &&
      this.state.value === null
    );
  };

  componentWillReceiveProps(nextProps) {
    /** 
     * If the model list that comes as props is updated, the modelList variable in the state must also be updated.
     */
    if (!_.isEqual(nextProps.modelList, this.props.modelList)) {
      this.setState({
        modelList: nextProps.modelList,
      });
    }

    if (nextProps.modelList && nextProps.modelList.length > 0 && nextProps.modelId !== this.props.modelId) {
      let reduxState = store.getState()
      let modelId = reduxState.SelfServiceReducer.modelId

      if (nextProps.modelId !== modelId) {
        this.onChange(nextProps.modelId, false)
      }
    }

    /** 
     * If a new model is added to the modelList that comes as props, the added model is selected
     */
    /** 
     *  if a value is removed from the modelList, the selected value is deleted
     */
    if (
      nextProps.modelList.length > this.props.modelList.length &&
      this.props.modelList.length > 0
    ) {
      this.setValue(nextProps.modelList[nextProps.modelList.length - 1].name);
    } else if (nextProps.modelList.length < this.props.modelList.length) {
      this.setValue(null);
    }

    if(this.hasModelChanged(nextProps)) {
      this.setValue(nextProps.modelId)
    }
  }

  onChange = (value, isCookieWillSet = true) => {
    /** 
     * Choosing the model and notifying the selected model to the parent component
     */
    this.props.findJoinChanges(value, false)
    this.props.handleSelection(value, isCookieWillSet);
    this.setState({
      value,
    });
  };

  setValue = (value) => {
    this.setState({
      ...this.state,
      value: value,
    });
  };

  /**
   *
   * @param {Array} modelList Contains model information in the model list
   * This method allows the future modelList to be sorted.
   */
  sortDropdown = (modelList) => {
    if (modelList && modelList.length > 0) {
      modelList.sort(function (a, b) {
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
        return 0;
      });
    }
  };

  render() {
    const { modelList } = this.props;
    let modelListDropdownValue = clone(modelList);
    this.sortDropdown(modelListDropdownValue);

    return modelList && modelList.length > 0 ? (
      <div>
        <Select 
          showSearch
          id={"modelSearch"}
          style={this.props.style}
          value={
            this.state.value == null
              ? i18n.t("Model.SelectModel.PlaceHolder")
              : this.state.value
          }
          placeholder={i18n.t("Model.SelectModel.PlaceHolder")}
          optionFilterProp="children"
          onChange={this.onChange}
          filterOption={(input, option) =>
            option.props.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {modelListDropdownValue.map((model, key) => (
            <Option value={model.id} key={key}>
              <Icon id={model.displayName} type="profile" /> &nbsp; {model.displayName}
            </Option>
          ))}
        </Select>
      </div>
    ) : (
      ""
    );
  }
}
