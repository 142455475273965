import {
  post,
} from "./WebService";
import {
  eraseCookie,
  createCookie,
  parseJwt,
  navigate
} from "./Global";
import Cookies from "js-cookie";

export const authToken = Cookies.get("ORA_BIPS_NQID");
export const authenticatedUser = Cookies.get("authenticatedUser");

export const logout = (successFunc, errorFunc) => {
  const url = "/security/logoff";

  const successLogout = () => {
    removeAuthentication();

    if (successFunc) {
      successFunc();
    } else {
      naviagateToLogin();
    }
  }

  post(url, null, successLogout, successLogout);
};

/**
 * Checks whether user authenticated or not
 */
export const isAuthenticated = () => {
  const token = Cookies.get("ORA_BIPS_NQID");
  return token != undefined && token != null && token !== "";
}

/**
 * Navigates web site to login page
 */
export const naviagateToLogin = (delayAsMilliseconds = 250) => {
  navigate("/vispeahen/ui/login", delayAsMilliseconds);
}

/**
 * Sets tenant, roles, token adn authenticated user to cookie
 * @param {Access Token produced by authentication server} token 
 */
export const setAuthentication = (token) => {
  createCookie("ORA_BIPS_NQID", token.access_token, null, "/");

  let sign = parseJwt(token.access_token);
  
  createCookie("tenant", sign.tenant, null, "/");
  createCookie("roles", sign.authorities, null, "/");
  createCookie("authenticatedUser", sign.user_name, null, "/");
  createCookie("operationTypes", getRolesOperations(sign.operationTypes), null, "/");
  createCookie("lastUser", sign.user_name, null, "/");
}

/**
 * Removes all authentication related cookies
 */
export const removeAuthentication = () => {
  eraseCookie('ORA_BIPS_NQID', '/');
  eraseCookie('tenant', '/');
  eraseCookie('roles', '/');
  eraseCookie('authenticatedUser', '/');

  localStorage.removeItem("copiedPlugin");
}

/**
 *
 * @param {*} operationTypes
 * @returns
 * Separates the operations of the roles and assigns them to the array
 */
 function getRolesOperations(operationTypes) {
  let rolesOperations = [];

  for (let operationField in operationTypes) {
    if (!rolesOperations.includes(operationTypes[operationField])) {
      rolesOperations.push(operationTypes[operationField]);
    }
  }

  return rolesOperations;
}