import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import { Row, Col, Input, Select, Checkbox, Divider, Button, InputNumber, Tooltip } from "antd";
import {
  FontSizeOutlined,
  BgColorsOutlined,
  EyeInvisibleOutlined,
  FieldTimeOutlined,
  UnderlineOutlined,
  ItalicOutlined,
  BoldOutlined,
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  AimOutlined,
  CloseCircleFilled,
  PlusOutlined,
  CloseOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../Utils/i18next"
import $ from "jquery"
import { InsightsConfig } from "../../RenderJs/config";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import "../../../GeneralComponents/ThemePalette/themePalette.css";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import { deepCopy } from "../../../../Utils/Global";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { TextArea } = Input;
const { Option } = Select;

/**
* Configuration file of ScatterChart plugin
*/
export default class ScatterChartConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paletteColours: InsightsConfig.Palettes[this.props.config.colours] ? InsightsConfig.Palettes[this.props.config.colours] : this.props.config.colours,
      backgroundColor: this.props.config.backgroundColor,
      colours: this.props.config.colours,

      height: this.props.config.height,
      width: this.props.config.width,

      maxPointSize: this.props.config.maxPointSize,
      minPointSize: this.props.config.minPointSize,
      showHideButton: this.props.config.showHideButton,

      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,

      xAxisAlignment: this.props.config.xAxisAlignment === undefined ? "bottom" : this.props.config.xAxisAlignment,
      xTitleFontStyle: this.props.config.xTitleFontStyle === undefined ? false : this.props.config.xTitleFontStyle,
      xTitleFontWeight: this.props.config.xTitleFontWeight === undefined ? false : this.props.config.xTitleFontWeight,
      xTitleFontSize: this.props.config.xTitleFontSize === undefined ? 12 : this.props.config.xTitleFontSize,
      xTitleTextDecor: this.props.config.xTitleTextDecor === undefined ? false : this.props.config.xTitleTextDecor,

      yAxisAlignment: this.props.config.yAxisAlignment === undefined ? "left" : this.props.config.yAxisAlignment,
      yTitleFontStyle: this.props.config.yTitleFontStyle === undefined ? false : this.props.config.yTitleFontStyle,
      yTitleFontWeight: this.props.config.yTitleFontWeight === undefined ? false : this.props.config.yTitleFontWeight,
      yTitleFontSize: this.props.config.yTitleFontSize === undefined ? 12 : this.props.config.yTitleFontSize,
      yTitleTextDecor: this.props.config.yTitleTextDecor === undefined ? false : this.props.config.yTitleTextDecor,

      lr: this.props.config.lr === undefined ? false : this.props.config.lr,
      legend: this.props.config.legend === undefined ? true : this.props.config.legend,
      condFormat: this.props.config.condFormat === undefined ? true : this.props.config.condFormat,

      duration: this.props.config.duration === undefined ? 1 : this.props.config.duration,
      animationLabel: this.props.config.animationLabel === undefined ? true : this.props.config.animationLabel,
      animationTitleFontStyle: this.props.config.animationTitleFontStyle === undefined ? false : this.props.config.animationTitleFontStyle,
      animationTitleFontWeight: this.props.config.animationTitleFontWeight === undefined ? false : this.props.config.animationTitleFontWeight,
      animationTitleFontSize: this.props.config.animationTitleFontSize === undefined ? 32 : this.props.config.animationTitleFontSize,
      animationTitleFontDecor: this.props.config.animationTitleFontDecor === undefined ? false : this.props.config.animationTitleFontDecor,
      animationTitleOversizeBehaviour: this.props.config.animationTitleOversizeBehaviour === undefined ? "fit" : this.props.config.animationTitleOversizeBehaviour,

      scrollbarX: this.props.config.scrollbarX === undefined ? false : this.props.config.scrollbarX,
      scrollbarY: this.props.config.scrollbarY === undefined ? false : this.props.config.scrollbarY,

      showTargets: this.props.config.showTargets === undefined ? false : this.props.config.showTargets,
      fillTargets: this.props.config.fillTargets === undefined ? "area" : this.props.config.fillTargets,
      targets: this.props.config.targets === undefined ? [{
        color: "#FF0000",
        axis: "y"
      }] : this.props.config.targets,

      keyForTitleSize: null,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours] ? InsightsConfig.Palettes[nextProps.config.colours] : nextProps.config.colours,
        backgroundColor: nextProps.config.backgroundColor,
        colours: nextProps.config.colours,

        height: nextProps.config.height,
        width: nextProps.config.width,

        maxPointSize: nextProps.config.maxPointSize,
        minPointSize: nextProps.config.minPointSize,
        showHideButton: nextProps.config.showHideButton,

        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,

        xAxisAlignment: nextProps.config.xAxisAlignment === undefined ? "bottom" : nextProps.config.xAxisAlignment,
        xTitleFontStyle: nextProps.config.xTitleFontStyle === undefined ? false : nextProps.config.xTitleFontStyle,
        xTitleFontWeight: nextProps.config.xTitleFontWeight === undefined ? false : nextProps.config.xTitleFontWeight,
        xTitleFontSize: nextProps.config.xTitleFontSize === undefined ? 12 : nextProps.config.xTitleFontSize,
        xTitleTextDecor: nextProps.config.xTitleTextDecor === undefined ? false : nextProps.config.xTitleTextDecor,

        yAxisAlignment: nextProps.config.yAxisAlignment === undefined ? "left" : nextProps.config.yAxisAlignment,
        yTitleFontStyle: nextProps.config.yTitleFontStyle === undefined ? false : nextProps.config.yTitleFontStyle,
        yTitleFontWeight: nextProps.config.yTitleFontWeight === undefined ? false : nextProps.config.yTitleFontWeight,
        yTitleFontSize: nextProps.config.yTitleFontSize === undefined ? 12 : nextProps.config.yTitleFontSize,
        yTitleTextDecor: nextProps.config.yTitleTextDecor === undefined ? false : nextProps.config.yTitleTextDecor,

        lr: nextProps.config.lr === undefined ? false : nextProps.config.lr,
        legend: nextProps.config.legend === undefined ? true : nextProps.config.legend,
        condFormat: nextProps.config.condFormat === undefined ? true : nextProps.config.condFormat,

        duration: nextProps.config.duration === undefined ? 1 : nextProps.config.duration,
        animationLabel: nextProps.config.animationLabel === undefined ? true : nextProps.config.animationLabel,
        animationTitleFontStyle: nextProps.config.animationTitleFontStyle === undefined ? false : nextProps.config.animationTitleFontStyle,
        animationTitleFontWeight: nextProps.config.animationTitleFontWeight === undefined ? false : nextProps.config.animationTitleFontWeight,
        animationTitleFontSize: nextProps.config.animationTitleFontSize === undefined ? 32 : nextProps.config.animationTitleFontSize,
        animationTitleFontDecor: nextProps.config.animationTitleFontDecor === undefined ? false : nextProps.config.animationTitleFontDecor,
        animationTitleOversizeBehaviour: nextProps.config.animationTitleOversizeBehaviour === undefined ? "fit" : nextProps.config.animationTitleOversizeBehaviour,

        scrollbarX: nextProps.config.scrollbarX === undefined ? false : nextProps.config.scrollbarX,
        scrollbarY: nextProps.config.scrollbarY === undefined ? false : nextProps.config.scrollbarY,

        showTargets: nextProps.config.showTargets === undefined ? false : nextProps.config.showTargets,
        fillTargets: nextProps.config.fillTargets === undefined ? "area" : nextProps.config.fillTargets,
        targets: nextProps.config.targets === undefined ? [{
          color: "#FF0000",
          axis: "y"
        }] : nextProps.config.targets,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,
      });
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  /**
   * Sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
   */
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  /**
   * Changes the visibility of title font size change button
   */
  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  /**
   * Timer for inputs
   */
  inputTimer;

  /**
   * Changes the value of text attributes in ScatterChart plugin use handleChange
   */
  handleChange = async e => {
    clearTimeout(this.inputTimer);

    let type = e.target.id
    let value = e.target.value

    this.setState({
      ...this.state,
      [type]: value
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: type, value: value });
    }, 500);
  };

  /**
   * Checks whether checkbox is checked or not in ScatterChart plugin use handleCheck
   */
  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Updates state attributes in ScatterChart plugin
   */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Changes the icon attribute in ScatterChart plugin use iconOnChange
   */
  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  /**
   * Changes the title attribute in ScatterChart plugin use titleAlignOnChange
   */
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Changes the color attribute in ScatterChart plugin use colorOnChange
   */
  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  /**
   * Changes the background colour attribute in ScatterChart plugin use backgroundColorOnChange
   */
  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in ScatterChart plugin
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  /**
   * Changes the colours attribute in ScatterChart plugin use coloursOnChange
   * 
   * @param {*} value 
   */
  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };

  /**
   * Changes the animation title oversize behaviour
   * 
   * @param {*} value 
   */
  handleOversizeBehaviour = async value => {
    clearTimeout(this.inputTimer);

    this.setState({
      ...this.state,
      animationTitleOversizeBehaviour: value
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "animationTitleOversizeBehaviour", value: value });
    }, 500);
  }

  /**
   * Changes x axis alignment in ScatterChart plugin use handleXAxisAlignment
   * 
   * @param {*} value 
   */
  handleXAxisAlignment = async value => {
    clearTimeout(this.inputTimer);

    this.setState({
      ...this.state,
      xAxisAlignment: value
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "xAxisAlignment", value: value });
    }, 500);
  }

  /**
   * Changes y axis alignment in ScatterChart plugin use 
   * 
   * @param {*} value 
   */
  handleYAxisAlignment = async value => {
    clearTimeout(this.inputTimer);

    this.setState({
      ...this.state,
      yAxisAlignment: value
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "yAxisAlignment", value: value });
    }, 500);
  }

  /**
   * Changes x axis scrollbar in ScatterChart plugin use handleScrollbarX
   * 
   * @param {*} value 
   */
  handleScrollbarX = async value => {
    clearTimeout(this.inputTimer);

    this.setState({
      ...this.state,
      scrollbarX: value
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "scrollbarX", value: value });
    }, 500);
  }

  /**
   * Changes y axis scrollbar in ScatterChart plugin use handleScrollbarY
   * 
   * @param {*} value 
   */
  handleScrollbarY = async value => {
    clearTimeout(this.inputTimer);

    this.setState({
      ...this.state,
      scrollbarY: value
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "scrollbarY", value: value });
    }, 500);
  }

  /**
   * Changes fill targets in ScatterChart plugin use handleFillTargets
   * 
   * @param {*} value 
   */
  handleFillTargets = async value => {
    clearTimeout(this.inputTimer);

    this.setState({
      ...this.state,
      fillTargets: value
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "fillTargets", value: value });
    }, 500);
  }

  /**
   * Updates target list
   * 
   * @param {*} targets 
   */
  updateTargets = targets => {
    this.setState({
      ...this.state,
      targets: targets
    });

    this.inputTimer = setTimeout(() => {
      this.setConfigToPlugin({ type: "targets", value: targets });
    }, 500);
  }

  /**
   * Sets selected target's start value
   */
  setTargetStartValue = async (value, index) => {
    clearTimeout(this.inputTimer);

    let targets = deepCopy(this.state.targets);

    targets[index].startValue = value;

    this.updateTargets(targets);
  }

  /**
   * Sets selected target's end value
   * 
   * @param {*} value 
   * @param {*} index 
   */
  setTargetEndValue = async (value, index) => {
    clearTimeout(this.inputTimer);

    let targets = deepCopy(this.state.targets);

    targets[index].endValue = value;

    this.updateTargets(targets);
  }

  /**
   * Sets selected target's name
   */
  setTargetName = async (value, index) => {
    clearTimeout(this.inputTimer);

    let targets = deepCopy(this.state.targets);

    targets[index].name = value;

    this.updateTargets(targets);
  }

  /**
   * Sets selected target's color
   */
  setTargetColor = async (index, color) => {
    clearTimeout(this.inputTimer);

    let targets = deepCopy(this.state.targets);

    targets[index].color = color;

    this.updateTargets(targets);
  };

  /**
   * Checks the last element of values array is filled
   */
  allowedToNewTarget = () => {
    let targets = this.state.targets;

    let last = targets[targets.length - 1];

    return last && (last.name && (last.startValue || last.endValue) && last.axis && last.color) ? true : false
  }

  /**
   * Adds an empty target object to targets array
   */
  addTarget = async (index = undefined) => {
    clearTimeout(this.inputTimer);

    if (!this.allowedToNewTarget()) {
      return;
    }

    let targets = deepCopy(this.state.targets);
    let emptyTarget = {
      color: "#FF0000",
      axis: "y"
    };

    if (index !== undefined) {
      targets.splice(index + 1, 0, emptyTarget);
    } else {
      targets.push(emptyTarget);
    }

    this.updateTargets(targets);
  }

  /**
   * Removes selected target object from the targets array
   */
  removeTarget = async (index) => {
    clearTimeout(this.inputTimer);

    let targets = deepCopy(this.state.targets);

    targets.splice(index, 1);

    if (targets.length === 0) {
      targets.push({
        color: "#FF0000",
        axis: "y"
      });
    }

    this.updateTargets(targets);
  }

  /**
   * Toggle target color picker visible
   */
  setTargetColorVisible = (index) => {
    this.setState({
      ...this.state,
      selectedTarget: index,
      targetColorVisible: index !== null ? true : false
    });
  }

  /**
   * Set axis of the selected target
   */
  handleTargetAxis = async (value, index) => {
    clearTimeout(this.inputTimer);

    let targets = deepCopy(this.state.targets);

    targets[index].axis = value;

    this.updateTargets(targets);
  }

  /**
   * Value on press enter handler for targets
   */
  targetOnPressEnter = (type, index) => {
    switch (type) {
      case "name":
        let startInput = document.getElementById(`targetStart-${index}`);

        startInput.focus();

        break;

      case "start":
        let endInput = document.getElementById(`targetEnd-${index}`);

        endInput.focus();

        break;

      case "end":
        if (this.state.targets.length - 1 === index) {
          this.addTarget();
        } else {
          let nextNameInput = document.getElementById(`targetName-${index + 1}`);

          nextNameInput.focus();
        }

        break;

      default:
        break;
    }
  }

  render() {
    let allowedToNewTarget = this.allowedToNewTarget();

    return (
      <div style={{ color: "#505050" }}>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Palette")}
            </Text>
          </Col>
          <Col span={4} style={{ paddingRight: "10px" }}>
            <Select
              id={"colours"}
              defaultValue={this.state.colours}
              value={this.state.colours}
              style={{ width: "100%" }}
              onChange={this.coloursOnChange}>
              <Option value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
              <Option value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
              <Option value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
              <Option value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
              <Option value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
            </Select>
          </Col>
          <Col span={8}>
            <ThemePalette
              colours={this.state.paletteColours}
              update={this.setConfigToPlugin}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
            </Text>
          </Col>
          <Col span={12}>
            <Button
              onClick={this.backgroundColorChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.backgroundColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.backgroundColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.backgroundColor}
                onChange={this.backgroundColorOnChange}
                id={"backgroundColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Chart")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.MaxPointSize")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              min={this.props.config.minPointSize}
              id={"maxPointSize"}
              defaultValue={this.state.maxPointSize}
              value={this.state.maxPointSize}
              formatter={value => `${value}`.replace(/[^0-9]/g, '')}
              parser={value => `${value}`.replace(/[^0-9]/g, '')}
              onChange={(value) => value ? this.handleChange({ target: { id: "maxPointSize", value: value ? value : 0 } }) : null}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.MinPointSize")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"minPointSize"}
              min={0}
              max={this.props.config.maxPointSize}
              defaultValue={this.state.minPointSize}
              value={this.state.minPointSize}
              formatter={value => `${value}`.replace(/[^0-9]/g, '')}
              parser={value => `${value}`.replace(/[^0-9]/g, '')}
              onChange={(value) => value ? this.handleChange({ target: { id: "minPointSize", value: value ? value : 0 } }) : null}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.LR")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"lr"}
              defaultChecked={this.state.lr}
              checked={this.state.lr}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Legend")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"legend"}
              defaultChecked={this.state.legend}
              checked={this.state.legend}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"condFormat"}
              defaultChecked={this.state.condFormat}
              checked={this.state.condFormat}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Animation")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FieldTimeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AnimationDuration")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"duration"}
              defaultValue={this.state.duration}
              value={this.state.duration}
              style={{ width: "100%" }}
              min={0}
              step={0.1}
              formatter={value => `${value}`.replace(/[^0-9.]/g, '')}
              parser={value => `${value}`.replace(/[^0-9.]/g, '')}
              onChange={(value) => value ? this.handleChange({ target: { id: "duration", value: Number(value) ? value : 0 } }) : null}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AnimationLabel")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"animationLabel"}
              defaultChecked={this.state.animationLabel}
              checked={this.state.animationLabel}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col
            style={{ textAlign: "center" }}
            span={3}
          >
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col
            style={{ textAlign: "left" }}
            span={9}
          >
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.LabelFontStyle")}
            </Text>
          </Col>
          <Col style={{ textAlign: "left" }} span={12}>
            {this.state.animationTitleFontWeight !== undefined ? (
              <span>
                <Button
                  onClick={() => this.handleChange({
                    target: {
                      id: "animationTitleFontWeight",
                      value: !this.state.animationTitleFontWeight
                    }
                  })}
                  style={{
                    backgroundColor: this.state.animationTitleFontWeight ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <BoldOutlined
                    style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                  />
                </Button>
              </span>
            ) : null}

            {this.state.animationTitleFontStyle !== undefined ? (
              <span>
                <Button
                  onClick={() => this.handleChange({
                    target: {
                      id: "animationTitleFontStyle",
                      value: !this.state.animationTitleFontStyle
                    }
                  })}
                  style={{
                    marginLeft: "20px",
                    backgroundColor: this.state.animationTitleFontStyle ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <ItalicOutlined
                    style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                  />
                </Button>
              </span>
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.LabelFontSize")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"animationTitleFontSize"}
              value={this.state.animationTitleFontSize}
              min={0}
              formatter={value => `${value}`.replace(/[^0-9]/g, '')}
              parser={value => `${value}`.replace(/[^0-9]/g, '')}
              onChange={(value) => value ? this.handleChange({ target: { id: "animationTitleFontSize", value: value ? value : 0 } }) : null}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AnimationOverSizedBehaviour")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"animationTitleOversizeBehaviour"}
              defaultValue={this.state.animationTitleOversizeBehaviour}
              value={this.state.animationTitleOversizeBehaviour}
              style={{ width: "100%" }}
              onChange={this.handleOversizeBehaviour}>
              <Option value="fit">{i18n.t("Dashboard.Configuration.Fields.OverSizedFit")}</Option>
              <Option value="truncate">{i18n.t("Dashboard.Configuration.Fields.OverSizedTruncate")}</Option>
              <Option value="wrap">{i18n.t("Dashboard.Configuration.Fields.OverSizedWrap")}</Option>
              <Option value="wrap-no-break">{i18n.t("Dashboard.Configuration.Fields.OverSizedWrapNoBreak")}</Option>
              <Option value="none">{i18n.t("Dashboard.Configuration.Fields.OverSizedNone")}</Option>
            </Select>
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Scrollbars")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnWidthOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ScrollbarX")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"scrollbarX"}
              defaultChecked={this.state.scrollbarX}
              checked={this.state.scrollbarX}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnHeightOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ScrollbarY")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"scrollbarY"}
              defaultChecked={this.state.scrollbarY}
              checked={this.state.scrollbarY}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.XAxis")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnHeightOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Alignment")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"xAxisAlignment"}
              defaultValue={this.state.xAxisAlignment}
              value={this.state.xAxisAlignment}
              style={{ width: "100%" }}
              onChange={this.handleXAxisAlignment}>
              <Option value="top">{i18n.t("Dashboard.Configuration.Fields.Top")}</Option>
              <Option value="bottom">{i18n.t("Dashboard.Configuration.Fields.Bottom")}</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col
            style={{ textAlign: "center" }}
            span={3}
          >
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col
            style={{ textAlign: "left" }}
            span={9}
          >
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.FontStyle")}
            </Text>
          </Col>
          <Col style={{ textAlign: "left" }} span={12}>
            {this.state.xTitleFontWeight !== undefined ? (
              <span>
                <Button
                  onClick={() => this.handleChange({
                    target: {
                      id: "xTitleFontWeight",
                      value: !this.state.xTitleFontWeight
                    }
                  })}
                  style={{
                    backgroundColor: this.state.xTitleFontWeight === true ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <BoldOutlined
                    style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                  />
                </Button>
              </span>
            ) : null}

            {this.state.xTitleFontStyle !== undefined ? (
              <span>
                <Button
                  onClick={() => this.handleChange({
                    target: {
                      id: "xTitleFontStyle",
                      value: !this.state.xTitleFontStyle
                    }
                  })}
                  style={{
                    marginLeft: "20px",
                    backgroundColor: this.state.xTitleFontStyle ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <ItalicOutlined
                    style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                  />
                </Button>
              </span>
            ) : null}

            {this.state.xTitleTextDecor !== undefined ? (
              <span>
                <Button
                  onClick={() => this.handleChange({
                    target: {
                      id: "xTitleTextDecor",
                      value: !this.state.xTitleTextDecor
                    }
                  })}
                  style={{
                    marginLeft: "20px",
                    backgroundColor: this.state.xTitleTextDecor ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <UnderlineOutlined
                    style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                  />
                </Button>
              </span>
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.FontSize")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"xTitleFontSize"}
              value={this.state.xTitleFontSize}
              min={0}
              formatter={value => `${value}`.replace(/[^0-9]/g, '')}
              parser={value => `${value}`.replace(/[^0-9]/g, '')}
              onChange={(value) => value ? this.handleChange({ target: { id: "xTitleFontSize", value: value ? value : 0 } }) : null}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.YAxis")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <ColumnWidthOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Alignment")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              id={"yAxisAlignment"}
              defaultValue={this.state.yAxisAlignment}
              value={this.state.yAxisAlignment}
              style={{ width: "100%" }}
              onChange={this.handleYAxisAlignment}
            >
              <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
              <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col
            style={{ textAlign: "center" }}
            span={3}
          >
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col
            style={{ textAlign: "left" }}
            span={9}
          >
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.FontStyle")}
            </Text>
          </Col>
          <Col style={{ textAlign: "left" }} span={12}>
            {this.state.yTitleFontWeight !== undefined ? (
              <span>
                <Button
                  onClick={() => this.handleChange({
                    target: {
                      id: "yTitleFontWeight",
                      value: !this.state.yTitleFontWeight
                    }
                  })}
                  style={{
                    backgroundColor: this.state.yTitleFontWeight === true ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <BoldOutlined
                    style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                  />
                </Button>
              </span>
            ) : null}

            {this.state.yTitleFontStyle !== undefined ? (
              <span>
                <Button
                  onClick={() => this.handleChange({
                    target: {
                      id: "yTitleFontStyle",
                      value: !this.state.yTitleFontStyle
                    }
                  })}
                  style={{
                    marginLeft: "20px",
                    backgroundColor: this.state.yTitleFontStyle ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <ItalicOutlined
                    style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                  />
                </Button>
              </span>
            ) : null}

            {this.state.yTitleTextDecor !== undefined ? (
              <span>
                <Button
                  onClick={() => this.handleChange({
                    target: {
                      id: "yTitleTextDecor",
                      value: !this.state.yTitleTextDecor
                    }
                  })}
                  style={{
                    marginLeft: "20px",
                    backgroundColor: this.state.yTitleTextDecor ? "#9ed3ff" : "initial",
                    color: "black",
                    borderColor: "lightgrey",
                  }}
                >
                  <UnderlineOutlined
                    style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                  />
                </Button>
              </span>
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.FontSize")}
            </Text>
          </Col>
          <Col span={12}>
            <InputNumber
              id={"yTitleFontSize"}
              value={this.state.yTitleFontSize}
              min={0}
              formatter={value => `${value}`.replace(/[^0-9]/g, '')}
              parser={value => `${value}`.replace(/[^0-9]/g, '')}
              onChange={(value) => value ? this.handleChange({ target: { id: "yTitleFontSize", value: value ? value : 0 } }) : null}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Targets")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowTargets")}
            </Text>
          </Col>
          <Col span={12}>
            <Row>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"showTargets"}
                defaultChecked={this.state.showTargets}
                checked={this.state.showTargets}
                onChange={this.handleCheck}
              />
            </Row>
          </Col>
        </Row>
        {
          this.state.showTargets ? (
            <>
              <Row style={{ marginBottom: "5px" }}>
                <Col style={{ textAlign: "center" }} span={3}>
                  <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={9}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.ColorFill")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Row>
                    <Select
                      id={"fillTargets"}
                      defaultValue={this.state.fillTargets}
                      value={this.state.fillTargets}
                      style={{ width: "100%" }}
                      onChange={this.handleFillTargets}
                    >
                      <Option value={false}>{i18n.t("Dashboard.Configuration.Fields.NoFill")}</Option>
                      <Option value="area">{i18n.t("Dashboard.Configuration.Fields.FillArea")}</Option>
                      <Option value="bubbles">{i18n.t("Dashboard.Configuration.Fields.FillBubbles")}</Option>
                      <Option value="both">{i18n.t("Dashboard.Configuration.Fields.Both")}</Option>
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col style={{ textAlign: "center" }} span={3}>
                  <AimOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={9}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.Targets")}
                  </Text>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={3}></Col>
                <Col span={21}>
                  {
                    this.state.targets.map((target, index) => (
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "4px"

                        }}
                      >
                        <Col span={5} style={{ paddingRight: "4px" }}>
                          <Input
                            id={`targetName-${index}`}
                            placeholder={i18n.t("Dashboard.Configuration.Fields.Label")}
                            value={target.name}
                            onChange={(event) => this.setTargetName(event.target.value || undefined, index)}
                            onPressEnter={() => this.targetOnPressEnter("name", index)}
                          />
                        </Col>
                        <Col span={5} style={{ padding: "2px 2px" }}>
                          <Input
                            id={`targetStart-${index}`}
                            placeholder={i18n.t("Dashboard.Configuration.Fields.StartLimit")}
                            value={target.startValue}
                            type={"number"}
                            onChange={(event) => this.setTargetStartValue(event.target.value || undefined, index)}
                            onPressEnter={() => this.targetOnPressEnter("start", index)}
                          />
                        </Col>
                        <Col span={5} style={{ padding: "2px 2px" }}>
                          <Input
                            id={`targetEnd-${index}`}
                            placeholder={i18n.t("Dashboard.Configuration.Fields.EndLimit")}
                            value={target.endValue}
                            type={"number"}
                            onChange={(event) => this.setTargetEndValue(event.target.value || undefined, index)}
                            onPressEnter={() => this.targetOnPressEnter("end", index)}
                          />
                        </Col>
                        <Col span={5} style={{ padding: "2px 2px" }}>
                          <Select
                            id={"targetAxis"}
                            placeholder={i18n.t("Dashboard.Configuration.Fields.Axis")}
                            defaultValue={target.axis}
                            value={target.axis}
                            style={{ width: "100%" }}
                            onChange={(value) => this.handleTargetAxis(value || undefined, index)}
                          >
                            <Option value="x">{i18n.t("Dashboard.Configuration.Fields.XAxis")}</Option>
                            <Option value="y">{i18n.t("Dashboard.Configuration.Fields.YAxis")}</Option>
                          </Select>
                        </Col>
                        <Col span={3} style={{ padding: "0 2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <Tooltip
                            placement="right"
                            overlayClassName="scatter-range-tooltip"
                            title={
                              !this.state.targetColorVisible || this.state.selectedTarget !== index
                                ? i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")
                                : null
                            }
                          >
                            <div
                              style={{
                                height: "32px",
                                width: "50px",
                                border: "1px solid #d7d6d6",
                                boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                                borderRadius: "4px",
                                cursor: "pointer",
                                backgroundColor: target.color || "#FF0000",
                              }}
                              onClick={() => this.setTargetColorVisible(!this.state.targetColorVisible ? index : null)}
                            />
                          </Tooltip>
                          {this.state.targetColorVisible && this.state.selectedTarget === index ? (
                            <div className={"palette-colour-picker"}>
                              <div className={"close-colour-palette"}>
                                <CloseOutlined onClick={() => this.setTargetColorVisible(!this.state.targetColorVisible ? index : null)} />
                              </div>
                              <ColorPickerPanel
                                alpha={100}
                                color={target.color}
                                onChange={obj => this.setTargetColor(index, obj.color)}

                                id={"perColor"}
                                style={{ marginTop: "10px" }}
                              />
                            </div>
                          ) : null}
                        </Col>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "right",
                            width: "100%"
                          }}
                        >
                          <CloseCircleFilled
                            onClick={() => this.removeTarget(index)}
                            style={{
                              fontSize: "18px",
                              color: this.state.targets.length > 1 || allowedToNewTarget ? "rgb(249, 47, 47)" : "#dadada",
                              cursor: this.state.targets.length > 1 || allowedToNewTarget ? "pointer" : "default"
                            }}
                          />
                        </div>
                      </Row>
                    ))
                  }
                  <Row>
                    {
                      allowedToNewTarget ? (
                        <div style={{ margin: "4px" }}>
                          <PlusOutlined
                            className={"circle-icon"}
                            onClick={() => this.addTarget()}
                          />
                        </div>
                      ) : null
                    }
                  </Row>
                </Col>
              </Row>
            </>
          ) : null
        }

        <TitleSettingsOfPlugin
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          keyForTitleSize={this.state.keyForTitleSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          changedTitleFontSize={this.state.changedTitleFontSize}
          handleChangeBothOfValues={this.handleChangeBothOfValues}
        />
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip 
              title={
                <>
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} /> 
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
