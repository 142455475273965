import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import SunburstConfiguration from "./SunburstConfiguration";
import SunburstData from "./SunburstData";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import {
  renderConfig,
  renderData,
  renderNavigation,
  renderConditionalFormatting,
} from "../PluginsCommonComponents";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import { renderContent } from "../renderContent";
import { getFormattedValue } from "../format";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import NavigationContent from "../../../Navigation/NavigationContent";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { findColumnMapHasAnyColumn } from "../../../ConditionalFormatting/RenderConditionalListName"
import { compare } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { calculatePluginInlineHeight } from "../../../DrillDown/PluginHeightWithDrilldown";
import { calculateLegendTitlePosition } from "../../../GeneralComponents/Legend/CalculateLegendPosition";
import { convertHTMLRuletoRule } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"ay_adi":"Aralık","level":[{"name":"ay_adi","value":"Aralık"}],"measure":27535217},{"ay_adi":"Ağustos","level":[{"name":"ay_adi","value":"Ağustos"}],"measure":47300238},{"ay_adi":"Ekim","level":[{"name":"ay_adi","value":"Ekim"}],"measure":38413038},{"ay_adi":"Eylül","level":[{"name":"ay_adi","value":"Eylül"}],"measure":42060547},{"ay_adi":"Haziran","level":[{"name":"ay_adi","value":"Haziran"}],"measure":45743885},{"ay_adi":"Kasım","level":[{"name":"ay_adi","value":"Kasım"}],"measure":29217440},{"ay_adi":"Mart","level":[{"name":"ay_adi","value":"Mart"}],"measure":29395781},{"ay_adi":"Mayıs","level":[{"name":"ay_adi","value":"Mayıs"}],"measure":38247130},{"ay_adi":"Nisan","level":[{"name":"ay_adi","value":"Nisan"}],"measure":34282854},{"ay_adi":"Ocak","level":[{"name":"ay_adi","value":"Ocak"}],"measure":28051103},{"ay_adi":"Temmuz","level":[{"name":"ay_adi","value":"Temmuz"}],"measure":48334798},{"ay_adi":"Şubat","level":[{"name":"ay_adi","value":"Şubat"}],"measure":26641311}]`
);
const columnMap = JSON.parse(
  `{"level":[{"Code":"\'ucus\'.\'ay_adi\'","Name":"ay_adi","DataType":"varchar","Table":"ucus","Measure":"none","ID":"ucus.ay_adi","SubjectArea":"BIG_DATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":true,"Type":"Column","Description":""}],"measure":{"Code":"\'ucus\'.\'yolcutoplam\'","Name":"yolcutoplam","DataType":"double","Table":"ucus","Measure":"sum(ucus.yolcutoplam)","ID":"ucus.yolcutoplam","SubjectArea":"BIG_DATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""}}`
);

// const columnMap = null;
const condFormats = [];
const filters = [];
const pluginName = "sunburst";

const description =
  "A multi-level pie chart that is useful for viewing hierarchical data.";

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "size",
    label: "Size",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc92"
  },
  {
    targetProperty: "condFormat",
    label: "CondFormat",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "legend",
    label: "Legend",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc193"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: {
      defaultValue: "Flat-UI"
    },
    desc: "desc93"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "titleAlign",
    label: "Title Align",
    inputType: "dropdown",
    inputOptions: {
      defaultValue: "center",
      multiSelect: false,
      values: ["center", "left", "right"]
    },
    desc: "desc94"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
];


// Highlight sections of the chart based on the input values from the interaction
const highlightSection = function (output, container) {
  if (output.length > 0) {
    var config = output[0].config;
    var columnMap = output[0].columnMap;
    var levelNames = columnMap.level.map(function (l) {
      return l.Name;
    });
    var chart = d3.select($(container)[0]);
    var paths = chart.selectAll("path.section");

    // Fade all the segments.
    chart.selectAll("path.section").style("opacity", 0.3);

    output.forEach(function (out) {
      if (out.col) {
        var depth = levelNames.indexOf(out.col.Name) + 1;
        paths
          .filter(function (d, i) {
            return d.depth == depth && out.values.indexOf(d.name) > -1;
          })
          .style("opacity", function (d, i) {
            // Then highlight only those that are an ancestor of the current segment.
            var ancestors = getAncestors(d);

            chart
              .selectAll("path")
              .filter(function (node) {
                return ancestors.indexOf(node) >= 0;
              })
              .style("opacity", 1);
          });
      }
    });
  }
};

const actions = [
  {
    trigger: "sectionClick",
    type: "click",
    name: "Tıklama - Bölüm",
    output: ["level"],
    description: "SectionClick2Desc"
  },
  {
    trigger: "sectionHover",
    type: "hover",
    name: "Hover - Bölüm",
    output: ["level"],
    description: "SectionHover3Desc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

/**
 * renders Sunburst plugin in Vispeahen V3
 */

const pluginConditionalFormatOptions = {
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe"
  }
};

const conditionalFormatColumnMap = new Set(["level", "measure"]);
const conditionalFormatTargetMap = new Set(["level"]);

export default class Sunburst extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    tempPlugin.highlightSection = highlightSection

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <SunburstConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getConditionalFormattingComponent = props => {
    let columnMap = this.props.plugin.columnMap
    
    // Eski silinen kolonlar id ile geldiği için comment yapılmıştır. #1112 issue. 
    // let hasColumnMapAnyColumn = findColumnMapHasAnyColumn(props.plugin.columnMap)
    // let isPluginNewPlugin = this.props.isNewPlugin ? true : false

    // if (hasColumnMapAnyColumn) {
    //   if (isPluginNewPlugin) {
    //     columnMap = props.plugin.columnMap
    //   } else {

    //     columnMap = this.props.columnMapWithLocationFieldName
    //   }
    // } else {
    //   columnMap = props.plugin.columnMap
    // }

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        pluginId={props.plugin.id}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
      />
    );
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <SunburstData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        navigations={props.navigations}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      level: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Level.Name"),
        type: "dim",
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Level.Desc"),
        multiple: true,
        data: []
      },
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        required: true,
        minimumColumnSize: 1,
        conditionalFormat: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  convertFormatConditionalFormatting = (condFormats, columns) => {
    let condFormatList = [];

    condFormats.map(condItem => {
      condItem.targetColumns.map(targetColumn => {
        let conditionalFormat = {};
        let columnsMap = new Map();
        columns.map(column => {
          columnsMap.set(column.uniqeColumnId, column);
        })

        conditionalFormat.RightRule = condItem.rule.rightRule.rule;
        conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
        conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName  === "{undefined}" ? convertHTMLRuletoRule(condItem.rule.leftRule).ruleColumnName : condItem.rule.leftRule.ruleColumnName;
        conditionalFormat.RightRuleColumnName = condItem.rule.rightRule.ruleColumnName
        conditionalFormat.Columns = columnsMap;
        conditionalFormat.TargetID = targetColumn.TargetID;
        conditionalFormat.TargetName = targetColumn.TargetName;
        conditionalFormat.Operator = condItem.rule.operator;
        conditionalFormat.id = condItem.id;

        conditionalFormat.Style = {
          background: { colour: condItem.options.backgroundColor }
        };
        condFormatList.push(conditionalFormat);
      });
    });

    return condFormatList;
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {    
    const convertColumnMapForConditionalFormat = (cmMap) => {
      let newColumnMap = []
      for (let i = 0; i < cmMap.level.length; i++) {
        newColumnMap.push(cmMap.level[i])
      }
      newColumnMap.push(cmMap.measure)
      return newColumnMap
    }
    if (condFormats !== undefined && typeof columnMap.level === 'object') {
      let convertedColumnMap = convertColumnMapForConditionalFormat(columnMap)
      condFormats = this.convertFormatConditionalFormatting(condFormats, convertedColumnMap);
    } else {
      condFormats = [];
    }

    let levelValues = []

    for (let i = 0; i < columnMap.level.length; i++) {
      levelValues.push(columnMap.level[i])
    }

    var THIS = this;

    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];
    let showCondForm = $('<div id="showCondForm" style="display:flex; position:relative; bottom:5px; flex-wrap:wrap;"></div>')

    container.style.background = config.backgroundColor;

    // Get colour function from unique array of all dimension attributes
    var uniqueDims = rmvpp.uniqueDims(data, columnMap);

    // Set width, height, radii
    var width = +config.size,
      height = +config.size;
    var radius = +config.size / 2;
    var totalSize = 0; // Updated later

    // Create chart container elements
    var chartContainer = d3
      .select(container)
      .append("div")
      .classed("sunburst-chart", true)
      .style("text-align", "center")
      .style("background", config.backgroundColor); //added bgColor style

    // Create tooltip
    $(container).append(showCondForm);
    var selector = $(chartContainer[0]).toArray();
    var tooltip = new rmvpp.Tooltip(chartContainer[0][0]);

    // Render chart SVG based on gridScope
    var chart;
    chart = chartContainer
      .append("svg")
      .attr("svgWidth", width)
      .attr("svgHeight", height)
      .attr("id", `svg-${divId}`)
      .style("width", "100%")
      .style("height", config.height - 15)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    let coloursWithConditional = new Map() //This is for legend color
    let firstDepthColour = new Map()
    let coloursWithAllColumns = new Map()

    if (condFormats.length > 0 && config.condFormat) {
      $(`#plugin-${divId}`).css("height", `${calculatePluginInlineHeight(divId)}`)

      addCondFormLegend();
    }

    function prepareConditionalLegends(conditionalLegendDiv) {
      //sets conditional format div's for find legend div
      conditionalLegendDiv.setAttribute("class", "apexcharts-legend-series");
      conditionalLegendDiv.setAttribute("style", "margin: 0px 5px;");
    }

    function prepareConditionalLegendMarkers(conditionalLegendMarkersDiv, d) {
      //sets conditional format legend marker's
      conditionalLegendMarkersDiv.setAttribute("class", "dot");
      conditionalLegendMarkersDiv.setAttribute(
        "style",
        "background: " +
        d.Style.colour +
        "; color:" +
        d.Style.colour +
        "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 10px; border-color: rgb(255, 255, 255); border-radius: 10px; display: inline-block; border: 1px solid white;"      );
    }

    function addCondFormLegend() {
      //adds conditional format legends to plugins bottom
      let usedCondFormatIds = new Set();

      condFormats.forEach(function (d, i) {
        if (usedCondFormatIds.has(d.id)) {
          return;
        } else {
          usedCondFormatIds.add(d.id);
        }

        let condLegend = document.createElement("div");
        prepareConditionalLegends(condLegend)

        let condLegendMarker = document.createElement("span");
        prepareConditionalLegendMarkers(condLegendMarker, d)

        let condLegendMarkerBackground = document.createElement("span");

        if (d.Style.background) {
          condLegendMarkerBackground.setAttribute("class", "dot");
          condLegendMarkerBackground.setAttribute(
            "style",
            "background: " +
            d.Style.background.colour +
            "; color:" +
            d.Style.background.colour +
            "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 10px; border-color: rgb(255, 255, 255); border-radius: 10px; display: inline-block; margin-left: 2px;  border: 1px solid white;  border: 1px solid white;"
          );
        }

        let condLegendText = document.createElement("span");
        condLegendText.setAttribute("class", "text");
        condLegendText.setAttribute(
          "style",
          "color: rgb(55, 61, 63); font-size: 12px; font-family: Helvetica, Arial, sans-serif;"
        );

        let ruleDescription = THIS.props.plugin.conditionalFormats[i]?.rule?.conditionalFormatRule;
        // if rule description is defined in text area, legend will use user input.

        let textNode = document.createTextNode(
          ruleDescription ? " " + ruleDescription  : " " + d.LeftRuleColumnName + " " + d.Operator + " " + d.RightRuleColumnName + ""
        );
        condLegendText.appendChild(textNode);

        condLegend.appendChild(condLegendMarkerBackground);
        condLegend.appendChild(condLegendText);

        showCondForm.append(condLegend);
      });
    }

    // D3 Layout algorithm used for sunburst
    var partition = d3.layout
      .partition()
      .size([2 * Math.PI, radius * radius])
      .value(function (d) {
        return +d.measure;
      });

    // SVG arcs based on partitioned nodes
    var arc = d3.svg
      .arc()
      .startAngle(function (d) {
        return d.x;
      })
      .endAngle(function (d) {
        return d.x + d.dx;
      })
      .innerRadius(function (d) {
        return Math.sqrt(d.y);
      })
      .outerRadius(function (d) {
        return Math.sqrt(d.y + d.dy);
      });

    // Build hierarchy from the flat data structure
    var nodeTree = { name: "root", children: [] };

    // Searches a node tree for a name at a given depth and adds child nodes
    function addChildNode(nodeChildren, name, datum) {
      var nodeNames = nodeChildren.map(function (node) {
        return node.name;
      });

      // Find which node child contains the value
      var idx = nodeNames.indexOf(name);
      if (idx == -1) {
        // Insert new node if not found
        let newIdx = nodeChildren.push({
          name: name,
          children: [],
          datum: datum
        });
        return nodeChildren[newIdx - 1];
      } else {
        return nodeChildren[idx];
      }
    }

    // Adds leaf node, which has a name and value but no children
    function addLeafNode(nodeChildren, name, value, datum) {
      var node = { name: name, measure: value, datum: datum };
      nodeChildren.push(node);
    }

    let colourNames = []
    let uniqueColours = new Map()

    // Transform flat data output structure into hierarchical parent-child JSON object
    data.forEach(function (datum) {
      var nodes = nodeTree;
      datum.level.forEach(function (level, i) {

        if (i === 0 && !uniqueColours.has(level.value)) {
          colourNames.push(level.value)
          uniqueColours.set(level.value)
        }

        if (i < columnMap.level.length - 1) {
          if (level.value) {
            nodes = addChildNode(nodes.children, level.value, datum)
          }

        } else {
          // Handle leaf node
          addLeafNode(nodes.children, level.value, +datum.measure, datum);
        }
      });
    });

    let colour = rmvpp.colourScale(colourNames, config.colours);

    // Add legend
    if (config.legend) {
      let legend = new rmvpp.Legend(
        chart,
        colourNames,
        columnMap.level[0].Name,
        radius,
        null,
        true
      );
      legend.addColourKey(colourNames, colour);
      legend.repositionCircular();
    }

    let sunburstContainer = $("#" + divId)
    let holder = $("[index=" + divId + "]") //main div
    let sunburstWidth = holder.width() //Sunburst's width
    let sunburstHeight = holder.height() //Sunburst's height

    sunburstContainer.find(".legend").attr("transform", "translate(140, -140)")

    if (config.legend && sunburstWidth > sunburstHeight) {
      sunburstContainer.find("svg").children().attr("transform", "translate(100, 150)")
      sunburstContainer.find("svg").attr("viewBox", "0 0 300 330")
    } else if (config.legend && sunburstWidth < sunburstHeight) {
      sunburstContainer.find("svg").attr("viewBox", "0 0 450 330")
      sunburstContainer.find("svg").children().attr("transform", "translate(170, 150)")
    }

    $(sunburstContainer.find(".legend").children()).each(function(index) {
      let column = columnMap.level[0];

      if (index !== 0) {
        $(this).find("text").text(getFormattedValue(column, $(this).find("text").text()))
      }
    })

    const addAndSymbol = (val) => {
      let preparedValue = val

      if (typeof val === "string") {
        if (val.split(" ").length === 1) {
          preparedValue = val
        } else {
          preparedValue = val.split(" ").join("-")
        }
      }

      return preparedValue
    }

    let dataWithColumns = new Map()

    const getChildrenSunburstData = (obj, parentName) => {
      if (obj.children) {
        for (let i = 0; i < obj.children.length; i++) {
          let newParentName = `${parentName} ${addAndSymbol(obj.children[i].name)}`
          dataWithColumns.set(`${newParentName}`, obj.children[i].datum)
          getChildrenSunburstData(obj.children[i], newParentName, obj.children[i].datum)
        }
      }
    }

    for (let i = 0; i < nodeTree.children.length; i++) {
      if (nodeTree) {
        dataWithColumns.set(`${addAndSymbol(nodeTree.children[i].name)}`, nodeTree.children[i].datum)
        getChildrenSunburstData(nodeTree.children[i], `${addAndSymbol(nodeTree.children[i].name)}`)
      }
    }

    // For efficiency, filter nodes to keep only those large enough to see.
    var nodes = partition.nodes(nodeTree).filter(function (d) {
      return d.dx > 0.005; // 0.005 radians = 0.29 degrees
    });

    const returnColoursWithConditionalFormat = (name, currentColumn, depth, parentNode, filteredLevels) => {
      if (coloursWithConditional.has(currentColumn)) {
        return coloursWithConditional.get(currentColumn)
      }

      if (depth === 1) {
        firstDepthColour.set(name, colour(name))
      }

      if (firstDepthColour.has(parentNode) || firstDepthColour.has(filteredLevels.value)) {
        if (depth === 1 && firstDepthColour.has(filteredLevels.value)) {
          return firstDepthColour.get(filteredLevels.value)
        } else if (depth === 1 && firstDepthColour.has(parentNode)) {
          return firstDepthColour.get(parentNode)
        }

        if (firstDepthColour.get(filteredLevels.value)) {
          return rmvpp.reduceOpacity(firstDepthColour.get(filteredLevels.value), 0.15 * depth)
        } else if (firstDepthColour.get(parentNode)) {
          return rmvpp.reduceOpacity(firstDepthColour.get(parentNode), 0.15 * depth)
        } 
      }

      if (coloursWithAllColumns.has(filteredLevels.value)) {
        for (let i = $(`.${filteredLevels.value}`).length - 1; i >= 0; i--) {
          if (!$("#" + divId).find($(`.${filteredLevels.value}`)[i]).hasClass("dontColourise")) {
            $($("#" + divId).find(`.${filteredLevels.value}`)[i18n]).css("fill", coloursWithAllColumns.get(filteredLevels.value))
          } else {
            $($("#" + divId).find(`.${filteredLevels.value}`)[i]).css("fill", colour(filteredLevels.value))
          }
        }

        return coloursWithAllColumns.get(filteredLevels.value)
      } else if (coloursWithAllColumns.has(parentNode)) {
        for (let i = $(`.${parentNode}`).length - 1; i >= 0; i--) {
          if (!$("#" + divId).find($(`.${parentNode}`)[i]).hasClass("dontColourise")) {
            $($("#" + divId).find(`.${parentNode}`)[i18n]).css("fill", coloursWithAllColumns.get(parentNode))
          } else {
            $($("#" + divId).find(`.${parentNode}`)[i]).css("fill", colour(parentNode))
          }
        }

        return coloursWithAllColumns.get(parentNode)
      }

      return colour(name)
    }

    var path = chart
      .data([nodeTree])
      .selectAll("path")
      .data(nodes)
      .enter()
      .append("svg:path")
      .attr("display", function (d) {
        return d.depth ? null : "none";
      })
      .attr("d", arc)
      .attr("fill-rule", "evenodd")
      .attr("class", function (d) {
        let classNameArr = []
        let currentColumnsArray = []

        for (let i = 0; i < d.depth; i++) {
          if (isNaN(+d.datum.level[i].value)) {
            if (d.datum.level[i].value.split(" ").length === 1) {
              classNameArr.push(addAndSymbol(d.datum.level[i].value))
            } else {
              classNameArr.push(addAndSymbol(d.datum.level[i].value))
            }
          } else {
            classNameArr.push(addAndSymbol(d.datum.level[i].value))
          }

          currentColumnsArray.push(`${d.datum.level[i].name}:${d.datum.level[i].value}`)
        }

        if (classNameArr.length === 1) {
          return classNameArr[0]
        } else {
          let joinedClasses = classNameArr.join(" ")
          $(this).attr("currentColumn", joinedClasses)
          return joinedClasses
        }
      })
      .style("fill", function (d) {
        let classNameArr = []
        let firstColumn
        let depthsByColumns = new Map()
        let measureName = columnMap.measure.Name
        let objectWithValue = {}
        objectWithValue[measureName] = d.value

        if (d.datum) {
          for (let i = 0; i < d.datum.level.length; i++) {
            if (i === 0) firstColumn = d.datum.level[0].name
            depthsByColumns.set(d.datum.level[i].name, i)
          }
        }

        const compareDataAndPluginDObject = (data, pluginD) => {
          let dataObj = {}

          for (let i = 0; i < levelValues.length; i++) {
            if (pluginD.depth > i) {
              dataObj[levelValues[i].Name] = data[levelValues[i].Name]
            }
          }

          dataObj["target"] = levelValues[pluginD.depth - 1].aliasName
          dataObj[columnMap.measure.Name] = pluginD.value
          return dataObj
        }

        for (let i = 0; i < d.depth; i++) {
          classNameArr.push(addAndSymbol(d.datum.level[i].value))
        }

        if (d.name !== "root") {
          let joinedClassNames = classNameArr.join(" ")
          let currentColumn = dataWithColumns.get(joinedClassNames)
          let comparedObject = compareDataAndPluginDObject(currentColumn, d)

          for (let i = 0; condFormats.length - 1 >= i; i++) {
            let comparedData = compare(comparedObject, condFormats[i])
            let leftRuleColumn = condFormats[i].LeftRule.replaceAll("_", "-").split("{")[1]?.split("}")[0] ? condFormats[i].LeftRule.replaceAll("_", "-").split("{")[1]?.split("}")[0] : condFormats[i].LeftRule
            let rightRuleColumn = condFormats[i].RightRule.replaceAll("_", "-").split("{")[1]?.split("}")[0] ? condFormats[i].RightRule.replaceAll("_", "-").split("{")[1]?.split("}")[0] : condFormats[i].RightRule

            const isRuleColumnDefined = (comparedObject[condFormats[i].Columns.get(leftRuleColumn)?.Name] !== undefined || comparedObject[condFormats[i].Columns.get(rightRuleColumn)?.Name] !== undefined);
            const isRuleColumnTarget = (condFormats[i].Columns.get(leftRuleColumn)?.Name === comparedObject.target || condFormats[i].Columns.get(rightRuleColumn)?.Name === comparedObject.target);

            if (d.depth === 1) {
              firstDepthColour.set(comparedObject[levelValues[0].Name], condFormats[i].Style.background.colour)
            }

            if (comparedData.status === true) {
              if (condFormats[i].TargetName === comparedObject.target) {
                if (d.depth === 1) {
                  firstDepthColour.set(comparedObject[levelValues[0].Name], condFormats[i].Style.background.colour)
                  coloursWithConditional.set(comparedObject[levelValues[0].Name], condFormats[i].Style.background.colour)
                }
                coloursWithConditional.set(joinedClassNames, condFormats[i].Style.background.colour)
              } else if (condFormats[i].Columns.has(leftRuleColumn) || condFormats[i].Columns.has(rightRuleColumn)) {
                if (condFormats[i].TargetName === "AllColumns" && isRuleColumnDefined) {
                  coloursWithAllColumns.set(comparedObject[levelValues[0].Name], condFormats[i].Style.background.colour)
                  coloursWithConditional.set(joinedClassNames, condFormats[i].Style.background.colour)
                }
              }
            } else if (isRuleColumnTarget && condFormats[i].TargetName === "AllColumns") {
              $(this).addClass("dontColourise")
            }
          }

          let filteredLevels = d.datum.level.filter(d => d.name === firstColumn)[0]

          return returnColoursWithConditionalFormat(d.name, joinedClassNames, d.depth, d.datum[firstColumn], filteredLevels)
        }
      })
      .style("stroke", "#FFFFFF")
      .style("opacity", 1)
      .on("mouseover", function (d, i) {
        // Render tooltip message
        var dim = columnMap.level[d.depth - 1].Name;
        let column = columnMap.level.find(level => level.aliasName === dim);
        let name = column ? (getFormattedValue(column, d.name)) : d.name;
        var pct = ((+d.value / +totalSize) * 100).toFixed(1);
        var info = dim + ": <b>" + name + "</b><br/>";
        info += columnMap.measure.Name + ": <b>" + (getFormattedValue(columnMap.measure, +d.value)) + "</b></br>";
        info += pct + "%";
        tooltip.displayHTML(info, d3.event);

        // Fade all the segments.
        chart.selectAll("path").style("opacity", 0.3);

        // Then highlight only those that are an ancestor of the current segment.
        var ancestors = getAncestors(d);

        chart
          .selectAll("path")
          .filter(function (node) {
            return ancestors.indexOf(node) >= 0;
          })
          .style("opacity", 1);

        let mousePosition = { x: window.event.pageX, y: window.event.pageY }

        // Send mouseover event
        createTrigger(
          actions,
          stripColumnMap(d.depth),
          container,
          "sectionHover",
          stripDatum(d.datum, d.depth),
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition
        );
      })
      .on("mouseleave", function (d, i) {
        tooltip.hide();

        // Embolden in the sections
        chart.selectAll("path").style("opacity", 1);
      })
      .on("click", function (d, i) {
        let mousePosition = { x: window.event.pageX, y: window.event.pageY }

        createTrigger(
          actions,
          stripColumnMap(d.depth),
          container,
          "sectionClick",
          stripDatum(d.datum, d.depth),
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition
        );
      });

    totalSize = path.node().__data__.value;

    // Strip the datum of all the objects that are not applicable, based on the depth
    function stripDatum(datum, depth) {
      var newDatum = { level: [] };
      for (var i = 0; i < depth; i++) {
        var key = columnMap.level[i].Name;
        newDatum[key] = datum[key];
        newDatum.level.push(datum.level[i]);
      }
      return newDatum;
    }

    // Strip the columnMap of all the objects that are not applicable, based on the depth
    function stripColumnMap(depth) {
      var newColumnMap = { level: [], measure: columnMap.measure };
      for (var i = 0; i < depth; i++) {
        newColumnMap.level.push(columnMap.level[i]);
      }
      return newColumnMap;
    }
    
    // Arrange sun-burst plugin title for overflow plugin size and add tooltip if sunburst plugin has legend attribute
    if (this.props.plugin.config.legend) {
      calculateLegendTitlePosition(divId);
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let conditionalFormatComponent = null;
    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            this.props.plugin.conditionalFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
          {navigationComponent}
          {conditionalFormatComponent}
        </div>
      </>
    );
  }
}

function getAncestors(node) {
  var path = [];
  var current = node;
  while (current.parent) {
    path.unshift(current);
    current = current.parent;
  }
  return path;
}