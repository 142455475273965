import React, { Component } from "react";
import { Row, Col, Select, Divider } from "antd";
import Text from "../GeneralComponents/Text/Text";
import i18n from "../../Utils/i18next";

const { Option, OptGroup } = Select;

export default class TargetColumns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetColumns: [],
      targetColumnsIds: []
    };
  }

  componentDidMount() {
    if(this.props.defaultConditionalFormatColumn !== undefined && this.props.defaultConditionalFormatColumn !== null && Object.keys(this.props.defaultConditionalFormatColumn).length !== 0) {
      this.setState({
        targetColumns: [this.props.defaultConditionalFormatColumn],
        targetColumnsIds: [this.props.defaultConditionalFormatColumn.uniqeColumnId]
      })

      this.props.setTargetColumnForDefault([this.props.defaultConditionalFormatColumn])
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clickedApply === true) {
      this.props.getTargetColumns(this.state.targetColumns, "targetColumns");
    }
  }

  componentWillMount() {
    let targetColumnsIds = [];
    let targetColumns

    if (this.isTargetColumnsOnlyAllColumns() || this.props.isTargetColumnAllColumns) {
      targetColumnsIds.push("AllColumns");
      targetColumns = this.props.targetColumns

      if (this.props.isTargetColumnAllColumns) {
        let column = [{
          TargetName: "AllColumns",
          TargetID: "columns",
          displayName: "AllColumns"
        }];
        
        targetColumns = column
      }
    } else {
      this.props.targetColumns.map(column => {
        targetColumnsIds.push(column.uniqeColumnId);
      });

      targetColumns = this.props.targetColumns
    }

    this.setState({
      targetColumns: [...targetColumns],
      targetColumnsIds: [...targetColumnsIds]
    });
  }

  /** all columns check */
  isTargetColumnsOnlyAllColumns = () => {
    return (
      this.props.targetColumns.length == 1 &&
      this.props.targetColumns[0].TargetName === "AllColumns"
    );
  };

  /** handle target columns change */
  targetColumnsOnChange = (value, columns, option) => {
    // let targetColumns = columns.filter(c => value.includes(c.uniqeColumnId));
    let targetColumns = [];

    if (value[value.length - 1] == "AllColumns") {
      let column = {
        TargetName: "AllColumns",
        TargetID: "columns",
        displayName: "AllColumns"
      };

      targetColumns.push(column);

      this.setState({
        targetColumnsIds: ["AllColumns"],
        targetColumns: targetColumns
      });
    } else {
      let allColumnsIndex = value.indexOf("AllColumns");

      if (allColumnsIndex != -1) {
        //all columns not exist in value array
        value.splice(allColumnsIndex, 1);
      }

      columns.map((c, index) => {
        if (value.includes(c.uniqeColumnId)) {
          c.TargetID = "columns" + index;
          c.TargetName = c.name;
          targetColumns.push(c);
        }
      });

      this.setState({
        targetColumnsIds: value,
        targetColumns: targetColumns
      });
    }
  };

  render() {
    let columns = this.props.columnsToBeUsed;
    let isDefaultConditionalFormatAvailable = this.props.defaultConditionalFormatColumn !== null && this.props.defaultConditionalFormatColumn !== undefined && Object.keys(this.props.defaultConditionalFormatColumn).length !== 0
    let selectedTargetColumn = isDefaultConditionalFormatAvailable 
      ? [this.props.defaultConditionalFormatColumn.uniqeColumnId] 
      : this.state.targetColumnsIds === null || this.state.targetColumnsIds === ""
        ? []
        : this.state.targetColumnsIds
    return (
      <Row className="target-columns">
        <Col span={24}>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.ConditionalFormatting.TargetColumns")}
          </Divider>
          <Text
            type="span"
            style={{
              color: "rgb(162, 162, 162)",
              fontSize: "14px",
              display: "block",
              marginBottom: "8px"
            }}
          >
            {i18n.t("Dashboard.ConditionalFormatting.TargetColumnsDescription")}
          </Text>
        </Col>
        <Col span={24}>
          <Select
            key={"conditionalFormatTargetColumns"}
            mode={"multiple"}
            style={{ width: "100%" }}
            showSearch
            defaultValue={selectedTargetColumn}
            disabled={this.props.isLockedTargetValue ? true : false}
            onChange={(value, option) =>
              this.targetColumnsOnChange(value, columns, option)
            }
            maxTagCount="responsive"
            value={
              selectedTargetColumn
            }
          >
            {this.props.isNecessaryAllColumns === false ? null : 
            <OptGroup label={i18n.t("AllColumns")}>
              <Option value="AllColumns" key="allColumns">
                {i18n.t("AllColumns")}
              </Option>
            </OptGroup>}
            <OptGroup label={i18n.t("Columns")}>
              {columns.map((d, i) => (
                this.props.conditionalFormatTargetMap.has(d.locationFieldName) || (d.uniqeColumnId || d.filterId) === selectedTargetColumn[0] ? 
                <Option value={d["uniqeColumnId"]} key={d["uniqeColumnId"]}>
                  {d["displayName"]}
                </Option> : null
              ))}
            </OptGroup>
          </Select>
        </Col>
      </Row>
    );
  }
}
