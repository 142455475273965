import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { store } from "./index";
import AdminPage from "./admin/AdminPage/AdminPage";
import LandingPage from "./ui/LandingPage/LandingPage";
import UserEntrance from "./ui/UserEntrance/UserEntrance";
import Dashboard from "./ui/Dashboard/Dashboard";
import i18n from "./Utils/i18next";
import { I18nextProvider } from "react-i18next";
import ReactGA from 'react-ga';
import { isAuthenticated, naviagateToLogin } from "./Utils/Auth";
import { get } from "./Utils/WebService";
import { API_BASE, BASE_URL_ADMIN, BASE_URL_REPORTS, BASE_URL_UI, ECHO_URL } from "../src/config"
import { setSessionVariableContent } from "./ui/GeneralComponents/SessionVariable/SessionVariableAction";
class VispeahenApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isThemePulled: false,
    }
  }

  componentWillMount() {
    let pathName = window.location.href
    let isPathIncludeLogin = pathName.includes(`${BASE_URL_UI}/login`) ? true : false
    let isPathIncludeRegister = pathName.includes(`${BASE_URL_UI}/register`) ? true : false
    let isPathIncludeForgotPassword = pathName.includes(`${BASE_URL_UI}/forgotPassword`) ? true : false
    let mustNavigate = !isPathIncludeForgotPassword && !isPathIncludeLogin && !isPathIncludeRegister ? true : false

    this.setState({
      mustNavigate: mustNavigate
    })

    /*
    * Sets target path to session storage for when user login, navigate this path
    */
    let setPathName = () => {
      if (mustNavigate) {
        let navigatedUrl = window.location.pathname

        sessionStorage.setItem("targetLocation", navigatedUrl)
      }
    }

    /*
    * Controls is user authenticate and is user's authentication id is valid
    */
    const controlIsUserAuthenticate = () => {
      let url = ECHO_URL

      const succesFunc = () => {
        this.setState({
          isAuthenticated: true,
        })
      }

      const errorFunc = () => {
        setPathName()
        naviagateToLogin()
      }

      get(url, succesFunc, errorFunc)
    }

    let isUserAuthenticated = isAuthenticated()

    if (!isUserAuthenticated && mustNavigate) {
      setPathName()
      naviagateToLogin(0)
    } else if (mustNavigate) {
      controlIsUserAuthenticate();

      this.getAndApplyTheme();
    }
  }

  /**
   * Get advanced themes of tenant
   */
  getAndApplyTheme = () => {
    let url = `${API_BASE}/theme`;

    const success = (response) => {
      let themeList = response.data;
      let themes = themeList;
      let theme = themes?.find(t => t.default === true);

      if (themes?.length > 0) {
        for (let theme of themes) {
          if (typeof theme.plugin?.titleFontSize !== "undefined") {
            theme.plugin.changedTitleFontSize = theme.plugin.titleFontSize;
          }
        }

        sessionStorage.setItem("themes", JSON.stringify(themes));
      }

      if (theme) {
        sessionStorage.setItem("theme-default", JSON.stringify(theme));
      }

      this.setState({
        isThemePulled: true,
      });
    }

    const error = (err) => {
      this.setState({
        isThemePulled: true
      });
    }

    sessionStorage.removeItem("themes");
    sessionStorage.removeItem("theme-default");
    sessionStorage.removeItem("theme-dashboard");

    get(url, success, error);
  }

  componentDidMount() {
    ReactGA.initialize('UA-135650457-2');
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    const { isThemePulled, isAuthenticated, mustNavigate } = this.state
    const isAppReady = (isThemePulled && isAuthenticated) || !mustNavigate;

    return isAppReady && (
      <I18nextProvider i18n={i18n}>
        <Switch>
          <Route exact path={`${BASE_URL_UI}`} component={LandingPage} />
          {/* following rule require id from url which will be needed to accomplish data(dashboard id) transfer through NavigationDashboard->DashboardCrud and all of its child components */}
          <Route path={`${BASE_URL_UI}/dashboard/:id`} component={Dashboard} />
          <Route path={`${BASE_URL_UI}/dashboard`} component={Dashboard} />
          <Route exact path={`${BASE_URL_UI}/login`} component={UserEntrance} />
          <Route
            exact
            path="/ui/register/:activationCode"
            component={UserEntrance}
          />
          <Route
            exact
            path="/ui/forgotPassword/:passwordCode"
            component={UserEntrance}
          />
          <Route path={`${BASE_URL_ADMIN}`} component={AdminPage} />
          <Route path={`${BASE_URL_REPORTS}/designer/:id`} />
          <Route path={`${BASE_URL_REPORTS}/designer`} />
        </Switch>
      </I18nextProvider>
    );
  }
}

export default VispeahenApp;
