import React, { Component } from "react";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import "leaflet-easybutton";
import "leaflet/dist/leaflet.css";
import geojson2svg from "geojson2svg";
import "amcharts3";
import "amcharts3/amcharts/serial";
import "amcharts3/amcharts/themes/light";
import "amcharts3/amcharts/themes/black";
import "amcharts3/amcharts/themes/chalk";
import "amcharts3/amcharts/themes/dark";
import "amcharts3/amcharts/themes/patterns";
import "ammap3";
import "ammap3/ammap/ammap";
import "ammap3/ammap/maps/js/worldHigh";
import "ammap3/ammap/maps/js/turkeyHigh";
import "ammap3/ammap/plugins/export/export";
import TurkeyMapConfiguration from "./TurkeyMapConfiguration";
import TurkeyMapData from "./TurkeyMapData";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import _ from "lodash";
import { renderContent } from "../renderContent";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import uuid from "react-uuid";
import { TOPOJSON_PATH } from "../../../GeneralComponents/MapTopoJsonOperations/DefaultTopojsons";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"value":21408836,"plaka":30},{"value":26175692,"plaka":36},{"value":34843284,"plaka":44},{"value":35586718,"plaka":65},{"value":37627460,"plaka":13},{"value":49845548,"plaka":24},{"value":51570180,"plaka":62},{"value":55415829,"plaka":12},{"value":61137250,"plaka":25}]`
);
const config = JSON.parse(
  `{"width":"100%","height":300,"isMulti":true,"showHideButton":false,"theme":"None","toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","selectedColor":"#333333","refresh":0}`
);
const columnMap = JSON.parse(
  `{"value":{"Code":"\'il_ilce_dogu_anadolu\'.\'deger\'","Name":"deger","DataType":"double","Table":"il_ilce_dogu_anadolu","Measure":"sum(il_ilce_dogu_anadolu.deger)","ID":"il_ilce_dogu_anadolu.deger","SubjectArea":"dogu_anadolu","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":""},"plaka":{"Code":"\'il_ilce_dogu_anadolu\'.\'il_id\'","Name":"il_id","DataType":"double","Table":"il_ilce_dogu_anadolu","Measure":"none","ID":"il_ilce_dogu_anadolu.il_id","SubjectArea":"dogu_anadolu","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":true,"Type":"Column","Description":"","remove":false},"title":{"Code":"","Name":"","DataType":"varchar","Table":"Unspecified","Measure":"none","ID":"Unspecified.","SubjectArea":"","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column","remove":false},"ilceId":{"Code":"","Name":"","DataType":"varchar","Table":"Unspecified","Measure":"none","ID":"Unspecified.","SubjectArea":"","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column"}}`
);

const condFormats = [];
const filters = [];
var horizontal;
var pluginName = "turkey-map";
let activeMap = 0;
const description =
  "Template plugin file that can be used by developers to create new plugins or learn to modify existing ones."; // Description of the plugin

// Mapping between RPD columns and the visualisation
const columnMappingParameters = [
  {
    targetProperty: "value", // ID of the property in columnMap object of render function
    formLabel: "Value", // Name the user will see
    type: "fact", // Either dim, fact or hidden - dimension, measure or hidden
    multiple: false, // Boolean for whether you can have more than one column in this section
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: true, // Indicate to the user that this is required
    desc: "desc72" //'Harita renklenmesi için değer' // Description that is displayed to the user
  },
  {
    targetProperty: "plaka", // ID of the property in columnMap object of render function
    formLabel: "CityId", // Name the user will see
    type: "dim", // Either dim, fact or hidden - dimension, measure or hidden
    multiple: false, // Boolean for whether you can have more than one column in this section
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: true, // Indicate to the user that this is required
    desc: "desc81" //'Şehir plaka numarası' // Description that is displayed to the user
  },
  {
    targetProperty: "title", // ID of the property in columnMap object of render function
    formLabel: "CityName", // Name the user will see
    type: "fact", // Either dim, fact or hidden - dimension, measure or hidden
    multiple: false, // Boolean for whether you can have more than one column in this section
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: false, // Indicate to the user that this is required
    desc: "desc82" //'Şehir adı' // Description that is displayed to the user
  },
  {
    targetProperty: "ilceId", // ID of the property in columnMap object of render function
    formLabel: "DistrictId", // Name the user will see
    type: "fact", // Either dim, fact or hidden - dimension, measure or hidden
    multiple: false, // Boolean for whether you can have more than one column in this section
    conditionalFormat: true, // Allow this to be conditionally formatted
    required: false, // Indicate to the user that this is required
    desc: "desc83" //'İlçe Adı' // Description that is displayed to the user
  }
];

// Global configuration for the plugin - must have width and height or size at minimum.
const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "width", // Name of the property in config object of render function
    label: "Width", // Name the user will see
    inputType: "textbox", // Indicates the UI element for setting this parameter
    inputOptions: {
      // Sub-parameters for the UI element
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc89" // Description displayed to the user
  },
  {
    // Height and width are mandatory properties unless size is specified
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300
    },
    desc: "desc90"
  },
  {
    // Height and width are mandatory properties unless size is specified
    targetProperty: "isMulti",
    label: "IsMulti",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "IsMulti"
  },
  {
    targetProperty: "hideLabel",
    label: "HideLabel",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    // Height and width are mandatory properties unless size is specified
    targetProperty: "theme",
    label: "Theme",
    inputType: "dropdown",
    inputOptions: {
      multiSelect: false,
      values: ["None", "Dark", "Crimson", "Chalk", "Light", "Patterns"],
      defaultValue: "None"
    },
    desc: "desc91"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgb(255,255,255)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "selectedColor",
    label: "SelectedColor",
    inputType: "colour",
    inputOptions: {
      defaultValue: "#333333"
    },
    desc: "desc134"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
  // {
  //     targetProperty: "ilceler",
  //     label: "İlçeler haritası",
  //     inputType: "checkbox",
  //     inputOptions: {
  //         defaultValue: false
  //     },
  //     desc: 'İlçeler haritasını aktifleştirir.'
  // },
];

// Interactions the user can make on this visualisation (e.g. click, highlight)
const actions = [
  {
    trigger: "clickFeature",
    type: "click",
    name: "Tıklama Feature",
    output: ["plaka", "title"],
    description: "ClickFeatureDesc"
  },
  {
    trigger: "doubleClickFeature",
    type: "click",
    name: "Çift Tıklama Feature",
    output: ["plaka"],
    description: "DoubleClickFeatureDesc"
  },
  {
    trigger: "hoverMapObject",
    type: "hover",
    name: "Hover",
    output: ["plaka"],
    description: "HoverMapObjectDesc"
  }
];

// Reactions this visualisation can have to other interactions. Everything can use filters
const reactions = [
  {
    id: "filter",
    name: "Filter",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

function getNumberSeparators() {
  // default
  const res = {
    decimal: ".",
    thousand: ""
  };

  // convert a number formatted according to locale
  const str = parseFloat(1234.56).toLocaleString();

  // if the resulting number does not contain previous number
  // (i.e. in some Arabic formats), return defaults
  if (!str.match("1")) return res;

  // get decimal and thousand separators
  res.decimal = str.replace(/.*4(.*)5.*/, "$1");
  res.thousand = str.replace(/.*1(.*)2.*/, "$1");

  // return results
  return res;
}

/**
 * Get locale settings
 */
const separators = getNumberSeparators();

/**
 * renders TurkeyMap plugin in Vispeahen V3
 */
export default class TurkeyMap extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};

    this.state = {
      selectedCities : new Set()
    }
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );

    if (this.props.clickedRefresh !== nextProps.clickedRefresh && nextProps.clickedRefresh === true) {
      this.setState({
        ...this.state,
        selectedCities: new Set()
      })
    }
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <TurkeyMapConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <TurkeyMapData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      value: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Value.Name"),
        type: "fact",
        required: true,
        minimumColumnSize: 1,
        conditionalFormat: true,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Value.Desc"),
        data: []
      },
      plaka: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Plaka.Name"),
        type: "dim",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Plaka.Desc"),
        required: true,
        conditionalFormat: true,
        data: []
      },
      title: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Title.Name"),
        type: "fact",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Title.Desc"),
        required: false,
        conditionalFormat: true,
        data: []
      },
      ilceId: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.IlceId.Name"),
        type: "fact",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.IlceId.Desc"),
        required: false,
        conditionalFormat: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    var THIS = this;
    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];

    var width = +config.width;
    var height = +config.height;
    var visWidth = "100%";
    var visHeight = height + "px";

    const zoomLevel = 2;
    columnMap.plaka.remove = false;
    columnMap.title.remove = false;
    data.forEach(d => {
      d.id = "";
      d.plaka = "" + d.plaka;
      if (!d.plaka.match(/TR-\d*/)) {
        const prefix = "TR-";
        const code = "0" + d.plaka;
        d.id = prefix + code.substring(code.length - 2);
        d.value = Number.parseFloat(d.value);
      } else {
        d.id = d.plaka;
      }
    });
    let ilData = [];
    if (columnMap.ilceId && columnMap.ilceId.Name !== "") {
      const reducedData = data.reduce((acc, item) => {
        if (acc.has(item.id)) {
          acc.set(item.id, {
            value: acc.get(item.id).value + item.value,
            plaka: item.plaka
          });
        } else {
          acc.set(item.id, {
            value: item.value,
            plaka: item.plaka
          });
        }
        return acc;
      }, new Map());
      ilData = [...reducedData].reduce((acc, item) => {
        acc.push({
          id: item[0],
          value: item[1].value,
          plaka: item[1].plaka
        });
        return acc;
      }, []);
    } else {
      ilData = data;
    }

    const ilceData = data.map(d => {
      return {
        id: d.ilceId,
        value: d.value,
        plaka: d.ilceId,
        ilId: d.id
      };
    });
    //added bg color attr.
    const randomMapId = uuid();
    if (!config.hideLabel) {
      $(container).append(
        `<div id="mapsDiv${randomMapId}" class="turkeyMap">
                  <div id="ilMap${randomMapId}" style="height: ${visHeight}; width: ${visWidth}; background-color: ${config.backgroundColor}; color: #fff"></div>
                  <div id="ilceMap${randomMapId}" style="display:none; height: ${visHeight}; width: ${visWidth}; background-color: #ffffff; color: #fff"></div>
              </div>`
      );
    }

    const mapData = {
      map: "turkeyHigh",
      areas: ilData,
      zoomLevel: 1
    };

    let ilceDataProvider;
    if (columnMap.ilceId && columnMap.ilceId.Name !== "") {
      $.getJSON(TOPOJSON_PATH + "/ilceler_detayli.json", geoJson => {
        ilceDataProvider = {
          mapVar: window.AmCharts.parseGeoJSON(geoJson),
          areas: ilceData
        };
      });
    }

    const map = window.AmCharts.makeChart(`ilMap${randomMapId}`, {
      type: "map",
      theme: config.theme.toLowerCase(),
      colorSteps: 10,
      decimalSeparator: separators.decimal,
      thousandsSeparator: separators.thousand,
      dataProvider: mapData,
      areasSettings: {
        autoZoom: false,
        balloonText: "[[title]]: <b>[[value]]</b>",
        selectable: true,
        selectedColor: config.isMulti ? config.selectedColor : undefined,
        outlineColor: "#000000"
      },
      zoomControl: {
        zoomControlEnabled: false
      },
      zoomOnDoubleClick: false
    });

    let filteredCities = this.state.selectedCities;

    if (filteredCities.size > 0) {
      filteredCities.forEach(elem => {
        const plaka = JSON.parse(elem).plaka;
        const mapObject = map.dataProvider.areas.find(
          area => area.plaka === plaka
        );

        if (mapObject) {
          map.selectedObject = map.dataProvider;
          mapObject.showAsSelected = !mapObject.showAsSelected;
          map.returnInitialColor(mapObject);
        }
      });
    }

    map.clickedObject = false;
    map.clickedObjectTimeout = false;
    map.addListener("clickMapObject", event => {
      const activeData = activeMap === 0 ? ilData : ilceData;
      columnMap.plaka.remove = false;
      columnMap.title.remove = false;
      const datum = {
        plaka: activeData.find(d => d.id === event.mapObject.id).plaka,
        title: activeData.find(d => d.id === event.mapObject.id).title
      };
      if (
        false !== map.clickedObject &&
        map.clickedObject === event.mapObject
      ) {
        if (activeMap === 0 && columnMap.ilceId.Name !== "") {
          let selectedIlData = ilceDataProvider.areas.filter(
            area => area.ilId === event.mapObject.id
          );
          map.clickedObject = false;

          // zoom to selected object
          // map.zoomToSelectedObject(map.selectedObject);
          map.dataProvider = {
            mapVar: ilceDataProvider.mapVar,
            areas: selectedIlData
          };
          map.validateData();
          activeMap = 1;
        }
      } else {
        const filteredCities = config.isMulti ? this.state.selectedCities : new Set();
        const stringified = JSON.stringify(datum);
        if (filteredCities.has(stringified)) {
          filteredCities.delete(stringified);
        } else {
          filteredCities.add(stringified);
        }

        if (activeMap === 0) {
          for (let il of filteredCities) {
            let il_json = JSON.parse(il);
            if (il_json.plaka > "9") {
              console.log(il);
            } else {
              filteredCities.add(il);
            }
          }
        }

        const array =
        filteredCities.size > 0 ? Array.from(filteredCities).map(d => JSON.parse(d)) : activeData;
        if (array.length == activeData.length) {
          columnMap.plaka.remove = true;
          columnMap.title.remove = true;
        }
        createTrigger(
          actions,
          columnMap,
          container,
          "clickFeature",
          array,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations
        );

        clearTimeout(map.clickedObjectTimeout);
        map.clickedObject = event.mapObject;
        map.clickedObject = false;
      }

      if (config.isMulti) {
        map.selectedObject = map.dataProvider;
        event.mapObject.showAsSelected = !event.mapObject.showAsSelected;
        map.returnInitialColor(event.mapObject);
      }
    });
    map.addListener("rollOverMapObject", event => {
      const activeData = activeMap === 0 ? ilData : ilceData;
      const datum = activeData.find(d => d.id === event.mapObject.id);
      createTrigger(
        actions,
        columnMap,
        container,
        "hoverMapObject",
        datum,
        THIS.props.plugin.id,
        THIS.props.interactions,
        THIS.props.navigations
      );
    });

    map.addListener("zoomCompleted", function (event) {
      if (map.zoomLevel() === zoomLevel) {
        // replace map with detailed view
        map.dataProvider = ilceDataProvider;
        map.validateData();
        activeMap = 1;
      }
    });

    map.addListener("homeButtonClicked",  (e) => {
      //Must be turned on to return home if autoZoom = true
      // if (map.dataProvider.map === 'turkeyHigh') {
      //     return;
      // }

      let filteredCities = this.state.selectedCities;

      if (filteredCities.size > 0) {
        filteredCities.forEach(elem => {
          const plaka = JSON.parse(elem).plaka;
          const mapObject = map.dataProvider.areas.find(
            area => area.plaka === plaka
          );

          if (mapObject) {
            map.selectedObject = map.dataProvider;
            mapObject.showAsSelected = !mapObject.showAsSelected;
            map.returnInitialColor(mapObject);
          }
        });

        this.state.selectedCities.clear();

        const activeData = activeMap === 0 ? ilData : ilceData;
        const array =
        filteredCities.size > 0 ? Array.from(filteredCities).map(d => JSON.parse(d)) : activeData;
        if (array.length == activeData.length) {
          columnMap.plaka.remove = true;
          columnMap.title.remove = true;
        }
        createTrigger(
          actions,
          columnMap,
          container,
          "clickFeature",
          array,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations
        );
      }

      // reset map to original world view
      map.dataProvider = mapData;
      map.validateData();
      activeMap = 0;
    });

    if ($(`#` + divId).find("a").length !== 0) {
      $(`#` + divId).find("a").remove()
    }

    window.AmCharts.parseGeoJSON = function (geojson, fieldMap) {
      
      // init field map
      if (typeof fieldMap !== "object") fieldMap = {};

      // init calibration
      const bounds = {
        left: -180,
        bottom: -90,
        right: 180,
        top: 90
      };

      // init empty map
      const mapVar = {
        svg: {
          defs: {
            "amcharts:ammap": {
              projection: "mercator",
              leftLongitude: "-180",
              topLatitude: "90",
              rightLongitude: "180",
              bottomLatitude: "-90"
            }
          },
          g: {
            path: []
          }
        }
      };

      // convert GeoJSON to SVG paths
      const converter = geojson2svg({
        output: "svg",
        explode: false,
        attributes: [
          {
            property: "properties.NAME_2",
            type: "dynamic",
            key: "title"
          }
        ],
        mapExtent: bounds,
        viewportSize: {
          width: 800,
          height: 800
        }
      });
      const svg = converter.convert(geojson, {});

      // parse each path into JavaScript Maps data structure
      for (let i = 0; i < svg.length; i++) {
        const path = svg[i];
        const attrs = path.match(/\w+=".*?"/g);
        const area = {};
        for (let x = 0; x < attrs.length; x++) {
          const parts = attrs[x].replace(/"/g, "").split("=");
          const key = fieldMap[parts[0]] || parts[0];
          area[key] = parts[1];
        }
        mapVar.svg.g.path.push(area);
      }

      return mapVar;
    };

    if (config.showHideButton) {
      let hideButtonToggleKey = [$("#mapsDiv" + randomMapId)];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{height: "100%"}}>
          <div id={this.props.plugin.id}></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            condFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
        </div>
      </>
    );
  }
}
