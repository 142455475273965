import { showNotificationWithIcon } from "../../Utils/Notification";
import React, { Component } from "react";
import DashboardCrud from "../DashboardPage/DashboardCrud/DashboardCrud";
import SplashScreen from "../SplashScreen/SplashScreen";
import i18n from "../../Utils/i18next";
import { setTriggeredNavigation } from "../Navigation/NavigationAction";
import { store } from "../../index";
import { deepCopy } from "../../Utils/Global";

const clone = require("rfdc")();

/**
 * It does the operations of opening a saved report and navigation from one report to other reports.
 */
export default class NavigationDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigations: [],
      currentDashboard: {},
      isCustomDashboard: false,
      theme: null,
      themes: []
    };
  }

  // Splits dashboardId from the incoming URL and returns it
  findDashboardId = () => {
    let afterDashboarUrl = window.location.pathname.split("dashboard")[1];
    let dashboardIdCandidate =
      afterDashboarUrl && afterDashboarUrl.split("/")[1];
    let dashboardIdWithoutQueryString =
      dashboardIdCandidate && dashboardIdCandidate.split("?")[0];
    return dashboardIdWithoutQueryString ? dashboardIdWithoutQueryString : "";
  };

  /**
   * Controls navigation object is valid or not
   *
   * */
  isNavigationValid = navigation => {
    return (
      navigation && navigation.dashboardId && navigation.dashboardId !== ""
    );
  };

  /**
   *
   * @param {*} dashboardId
   * @param {*} dashboardTitle
   *
   * It takes rendered report title and finds the report using dashboardId then sets it
   */
  setDashboardTitle = (dashboardId, dashboardTitle) => {
    let navigations = clone(this.state.navigations);

    navigations.map(navigation => {
      if (navigation.dashboardId === dashboardId) {
        navigation.dashboardInformation.title = dashboardTitle;
      }
    });

    this.setState({
      ...this.state,
      navigations
    });
  };

  /**
   *
   * @param {*} navigation
   *
   * Sets the incoming navigation to the navigation list and currentDashboard
   */
  setNavigations = navigation => {
    if (this.isNavigationValid(navigation)) {
      let navigations = clone(this.state.navigations);

      navigations.push(navigation);

      this.setState({
        ...this.state,
        navigations,
        currentDashboard: navigation
      });

      store.dispatch(setTriggeredNavigation(navigation));
    } else {
      showNotificationWithIcon(
        i18n.t("Error"),
        i18n.t("Navigation.InvalidNavigation"),
        "error"
      );
    }
  };

  /**
   * To update navigations variables after switch dashboard
   */
  updateNavigationsAfterSwitchDashboard = dashboard => {
    let navigations = [];
    navigations.push(dashboard);

    this.setState({
      ...this.state,
      navigations: navigations,
      currentDashboard: dashboard
    });

    store.dispatch(setTriggeredNavigation({}));
  };

  /**
   *
   * @param {*} item selected navigation
   * @param {*} index index of selected navigation
   *
   * Render the selected nagivation object
   */
  goToSelectedNavigation = (item, index) => {
    let navigations = clone(this.state.navigations);

    navigations = navigations.slice(0, index + 1);

    store.dispatch(setTriggeredNavigation(navigations[navigations.length - 1]));
    this.setState({
      ...this.state,
      navigations,
      currentDashboard: item
    });
  };

  /**
   * Renders the report according to the dashboardId that retrieved from the URL at page opening
   */
  componentWillMount() {
    let newState = deepCopy(this.state);
    let dashboardId = this.findDashboardId();
    let navigations = [];

    if (dashboardId !== undefined && dashboardId !== "") {
      let dashboard = {
        dashboardId: dashboardId,
        dashboardInformation: {}
      };

      navigations.push(dashboard);

      newState.navigations = navigations;
      newState.currentDashboard = dashboard;
    } else {
      let defaultTheme = sessionStorage.getItem("theme-default");

      if (defaultTheme) {
        
        sessionStorage.setItem("theme-dashboard", defaultTheme);

        newState = {
          ...newState,
          theme: JSON.parse(defaultTheme)?._id
        };
      }
    }

    let themes = JSON.parse(sessionStorage.getItem("themes"));

    if (themes) {
      newState.themes = JSON.parse(sessionStorage.getItem("themes"));
    }

    this.setState(newState);
  }

  /**
   *
   * @param {*} newDashboard
   * Adds the newly saved dashboard to the navigations array
   */
  setNewDashboard = newDashboard => {
    let dashboard = {};

    if (newDashboard && newDashboard.id && newDashboard.title) {
      dashboard.dashboardId = newDashboard.id;
      dashboard.dashboardInformation = {};
      dashboard.dashboardInformation.title = newDashboard.title;

      let navigations = [...this.state.navigations];
      navigations.push(dashboard);

      this.setState({
        ...this.state,
        navigations: navigations
      });
    }
  };

  /** Update current dashboard */
  updateCurrentDashboard = (newDashboard, isCustomDashboard = false, mainDashboardId = null) => {
    let navigations = [...this.state.navigations];
    let navigationsLength = navigations.length;
    let dashboard = {
      dashboardId: isCustomDashboard ? mainDashboardId : newDashboard.id,
      dashboardInformation: {title: newDashboard.name}
    };

    if (navigationsLength > 0) {
      navigations[navigationsLength - 1] = dashboard;
    }

    this.setState({
      ...this.state,
      navigations: navigations,
      currentDashboard: dashboard,
      isCustomDashboard: isCustomDashboard
    });
  };

  render() {
    return (
      <div>
        <SplashScreen />
        {/* get dashboard id from query string which follows the procedure of React Router v4 */}
        <DashboardCrud
          errorFallback={this.props.errorFallback}
          updateErrorFallbackStatus={this.props.updateErrorFallbackStatus}
          lastState={this.props.lastState}
          addStateToHistory={this.props.addStateToHistory}
          setNewDashboard={this.setNewDashboard}
          navigations={this.state.navigations}
          setNavigations={this.setNavigations}
          goToSelectedNavigation={this.goToSelectedNavigation}
          setDashboardTitle={this.setDashboardTitle}
          currentDashboard={this.state.currentDashboard}
          updateNavigationsAfterSwitchDashboard={
            this.updateNavigationsAfterSwitchDashboard
          }
          updateCurrentDashboard={this.updateCurrentDashboard}
          isCustomDashboard={this.state.isCustomDashboard}
          theme={this.state.theme}
          themes={this.state.themes}
        />
      </div>
    );
  }
}
