import {
  AreaChartOutlined,
  BgColorsOutlined,
  BoldOutlined,
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  EyeInvisibleOutlined,
  FontSizeOutlined,
  HighlightOutlined,
  InfoCircleOutlined,
  ItalicOutlined,
  LineChartOutlined,
  UnderlineOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, InputNumber, Row, Select, Tooltip } from "antd";
import $ from "jquery";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import i18n from "../../../../Utils/i18next";
import FontTypeSelector from "../../../GeneralComponents/FontTypeSelector/FontTypeSelector";
import Text from "../../../GeneralComponents/Text/Text";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import { InsightsConfig } from "../../RenderJs/config";
import {
  barValueFontSizeAutomaticFunction
} from "./MultiAxisLineChart";
import { themeFields } from "../common";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { TextArea } = Input;
const { Option } = Select;

export default class MultiAxisLineChartConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: this.props.config.width,
      height: this.props.config.height,
      backgroundColor: this.props.config.backgroundColor,
      colours: this.props.config.colours,
      pointSize: this.props.config.pointSize,
      strokeWidth: this.props.config.strokeWidth,
      legend: this.props.config.legend,
      axisNumber: this.props.config.axisNumber,
      ShowLineValueOption: this.props.config.ShowLineValueOption,
      ColoredLineValues: this.props.config.ColoredLineValues,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      paletteColours: InsightsConfig.Palettes[this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours]
        : this.props.config.colours,
      ValueOnBarOption: this.props.config.ValueOnBarOption,
      ShowYAxisStylingOption: this.props.config.ShowYAxisStylingOption,
      ShowXAxisStylingOption: this.props.config.ShowXAxisStylingOption,
      condFormat: this.props.config.condFormat,
      yAxisFont: this.props.config.yAxisFont === undefined ? "Verdana" : this.props.config.yAxisFont,
      yAxisFontSize: this.props.config.yAxisFontSize === undefined ? 13 : this.props.config.yAxisFontSize,
      yAxisFontColor: this.props.config.yAxisFontColor === undefined ? "black" : this.props.config.yAxisFontColor,
      yAxisFontStyle: this.props.config.yAxisFontStyle === undefined ? false : this.props.config.yAxisFontStyle,
      yAxisFontWeight: this.props.config.yAxisFontWeight === undefined ? false : this.props.config.yAxisFontWeight,
      yAxisTextDecoration: this.props.config.yAxisTextDecoration === undefined ? false : this.props.config.yAxisTextDecoration,
      yAxisAlign: this.props.config.yAxisAlign,
      xAxisFont: this.props.config.xAxisFont === undefined ? "Verdana" : this.props.config.xAxisFont,
      xAxisFontSize: this.props.config.xAxisFontSize === undefined ? 13 : this.props.config.xAxisFontSize,
      xAxisFontColor: this.props.config.xAxisFontColor === undefined ? "black" : this.props.config.xAxisFontColor,
      xAxisFontStyle: this.props.config.xAxisFontStyle === undefined ? false : this.props.config.xAxisFontStyle,
      xAxisFontWeight: this.props.config.xAxisFontWeight === undefined ? false : this.props.config.xAxisFontWeight,
      xAxisTextDecoration: this.props.config.xAxisTextDecoration === undefined ? false : this.props.config.xAxisTextDecoration,
      xAxisAlign: this.props.config.xAxisAlign,
      yAxisSingleTitleShow: this.props.config.yAxisSingleTitleShow === undefined ? true : this.props.config.yAxisSingleTitleShow,
      yAxisSingleTitle: this.props.config.yAxisSingleTitle === undefined ? false : this.props.config.yAxisSingleTitle,
      showEllipsis: this.props.config.showEllipsis === undefined ? true : this.props.config.showEllipsis,
      showEllipsisOnXAxis: this.props.config.showEllipsisOnXAxis === undefined ? false : this.props.config.showEllipsisOnXAxis,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      lineValueFont: this.props.config.lineValueFont === undefined ? "Verdana" : this.props.config.lineValueFont,
      lineValueFontSize: this.props.config.lineValueFontSize === undefined ? 13 : this.props.config.lineValueFontSize,
      lineValueFontColor: this.props.config.lineValueFontColor === undefined ? "black" : this.props.config.lineValueFontColor,
      lineValueAngle: this.props.config.lineValueAngle === undefined ? 0 : this.props.config.lineValueAngle,
      barValueFont: this.props.config.barValueFont === undefined ? "Verdana" : this.props.config.barValueFont,
      barValueFontSize: this.props.config.barValueFontSize === undefined ? 10 : this.props.config.barValueFontSize,
      barValueFontColor: this.props.config.barValueFontColor === undefined ? "black" : this.props.config.barValueFontColor,
      barValueFontSizeMax: this.props.config.barValueFontSizeMax,
      barValueFontSizeMin: this.props.config.barValueFontSizeMin,
      barValueFontSizeAutomatic: this.props.config.barValueFontSizeAutomatic === undefined ? false : this.props.config.barValueFontSizeAutomatic,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle,
      targetSumOperation: this.props.config.targetSumOperation === undefined ? "none" : this.props.config.targetSumOperation
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        width: nextProps.config.width,
        height: nextProps.config.height,
        backgroundColor: nextProps.config.backgroundColor,
        colours: nextProps.config.colours,
        pointSize: nextProps.config.pointSize,
        strokeWidth: nextProps.config.strokeWidth,
        legend: nextProps.config.legend,
        axisNumber: nextProps.config.axisNumber,
        ShowLineValueOption: nextProps.config.ShowLineValueOption,
        ColoredLineValues: nextProps.config.ColoredLineValues,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours]
          : nextProps.config.colours,
        ValueOnBarOption: nextProps.config.ValueOnBarOption,
        condFormat: nextProps.config.condFormat,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        yAxisFont: nextProps.config.yAxisFont === undefined ? "Verdana" : nextProps.config.yAxisFont,
        yAxisFontSize: nextProps.config.yAxisFontSize === undefined ? 13 : nextProps.config.yAxisFontSize,
        yAxisFontColor: nextProps.config.yAxisFontColor === undefined ? "black" : nextProps.config.yAxisFontColor,
        yAxisFontStyle: nextProps.config.yAxisFontStyle === undefined ? false : nextProps.config.yAxisFontStyle,
        yAxisFontWeight: nextProps.config.yAxisFontWeight === undefined ? false : nextProps.config.yAxisFontWeight,
        yAxisTextDecoration: nextProps.config.yAxisTextDecoration === undefined ? false : nextProps.config.yAxisTextDecoration,
        xAxisFont: nextProps.config.xAxisFont === undefined ? "Verdana" : nextProps.config.xAxisFont,
        xAxisFontSize: nextProps.config.xAxisFontSize === undefined ? 13 : nextProps.config.xAxisFontSize,
        xAxisFontColor: nextProps.config.xAxisFontColor === undefined ? "black" : nextProps.config.xAxisFontColor,
        xAxisFontStyle: nextProps.config.xAxisFontStyle === undefined ? false : nextProps.config.xAxisFontStyle,
        xAxisFontWeight: nextProps.config.xAxisFontWeight === undefined ? false : nextProps.config.xAxisFontWeight,
        xAxisTextDecoration: nextProps.config.xAxisTextDecoration === undefined ? false : nextProps.config.xAxisTextDecoration,
        ShowYAxisStylingOption: nextProps.config.ShowYAxisStylingOption,
        ShowXAxisStylingOption: nextProps.config.ShowXAxisStylingOption,
        showEllipsisOnXAxis: nextProps.config.showEllipsisOnXAxis === undefined ? false : nextProps.config.showEllipsisOnXAxis,
        yAxisSingleTitleShow: nextProps.config.yAxisSingleTitleShow === undefined ? true : nextProps.config.yAxisSingleTitleShow,
        yAxisSingleTitle: nextProps.config.yAxisSingleTitle === undefined ? false : nextProps.config.yAxisSingleTitle,
        showEllipsis: nextProps.config.showEllipsis === undefined ? true : nextProps.config.showEllipsis,
        lineValueFont: nextProps.config.lineValueFont === undefined ? "Verdana" : nextProps.config.lineValueFont,
        lineValueFontSize: nextProps.config.lineValueFontSize === undefined ? 13 : nextProps.config.lineValueFontSize,
        lineValueFontColor: nextProps.config.lineValueFontColor === undefined ? "black" : nextProps.config.lineValueFontColor,
        lineValueAngle: nextProps.config.lineValueAngle === undefined ? 0 : nextProps.config.lineValueAngle,
        barValueFont: nextProps.config.barValueFont === undefined ? "Verdana" : nextProps.config.barValueFont,
        barValueFontSize: nextProps.config.barValueFontSize === undefined ? 10 : nextProps.config.barValueFontSize,
        barValueFontColor: nextProps.config.barValueFontColor === undefined ? "black" : nextProps.config.barValueFontColor,
        barValueFontSizeMax: nextProps.config.barValueFontSizeMax,
        barValueFontSizeMin: nextProps.config.barValueFontSizeMin,
        barValueFontSizeAutomatic: nextProps.config.barValueFontSizeAutomatic === undefined ? false : nextProps.config.barValueFontSizeAutomatic,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,
        targetSumOperation: nextProps.config.targetSumOperation === undefined ? "none" : nextProps.config.targetSumOperation
      });
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  handleCheckAutomatic = e => {
    let fontSizeConfig = barValueFontSizeAutomaticFunction(this.props.plugin.id, this.state.barValueFontSizeAutomatic, this.state.barValueFontSize);

    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked,
      barValueFontSize: fontSizeConfig.barValueFontSize,
      barValueFontSizeMax: fontSizeConfig.barValueFontSizeMax,
      barValueFontSizeMin: fontSizeConfig.barValueFontSizeMin
    });

    this.setConfigToPlugin([{ type: e.target.id, value: e.target.checked },
    {
      type: "barValueFontSize",
      value: fontSizeConfig.barValueFontSize
    },
    {
      type: "barValueFontSizeMax",
      value: fontSizeConfig.barValueFontSizeMax
    },
    {
      type: "barValueFontSizeMin",
      value: fontSizeConfig.barValueFontSizeMin
    }]);
  };

  handleChangeFontSize = e => {
    let fontSizeConfig = barValueFontSizeAutomaticFunction(this.props.plugin.id, this.state.barValueFontSizeAutomatic, this.state.barValueFontSize);

    this.setState({
      ...this.state,
      barValueFontSize: e,
      barValueFontSizeMax: fontSizeConfig.barValueFontSizeMax,
      barValueFontSizeMin: fontSizeConfig.barValueFontSizeMin
    });

    this.setConfigToPlugin(
      [
        {
          type: "barValueFontSize",
          value: e
        },
        {
          type: "barValueFontSizeMax",
          value: fontSizeConfig.barValueFontSizeMax
        },
        {
          type: "barValueFontSizeMin",
          value: fontSizeConfig.barValueFontSizeMin
        }
      ]);

  };

  handleCheckBar = e => {
    let fontSizeConfig = barValueFontSizeAutomaticFunction(this.props.plugin.id, e.target.checked, this.state.barValueFontSize);

    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked,
      barValueFontSizeAutomatic: e.target.checked,
      barValueFontSize: fontSizeConfig.barValueFontSize,
      barValueFontSizeMax: fontSizeConfig.barValueFontSizeMax,
      barValueFontSizeMin: fontSizeConfig.barValueFontSizeMin
    });

    this.setConfigToPlugin(
      [
        {
          type: e.target.id,
          value: e.target.checked
        },
        {
          type: "barValueFontSizeAutomatic",
          value: e.target.checked
        },
        {
          type: "barValueFontSize",
          value: fontSizeConfig.barValueFontSize
        },
        {
          type: "barValueFontSizeMax",
          value: fontSizeConfig.barValueFontSizeMax
        },
        {
          type: "barValueFontSizeMin",
          value: fontSizeConfig.barValueFontSizeMin
        }
      ]);
  };

  paletteColourChange = (color, index) => {
    let chartColors;
    let configColours = this.state.colours;

    if (InsightsConfig.Palettes[configColours] != undefined) {
      chartColors = [...InsightsConfig.Palettes[configColours]];
    } else {
      chartColors = [...configColours];
    }

    chartColors[index] = color;

    this.setConfigToPlugin({ type: "colours", value: chartColors });
  }

  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };
  yAxisAlignOnChange = value => {
    this.setState({
      ...this.state,
      yAxisAlign: value
    });

    this.setConfigToPlugin({ type: "yAxisAlign", value: value });
  };
  //To change the align of the x axis title
  xAxisAlignOnChange = value => {
    this.setState({
      ...this.state,
      xAxisAlign: value
    });

    this.setConfigToPlugin({ type: "xAxisAlign", value: value });
  };
  axisOnChange = value => {
    this.setState({
      ...this.state,
      axisNumber: value
    });

    this.setConfigToPlugin({ type: "axisNumber", value: value });
  };
  // To disable x axis settings when chart is too small
  isXAxisValueDisable = () => {
    let pluginWidth = this.props.plugin.w;

    if (this.state.axisNumber !== "Multi") {
      if (pluginWidth < 10) {

        return true
      }
    }
    else if (this.state.axisNumber == "Multi") {
      if (pluginWidth < 12) {

        return true
      }
    }

    return false
  }

  //changes show bar value font
  barValueFontOnChange = obj => {
    this.setState({
      ...this.state,
      barValueFont: obj
    });

    this.setConfigToPlugin({ type: "barValueFont", value: obj });
  };

  colorChangeVisible = () => {
    this.setState({ ...this.state, colorChange: !this.state.colorChange });
  };

  lineValueFontOnChange = obj => {
    this.setState({
      ...this.state,
      lineValueFont: obj
    });

    this.setConfigToPlugin({ type: "lineValueFont", value: obj });
  };
  yAxisFontOnChange = obj => {
    this.setState({
      ...this.state,
      yAxisFont: obj
    })
    this.setConfigToPlugin({ type: "yAxisFont", value: obj });
  }
  //To change the font style of the x axis title
  xAxisFontOnChange = obj => {
    this.setState({
      ...this.state,
      xAxisFont: obj
    })
    this.setConfigToPlugin({ type: "xAxisFont", value: obj });
  }
  //changes axis fontweight to bold or normal
  yAxisWeightOnChange = () => {
    this.handleChange({
      target: {
        id: "yAxisFontWeight",
        value: !this.state.yAxisFontWeight
      }
    })
  }

  //changes axis fontstyle to italic or normal 
  yAxisStyleOnChange = () => {
    this.handleChange({
      target: {
        id: "yAxisFontStyle",
        value: !this.state.yAxisFontStyle
      }
    })
  }

  //changes axistextdecor to underline or none
  yAxisDecorOnChange = () => {
    this.handleChange({
      target: {
        id: "yAxisTextDecoration",
        value: !this.state.yAxisTextDecoration
      }
    })

  }

  //changes axis fontweight to bold or normal
  xAxisWeightOnChange = () => {
    this.handleChange({
      target: {
        id: "xAxisFontWeight",
        value: !this.state.xAxisFontWeight
      }
    })
  }

  //changes axis fontstyle to italic or normal 
  xAxisStyleOnChange = () => {
    this.handleChange({
      target: {
        id: "xAxisFontStyle",
        value: !this.state.xAxisFontStyle
      }
    })
  }

  //changes axistextdecor to underline or none
  xAxisDecorOnChange = () => {
    this.handleChange({
      target: {
        id: "xAxisTextDecoration",
        value: !this.state.xAxisTextDecoration
      }
    })

  }
  lineValueFontColorOnChange = obj => {
    this.setState({
      ...this.state,
      lineValueFontColor: obj.color
    });

    this.setConfigToPlugin({ type: "lineValueFontColor", value: obj.color });
  };
  yAxisFontColorOnChange = obj => {
    this.setState({
      ...this.state,
      yAxisFontColor: obj.color
    })
    this.setConfigToPlugin({ type: "yAxisFontColor", value: obj.color });
  }
  // And onchange handler for x axis font color changes
  xAxisFontColorOnChange = obj => {
    this.setState({
      ...this.state,
      xAxisFontColor: obj.color
    })
    this.setConfigToPlugin({ type: "xAxisFontColor", value: obj.color });
  }
  lineValueFontColorChangeVisible = () => {
    this.setState({
      ...this.state,
      lineValueFontColorChange: !this.state.lineValueFontColorChange
    });
  };
  yAxisValueFontColorChangeVisible = () => {
    this.setState({
      ...this.state,
      yAxisColorChange: !this.state.yAxisColorChange
    });
  };
  // To change font color of x axis title visibly
  xAxisValueFontColorChangeVisible = () => {
    this.setState({
      ...this.state,
      xAxisColorChange: !this.state.xAxisColorChange
    });
  };

  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  //changes show bar value font color
  barValueFontColorOnChange = obj => {
    this.setState({
      ...this.state,
      barValueFontColor: obj.color
    });

    this.setConfigToPlugin({ type: "barValueFontColor", value: obj.color });
  };

  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  //changes show bar value font color visible
  barValueFontColorChangeVisible = () => {
    this.setState({
      ...this.state,
      barValueFontColorChange: !this.state.barValueFontColorChange
    });
  };

  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };

  /*
  * On change target sum operation
  */
  targetSumOperationOnChange = value => {
    this.setState({
      ...this.state,
      targetSumOperation: value
    });

    this.setConfigToPlugin({ type: "targetSumOperation", value: value });
  };

  render() {
    const fontSizeConfig = barValueFontSizeAutomaticFunction(this.props.plugin.id, this.state.barValueFontSizeAutomatic, this.state.barValueFontSize);

    this.state.barValueFontSize = fontSizeConfig.barValueFontSize;
    this.state.barValueFontSizeMax = fontSizeConfig.barValueFontSizeMax;
    this.state.barValueFontSizeMin = fontSizeConfig.barValueFontSizeMin;

    return (
      <>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <div style={{ color: "#505050" }}>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowEllipsis")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "3px" }}
                id={"showEllipsis"}
                defaultChecked={this.state.showEllipsis}
                checked={this.state.showEllipsis}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                id={"openMaxisColorPicker"}
                onClick={this.backgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.backgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.backgroundColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.backgroundColor}
                  onChange={this.backgroundColorOnChange}
                  id={"backgroundColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Palette")}
              </Text>
            </Col>
            <Col span={4} style={{ paddingRight: "10px" }}>
              <Select
                id={"colours"}
                defaultValue={this.state.colours}
                value={this.state.colours}
                style={{ width: "100%" }}
                onChange={this.coloursOnChange}>
                <Option id={"flatUIPalette"} value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
                <Option id={"flatUISoftPalette"} value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
                <Option id={"coolScalePalette"} value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
                <Option id={"warmScalePalette"} value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
                <Option id={"heatmapPalette"} value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
              </Select>
            </Col>
            <Col span={8}>
              <ThemePalette
                colours={this.state.paletteColours}
                update={this.setConfigToPlugin}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <ColumnWidthOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.StrokeWidth")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"strokeWidth"}
                defaultValue={this.state.strokeWidth}
                value={this.state.strokeWidth}
                onChange={this.handleChange}
                type={"number"}
                min={"0"}
                max={"1000"}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <ColumnHeightOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.PointSize")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"pointSize"}
                defaultValue={this.state.pointSize}
                value={this.state.pointSize}
                onChange={this.handleChange}
                type={"number"}
                min={"0"}
                max={"1000"}
              />
            </Col>
          </Row>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.DataGrouping")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.AggregationRule")}
              </Text>
            </Col>
            <Col span={12} style={{ paddingRight: "10px" }}>
              <Select
                id={"targetSumOperation"}
                defaultValue={this.state.targetSumOperation}
                value={this.state.targetSumOperation}
                style={{ width: "100%" }}
                onChange={this.targetSumOperationOnChange}>
                <Option id={"none"} value="none">{i18n.t("Dashboard.Configuration.Fields.None")}</Option>
                <Option id={"sum"} value="sum">{i18n.t("Dashboard.Configuration.Fields.Sum")}</Option>
                <Option id={"average"} value="average">{i18n.t("Dashboard.Configuration.Fields.Average")}</Option>
                <Option id={"min"} value="min">{i18n.t("Dashboard.Configuration.Fields.Minimum")}</Option>
                <Option id={"max"} value="max">{i18n.t("Dashboard.Configuration.Fields.Maximum")}</Option>
                <Option id={"count"} value="count">{i18n.t("Dashboard.Configuration.Fields.Count")}</Option>
              </Select>
            </Col>
          </Row>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.AxisSettings")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AreaChartOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.AxisType")}
              </Text>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                id={"maxisType"}
                style={{ width: "100%" }}
                placeholder="Select title align"
                optionFilterProp="children"
                onChange={this.axisOnChange}
                defaultValue={this.state.axisNumber}
              >
                <Option id={"multiType"} value="Multi">{i18n.t("Dashboard.Configuration.Fields.Multi")}</Option>
                <Option id={"singleType"} value="Single">{i18n.t("Dashboard.Configuration.Fields.Single")}</Option>
                <Option id={"singleStackType"} value="Single_Stack">{i18n.t("Dashboard.Configuration.Fields.SingleStack")}</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.YAxisStylingOption")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "3px" }}
                id={"ShowYAxisStylingOption"}
                defaultChecked={this.state.ShowYAxisStylingOption}
                checked={this.state.ShowYAxisStylingOption}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          {this.state.ShowYAxisStylingOption ? (
            <>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2} />
                <Col style={{ textAlign: "center" }} span={3}>
                  <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.YAxisShowColumnNameAsTitle")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Checkbox
                    style={{ float: "left", marginTop: "3px" }}
                    id={"yAxisSingleTitleShow"}
                    defaultChecked={this.state.yAxisSingleTitleShow}
                    checked={this.state.yAxisSingleTitleShow}
                    onChange={this.handleCheck}
                    disabled={this.state.axisNumber == "Single" || this.state.axisNumber == "Single_Stack" ? false : true}
                  ></Checkbox>
                </Col>
              </Row>

              <Row style={{ marginBottom: "5px" }}>
                <Col span={2} />
                <Col style={{ textAlign: "center" }} span={3}>
                  <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.DisplayName")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Input
                    name={"yAxisSingleTitle"}
                    id={"yAxisSingleTitle"}
                    defaultValue={""}
                    value={this.state.yAxisSingleTitle ? this.state.yAxisSingleTitle : ""}
                    onChange={this.handleChange}
                    disabled={this.state.yAxisSingleTitleShow || this.state.axisNumber == "Multi"}

                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <i
                    class="fa fa-font"
                    aria-hidden="true"
                    style={{ position: "relative", bottom: "-5px" }}
                  >
                    {" "}
                  </i>
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.Font")}
                  </Text>
                </Col>
                <Col span={12}>
                  <FontTypeSelector
                    id={"yAxisValueFont"}
                    placeholder={this.state.yAxisFont || "Verdana"}
                    onChange={this.yAxisFontOnChange}
                    value={this.state.yAxisFont || "Verdana"}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2} />
                <Col style={{ textAlign: "center" }} span={3}>
                  <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.YAxisAlign")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Select
                    showSearch
                    id={"yAxisAlign"}
                    style={{ width: "100%" }}
                    placeholder={i18n.t(
                      "Dashboard.Configuration.Fields.SelectYAxisAlign"
                    )}
                    optionFilterProp="children"
                    onChange={this.yAxisAlignOnChange}
                    key={this.state.yAxisAlign} //to trigger defaultValue reverting when returnToDefault button clicked
                    defaultValue={this.state.yAxisAlign || i18n.t("Dashboard.Configuration.Fields.Center")}
                    getPopupContainer={trigger => trigger.parentNode}
                    dropdownMenuStyle={{ textAlign: "left" }}
                  >
                    <Option name={"leftYAxisAlign"} value="translate(0,-117)">
                      {i18n.t("Dashboard.Configuration.Fields.Top")}
                    </Option>
                    <Option name={"centerYAxisAlign"} value="">
                      {i18n.t("Dashboard.Configuration.Fields.Center")}
                    </Option>
                    <Option name={"rightYAxisAlign"} value="translate(0,130)">
                      {i18n.t("Dashboard.Configuration.Fields.Bottom")}
                    </Option>
                  </Select>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.FontSize")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Input
                    id={"yAxisFontSize"}
                    defaultValue={this.state.yAxisFontSize}
                    value={this.state.yAxisFontSize}
                    onChange={this.handleChange}
                    placeholder={this.state.yAxisFontSize}
                    type={"number"}
                    min={"0"}
                    max={"1000"}
                  />
                </Col>
              </Row>
              {Number(this.state.yAxisFontSize) > 30 || Number(this.state.yAxisFontSize) < 13 ? (
                <Row style={{ height: "11px" }}>
                  <Col span={2}> </Col>
                  <Col span={10}></Col>
                  <Col span={12} style={{ fontSize: "11px", textAlign: "left", color: "red" }}>
                    {Number(this.state.yAxisFontSize) > 30
                      ? "* " + i18n.t("Dashboard.Configuration.Fields.CantChangeMeasureSize", { size: `30` })
                      : "* " + i18n.t("Dashboard.Configuration.Fields.CantReduceMeasureSize", { size: `13` })}
                  </Col>
                </Row>
              ) : null}
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}></Col>

                <Col
                  style={{ textAlign: "center" }}
                  span={3}
                >
                  <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col
                  style={{ textAlign: "left" }}
                  span={7}
                >
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.YAxisFontStyle")}
                  </Text>
                </Col>
                <Col style={{ textAlign: "left" }} span={12}>
                  <span>
                    <Button
                      onClick={this.yAxisWeightOnChange}
                      style={{
                        backgroundColor: this.state.yAxisFontWeight ? "#9ed3ff" : "initial",
                        color: "black",
                        borderColor: "lightgrey",
                      }}
                    >
                      <BoldOutlined
                        style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                      />
                    </Button>
                  </span>

                  <span>
                    <Button
                      onClick={this.yAxisStyleOnChange}
                      style={{
                        marginLeft: "20px",
                        backgroundColor: this.state.yAxisFontStyle ? "#9ed3ff" : "initial",
                        color: "black",
                        borderColor: "lightgrey",
                      }}
                    >
                      <ItalicOutlined
                        style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                      />
                    </Button>
                  </span>

                  <span>
                    <Button
                      onClick={this.yAxisDecorOnChange}
                      style={{
                        marginLeft: "20px",
                        backgroundColor: this.state.yAxisTextDecoration ? "#9ed3ff" : "initial",
                        color: "black",
                        borderColor: "lightgrey",
                      }}
                    >
                      <UnderlineOutlined
                        style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                      />
                    </Button>
                  </span>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.FontColour")}
                  </Text>
                </Col>
                <Col span={10}>
                  <Button
                    id={"openMaxisColorPicker"}
                    onClick={this.yAxisValueFontColorChangeVisible}
                    style={{ width: "100%" }}
                  >
                    {this.state.yAxisFontColorOnChange
                      ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                      : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
                  </Button>
                  {this.state.yAxisColorChange ? (
                    <ColorPickerPanel
                      alpha={100}
                      color={this.state.yAxisFontColor}
                      onChange={this.yAxisFontColorOnChange}
                      id={"yAxisFontColor"}
                      style={{ marginTop: "5px", position: "absolute", right: 0 }}
                    />
                  ) : null}
                </Col>
                <Col span={2}>
                  <div
                    onClick={this.yAxisValueFontColorChangeVisible}
                    style={{
                      marginLeft: "5px",
                      height: "32px",
                      border: "1px solid #d7d6d6",
                      boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                      borderRadius: "4px",
                      cursor: "pointer",
                      backgroundColor: this.state.yAxisFontColor || "black",
                    }}
                  ></div>
                </Col>
              </Row>
            </>
          ) : null}
          {/* */}
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.XAxisStylingOption")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "3px" }}
                id={"ShowXAxisStylingOption"}
                defaultChecked={this.state.ShowXAxisStylingOption}
                checked={this.isXAxisValueDisable() ? false : this.state.ShowXAxisStylingOption ? true : false}
                onChange={this.handleCheck}
                disabled={this.isXAxisValueDisable()}
              ></Checkbox>
            </Col>
          </Row>
          {this.state.ShowXAxisStylingOption ? this.isXAxisValueDisable() ? null : (
            <>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <i
                    class="fa fa-font"
                    aria-hidden="true"
                    style={{ position: "relative", bottom: "-5px" }}
                  >
                    {" "}
                  </i>
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.Font")}
                  </Text>
                </Col>
                <Col span={12}>
                  <FontTypeSelector
                    id={"xAxisValueFont"}
                    placeholder={this.state.xAxisFont || "Verdana"}
                    onChange={this.xAxisFontOnChange}
                    value={this.state.xAxisFont || "Verdana"}
                  />
                </Col>
              </Row>

              <Row style={{ marginBottom: "5px" }}>
                <Col span={2} />
                <Col style={{ textAlign: "center" }} span={3}>
                  <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.ShowEllipsisXAxis")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Checkbox
                    style={{ float: "left", marginTop: "3px" }}
                    id={"showEllipsisOnXAxis"}
                    defaultChecked={this.state.showEllipsisOnXAxis}
                    checked={this.state.showEllipsisOnXAxis}
                    onChange={this.handleCheck}
                  ></Checkbox>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.FontSize")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Input
                    id={"xAxisFontSize"}
                    defaultValue={this.state.xAxisFontSize}
                    value={this.state.xAxisFontSize}
                    onChange={this.handleChange}
                    placeholder={this.state.xAxisFontSize}
                    type={"number"}
                    min={"0"}
                    max={"1000"}
                  />
                </Col>
              </Row>
              {Number(this.state.xAxisFontSize) > 30 || Number(this.state.xAxisFontSize) < 13 ? (
                <Row style={{ height: "11px" }}>
                  <Col span={2}> </Col>
                  <Col span={10}></Col>
                  <Col span={12} style={{ fontSize: "11px", textAlign: "left", color: "red" }}>
                    {Number(this.state.xAxisFontSize) > 30
                      ? "* " + i18n.t("Dashboard.Configuration.Fields.CantChangeMeasureSize", { size: `30` })
                      : "* " + i18n.t("Dashboard.Configuration.Fields.CantReduceMeasureSize", { size: `13` })}
                  </Col>
                </Row>
              ) : null}
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}></Col>

                <Col
                  style={{ textAlign: "center" }}
                  span={3}
                >
                  <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col
                  style={{ textAlign: "left" }}
                  span={7}
                >
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.XAxisFontStyle")}
                  </Text>
                </Col>
                <Col style={{ textAlign: "left" }} span={12}>
                  <span>
                    <Button
                      onClick={this.xAxisWeightOnChange}
                      style={{
                        backgroundColor: this.state.xAxisFontWeight ? "#9ed3ff" : "initial",
                        color: "black",
                        borderColor: "lightgrey",
                      }}
                    >
                      <BoldOutlined
                        style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                      />
                    </Button>
                  </span>

                  <span>
                    <Button
                      onClick={this.xAxisStyleOnChange}
                      style={{
                        marginLeft: "20px",
                        backgroundColor: this.state.xAxisFontStyle ? "#9ed3ff" : "initial",
                        color: "black",
                        borderColor: "lightgrey",
                      }}
                    >
                      <ItalicOutlined
                        style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                      />
                    </Button>
                  </span>

                  <span>
                    <Button
                      onClick={this.xAxisDecorOnChange}
                      style={{
                        marginLeft: "20px",
                        backgroundColor: this.state.xAxisTextDecoration ? "#9ed3ff" : "initial",
                        color: "black",
                        borderColor: "lightgrey",
                      }}
                    >
                      <UnderlineOutlined
                        style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                      />
                    </Button>
                  </span>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.FontColour")}
                  </Text>
                </Col>
                <Col span={10}>
                  <Button
                    id={"openMaxisColorPicker"}
                    onClick={this.xAxisValueFontColorChangeVisible}
                    style={{ width: "100%" }}
                  >
                    {this.state.xAxisFontColorOnChange
                      ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                      : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
                  </Button>
                  {this.state.xAxisColorChange ? (
                    <ColorPickerPanel
                      alpha={100}
                      color={this.state.xAxisFontColor}
                      onChange={this.xAxisFontColorOnChange}
                      id={"yAxisFontColor"}
                      style={{ marginTop: "5px", position: "absolute", right: 0 }}
                    />
                  ) : null}
                </Col>
                <Col span={2}>
                  <div
                    onClick={this.xAxisValueFontColorChangeVisible}
                    style={{
                      marginLeft: "5px",
                      height: "32px",
                      border: "1px solid #d7d6d6",
                      boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                      borderRadius: "4px",
                      cursor: "pointer",
                      backgroundColor: this.state.xAxisFontColor || "black",
                    }}
                  ></div>
                </Col>
              </Row>
            </>
          ) : null}
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.ValueSettings")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowLineValue")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "3px" }}
                id={"ShowLineValueOption"}
                defaultChecked={this.state.ShowLineValueOption}
                checked={this.state.ShowLineValueOption}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>

          {this.state.ShowLineValueOption ? (
            <>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <i
                    class="fa fa-font"
                    aria-hidden="true"
                    style={{ position: "relative", bottom: "-5px" }}
                  >
                    {" "}
                  </i>
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.Font")}
                  </Text>
                </Col>
                <Col span={12}>
                  <FontTypeSelector
                    id={"lineValueFont"}
                    placeholder={this.state.lineValueFont || "Verdana"}
                    onChange={this.lineValueFontOnChange}
                    value={this.state.lineValueFont || "Verdana"}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.FontSize")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Input
                    id={"lineValueFontSize"}
                    defaultValue={this.state.lineValueFontSize}
                    value={this.state.lineValueFontSize}
                    onChange={this.handleChange}
                    type={"number"}
                    min={"0"}
                    max={"1000"}
                  />
                </Col>
              </Row>
              {Number(this.state.lineValueFontSize) > 30 || Number(this.state.lineValueFontSize) < 13 ? (
                <Row style={{ height: "11px" }}>
                  <Col span={2}> </Col>
                  <Col span={10}></Col>
                  <Col span={12} style={{ fontSize: "11px", textAlign: "left", color: "red" }}>
                    {Number(this.state.lineValueFontSize) > 30
                      ? "* " + i18n.t("Dashboard.Configuration.Fields.CantChangeMeasureSize", { size: `30` })
                      : "* " + i18n.t("Dashboard.Configuration.Fields.CantReduceMeasureSize", { size: `13` })}
                  </Col>
                </Row>
              ) : null}
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.FontColour")}
                  </Text>
                </Col>
                <Col span={10}>
                  <Button
                    id={"openMaxisColorPicker"}
                    onClick={this.lineValueFontColorChangeVisible}
                    style={{ width: "100%" }}
                  >
                    {this.state.lineValueFontColorChange
                      ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                      : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
                  </Button>
                  {this.state.lineValueFontColorChange ? (
                    <ColorPickerPanel
                      alpha={100}
                      color={this.state.lineValueFontColor}
                      onChange={this.lineValueFontColorOnChange}
                      id={"lineValueFontColor"}
                      style={{ marginTop: "5px", position: "absolute", right: 0 }}
                    />
                  ) : null}
                </Col>
                <Col span={2}>
                  <div
                    onClick={this.lineValueFontColorChangeVisible}
                    style={{
                      marginLeft: "5px",
                      height: "32px",
                      border: "1px solid #d7d6d6",
                      boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                      borderRadius: "4px",
                      cursor: "pointer",
                      backgroundColor: this.state.lineValueFontColor || "black",
                    }}
                  ></div>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <HighlightOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.ColoredLineValue")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Checkbox
                    style={{ float: "left", marginTop: "3px" }}
                    id={"ColoredLineValues"}
                    defaultChecked={this.state.ColoredLineValues}
                    checked={this.state.ColoredLineValues}
                    onChange={this.handleCheck}
                  ></Checkbox>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.ValueAngle")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Input
                    id={"lineValueAngle"}
                    defaultValue={this.state.lineValueAngle}
                    value={this.state.lineValueAngle}
                    onChange={this.handleChange}
                    type={"number"}
                    min={"-360"} // angle value can be min -360
                    max={"360"} // angle value can be max 360
                  />
                </Col>
              </Row>
            </>
          ) : null}
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowBarValue")}
              </Text>
            </Col>
            {fontSizeConfig.showBarValueDisable ? (
              <Col span={12}>
                <Checkbox
                  style={{ float: "left", marginTop: "3px" }}
                  id={"ValueOnBarOption"}
                  defaultChecked={false}
                  checked={false}
                  disabled
                ></Checkbox>
              </Col>
            ) : (
              <Col span={12}>
                <Checkbox
                  style={{ float: "left", marginTop: "3px" }}
                  id={"ValueOnBarOption"}
                  defaultChecked={this.state.ValueOnBarOption}
                  checked={this.state.ValueOnBarOption}
                  onChange={this.handleCheckBar}
                  disable
                ></Checkbox>
              </Col>
            )}
          </Row>
          {this.state.ValueOnBarOption && fontSizeConfig.showBarValueDisable === false ? (
            <>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <i
                    class="fa fa-font"
                    aria-hidden="true"
                    style={{ position: "relative", bottom: "-5px" }}
                  >
                    {" "}
                  </i>
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.Font")}
                  </Text>
                </Col>
                <Col span={12}>
                  <FontTypeSelector
                    id={"barValueFont"}
                    placeholder={this.state.barValueFont || "Verdana"}
                    onChange={this.barValueFontOnChange}
                    value={this.state.barValueFont || "Verdana"}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <LineChartOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.BarValueAutomatic")}
                  </Text>
                </Col>
                {this.state.axisNumber === "Single_Stack" ? (
                  <Col span={12}>
                    <Checkbox
                      style={{ float: "left", marginTop: "3px" }}
                      id={"barValueFontSizeAutomatic"}
                      defaultChecked={this.state.barValueFontSizeAutomatic = false}
                      checked={this.state.barValueFontSizeAutomatic = false}
                      disabled
                    ></Checkbox>
                  </Col>
                ) : (
                  <Col span={12}>
                    <Checkbox
                      style={{ float: "left", marginTop: "3px" }}
                      id={"barValueFontSizeAutomatic"}
                      defaultChecked={this.state.barValueFontSizeAutomatic}
                      checked={this.state.barValueFontSizeAutomatic}
                      onChange={this.handleCheck}
                    ></Checkbox>
                  </Col>
                )}
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px",
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.FontSize")}
                  </Text>
                </Col>
                {this.state.axisNumber === "Single_Stack" || this.state.barValueFontSizeAutomatic === false ? (
                  <Col span={12}>
                    <InputNumber
                      id={"barValueFontSize"}
                      defaultValue={this.state.barValueFontSize}
                      value={this.state.barValueFontSize}
                      onChange={(e) => this.handleChangeFontSize(e)}
                      type={"number"}
                      min={this.state.barValueFontSizeMin}
                      max={this.state.barValueFontSizeMax}
                      style={{ width: "100%" }}
                    />
                  </Col>) : (
                  <Col span={12}>
                    <InputNumber
                      id={"barValueFontSize"}
                      defaultValue={""}
                      value={""}
                      type={"number"}
                      min={this.state.barValueFontSizeMin}
                      max={this.state.barValueFontSizeMax}
                      disabled
                      style={{ width: "100%" }}
                    />
                  </Col>
                )}
              </Row>
              {fontSizeConfig.warning != "default" ? (
                <Row style={{ height: "11px" }}>
                  <Col span={2}> </Col>
                  <Col span={10}></Col>
                  <Col span={12} style={{ fontSize: "11px", textAlign: "left", color: "red" }}>
                    {i18n.t(fontSizeConfig.warning)}
                  </Col>
                </Row>
              ) : null}
              <Row style={{ marginBottom: "5px" }}>
                <Col span={2}> </Col>
                <Col style={{ textAlign: "center" }} span={3}>
                  <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={7}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.FontColour")}
                  </Text>
                </Col>
                <Col span={10}>
                  <Button
                    id={"openMaxisColorPicker"}
                    onClick={this.barValueFontColorChangeVisible}
                    style={{ width: "100%" }}
                  >
                    {this.state.barValueFontColorChange
                      ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                      : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
                  </Button>
                  {this.state.barValueFontColorChange ? (
                    <ColorPickerPanel
                      alpha={100}
                      color={this.state.barValueFontColor}
                      onChange={this.barValueFontColorOnChange}
                      id={"barValueFontColor"}
                      style={{ marginTop: "5px", position: "absolute", right: 0 }}
                    />
                  ) : null}
                </Col>
                <Col span={2}>
                  <div
                    onClick={this.barValueFontColorChangeVisible}
                    style={{
                      marginLeft: "5px",
                      height: "32px",
                      border: "1px solid #d7d6d6",
                      boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                      borderRadius: "4px",
                      cursor: "pointer",
                      backgroundColor: this.state.barValueFontColor || "black",
                    }}
                  ></div>
                </Col>
              </Row>
            </>
          ) : null}
          <TitleSettingsOfPlugin
            plugin={this.props.plugin}
            handleChange={this.handleChange}
            commonTitleConfig={this.props.commonTitleConfig}
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
            setDefaultsForTitle={this.setDefaultsForTitle}
            title={this.state.title}
            titleAlign={this.state.titleAlign}
            titleColour={this.state.titleColour}
            titleColourChange={this.state.titleColourChange}
            titleColourChangeVisible={this.titleColourChangeVisible}
            titleFont={this.state.titleFont}
            titleFontSize={this.state.titleFontSize}
            keyForTitleSize={this.state.keyForTitleSize}
            titleFontStyle={this.state.titleFontStyle}
            titleFontWeight={this.state.titleFontWeight}
            titleTextDecor={this.state.titleTextDecor}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            changedTitleFontSize={this.state.changedTitleFontSize}
            handleChangeBothOfValues={this.handleChangeBothOfValues}
          />
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.Others")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Legend")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"legend"}
                defaultChecked={this.state.legend}
                checked={this.state.legend}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"condFormat"}
                defaultChecked={this.state.condFormat}
                checked={this.state.condFormat}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left", display: "flex" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Settings.NoDataConfiguration")}
              </Text>
              <Tooltip
                title={
                  <>
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                  </>
                }
              >
                <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <TextArea
                allowClear
                id={"noDataTitle"}
                defaultValue={this.state.noDataTitle}
                value={this.state.noDataTitle}
                onChange={this.handleChange}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={i18n.t("NoDataContent.NoData") + " " +
                  i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
