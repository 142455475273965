import uuid from "react-uuid";
import { checkAndFindDataSourceKeyToColumn } from "../../PluginComponents/columnDataSourceKeyOperations";

const clone = require("rfdc")();

//This js for general default filter functions

/* Adds default filter and updates */
export const addFilterForDataComponent = (
  column,
  tableAliasName,
  tableDisplayName,
  model,
  defaultFilters
) => {
  column.filterPredicate = "=";
  column.filterId = uuid();
  column.tableAliasName = tableAliasName;
  column.dataSourceKey = checkAndFindDataSourceKeyToColumn(column.dataSourceKey, tableAliasName, model);
  column.tableDisplayName = tableDisplayName;
  column.value = null;
  column.whereOperator = " AND ";

  return updateDefaultFiltersForDataComponent(column, "ADD", defaultFilters);
};

/* Updates default filter and return default filter */
export const updateDefaultFiltersForDataComponent = (
  filter,
  status,
  defaultFilters
) => {
  let tempDefaultFilters = [...defaultFilters];

  if (status == "ADD") {
    filter.protected = false;
    tempDefaultFilters.push(clone(filter));
  } else if (status == "DELETE") {
    tempDefaultFilters = tempDefaultFilters.filter(
      (f) => f.filterId !== filter.filterId
    );
  } else if (status == "UPDATE") {
    tempDefaultFilters.map((f, i) => {
      if (filter.filterId == f.filterId) {
        tempDefaultFilters[i] = clone(filter);
      }
    });
  } else if (status == "COMPARE") {
    tempDefaultFilters.map((f, i) => {
      let newFilter = clone(f)
      
      if (filter.filterId == f.filterId) {
        if (newFilter["compared"]) {
          newFilter["compared"] = false
        } else {
          newFilter["compared"] = true
        }
      } else {
        newFilter["compared"] = false
      }

      tempDefaultFilters[i] = newFilter;
    });
  }

  return tempDefaultFilters;
};
