import React, { Component } from "react";
import { Row } from "react-bootstrap";
import Report from "./Report";

class ListAllDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Row>
        {this.props.data.map((dashboard) => {
          return (
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <Report
                refresh={this.props.refresh}
                selectReport={this.props.selectReport}
                data={dashboard}
                dataLabel={this.props.dataLabel}
                dashboardDelete={this.props.dashboardDelete}
                dashboardReloaded={this.props.dashboardReloaded}
                dashboardDeletedPermanently={this.props.dashboardDeletedPermanently}
              />
            </div>
          );
        })}
      </Row>
    );
  }
}

export default ListAllDashboard;
