import React, { Component } from "react";
import { Row, Col, Input, InputNumber, Select, Radio, Button } from "antd";
import i18n from "../../../../../Utils/i18next";
import uuid from "react-uuid";
import { TYPE_INPUT, TYPE_SLIDER } from "./WhatIf";
import { convertNumber } from "./Parameter";
import { showNotificationWithIcon } from "../../../../../admin/Utils/Notification";
import { decideSeparator } from "../../../../../Utils/NumberLocalization";

const { Option } = Select;
const numberSeparator = decideSeparator();

/** What creation and edit operations. */
export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      id: undefined,
      name: "",
      displayName: "",
      defaultValue: null,
      currentValue: null,
      minValue: null,
      maxValue: null,
      incrementValue: null,
      dataType: "decimal",
      showType: TYPE_SLIDER
    };

    this.state = { ...this.initialState };
  }

  componentWillMount() {
    if (this.props.parameter !== undefined) {
      this.setState({ ...this.props.parameter });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.parameter !== nextProps.parameter) {
      if (nextProps.parameter === undefined) {
        this.setState({ ...this.initialState });
      } else {
        this.setState({ ...nextProps.parameter });
      }
    }
  }

  /** Handle changing input and set new value */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });
  };

  /** Handle changing number inputs and sets new value */
  handleChangeNumber = (e, field) => {
    let value = e;
    
    this.setState({
      ...this.state,
      [field]: value
    });
  }

  /** Handle data type and set new value */
  handleChangeDataType = value => {
    this.setState({
      ...this.state,
      dataType: value
    });
  };

  /** Handle show type and set new value */
  handleChangeShowType = e => {
    this.setState({
      ...this.state,
      showType: e.target.value
    });
  };

  /** What if apply button */
  applyButton = (
    <Button
      className={"general-button"}
      style={{ float: "right", margin: "0", width: "25%" }}
      onClick={() => this.apply()}
    >
      {i18n.t("Apply")}
    </Button>
  );

  /** What if set default button */
  clearButton = (
    <Button
      onClick={() => this.clear()}
      style={{
        float: "right",
        margin: "0 10px",
        borderBottom: "2px solid",
        width: "25%"
      }}
    >
      {i18n.t("Default")}
    </Button>
  );

  /** What if edit cancel button */
  cancelButton = (
    <Button
      onClick={() => this.cancel()}
      style={{
        float: "right",
        margin: "0 10px",
        borderBottom: "2px solid",
        width: "25%"
      }}
    >
      {i18n.t("Cancel")}
    </Button>
  );

  /** Add a parameter for what if with validation */
  apply = () => {
    let parameter = { ...this.state };
    let validation = this.applyValidation(parameter);

    if (validation.status) {
      if (parameter.id === undefined) {
        parameter.id = uuid();
      }

      parameter.currentValue = parameter.defaultValue;
      this.props.applyWhatIfParameter(parameter);
      this.clear();
    } else {
      showNotificationWithIcon(i18n.t("Error"), validation.message, "error");
    }
  };

  /** Checks any value is empty */
  isEmpty = value => {
    if (value === "" || value === null) {
      return true;
    }

    return false;
  };

  /** Validation for parameter */
  applyValidation = parameter => {
    let result = { status: true, message: "success" };

    if (
      //any parameter cannot be empty
      this.isEmpty(parameter.name) ||
      this.isEmpty(parameter.displayName) ||
      this.isEmpty(parameter.defaultValue) ||
      this.isEmpty(parameter.minValue) ||
      this.isEmpty(parameter.maxValue)
    ) {
      result.status = false;
      result.message = i18n.t(
        "Dashboard.Data.WhatIf.CreateUpdate.NoBlankSpaceCanBeLeft"
      );
    } else if (
      // if show type slider, increment value cannot be empty.
      parameter.showType === TYPE_SLIDER &&
      this.isEmpty(parameter.incrementValue)
    ) {
      result.status = false;
      result.message = i18n.t(
        "Dashboard.Data.WhatIf.CreateUpdate.NoBlankSpaceCanBeLeft"
      );
    } else if (
      parameter.showType === TYPE_SLIDER && convertNumber(parameter.incrementValue, parameter.dataType) <= 0
    ) {
      result.status = false;
      result.message = i18n.t(
        "Dashboard.Data.WhatIf.CreateUpdate.IncrementValueMustBeGreaterThanZero"
      );
    } else if (
      //default value cannot be lower than min value.
      convertNumber(parameter.defaultValue, parameter.dataType) <
      convertNumber(parameter.minValue, parameter.dataType)
    ) {
      result.status = false;
      result.message = i18n.t(
        "Dashboard.Data.WhatIf.CreateUpdate.DefaultValueCannotBeLowerThanMinValue"
      );
    } else if (
      //default value cannot be greater than max value.
      convertNumber(parameter.defaultValue, parameter.dataType) >
      convertNumber(parameter.maxValue, parameter.dataType)
    ) {
      result.status = false;
      result.message = i18n.t(
        "Dashboard.Data.WhatIf.CreateUpdate.DefaultValueCannotBeGreaterThanMaxValue"
      );
    } else if (
      //increment value cannot be greater then difference of max value and min value.
      convertNumber(parameter.incrementValue, parameter.dataType) >
      convertNumber(parameter.maxValue, parameter.dataType) -
        convertNumber(parameter.minValue, parameter.dataType)
    ) {
      result.status = false;
      result.message = i18n.t(
        "Dashboard.Data.WhatIf.CreateUpdate.IncrementValueCannotBeGreaterThanDifferenceOfMaxValueAndMinValue"
      );
    }

    return result;
  };

  /** clear what if creation */
  clear = () => {
    this.setState({ ...this.initialState });
  };

  /** Cancel what if create and update */
  cancel = () => {
    this.setState({ ...this.initialState });
    this.props.cancelEditParameter();
  };

  render() {
    return (
      <div className={"whatIf-create-update"}>
        <Row style={{ margin: "11px 0 20px" }}>
          <Col span={8}>
            {" "}
            {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.ShowType")}:
          </Col>
          <Col span={16}>
            <Radio.Group
              onChange={this.handleChangeShowType}
              value={this.state.showType}
            >
              <Radio value={TYPE_SLIDER}>
                {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.Slider")}{" "}
              </Radio>
              <Radio value={TYPE_INPUT}>
                {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.Input")}
              </Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Row style={{ margin: "0 0 30px 0" }} gutter={12}>
          <Col span={8} style={{ top: "5px" }}>
            {" "}
            {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.Name")}:
            <Input
              id={"name"}
              onChange={this.handleChange}
              value={this.state.name}
            ></Input>
          </Col>

          <Col span={8} style={{ top: "5px" }}>
            {" "}
            {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.DisplayName")}:
            <Input
              id={"displayName"}
              onChange={this.handleChange}
              value={this.state.displayName}
            ></Input>
          </Col>

          <Col span={8} style={{ top: "5px" }}>
            {" "}
            {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.DataType")}:
            <Select
              showSearch
              style={{ width: "100%" }}
              id={"dataType"}
              defaultValue={this.state.dataType}
              value={this.state.dataType}
              onChange={this.handleChangeDataType}
            >
              <Option value="integer">
                {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.Integer")}
              </Option>
              <Option value="decimal">
                {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.Decimal")}
              </Option>
              <Option value="percent">
                {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.Percent")}
              </Option>
            </Select>
          </Col>
        </Row>

        <Row style={{ margin: "0 0 20px 0" }} gutter={12}>
          <Col span={6} style={{ top: "5px" }}>
            {" "}
            {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.DefaultValue")}:
            <InputNumber
              id={"defaultValue"}
              onChange={(e) => this.handleChangeNumber(e, "defaultValue")}
              value={this.state.defaultValue}
              decimalSeparator={numberSeparator}
            ></InputNumber>
          </Col>

          <Col span={6} style={{ top: "5px" }}>
            {" "}
            {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.MinValue")}:
            <InputNumber
              id={"minValue"}
              onChange={(e) => this.handleChangeNumber(e, "minValue")}
              value={this.state.minValue}
              decimalSeparator={numberSeparator}
            ></InputNumber>
          </Col>

          <Col span={6} style={{ top: "5px" }}>
            {" "}
            {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.MaxValue")}:
            <InputNumber
              id={"maxValue"}
              onChange={(e) => this.handleChangeNumber(e, "maxValue")}
              value={this.state.maxValue}
              decimalSeparator={numberSeparator}
            ></InputNumber>
          </Col>

          {this.state.showType === TYPE_SLIDER ? (
            <Col span={6} style={{ top: "5px" }}>
              {" "}
              {i18n.t("Dashboard.Data.WhatIf.CreateUpdate.IncrementValue")}:
              <InputNumber
                id={"incrementValue"}
                onChange={(e) => this.handleChangeNumber(e, "incrementValue")}
                value={this.state.incrementValue}
                decimalSeparator={numberSeparator}
              ></InputNumber>
            </Col>
          ) : null}
        </Row>

        <Row
          style={{
            marginTop: "10px",
            borderTop: "1px solid #e8e8e8",
            paddingTop: "10px"
          }}
        >
          {this.applyButton}
          {this.state.id === undefined ? this.clearButton : this.cancelButton}
        </Row>
      </div>
    );
  }
}
