import {
  BgColorsOutlined,
  EyeInvisibleOutlined,
  FontSizeOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, Row, Select, Tooltip, Icon } from "antd";
import $ from "jquery";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import { store } from "../../../..";
import i18n from "../../../../Utils/i18next";
import Text from "../../../GeneralComponents/Text/Text";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import { InsightsConfig } from "../../RenderJs/config";
import { themeFields } from "../common";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { TextArea } = Input;
const { Option } = Select;

export default class PieChartEnhancedConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: this.props.config.backgroundColor,
      colours: this.props.config.colours,
      dataType: this.props.config.dataType !== undefined ? this.props.config.dataType : "numeric",
      innerRadius: this.props.config.innerRadius,
      legend: this.props.config.legend,
      showHideButton: this.props.config.showHideButton,
      showMeasureOnPie: this.props.config.showMeasureOnPie,
      showMeasureWithLines: this.props.config.showMeasureWithLines,
      size: this.props.config.size,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      paletteColours: InsightsConfig.Palettes[this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours]
        : this.props.config.colours,
      condFormat: this.props.config.condFormat,
      legendPos: this.props.config.legendPos !== undefined ? this.props.config.legendPos : "bottom",
      showMeasures: this.props.config.showMeasures == undefined ? true : this.props.config.showMeasures,
      showMeasuresWith: this.props.config.showMeasuresWith !== undefined ? this.props.config.showMeasuresWith : "showMeasuresWithLine",
      selectMultiple: this.props.config.selectMultiple == undefined ? true : this.props.config.selectMultiple,
      legendType: this.props.config.legendType !== undefined ? this.props.config.legendType : "percent-numeric",
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      threeDVisulisation: this.props.config.threeDVisulisation,
      visuliateByRadius: this.props.config.visuliateByRadius,
      isPluginExplodePieChart: false,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle,
      targetSumOperation: this.props.config.targetSumOperation === undefined ? "sum" : this.props.config.targetSumOperation
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        backgroundColor: nextProps.config.backgroundColor,
        colours: nextProps.config.colours,
        dataType: nextProps.config.dataType,
        innerRadius: nextProps.config.innerRadius,
        legend: nextProps.config.legend,
        showHideButton: nextProps.config.showHideButton,
        showMeasureOnPie: nextProps.config.showMeasureOnPie,
        showMeasureWithLines: nextProps.config.showMeasureWithLines,
        size: nextProps.config.size,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours]
          : nextProps.config.colours,
        condFormat: nextProps.config.condFormat,
        legendPos: nextProps.config.legendPos !== undefined ? nextProps.config.legendPos : "bottom",
        showMeasures: nextProps.config.showMeasures == undefined ? true : nextProps.config.showMeasures,
        showMeasuresWith: nextProps.config.showMeasuresWith !== undefined ? nextProps.config.showMeasuresWith : "showMeasuresWithLine",
        selectMultiple: nextProps.config.selectMultiple == undefined ? true : nextProps.config.selectMultiple,
        threeDVisulisation: nextProps.config.threeDVisulisation,
        visuliateByRadius: nextProps.config.visuliateByRadius,
        legendType: nextProps.config.legendType !== undefined ? nextProps.config.legendType : "percent-numeric",
        legendType: nextProps.config.legendType,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        titleAlign: nextProps.config.titleAlign,
        threeDVisulisation: nextProps.config.threeDVisulisation,
        visuliateByRadius: nextProps.config.visuliateByRadius,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,
        targetSumOperation: nextProps.config.targetSumOperation === undefined ? "sum" : nextProps.config.targetSumOperation
      });
    }
  }

  componentDidMount() {
    let reduxState = store.getState()
    let hasPluginDrilldown = reduxState.DrillDownReducer.drillDowns.has(this.props.plugin.id) ? true : false
    let drillDownsReducer = hasPluginDrilldown && reduxState.DrillDownReducer.drillDowns.get(this.props.plugin.id) ? reduxState.DrillDownReducer.drillDowns.get(this.props.plugin.id) : {}
    let isDrillExplodePieChart = drillDownsReducer.drillDownTypes && drillDownsReducer.drillDownTypes === "explode-pie-chart" ? true : false

    this.setState({
      ...this.state,
      isPluginExplodePieChart: isDrillExplodePieChart
    })
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /*
  * i needed to handle min and max values of input so i writed this new handlechange function
  */
  handleChangeMax = e => {
    let { value, min, max } = e.target
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    this.setState({
      ...this.state,
      [e.target.id]: value
    });

    this.setConfigToPlugin({ type: e.target.id, value: value });
  };

  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };


  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  colorChangeVisible = () => {
    this.setState({ ...this.state, colorChange: !this.state.colorChange });
  };

  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /*
* handle palette selection func.
*/
  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };


  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  showMeasureWithLinesOnChange = obj => {
    this.setState({
      ...this.state,
      showMeasureWithLines: obj.value
    });

    this.setConfigToPlugin({ type: "showMeasureWithLines", value: obj.value });
  };

  showMeasureOnPieOnChange = obj => {
    this.setState({
      ...this.state,
      showMeasureOnPie: obj.value
    });

    this.setConfigToPlugin({ type: "showMeasureOnPie", value: obj.value });
  };

  dataTypeOnChange = value => {
    this.setState({
      ...this.state,
      dataType: value
    });

    this.setConfigToPlugin({ type: "dataType", value: value });
  };

  legendPosOnChange = value => {
    this.setState({
      ...this.state,
      legendPos: value
    });

    this.setConfigToPlugin({ type: "legendPos", value: value });
  };

  legendTypeOnChange = value => {
    this.setState({
      ...this.state,
      legendType: value
    });

    this.setConfigToPlugin({ type: "legendType", value: value });
  }

  measureTypeOnChange = value => {
    this.setState({
      ...this.state,
      showMeasuresWith: value
    });

    this.setConfigToPlugin({ type: "showMeasuresWith", value: value });
  };

  dataTypeChangeVisible = () => {
    this.setState({
      ...this.state,
      dataTypeOnChange: !this.state.dataTypeOnChange
    });
  };

  /*
  * On change target sum operation
  */
  targetSumOperationOnChange = value => {
    this.setState({
      ...this.state,
      targetSumOperation: value
    });

    this.setConfigToPlugin({ type: "targetSumOperation", value: value });
  };

  render() {
    return (
      <>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <div style={{ color: "#505050" }}>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
          </Divider>

          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ThreeDVisulisation")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"threeDVisulisation"}
                defaultChecked={this.state.threeDVisulisation}
                disabled={this.state.isPluginExplodePieChart ? true : false}
                checked={this.state.threeDVisulisation}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>

          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.VisuliateByRadius")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"visuliateByRadius"}
                defaultChecked={this.state.visuliateByRadius}
                checked={this.state.visuliateByRadius}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>

          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.InnerRadius")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"innerRadius"}
                defaultValue={this.state.innerRadius}
                value={this.state.innerRadius}
                onChange={this.handleChangeMax} //im using new handleChange function here.
                type={"number"} //only number for input
                min={"0"}
                max={"100"} //when i give this value 100 or greater,the chart is looking bad
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Palette")}
              </Text>
            </Col>
            <Col span={4} style={{ paddingRight: "10px" }}>
              <Select
                id={"colours"}
                defaultValue={this.state.colours}
                value={this.state.colours}
                style={{ width: "100%" }}
                onChange={this.coloursOnChange}>
                <Option value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
                <Option value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
                <Option value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
                <Option value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
                <Option value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
              </Select>
            </Col>
            <Col span={8}>
              <ThemePalette
                colours={this.state.paletteColours}
                update={this.setConfigToPlugin}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.backgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.backgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}

              </Button>
              {this.state.backgroundColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.backgroundColor}
                  onChange={this.backgroundColorOnChange}
                  id={"backgroundColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.DataGrouping")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.AggregationRule")}
              </Text>
            </Col>
            <Col span={12} style={{ paddingRight: "10px" }}>
              <Select
                id={"targetSumOperation"}
                defaultValue={this.state.targetSumOperation}
                value={this.state.targetSumOperation}
                style={{ width: "100%" }}
                onChange={this.targetSumOperationOnChange}>
                <Option id={"none"} value="none">{i18n.t("Dashboard.Configuration.Fields.None")}</Option>
                <Option id={"sum"} value="sum">{i18n.t("Dashboard.Configuration.Fields.Sum")}</Option>
                <Option id={"average"} value="avg">{i18n.t("Dashboard.Configuration.Fields.Average")}</Option>
                <Option id={"min"} value="min">{i18n.t("Dashboard.Configuration.Fields.Minimum")}</Option>
                <Option id={"max"} value="max">{i18n.t("Dashboard.Configuration.Fields.Maximum")}</Option>
                <Option id={"count"} value="count">{i18n.t("Dashboard.Configuration.Fields.Count")}</Option>
              </Select>
            </Col>
          </Row>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.EnhancedConfiguration")}
          </Divider>
          <Row style={{ height: "11px" }}>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.SelectMultiple")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"selectMultiple"}
                defaultChecked={this.state.selectMultiple}
                checked={this.state.selectMultiple}
                onChange={this.handleCheck}
                disabled={this.state.isPluginExplodePieChart ? true : false}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"condFormat"}
                defaultChecked={this.state.condFormat}
                checked={this.state.condFormat}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col span={14}>
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Dashboard.Configuration.Fields.LegendConfiguration")}
              </Divider></Col>
            <Col span={10} style={{ fontSize: "11px", color: "red" }}> {"* " + i18n.t("Dashboard.Configuration.Fields.LegendsMustNotShowed")}</Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.dataType")}
              </Text>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={i18n.t("Dashboard.Configuration.Fields.dataType")}
                optionFilterProp="children"
                onChange={this.dataTypeOnChange}
                defaultValue={this.state.dataType}
              >
                <Option value="numeric">{i18n.t("Dashboard.Configuration.Fields.Numeric")}</Option>
                <Option value="percent">{i18n.t("Dashboard.Configuration.Fields.Percentage")}</Option>
                <Option value="percent-numeric">{i18n.t("Dashboard.Configuration.Fields.NumericPercentage")}</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Legend")}
              </Text>
            </Col>
            <Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Col span={2} style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <Checkbox
                  style={{ float: "left" }}
                  id={"legend"}
                  defaultChecked={this.state.legend}
                  checked={this.state.legend}
                  onChange={this.handleCheck}
                ></Checkbox>
              </Col>
              <Col
                span={21}
                style={{ fontSize: "9px", textAlign: "left", color: "red", marginLeft: "5px" }}
              >
              </Col>
            </Col>
          </Row>
          {this.state.legend ?
            <>
              <Row style={{ marginBottom: "5px" }}>
                <Col style={{ textAlign: "center" }} span={3}>
                  <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
                </Col>
                <Col style={{ textAlign: "left" }} span={9}>
                  <Text
                    style={{
                      fontSize: "16px",
                      display: "inline-block",
                      marginTop: "2px"
                    }}
                  >
                    {i18n.t("Dashboard.Configuration.Fields.LegendPosition")}
                  </Text>
                </Col>
                <Col span={12}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder={i18n.t("Dashboard.Configuration.Fields.LegendPosition")}
                    optionFilterProp="children"
                    onChange={this.legendPosOnChange}
                    defaultValue={this.state.legendPos}
                  >
                    <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
                    <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
                    <Option value="top">{i18n.t("Dashboard.Configuration.Fields.Top")}</Option>
                    <Option value="bottom">{i18n.t("Dashboard.Configuration.Fields.Bottom")}</Option>
                  </Select>
                </Col>
              </Row></>
            : null}
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col span={14}>
              <Divider className={"config-divider"} orientation="left">
                {i18n.t("Dashboard.Configuration.Fields.EnhancedMeasureConfiguration")}
              </Divider>
            </Col>
            <Col span={10} style={{ fontSize: "11px", color: "red" }}>
              {"* " + i18n.t("Dashboard.Configuration.Fields.ShowMeasureMustNotShowed")}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col span={9} style={{ textAlign: "left" }}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ShowMeasure")}
                <Tooltip
                  overlayClassName={"table-plugin-links"}
                  title={i18n.t("Dashboard.Configuration.Fields.ShowMeasureDescriptionInfo")}
                >
                  <span
                    className={"table-plugin-link-info"}
                  >
                    (?)
                  </span>
                </Tooltip>
              </Text>
            </Col>
            <Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Col span={2} style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <Checkbox
                  style={{ float: "left" }}
                  id={"showMeasures"}
                  defaultChecked={this.state.showMeasures}
                  checked={this.state.showMeasures}
                  onChange={this.handleCheck}
                ></Checkbox>
              </Col>
              <Col
                span={21}
                style={{ fontSize: "9px", textAlign: "left", color: "red", marginLeft: "5px" }}
              >
              </Col>
            </Col>
          </Row>
          {this.state.showMeasures ?
            <Row style={{ marginBottom: "5px" }}>
              <Col style={{ textAlign: "center" }} span={3}>
                <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
              </Col>
              <Col style={{ textAlign: "left" }} span={9}>
                <Text
                  style={{
                    fontSize: "16px",
                    display: "inline-block",
                    marginTop: "2px"
                  }}
                >
                  {i18n.t("Dashboard.Configuration.Fields.MeasureType")}
                </Text>
              </Col>
              <Col span={12}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={i18n.t("Dashboard.Configuration.Fields.LegendPosition")}
                  optionFilterProp="children"
                  onChange={this.measureTypeOnChange}
                  defaultValue={this.state.showMeasuresWith}
                >
                  <Option value="showMeasuresWithLine">{i18n.t("Dashboard.Configuration.Fields.ShowMeasureWithLines")}</Option>
                  <Option value="showMeasuresWithPie">{i18n.t("Dashboard.Configuration.Fields.ShowMeasureOnPie")}</Option>
                </Select>
              </Col>
            </Row> : null}
          <TitleSettingsOfPlugin
            plugin={this.props.plugin}
            handleChange={this.handleChange}
            commonTitleConfig={this.props.commonTitleConfig}
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
            setDefaultsForTitle={this.setDefaultsForTitle}
            title={this.state.title}
            titleAlign={this.state.titleAlign}
            titleColour={this.state.titleColour}
            titleColourChange={this.state.titleColourChange}
            titleColourChangeVisible={this.titleColourChangeVisible}
            titleFont={this.state.titleFont}
            titleFontSize={this.state.titleFontSize}
            keyForTitleSize={this.state.keyForTitleSize}
            titleFontStyle={this.state.titleFontStyle}
            titleFontWeight={this.state.titleFontWeight}
            titleTextDecor={this.state.titleTextDecor}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            changedTitleFontSize={this.state.changedTitleFontSize}
            handleChangeBothOfValues={this.handleChangeBothOfValues}
          />
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.Others")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left", display: "flex" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Settings.NoDataConfiguration")}
              </Text>
              <Tooltip
                title={
                  <>
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                  </>
                }
              >
                <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <TextArea
                allowClear
                id={"noDataTitle"}
                defaultValue={this.state.noDataTitle}
                value={this.state.noDataTitle}
                onChange={this.handleChange}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={i18n.t("NoDataContent.NoData") + " " +
                  i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
