import React, { Component } from "react";
import PopupContainer from "../../../GeneralComponents/PopupContainer/PopupContainer";
import BoxItem from "../../../GeneralComponents/BoxItem/BoxItem";
import { Popconfirm } from "antd";
import Text from "../../../GeneralComponents/Text/Text";
import Button from "../../../GeneralComponents/Button/Button";
import i18n from "../../../../Utils/i18next";
import { decideToDefaultFormat } from "../../PluginComponents/format";
import { columnHasConditionalFormats } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import ColumnProperties from "./ColumnProperties";
import AddDrillDown from "../../../DrillDown/AddDrillDown";
import { store } from "../../../..";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { deepCopy } from "../../../../Utils/Global";
import { getPluginsAllColumnsByField } from "../../../../Utils/PluginOperations";
import { RiFileSearchFill, RiFileSearchLine } from "react-icons/ri";
import { changeDisabledColumnsStatus } from "../../../GeneralComponents/CustomDashboard/CustomDashboardAction";
import $ from "jquery";
/**
 * List Data Columns.
 * Includes boxItem and extra methods.
 */
export default class DataColumn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isThreePointVisible: false,
      popconfirmVisible: false,
      columnIndex: null,
      isReadOnlyAndCustomDashboardMode: false,
      isWriteMode: false,
      isCustomDashboardMode: false,
      isDisabledColumn: false
    };
  }

  /*
  * When component mount, controls is write mode and dashboard mode active, then checks visible of drag drop content
  */
  componentDidMount() {
    let reduxState = store.getState()
    let isCustomDashboardMode = reduxState.CustomDashboardReducer.isCustomDashboard
    let isWriteMode = isValidWriteRoles()
    let isDisabledColumn = this.props.column.isDisabledColumn

    document.addEventListener("mousedown", this.checkIsColumnProperties);

    this.setState({
      ...this.state,
      isReadOnlyAndCustomDashboardMode: isCustomDashboardMode && !isWriteMode,
      isWriteMode: isWriteMode,
      isCustomDashboardMode: isCustomDashboardMode,
      isDisabledColumn: isDisabledColumn
    })
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.checkIsColumnProperties);
  }

  componentWillReceiveProps(nextProps) {
    /** Close all extras. */
    if (
      nextProps.closeThreePointPopup == true &&
      (nextProps.exceptPopupIndex !== this.props.columnIndex || this.props.comesFrom === "drilldown")
    ) {
      this.setState({
        isThreePointVisible: false
      });

      this.props.isCloseOthers(false, 0);
    }
  }

  //Closes the popup when click out
  checkIsColumnProperties = (e) => {
    let clickControl = !e.target.classList.contains('ant-modal-wrap') || !e.target.classList.contains('ant-modal-centered')
    let isInside = $(e.target).closest(".data-column-undefined").length > 0
    let isDropdown = $(e.target).closest(".ant-select-dropdown-menu").length > 0
    let isProperties = clickControl && !isInside && !isDropdown

    if(isProperties){
      this.setState({
        isThreePointVisible: false
      })
    }
  }

  toggleThreePoints = () => {
    /** Open column config and set fields. */
    if (this.state.isThreePointVisible === false) {
      this.setState({
        isThreePointVisible: true,
        columnIndex: this.props.columnIndex
      });
    } 

    if (typeof this.props.closeOtherPopups === "function") {
      if (this.props.closeOtherPopups) {
        this.props.closeOtherPopups(true, this.props.columnIndex, "three-point");
      }  
    }
  };

  /** Close column config. */
  closeThreePoints = () => {
    this.setState({
      isThreePointVisible: false
    });
  };

  /*
  * Gets datatype by column property
  */
  getDataTypeByColumnProperties = (columnProperties, column) => {
    let newColumnDataType = ""
    let copiedColumn = deepCopy(column)
    let columnDataType = columnProperties.DataFormatType
    let columnDataTypeSubtitle = columnProperties.DataFormatTypeSubTitle

    if (columnDataType === "number") {
      if (columnDataTypeSubtitle === "wholeNumber") {
        newColumnDataType = "integer"
      } else {
        newColumnDataType = "double"
      }
    } else if (columnDataType === "text") {
      newColumnDataType = "varchar"
    } else if (columnDataType === "time") {
      if (columnDataTypeSubtitle === "date") {
        newColumnDataType = "date"
      } else {
        newColumnDataType = "timestamp"
      }
    } else if (columnDataType === "custom") {
      newColumnDataType = "custom"
    }

    if (!copiedColumn["dataType"]) {
      copiedColumn["dataType"] = columnProperties.originalDataType

      columnProperties.dataType = columnProperties.originalDataType
    }

    if (!column.originalDataType) {
      columnProperties.originalDataType = copiedColumn.dataType
    } else {
      columnProperties.originalDataType = column.originalDataType
    }

    if (newColumnDataType !== "custom") {
      copiedColumn["dataType"] = newColumnDataType

      columnProperties.dataType = newColumnDataType
    } else {
      columnProperties.DataFormatType = "custom"
      columnProperties.dataType = copiedColumn.dataType
    }

    return copiedColumn
  }

  updateColumnProperties = (columnProperties, whatIf = false) => {
    let column = this.getDataTypeByColumnProperties(columnProperties, this.props.column)

    this.props.changeColumnProperties(
      columnProperties,
      this.props.type,
      column,
      whatIf
    );
  };

  /**
   * The popover component manages the visible change and calls related methods according to the conditional states.
   */
  handleVisibleChange = popconfirmVisible => {
    if (!popconfirmVisible) {
      this.setState({ popconfirmVisible, conditionalFormats: undefined });
      return;
    }

    let condFormats = columnHasConditionalFormats(this.props);

    // Determining condition before show the popconfirm.
    if (
      condFormats &&
      condFormats.length !== this.props.conditionalFormats.length
    ) {
      this.setState({ popconfirmVisible, conditionalFormats: condFormats }); // show the popconfirm
    } else {
      this.removeColumnOnMapping(); // next step
    }
  };

  addAndShowDefaultFilters = () => {
    //First add's default filter and show default filter component.
    this.props.addFilter(this.props.column, this.props.column.tableAliasName, this.props.column.tableDisplayName)
    this.props.showDefaultFilters(true)
  }

  /**
   * Calls the corresponding method to delete the column
   */
  removeColumnOnMapping = () => {
    let conditionalFormats;

    if (
      this.state.conditionalFormats !== undefined &&
      this.state.conditionalFormats.length !==
      this.props.conditionalFormats.length
    ) {
      conditionalFormats = this.state.conditionalFormats;
    } else {
      conditionalFormats = undefined;
    }

    this.props.removeColumnOnMapping(
      this.props.column,
      this.props.type,
      conditionalFormats
    );
  };

  /**
   * Extras of boxItem component.
   * Includes three points and this extras.
   **/
  getExtra = () => {
    let drillDownVisible =
      this.props.includesDrillDown === true &&
      (this.props.column.aggrRule === null ||
        this.props.column.aggrRule === "");

    return (
      <div style={{ height: "100%" }}>
        {drillDownVisible ? (
          <AddDrillDown
            componentTHIS={this.props.componentTHIS}
            type={this.props.type}
            updateDrillDowns={this.props.updateDrillDowns}
            parentColumn={this.props.column
            }
            refreshPlugin={this.props.refreshPlugin}
            disabled={this.props.disabledDrillDown}
            fieldWarning={this.props.drillDownFieldWarning}
            closeOtherPopups={this.props.closeOtherPopups}
            columnIndex={this.props.columnIndex}
            comesFrom={this.props.comesFrom}
            plugin={this.props.plugin}
          ></AddDrillDown>
        ) : null}
        {this.props.showDefaultFilters !== undefined ? <a class="fas fa-filter" style={{ fontSize: "12px" }} onClick={() => this.addAndShowDefaultFilters()}></a> : null}
        <a
          href="javascript:;"
          class="btn btn-sm moreIcon"
          onClick={() => this.toggleThreePoints()}
          style={{
            fontSize: "12px",
            top: "calc(50% - 14px)",
            position: "relative",
            color: "#484848"
          }}
        >
          <i
            name={this.props.column.displayName + " Content Of Column"}
            class="fas fa-ellipsis-v"
          ></i>
          <div
            style={{
              display:
                this.state.isThreePointVisible === true ? "block" : "none"
            }}
          >
            <PopupContainer
              className={`data-column-${this.props.pluginId}`}
              top={"33px"}
              position={"right"}
              width={
                this.props.componentTHIS && this.props.componentTHIS.positionConfiguration
                  ? this.props.componentTHIS.positionConfiguration.width
                  : "300px"
              }
              positionCalibration={
                this.props.componentTHIS && this.props.componentTHIS.positionConfiguration
                  ? this.props.componentTHIS.positionConfiguration
                    .positionCalibration
                  : "15px"
              }
              marginBottom={"20px"}
            >
              <ColumnProperties
                componentTHIS={this.props.componentTHIS}
                isThreePointVisible={this.state.isThreePointVisible}
                updateColumnProperties={this.updateColumnProperties}
                column={this.props.column}
                plugin={this.props.plugin}
                dropdownClassName={`default-filter-select-${this.props.pluginId}`}
              />
              <Button
                id={"closeContentOfColumn " + this.props.column.displayName}
                style={{
                  position: "absolute",
                  top: "3px",
                  right: "15px",
                  borderRadius: "40px",
                  padding: "6px 11px",
                  backgroundColor: "white",
                  zIndex: 2
                }}
                type={"default"}
                onClick={() => this.closeThreePoints()}
              >
                X
              </Button>
            </PopupContainer>
          </div>
        </a>
      </div>
    );
  };

  removeIcon = () => {
    return (
      <div className={this.props.type === "drilldown" ? "data-column-remove-icon-drilldown" : "data-column-remove-icon"}>
        <Popconfirm
          title={
            <Text
              type={"span"}
              style={{ fontSize: "13px", maxWidth: "5px !important" }}
            >
              {i18n.t("Dashboard.Data.UseConditionalFormatting")} <br></br>{" "}
              {i18n.t("Dashboard.Data.DeleteConditionalFormatting")} <br></br>
              {i18n.t("Dashboard.Data.AreYouSure")}
            </Text>
          }
          onConfirm={this.removeColumnOnMapping}
          onVisibleChange={this.handleVisibleChange}
          okText={i18n.t("Yes")}
          cancelText={i18n.t("No")}
          visible={this.state.popconfirmVisible}
          okButtonProps={{
            id: "truncateFileAccept"
          }}
          cancelButtonProps={{ id: "truncateFileCancel" }}
        >
          <a href="javascript:;">
            <i
              name={this.props.column.displayName + " Remove Column"}
              class="fa fa-times"
              style={{ fontSize: "14px", color: "#ff8d8d" }}
            ></i>
          </a>
        </Popconfirm>
      </div>
    );
  };

  updateColumnMap = () => {
    let componentThis = this.props.componentTHIS
    let pluginColumnMap = deepCopy(componentThis.props.columnMap)
    let item = this.props.column
    let reduxState = store.getState()

    pluginColumnMap[item.locationFieldName].data.filter(d => d.uniqeColumnId === item.uniqeColumnId)[0].isDisabledColumn = !item.isDisabledColumn

    componentThis.updateColumnMapData(pluginColumnMap[item.locationFieldName].data, item.locationFieldName)

    let addedDisabledColumnsMap = new Set(reduxState.CustomDashboardReducer.disabledColumnsMap)

    if (item.isDisabledColumn) {
      addedDisabledColumnsMap.add(item.uniqeColumnId)
    } else {
      addedDisabledColumnsMap.delete(item.uniqeColumnId)
    }

    store.dispatch(changeDisabledColumnsStatus(addedDisabledColumnsMap))

    this.setState({
      ...this.state,
      isDisabledColumn: !item.isDisabledColumn
    })
  }

  /**
   * Checks if column has an error, returns error message
   *  - Column display name already exists
   * 
   * @returns 
   */
  checkColumnError = () => {
    let column = this.props.column;

    if (this.props.onColumnMap) {      
      let pluginId =  this.props.plugin?.id;
      let reduxState = store.getState();
      let drillDowns = reduxState.DrillDownReducer.drillDowns;
      let allDrillDownColumnsInPlugin = drillDowns?.get(pluginId)?.allDrillDownColumnsInPlugin?.values() || [];
      let gettedCols = getPluginsAllColumnsByField(this.props.plugin?.columnMap);

      let sortedColumnList = this.props.plugin?.sortedColumnList || [];
      let sortedColumnIds = sortedColumnList.map(c => c.uniqeColumnId);
            
      allDrillDownColumnsInPlugin = [...allDrillDownColumnsInPlugin].flat();
      allDrillDownColumnsInPlugin = allDrillDownColumnsInPlugin.filter(c => c.drillDownParentColumnId && sortedColumnIds.includes(c.drillDownParentColumnId));

      let allColumns = [...gettedCols, ...allDrillDownColumnsInPlugin];
     
      for (let col of allColumns) {
        if (col.displayName === column.displayName && col.uniqeColumnId !== column.uniqeColumnId) {
          return i18n.t("Dashboard.Data.ExistingNameError");
        }
      }
    }
  }

  render() {
    let extra = null;
    let error = this.checkColumnError();

    if (this.props.extra === true) {
      extra = this.getExtra();
    } else if (this.props.filter) {
      extra = this.props.filter;
    }

    let removeIconStatus = true;

    if (this.props.remove === false) {
      removeIconStatus = false;
    }

    return (
      <div style={{ position: "relative" }}>
        {this.state.isWriteMode ?
          <>
            {removeIconStatus !== false ? this.removeIcon() : null}
          </> : null}
        {this.props.type !== "drilldown" ?
          <>
            {
              (this.state.isWriteMode || this.state.isCustomDashboardMode) && this.props.column.locationFieldName !== "hidden" && this.props.onColumnMap
                ? !this.props.column.isDisabledColumn === true
                  ? <EyeOutlined onClick={() => this.updateColumnMap()} style={this.state.isWriteMode ? { left: "24px" } : { left: "8px" }} className="hide-box-item" />
                  : <EyeInvisibleOutlined onClick={() => this.updateColumnMap()} style={this.state.isWriteMode ? { left: "24px" } : { left: "8px" }} className="hide-box-item" />
                : null
            }
            {
              this.props.column.locationFieldName === "hidden" && this.props.onColumnMap
                ? !this.props.column.isDisabledColumn === true
                  ? <EyeOutlined onClick={() => this.updateColumnMap()} style={this.state.isWriteMode ? { left: "24px" } : { left: "8px" }} className="hide-box-item" />
                  : <EyeInvisibleOutlined onClick={() => this.updateColumnMap()} style={this.state.isWriteMode ? { left: "24px" } : { left: "8px" }} className="hide-box-item" />
                : null
            }
          </> : null}

        <BoxItem
          showTooltip={this.state.isThreePointVisible === false}
          tooltipTitle={this.props.column.displayName}
          title={this.props.column.displayName}
          divClassName={"noStyle"}
          className={this.props.onColumnMap ? "onColumnMap" : ""}
          style={this.props.boxStyle || { cursor: "grab" }}
          extra={extra}
          error={error}
          onColumnMap={this.props.onColumnMap ? true : false}
          isReadOnlyAndCustomDashboardMode={this.state.isReadOnlyAndCustomDashboardMode}
          isDisabledColumn={this.state.isDisabledColumn}
          disabledDrillDown={this.props.disabledDrillDown}
        ></BoxItem>{" "}
      </div>
    );
  }
}
