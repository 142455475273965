import { store } from "../../../index";

export const PLUGIN_LOADER_VISIBILITY = "PLUGIN_LOADER_VISIBILITY"

/**
 * 
 * @param {*}
 * @returns 
 * 
 * Sets plugin loader visibility
 */
export function changePluginLoaderVisibility(pluginId, status) {
  let reduxState = store.getState();
  let waitForLoadPlugins = reduxState.PluginLoaderReducer.waitForLoadPlugins;

  if (status === true) {
    waitForLoadPlugins.add(pluginId)
  } else if (status === false && waitForLoadPlugins.has(pluginId)) {
    waitForLoadPlugins.delete(pluginId)
  }

  let payload = {
    waitForLoadPlugins: waitForLoadPlugins
  }

  return (dispatch) => {
    dispatch({
      type: PLUGIN_LOADER_VISIBILITY,
      payload: payload,
    });
  };
}
