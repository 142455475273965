import { Button, Checkbox, Col, Row, Select } from "antd";
import React, { Component } from "react";
import Text from "../GeneralComponents/Text/Text";
import i18n from "../../Utils/i18next";
import {
  BorderTopOutlined,
  CheckSquareOutlined,
  FilterOutlined,
  RollbackOutlined
} from "@ant-design/icons";
import { changeDisabledColumnsStatus } from "../GeneralComponents/CustomDashboard/CustomDashboardAction";
import { store } from "../..";
import { filter, isEqual } from "lodash";
import Tooltip from "../GeneralComponents/Tooltip/Tooltip";
import { defaultInteractionObjects, defaultReactionObjects, defaultTitleReactions } from "./DefaultInteractionObjects";

const { Option } = Select;
/**
 * This component includes the interaction edit panel. Allows editing of interaction properties
 */
export default class InteractionEditPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: this.props.interaction.selectedActionsNames,
      reactions: this.props.interaction.selectedReactionsNames,
      disabledColumns: new Set(),
      disabledColumnsArray: []
    }
  }

  /*
  * Checks and sets disabled columns interaction edit panel
  */
  checkAndSetDisabledColumnsInteractionEditPanel = (columnsHashMap = this.props.interaction.columnsHashMap) => {
    let columnMapKeys = this.props.interaction.selectedColumnsNames
    let disColumns = {}

    disColumns["disabledColumns"] = new Set()
    disColumns["disabledColumnsArray"] = []

    columnMapKeys.map(columnMapArea => {
      if (columnsHashMap.has(columnMapArea) && columnsHashMap.get(columnMapArea).isDisabledColumn === true) {
        disColumns.disabledColumns.add(columnsHashMap.get(columnMapArea).uniqeColumnId)
        disColumns.disabledColumnsArray.push(columnsHashMap.get(columnMapArea).uniqeColumnId)
      }
    })

    store.dispatch(changeDisabledColumnsStatus(disColumns.disabledColumns))

    return disColumns;
  }

  /*
  * Gets translate title reaction name
  */
  translatedTitleReactionNames = () => {
    let translatedTitleReactionNames = []

    for (let i = 0; i < this.props.interaction?.selectedTitleReactionsNames?.length; i++) {
      translatedTitleReactionNames.push(i18n.t("Interaction." + this.props.interaction?.selectedTitleReactionsNames[i]))
    }

    return translatedTitleReactionNames
  }

  componentDidMount() {
    let disColumns = this.checkAndSetDisabledColumnsInteractionEditPanel()
    let translatedTitleReactionNames = this.translatedTitleReactionNames()

    this.setState({
      ...this.state,
      disabledColumns: disColumns.disabledColumns,
      disabledColumnsArray: disColumns.disabledColumnsArray,
      titleReactions: translatedTitleReactionNames,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.interaction !== this.props.interaction) {
      let isStateChanged = false;
      let newState = { ...this.state };

      if (this.props.interaction.actions !== nextProps.interaction.actions) {
        newState.actions = [];

        nextProps.interaction.actions.map(action => {
          newState.actions.push(action.name)
        })

        isStateChanged = true;
      }

      if (this.props.interaction.reactions !== nextProps.interaction.reactions) {
        newState.reactions = [];

        if (this.props.plugin.key == "title") {
          nextProps.interaction.reactions.map(reaction => {
            newState.reactions.push(i18n.t("Interaction." + reaction.name))
          })
        } else {
          nextProps.interaction.reactions.map(reaction => {
            newState.reactions.push(reaction.name)
          })
        }
        

        isStateChanged = true;
      }

      if (this.props.interaction.titleReactions !== nextProps.interaction.titleReactions) {
        newState.titleReactions = [];

        for (let i = 0; i < nextProps.interaction?.titleReactions?.length; i++) {
          let titleReaction = nextProps.interaction.titleReactions[i]

          newState.titleReactions.push(i18n.t("Interaction." + titleReaction.name));
        }

        isStateChanged = true;
      }

      if (this.props.activeInteractionPluginObject.columnMap !== nextProps.activeInteractionPluginObject.columnMap) {
        let disColumns = this.checkAndSetDisabledColumnsInteractionEditPanel(nextProps.interaction.columnsHashMap)

        isStateChanged = true

        newState.disabledColumns = disColumns.disabledColumns
        newState.disabledColumnsArray = disColumns.disabledColumnsArray
      }

      if (isStateChanged === true) {
        this.setState(newState);
      }
    }
  }

  /** Handles actions changes */
  handleChangeActionsDropdown = value => {
    this.setState({ ...this.state, actions: [...value] });
    this.props.handleChangeActionsDropdown(value)
  };

  /** Handles reactions changes */
  handleChangeReactionsDropdown = value => {
    this.setState({ ...this.state, reactions: [...value] });
    this.props.handleChangeReactionsDropdown(value)
  };

  /**
   *  Handles Title reactions changes
   */
  handleChangeTitleReactionsDropdown = (value) => {
    let newVal = []

    if (value.length > 0) {
      newVal = [value[value.length - 1]]
    }

    this.setState({
      ...this.state,
      titleReactions: [...newVal]
    }, () => {
      this.props.handleChangeTitleReactionsDropdown(newVal);
    });
  };

  isColumnLocationFieldNameHidden = column => {
    if (column.locationFieldName === "hidden") {
      return true
    }

    return false
  }

  render() {
    let unProtectedDefaultFilters = [];
    let matchedDefaultFilters = [];
    let ignoredDefaultFilters = [];

    if (this.props.interaction.ignoredDefaultFilters) {
      ignoredDefaultFilters = [...this.props.interaction.ignoredDefaultFilters];
    }

    if (this.props.plugin.defaultFilters && this.props.plugin.defaultFilters.length > 0) {
      unProtectedDefaultFilters = this.props.plugin.defaultFilters.filter(filter => !filter.protected);
      matchedDefaultFilters = unProtectedDefaultFilters.filter(filter => this.props.interaction.selectedColumnsNames.some(columnName => columnName === filter.aliasName))
    }

    if (Array.isArray(this.props.interaction.affectedDefaultFilters)) {
      matchedDefaultFilters = matchedDefaultFilters.filter(filter => !this.props.interaction.affectedDefaultFilters.includes(filter.filterId))
    }

    ignoredDefaultFilters.push(...matchedDefaultFilters.map(filter => filter.filterId));
    ignoredDefaultFilters = ignoredDefaultFilters.filter(filterId => unProtectedDefaultFilters.some(filter => filter.filterId === filterId));

    let actions = this.props.activeInteractionPluginObject.actions
      ? this.props.activeInteractionPluginObject.actions
      : defaultInteractionObjects[this.props.activeInteractionPluginObject.key]
        ? [defaultInteractionObjects[this.props.activeInteractionPluginObject.key]]
        : {}
    
    let reactions;

    if (this.props.plugin.key !== "title") {
      reactions = this.props.activeInteractionPluginObject.reactions
      ? this.props.activeInteractionPluginObject.reactions
      : defaultReactionObjects[this.props.activeInteractionPluginObject.key]
        ? [defaultReactionObjects[this.props.activeInteractionPluginObject.key]]
        : {}

    } else {
      reactions = this.props.plugin.reactions
      ? this.props.plugin.reactions
      : defaultReactionObjects[this.props.plugin.key]
        ? [defaultReactionObjects[this.props.plugin.key]]
        : {}
    }

    let titleReactions = this.props.activeInteractionPluginObject.titleReactions 
      ? this.props.activeInteractionPluginObject.titleReactions
      : defaultTitleReactions[this.props.activeInteractionPluginObject.key] 
        ? defaultTitleReactions[this.props.activeInteractionPluginObject.key] 
        : []

    return (
      <div style={{ width: "400px" }}>
        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ textAlign: "center" }} span={2}>
            <BorderTopOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={7}>
            <Text style={{ marginBottom: "5px", display: "block" }}>
              {i18n.t("Columns")}
            </Text>
          </Col>
          <Col span={15}>
            <div className={"interaction-edit-columns-area"}>
              <Checkbox.Group
                value={this.props.interaction.selectedColumnIds.filter(col => !this.state.disabledColumnsArray.includes(col.uniqeColumnId))}
                style={{ width: "100%", marginTop: "5px" }}
                onChange={this.props.onChangeSelectedColumns}
              >
                {this.props.interaction.allColumns &&
                  this.props.interaction.allColumns.map((column, index) => (
                    <>
                      {!this.isColumnLocationFieldNameHidden(column) ?
                        <Row key={column.uniqeColumnId}>
                          <Col>
                            <Tooltip tooltip={column.displayName} tooltipPlacement="leftTop">
                              <Checkbox
                                key={column.uniqeColumnId}
                                value={column.uniqeColumnId}
                                disabled={this.state.disabledColumns.has(column.uniqeColumnId)}
                              >
                                {column.displayName}
                              </Checkbox>
                            </Tooltip>
                          </Col>
                        </Row> : null}
                    </>
                  ))}{" "}
              </Checkbox.Group>
            </div>
          </Col>
        </Row>

        {
          unProtectedDefaultFilters.length > 0 ? (
            <Row style={{ marginBottom: "10px" }}>
              <Col style={{ textAlign: "center" }} span={2}>
                <FilterOutlined style={{ fontSize: "16px" }} />{" "}
              </Col>
              <Col span={7}>
                <Text>{i18n.t("Interaction.IgnoreDefaultFilters")}</Text>
              </Col>
              <Col span={15}>
                <div className={"interaction-edit-columns-area"}>

                  <Checkbox.Group
                    value={ignoredDefaultFilters}
                    style={{ width: "100%", marginTop: "5px" }}
                  >
                    {
                      unProtectedDefaultFilters.map(col => (
                        <Row key={col.filterId}>
                          <Col>
                            <Tooltip tooltip={col.displayName} tooltipPlacement="leftTop">
                              <Checkbox
                                value={col.filterId}
                                onChange={(event) => this.props.onChangeIgnoredDefaultFilters(event.target.value, ignoredDefaultFilters)}
                              >
                                {col.displayName}
                              </Checkbox>
                            </Tooltip>
                          </Col>
                        </Row>
                      ))
                    }{" "}
                  </Checkbox.Group>
                </div>
              </Col>
            </Row>
          ) : null
        }

        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ textAlign: "center" }} span={2}>
            <CheckSquareOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col span={7}>
            <Text>{i18n.t("Interaction.InteractionTypes")}</Text>
          </Col>
          <Col span={15}>
            <Select
              showArrow="true"
              mode="tags"
              style={{ width: "100%" }}
              value={this.state.actions}
              onChange={value => this.handleChangeActionsDropdown(value)}
              tokenSeparators={[","]}
            >
              {actions.map(
                (interactionType, index) => (
                  <Option key={interactionType.name}>
                    {i18n.t(`${interactionType.name}`)}
                  </Option>
                )
              )}
            </Select>
          </Col>
        </Row>

        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ textAlign: "center" }} span={2}>
            <RollbackOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col span={7}>
            <Text>{i18n.t("Interaction.Reactions")}</Text>
          </Col>
          <Col span={15}>
            <Select
              showArrow="true"
              mode="tags"
              value={this.state.reactions}
              style={{ width: "100%" }}
              onChange={value =>
                this.handleChangeReactionsDropdown(value)
              }
              tokenSeparators={[","]}
            >
              {this.props.plugin.key == "title" ? 
              (reactions.map((reaction, index) => (
                <Option key={i18n.t("Interaction." + reaction.name)}>
                  {i18n.t("Interaction." + reaction.name)}
                </Option>
              ))) : 
              (reactions.map((reaction, index) => (
                <Option key={reaction.name}>
                  {reaction.name}
                </Option>
              )))}
            </Select>
          </Col>
        </Row>
        {this.props.plugin.key !== "title" ? (
          <Row style={{ marginBottom: "10px" }}>
            <Col style={{ textAlign: "center" }} span={2}>
              <RollbackOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col span={7}>
              <Text>{i18n.t("Interaction.TitleReactions")}</Text>
            </Col>
            <Col span={15}>
              <Select
                showArrow="true"
                mode="tags"
                value={this.state.titleReactions}
                style={{ width: "100%" }}
                onChange={(value, options) =>
                  this.handleChangeTitleReactionsDropdown(value, options)
                }
                tokenSeparators={[","]}
              >
                {titleReactions.map(
                  (titleReaction) => (
                    <Option key={i18n.t("Interaction." + titleReaction.name)}>
                      {i18n.t("Interaction." + titleReaction.name)}
                    </Option>
                  )
                )}
              </Select>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Button
            className={"general-button"}
            style={{ float: "right", width: "25%" }}
            onClick={() =>
              this.props.updateInteractionSettings(this.props.plugin)
            }
          >
            {i18n.t("Update")}
          </Button>
        </Row>
      </div>
    );
  }
}
