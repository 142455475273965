import React, { Component } from "react";
import Button from "../../../GeneralComponents/Button/Button";
import { Divider, Switch, Tooltip, Dropdown, Menu, Modal, Select, Row, Col, Input, InputNumber } from "antd";
import {
  SettingOutlined,
  TableOutlined,
  DatabaseOutlined,
  LockOutlined,
  CloseOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import Plugins from "../../../Plugins/Plugins";
import DataSourceMenu from "../../../Datasources/DataSourceMenu";
import Settings from "./Settings/Settings";
import Labels from "./Label/Labels";
import i18n from "../../../../Utils/i18next";
import DashboardSave from "./Save/DashboardSave";
import Text from "../../../GeneralComponents/Text/Text";
import { FAVORITE_LABEL, API_BASE } from "../../../../config";
import { addToFavorite, removeFromFavorite } from "../../FavoriteService";
import { showNotificationWithIcon } from "../../../../Utils/Notification";
import DashboardRefreshButton from "./DashboardRefreshButton";
import ExportButtons from "./ExportButtons/ExportButtons";
import WhatIf from "./WhatIf/WhatIf";
import { del, get } from "../../../../Utils/WebService";
import DashboardComments from "./DashboardComments/DashboardComments";
import { store } from "../../../..";
import CustomDashboard from "../../../GeneralComponents/CustomDashboard/CustomDashboard";
import { connect } from "react-redux";
import { changeCustomDashboardStatus } from "../../../GeneralComponents/CustomDashboard/CustomDashboardAction";
import { isValidWriteRoles } from "../../RoleStore";
import { getIcon, navigate } from "../../../../Utils/Global"
import { BASE_URL_REPORTS, BASE_URL_UI } from "../../../../config";
import { AiFillWarning } from "react-icons/ai";
import { CgDuplicate } from "react-icons/cg";
import DashboardMail from "./DashboardMail/DashboardMail";
import $ from "jquery"
import { addPluginCacheMode, cachedPluginsTime, setCacheMode } from "../../../CacheMode/CacheModeAction";
import { TbDatabaseImport } from "react-icons/tb"
import Cookies from "js-cookie";
import { getContrastColor } from "../../../Plugins/PluginComponents/common";

const { Option } = Select;

const sectionButtons = [
  "autoRefreshButton",
  "customizedDashboardsButton",
  "dashboardCommentsButton",
  "dashboardMailButton",
  "favoriteButton",
  "immobileDashboardSwitch",
  "labelsButton",
  "modelButton",
  "newButton",
  "pluginListButton",
  "refreshButton",
  "saveButton",
  "settingsButton",
  "whatIfButton"
];

class DashboardToolsSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visiblePlugin: false,
      visibleDataSource: false,
      visibleSettings: false,
      visibleLabels: false,
      isFavorite: false,
      dashboardId: this.props.dashboard.id,
      customExcelList: [],
      dashboardCommentVisible: false,
      newDashboardChooserVisible: false,
      willNavigateDashboad: null,
    };
  }

  /**
   * Get custom excels by dashboard id
   */
  getCustomExcels = id => {
    let url = `${API_BASE}/custom-excel?path=${id}`;

    const success = response => {
      this.setState({
        ...this.state,
        customExcelList: [...response.data]
      });
    };

    get(url, success, undefined, false);
  };

  componentDidMount() {
    document.addEventListener("click", this.newDashboardOrReportNewTab)

    let isFavorite = this.props.dashboard.labels
      ? this.props.dashboard.labels.filter(x => x.name == FAVORITE_LABEL)
        .length > 0
      : false

    this.setState({
      ...this.state,
      isFavorite
    })
  }

  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state };
    let shallIsetState = false;

    if (nextProps.dashboard.id !== this.props.dashboard.id) {
      newState.dashboardId = nextProps.dashboard.id;
      shallIsetState = true;

      let newDashboardId = nextProps.mainDashboardId ? nextProps.mainDashboardId : newState.dashboardId

      this.getCustomExcels(newDashboardId);
    }

    if (nextProps.dashboard.labels !== this.props.dashboard.labels) {
      if (
        nextProps.dashboard.labels.filter(x => x.name == FAVORITE_LABEL)
          .length > 0
      ) {
        newState.isFavorite = true;
        shallIsetState = true;
      } else {
        newState.isFavorite = false;
        shallIsetState = true;
      }
    }

    if (this.props.state.OpenPluginListReducer.pluginListVisibility !== nextProps.state.OpenPluginListReducer.pluginListVisibility) {
      newState["visiblePlugin"] = true

      shallIsetState = true;
    }

    if (shallIsetState) {
      this.setState(newState);
    }
  }

  /**
   *  Check event is click and reject it for these button 
   * @param {*} event 
   */
  newDashboardOrReportNewTab = (event) => {
    let newDasboardLinkID = "#contextMenuNewDashboard";
    let newReportLinkID = "#contextMenuNewReport";
    let isNewDashboard = $(event.target).closest(newDasboardLinkID).length > 0;
    let isNewReport = $(event.target).closest(newReportLinkID).length > 0;
    let didClickThem = isNewDashboard || isNewReport;

    if (didClickThem) {
      event.preventDefault();
    }
  }

  /**
   * Remove custom excel from dashboard
   */
  removeCustomExcel = id => {
    let url = `${API_BASE}/custom-excel/${id}`;

    const success = response => {
      showNotificationWithIcon(
        i18n.t("Success"),
        i18n.t("SuccessfullyDeleted"),
        "success"
      );

      let newDashboardId = this.props.mainDashboardId ? this.props.mainDashboardId : this.state.dashboardId

      this.getCustomExcels(newDashboardId);
    };

    del(url, undefined, success);
  };

  pluginOnClick = () => {
    if (this.state.visiblePlugin === false) {
      this.setState({
        ...this.state,
        visiblePlugin: true
      });
    }
  };

  closePlugins = () => {
    this.setState({
      ...this.state,
      visiblePlugin: false
    });
  };

  openDatasource = () => {
    if (this.state.visibleDataSource !== true) {
      this.setState({
        visibleDataSource: true
      });
    }
  };

  closeDataSource = () => {
    this.setState({
      visibleDataSource: false
    });
  };

  selectPlugin = plugin => {
    this.props.selectPlugin(plugin);
  };

  openSettings = () => {
    if (this.state.visibleSettings !== true) {
      this.setState({
        visibleSettings: true
      });
    }
  };

  closeSettings = () => {
    this.setState({
      visibleSettings: false
    });
  };

  clickFavorite = () => {
    let successText = "";
    let id = this.props.mainDashboardId ? this.props.mainDashboardId : this.props.dashboard.id

    const successFunc = () => {
      showNotificationWithIcon(i18n.t("Success"), successText, "success");

      this.setState({
        ...this.state,
        isFavorite: !this.state.isFavorite
      });
    };

    if (this.state.isFavorite) {
      successText = i18n.t("Dashboard.DashboardRemovedFromFavorite");
      removeFromFavorite(id, successFunc);
    } else {
      successText = i18n.t("Dashboard.DashboardAddedToFavorite");
      addToFavorite(id, successFunc);
    }
  };

  /**
   * Toggle function is for navigate modal visibilty.
   * @param {*} status 
   */
  navigateConfirmModalVisibility = (status) => {
    this.setState({
      newDashboardChooserVisible: status,
    });
  };

  /**
   * To navigate new Dashboard by shortcut
   */
  navigateNewDashboard = () => {
    navigate(BASE_URL_UI + "/dashboard", 0);

    this.navigateConfirmModalVisibility(false);
  };

  /**
   * To navigate new Report by shourtcut
   */
  navigateNewReport = () => {
    navigate(BASE_URL_REPORTS + "/designer", 0);

    this.navigateConfirmModalVisibility(false);
  };

  /**
   * To choose Modal context Dashboard or Report.
   * @param {*} select 
   */
  chooserDasboardOrReport = (select) => {
    this.setState({
      willNavigateDashboad: select,
    });

    this.navigateConfirmModalVisibility(true);
  };

  /**
   *  Return New Dashboard - New Reports Dropdown context
   * @returns 
   */
  newMenu = () => {
    return (
      <Menu
        getPopupContainer={(trigger) => trigger.parentNode}
        className="new-dropdown"
      >
        <Menu.Item
          key="newDashboard"
          className="new-dashboard-shortcut"
          onClick={() => this.chooserDasboardOrReport(true)}
        >
          <a id="contextMenuNewDashboard" href={BASE_URL_UI + "/dashboard"}>
            {i18n.t("LandingPage.NewDashboard")}
          </a>
        </Menu.Item>
        <Divider style={{ margin: "0px", color: "#e0e0e0" }} />
        <Menu.Item
          key="newReport"
          className="new-report-shortcut"
          onClick={() => this.chooserDasboardOrReport(false)}
        >
          <a id="contextMenuNewReport" href={BASE_URL_REPORTS + "/designer"}>
            {i18n.t("LandingPage.NewReport")}
          </a>
        </Menu.Item>
      </Menu>
    );
  };

  setCacheTime = (value) => {
    let pluginKeys = Array.from(this.props.cacheModeState.cachedPluginsMap.keys())
    let newPluginMap = new Map()

    for (let i = 0; i < pluginKeys.length; i++) {
      let perPluginId = pluginKeys[i]

      if (this.props.cacheModeState.cachedPluginsMap.has(perPluginId) && this.props.cacheModeState.cachedPluginsMap.get(perPluginId).status) {
        if (value !== "MINUTE") {
          let obj = { type: value }

          newPluginMap.set(perPluginId, { status: true, cacheOptions: obj })
        } else if (value === "MINUTE") {
          let obj = { type: value, minute: 5 }

          newPluginMap.set(perPluginId, { status: true, cacheOptions: obj })
        }
      } else {
        newPluginMap.set(perPluginId, { status: false, cacheOptions: {} })
      }
    }

    if (value !== "MINUTE") {
      let obj = { type: value }

      store.dispatch(cachedPluginsTime(obj))
    } else {
      let obj = { type: value, minute: 5 }

      store.dispatch(cachedPluginsTime(obj))
    }

    store.dispatch(addPluginCacheMode(newPluginMap))
  }

  onChangeCacheMinute = (event) => {
    let pluginKeys = Array.from(this.props.cacheModeState.cachedPluginsMap.keys())
    let newPluginMap = new Map()

    for (let i = 0; i < pluginKeys.length; i++) {
      let perPluginId = pluginKeys[i]
      let isCacheTypeMinute = this.props.cacheModeState.cachedPluginsMap.has(perPluginId)
        && this.props.cacheModeState.cachedPluginsMap.get(perPluginId).status
        && this.props.cacheModeState.cachedPluginsMap.get(perPluginId).cacheOptions.type === "MINUTE" ? true : false

      if (isCacheTypeMinute) {
        let obj = {
          type: this.props.cacheModeState.cachedPluginsMap.get(perPluginId).cacheOptions.type,
          minute: event
        }

        newPluginMap.set(perPluginId, { status: true, cacheOptions: obj })
      } else {
        newPluginMap.set(perPluginId, { status: false, cacheOptions: {} })
      }
    }

    let obj = {
      type: "MINUTE",
      minute: event
    }

    store.dispatch(cachedPluginsTime(obj))
    store.dispatch(addPluginCacheMode(newPluginMap))
  }

  addAllPluginsToCacheMode = (checkIsCachedPluginsLengthEqualsPluginLength) => {
    let addedPluginsMap = new Map()

    for (let i = 0; i < this.props.dashboard.plugins.length; i++) {
      let plugin = this.props.dashboard.plugins[i]
      let pluginId = plugin.id
      let cacheObj = {}

      if (checkIsCachedPluginsLengthEqualsPluginLength) {
        cacheObj = {
          status: false,
          cacheOptions: {}
        }
      } else {
        cacheObj = {
          status: true,
          cacheOptions: this.props.cacheModeState.cachedOptions
        }
      }

      addedPluginsMap.set(pluginId, cacheObj)
    }

    store.dispatch(addPluginCacheMode(addedPluginsMap))
  }

  checkIsCachedPluginsLengthEqualsPluginLength = () => {
    let pluginLength = this.props.dashboard?.plugins?.length
    let cacheModeAddedPluginsLength = 0

    for (let i = 0; i < this.props.dashboard?.plugins?.length; i++) {
      let plugin = this.props.dashboard.plugins[i]
      let pluginId = plugin.id

      if (this.props.cacheModeState.cachedPluginsMap.has(pluginId) && this.props.cacheModeState.cachedPluginsMap.get(pluginId).status) {
        cacheModeAddedPluginsLength++
      }
    }

    if (pluginLength === cacheModeAddedPluginsLength) {
      return true
    }

    return false
  }

  render() {
    let theme = this.props.themeObj?.topBar;
    let buttonThemes = theme?.buttons || {};

    let sectionVisibility = sectionButtons.filter(b => buttonThemes[b]?.visibility !== false).length > 0;

    let cacheModeTime = this.props.cacheModeState.cachedOptions;
    let checkIsCachedPluginsLengthEqualsPluginLength = this.checkIsCachedPluginsLengthEqualsPluginLength();

    let cacheToolsBarStyle = {
      display: this.props.cacheModeState.isCacheModeActive ? "flex" : "none",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      color: theme?.textColor
    };

    let toolsBarStyle = {
      display: !this.props.cacheModeState.isCacheModeActive ? "flex" : "none",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      color: theme?.textColor
    };

    const readOnlyDescription = !this.props.settings.grid.isDraggableAndResizable
      ? "ReadOnlyDescriptionInactive"
      : "ReadOnlyDescriptionActive";

    let isSettingsButtonVisible = isValidWriteRoles() || this.props.state.CustomDashboardReducer.isCustomDashboard

    return sectionVisibility && (
      <div
        class="dashboard-setting"
        style={{
          display: "flex",
        }}
      >
        <style>
          {theme && `
            .dashboard-setting {
              background-color: ${theme.backgroundColor};
              color: ${theme.textColor};
              border-bottom-color: ${theme.textColor}4d;
            }
          `}
        </style>
        {
          this.props.cacheModeState.isCacheModeActive ? (
            <div style={cacheToolsBarStyle} className={"dashboardToolsSettings"}>
              <div className={"left-tools"} style={{ width: "40%", height: "100%" }}>
                <Row style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  <Col span={1} className={"cache-icon"} style={{ color: theme?.textColor }}>
                    <TbDatabaseImport />
                  </Col>
                  <Col span={23} style={{ color: theme?.textColor || "#626465", display: "flex", alignItems: "center" }}>
                    <Text type="span" style={{ display: "inline-block" }}>{i18n.t("Cache.CacheModeValidTime")}</Text>
                    <Tooltip title={i18n.t("Cache.CacheModeDescription")}>
                      <InfoCircleOutlined style={{ position: 'relative', marginTop: '0px', marginLeft: "6px", color: theme?.textColor }} />
                    </Tooltip>
                    <Select
                      showSearch
                      className="top-menu-select"
                      dropdownClassName="top-menu-dropdown"
                      style={{ width: "30%", margin: "0px 15px" }}
                      placeholder="Cache Mode Time"
                      optionFilterProp="children"
                      onChange={this.setCacheTime}
                      value={cacheModeTime.type}
                    >
                      <Option value="MINUTE">{i18n.t("Cache.Minute")}</Option>
                      <Option value="ON_THE_QUARTER_HOUR">{i18n.t("Cache.OnTheQuarterHour")}</Option>
                      <Option value="ON_THE_HALF_HOUR">{i18n.t("Cache.OnTheHalfHour")}</Option>
                      <Option value="ON_THE_HOUR">{i18n.t("Cache.OnTheHour")}</Option>
                      <Option value="MIDNIGHT">{i18n.t("Cache.Midnight")}</Option>
                    </Select>
                    {cacheModeTime.type === "MINUTE" ?
                      <InputNumber
                        className="top-menu-input"
                        style={{ width: "23%" }}
                        value={cacheModeTime.minute}
                        onChange={this.onChangeCacheMinute}
                        min={1}
                        placeholder={i18n.t("Cache.EveryMin")} /> : null}
                  </Col>
                </Row>
              </div>
              <div className={"middle-tools"}>
                <DashboardSave
                  changeSettings={this.props.changeSettings}
                  saveDashboard={this.props.saveDashboard}
                  setTitleSettingsToDefault={this.props.setTitleSettingsToDefault}
                  data={this.props.saveData}
                  readOnly={this.props.readOnly}
                  mainDashboardId={this.props.mainDashboardId}
                  pureMainDashboardPlugin={this.props.pureMainDashboardPlugin}
                  pureMainDashboardSettings={this.props.pureMainDashboardSettings}
                  plugins={this.props.plugins}
                  dashboardSettings={this.props.dashboardSettings}
                  dashboard={this.props.dashboard}
                  getDashboard={this.props.getDashboard}
                  theme={theme}
                />
              </div>
              <div className={"right-tools"} style={{ width: "40%", minWidth: "10%" }}>
                <Button
                  id="dashboardSettingsButton"
                  className={`${!isSettingsButtonVisible ? "disabled-button" : ""} btn btn-new circle-button hide-on-mobile`}
                  disabled={!isSettingsButtonVisible}
                  tooltip={i18n.t("Settings")}
                  onClick={this.openSettings}
                  style={{ float: "right" }}
                >
                  <style>
                    {buttonThemes?.settingsButton && `
                      #dashboardSettingsButton {
                        background-color: ${buttonThemes.settingsButton?.backgroundColor}${!isSettingsButtonVisible ? "33" : ""};
                        color: ${buttonThemes.settingsButton?.textColor}${!isSettingsButtonVisible ? "33" : ""};
                        border-color: ${buttonThemes.settingsButton?.textColor}33;
                      }

                      #dashboardSettingsButton:hover,
                      #dashboardSettingsButton:focus,
                      #dashboardSettingsButton:active {
                        background-color: ${buttonThemes.settingsButton?.backgroundColorActive};
                        color: ${buttonThemes.settingsButton?.textColorActive};
                        border-color: ${buttonThemes.settingsButton?.textColorActive}33;
                      }
                    `}
                  </style>

                  {
                    getIcon(
                      buttonThemes?.settingsButton?.icon,
                      <SettingOutlined style={{ fontSize: "25px" }} />,
                      { fontSize: "25px" }
                    )
                  }

                  <Settings
                    visible={this.state.visibleSettings}
                    closeSettings={this.closeSettings}
                    settings={this.props.settings}
                    changeSettings={this.props.changeSettings}
                    plugins={this.props.plugins}
                    gridCalculator={this.props.gridCalculator}
                    customExcelList={this.state.customExcelList}
                    mainDashboardId={this.props.mainDashboardId}
                    getCustomExcels={this.getCustomExcels}
                    removeCustomExcel={this.removeCustomExcel}
                    dashboardId={this.state.dashboardId}
                    readOnly={this.props.readOnly}
                    forceCloseInteraction={this.props.forceCloseInteraction}
                    theme={this.props.theme}
                    setTheme={this.props.setTheme}
                  ></Settings>
                </Button>

                <Tooltip title={i18n.t("Cache.ExitCacheMode")} placement="topLeft">
                  <Button
                    id="exitCacheModeButton"
                    onClick={() => store.dispatch(setCacheMode(false))}
                    style={{ marginLeft: "6px" }}
                  >
                    {i18n.t("Cache.ExitCacheMode")}
                  </Button>
                </Tooltip>

                <Button
                  id="cacheAllPluginsButton"
                  style={checkIsCachedPluginsLengthEqualsPluginLength ? { background: "rgb(255, 152, 1)", border: "1px solid rgb(255, 152, 1)" } : {}}
                  type={checkIsCachedPluginsLengthEqualsPluginLength ? "danger" : "default"}
                  onClick={() => this.addAllPluginsToCacheMode(checkIsCachedPluginsLengthEqualsPluginLength)}>
                  {checkIsCachedPluginsLengthEqualsPluginLength ? i18n.t("Cache.RemoveCacheModeInPlugin") : i18n.t("Cache.AddCacheModeInPlugin")}
                </Button>
              </div>
            </div>
          ) : (
            <div style={toolsBarStyle} className={"dashboardToolsSettings"}>
              <div className={"left-tools"}>
                {
                  /* NEW BUTTON */
                  buttonThemes?.newButton?.visibility !== false && (
                    <>
                      <Dropdown
                        overlay={this.newMenu}
                        trigger={["click"]}
                        className="new-dropdown hide-on-mobile"
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        <Button
                          id="newDashboard"
                          className="dashboard-button btn btn-new circle-button hide-on-mobile"
                          onClick={(e) => e.preventDefault()}
                          tooltip={i18n.t(
                            "Dashboard.NewReportDashboardShortCut.DashboardReport"
                          )}
                          style={this.props.readOnly === "WRITE" ? {} : { display: "none" }}
                        >
                          <style>
                            {buttonThemes?.newButton && `
                              #newDashboard {
                                background-color: ${buttonThemes.newButton?.backgroundColor};
                                color: ${buttonThemes.newButton?.textColor};
                                border-color: ${buttonThemes.newButton?.textColor}33;
                              }

                              #newDashboard:hover,
                              #newDashboard:focus,
                              #newDashboard:active {
                                background-color: ${buttonThemes.newButton?.backgroundColorActive};
                                color: ${buttonThemes.newButton?.textColorActive};
                                border-color: ${buttonThemes.newButton?.textColorActive}33;
                              }

                              .new-dropdown {
                                  color: ${buttonThemes.newButton?.textColor};
                                  background: ${buttonThemes.newButton?.backgroundColor};
                                  
                              }

                              .new-dropdown .ant-divider {
                                color: ${buttonThemes.newButton?.textColor}33;
                                background: ${buttonThemes.newButton?.textColor}33;
                              }

                              .new-dashboard-shortcut,
                              .new-report-shortcut {
                                  background: ${buttonThemes.newButton?.backgroundColor};
                                  color: ${buttonThemes.newButton?.textColor};
                              }

                              .new-dashboard-shortcut:hover,
                              .new-report-shortcut:hover {
                                  background: ${buttonThemes.newButton?.backgroundColorActive};
                                  color: ${buttonThemes.newButton?.textColorActive};
                              }
                            `}
                          </style>
                          {
                            getIcon(
                              buttonThemes?.newButton?.icon,
                              <CgDuplicate fontSize={25} />,
                              { fontSize: "25px" }
                            )
                          }
                        </Button>
                      </Dropdown>
                      <Modal
                        className="dashboard-button new-Dashboard-Report-Modal"
                        title={
                          <div>
                            <AiFillWarning
                              fontSize={20}
                              color="#ffa200"
                              style={{ marginRight: "5px", marginBottom: "3px" }}
                            />
                            {i18n.t(
                              this.state.willNavigateDashboad
                                ? "Dashboard.NewReportDashboardShortCut.NavigateingToDashboard"
                                : "Dashboard.NewReportDashboardShortCut.NavigateingToReport"
                            )}
                          </div>
                        }
                        visible={this.state.newDashboardChooserVisible}
                        onCancel={() => this.navigateConfirmModalVisibility(false)}
                        onOk={
                          this.state.willNavigateDashboad
                            ? this.navigateNewDashboard
                            : this.navigateNewReport
                        }
                        okText={i18n.t("Dashboard.NewReportDashboardShortCut.OK")}
                        cancelText={i18n.t("Dashboard.NewReportDashboardShortCut.Cancel")}
                      >
                        <Text>
                          {i18n.t(
                            this.state.willNavigateDashboad
                              ? "Dashboard.NewReportDashboardShortCut.WarningTextToDashboard"
                              : "Dashboard.NewReportDashboardShortCut.WarningTextToReport"
                          )}
                          <br />
                          <b>{i18n.t("Dashboard.NewReportDashboardShortCut.Continue")}</b>
                        </Text>
                      </Modal>
                    </>
                  )
                }

                {
                  /* MODEL BUTTON */
                  buttonThemes?.modelButton?.visibility !== false && (
                    <Button
                      id="modelButton"
                      className="dashboard-button btn btn-new circle-button datasource-button hide-on-mobile "
                      onClick={this.openDatasource}
                      tooltip={i18n.t("Model.Title")}
                      style={this.props.readOnly === "WRITE" ? {} : { display: "none" }}
                    >
                      <style>
                        {buttonThemes?.modelButton && `
                          #modelButton {
                            background-color: ${buttonThemes.modelButton?.backgroundColor};
                            color: ${buttonThemes.modelButton?.textColor};
                            border-color: ${buttonThemes.modelButton?.textColor}33;
                          }

                          #modelButton:hover,
                          #modelButton:focus,
                          #modelButton:active {
                            background-color: ${buttonThemes.modelButton?.backgroundColorActive};
                            color: ${buttonThemes.modelButton?.textColorActive};
                            border-color: ${buttonThemes.modelButton?.textColorActive}33;
                          }
                        `}
                      </style>
                      {
                        getIcon(
                          buttonThemes?.modelButton?.icon,
                          <DatabaseOutlined style={{ fontSize: "25px" }} />,
                          { fontSize: "25px" }
                        )
                      }
                      <DataSourceMenu
                        modelId={this.props.modelId}
                        setSelectedModel={this.props.setSelectedModel}
                        display={this.state.visibleDataSource}
                        model={this.props.model}
                        close={() => this.closeDataSource(false)}
                        findJoinChanges={this.props.findJoinChanges}
                        join={this.props.join}
                        getModelTables={this.props.getModelTables}
                        updateModelTablesForJoin={this.props.updateModelTablesForJoin}
                        getModel={this.props.getModel}
                        newColumnAddedFlag={this.props.newColumnAddedFlag}
                        removeNewColumn={this.props.removeNewColumn}
                      ></DataSourceMenu>
                    </Button>
                  )
                }

                {
                  /* PLUGINS BUTTON */
                  buttonThemes?.pluginListButton?.visibility !== false && (
                    <Button
                      id="pluginButton"
                      className="dashboard-button btn btn-new circle-button plugin-button hide-on-mobile"
                      onClick={this.pluginOnClick}
                      tooltip={i18n.t("Dashboard.ToolsSection.PluginList")}
                      style={this.props.readOnly === "WRITE" ? {} : { display: "none" }}
                    >
                      <style>
                        {buttonThemes?.pluginListButton && `
                          #pluginButton {
                            background-color: ${buttonThemes.pluginListButton?.backgroundColor};
                            color: ${buttonThemes.pluginListButton?.textColor};
                            border-color: ${buttonThemes.pluginListButton?.textColor}33;
                          }

                          #pluginButton:hover,
                          #pluginButton:focus,
                          #pluginButton:active {
                            background-color: ${buttonThemes.pluginListButton?.backgroundColorActive};
                            color: ${buttonThemes.pluginListButton?.textColorActive};
                            border-color: ${buttonThemes.pluginListButton?.textColorActive}33;
                          }
                        `}
                      </style>
                      {
                        getIcon(
                          buttonThemes?.pluginListButton?.icon,
                          <TableOutlined style={{ fontSize: "25px" }} />,
                          { fontSize: "25px" }
                        )
                      }
                      {
                        this.state.visiblePlugin === true && (
                          <Plugins
                            cancel={this.closePlugins}
                            visible={this.state.visiblePlugin}
                            selectPlugin={this.selectPlugin}
                          />
                        )
                      }
                    </Button>
                  )
                }

                <Divider
                  hidden={
                    buttonThemes?.refreshButton?.visibility === false ||
                    buttonThemes?.autoRefreshButton?.visibility === false ||
                    this.props.readOnly !== "WRITE"
                  }
                  style={{
                    height: "1.9em"
                  }}
                  type="vertical"
                  className={"hide-on-mobile"}
                />

                {/* REFRESH BUTTON */}
                <DashboardRefreshButton
                  refresh={this.props.changeSettings}
                  tooltip={i18n.t("Dashboard.ToolsSection.RefreshButton")}
                  id={"refreshButton"}
                  autoRefresh={this.props.autoRefresh}
                  setAutoRefreshToDashboard={this.props.setAutoRefreshToDashboard}
                  dashboard={this.props.dashboard}
                  navigations={this.props.navigations}
                  theme={theme}
                />

                <Divider
                  hidden={buttonThemes?.refreshButton?.visibility === false || buttonThemes?.autoRefreshButton?.visibility === false}
                  style={{
                    height: "1.9em",
                  }}
                  type="vertical"
                  className={"hide-on-mobile"}
                />

                {
                  /* EXPORT BUTTON */
                  buttonThemes?.exportButton?.visibility !== false && (
                    <ExportButtons
                      customExcelList={this.state.customExcelList}
                      excelExportClick={this.props.excelExportClick}
                      exportPDFClick={this.props.exportPDFClick}
                      exportPNGClick={this.props.exportPNGClick}
                      plugins={this.props.plugins}
                      excelExportProgress={this.props.excelExportProgress}
                      theme={theme}
                      settings={this.props.settings}
                      downloadFEExcelExport={this.props.downloadFEExcelExport}
                      excelExportBigSizeDownload = {this.props.excelExportBigSizeDownload}
                    />
                  )
                }

                {
                  /* MAIL BUTTON */
                  buttonThemes?.dashboardMailButton?.visibility !== false &&
                  Object.keys(this.props.dashboard).length > 0 &&
                  <DashboardMail
                    dashboard={this.props.dashboard}
                    mainDashboardId={this.props.mainDashboardId}
                    theme={theme}
                  />
                }

                {
                  /* DIVIDER */
                  buttonThemes?.whatIfButton?.visibility !== false &&
                  <Divider 
                    className={"hide-on-mobile"}
                    style={{
                      height: "1.9em"
                    }}
                    type="vertical"
                  />
                }

                {
                  /* WHAT IF BUTTON */
                  buttonThemes?.whatIfButton?.visibility !== false &&
                  <div className="hide-on-mobile">
                    <WhatIf
                      refresh={this.props.changeSettings}
                      readOnly={this.props.readOnly}
                      changeWhatIf={this.props.changeWhatIf}
                      whatIf={this.props.whatIf}
                      theme={theme}
                    />
                  </div>
                }
              </div>

              <div className={"middle-tools"}>
                {
                  /* SAVE BUTTON */
                  buttonThemes?.saveButton?.visibility !== false &&
                  <DashboardSave
                    changeSettings={this.props.changeSettings}
                    saveDashboard={this.props.saveDashboard}
                    setTitleSettingsToDefault={this.props.setTitleSettingsToDefault}
                    data={this.props.saveData}
                    readOnly={this.props.readOnly}
                    mainDashboardId={this.props.mainDashboardId}
                    pureMainDashboardPlugin={this.props.pureMainDashboardPlugin}
                    pureMainDashboardSettings={this.props.pureMainDashboardSettings}
                    plugins={this.props.plugins}
                    dashboardSettings={this.props.dashboardSettings}
                    dashboard={this.props.dashboard}
                    getDashboard={this.props.getDashboard}
                    theme={theme}
                  />
                }
              </div>

              <div className={"right-tools"}>
                {
                  /* SETTINGS BUTTON */
                  buttonThemes?.settingsButton?.visibility !== false && (
                    <Button
                      id="dashboardSettingsButton"
                      className={`${!isSettingsButtonVisible ? "disabled-button" : ""} dashboard-button btn btn-new circle-button hide-on-mobile`}

                      tooltip={i18n.t("Settings")}
                      onClick={this.openSettings}
                      style={{ float: "right" }}
                    >
                      <style>
                        {buttonThemes?.settingsButton && `
                          #dashboardSettingsButton {
                            background-color: ${buttonThemes.settingsButton?.backgroundColor}${!isSettingsButtonVisible ? "33" : ""};
                            color: ${buttonThemes.settingsButton?.textColor}${!isSettingsButtonVisible ? "33" : ""};
                            border-color: ${buttonThemes.settingsButton?.textColor}33;
                          }

                          #dashboardSettingsButton:hover,
                          #dashboardSettingsButton:focus,
                          #dashboardSettingsButton:active {
                            background-color: ${buttonThemes.settingsButton?.backgroundColorActive};
                            color: ${buttonThemes.settingsButton?.textColorActive};
                            border-color: ${buttonThemes.settingsButton?.textColorActive}33;
                          }
                        `}
                      </style>
                      {
                        getIcon(
                          buttonThemes?.settingsButton?.icon,
                          <SettingOutlined style={{ fontSize: "25px" }} />,
                          { fontSize: "25px" }
                        )
                      }
                      <Settings
                        visible={this.state.visibleSettings}
                        closeSettings={this.closeSettings}
                        settings={this.props.settings}
                        changeSettings={this.props.changeSettings}
                        plugins={this.props.plugins}
                        gridCalculator={this.props.gridCalculator}
                        customExcelList={this.state.customExcelList}
                        mainDashboardId={this.props.mainDashboardId}
                        getCustomExcels={this.getCustomExcels}
                        removeCustomExcel={this.removeCustomExcel}
                        dashboardId={this.state.dashboardId}
                        readOnly={this.props.readOnly}
                        forceCloseInteraction={this.props.forceCloseInteraction}
                        theme={this.props.theme}
                        setTheme={this.props.setTheme}
                      ></Settings>
                    </Button>
                  )
                }

                {
                  /* FAVORITE BUTTON */
                  buttonThemes?.favoriteButton?.visibility !== false &&
                  this.state.dashboardId !== undefined &&
                  (
                    <Button
                      id="favButton"
                      className={
                        this.state.isFavorite
                          ? "dashboard-button btn favorite-button circle-button active"
                          : "dashboard-button btn favorite-button circle-button"
                      }
                      tooltip={i18n.t("Favorite")}
                      style={{
                        float: "right",
                        marginLeft: "5px",
                        fontSize: "21px"
                      }}
                      onClick={() => this.clickFavorite()}
                    >
                      <style>
                        {buttonThemes?.favoriteButton && `
                          #favButton {
                            background-color: ${buttonThemes.favoriteButton?.backgroundColor};
                            color: ${buttonThemes.favoriteButton?.textColor};
                            border-color: ${buttonThemes.favoriteButton?.textColor}33;
                          }

                          #favButton:hover,
                          #favButton:focus,
                          #favButton:active,
                          #favButton.active,
                          #favButton.active:hover,
                          #favButton.active:focus,
                          #favButton.active:active {
                            background-color: ${buttonThemes.favoriteButton?.backgroundColorActive};
                            color: ${buttonThemes.favoriteButton?.textColorActive};
                            border-color: ${buttonThemes.favoriteButton?.textColorActive}33;
                          }
                        `}
                      </style>
                      {
                        getIcon(
                          buttonThemes?.favoriteButton?.icon, 
                          <i class="fa fa-star"></i>
                        )
                      }
                    </Button>
                  )
                }

                {
                  /* CUSTOMIZED DASHBOARDS BUTTON */
                  buttonThemes?.customizedDashboardsButton?.visibility !== false &&
                  this.state.dashboardId !== undefined && (
                    <CustomDashboard
                      customDashboards={this.props.customDashboards}
                      getDashboard={this.props.getDashboard}
                      dashboard={this.props.dashboard}
                      mainDashboard={this.props.mainDashboard}
                      mainDashboardId={this.props.mainDashboardId}
                      deleteCustomDashboard={this.props.deleteCustomDashboard}
                      setCustomDashboardAsMainDashboard={this.props.setCustomDashboardAsMainDashboard}
                      theme={theme}
                    />
                  )
                }

                {
                  /* COMMENTS BUTTON */
                  buttonThemes?.dashboardCommentsButton?.visibility !== false &&
                  Object.keys(this.props.dashboard).length > 0 &&
                  <DashboardComments
                    dashboard={this.props.dashboard}
                    mainDashboardId={this.props.mainDashboardId}
                    isCustomDashboard={this.props.isCustomDashboard}
                    theme={theme}
                  />
                }

                {
                  /* LABELS BUTTON */
                  buttonThemes?.labelsButton?.visibility !== false &&
                  <Labels
                    readOnly={this.props.readOnly}
                    updateLabels={this.props.updateLabels}
                    visible={this.state.visibleLabels}
                    closeLabels={this.closeLabels}
                    dashboard={this.props.dashboard}
                    labelCreated={this.props.labelCreated}
                    theme={theme}
                  />
                }

                {
                  /* IMMOBILE DASHBOARD SWITCH */
                  buttonThemes?.immobileDashboardSwitch?.visibility !== false &&
                  <Tooltip
                    id={i18n.t(readOnlyDescription)}
                    title={i18n.t(readOnlyDescription)}
                  >
                    <div
                      id="draggableSwitch"
                      className={"draggableSwitch hide-on-mobile " + (!this.props.settings.grid.isDraggableAndResizable && "checked")}
                      style={this.props.readOnly === "WRITE" || store.getState().CustomDashboardReducer.isCustomDashboard ? {} : { display: "none" }}
                    >
                      {
                        getIcon(
                          buttonThemes?.immobileDashboardSwitch?.icon,
                          <LockOutlined style={{ fontSize: 25 }} />,
                          {
                            fontSize: 25,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }
                        )
                      }

                      <Switch
                        id="imDashboard"
                        checked={!this.props.settings.grid.isDraggableAndResizable}
                        onClick={e => this.props.gridSystemDragDropChangeStatus(!e, true, false, true)}
                        style={{
                          marginLeft: "6px"
                        }}
                      />
                      <style>
                        {buttonThemes?.immobileDashboardSwitch && `
                          .draggableSwitch {
                            background-color: ${buttonThemes.immobileDashboardSwitch?.backgroundColor};
                            color: ${buttonThemes.immobileDashboardSwitch?.textColor};
                            border-color: ${buttonThemes.immobileDashboardSwitch?.textColor}33;
                          }

                          .draggableSwitch.checked {
                            background-color: ${buttonThemes.immobileDashboardSwitch?.backgroundColorActive};
                            color: ${buttonThemes.immobileDashboardSwitch?.textColorActive};
                            border-color: ${buttonThemes.immobileDashboardSwitch?.textColorActive}33;
                          }

                          .draggableSwitch .ant-switch {
                            background-color: ${buttonThemes.immobileDashboardSwitch?.textColor}4d;
                            border-color: ${buttonThemes.immobileDashboardSwitch?.textColor}22;
                          }

                          .draggableSwitch .ant-switch-checked {
                            background-color: ${buttonThemes.immobileDashboardSwitch?.textColorActive}4d;
                            border-color: ${buttonThemes.immobileDashboardSwitch?.textColorActive}22;
                          }
                        `}
                      </style>
                    </div>
                  </Tooltip>
                }
              </div>
            </div>
          )
        }

        {/* BUTTON THEME STYLE */}
        <style>
          {theme && `
            .dashboard-setting .left-tools>.ant-divider,
            .dashboard-setting .middle-toos>.ant-divider,
            .dashboard-setting .right-tools>.ant-divider {
              background-color: ${theme.textColor}4d;
            }

            #exitCacheModeButton,
            #cacheAllPluginsButton,
            .top-menu-select .ant-select-selection,
            .top-menu-dropdown,
            .top-menu-input {
              color: ${theme.textColor};
              background-color: ${theme.backgroundColor};
              border-color: ${theme.textColor}33;
            }

            #exitCacheModeButton:active,
            #exitCacheModeButton:hover,
            #exitCacheModeButton:focus,
            #cacheAllPluginsButton:active,
            #cacheAllPluginsButton:hover,
            #cacheAllPluginsButton:focus,
            #cacheAllPluginsButton.ant-btn-danger {
              color: ${theme.backgroundColor} !important;
              background-color: ${theme.textColor} !important;
              border-color: ${theme.backgroundColor}33 !important;
            }

            .top-menu-select .ant-select-arrow-icon,
            .top-menu-dropdown .ant-select-dropdown-menu-item {
              color: ${theme.textColor};
            }

            .top-menu-dropdown .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active {
              color: ${theme.backgroundColor};
              background-color: ${theme.textColor}d4;
            }

            .top-menu-dropdown .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
              color: ${theme.backgroundColor};
              background-color: ${theme.textColor};
            }

            .top-menu-input:hover,
            .top-menu-input:focus,
            .top-menu-input:active,
            .top-menu-select.ant-select-open .ant-select-selection:hover,
            .top-menu-select.ant-select-open .ant-select-selection,
            .top-menu-select.ant-select-focused .ant-select-selection,
            .top-menu-select .ant-select-selection:focus,
            .top-menu-select .ant-select-selection:active {
              border-color: ${theme.textColor}88;
              -webkit-box-shadow: none;
              box-shadow: none;
            }
          `}
        </style>
      </div>
    );
  }
}

/*
* Connected custom dashboard status for dashboard tools visibility
*/
const mapDispatchToProps = {
  changeCustomDashboardStatus,
};

const mapStateToProps = (state) => {
  return {
    state: state,
    cacheModeState: state.CacheModeReducer
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardToolsSection);
