import { setPluginTriggerObject } from "../Plugins/PluginTriggerAction";
import $ from "jquery";
import { store } from "../../index";
import { absorbEvent, lastMouseClickPosition } from "../../Utils/Global";
import { setAutoInteractionAppliedPluginId } from "./FilterAutoInteractionAction";
import _ from "lodash";

var positions = {};

/**
 *
 * @param {*} event
 * @param {*} navigations
 * Checks if there is a valid event in the navigation object
 */
const hasNavigationInEvent = (event, navigations) => {
  return navigations.filter(
    navigation => navigation.dashboardInformation.actions[0].trigger === event
  )[0];
};

/**
 *
 * @param {*} pluginId
 * @param {*} interactions
 * @returns
 *
 * It checks whether there is interaction from the incoming plugin to other plugins.
 */
const doesPluginHasInteraction = (
  pluginId,
  interactions,
  event = undefined
) => {
  let hasInteraction = false;

  interactions &&
    interactions.map(interaction => {
      if (interaction.sourceId === pluginId) {
        if (event === undefined) {
          hasInteraction = true;
        } else {
          interaction.actions.forEach(action => {
            if (action.trigger === event) {
              hasInteraction = true;
            }
          });
        }
      }
    });

  return hasInteraction;
};

/**
 *
 * @param {*} pluginContent
 * @returns
 * Checks if the plugin's current column contains drill down
 */
const doesPluginHasDrillDown = pluginContent => {
  let hasDrillDown = false;
  let reduxState = store.getState();
  let drillDowns = reduxState.DrillDownReducer.drillDowns.get(
    pluginContent.pluginId
  );

  if (pluginContent.clickedColumn && drillDowns) {
    let column = pluginContent.clickedColumn[0];
    let columnHasDrillDown =
      column &&
      drillDowns.drillDownLayerMap.get(
        column.aliasName + "#" + column.uniqeColumnId
      ) &&
      drillDowns.drillDownLayerMap.get(
        column.aliasName + "#" + column.uniqeColumnId
      ).hasDrillDownOperation === false;

    if (columnHasDrillDown) {
      hasDrillDown = true;
    } else {
      let isDrilldownPluginInside = reduxState.DrillDownReducer.drillDowns.get(
        pluginContent.pluginId
      ).drillDownTypes === "inside-plugin" || reduxState.DrillDownReducer.drillDowns.get(
        pluginContent.pluginId
      ).drillDownTypes === "explode-pie-chart" ? true : false

      hasDrillDown = isDrilldownPluginInside ? true : false
    }
  }

  return hasDrillDown;
};

/**
 * Checks if the plugin's current column contains any custom action
 * 
 * @param {*} pluginContent 
 * @returns 
 */
const doesPluginHasCustomAction = pluginContent => {
  let customActions = pluginContent.customActions

  return customActions && customActions.length > 0
}

/**
 * It is determined whether navigation or interaction will be selected according to the objects coming from the clicked plugin and actions are taken accordingly.
 *
 * @param {*} actions
 * @param {*} columnMap
 * @param {*} container
 * @param {*} event
 * @param {*} datum
 * @param {*} pluginId
 * @param {*} interactions
 * @param {*} navigations
 * @param {*} mousePosition
 * @param {*} filterOperator
 * @param {*} drillDowns
 * @param {*} clickedColumn
 * @param {*} plugin
 * @param {*} model
 * @param {*} datumForNavigation
 */
export const createTrigger = (
  actions,
  columnMap,
  container,
  event,
  datum,
  pluginId,
  interactions,
  navigations,
  mousePosition = { x: 0, y: 0 },
  filterOperator = null,
  drillDowns,
  clickedColumn,
  plugin,
  model,
  datumForNavigation,
  callback,
  customActions
) => {
  const createTrigger = _.throttle(() => {
    let reduxState = store.getState();

    let autoInteractions = reduxState.FilterAutoInteractionReducer
      ? new Map(reduxState.FilterAutoInteractionReducer.filterAutoInteractions)
      : undefined;

    if (autoInteractions) {
      if (autoInteractions.has(pluginId)) {
        interactions = [...interactions, ...autoInteractions.get(pluginId)];

        store.dispatch(setAutoInteractionAppliedPluginId(pluginId));
      }
    }

    if (datumForNavigation !== undefined) {
      if (
        datumForNavigation.withDefaultFilters === undefined &&
        datumForNavigation.withoutDefaultFilters === undefined &&
        Array.isArray(datumForNavigation) === false
      ) {
        datumForNavigation = [datumForNavigation];
      } else {
        if (
          datumForNavigation.withDefaultFilters &&
          Array.isArray(datumForNavigation.withDefaultFilters) === false
        ) {
          datumForNavigation.withDefaultFilters = [
            datumForNavigation.withDefaultFilters
          ];
        }

        if (
          datumForNavigation.withoutDefaultFilters &&
          Array.isArray(datumForNavigation.withoutDefaultFilters) === false
        ) {
          datumForNavigation.withoutDefaultFilters = [
            datumForNavigation.withoutDefaultFilters
          ];
        }
      }
    }

    if (datum && Array.isArray(datum) === false) {
      datum = [datum];
    }

    let pluginContent = {
      model,
      columnMap,
      container,
      event,
      datum,
      pluginId,
      interactions,
      navigations,
      datumForNavigation,
      filterOperator,
      drillDowns,
      clickedColumn,
      callback,
      customActions,
      plugin: plugin || reduxState.PluginTriggerReducer.plugins.get(pluginId),
    };

    let hasNavigation = (
      navigations &&
      navigations.length > 0 &&
      hasNavigationInEvent(event, navigations)
    );
    let hasInteraction = (
      interactions &&
      interactions.length > 0 &&
      doesPluginHasInteraction(pluginId, interactions, event)
    );
    let hasDrillDown = doesPluginHasDrillDown(pluginContent);
    let hasCustomAction = doesPluginHasCustomAction(pluginContent);

    let isActionListVisible = false;
    let isAction = false;

    if (hasNavigation) {
      isActionListVisible = true;
      isAction = true;

      if (navigations.length > 1) {
        callStoreDispach(
          pluginContent,
          mousePosition,
          isActionListVisible,
          isAction
        );
      } else if (
        navigations.length === 1 &&
        (hasInteraction || hasDrillDown || hasCustomAction)
      ) {
        callStoreDispach(
          pluginContent,
          mousePosition,
          isActionListVisible,
          isAction
        );
      } else {
        positions = { x: 0, y: 0 };
        callStoreDispach(pluginContent, positions, false, true);
      }
    } else if (hasInteraction) {
      let isActionListVisible = true;
      isAction = true;

      if ((hasDrillDown || hasCustomAction) && event !== "tileHover") {
        callStoreDispach(
          pluginContent,
          mousePosition,
          isActionListVisible,
          isAction
        );
      } else {
        positions = { x: 0, y: 0 };
        callStoreDispach(pluginContent, positions, false, true);
      }
    } else if (hasCustomAction) {
      let isActionListVisible = true;
      isAction = true;

      if (customActions.length > 1) {
        callStoreDispach(
          pluginContent,
          mousePosition,
          isActionListVisible,
          isAction
        );
      } else if (customActions.length === 1 && hasDrillDown) {
        callStoreDispach(
          pluginContent,
          mousePosition,
          isActionListVisible,
          isAction
        );
      } else {
        positions = { x: 0, y: 0 };
        callStoreDispach(pluginContent, positions, false, true);
      }
    } else if (event !== "tileHover") {
      positions = { x: 0, y: 0 };
      callStoreDispach(pluginContent, positions, false, false);
    } else {
      positions = { x: 0, y: 0 };
      callStoreDispach(pluginContent, positions, false, true);
    }
  }, 2000);

  absorbEvent();
  createTrigger();
};

export const callStoreDispach = (
  pluginContent,
  positions,
  isActionListVisible,
  isAction
) => {
  store.dispatch(
    setPluginTriggerObject(
      pluginContent,
      positions,
      isActionListVisible,
      isAction
    )
  );
};
