import React, { Component } from "react";
import $ from "jquery";
import GaugeChartData from "./GaugeChartData";
import GaugeChartConfiguration from "./GaugeChartConfiguration";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConditionalFormatting, renderConfig, renderData, renderNavigation } from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5_export from "@amcharts/amcharts5/plugins/exporting";
import { InsightsConfig } from "../../RenderJs/config";
import { bigNumberPrefixes, decimalSeperators, longMonths, shortMonths, thousandSeperators } from "../../../GeneralComponents/PublicSortItems";
import { calculatePluginInlineHeight } from "../../../DrillDown/PluginHeightWithDrilldown";
import { aggregatableDataTypes, dateDataTypes } from "../../DataComponents/DataConfigure";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import NavigationContent from "../../../Navigation/NavigationContent";
import { compare } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import _ from "lodash";
import { changePluginLoaderVisibility } from "../../../GeneralComponents/PluginLoader/PluginLoaderAction";
import { loadingScreen } from "../../../../Utils/Global";
import { store } from "../../../..";
import { vispeahenLogo } from "../Table/TablePdfContent";
import { rmvpp } from "../../RenderJs/rmvpp";

const condFormats = [];

const filters = [];

const data = [{ "measure": 5610072926 }];

const config = {
  animation: "cubic",
  duration: 1,
  font: "Verdana",
  axisFontSize: 12,
  measureFontSize: 12,
  measureAutoFontSize: true,
  showMeasureName: true,
  showMeasureValue: true,
  innerRadius: "80",
  legend: true,
  measureType: "numeric",
  axisMin: "",
  axisMax: "",
  axisType: "numeric",
  showTargets: true,
  targetLabel: "",
  targetDataType: "numeric",
  targetStart: "",
  targetEnd: "",
  targetColor: "#FF0000",
  handType: "triangle",
  measuresOnAxis: false,
  chartType: "radial",
  staticTarget: false,
};

const columnMap = {
  measure: {
    Code: "",
    Name: "kargotoplam",
    DataType: "double",
    Table: "ucus_PBWWSJXEAR",
    Measure: "sum('ucus'.'kargotoplam')",
    ID: "ucus_PBWWSJXEAR.kargotoplam",
    SubjectArea: "deneme",
    SortKey: false,
    Sorting: false,
    SortDirection: "asc",
    SortOrder: 0,
    Locale: "TR",
    DataFormat: ".1s",
    Config: {},
    Verified: true,
    Type: "Column",
    Description: "",
    displayName: "kargotoplam"
  },
  maximum: {
    Code: "",
    Name: "",
    DataType: "varchar",
    Table: "Unspecified",
    Measure: "none",
    ID: "Unspecified.",
    SubjectArea: "",
    SortKey: false,
    Sorting: false,
    SortDirection: "asc",
    SortOrder: 0,
    Locale: "TR",
    DataFormat: ".1s",
    Config: {},
    Verified: true,
    Type: "Column",
    Description: ""
  },
  minimum: {
    Code: "",
    Name: "",
    DataType: "varchar",
    Table: "Unspecified",
    Measure: "none",
    ID: "Unspecified.",
    SubjectArea: "",
    SortKey: false,
    Sorting: false,
    SortDirection: "asc",
    SortOrder: 0,
    Locale: "TR",
    DataFormat: ".1s",
    Config: {},
    Verified: true,
    Type: "Column",
    Description: ""
  },
  target: {
    Code: "",
    Name: "",
    DataType: "varchar",
    Table: "Unspecified",
    Measure: "none",
    ID: "Unspecified.",
    SubjectArea: "",
    SortKey: false,
    Sorting: false,
    SortDirection: "asc",
    SortOrder: 0,
    Locale: "TR",
    DataFormat: ".1s",
    Config: {},
    Verified: true,
    Type: "Column",
    Description: ""
  },
  hidden: {
    Code: "",
    Name: "",
    DataType: "varchar",
    Table: "Unspecified",
    Measure: "none",
    ID: "Unspecified.",
    SubjectArea: "",
    SortKey: false,
    Sorting: false,
    SortDirection: "asc",
    SortOrder: 0,
    Locale: "TR",
    DataFormat: "%s",
    Config: {},
    Verified: false,
    Type: "Column"
  }
};

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "font",
    label: "Font",
    inputType: "font",
    inputOptions: { defaultValue: "Verdana" },
    desc: "desc116"
  },
  {
    targetProperty: "duration",
    label: "AnimationDuration",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 1
    },
    desc: "AnimationDuration"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: {
      defaultValue: "Flat-UI"
    },
    desc: "Colours"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "Title"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "Summary"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "BackgroundColor"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "RefreshPeriod"
  },
  {
    targetProperty: "axisFontSize",
    label: "AxisFontSize",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 12
    },
    desc: "AxisFontSize"
  },
  {
    targetProperty: "measureFontSize",
    label: "MeasureFontSize",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 12
    },
    desc: "MeasureFontSize"
  },
  {
    targetProperty: "measureAutoFontSize",
    label: "MeasureAutoFontSize",
    inputType: "textbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "MeasureAutoFontSize"
  },
  {
    targetProperty: "innerRadius",
    label: "InnerRadius",
    inputType: "textbox",
    inputOptions: {
      subType: "number",
      defaultValue: 90
    },
    desc: "InnerRadius"
  },
  {
    targetProperty: "animation",
    label: "Animation",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "cubic"
    },
    desc: "Animation"
  },
  {
    targetProperty: "legend",
    label: "Legend",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "Legend"
  },
  {
    targetProperty: "showTargets",
    label: "ShowTargets",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "ShowTargets"
  },
  {
    targetProperty: "targetLabel",
    label: "Label",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "Animation"
  },
  {
    targetProperty: "targetStart",
    label: "StartLlimit",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc113"
  },
  {
    targetProperty: "targetEnd",
    label: "EndLimit",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc113"
  },
  {
    targetProperty: "targetColor",
    label: "Color",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "#FF0000"
    },
    desc: "desc113"
  },
  {
    targetProperty: "measureType",
    label: "MeasureType",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "numeric"
    },
    desc: "MeasureType"
  },
  {
    targetProperty: "handType",
    label: "MeasureHandType",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "triangle"
    },
    desc: "MeasureHandType"
  },
  {
    targetProperty: "axisType",
    label: "AxisDataType",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "numeric"
    },
    desc: "AxisDataType"
  },
  {
    targetProperty: "targetDataType",
    label: "ValueType",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "numeric"
    },
    desc: "ValueType"
  },
  {
    targetProperty: "showMeasureName",
    label: "ShowMeasureName",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "ShowMeasureName"
  },
  {
    targetProperty: "measuresOnAxis",
    label: "MeasuresOnAxis",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "MeasuresOnAxis"
  },
  {
    targetProperty: "chartType",
    label: "ChartType",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "radial"
    },
    desc: "ChartType"
  },
  {
    targetProperty: "showMeasureValue",
    label: "ShowMeasureValue",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "ShowMeasureValue"
  },
];

const pluginConditionalFormatOptions = {
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.Color"),
    type: "COLOR",
    defaultValue: "#fffffe"
  }
};

const conditionalFormatColumnMap = new Set(["measure", "maximum", "minimum", "target"]);

const actions = [
  {
    trigger: "click",
    type: "click",
    name: "Tıklama",
    output: ["measure"],
    description: ""
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

// Value is converted to affix version
function affixFormat(value, columnMap) {
  if (columnMap.affixAlign === "right") {
    return value + "'" + columnMap.affixValue + "'";
  } else if (columnMap.affixAlign === "left") {
    return "'" + columnMap.affixValue + "'" + value;
  }

  return value;
}

/**
 * GaugeChart plugin class
 */
export default class GaugeChart extends Component {
  constructor(props) {
    super(props);

    this.rerenderProcessStarted = false;
    this.callBackObject = {};
    this.root = undefined;
    this.resizeTimer = undefined;
    this.contrastColor = undefined;
    this.currentColorStep = 0;
  }

  /**
   * Calculates the height of the plugin based on the grid's row height
   * 
   * @param {*} plugin 
   * @param {*} settings 
   * @returns 
   */
  calculatePluginHeight = (plugin, settings) => {
    let pluginHeight = settings.grid.rowHeight * plugin.h; // multiply plugin.height and grid's row height
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginMinHeightDifference = 30;
    let maxHeight =
      pluginHeight -
      (pluginMinHeightDifference +
        pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        pluginContainerBorder);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  };

  /**
   * Resizes the root
   */
  resize = async () => {
    clearTimeout(this.resizeTimer);

    this.resizeTimer = setTimeout(() => {
      this.props.setPluginRerender(true, this.props.plugin.id, false, this.props.plugin.isInteraction);
    }, 100);
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  /**
   * Sets rerender process started status
   * 
   * @param {*} status 
   */
  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  /**
   * Sets callback object
   * 
   * @param {*} callBackObject 
   */
  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  /**
   * Gets callback object
   * 
   * @returns 
   */
  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  };

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  };

  /**
   * Prepares the columnMap
   * 
   * @param {*} tempPlugin 
   * @returns 
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      measure: {
        name: i18n.t("Plugins.gauge-chart.ColumnMap.Measure.Name"),
        type: "fact",
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins.gauge-chart.ColumnMap.Measure.Desc"),
        conditionalFormat: true,
        multiple: true,
        data: []
      },
      maximum: {
        name: i18n.t("Plugins.gauge-chart.ColumnMap.Maximum.Name"),
        type: "fact",
        desc: i18n.t("Plugins.gauge-chart.ColumnMap.Maximum.Desc"),
        required: false,
        multiple: false,
        minimumColumnSize: 0,
        conditionalFormat: true,
        data: []
      },
      minimum: {
        name: i18n.t("Plugins.gauge-chart.ColumnMap.Minimum.Name"),
        type: "fact",
        desc: i18n.t("Plugins.gauge-chart.ColumnMap.Minimum.Desc"),
        required: false,
        multiple: false,
        minimumColumnSize: 0,
        conditionalFormat: true,
        data: []
      },
      target: {
        name: i18n.t("Plugins.gauge-chart.ColumnMap.Target.Name"),
        type: "fact",
        desc: i18n.t("Plugins.gauge-chart.ColumnMap.Target.Desc"),
        required: false,
        multiple: false,
        minimumColumnSize: 0,
        data: []
      },
      hidden: {
        name: i18n.t("Plugins.gauge-chart.ColumnMap.Hidden.Name"),
        type: "hidden",
        required: false,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins.gauge-chart.ColumnMap.Hidden.Desc"),
        conditionalFormat: true,
        multiple: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;

    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  /**
 * Renders the configuration component
 * 
 * @param {*} props 
 * @returns 
 */
  getConfigComponent = props => {
    if (props.config) {
      return (
        <GaugeChartConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          axisMin={this.axisMin}
          axisMax={this.axisMax}
          measureCount={this.measureCount}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  /**
   * Renders the data component
   * 
   * @param {*} props 
   * @returns 
   */
  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    return (
      <GaugeChartData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(props.join, props.plugin.columnMap, props.refreshedPluginId, props.plugin.id, true)}
        setInteractions={props.setInteractions}
        interactions={props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * Renders the navigation component
   * 
   * @param {*} props 
   * @returns 
   */
  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={props.navigations}
        setNavigations={props.updatePlugin}
        plugin={props.plugin}
        dashboardInformation={props.dashboardInformation}
      />
    );
  };

  /*
  * Converts columnMap with data object for conditional format component
  */
  convertColumnMapForConditionalFormat = (cmMap) => {
    let newColumnMap = []
    let fields = ["measure", "maximum", "minimum", "target"]

    for (let field of fields) {
      if (cmMap[field]?.data?.length > 0) {
        newColumnMap.push(cmMap[field].data[0])
      }
    }

    return newColumnMap
  };

  /**
   * Renders the conditional format component
   * 
   * @param {*} props 
   * @returns 
   */
  getConditionalFormattingComponent = props => {
    let columnMap = props.plugin.columnMap;

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        pluginId={props.plugin.id}
        conditionalFormatTargetMap={conditionalFormatColumnMap}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
      />
    );
  };

  /**
   * Converts data for conditional format
   * 
   * @param {*} data 
   * @param {*} columnMap 
   * @returns 
   */
  convertDataToColumnMap = (data, columnMap) => {
    let newData = {};

    for (let field of Object.keys(columnMap)) {
      if (Array.isArray(columnMap[field])) {
        for (let column of columnMap[field]) {
          newData[column.displayName] = data[column.displayName];
        }
      } else {
        newData[columnMap[field].displayName] = data[field];
      }
    }

    return newData;
  };

  /**
   * Converts conditional format data to list of objects
   * 
   * @param {*} condFormats 
   * @param {*} columnMap 
   * @returns 
   */
  convertFormatConditionalFormatting = (condFormats, columnMap) => {
    if (!condFormats || condFormats?.length === 0) return [];

    let condFormatList = [];
    let columnsMap = new Map();

    for (let field of Object.keys(columnMap)) {
      if (Array.isArray(columnMap[field])) {
        for (let column of columnMap[field]) {
          columnsMap.set(column.uniqeColumnId, column);
        }
      } else {
        columnsMap.set(columnMap[field].uniqeColumnId, columnMap[field]);
      }
    }

    condFormats.forEach(condItem => {
      let leftColId = condItem.rule.leftRule.rule.replaceAll("_", "-");
      leftColId = leftColId.substr(1, leftColId.length - 2);

      let rightColId = condItem.rule.rightRule.rule.replaceAll("_", "-");
      rightColId = rightColId.substr(1, rightColId.length - 2);

      let isCondItemValid = columnsMap.has(rightColId) || columnsMap.has(leftColId)

      if (isCondItemValid) condItem.targetColumns.forEach(targetColumn => {
        let controlTargetIsAllColumns = targetColumn?.TargetName === "AllColumns" ? true : false;
        let isTargetValid = controlTargetIsAllColumns || columnsMap.has(targetColumn?.uniqeColumnId);

        if (isTargetValid) condFormatList.push({
          id: condItem.id,
          RightRule: condItem.rule.rightRule.rule,
          LeftRule: condItem.rule.leftRule.rule,
          LeftRuleColumnName: condItem.rule.leftRule.ruleColumnName,
          RightRuleColumnName: condItem.rule.rightRule.ruleColumnName,
          Columns: columnsMap,
          TargetName: controlTargetIsAllColumns ? targetColumn.TargetName : columnsMap.get(targetColumn.uniqeColumnId)?.displayName,
          TargetAliasName: controlTargetIsAllColumns ? targetColumn.TargetName : columnsMap.get(targetColumn.uniqeColumnId)?.aliasName,
          LocationFieldName: targetColumn.locationFieldName,
          Operator: condItem.rule.operator,
          Color: condItem.options.backgroundColor,
        });
      });
    });

    return condFormatList;
  };

  /**
   * Compares and returns the provided conditional formats
   * 
   * @param {*} data 
   * @param {*} condFormats 
   * @returns 
   */
  getProvidedConditionalFormats = (data, condFormats, columnMap) => {
    if (!condFormats || condFormats?.length === 0) return [];

    let providedCondFormats = condFormats.map(condFormat => {
      let convertedData = this.convertDataToColumnMap(data, columnMap);
      let comparedData = compare(convertedData, condFormat);

      if (comparedData.status === true) {
        return condFormat;
      }
    });

    return providedCondFormats.filter(cf => cf);
  };

  /**
   * It is determined whether navigation or interaction will be selected according to the objects coming from the clicked plugin and actions are taken accordingly.
   * 
   * @param {*} event 
   * @param {*} container 
   * @param {*} columnMap 
   */
  createTrigger = (event, columnMap) => {
    let container = $(`#${this.props.plugin.id}`)[0];
    let mousePosition = { x: event.originalEvent.pageX, y: event.originalEvent.pageY }

    createTrigger(
      actions,
      columnMap,
      container,
      'click',
      [], // datum
      this.props.plugin.id,
      this.props.interactions,
      this.props.navigations,
      mousePosition,
      null, // filterOperator
      null, // drillDowns
      null, // clickedColumn
      this.props.plugin,
      this.props.model,
      [] // datumForNavigation
    );
  };

  /**
   * Changes cursor style of the target on pointer over
   * 
   * @param {*} event 
   */
  onPointerOver = event => {
    event.target.set("cursorOverStyle", "pointer");
    event.target.set("cursorOverStyle", this.props.navigations?.length ? "pointer" : "default");
  };

  /**
   * Creates the root of the chart
   * 
   * @param {*} self 
   * @param {*} parent 
   * @returns 
   */
  createRoot = (self, parent) => {
    // If there a root element has been created before, dispose it.
    if (self) self.dispose();

    let root = am5.Root.new(parent);

    // Dispose amcharts logo
    root._logo.dispose();

    // Disable autoResize in order to avoid resizeObserver error
    root.autoResize = false;

    // Resize root when chart is ready
    // Warning: It will triggered on the end of every frame.
    root.events.on("frameended", (e) => e.target.resize());

    // Set root format settings
    root.dateFormatter.monthsShort = shortMonths;
    root.dateFormatter.months = longMonths;
    root.numberFormatter.bigNumberPrefixes = bigNumberPrefixes;
    root.locale["_decimalSeparator"] = decimalSeperators;
    root.locale["_thousandSeparator"] = thousandSeperators;

    // Get number formatter
    this.numberFormatter = root.numberFormatter;

    root.container.setAll({
      paddingBottom: 10,
      paddingTop: 10,
      paddingRight: 10,
      paddingLeft: 10
    });

    return root;
  };

  /**
   * Sets plugin color theme
   * 
   * @param {*} config 
   */
  setTheme = (config) => {
    let pluginContainer = $(`#plugin-${this.props.plugin.id}`);
    let pluginContainerHeight = pluginContainer.height();
    let pluginContainerWidth = pluginContainer.width();

    let theme = am5themes_Animated.new(this.root);
    let paletteColors = Array.isArray(config.colours) ? config.colours : InsightsConfig.Palettes[config.colours];
    let colorSet = paletteColors.map(color => am5.color(color));

    this.contrastColor = this.getContrastColor(am5.color(config.backgroundColor));

    let buttonBackground = {
      fill: am5.color(config.backgroundColor),
      stroke: am5.color(this.contrastColor),
      strokeOpacity: 0.5
    };

    let grip = {
      icon: undefined,
      width: 14,
      height: 14
    };

    theme.rule("ColorSet").setAll({
      colors: colorSet,
      step: 1,
    });

    theme.rule("Label").setAll({
      fill: am5.color(this.contrastColor),
      oversizedBehavior: "truncate",
      fontFamily: config.font
    });

    theme.rule("Grid").setAll({
      stroke: am5.color(this.contrastColor),
      strokeOpacity: 0.2
    });

    theme.rule("Button").setup = (button) => {
      button.get("background").setAll(buttonBackground);

      button.get("background").states.create("down", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 1
      });

      button.get("background").states.create("active", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 0.5
      });

      button.get("background").states.create("hover", {
        fill: am5.color(config.backgroundColor),
        strokeOpacity: 0.8
      });
    };

    theme.rule("Graphics", ["button", "icon"]).setAll({
      fill: am5.color(this.contrastColor),
      stroke: am5.color(this.contrastColor),
      x: am5.p50,
      centerX: am5.p50,
      y: am5.p50,
      centerY: am5.p50
    });

    theme.rule("Tooltip").setup = (tooltip) => {
      tooltip.setAll({
        getStrokeFromSprite: false,
        maxWidth: pluginContainerWidth,
        maxHeight: pluginContainerHeight,
      });

      tooltip.get("background").setAll({
        shadowColor: am5.color(0x000000),
        shadowBlur: 4,
        shadowOffsetX: 1,
        shadowOffsetY: 1
      });

      tooltip.label.setAll({
        fontSize: 12,
        textAlign: "center",
        maxWidth: pluginContainerWidth - 20,
        maxHeight: pluginContainerHeight - 20,
        oversizedBehavior: "fit",
      });
    };

    theme.rule("Scrollbar").setup = (scrollbar) => {
      scrollbar.setAll({
        minWidth: 5,
        minHeight: 5
      });

      scrollbar.get("background").setAll({
        fill: am5.color(this.contrastColor),
        fillOpacity: 0.1
      });

      scrollbar.thumb.setAll({
        fill: am5.color(this.contrastColor),
        fillOpacity: 0.2
      });

      scrollbar.startGrip.setAll(grip);
      scrollbar.endGrip.setAll(grip);

      scrollbar.startGrip.get("background").setAll(buttonBackground);
      scrollbar.endGrip.get("background").setAll(buttonBackground);
    };

    this.root.setThemes([theme]);

    this.root.container.setAll({
      background: am5.Rectangle.new(this.root, {
        fill: am5.color(config.backgroundColor)
      })
    });

    this.colorSet = am5.ColorSet.new(this.root, { step: 1 });
    this.currentColorStep = 0;
  };

  /**
   * Creates a container which is the parent of the chart
   * 
   * @param {*} config 
   * @returns 
   */
  createParent = (config, data, allCondFormats) => {
    let isMeasureMultiple = data.measure instanceof Array && data.measure.length > 1;
    let pluginContainer = $(`#plugin-${this.props.plugin.id}`);
    let pluginContainerHeight = pluginContainer.height();
    let pluginContainerWidth = pluginContainer.width();

    let availableHeight = pluginContainerHeight - (config.condFormat && allCondFormats.length ? (30 + 20) : 20); // 20px for padding, 30px for condFormat legends
    let availableWidth = pluginContainerWidth - 20; // 20px for padding

    let container = this.root.container.children.push(am5.Container.new(this.root, {
      height: availableHeight,
      width: availableWidth,
      containerHeight: availableHeight,
      containerWidth: availableWidth,
      layout: isMeasureMultiple || ["Left", "Right"].includes(config.legendPos) ? this.root.horizontalLayout : this.root.verticalLayout
    }));

    return container;
  };

  /**
   * Creates an XY chart a child of the given container
   * 
   * @param {*} container 
   */
  createChart = (container, config, data, columnMap, isPluginBigEnough) => {
    let chart;

    let isMeasureMultiple = data.measure instanceof Array && data.measure.length > 1;
    let isLegendsValid = config.legend !== false;
    let isLegendRightorLeft = isMeasureMultiple || ["Right", "Left"].includes(config.legendPos);

    let chartWidthFactor = isPluginBigEnough && isLegendsValid && isLegendRightorLeft ? .7 : 1;
    let chartHeightFactor = isPluginBigEnough && isLegendsValid && !isLegendRightorLeft ? .6 : 1;

    let availableHeight = container.get("height") * chartHeightFactor;
    let availableWidth = container.get("width") * chartWidthFactor;

    let maxAvailable = Math.min(availableHeight, availableWidth) - 40; // 40px for padding
    let innerRadiusPercent = Math.min(Number(config.innerRadius || 0), 99);
    let calculatedRadius = maxAvailable * .8;
    let calculatedInnerRadius = calculatedRadius * innerRadiusPercent / 100;

    if (config.chartType === "radial") {
      chart = am5radar.RadarChart.new(this.root, {
        startAngle: 180,
        endAngle: 360,
      });

      if (innerRadiusPercent > 0) {
        chart.set("innerRadius", am5.percent(innerRadiusPercent));
      }

      chart.radarContainer.set("cursorOverStyle", this.props.navigations?.length ? "pointer" : "default");
      chart.radarContainer.events.on("click", (event) => this.createTrigger(event, columnMap));
      chart.radarContainer.events.on("pointerover", this.onPointerOver);
    } else {
      chart = am5xy.XYChart.new(this.root, {});

      if (config.chartType === "horizontal") {
        chart.chartContainer.setAll({
          y: am5.percent(50),
          centerY: 40,
          height: null,
          minHeight: 40,
          maxHeight: am5.percent(100),
          layout: this.root.verticalLayout,
        });

        chart.plotContainer.setAll({
          height: 40,
        });

        chart.topAxesContainer.setAll({
          cursorOverStyle: this.props.navigations?.length ? "pointer" : "default"
        });
      } else if (config.chartType === "vertical") {
        chart.yAxesAndPlotContainer.setAll({
          x: am5.percent(50),
          centerX: am5.percent(50),
          width: 40,
          cursorOverStyle: this.props.navigations?.length ? "pointer" : "default",
        });

        chart.plotContainer.setAll({
          position: "absolute",
          x: am5.percent(50),
          centerX: am5.percent(50),
          width: 40,
        });

        chart.rightAxesContainer.setAll({
          position: "absolute",
          x: am5.percent(100),
          centerX: am5.percent(0),
        });
      }

      chart.yAxesAndPlotContainer.events.on("click", (event) => this.createTrigger(event, columnMap));
      chart.yAxesAndPlotContainer.events.on("pointerover", this.onPointerOver);

      chart.topAxesContainer.events.on("click", (event) => this.createTrigger(event, columnMap));
      chart.topAxesContainer.events.on("pointerover", this.onPointerOver);
    }

    chart.setAll({
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      calculatedRadius: calculatedRadius,
      calculatedInnerRadius: calculatedInnerRadius,
      containerWidth: availableWidth,
      containerHeight: availableHeight
    });

    chart.chartContainer.setAll({
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
    })

    container.children.push(chart);

    return chart;
  };

  /**
   * Creates a date or value axis item with given column and chart
   * 
   * @param {*} dataType 
   * @param {*} chart 
   * @returns 
   */
  createAxis = (chart, data, config, columnMap, condFormats) => {
    let axis;
    let axisRenderer;
    let min, max;

    let minMeasure, maxMeasure;
    let minColumn, maxColumn;

    if (data.measure instanceof Array) {
      let measures = data.measure;
      let validMeasures = measures.filter(measure => isNaN(Number(measure.value)) === false);

      if (validMeasures.length) {
        let sortedMeasures = validMeasures.sort((a, b) => (a.value - b.value));

        minMeasure = sortedMeasures[0];
        maxMeasure = sortedMeasures[sortedMeasures.length - 1];

        minColumn = columnMap.measure.find(c => c.displayName === minMeasure.name);
        maxColumn = columnMap.measure.find(c => c.displayName === maxMeasure.name);

        min = minMeasure.value < 0 ? minMeasure.value * 2 : 0;
        max = maxMeasure.value > 0 ? maxMeasure.value * 2 : 0;
      }
    } else {
      let measure = data.measure;

      if (isNaN(Number(measure)) === false) {
        min = measure < 0 ? measure * 2 : 0;
        max = measure > 0 ? measure * 2 : 0;

        minColumn = columnMap.measure;
        maxColumn = columnMap.measure;
      }
    }

    let minimum = data.minimum;
    let maximum = data.maximum;

    if (minimum && isNaN(Number(minimum)) === false) {
      min = minimum;
      minColumn = columnMap.minimum;
    }

    if (maximum && isNaN(Number(maximum)) === false) {
      max = maximum;
      maxColumn = columnMap.maximum;
    }

    this.axisMin = min;
    this.axisMax = max;

    if (isNaN(Number(config.axisMin)) === false && config.axisMin !== "") {
      min = Number(config.axisMin);
    }

    if (isNaN(Number(config.axisMax)) === false && config.axisMax !== "") {
      max = Number(config.axisMax);
    }

    if (min >= max && min !== this.axisMin) {
      min = this.axisMin;
    }

    if (max <= min && max !== this.axisMax) {
      max = this.axisMax;
    }

    if (typeof min === "undefined" || typeof max === "undefined") {
      this.error = true;

      return;
    };

    let axisColor = am5.color(this.contrastColor);

    if (config.chartType === "radial") {
      axisRenderer = am5radar.AxisRendererCircular.new(this.root, {
        stroke: axisColor,
        strokeOpacity: 0.1,
      });

      axis = chart.xAxes.push(am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0,
        min: min,
        max: max,
        strictMinMax: true,
        renderer: axisRenderer
      }));
    } else {
      if (config.chartType === "horizontal") {
        axisRenderer = am5xy.AxisRendererX.new(this.root, {
          stroke: axisColor,
          strokeOpacity: 0.1,
          opposite: true
        });

        axis = am5xy.ValueAxis.new(this.root, {
          maxDeviation: 0,
          strictMinMax: true,
          min: min,
          max: max,
          renderer: axisRenderer,
        });

        chart.xAxes.push(axis);
      } else {
        axisRenderer = am5xy.AxisRendererY.new(this.root, {
          stroke: axisColor,
          strokeOpacity: 0.1,
          opposite: true
        });

        axis = am5xy.ValueAxis.new(this.root, {
          maxDeviation: 0,
          strictMinMax: true,
          min: min,
          max: max,
          renderer: axisRenderer
        });

        chart.yAxes.push(axis);
      }
    }

    let isChartSmall = chart.get("containerWidth") <= 40 || chart.get("containerHeight") <= 40;
    let isGridSmall = this.props.plugin.w < 3 || this.props.plugin.w < 3;

    if (isChartSmall || isGridSmall) {
      axisRenderer.ticks.template.set("forceHidden", true);
      axisRenderer.labels.template.set("forceHidden", true);

      if (config.chartType !== "radial") {
        chart.plotContainer.setAll({
          width: am5.percent(100),
          height: am5.percent(100)
        });

        chart.yAxesAndPlotContainer.setAll({
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          width: am5.percent(100),
          height: am5.percent(100)
        });
      }
    }

    axisRenderer.ticks.template.setAll({
      visible: false,
      strokeOpacity: 0.8,
      stroke: axisColor,
      strokeWidth: 1
    });

    axisRenderer.grid.template.setAll({
      visible: false
    });

    axisRenderer.labels.template.setAll({
      visible: false,
      fontSize: Number(config.axisFontSize)
    });

    let minDataItem = axis.makeDataItem({
      value: min
    });

    let rangeDataItem = axis.makeDataItem({
      value: min,
      endValue: max
    });

    let maxDataItem = axis.makeDataItem({
      value: max
    });

    axis.createAxisRange(rangeDataItem);

    rangeDataItem.get("axisFill").setAll({
      visible: true,
      fill: axisColor,
      fillOpacity: 0.1,
      stroke: axisColor,
      strokeOpacity: 0.2,
      strokeWidth: 1
    });

    rangeDataItem.get("tick").setAll({
      visible: false
    });

    let minFormat = minColumn ? this.getFormat(minColumn).format : null;
    let maxFormat = maxColumn ? this.getFormat(maxColumn).format : null;

    let minLabel = config.axisType === "percentage" ? "0%" : this.numberFormatter.format(min, minFormat);
    let maxLabel = config.axisType === "percentage" ? "100%" : this.numberFormatter.format(max, maxFormat);

    axis.createAxisRange(minDataItem);
    axis.createAxisRange(maxDataItem);


    minDataItem.get("label").setAll({
      visible: true,
      text: minLabel,
      textAlign: "right",
      textType: "adjusted",
      background: am5.RoundedRectangle.new(this.root, {
        fill: am5.color(config.backgroundColor),
        fillOpacity: 0,
      })
    });

    maxDataItem.get("label").setAll({
      visible: true,
      text: maxLabel,
      textAlign: "left",
      textType: "adjusted",
      background: am5.RoundedRectangle.new(this.root, {
        fill: am5.color(config.backgroundColor),
        fillOpacity: 0,
      })
    });

    if (condFormats?.length > 0) for (let condFormat of condFormats) {
      if (condFormat) {
        let color = am5.color(condFormat.Color);
        let formatMin = condFormat.TargetName === "AllColumns" || condFormat.LocationFieldName === "minimum";
        let formatMax = condFormat.TargetName === "AllColumns" || condFormat.LocationFieldName === "maximum";

        if (formatMin) {
          minDataItem.get("label").set("fill", color);
        }

        if (formatMax) {
          maxDataItem.get("label").set("fill", color);
        }
      }
    }

    return axis;
  };

  /**
   * Creates a data item for given measure on the given axis.
   * 
   * @param {*} axis 
   * @param {*} measure 
   * @returns 
   */
  createDataItem = (chart, axis, config, color, value = undefined, isMeasure = true) => {
    if (!chart || !axis) return;

    let dataItem = axis.makeDataItem({
      value: value !== undefined ? value : axis.get("min"),
      color: color,
    });

    let axisRange = this.createAxisRange(axis, color, dataItem);

    if (isMeasure && config.chartType === "radial") {
      let chartInnerRadius = Math.min(Math.abs(Number(config.innerRadius)), 99);
      let hand = dataItem.set("bullet", am5xy.AxisBullet.new(this.root, {
        sprite: am5radar.ClockHand.new(this.root, {
          visible: config.handType ? true : false,
          radius: am5.percent(chartInnerRadius * .99),
          innerRadius: config.handType === "triangle" ? am5.percent(chartInnerRadius * .7) : 0,
          pinRadius: config.handType === "triangle" ? 0 : 2.5,
          topWidth: 1,
          bottomWidth: 5
        })
      }));

      hand.get("sprite").hand.setAll({
        fill: color
      });

      hand.get("sprite").pin.setAll({
        fill: color
      });
    }

    axisRange.get("tick").setAll({
      visible: isMeasure ? config.measuresOnAxis : true,
      stroke: color,
      strokeWidth: 2,
      strokeOpacity: 1
    });

    axisRange.get("grid").setAll({
      visible: isMeasure ? config.measuresOnAxis : true,
      stroke: color,
      strokeWidth: 2,
      strokeOpacity: 1
    });

    return dataItem;
  };

  /**
   * Creates a axis range on the given axis
   * 
   * @param {*} axis 
   * @param {*} color 
   * @param {*} start 
   * @param {*} end 
   * @returns 
   */
  createAxisRange = (axis, color, dataItem = undefined, start = undefined, end = undefined, tooltipText = "") => {
    if (typeof axis === "undefined") return;

    let rangeDataItem = dataItem ? dataItem : axis.makeDataItem({
      value: start !== undefined ? Number(start) : axis.get("min"),
      endValue: end !== undefined ? Number(end) : axis.get("min")
    });

    let axisRange = axis.createAxisRange(rangeDataItem);
    let axisFill = axisRange.get("axisFill");

    axisFill.setAll({
      visible: true,
      fill: color,
      fillOpacity: 1
    });

    rangeDataItem.get("grid").setAll({
      visible: true,
      forceHidden: false,
      stroke: color
    })

    if (!dataItem) {
      axisFill.setAll({
        tooltip: am5.Tooltip.new(this.root, {}),
        tooltipText: tooltipText,
        tooltipPosition: "pointer"
      });
    }

    return rangeDataItem;
  };

  /**
   * Creates dataItems and axisRanges for all the measures in data
   * 
   * @param {*} chart 
   * @param {*} axis 
   * @param {*} config 
   * @param {*} data 
   * @returns 
   */
  createMeasures = (chart, axis, config, columnMap, data, condFormats) => {
    let measureData;

    if (data.measure instanceof Array) {
      measureData = data.measure
    } else {
      measureData = [{ value: data.measure }];
    }

    measureData = measureData.filter(measure => isNaN(Number(measure.value)) === false);
    measureData = measureData.sort((a, b) => b.value - a.value);

    let measures = measureData.map((measure, index) => {
      let column;

      if (columnMap.measure instanceof Array) {
        column = columnMap.measure.find(col => col.displayName === measure.name);
      } else {
        column = columnMap.measure;
      }

      let value = measure.value;
      let format = this.getFormat(column).format;
      let percent = this.calculatePercentage(value, axis.get("min"), axis.get("max"));
      let formattedValue = this.numberFormatter.format(value, format);
      let color = this.colorSet.getIndex(index);
      let name = column.displayName;

      if (condFormats?.length > 0) {
        for (let condFormat of condFormats) {
          if (condFormat.TargetName === "AllColumns" || condFormat.TargetName === name) {
            color = am5.color(condFormat.Color);
          }
        }
      }

      let dataItem = this.createDataItem(chart, axis, config, color);
      let text = `${name}\n[bold]${formattedValue}[/]\n[bold]${percent}%[/]`

      let axisRange = this.createAxisRange(axis, color);
      let axisFill = axisRange.get("axisFill");

      if (config.measuresOnAxis === true) {
        let axisLabel = dataItem.get("label");

        axisLabel.setAll({
          visible: true,
          text: config.axisType === "percentage" ? percent + "%" : formattedValue,
          textType: "adjusted",
          background: am5.Rectangle.new(this.root, {
            fill: am5.color(config.backgroundColor),
            fillOpacity: 0,
          })
        });

        axisLabel.events.on("pointerover", () => axisFill.showTooltip());
        axisLabel.events.on("pointerout", () => axisFill.hideTooltip());
      }

      let hand;

      if (config.chartType === "radial") {
        let bullet = dataItem.get("bullet");

        hand = bullet.get("sprite");

        hand.events.on("pointerover", () => axisFill.showTooltip());
        hand.events.on("pointerout", () => axisFill.hideTooltip());
      }

      this.currentColorStep = index;

      return {
        name: name,
        value: value,
        color: color,
        colorStep: index,
        format: format,
        text: text,
        dataItem: dataItem,
        axisRange: axisRange,
        axisFill: axisFill,
        hand: hand
      };
    });

    measures = measures.filter(m => m);

    this.measureCount = measures.length;

    if (!measures.length) this.error = true;

    return measures;
  };

  /**
   * Creates dataItems and axisRanges for all of the targets in data and config
   * 
   * @param {*} chart 
   * @param {*} axis 
   * @param {*} config 
   * @param {*} columnMap 
   * @param {*} data 
   * @returns 
   */
  createTarget = (chart, axis, config, columnMap, data, condFormats, isPluginBigEnough) => {
    if (config.showTargets === false) return;

    /**
     * Creates a target data item
     * 
     * @param {*} value 
     * @param {*} format 
     * @param {*} color 
     * @returns 
     */
    const createDataItem = (value, format, color) => {
      let dataItem = this.createDataItem(chart, axis, config, color, value, false);
      let text = config.axisType === "percentage"
        ? this.calculatePercentage(value, axisMin, axisMax) + "%"
        : this.numberFormatter.format(value, format);

      dataItem.get("label").setAll({
        visible: true,
        text: text,
        fill: color,
        textType: "adjusted",
        textAlign: "left",
        background: am5.RoundedRectangle.new(this.root, {
          fill: am5.color(config.backgroundColor),
          fillOpacity: 0,
        })
      });

      dataItem.get("grid").setAll({
        visible: true,
        background: am5.RoundedRectangle.new(this.root, {
          fill: color,
          fillOpacity: 4,
        })
      });

      return dataItem;
    }

    let axisMin = axis.get("min");
    let axisMax = axis.get("max");

    let label = config.targetLabel;
    let color = am5.color(config.targetColor || "#FF0000");
    let start = config.targetStart;
    let end = config.targetEnd;
    let startPercentage, endPercentage;

    let isStartValid = typeof start !== "undefined" && !isNaN(Number(start)) && start !== "";
    let isEndValid = typeof end !== "undefined" && !isNaN(Number(end)) && end !== "";
    let isTargetValid = color && (isStartValid || isEndValid) && config.staticTarget;

    if (isTargetValid) {
      let startDataItem, endDataItem, axisRange;

      if (condFormats?.length > 0) {
        for (let condFormat of condFormats) {
          let formatTarget = condFormat.TargetName === "AllColumns" || condFormat.LocationFieldName === "target";

          if (formatTarget) color = am5.color(condFormat.Color);
        }
      }

      if (isStartValid) {
        start = Number(start)

        if (config.targetDataType === "percentage") {
          startPercentage = start;
          start = this.calculateValueFromPercentage(start, axisMin, axisMax);
        } else {
          startPercentage = this.calculatePercentage(start, axisMin, axisMax);
        }

        startDataItem = createDataItem(start, null, color);
      }

      if (isEndValid) {
        end = Number(end)

        if (config.targetDataType === "percentage") {
          endPercentage = end;
          end = this.calculateValueFromPercentage(end, axisMin, axisMax);
        } else {
          endPercentage = this.calculatePercentage(end, axisMin, axisMax);
        }

        endDataItem = createDataItem(end, null, color)
      }

      if (isStartValid || isEndValid) {
        let textColor = this.getContrastColor(color);
        let tooltipText = "";

        if (label) {
          tooltipText += label;
          tooltipText += "\n";
        }

        if (isStartValid) {
          tooltipText += `[bold]${this.numberFormatter.format(start, null)}[/]`;

          if (isEndValid) {
            tooltipText += " - ";
            tooltipText += `[bold]${this.numberFormatter.format(end, null)}[/]`;
            tooltipText += "\n";
            tooltipText += `[bold]${startPercentage}%[/] - [bold]${endPercentage}%[/]`;
          } else {
            tooltipText += "\n";
            tooltipText += `[bold]${startPercentage}%[/]`;
          }
        } else {
          tooltipText += `[bold]${this.numberFormatter.format(end, null)}[/]`;
          tooltipText += "\n";
          tooltipText += `[bold]${endPercentage}%[/]`;
        }

        axisRange = this.createAxisRange(
          axis,
          color,
          null,
          isStartValid ? start : end,
          isEndValid ? end : start,
          tooltipText
        );

        let axisFill = axisRange.get("axisFill");
        let axisLabel = axisRange.get("label");

        axisFill.setAll({
          fillOpacity: 0.4
        });

        axisLabel.setAll({
          visible: false,
          inside: true,
          fill: am5.color(textColor)
        });

        if (isStartValid) {
          let startLabel = startDataItem.get("label");
          startLabel.events.on("pointerover", () => axisFill.showTooltip());
          startLabel.events.on("pointerout", () => axisFill.hideTooltip());

          let startGrid = startDataItem.get("grid");
          startGrid.set("visible", true);
          startGrid.events.on("pointerover", () => axisFill.showTooltip());
          startGrid.events.on("pointerout", () => axisFill.hideTooltip());
        }

        if (isEndValid) {
          let endLabel = endDataItem.get("label");
          endLabel.events.on("pointerover", () => axisFill.showTooltip());
          endLabel.events.on("pointerout", () => axisFill.hideTooltip());

          let endGrid = endDataItem.get("grid");
          endGrid.set("visible", true);
          endGrid.events.on("pointerover", () => axisFill.showTooltip());
          endGrid.events.on("pointerout", () => axisFill.hideTooltip());
        }

        if (isStartValid && isEndValid) chart.events.on("boundschanged", async () => {
          setTimeout(() => {
            let radius = chart.getPrivate("radius");
            let innerRadius = chart.getPrivate("innerRadius");

            let maxLabelHeight = config.chartType === "radial" ? radius - innerRadius : 40;
            let maxWidth = config.chartType === "radial"
              ? Math.PI * Math.max(innerRadius, radius / 2)
              : config.chartType === "vertical"
                ? chart.plotContainer.height()
                : chart.plotContainer.width();

            let insideWidth = (end - start) / (axis.get("max") - axis.get("min")) * maxWidth;
            let fontSize = Math.ceil(Math.min(insideWidth / label.length, maxLabelHeight / 2));
            let labelRadius = (maxLabelHeight - fontSize) / 2

            if (fontSize < 12) {
              let maxLabelLengthToFit = Math.round(Math.min(insideWidth / 12));

              if (label.length > maxLabelLengthToFit) {
                label = label.substring(0, maxLabelLengthToFit).trim() + "...";
              }

              fontSize = 12;
              labelRadius = (maxLabelHeight - fontSize) / 2
            }

            axisLabel.setAll({
              layer: 1,
              text: label,
              fontSize: fontSize,
              visible: maxWidth && maxLabelHeight >= 12 && insideWidth >= 48
            });

            if (config.chartType === "radial") {
              axisLabel.set("raidus", labelRadius);
            } else {
              let axisHeight = chart.topAxesContainer.height();
              let centerX = am5.percent(50);
              let centerY = config.chartType === "vertical"
                ? am5.percent(100)
                : axisHeight * -1;

              axisLabel.setAll({
                paddingTop: 20 - fontSize / 2,
                paddingBottom: 20 - fontSize / 2,
                centerX: centerX,
                centerY: centerY,
                rotation: config.chartType === "vertical" ? -90 : 0
              });
            }
          }, 10);
        });
      }

      return {
        name: label,
        color: color,
        start: startDataItem,
        end: endDataItem,
        range: axisRange
      }
    } else if (typeof data?.target !== "undefined") {
      let value = data.target;

      if (isNaN(Number(value)) === true) return;

      let column = columnMap.target;
      let name = column.displayName;
      let percentage = this.calculatePercentage(value, axisMin, axisMax);
      let color = this.colorSet.getIndex(++this.currentColorStep);
      let format = this.getFormat(column).format

      if (condFormats?.length > 0) {
        for (let condFormat of condFormats) {
          if (condFormat.TargetName === "AllColumns" || condFormat.LocationFieldName === "target") {
            color = am5.color(condFormat.Color);
          }
        }
      }

      let tooltipText = name;
      tooltipText += "\n";
      tooltipText += `[bold]${this.numberFormatter.format(value, format)}[/]`;
      tooltipText += "\n";
      tooltipText += `[bold]${percentage}%[/]`;

      let dataItem = createDataItem(value, format, color)
      let axisRange = this.createAxisRange(
        axis,
        color,
        null,
        value,
        value,
        tooltipText
      );

      let axisFill = axisRange.get("axisFill");
      axisFill.events.on("pointerover", () => axisFill.showTooltip());
      axisFill.events.on("pointerout", () => axisFill.hideTooltip());

      let label = dataItem.get("label");
      label.events.on("pointerover", () => axisFill.showTooltip());
      label.events.on("pointerout", () => axisFill.hideTooltip());

      let grid = dataItem.get("grid");
      grid.set("visible", true);
      grid.events.on("pointerover", () => axisFill.showTooltip());
      grid.events.on("pointerout", () => axisFill.hideTooltip());

      return {
        name: name,
        color: color,
        format: format,
        start: dataItem,
        end: dataItem,
        range: axisRange
      }
    }
  };

  /**
   * Creates the labels of the measures
   * 
   * @param {*} chart 
   * @param {*} axis 
   * @param {*} config 
   * @param {*} data 
   * @param {*} columnMap 
   * @param {*} isPluginBigEnough 
   * @returns 
   */
  createLabels = (chart, axis, config, data, columnMap, isPluginBigEnough) => {
    let isLabelsValid = isPluginBigEnough && config.legend && (config.showMeasureName || config.showMeasureValue);

    if (!isLabelsValid) return;

    let axisMin = axis.get("min");
    let axisMax = axis.get("max");

    let isValueLabelValid = config.showMeasureValue && ["numeric", "numeric-percentage"].includes(config.measureType);
    let isPercentageLabelValid = config.showMeasureValue && ["percentage", "numeric-percentage"].includes(config.measureType);
    let isNameLabelValid = config.showMeasureName;

    let longestLegend = data.reduce((max, current) => (current.text.length > max ? current : max)).text;
    let words = longestLegend.split("\n");
    let longestWordLength = words.reduce((max, current) => (current.length > max ? current : max)).length;

    /**
     * Calculates font size according the longest word in the label
     * @returns 
     */
    const calculateFontSize = (labelMaxWidth, maxHeight) => {
      let fontSize = Number(config.measureFontSize) || 14;

      if (config.measureAutoFontSize) {
        fontSize = labelMaxWidth / 8;

        while (labelMaxWidth / longestWordLength < fontSize) {
          fontSize -= 1;
        }

        if (maxHeight) fontSize = Math.min(fontSize, maxHeight);
        
        fontSize = Math.max(12, fontSize);
      }

      return fontSize;
    };

    if (data.length > 1 || ["Right", "Left"].includes(config.legendPos)) {
      let parentHeight = chart.parent.get("containerHeight");
      let parentWidth = chart.parent.get("containerWidth");
      let containerWidth = parentWidth * .3;
      let scrollbarMargin = 10;
      let labelMaxWidth = containerWidth - scrollbarMargin * 3;
      let fontSize = Math.max(12, calculateFontSize(labelMaxWidth));
      let nameFontSize = Math.max(10, fontSize - (config.showMeasureValue ? 2 : 0));
      let container = am5.Container.new(this.root, {
        width: containerWidth,
        containerWidth: containerWidth,
        containerHeight: parentHeight,
        height: parentHeight,
        paddingTop: 16,
        paddingBottom: 16,
        x: config.legendPos === "Left" ? 0 : am5.percent(100),
        centerX: config.legendPos === "Left" ? 0 : am5.percent(100),
        layout: this.root.horizontalLayout
      });

      if (config.legendPos === "Left") {
        chart.parent.children.unshift(container);
      } else {
        chart.parent.children.push(container);
      }

      let legend = container.children.push(am5.Container.new(this.root, {
        maxWidth: containerWidth,
        height: parentHeight - 32,
        containerHeight: parentHeight - 32,
        containerWidth: containerWidth,
        layout: this.root.verticalLayout,
        paddingLeft: scrollbarMargin,
        paddingRight: scrollbarMargin,
        longestWordLength: longestWordLength,
        maxLabelWidth: labelMaxWidth,
        verticalScrollbar: am5.Scrollbar.new(this.root, {
          orientation: "vertical",
          marginRight: scrollbarMargin,
          animationDuration: 0,
          animationEasing: null
        })
      }));

      container.events.on("click", () => legend.get("verticalScrollbar").set("start", 0));

      let labels = [];
      let innerContainers = [];

      for (let measure of data) {
        let nameLabel, valueLabel, percentageLabel;

        let name = measure.name;
        let format = measure.format;
        let dataItem = measure.dataItem;
        let value = dataItem.get("value");

        let color = measure.color;
        let contrastColor = am5.color(this.getContrastColor(color));

        let innerContainer = legend.children.push(
          am5.Container.new(this.root, {
            x: am5.percent(50),
            centerX: am5.percent(50),
            marginRight: 4,
            marginLeft: 4,
            marginBottom: 4,
            marginTop: 4,
            maxWidth: labelMaxWidth,
            containerWidth: labelMaxWidth,
            layout: this.root.verticalLayout,
            cursorOverStyle: this.props.navigations?.length ? "pointer" : "default",
            background: am5.RoundedRectangle.new(this.root, {
              fill: color
            }),
          })
        );

        innerContainers.push(innerContainer);

        if (isNameLabelValid) {
          nameLabel = innerContainer.children.push(
            am5.Label.new(this.root, {
              fontSize: nameFontSize,
              maxWidth: labelMaxWidth,
              width: labelMaxWidth,
              containerWidth: labelMaxWidth,
              text: name,
              oversizedBehavior: "truncate",
              textAlign: "center",
              fill: contrastColor,
              fillOpacity: 0.5
            })
          );

          labels.push(nameLabel);
        }

        if (config.showMeasureValue) {
          if (isValueLabelValid) {
            valueLabel = innerContainer.children.push(
              am5.Label.new(this.root, {
                fontSize: fontSize,
                maxWidth: labelMaxWidth,
                width: labelMaxWidth,
                containerWidth: labelMaxWidth,
                oversizedBehavior: "truncate",
                textAlign: "center",
                fill: contrastColor,
                text: this.numberFormatter.format(value, format)
              })
            );

            labels.push(valueLabel);
          }

          if (isPercentageLabelValid) {
            percentageLabel = innerContainer.children.push(
              am5.Label.new(this.root, {
                fontSize: fontSize,
                maxWidth: labelMaxWidth,
                width: labelMaxWidth,
                containerWidth: labelMaxWidth,
                oversizedBehavior: "truncate",
                textAlign: "center",
                fill: contrastColor,
                text: this.calculatePercentage(value, axisMin, axisMax) + "%"
              })
            );

            labels.push(percentageLabel);
          }

          if (nameLabel) {
            nameLabel.set("paddingBottom", 0);
          }

          if (config.measureType === "numeric-percentage") {
            valueLabel.set("paddingBottom", 0);
            percentageLabel.set("paddingTop", 0);
          }
        }

        dataItem.on("value", (data) => {
          if (isValueLabelValid) {
            let value = this.numberFormatter.format(data, format);

            valueLabel.set("text", value);
          }

          if (isPercentageLabelValid) {
            let percentage = this.calculatePercentage(data, axisMin, axisMax);

            percentageLabel.set("text", percentage + "%");
          }
        });

        innerContainer.events.on("pointerover", this.onPointerOver);
        innerContainer.events.on("pointerover", () => measure.axisFill?.showTooltip());
        innerContainer.events.on("pointerout", () => measure.axisFill?.hideTooltip());
        innerContainer.events.on("click", (event) => this.createTrigger(event, columnMap));
      }

      return {
        sprite: legend,
        scrollbarMargin: scrollbarMargin,
        maxLabelWidth: labelMaxWidth,
        fontSize: fontSize,
        longestWordLength: longestWordLength,
        innerContainers: innerContainers,
        labels: labels
      };
    } else {
      let nameLabel, valueLabel, percentageLabel;

      let parentWidth = chart.parent.get("width");
      let containerWidth = parentWidth * .5;
      let containerHeight = chart.parent.get("height") * .4;

      let container = am5.Container.new(this.root, {
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 8,
        paddingLeft: 8,
        width: parentWidth,
        x: am5.percent(50),
        centerX: am5.percent(50),
        maxHeight: containerHeight,
        containerHeight: containerHeight,
        containerWidth: parentWidth,
        layout: this.root.horizontalLayout
      });

      if (config.legendPos === "Top") {
        chart.parent.children.unshift(container);
      } else {
        chart.parent.children.push(container);
      }

      let measure = data[0];
      let name = measure.name;
      let dataItem = measure.dataItem;
      let value = dataItem.get("value");

      let color = measure.color;
      let contrastColor = am5.color(this.getContrastColor(color));

      let maxLabelWidth = containerWidth - 24;
      let innerContainerHeight = containerHeight - 16;
      let maxLabelHeight = containerHeight - 16;
      let labelCount = 0;

      if (isNameLabelValid) labelCount++;
      if (isValueLabelValid) labelCount++;
      if (isPercentageLabelValid) labelCount++;

      maxLabelHeight = maxLabelHeight / labelCount;

      let fontSize = calculateFontSize(maxLabelWidth - 24, maxLabelHeight - 8);
      let valueFontSize = Math.max(14, fontSize)
      let nameFontSize = valueFontSize - (config.showMeasureValue ? 2 : 0)

      let labels = [];
      let innerContainers = [];

      let innerContainer = container.children.push(
        am5.Container.new(this.root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          maxHeight: innerContainerHeight,
          containerHeight: innerContainerHeight,
          paddingRight: 4,
          paddingLeft: 4,
          layout: this.root.verticalLayout,
          longestWordLength: longestWordLength,
          cursorOverStyle: this.props.navigations?.length ? "pointer" : "default",
          verticalScrollbar: am5.Scrollbar.new(this.root, {
            orientation: "vertical",
            marginRight: 0,
            marginLeft: 0,
            forceHidden: true,
            width: 0,
            height: 0
          }),
          background: am5.RoundedRectangle.new(this.root, {
            fill: color
          }),
        })
      );

      innerContainers.push(innerContainer);

      if (isNameLabelValid) {
        nameLabel = innerContainer.children.push(
          am5.Label.new(this.root, {
            x: am5.percent(50),
            centerX: am5.percent(50),
            fontSize: nameFontSize,
            maxWidth: maxLabelWidth,
            containerWidth: maxLabelWidth,
            paddingTop: 4,
            paddingBottom: 4,
            text: name,
            oversizedBehavior: "truncate",
            textAlign: "center",
            fill: contrastColor,
            fillOpacity: 0.5,
          })
        );

        labels.push(nameLabel);
      }

      if (config.showMeasureValue) {
        if (isValueLabelValid) {
          valueLabel = innerContainer.children.push(
            am5.Label.new(this.root, {
              x: am5.percent(50),
              centerX: am5.percent(50),
              fontSize: valueFontSize,
              maxWidth: maxLabelWidth,
              containerWidth: maxLabelWidth,
              paddingTop: 4,
              paddingBottom: 4,
              oversizedBehavior: "truncate",
              textAlign: "center",
              fill: contrastColor,
              text: this.numberFormatter.format(value, measure.format)
            })
          );

          labels.push(valueLabel);
        }


        if (isPercentageLabelValid) {
          percentageLabel = innerContainer.children.push(
            am5.Label.new(this.root, {
              x: am5.percent(50),
              centerX: am5.percent(50),
              fontSize: valueFontSize,
              maxWidth: maxLabelWidth,
              containerWidth: maxLabelWidth,
              paddingTop: 4,
              paddingBottom: 4,
              oversizedBehavior: "truncate",
              textAlign: "center",
              fill: contrastColor,
              text: this.calculatePercentage(value, axisMin, axisMax) + "%"
            })
          );

          labels.push(percentageLabel);
        }

        if (nameLabel) nameLabel.set("paddingBottom", 0);

        if (config.measureType === "numeric-percentage") {
          valueLabel.set("paddingBottom", 0);
          percentageLabel.set("paddingTop", 0);
        }
      }

      dataItem.on("value", (data) => {
        if (isValueLabelValid) {
          let value = this.numberFormatter.format(data, measure.format);

          valueLabel.set("text", value);
        }

        if (isPercentageLabelValid) {
          let percentage = this.calculatePercentage(data, axisMin, axisMax);

          percentageLabel.set("text", percentage + "%");
        }
      });

      innerContainer.events.on("click", (event) => this.createTrigger(event, columnMap))
      innerContainer.events.on("pointerover", this.onPointerOver);
      innerContainer.events.on("pointerover", () => measure.axisFill?.showTooltip());
      innerContainer.events.on("pointerout", () => measure.axisFill?.hideTooltip());

      return {
        sprite: innerContainer,
        maxLabelWidth: maxLabelWidth,
        fontSize: fontSize,
        longestWordLength: longestWordLength,
        innerContainers: innerContainers,
        labels: labels
      };
    }
  };

  /**
   * Add conditional format legends to plugins bottom
   * 
   * @param {*} condFormats 
   * @param {*} container 
   */
  createCondFormLegends = (container, config, condFormats) => {
    if (!config.condFormat || !condFormats.length) return;

    //adds conditional format legends to plugins bottom
    let usedCondFormatIds = new Set();
    let condFormatData = [];

    let condFormLegend = container.children.push(am5.Legend.new(this.root, {
      position: "relative",
      nameField: "label",
      fillField: "fill",
      strokeField: "stroke",
      useDefaultMarker: true,
      height: 30,
      containerHeight: 30,
      width: am5.p100,
      paddingRight: 10,
      paddingLeft: 10,
      y: am5.p100,
      centerY: am5.p100,
      layout: this.root.gridLayout,
      verticalScrollbar: am5.Scrollbar.new(this.root, {
        orientation: "vertical",
        marginRight: 10
      })
    }));

    condFormLegend.markerRectangles.template.setAll({
      width: 14,
      height: 14,
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10
    });

    condFormLegend.itemContainers.template.setAll({
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 0,
      paddingLeft: 0,
      marginRight: 0,
      marginLeft: 0
    });

    let labelWidth = condFormLegend.innerWidth() - condFormLegend.get("paddingRight") - condFormLegend.get("paddingLeft") - 40;

    condFormLegend.labels.template.setAll({
      oversizedBehavior: "wrap-no-break",
      maxWidth: labelWidth,
      containerWidth: labelWidth,
      fontSize: 14,
    });

    condFormats.forEach((d, i) => {
      if (usedCondFormatIds.has(d.id)) {
        return;
      } else {
        usedCondFormatIds.add(d.id);
      }

      let condItem = this.props.plugin.conditionalFormats.find(cf => cf.id === d.id);

      if (condItem) {
        let leftRule = d.LeftRuleColumnName.includes("{") ? `{${d.LeftRuleColumnName}}` : `${d.LeftRuleColumnName}`;
        let rightRule = d.RightRuleColumnName.includes("{") ? `{${d.RightRuleColumnName}}` : `${d.RightRuleColumnName}`;
        let operator = d.Operator;
        let ruleDescription = condItem.rule?.conditionalFormatRule;

        condFormatData.push({
          fill: d.Color ? am5.color(d.Color) : am5.color("#fff"),
          stroke: d.Color ? am5.color(d.Color) : this.contrastColor,
          label: ruleDescription ? `${ruleDescription}` : `${leftRule} ${operator} ${rightRule}`
        });
      }
    });

    condFormatData = condFormatData.sort((a, b) => a.label.length > b.label.length ? -1 : 1);

    condFormLegend.data.setAll(condFormatData);

    return condFormLegend;
  };

  /**
  * Animates hand and range of the given measure
  * 
  * @param {*} dataItems 
  * @param {*} axisRanges 
  * @param {*} to 
  * @param {*} duration 
  * @param {*} ease
  * @param {*} timeout 
  */
  animateItems = (dataItems = [], axisRanges = [], to = 0, duration = 1000, ease = undefined, timeout = 0) => {
    const animate = () => {
      for (let item of dataItems) {
        item.animate({
          key: "value",
          to: to,
          duration: duration,
          easing: am5.ease.out(ease)
        });
      }

      for (let range of axisRanges) {
        range.animate({
          key: "endValue",
          to: to,
          duration: duration,
          easing: am5.ease.out(ease)
        });
      }
    };

    setTimeout(() => animate(), timeout);
  };

  /**
   * Animates the render of the chart
   * 
   * @param {*} chart 
   * @param {*} config 
   * @param {*} measures 
   */
  animateChart = (container, config, axis, measures) => {
    let appearDuration = 1000;
    let appearTimeout = 100;

    let animationEase = am5.ease[config.animation || "cubic"];
    let animationDuration = Number(config.duration || 0) * 1000;
    let animationTimeout = appearDuration + appearTimeout;
    let isAnimationValid = animationDuration && animationEase ? true : false;

    container.appear(appearDuration, appearTimeout);

    if (isAnimationValid) {
      measures.forEach((current) => {
        this.animateItems(
          [current.dataItem],
          [current.axisRange],
          current.value,
          animationDuration,
          animationEase,
          animationTimeout
        );

      });
    } else {
      measures.forEach((current) => {
        current.dataItem.set("value", current.value);
        current.axisRange.set("endValue", current.value);
      });
    }

    setTimeout(() => measures.forEach((current, index) => {
      let next = measures[index + 1];

      current.axisRange.set("value", next ? next.value : axis.get("min"));
      current.axisRange.get("axisFill").set("tooltipText", current.text);
    }), isAnimationValid ? animationDuration + animationTimeout : 0);
  };

  /**
   * Creates the exporter and exporting methods of the plugin
   * @param {*} plugin 
   * @param {*} data 
   * @param {*} config 
   * @param {*} parent 
   * @param {*} chart 
   * @param {*} axis 
   * @param {*} measures 
   * @param {*} labels 
   * @param {*} target 
   * @param {*} condFormatLegends 
   */
  createExporter = (plugin, data, config, parent, chart, axis, measures, labels, target, condFormatLegends) => {
    let title = `${this.props.title}`;
    let exporter = am5_export.Exporting.new(this.root, {
      title: title,
      filePrefix: title,
      showTimeout: () => { },
      pngOptions: {
        quality: 1,
        minWidth: 4320,
        minHeight: 4320,
        pageOrientation: "portrait"
      },
      pdfOptions: {
        quality: 1,
        addURL: false,
        minWidth: 4320,
        minHeight: 4320,
        align: "center"
      }
    });

    let isMultiMeasure = measures.length > 1;
    let isChartPosLeftOrRight = isMultiMeasure || ["Left", "Right"].includes(config.legendPos);

    /**
     * Prepares the plugin before exporting
     * 
     * @param {*} format 
     * @returns 
     */
    const preparePluginToExport = format => {
      let container = document.getElementById(this.props.plugin.id);
      let headerSection = document.getElementsByClassName("header-section")[0];
      let toolsSection = document.getElementsByClassName("dashboard-setting")[0];

      headerSection.style.position = "relative";
      headerSection.style.zIndex = 1000;

      toolsSection.style.position = "relative";
      toolsSection.style.zIndex = 1000;

      container.style.visibility = "hidden";
      container.style.position = "fixed";

      changePluginLoaderVisibility(this.props.plugin.id, true)
      loadingScreen(true);

      if (format === "xlsx") return;

      let height = $(container).height();
      let width = $(container).width();

      container.preHeight = height;
      container.preWidth = width;

      let plotContainerWidth = chart.plotContainer._contentWidth || 0;
      let plotContainerHeight = chart.plotContainer._contentHeight || 0;
      let leftAxesContainerWidth = chart.leftAxesContainer._contentWidth || 0;
      let rightAxesContainerWidth = chart.rightAxesContainer._contentWidth || 0;
      let topAxesContainerHeight = chart.topAxesContainer._contentHeight || 0;
      let bottomAxesContainerHeight = chart.bottomAxesContainer._contentHeight || 0;

      let measuredHeight = Math.max(plotContainerHeight + topAxesContainerHeight + bottomAxesContainerHeight + 40, height);
      let measuredWidth = Math.max(plotContainerWidth + leftAxesContainerWidth + rightAxesContainerWidth + 40, width);

      if (labels) {
        let legendHeight = labels.sprite._contentHeight;

        if (!isChartPosLeftOrRight) {
          let labelHeight = legendHeight + 16;
          let labelWidth = labels.longestWordLength * labels.fontSize;
          let spriteWidth = labelWidth + 8;

          measuredHeight = labelHeight * 4
          measuredWidth = Math.max(measuredWidth, spriteWidth * 2);

          labels.labels.forEach(item => {
            item.set("maxWidth", labelWidth);
          });

          labels.sprite.get("verticalScrollbar").set("start", 0);
          labels.sprite.parent.set("width", measuredWidth);
          labels.sprite.parent.set("maxHeight", labelHeight);

          chart.set("minWidth", measuredWidth);
          chart.set("minHeight", measuredHeight * .75);

        } else if (isChartPosLeftOrRight) {
          let labelsParentHeight = legendHeight + 32;

          labels.sprite.set("height", legendHeight);
          labels.sprite.parent.set("height", labelsParentHeight);

          measuredHeight = Math.max(labelsParentHeight, measuredHeight);
          labelsParentHeight = measuredHeight;

          let labelWidth = labels.longestWordLength * labels.fontSize;
          let legendWidth = labelWidth + labels.scrollbarMargin * 3;

          labels.sprite.set("maxWidth", legendWidth);
          labels.sprite.parent.set("width", legendWidth);

          labels.innerContainers.forEach(item => {
            item.set("maxWidth", labelWidth);
            item.set("width", labelWidth);
          });

          labels.labels.forEach(item => {
            item.set("maxWidth", labelWidth);
            item.set("width", labelWidth);
          });

          labels.sprite.get("verticalScrollbar").set("start", 0);

          measuredWidth = legendWidth * 4;

          chart.set("minWidth", measuredWidth * .75);
          chart.set("minHeight", measuredHeight);
        }
      }

      chart.parent.set("height", measuredHeight);
      chart.parent.set("width", measuredWidth);

      measuredHeight += 20;
      measuredWidth += 20;

      container.style.width = measuredWidth + "px";
      container.style.height = measuredHeight + "px";

      if (condFormatLegends?.data?.length) {
        condFormatLegends.set("width", am5.percent(100));
        condFormatLegends.get("verticalScrollbar").set("start", 0);

        let condFormatHeight = condFormatLegends._contentHeight;

        condFormatLegends.set("height", condFormatHeight);

        let labelWidth = measuredWidth - condFormatLegends.get("paddingRight") - condFormatLegends.get("paddingLeft") - 40;

        condFormatLegends.labels.template.setAll({
          maxWidth: labelWidth
        });

        measuredHeight += condFormatHeight;

        container.style.height = measuredHeight + "px";
      }

      if (format === "pdf") {
        let options = exporter.get("pdfOptions");

        exporter.set("pdfOptions", {
          ...options,
          pageOrientation: measuredHeight > measuredWidth ? "portrait" : "landscape"
        });
      }
    };

    /**
     * Reverts changes made for export.
     * 
     * @param {*} format 
     * @returns 
     */
    const revertChangesAfterExport = format => setTimeout(() => {
      let container = $(`#${this.props.plugin.id}`)[0];

      let headerSection = document.getElementsByClassName("header-section")[0];
      let toolsSection = document.getElementsByClassName("dashboard-setting")[0];

      headerSection.style.position = "";
      headerSection.style.zIndex = "";

      toolsSection.style.position = "";
      toolsSection.style.zIndex = "";

      if (format !== "xlsx") {
        let chartParentPreWidth = chart.parent.get("containerWidth");
        let chartParentPreHeight = chart.parent.get("containerHeight");

        chart.set("minWidth", undefined);
        chart.set("minHeight", undefined);

        chart.parent.set("height", chartParentPreHeight);
        chart.parent.set("width", chartParentPreWidth);

        container.style.height = container.preHeight ? container.preHeight + "px" : "";
        container.style.width = container.preWidth ? container.preWidth + "px" : "";

        if (labels) {
          let labelsPreHeight = labels.sprite.get("containerHeight");
          let labelsPreWidth = labels.sprite.get("containerWidth");
          let labelsParentPreHeight = labels.sprite.parent.get("containerHeight");
          let labelsParentPreWidth = labels.sprite.parent.get("containerWidth");

          if (isChartPosLeftOrRight) {
            labels.sprite.set("height", labelsPreHeight);
            labels.sprite.parent.set("height", labelsParentPreHeight);
            labels.sprite.set("maxWidth", labelsPreWidth);
            labels.sprite.parent.set("width", labelsParentPreWidth);

            labels.innerContainers.forEach(item => {
              let preWidth = item.get("containerWidth");

              item.set("maxWidth", preWidth);
              item.set("width", preWidth);
            });

            labels.labels.forEach(item => {
              let preWidth = item.get("containerWidth");

              item.set("maxWidth", preWidth);
              item.set("width", preWidth);
            });
          } else {
            labels.labels.forEach(item => {
              let preWidth = item.get("containerWidth");

              item.set("maxWidth", preWidth);
            });

            labels.sprite.parent.set("width", labelsParentPreWidth);
            labels.sprite.parent.set("maxHeight", labelsParentPreHeight);
          }
        }

        if (condFormatLegends?.data?.length) {
          setTimeout(() => {
            let condFormatsPreHeight = condFormatLegends.get("containerHeight");
            let labelPreWidth = condFormatLegends.labels.template.get("containerWidth");

            condFormatLegends.set("height", condFormatsPreHeight);
            condFormatLegends.set("width", am5.percent(100));

            condFormatLegends.labels.template.setAll({
              maxWidth: labelPreWidth
            });
          })
        }
      }

      store.dispatch(changePluginLoaderVisibility(this.props.plugin.id, false));
      loadingScreen(false);

      container.style.position = "";
      container.style.visibility = "";
    });

    plugin.PDF = () => {
      preparePluginToExport("pdf");

      exporter.download("pdf").then(() => revertChangesAfterExport("pdf"));
    }
    plugin.PNG = () => {
      preparePluginToExport("png");

      exporter.download("png").then(() => revertChangesAfterExport("png"))
    };

    /**
     * Makes the necessary edits when the document is exported.
     */
    exporter.events.on("pdfdocready", (pdf) => {
      let { content } = pdf.doc;
      let [title, image] = content;

      image.fit[0] -= 60;

      pdf.doc.header = {
        columns: [
          {
            text: `${i18n.t("Dashboard.Configuration.Fields.ExportDate")}: ${rmvpp.generateDate()}`,
            bold: false,
            margin: [0, 5, 30, 0],
            alignment: 'right'
          }
        ]
      }

      pdf.doc.content[0] = title;
      pdf.doc.content[1] = image;

      pdf.doc.footer = {
        image: vispeahenLogo,
        margin: [0, 0, 15, 5],
        alignment: 'right',
      };

      pdf.compress = false;

      return pdf;
    });
  }

  /**
   * Prepares and renders the plugin
   * 
   * @param {*} divId 
   * @param {*} data 
   * @param {*} columnMap 
   * @param {*} config 
   * @param {*} condFormats 
   * @param {*} filters 
   * @returns 
   */
  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    this.error = false;

    // Get the container which the chart sits on it
    let plugin = $("#" + divId)[0];

    // getComputedStyle error fix
    if (plugin === undefined) return;

    // Create the chart root
    this.root = this.createRoot(this.root, plugin);

    // Set the chart theme
    this.setTheme(config);

    data = data[0];

    let allCondFormats = this.convertFormatConditionalFormatting(condFormats, columnMap);
    let providedCondFormats = this.getProvidedConditionalFormats(data, allCondFormats, columnMap);

    let isLegendRightorLeft = ["Right", "Left"].includes(config.legendPos) || data?.measure?.length > 1;
    let isPluginBigEnough = isLegendRightorLeft
      ? this.props.plugin.w > 4
      : this.props.plugin.h > 2 && this.props.plugin.w > 2;

    let parent = this.createParent(config, data, allCondFormats);
    let chart = this.createChart(parent, config, data, columnMap, isPluginBigEnough);
    let axis = this.createAxis(chart, data, config, columnMap, providedCondFormats);

    if (axis && !this.error) {
      let measures = this.createMeasures(chart, axis, config, columnMap, data, providedCondFormats);

      if (measures.length && !this.error) {
        let target = this.createTarget(chart, axis, config, columnMap, data, providedCondFormats);
        let labels = this.createLabels(chart, axis, config, measures, columnMap, isPluginBigEnough);
        let condFormatLegends = this.createCondFormLegends(this.root.container, config, allCondFormats);

        this.createExporter(plugin, data, config, parent, chart, axis, measures, labels, target, condFormatLegends);
        this.animateChart(parent, config, axis, measures);
      }
    }

    if (this.error) {
      this.root.dispose()
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  /**
   * Updates the last content status
   * @param {*} status 
   */
  updateLastContent = (status) => {
    this.lastContent = status
  };

  /**
   * Returns the contrast color of the given RGB color
   * 
   * @param {*} colorRGB 
   * @returns 
   */
  getContrastColor = (colorRGB) => {
    const yiq = (colorRGB.r * 299 + colorRGB.g * 587 + colorRGB.b * 114) / 1000;

    return yiq >= 128 ? "#000000" : "#FFFFFF"
  };

  /**
   * Returns the right format string of given column
   * 
   * @param {*} column 
   * @returns 
   */
  getFormat = (column) => {
    let type = column.DataType;
    let dataFormat = column.DataFormat;
    let format = column.DataFormat;

    if (format) {
      if (dateDataTypes.has(type)) {
        let parts = {
          "%Y": "yyyy",
          "%m": "MM",
          "%d": "dd",
          "%H": "HH",
          "%M": "mm",
          "%S": "ss"
        }

        for (let part of Object.keys(parts)) {
          format = format.replace(part, parts[part]);
        }

        this.root.dateFormatter.set("dateFormat", "format");

        return { format: format, placeHolder: `.formatDate('${format}')` };
      } else if (aggregatableDataTypes.has(type)) {
        dataFormat = dataFormat.toLowerCase();

        let isDataFormatDotNumberS = dataFormat[0] === "." && dataFormat[dataFormat.length - 1] === "s";
        let isDataFormatDotCommaNumberS = dataFormat[0] === "," && dataFormat[1] === "." && dataFormat[dataFormat.length - 1] === "s";

        let isDataFormatDotNumberF = dataFormat[0] === "." && dataFormat[dataFormat.length - 1] === "f";
        let isDataFormatDotCommaNumberF = dataFormat[0] === "," && dataFormat[1] === "." && dataFormat[dataFormat.length - 1] === "f";

        let format = {
          format: "#.",
          placeHolder: `.formatNumber('#.')`
        };

        if (dataFormat !== ".s" && isDataFormatDotNumberS) {
          // for .Ns
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("s"))

          if (!isNaN((Number(subString)))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#.${zeroArr}a')`;
            format.format = affixFormat(`#.${zeroArr}a`, column)
          }
        } else if (isDataFormatDotCommaNumberF) {
          // for ,.Nf
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("f"))

          if (!isNaN((Number(subString)))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#,###.${zeroArr}')`;
            format.format = affixFormat(`#,###.${zeroArr}`, column)
          }
        } else if (isDataFormatDotCommaNumberS) {
          // for ,.Ns
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("s"));

          if (!isNaN(Number(subString))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(isNaN(zeroCount) ? 0 : zeroCount + 1).join("0");

            format.placeHolder = `.formatNumber('#,###.${zeroArr}a')`;
            format.format = affixFormat(`#,###.${zeroArr}a`, column)
          }
        } else if (dataFormat === ".f") {
          //for .f
          format.placeHolder = `.formatNumber('#.')`;
          format.format = affixFormat('#.', column)
        } else if (dataFormat !== ".f" && isDataFormatDotNumberF) {
          // for .Nf
          let subString = dataFormat.substring(dataFormat.indexOf(".") + 1, dataFormat.lastIndexOf("f"));

          if (!isNaN(Number(subString))) {
            let zeroCount = parseInt(subString);
            let zeroArr = Array(zeroCount + 1).join("0")

            format.placeHolder = `.formatNumber('#.${zeroArr}')`;
            format.format = affixFormat(`#.${zeroArr}`, column)
          }
        }

        return format;
      }
    }

    return {
      placeHolder: "",
      format: null
    };
  };

  /**
   * Calculates the percentage of the given value for the specified min-max range.
   * 
   * @param {*} value 
   * @param {*} min 
   * @param {*} max 
   * @returns 
   */
  calculatePercentage = (value, min, max) => {
    return this.numberFormatter.format(value / (max - min) * 100, "#.##");
  };

  /**
   * Calculates the actual value of the given percentage for the specified min-max range.
   * 
   * @param {*} value 
   * @param {*} min 
   * @param {*} max 
   * @returns 
   */
  calculateValueFromPercentage = (value, min, max) => {
    return (max - min) * value / 100;
  };

  render() {
    // Configuration component
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    // Data component
    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    // Navigation Component
    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    // Conditional formatting component
    let conditionalFormatComponent = null;
    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    // If there is no data, return empty div
    if (!this.props.plugin.config) {
      return (
        <div>
          <div id={this.props.plugin.id} />
        </div>
      );
    }

    let pluginContainerPadding = parseInt(
      $("#grid-" + this.props.plugin.id).css("padding")
    );

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    pluginConfig.height =
      this.calculatePluginHeight(this.props.plugin, this.props.settings) -
      pluginContainerPadding * 2;

    if (isNaN(pluginConfig.height)) {
      pluginConfig.height = this.currentHeight;
    }

    if (pluginConfig.height !== this.currentHeight) {
      this.currentHeight = pluginConfig.height;
      isRerender = true;
    }

    return (
      <>
        <div style={{ height: "100%" }}>
          <div id={this.props.plugin.id} style={{ height: calculatePluginInlineHeight(this.props.plugin.id) }} />
          {
            renderContent(
              isRerender,
              this.pluginRender,
              this.props.plugin,
              data,
              columnMap,
              pluginConfig,
              this.props.plugin.conditionalFormats,
              this.props.setPluginRerender,
              this.lastContent,
              this.updateLastContent,
            )
          }
          {configComponent}
          {dataComponent}
          {navigationComponent}
          {conditionalFormatComponent}
        </div>
      </>
    );
  };
};
