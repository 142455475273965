import {
  AreaChartOutlined,
  BgColorsOutlined, BoldOutlined, EyeInvisibleOutlined, FontSizeOutlined,
  InfoCircleOutlined,
  ItalicOutlined, NumberOutlined, UnderlineOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, Row, Select, Tooltip } from "antd";
import $ from "jquery";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import i18n from "../../../../Utils/i18next";
import FontTypeSelector from "../../../GeneralComponents/FontTypeSelector/FontTypeSelector";
import Text from "../../../GeneralComponents/Text/Text";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";
import { themeFields } from "../common";

const { TextArea } = Input;
const { Option } = Select;

//Configuration file of Radio button filter plugin
export default class RadioButtonFilterConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: this.props.config.backgroundColor,
      height: this.props.config.height,
      showHideButton: this.props.config.showHideButton,
      summary: this.props.config.summary,
      title: this.props.config.title,
      toggleCriteria: this.props.config.toggleCriteria,
      width: this.props.config.width,
      hideLabel: this.props.config.hideLabel,
      titleAlign: this.props.config.titleAlign,
      isMultiple: this.props.config.isMultiple == undefined ? true : this.props.config.isMultiple,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      wrapText: this.props.config.wrapText,
      flexDirection: this.props.config.flexDirection !== undefined ? this.props.config.flexDirection : "left",
      theme: this.props.config.theme !== undefined ? this.props.config.theme : "default",
      textColor: this.props.config.textColor !== undefined ? this.props.config.textColor : "rgba(0,0,0, 1)",
      font: this.props.config.font !== undefined ? this.props.config.font : "Verdana",
      textDecor: this.props.config.textDecor !== undefined ? this.props.config.textDecor : false,
      fontStyle: this.props.config.fontStyle !== undefined ? this.props.config.fontStyle : false,
      fontWeight: this.props.config.fontWeight !== undefined ? this.props.config.fontWeight : false,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        backgroundColor: nextProps.config.backgroundColor,
        height: nextProps.config.height,
        showHideButton: nextProps.config.showHideButton,
        summary: nextProps.config.summary,
        title: nextProps.config.title,
        toggleCriteria: nextProps.config.toggleCriteria,
        width: nextProps.config.widths,
        hideLabel: nextProps.config.hideLabel,
        titleAlign: nextProps.config.titleAlign,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        isMultiple: nextProps.config.isMultiple == undefined ? true : nextProps.config.isMultiple,
        wrapText: nextProps.config.wrapText,
        flexDirection: nextProps.config.flexDirection !== undefined ? nextProps.config.flexDirection : "left",
        theme: nextProps.config.theme !== undefined ? nextProps.config.theme : "default",
        textColor: nextProps.config.textColor !== undefined ? nextProps.config.textColor : "rgba(0,0,0, 1)",
        font: nextProps.config.font !== undefined ? nextProps.config.font : "Verdana",
        textDecor: nextProps.config.textDecor !== undefined ? nextProps.config.textDecor : false,
        fontStyle: nextProps.config.fontStyle !== undefined ? nextProps.config.fontStyle : false,
        fontWeight: nextProps.config.fontWeight !== undefined ? nextProps.config.fontWeight : false,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle
      });
    }
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  /**
   * Changes the value of text attributes in Radio button filter plugin use handleChange
   */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /**
* Changes the title attribute in Filter plugin use titleAlignOnChange
*/
  titleAlignOnChange = (value) => {
    this.setState({
      ...this.state,
      titleAlign: value,
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Checks whether checkbox is checked or not in Radio button filter plugin use handleCheck
   */
  handleCheckText = e => {
    let val = this.state[e.target.id]

    this.setState({
      ...this.state,
      [e.target.id]: !val
    });

    this.setConfigToPlugin({ type: e.target.id, value: !val });
  };

  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Checks whether checkbox is checked or not in Radio button filter plugin use handleCheck
   */
  handleCheckIsMultiple = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked,
      theme: e.target.checked || e.target.checked === undefined ? "default" : "18"
    });

    this.setConfigToPlugin([
      { type: e.target.id, value: e.target.checked },
      { type: "theme", value: e.target.checked || e.target.checked === undefined ? "default" : "18" }]);
  };

  /*
  * Checks is custom button
  */
  handleCheckCustomButton = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked })
  };

  /**
   * Updates state attributes in Radio button filter plugin
   */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Changes the background colour attribute in Radio button filter plugin use backgroundColorOnChange
   */
  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /**
 * Changes the background colour attribute in Radio button filter plugin use backgroundColorOnChange
 */
  textColorOnChange = obj => {
    this.setState({
      ...this.state,
      textColor: obj.color
    });

    this.setConfigToPlugin({ type: "textColor", value: obj.color });
  };

  flexDirectionOnChange = value => {
    this.setState({
      ...this.state,
      flexDirection: value
    });

    this.setConfigToPlugin({ type: "flexDirection", value: value });
  };

  themeOnChange = value => {
    this.setState({
      ...this.state,
      theme: value
    });

    this.setConfigToPlugin({ type: "theme", value: value });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in Radio button filter plugin
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in Radio button filter plugin
   */
  textColorChangeVisible = () => {
    this.setState({
      ...this.state,
      textColorChange: !this.state.textColorChange
    });
  };

  //added this function to change the font-family
  fontOnChange = (obj) => {
    this.setState({
      ...this.state,
      font: obj,
    });

    this.setConfigToPlugin({ type: "font", value: obj });
  };

  render() {
    return (
      <>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <div style={{ color: "#505050" }}>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.IsMulti")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"isMultiple"}
                defaultChecked={this.state.isMultiple}
                checked={this.state.isMultiple}
                onChange={this.handleCheckIsMultiple}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.WrapText")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"wrapText"}
                defaultChecked={this.state.wrapText}
                checked={this.state.wrapText}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AreaChartOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.DefaultSortDirection")}
              </Text>
            </Col>
            <Col span={12}>
              <Select
                showSearch
                id={"flexDirection"}
                style={{ width: "100%" }}
                placeholder="Select flex direction"
                optionFilterProp="children"
                onChange={this.flexDirectionOnChange}
                defaultValue={this.state.flexDirection}
              >
                <Option id={"bottom"} value="bottom">{i18n.t("Dashboard.Configuration.Fields.VerticalDirection")}</Option>
                <Option id={"left"} value="left">{i18n.t("Dashboard.Configuration.Fields.HorizontalDirection")}</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <AreaChartOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Theme")}
              </Text>
            </Col>
            <Col span={12}>
              {this.state.isMultiple ? <Select
                showSearch
                id={"theme"}
                style={{ width: "100%" }}
                placeholder="Select flex direction"
                optionFilterProp="children"
                onChange={this.themeOnChange}
                value={this.state.theme}
              >
                <Option id={"default"} value="default">{i18n.t("Dashboard.Configuration.Fields.ThemeButton")} 1</Option>
                <Option id={"4"} value="4">{i18n.t("Dashboard.Configuration.Fields.ThemeButton")} 2</Option>
                <Option id={"28"} value="28">{i18n.t("Dashboard.Configuration.Fields.ThemeButton")} 3</Option>
                <Option id={"29"} value="29">{i18n.t("Dashboard.Configuration.Fields.ThemeButton")} 4</Option>
                <Option id={"30"} value="30">{i18n.t("Dashboard.Configuration.Fields.ThemeButton")} 5</Option>
              </Select> :
                <Select
                  showSearch
                  id={"theme"}
                  style={{ width: "100%" }}
                  placeholder="Select flex direction"
                  optionFilterProp="children"
                  onChange={this.themeOnChange}
                  value={this.state.theme}
                >
                  <Option id={"18"} value="18">{i18n.t("Dashboard.Configuration.Fields.ThemeButton")} 1</Option>
                  <Option id={"12"} value="12">{i18n.t("Dashboard.Configuration.Fields.ThemeButton")} 2</Option>
                </Select>}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.TextColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.textColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.textColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.textColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.textColor}
                  onChange={this.textColorOnChange}
                  id={"textColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <NumberOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Font")}
              </Text>
            </Col>
            <Col span={12}>
              <FontTypeSelector
                placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
                onChange={this.fontOnChange}
                value={this.state.font}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.backgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.backgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.backgroundColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.backgroundColor}
                  onChange={this.backgroundColorOnChange}
                  id={"backgroundColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col
              style={{ textAlign: "center" }}
              span={this.props.titleIcon ? this.props.titleIcon : 3}
            >
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col
              style={{ textAlign: "left" }}
              span={this.props.titleColumn ? this.props.titleColumn : 9}
            >
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.ConditionalFormatting.Font")}
              </Text>
            </Col>
            <Col style={{ textAlign: "left" }} span={12}>
              {this.state.fontWeight !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleCheckText}
                    id={"fontWeight"}
                    style={{
                      backgroundColor: this.state.fontWeight ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <BoldOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.fontStyle !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleCheckText}
                    id={"fontStyle"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: this.state.fontStyle ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <ItalicOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.textDecor !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleCheckText}
                    id={"textDecor"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: this.state.textDecor ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <UnderlineOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}
            </Col>
          </Row>
          <TitleSettingsOfPlugin
            plugin={this.props.plugin}
            handleChange={this.handleChange}
            commonTitleConfig={this.props.commonTitleConfig}
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
            setDefaultsForTitle={this.setDefaultsForTitle}
            title={this.state.title}
            titleAlign={this.state.titleAlign}
            titleColour={this.state.titleColour}
            titleColourChange={this.state.titleColourChange}
            titleColourChangeVisible={this.titleColourChangeVisible}
            titleFont={this.state.titleFont}
            titleFontSize={this.state.titleFontSize}
            keyForTitleSize={this.state.keyForTitleSize}
            titleFontStyle={this.state.titleFontStyle}
            titleFontWeight={this.state.titleFontWeight}
            titleTextDecor={this.state.titleTextDecor}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            changedTitleFontSize={this.state.changedTitleFontSize}
            handleChangeBothOfValues={this.handleChangeBothOfValues}
          />
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.Others")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left", display: "flex" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Settings.NoDataConfiguration")}
              </Text>
              <Tooltip
                title={
                  <>
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                  </>
                }
              >
                <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <TextArea
                allowClear
                id={"noDataTitle"}
                defaultValue={this.state.noDataTitle}
                value={this.state.noDataTitle}
                onChange={this.handleChange}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={i18n.t("NoDataContent.NoData") + " " +
                  i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
