import React, { Component } from "react";
import Button from "../../../../GeneralComponents/Button/Button";
import i18n from "../../../../../Utils/i18next";
import {
  FileExcelOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import { Divider } from "antd";
import $ from "jquery";
import { getIcon, loadingScreen } from "../../../../../Utils/Global";
import { showNotificationWithIcon } from "../../../../../Utils/Notification";
import TooltipVispeahen from "../../../../GeneralComponents/Tooltip/Tooltip";
import { EXCEL_EXPORT_PROGRESS } from "../../../../../Utils/Exports/GeneralExcelExport";

/**
 * Export operations
 */
export default class ExportButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportListVisibility: false
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //If click outside hide switcher area.
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (!$(event.target).closest("#loginPopup").length > 0) {
        this.openExportList(false);
      }
    }
  };

  /**
   * Export options click handler
   * 
   * @param {*} callback 
   */
  handleExport = (callback = () => { }, isExcel = false) => {
    if (this.props.plugins && this.props.plugins.length > 0) {
      this.setState({
        exportListVisibility: false
      });

      if (this.props.settings.isExcelExportStyled) {
        this.props.excelExportProgress(EXCEL_EXPORT_PROGRESS.STARTED)

        setTimeout(callback);
      } else {
        if (isExcel) {
          if (this.props.settings.isExcelExportBigSized)
            {
              this.props.excelExportBigSizeDownload()
              return
            }
            else{
              this.props.downloadFEExcelExport()
            }            

        } else {
          callback()
        }
      }
    }
  }

  /** To open export list dropdown */
  openExportList = status => {
    if (this.props.plugins && this.props.plugins.length > 0) {
      this.setState({
        exportListVisibility: status
      });
    }
  };

  /**
   * Renders standart excel content
   */
  renderExcelContent = () => {
    return (
      <div
        id="exportXLSXButton"
        className={"export-list-item"}
        tooltip={i18n.t("Dashboard.ToolsSection.ExporttoXLSX")}
        onClick={() => this.handleExport(this.props.excelExportClick, true)}
      >
        XLSX
      </div>
    );
  };

  /**
   * Renders custom excel content with list
   */
  renderCustomExcelContent = () => {
    return (
      <div>
        <div
          className={"export-list-group"}
          tooltip={i18n.t("Dashboard.ToolsSection.ExporttoXLSX")}
        >
          {i18n.t("Dashboard.Settings.ExcelList")}
        </div>
        <Divider style={{ margin: 0 }} />

        <div
          id="exportXLSXButton"
          className={"export-list-item"}
          tooltip={i18n.t("Dashboard.ToolsSection.ExporttoXLSX")}
          onClick={() => this.handleExport(this.props.excelExportClick)}
        >
          <span style={{ display: "block", padding: "0 5px" }}>
            - {i18n.t("Dashboard.Settings.StandardExcel")}
          </span>
        </div>

        {this.props.customExcelList.map(excel => (
          <div
            className={"export-list-item"}
            tooltip={i18n.t("Dashboard.ToolsSection.ExporttoXLSX")}
            onClick={e => this.handleExport(() => this.props.excelExportClick(e, excel.id))}
          >
            <span style={{ display: "block", padding: "0 5px" }}>
              - {excel.alias}
            </span>
          </div>
        ))}
        <Divider style={{ margin: 0 }} />
      </div>
    );
  };

  /**
   * Excel export content decider by custom excel status
   */
  excelExportFactory = customStatus => {
    if (customStatus) {
      return this.renderCustomExcelContent();
    } else {
      return this.renderExcelContent();
    }
  };

  /**
   * Export options list
   */
  exportList = () => {
    let customStatus =
      this.props.customExcelList && this.props.customExcelList.length > 0;
    return (
      <div
        className={"export-list"}
        style={{ width: customStatus ? "250px" : "150px" }}
      >
        {this.excelExportFactory(customStatus)}

        <div
          id="exportPDFButton"
          className={"export-list-item"}
          tooltip={i18n.t("Dashboard.ToolsSection.ExporttoPDF")}
          onClick={() => this.handleExport(this.props.exportPDFClick)}
        >
          PDF
        </div>

        <div
          id="exportPngButton"
          className={"export-list-item"}
          tooltip={i18n.t("Dashboard.ToolsSection.ExporttoPNG")}
          onClick={() => this.handleExport(this.props.exportPNGClick)}
        >
          PNG
        </div>
      </div>
    );
  };

  render() {
    let buttonTheme = this.props.theme?.buttons?.exportButton;

    return (
      <div className={"export-operations"} ref={this.wrapperRef}>
        <Button
          id="dashboardExportButton"
          tooltip={i18n.t("Dashboard.ToolsSection.ExportButton")}
          className="dashboard-button btn btn-new circle-button"
          style={{ margin: 0 }}
          onClick={() => {
            if (this.props.plugins && this.props.plugins.length > 0) {
              this.openExportList(!this.state.exportListVisibility)
            } else {
              showNotificationWithIcon(
                i18n.t("Error"),
                i18n.t("Dashboard.ToolsSection.CantExportXLSX"),
                "warn"
              );
            }
          }}
        >
          {
            buttonTheme && (
              <style>
                {`
                  #dashboardExportButton {
                    background-color: ${buttonTheme?.backgroundColor};
                    color: ${buttonTheme?.textColor};
                    border-color: ${buttonTheme?.textColor}33;
                  }

                  #dashboardExportButton:hover,
                  #dashboardExportButton:focus,
                  #dashboardExportButton:active {
                    background-color: ${buttonTheme?.backgroundColorActive};
                    color: ${buttonTheme?.textColorActive};
                    border-color: ${buttonTheme?.textColorActive}33;
                  }
                `}
              </style>
            )
          }
          {
            getIcon(
              buttonTheme?.icon,
              <DownloadOutlined style={{ fontSize: 25 }} />,
              { fontSize: "25px" }
            )
          }
        </Button>

        {this.state.exportListVisibility ? this.exportList() : null}
      </div>
    );
  }
}
