import $ from "jquery";
import { bindData } from "./dataAndQuery";
import i18n from "../../../Utils/i18next";
import { columnMapValidation } from "./PluginColumnMapValidation";
import { store } from "../../../index";
import { API_BASE } from "../../../config";
import { get } from "../../../Utils/WebService";
import {
  setDefaultPlugins,
  setIsRequested
} from "../DefaultPluginsStore/DefaultPluginsAction";
import _ from "lodash";
import { comparedPlugins } from "../../ComparedPlugins";
import { deepCopy } from "../../../Utils/Global";
import { setUpdatedPlugins } from "../PluginTriggerAction";
import evaluate from "../../../Utils/Evaluate";

const clone = require("rfdc")();
var previousPluginInteraction = new Map();

const excludeTitlePlugins = new Set(["measure-tile"])

export const calculatePluginHeight = (plugin, settings) => {
  let pluginHeight = settings.grid.rowHeight * plugin.h; // multiply plugin.height and grid's row height
  let pluginTitleContainer = $("#title-" + plugin.id);
  let pluginContainerBorder = -2;
  let pluginMinHeightDifference = 20;
  let maxHeight =
    pluginHeight -
    (pluginMinHeightDifference +
      pluginTitleContainer.outerHeight() +
      parseInt(pluginTitleContainer.css("margin-bottom")) +
      pluginContainerBorder);

  return maxHeight;
};

export const getDefaultConfig = configurationParameters => {
  var configObj = {},
    configParams = JSON.parse(JSON.stringify(configurationParameters));

  for (var i = 0; i < configParams.length; i++) {
    if (configParams[i].inputOptions) {
      configObj[configParams[i].targetProperty] =
        configParams[i].inputOptions.defaultValue;
    } else {
      configObj[configParams[i].targetProperty] = undefined;
    }
  }

  return configObj;
};

export const onComponentWillMount = (
  props,
  tempPlugin,
  reactions,
  actions,
  configurationParameters,
  configComponent,
  dataComponent,
  prepareColumnMapping,
  comparedCallbackObject,
  comparedErrorCallbackObject,
  resetMeasureData,
  titleReactions
) => {
  let preparedConfig = {};
  let preparedColumMapping = {};

  /**
   * If changes made locally then changes send to parent component otherwise configures plugin by data comes from backend
   */

  if (tempPlugin.config) {
    preparedConfig.config = clone(tempPlugin.config);
  } else {
    preparedConfig = prepareConfig(tempPlugin, configurationParameters);
    tempPlugin = preparedConfig.plugin;
  }

  let isValidColumnMap = columnMapValidation(tempPlugin);

  if (tempPlugin.columnMap) {
    preparedColumMapping.columnMap = clone(tempPlugin.columnMap);

    let controlledProps = controlIsComparePlugin(props)

    if (controlledProps.type === "not-compared") {
      let callBackStatus = props.plugin.key === "measure-tile" ? true : false
      setTimeout(() => {
        bindData(props, undefined, undefined, undefined, isValidColumnMap, undefined, undefined, undefined, false, callBackStatus, comparedCallbackObject, controlledProps, comparedErrorCallbackObject);
      }, 10)
    } else if (controlledProps.type === "compared") {
      resetMeasureData()

      for (let i = 0; i < controlledProps.propsArray.length; i++) {
        setTimeout(() => {
          bindData(controlledProps.propsArray[i], undefined, undefined, undefined, isValidColumnMap, undefined, undefined, undefined, false, true, comparedCallbackObject, controlledProps, comparedErrorCallbackObject);
        }, 10)
      }
    }
  } else {
    if (tempPlugin.columnMap === undefined) {
      preparedColumMapping = prepareColumnMapping(tempPlugin);
      tempPlugin = preparedColumMapping.plugin;
    }

    tempPlugin.rerender = true;
  }

  /**
   * Plugin adds actions and reactions to the object
   */
  tempPlugin["actions"] = actions;
  tempPlugin["reactions"] = reactions;
  tempPlugin["titleReactions"] = titleReactions;

  props.updatePlugin(tempPlugin);
};

/**
 * Get last value from interactions in redux store
 */
const getInteractionObject = () => {
  return store.getState().PluginTriggerReducer.pluginInteractionFilters;
};

/*
* Controls is plugin compare available
*/
const controlIsPluginCompareAvailable = (defaultFilters, props) => {
  let isDefaultFiltersAvailable = defaultFilters && defaultFilters.length > 0 && defaultFilters.filter(defaultFilter => defaultFilter.compared === true).length ? true : false
  let forcePluginMustSingleColumn = false

  if (isDefaultFiltersAvailable) {
    let isDefaultFilterCompareValueAvailable = defaultFilters.filter(defaultFilter => defaultFilter.compared === true)[0].value && defaultFilters.filter(defaultFilter => defaultFilter.compared === true)[0].value.length > 0 ? true : false

    if (isDefaultFilterCompareValueAvailable) {
      let reduxState = store.getState()
      let pluginInteractionFilters = reduxState.PluginTriggerReducer.pluginInteractionFilters
      let defaultFiltersMustBeIgnored = pluginInteractionFilters?.has(props.plugin.id) ? pluginInteractionFilters?.get(props.plugin.id) : false
      let comparedDefaultFilter = defaultFilters.find(defaultFilter => defaultFilter.compared === true)

      if (defaultFiltersMustBeIgnored && defaultFiltersMustBeIgnored.length > 0) {
        for (let i = 0; i < defaultFiltersMustBeIgnored.length; i++) {
          if (defaultFiltersMustBeIgnored[i].usedInteraction?.ignoredDefaultFilters === undefined || defaultFiltersMustBeIgnored[i].usedInteraction?.ignoredDefaultFilters?.includes(comparedDefaultFilter.filterId)) {
            forcePluginMustSingleColumn = true
          }
        }
      }

      if (!forcePluginMustSingleColumn) {
        return true
      }
    }
  }

  return false
}

// Controls is plugin is compare plugin 
const controlIsComparePlugin = (nextProps) => {
  let copiedProps = _.cloneDeep(nextProps)
  let defaultFilters = copiedProps.plugin.defaultFilters

  if (controlIsPluginCompareAvailable(defaultFilters, nextProps) && comparedPlugins.has(nextProps.plugin.key)) {
    let compareColumn = defaultFilters.filter(defaultFilter => defaultFilter.compared === true)[0]
    let compareValues = compareColumn.lastValue ? compareColumn.lastValue : [compareColumn.lsqlValue]
    let nextPropsArrays = []

    for (let i = 0; i < compareValues.length; i++) {
      let newProps = _.cloneDeep(nextProps)
      let newDefaultFilterList = []

      for (let j = 0; j < newProps.plugin.defaultFilters.length; j++) {
        let defFilter = newProps.plugin.defaultFilters[j]

        if (defFilter.compared) {
          defFilter.value = [compareValues[i]]
          defFilter.lastValue = [compareValues[i]]
          defFilter.compareIndex = i
          defFilter.compareLength = compareValues.length

          newDefaultFilterList.push(defFilter)
        } else {
          newDefaultFilterList.push(defFilter)
        }
      }

      let columnMapWithoutDisabledColumns = newProps.plugin.columnMap.measure.data.filter(column => column.isDisabledColumn !== true)

      newProps.plugin.defaultFilters = newDefaultFilterList
      if (columnMapWithoutDisabledColumns.length > 0) {
        newProps.plugin.columnMap.measure.data = [columnMapWithoutDisabledColumns[0]]
      }

      nextPropsArrays.push(newProps)
    }

    return { propsArray: nextPropsArrays, type: "compared", comparedType: "default-filter" }
  } else if (comparedPlugins.has(nextProps.plugin.key)) {
    let columnMap = copiedProps.plugin.columnMap
    let filteredColumnMap = columnMap["measure"].data.filter(column => column.isDisabledColumn !== true)

    if (columnMap["measure"] && filteredColumnMap.length > 1) {
      let propsArray = []

      for (let i = 0; i < filteredColumnMap.length; i++) {
        let newProps = _.cloneDeep(nextProps)

        newProps.plugin.columnMap.measure.data = [filteredColumnMap[i]]
        newProps.plugin.columnMap.measure.data[0]["compareIndex"] = i
        newProps.plugin.columnMap.measure.data[0]["compareLength"] = filteredColumnMap.length

        propsArray.push(newProps)
      }

      return { propsArray: propsArray, type: "compared", comparedType: "multiple-column" }
    }
  }

  return { props: nextProps, type: "not-compared" }
}

export const onComponentWillReceiveProps = (
  nextProps,
  props,
  renderCallBack = undefined,
  rerenderProcessStarted,
  setCallBackObject,
  callBackObject,
  getCallBackObject,
  comparedCallbackObject,
  comparedErrorCallbackObject,
  resetMeasureData
) => {
  function callback() {
    renderCallBack(false);

    let lastCallBackObject = getCallBackObject();

    if (
      isValidCallBackObject(lastCallBackObject) &&
      Object.keys(lastCallBackObject).length > 0
    ) {
      renderCallBack(true);
      bindData(
        lastCallBackObject.nextProps,
        undefined,
        undefined,
        undefined,
        undefined,
        lastCallBackObject.renderCallBack,
        undefined,
        false
      );
    }
  }

  if (nextProps.plugin.getData === true) {
    if (rerenderProcessStarted === false) {
      let interactionObject = getInteractionObject();
      previousPluginInteraction.set(
        nextProps.plugin.id,
        interactionObject.get(nextProps.plugin.id)
      );

      renderCallBack(true);
      setCallBackObject({});

      let controlledProps = controlIsComparePlugin(nextProps)

      if (controlledProps.type === "not-compared") {
        let isPluginMeasureTile = nextProps.plugin.key === "measure-tile" ? true : false

        bindData(nextProps, undefined, undefined, undefined, undefined, callback, undefined, undefined, false, isPluginMeasureTile, comparedCallbackObject, controlledProps, comparedErrorCallbackObject);
      } else if (controlledProps.type === "compared") {
        resetMeasureData();

        for (let i = 0; i < controlledProps.propsArray.length; i++) {
          bindData(controlledProps.propsArray[i], undefined, undefined, undefined, undefined, callback, undefined, undefined, false, true, comparedCallbackObject, controlledProps, comparedErrorCallbackObject);
        }
      }
    }
  } else if (nextProps.plugin.isInteraction === true) {
    let interactionObject = getInteractionObject();

    /** Check value last interaction information with previous interaction information. If they are equals, dont callback. */
    if (
      previousPluginInteraction.get(nextProps.plugin.id) ===
      interactionObject.get(nextProps.plugin.id)
    ) {
      return;
    }

    if (isValidCallBackObject(callBackObject)) {
      callBackObject = {
        nextProps: nextProps,
        props: props,
        renderCallBack: callback,
        rerenderProcessStarted: rerenderProcessStarted
      };

      previousPluginInteraction.set(
        nextProps.plugin.id,
        interactionObject.get(nextProps.plugin.id)
      );

      if (
        isValidCallBackObject(callBackObject) &&
        Object.keys(callBackObject).length > 0
      ) {
        if (setCallBackObject) {
          setCallBackObject(callBackObject);
        }
      }
    }
  }
};

const isValidCallBackObject = callBackObject => {
  return callBackObject !== null && callBackObject !== undefined;
};

export const getColumnMapping = (props, nextProps, prepareColumnMapping) => {
  let columnMap = nextProps.plugin.columnMap;

  if (props.model.name !== nextProps.model.name) {
    columnMap = prepareColumnMapping(nextProps.plugin).columnMap;
  }

  return columnMap;
};

export const prepareConfig = (tempPlugin, configurationParameters) => {
  let theme = sessionStorage.getItem("theme-dashboard");
  let originalConfig = getDefaultConfig(configurationParameters);

  let isPluginHasTitle = !excludeTitlePlugins.has(tempPlugin.key)

  originalConfig.title = isPluginHasTitle ? i18n.t("TitleNotSet") : "";

  let config = deepCopy(originalConfig);

  if (theme) {
    let pluginTheme = JSON.parse(theme)?.plugin;

    if (pluginTheme) {
      delete pluginTheme?.css;

      config = {
        ...config,
        ...pluginTheme
      };
    }
  }

  tempPlugin.originalConfig = originalConfig;

  tempPlugin = updateConfig(tempPlugin, config);

  return {
    plugin: tempPlugin,
    config: config
  };
};

export const themeFields = new Set([
  "backgroundColor",
  "colours",
  "condFormat",
  "legend",
  "paletteColours",
  "showTitle",
  "titleAlign",
  "titleColour",
  "titleFont",
  "titleFontSize",
  "titleFontStyle",
  "titleFontWeight",
  "titleTextDecor"])

export const updateConfig = (plugin, config, configComponent = undefined) => {
  let reduxState = store.getState();
  let updatedPlugins = deepCopy(reduxState.PluginTriggerReducer.plugins);

  if (plugin["config"] === undefined) {
    plugin["config"] = {};
  }

  if (configComponent && plugin["configComponent"] === undefined) {
    plugin["configComponent"] = {};
  }

  plugin["config"] = config;

  if (configComponent) {
    plugin["configComponent"] = configComponent;
  }

  updatedPlugins.set(plugin.id, plugin);

  store.dispatch(setUpdatedPlugins(updatedPlugins));

  return plugin;
};

var pagePopupPluginId = "";
export const setPagePopupPluginId = id => {
  pagePopupPluginId = id;
};

/** When error the occured, add to plugin for show notification. */
export const addErrorInPlugin = (plugin, error, errorKey, type, comesFromPluginRender = false, pluginErrorHash) => {
  if (plugin.errors === undefined) {
    plugin.errors = new Map();
  }

  let reduxState = store.getState();
  let pluginImages = reduxState.DefaultPluginsReducer
    ? reduxState.DefaultPluginsReducer.pluginsMap
    : undefined;
  let isRequested = reduxState.DefaultPluginsReducer.isRequested;
  let isPluginImagesNotExists =
    (!pluginImages || pluginImages.size === 0) && isRequested === false;

  if (isPluginImagesNotExists) {
    getPluginImages();
  }

  if (!comesFromPluginRender) {
    plugin.errors.set(errorKey, { type: type, message: error });
  } else {
    plugin.errors.clear()

    let hashKeys = Array.from(pluginErrorHash.keys())

    for (let i = 0; i < hashKeys.length; i++) {
      plugin.errors.set(hashKeys[i])
    }
  }
};

/** When the error fixed, remove from plugin.  */
export const removeErrorFromPlugin = (plugin, errorKey) => {
  if (plugin && plugin.errors !== undefined) {
    if (plugin.errors.has(errorKey)) {
      plugin.errors.delete(errorKey);
    }
  }
};

/**
 * Gets plugin images and dispatch store
 */
export const getPluginImages = (callback = undefined) => {
  let url = `${API_BASE}/plugin`;

  store.dispatch(setIsRequested(true));

  const successFunc = result => {
    let plugins = result.data;
    let pluginImages = new Map();

    for (let pluginImage of result.data) {
      pluginImages.set(pluginImage.key, pluginImage.image);
    }

    let pluginsObject = { plugins: plugins, pluginsMap: pluginImages };

    store.dispatch(setDefaultPlugins(pluginsObject));
    store.dispatch(setIsRequested(false));

    if (callback) {
      callback(pluginsObject);
    }
  };

  get(url, successFunc, undefined, false);
};

/**
 * Gets mouse event and checks general components for clicked
 * @param {*} event 
 */
export const shouldIClose = (event) => {
  let isTargetDropdownItem =
    $(".ant-select-dropdown").length > 0 &&
    $(event.target).closest(".ant-select-dropdown").length > 0;
  let isTargetModelItem =
    $(".ant-modal-wrap").length > 0 &&
    $(event.target).closest(".ant-modal-wrap").length > 0;
  let isTargetPopOverItem =
    $(".ant-popover").length > 0 &&
    $(event.target).closest(".ant-popover").length > 0;
  let isTargetTooltip =
    ($(event.target).closest(".ant-tooltip").length > 0 ||
      $(event.target).closest(".ant-tooltip-inner").length > 0) &&
    ($(event.target).closest(".accordion-content-tooltip").length > 0 ||
      $(event.target).closest(".box-item-tooltip").length > 0 ||
      $(event.target).closest(".formula-editor-tooltip").length > 0 ||
      $(event.target).closest(".table-info-tooltip").length > 0 ||
      $(event.target).closest(".filter-name-tooltip").length > 0 ||
      $(event.target).closest(".default-filter-operator-tooltip").length > 0 ||
      $(event.target).closest(".age-range-tooltip").length > 0 ||
      $(event.target).closest(".scatter-range-tooltip").length > 0 ||
      $(event.target).closest(".radar-range-tooltip").length > 0 ||
      $(event.target).closest(".reset-config-tooltip").length > 0);

  let isTargetChoroplethPopup = $(event.target).closest(".mapTopojsonOperator").length > 0

  return !(
    isTargetDropdownItem ||
    isTargetModelItem ||
    isTargetPopOverItem ||
    isTargetTooltip ||
    isTargetChoroplethPopup
  );
};

/**
  * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  * 
  * @param {String} text The text to be rendered.
  * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  * 
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  */
export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");

  context.font = font;
  const metrics = context.measureText(text);

  return metrics.width;
}

export function getCssStyle(element, prop) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

export function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

/**
 *
 * @param {*} drillDowns
 * @returns
 *
 * Converts drill down saved as string to map
 */
export const convertDrillDownStringToMap = (drillDowns) => {
  if (drillDowns) {
    if (typeof drillDowns.drillDownColumnsForParentColumns === "string") {
      drillDowns.drillDownColumnsForParentColumns = new Map(
        JSON.parse(drillDowns.drillDownColumnsForParentColumns)
      );
    }

    if (typeof drillDowns.allDrillDownColumnsInPlugin === "string") {
      drillDowns.allDrillDownColumnsInPlugin = new Map(
        JSON.parse(drillDowns.allDrillDownColumnsInPlugin)
      );
    }

    if (typeof drillDowns.drillDownLayerMap === "string") {
      drillDowns.drillDownLayerMap = new Map(
        JSON.parse(drillDowns.drillDownLayerMap)
      );
    }

    if (typeof drillDowns.preserveDefaultFilters === "string") {
      drillDowns.preserveDefaultFilters = new Map(
        JSON.parse(drillDowns.preserveDefaultFilters)
      );
    }
  }

  return drillDowns;
};

//Calculates current date and time
export function getCurrentDateTime() {
  let currentdate = new Date();
  let tempsec = currentdate.getSeconds();
  let tempmin = currentdate.getMinutes();
  let temphour = currentdate.getHours();
  let date = currentdate.getDate();
  let month = currentdate.getMonth() + 1;

  if (currentdate.getSeconds() < 10) {
    tempsec = "0" + currentdate.getSeconds();
  }

  if (currentdate.getMinutes() < 10) {
    tempmin = "0" + currentdate.getMinutes();
  }

  if (currentdate.getHours() < 10) {
    temphour = "0" + currentdate.getHours();
  }

  if (date < 10) {
    date = "0" + currentdate.getDate();
  }

  if (month < 10) {
    month = "0" + (currentdate.getMonth() + 1);
  }

  let datetime = date + "/" + month + "/" + currentdate.getFullYear() + " - " + temphour + ":" + tempmin + ":" + tempsec;

  return datetime;
}

/** Convert HSL colour to RGB */
export const hslToRGB = hsl => {
  let r, g, b;

  let h = hsl[0],
    s = hsl[1],
    l = hsl[2];

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    let hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;

      return p;
    }

    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

/** Convert RGB value to Hex. Expects input in 'rgb(r, g, b)' format or as a three dimensional array. */
export const rgbToHex = rgb => {
  if (typeof (rbg) == 'string') {
    let re = new RegExp('rgb\\((\\d*?), (\\d*?), (\\d*?)\\)')
    let splitRGB = re.exec(rgb);

    if (splitRGB) {
      splitRGB.pop(0);
      rgb = splitRGB;
    }
  }

  return "#" + componentToHex(+rgb[0]) + componentToHex(+rgb[1]) + componentToHex(+rgb[2]);
}

export const componentToHex = c => {
  let hex = c.toString(16);

  return hex.length == 1 ? "0" + hex : hex;
}

/** Convert Hex colour to RGB. */
export const hexToRGB = hex => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
}

/** Convert RGB colour to HSL. */
export const rgbToHSL = rgb => {
  let r = rgb[0],
    g = rgb[1],
    b = rgb[2];

  r /= 255;
  g /= 255;
  b /= 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);

  let h, s, l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;

    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }

    h /= 6;
  }

  return [h, s, l];
}

/** Convert HEX colour to HSL. */
export const hexToHSL = hex => {
  return rgbToHSL(hexToRGB(hex));
}

export const getContrastColor = (color) => {
  let colorRGB;

  if (color?.indexOf('#') > -1 || typeof color === "number") {
    if (typeof color === "string") {

    }

    colorRGB = hexToRGB(color);
  } else if (color?.indexOf("hsl") > -1) {
    colorRGB = hslToRGB(color);
  } else if (color?.indexOf("rgb") > -1 || typeof color === "object") {
    if (typeof color === "string") {
      colorRGB = color.match(/\d+/g).map(Number);
    } else if (color instanceof Object) {
      colorRGB = Object.values(colorRGB);
    }
  } else {
    return null;
  }

  let yiq = (colorRGB[0] * 299 + colorRGB[1] * 587 + colorRGB[2] * 114) / 1000;

  return yiq >= 128 ? "#000000" : "#FFFFFF";
}

/**
 * Fills calculations (session variable) in the given text
 * 
 * @param {*} pluginId 
 * @param {*} text 
 * @returns 
 */
export const calculateText = (plugin, text) => {
  if (!text || text.length === 0) return text;

  let reduxState = store.getState();
  let sessionVariableMatchRule = /\$\s*\{\s*session\.\w+\s*\}/g;
  let sessionVariables = reduxState.SessionVariableReducer.sessionVariables;
  let usedSessionVariables = new Map(plugin.usedSessionVariables);
  let dashboardSessionVariables = new Map(reduxState.SessionVariableReducer.dashboard);

  let matchedCalculations = (() => {
    let matches = [];
    let stack = [];
    let currentExpression = '';

    for (let i = 0; i < text.length; i++) {
      if (text[i] === '(') {
        stack.push(i);
      } else if (text[i] === ')') {
        if (stack.length > 0) {
          const startIndex = stack.pop();
          if (startIndex === 0 || text[startIndex - 1] === '=') {
            currentExpression = text.slice(startIndex - 1, i + 1);
            matches.push(currentExpression);
          }
        }
      }
    }

    return matches;
  })();

  for (let [name, value] of dashboardSessionVariables?.entries() || []) {
    if (name && RegExp(`\\$\\s*\\{\\s*session\\.${name}\\s*\\}`, "g").test(text)) {
      usedSessionVariables.set(name, value);
    }
  }

  for (let match of matchedCalculations) {
    let shouldEvaluate = true;
    let calculation = match.slice(2, -1);
    let matchedSessionVariables = calculation?.match(sessionVariableMatchRule) || [];

    for (let match of matchedSessionVariables) {
      let name = match.replace(/\$\s*\{\s*session\.(\w+)\s*\}/g, (m, p1) => p1);
      let value = usedSessionVariables.get(name);
      let variable = sessionVariables?.get(name);

      if (value !== undefined ) {
        if (value instanceof Array) {
          value = value.filter(d => d !== null);
          value = value.map(d => {
            try {
              return variable?.dataType !== "date" ? evaluate(d) : d;
            } catch (e) {
              return d;
            }
          });

          value = value.join(", ");
        } else {
          try {
            value = evaluate(value);
          } catch (e) {
            // pass
          }
        }

        calculation = calculation?.replaceAll(match, value);
      } else if (variable !== undefined) {
        calculation = "";
      }

      if (calculation === value || calculation === "") {
        shouldEvaluate = false;
      }
    }

    try {
      calculation = shouldEvaluate ? evaluate(calculation) : calculation;
    } catch (e) {
      console.error(`Calculation ${calculation} cannot be evaluated.`);

      let matchedMissing = calculation
        ?.match(sessionVariableMatchRule)
        ?.map(m => m.slice(10, -1))
        ?.join(", ");

      if (matchedMissing) {
        calculation = i18n.t("Dashboard.Plugin.Calculations.Errors.Missing")
      } else {
        calculation = i18n.t("Dashboard.Plugin.Calculations.Errors.Error")
      }
    }

    text = text.replaceAll(match, calculation)
  }

  return text;
}