import React, { Component } from "react";
import TitleWithIcon from "../TitleWithIcon";
import "./selector.css";
import { Row, Input, Typography, Divider } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import i18n from "../../../Utils/i18next";
import { deepCopy } from "../../../Utils/Global";

const { Search } = Input;
const { Text } = Typography;

/**
 * props >> list : list of objects which will be shown in the dropdown (search action will update this)
 * item of list : type as object  which has following fields;
 * displayName (must) > field which is the title of every item of the dropdown
 * color \ icon (optional) > field which will be shown at the left of displayName
 * (color or icon should be used if both of them includes data icon will be shown)
 * id (must): unique for every item
 * props >> selecteds : selected items from props.list as list of objects which must have (id,displayName, color \ icon fields) same field of list
 * props >> updateList : function for search which must update props.list
 * props >> select : function for selection of item(item of props.list) must update props.selecteds
 * props >> maxHeight : maximum height of the list if it won't be enough scroll will be shown
 */
export default class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = { initialList: [] };
  }

  /**
   * set initialList if it never changed before
   * @param {*} nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (this.state.initialList.length == 0 && nextProps.list.length > 0) {
      this.setState({
        ...this.state,
        initialList: deepCopy(nextProps.list),
      });
    }
  }

  /**
   * Detect whether item is selected or not return true/false
   * return value will be used for styling
   * @param {item} option - item whose selection status will be detected
   */
  detectSelection = (option) => {
    let status = false;

    this.props.selecteds.forEach(function (item) {
      if (item.id == undefined || item.id == null) {
        if (item.label === option.id) {
          status = true;
          return;
        }
      } else {
        if (item.id === option.id) {
          status = true;
          return;
        }
      }
    });

    return status;
  };

  /**
   * Filter list by using given value
   * @param {*} e - element which fired this function
   */
  onSearch = (e) => {
    const THIS = this;
    /**
     * Always use initialList so always filter between all of the labels
     */
    let result = deepCopy(THIS.state.initialList);
    if (e.target.value) {
      result = result.filter(function (item) {
        // Since string.search function returns position of string which searched result
        // can be 0 but 0 means false so we should add 1 to it to make it true
        return (
          item.displayName.toLowerCase().search(e.target.value.toLowerCase()) +
          1
        );
      });
    }

    this.props.updateList(result);
  };

  /*
  * Returns well defined or created lists
  */
  wellDefinedOrOtherList = (lists) => {
    let wellDefinedLabels = []
    let createdLabels = []

    lists.map(list => {
      if (!list.isDeletable) {
        wellDefinedLabels.push(list)
      } else {
        createdLabels.push(list)
      }
    })

    return {
      wellDefinedLabels: wellDefinedLabels,
      createdLabels: createdLabels
    }
  } 

  render() {
    let labels = this.wellDefinedOrOtherList(this.props.list)
    let wellDefinedLables = labels.wellDefinedLabels
    let createdLabels = labels.createdLabels

    return (
      <div>
        <Row className={"ant-row-padding-full"}>
          <Text>{i18n.t("LandingPage.Label.Title")}</Text>
        </Row>
        <Row style={{
          marginTop:"-3px"
        }}>
          <Text
            type="span"
            style={{
              fontSize: "13px",
              textAlign: "center",
              whiteSpace: "normal",
            }}
          >
            {i18n.t("LandingPage.Label.Description")}
          </Text>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Search
            placeholder={i18n.t("Dashboard.Label.Selector.Search")}
            onChange={this.onSearch}
          />
        </Row>
        <div
          className={"scroll-labels"}
          style={{ maxHeight: this.props.maxHeight }}
        >
          {wellDefinedLables.map((item, index) => {
            return (
              <Row
                key={item.id}
                style={{ textAlign: "left", paddingLeft: "15px" }}
                onClick={() => this.props.select(item)}
                className={
                  this.detectSelection(item) ? "disabled-row" : "default-row"
                }
              >
                <TitleWithIcon
                  key={index}
                  color={item.color}
                  icon={item.icon ? item.icon : undefined}
                  title={item.translateName}
                  className="colored-shape-custom"
                  titleStyle="title-style"
                ></TitleWithIcon>
                <CheckCircleFilled
                  style={{
                    color: "#099DB2",
                    float: "right",
                    marginRight: "5px",
                    fontSize: "16px",
                    marginTop: "3px",
                  }}
                />
              </Row>
            );
          })}
          <Divider style={{margin: "4px 0px 12px 0px"}}></Divider>
          {createdLabels.map((item, index) => {
            return (
              <Row
                key={item.id}
                style={{ textAlign: "left", paddingLeft: "15px" }}
                onClick={() => this.props.select(item)}
                className={
                  this.detectSelection(item) ? "disabled-row" : "default-row"
                }
              >
                <TitleWithIcon
                  key={index}
                  color={item.color}
                  icon={item.icon ? item.icon : undefined}
                  title={item.translateName}
                  className="colored-shape-custom"
                  titleStyle="title-style"
                ></TitleWithIcon>
                <CheckCircleFilled
                  style={{
                    color: "#099DB2",
                    float: "right",
                    marginRight: "5px",
                    fontSize: "16px",
                    marginTop: "3px",
                  }}
                />
              </Row>
            );
          })}
        </div>
      </div>
    );
  }
}
