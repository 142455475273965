import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import { Row, Col, Input, Select, Checkbox, Divider, Button, Tooltip } from "antd";
import {
  FontSizeOutlined,
  BgColorsOutlined,
  EyeInvisibleOutlined,
  NumberOutlined,
  UnderlineOutlined,
  ItalicOutlined,
  BoldOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../Utils/i18next";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import $ from "jquery"
import FontTypeSelector from "../../../GeneralComponents/FontTypeSelector/FontTypeSelector";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { TextArea } = Input;
const { Option } = Select;

export default class PivotTableConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoSize: this.props.config.autoSize,
      columnHeight: this.props.config.columnHeight,
      columnWidth: this.props.config.columnWidth,
      colColor: this.props.config.colColor == undefined ? "rgba(1,0,0,1)" : this.props.config.colColor,
      isColSizeMustConstant: this.props.config.isColSizeMustConstant == undefined ? true : this.props.config.isColSizeMustConstant,
      isRowSizeMustConstant: this.props.config.isRowSizeMustConstant == undefined ? true : this.props.config.isRowSizeMustConstant,
      rowWidth: this.props.config.rowWidth == undefined ? 100 : this.props.config.rowWidth,
      rowHeight: this.props.config.rowHeight == undefined ? 14 : this.props.config.rowHeight,
      rowColor: this.props.config.rowColor == undefined ? "rgba(1,0,0,1)" : this.props.config.rowColor,
      headerFont: this.props.config.headerFont == undefined ? "Verdana" : this.props.config.headerFont,
      headerFontSize: this.props.config.headerFontSize == undefined ? "10" : this.props.config.headerFontSize,
      totalFontSize: this.props.config.totalFontSize == undefined ? "10" : this.props.config.totalFontSize,
      totalFontColour: this.props.config.totalFontColour == undefined ? "rgba(1,0,0,1)" : this.props.config.totalFontColour,
      valueFontSize: this.props.config.valueFontSize == undefined ? "10" : this.props.config.valueFontSize,
      valueFontFamily: this.props.config.valueFontFamily == undefined ? "Verdana" : this.props.config.valueFontFamily,
      valueColor: this.props.config.valueColor == undefined ? "rgba(1,0,0,1)" : this.props.config.valueColor,
      valueFontWeight: this.props.config.valueFontWeight == undefined ? false : this.props.config.valueFontWeight,
      valueFontStyle: this.props.config.valueFontStyle == undefined ? false : this.props.config.valueFontStyle,
      valueFontDecor: this.props.config.valueFontDecor == undefined ? false : this.props.config.valueFontDecor,
      headerFontWeight: this.props.config.headerFontWeight == undefined ? false : this.props.config.headerFontWeight,
      headerFontStyle: this.props.config.headerFontStyle == undefined ? false : this.props.config.headerFontStyle,
      headerFontDecor: this.props.config.headerFontDecor == undefined ? false : this.props.config.headerFontDecor,
      totalFontWeight: this.props.config.totalFontWeight == undefined ? false : this.props.config.totalFontWeight,
      totalFontStyle: this.props.config.totalFontStyle == undefined ? false : this.props.config.totalFontStyle,
      totalFontDecor: this.props.config.totalFontDecor == undefined ? false : this.props.config.totalFontDecor,
      defaultDrilldownColor: this.props.config.defaultDrilldownColor == undefined ? true : this.props.config.defaultDrilldownColor,
      settedDrilldownColor: this.props.config.settedDrilldownColor == undefined ? "rgba(255, 255, 255, 1)" : this.props.config.settedDrilldownColor,
      controls: this.props.config.controls,
      pivotTableDescription: this.props.config.pivotTableDescription,
      renderer: this.props.config.renderer,
      showHideButton: this.props.config.showHideButton,
      showSum: this.props.config.showSum,
      subTotal: this.props.config.subTotal,
      sumSelector: this.props.config.sumSelector == undefined ? "ShowBoth" : this.props.config.sumSelector,
      themeColour: this.props.config.themeColour,
      valuesAsCols: this.props.config.valuesAsCols,
      wrapHeader: this.props.config.wrapHeader,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      condFormat: this.props.config.condFormat,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      enhanceMode: this.props.config.enhanceMode || false,
      totalFontFamily: this.props.config.totalFontFamily === undefined ? "Verdana" : this.props.config.totalFontFamily,
      groupName: this.props.config.groupName,
      rowSum: this.props.config.rowSum,
      pivotTheme: this.props.config.pivotTheme,
      groupDefaultExpanded: this.props.config.groupDefaultExpanded,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle,
      showCornerTotal: this.props.config.showCornerTotal == undefined ? false : this.props.config.showCornerTotal,
      nullValue: this.props.config.nullValue ? this.props.config.nullValue : ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        autoSize: nextProps.config.autoSize,
        columnHeight: nextProps.config.columnHeight,
        columnWidth: nextProps.config.columnWidth,
        colColor: nextProps.config.colColor == undefined ? "rgba(1,0,0,1)" : nextProps.config.colColor,
        isColSizeMustConstant: nextProps.config.isColSizeMustConstant == undefined ? true : nextProps.config.isColSizeMustConstant,
        isRowSizeMustConstant: nextProps.config.isRowSizeMustConstant == undefined ? true : nextProps.config.isRowSizeMustConstant,
        rowWidth: nextProps.config.rowWidth == undefined ? 100 : nextProps.config.rowWidth,
        rowHeight: nextProps.config.rowHeight == undefined ? 14 : nextProps.config.rowHeight,
        rowColor: nextProps.config.rowColor == undefined ? "rgba(1,0,0,1)" : nextProps.config.rowColor,
        headerFont: nextProps.config.headerFont == undefined ? "Verdana" : nextProps.config.headerFont,
        headerFontSize: nextProps.config.headerFontSize == undefined ? "10" : nextProps.config.headerFontSize,
        totalFontSize: nextProps.config.totalFontSize == undefined ? "10" : nextProps.config.totalFontSize,
        totalFontColour: nextProps.config.totalFontColour == undefined ? "rgba(1,0,0,1)" : nextProps.config.totalFontColour,
        valueFontSize: nextProps.config.valueFontSize == undefined ? "10" : nextProps.config.valueFontSize,
        valueFontFamily: nextProps.config.valueFontFamily == undefined ? "Verdana" : nextProps.config.valueFontFamily,
        valueColor: nextProps.config.valueColor == undefined ? "rgba(1,0,0,1)" : nextProps.config.valueColor,
        valueFontWeight: nextProps.config.valueFontWeight == undefined ? false : nextProps.config.valueFontWeight,
        valueFontStyle: nextProps.config.valueFontStyle == undefined ? false : nextProps.config.valueFontStyle,
        valueFontDecor: nextProps.config.valueFontDecor == undefined ? false : nextProps.config.valueFontDecor,
        headerFontWeight: nextProps.config.headerFontWeight == undefined ? false : nextProps.config.headerFontWeight,
        headerFontStyle: nextProps.config.headerFontStyle == undefined ? false : nextProps.config.headerFontStyle,
        headerFontDecor: nextProps.config.headerFontDecor == undefined ? false : nextProps.config.headerFontDecor,
        totalFontWeight: nextProps.config.totalFontWeight == undefined ? false : nextProps.config.totalFontWeight,
        totalFontStyle: nextProps.config.totalFontStyle == undefined ? false : nextProps.config.totalFontStyle,
        totalFontDecor: nextProps.config.totalFontDecor == undefined ? false : nextProps.config.totalFontDecor,
        defaultDrilldownColor: nextProps.config.defaultDrilldownColor == undefined ? true : nextProps.config.defaultDrilldownColor,
        settedDrilldownColor: nextProps.config.settedDrilldownColor == undefined ? "rgba(255, 255, 255, 1)" : nextProps.config.settedDrilldownColor,
        controls: nextProps.config.controls,
        pivotTableDescription: nextProps.config.pivotTableDescription,
        renderer: nextProps.config.renderer,
        showHideButton: nextProps.config.showHideButton,
        showSum: nextProps.config.showSum,
        subTotal: nextProps.config.subTotal,
        sumSelector: nextProps.config.sumSelector == undefined ? "ShowBoth" : nextProps.config.sumSelector,
        themeColour: nextProps.config.themeColour,
        valuesAsCols: nextProps.config.valuesAsCols,
        wrapHeader: nextProps.config.wrapHeader,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        condFormat: nextProps.config.condFormat,

        groupName: nextProps.config.groupName,
        rowSum: nextProps.config.rowSum,
        pivotTheme: nextProps.config.pivotTheme,
        groupDefaultExpanded: nextProps.config.groupDefaultExpanded,
        enhanceMode: nextProps.config.enhanceMode,
        totalFontFamily: nextProps.config.totalFontFamily === undefined ? "Verdana" : nextProps.config.totalFontFamily,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,
        showCornerTotal: nextProps.config.showCornerTotal == undefined ? false : nextProps.config.showCornerTotal,
        nullValue: nextProps.config.nullValue ? nextProps.config.nullValue : ""
      });
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /*
  * Changes value font size
  */
  handleChangeValueFontSize = e => {
    let valueFontSizeErr = false

    if (+e.target.value < 12) {
      valueFontSizeErr = true
    }

    this.setState({
      ...this.state,
      valueFontSize: e.target.value,
      valueFontSizeErr: valueFontSizeErr
    });

    this.setConfigToPlugin({ type: "valueFontSize", value: e.target.value });
  }

  /*
  * Changes null value
  */
  handleChangeNullValue = e => {
    this.setState({
      ...this.state,
      nullValue: e.target.value,
    });

    this.setConfigToPlugin({ type: "nullValue", value: e.target.value });
  }

  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /*
  * Changes font style
  */
  handleCheckFontStyle = e => {
    this.setState({
      ...this.state,
      [e.target.id]: !this.state[e.target.id]
    });

    this.setConfigToPlugin({ type: e.target.id, value: !this.state[e.target.id] });
  };

  /**
   * Changes the color attribute in MapCluster plugin use colorOnChange
   */
  themeColourOnChange = obj => {
    this.setState({
      ...this.state,
      themeColour: obj.color
    });

    this.setConfigToPlugin({ type: "themeColour", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in MapCluster plugin
   */
  themeColourChangeVisible = () => {
    this.setState({
      ...this.state,
      themeColourChange: !this.state.themeColourChange
    });
  };

  /*
  * Sets config to high order plugin component's state
  */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * On Chnage value of Select Sum Dropdown call this function. To change its value
   * @param {*} value 
   */
  sumSelectorOnChange = value => {
    this.setState({
      ...this.state,
      sumSelector: value
    }, () => {
      let shouldCloseCornerTotal = (value !== "ShowBoth" && value !== "Both");

      if (shouldCloseCornerTotal) {
        this.showCornerTotalOnchange(false);
      }
    });

    this.setConfigToPlugin({ type: "sumSelector", value: value });
  };

  /**
   * On Change value of showCornerTotal checkbox call this function. To change its value
   * @param {*} value 
   */
  showCornerTotalOnchange = value => {
    this.setState({
      ...this.state,
      showCornerTotal: value
    }, () => {
      this.setConfigToPlugin({ type: "showCornerTotal", value: value });
    });
  };

  rendererOnChange = value => {
    this.setState({
      ...this.state,
      sumSelector: value
    });

    this.setConfigToPlugin({ type: "renderer", value: value });
  };

  /*
  * Changes col color background visibility
  */
  colColorBackgroundColorOnChange = () => {
    this.setState({
      ...this.state,
      colColorBackgroundOnChange: !this.state.colColorBackgroundOnChange
    });
  };

  /*
  * Changes drill color background visibility
  */
  drillColorBackgroundColorOnChange = () => {
    this.setState({
      ...this.state,
      drillColorBackgroundOnChange: !this.state.drillColorBackgroundOnChange
    });
  };

  totalColorBackgroundColorOnChange = () => {
    this.setState({
      ...this.state,
      totalColorBackgroundOnChange: !this.state.totalColorBackgroundOnChange
    });
  };

  /*
  * Changes col color
  */
  colColorOnChange = obj => {
    this.setState({
      ...this.state,
      colColor: obj.color
    });

    this.setConfigToPlugin({ type: "colColor", value: obj.color });
  };

  /*
  * Changes drilldowned row/column color
  */
  drillColorOnChange = obj => {
    this.setState({
      ...this.state,
      settedDrilldownColor: obj.color
    });

    this.setConfigToPlugin({ type: "settedDrilldownColor", value: obj.color });
  };

  /*
  * Changes total color
  */
  totalColorOnChange = obj => {
    this.setState({
      ...this.state,
      totalFontColour: obj.color
    });

    this.setConfigToPlugin({ type: "totalFontColour", value: obj.color });
  };

  /*
  * Changes col color background visibility
  */
  rowColorBackgroundColorOnChange = () => {
    this.setState({
      ...this.state,
      rowColorBackgroundOnChange: !this.state.rowColorBackgroundOnChange
    });
  };

  /*
  * Changes col color background visibility
  */
  valueColorBackgroundColorOnChange = () => {
    this.setState({
      ...this.state,
      valueColorBackgroundOnChange: !this.state.valueColorBackgroundOnChange
    });
  };

  /*
* Changes col color
*/
  valueColorOnChange = obj => {
    this.setState({
      ...this.state,
      valueColor: obj.color
    });

    this.setConfigToPlugin({ type: "valueColor", value: obj.color });
  };

  /*
  * Changes col color
  */
  rowColorOnChange = obj => {
    this.setState({
      ...this.state,
      rowColor: obj.color
    });

    this.setConfigToPlugin({ type: "rowColor", value: obj.color });
  };

  /*
  * onChange headerFont
  */
  fontOnChange = obj => {
    this.setState({
      ...this.state,
      font: obj
    });

    this.setConfigToPlugin({ type: "headerFont", value: obj });
  };

  /*
  * onChange headerFont
  */
  valueFontOnChange = obj => {
    this.setState({
      ...this.state,
      valueFontFamily: obj
    });

    this.setConfigToPlugin({ type: "valueFontFamily", value: obj });
  };

  /*
  * onChange total Row/Column fontFamily
  */
  totalFontFamilyOnChange = obj => {
    this.setState({
      ...this.state,
      totalFontFamily: obj
    });

    this.setConfigToPlugin({ type: "totalFontFamily", value: obj });
  };

  render() {
    return (
      <div style={{ color: "#505050" }}>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Attribute")}
        </Divider>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.EnhanceMode")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"enhanceMode"}
              defaultChecked={this.state.enhanceMode}
              checked={this.state.enhanceMode}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.TextStyling")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ValueFontSize")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"valueFontSize"}
              defaultValue={this.state.valueFontSize}
              value={this.state.valueFontSize}
              style={this.state.valueFontSizeErr ? { background: "rgba(255, 0, 0, 0.26)" } : {}}
              onChange={this.handleChangeValueFontSize}
            />
          </Col>
        </Row>
        {this.state.valueFontSizeErr ?
          <Row>
            <Col span={12}></Col>
            <Col span={12} style={{ fontSize: "11px", textAlign: "left", color: "red" }}>
              {"* " + i18n.t("Dashboard.Configuration.Fields.CantReduceMeasureSize", { size: `11` })}
            </Col>
          </Row>
          : null}
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <NumberOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ValueFontFamily")}
            </Text>
          </Col>
          <Col span={12}>
            <FontTypeSelector
              placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
              onChange={this.valueFontOnChange}
              value={this.state.valueFontFamily}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ValueColor")}
            </Text>
          </Col>
          <Col span={10}>
            <Button
              id={"valueColorBackgroundOnChange"}
              onClick={this.valueColorBackgroundColorOnChange}
              style={{ width: "100%" }}
            >
              {this.state.valueColorBackgroundOnChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.valueColorBackgroundOnChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.valueColor}
                onChange={this.valueColorOnChange}
                id={"valueColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
          <Col span={2}>
            {/* selected Color */}
            <div
              onClick={this.valueColorBackgroundColorOnChange}
              style={{
                marginLeft: "5px",
                height: "32px",
                border: "1px solid #d7d6d6",
                boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor: this.state.valueColor,
              }}
            ></div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col
            style={{ textAlign: "center" }}
            span={this.props.titleIcon ? this.props.titleIcon : 3}
          >
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col
            style={{ textAlign: "left" }}
            span={this.props.titleColumn ? this.props.titleColumn : 9}
          >
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.ConditionalFormatting.ValueFontStyle")}
            </Text>
          </Col>
          <Col style={{ textAlign: "left" }} span={12}>
            <span>
              <Button
                onClick={this.handleCheckFontStyle}
                style={{
                  backgroundColor: this.state.valueFontWeight ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
                id={"valueFontWeight"}
              >
                <BoldOutlined
                  style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                />
              </Button>
            </span>

            <span>
              <Button
                onClick={this.handleCheckFontStyle}
                style={{
                  marginLeft: "20px",
                  backgroundColor: this.state.valueFontStyle ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
                id={"valueFontStyle"}
              >
                <ItalicOutlined
                  style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                />
              </Button>
            </span>

            <span>
              <Button
                onClick={this.handleCheckFontStyle}
                style={{
                  marginLeft: "20px",
                  backgroundColor: this.state.valueFontDecor ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
                id={"valueFontDecor"}
              >
                <UnderlineOutlined
                  style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                />
              </Button>
            </span>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowSumColumns")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"showSum"}
              defaultChecked={this.state.showSum}
              checked={this.state.showSum}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ValuesAsColumns")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"valuesAsCols"}
              defaultChecked={this.state.valuesAsCols}
              checked={this.state.valuesAsCols}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.TotalStyling")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <NumberOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.TotalFontFamily")}
            </Text>
          </Col>
          <Col span={12}>
            <FontTypeSelector
              placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
              onChange={this.totalFontFamilyOnChange}
              value={this.state.totalFontFamily}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.TotalColor")}
            </Text>
          </Col>
          <Col span={10}>
            <Button
              id={"totalColorBackgroundOnChange"}
              onClick={this.totalColorBackgroundColorOnChange}
              style={{ width: "100%" }}
            >
              {this.state.totalColorBackgroundOnChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.totalColorBackgroundOnChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.totalFontColour}
                onChange={this.totalColorOnChange}
                id={"totalFontColour"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
          <Col span={2}>
            {/* selected Color */}
            <div
              onClick={this.totalColorBackgroundColorOnChange}
              style={{
                marginLeft: "5px",
                height: "32px",
                border: "1px solid #d7d6d6",
                boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor: this.state.totalFontColour,
              }}
            ></div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col
            style={{ textAlign: "center" }}
            span={this.props.titleIcon ? this.props.titleIcon : 3}
          >
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col
            style={{ textAlign: "left" }}
            span={this.props.titleColumn ? this.props.titleColumn : 9}
          >
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.ConditionalFormatting.TotalStyle")}
            </Text>
          </Col>
          <Col style={{ textAlign: "left" }} span={12}>
            <span>
              <Button
                onClick={this.handleCheckFontStyle}
                style={{
                  backgroundColor: this.state.totalFontWeight ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
                id={"totalFontWeight"}
              >
                <BoldOutlined
                  style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                />
              </Button>
            </span>

            <span>
              <Button
                onClick={this.handleCheckFontStyle}
                style={{
                  marginLeft: "20px",
                  backgroundColor: this.state.totalFontStyle ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
                id={"totalFontStyle"}
              >
                <ItalicOutlined
                  style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                />
              </Button>
            </span>

            <span>
              <Button
                onClick={this.handleCheckFontStyle}
                style={{
                  marginLeft: "20px",
                  backgroundColor: this.state.totalFontDecor ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
                id={"totalFontDecor"}
              >
                <UnderlineOutlined
                  style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                />
              </Button>
            </span>
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.RowColumnStyle")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <NumberOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.HeaderFont")}
            </Text>
          </Col>
          <Col span={12}>
            <FontTypeSelector
              placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
              onChange={this.fontOnChange}
              value={this.state.headerFont}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "9px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.FitCols")}
            </Text>
          </Col>
          <Col span={1}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"isColSizeMustConstant"}
              defaultChecked={this.state.isColSizeMustConstant}
              checked={this.state.isColSizeMustConstant}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
          <Col span={11}>
            <div style={{ color: "rgb(255, 165, 34)", fontSize: "10px", textAlign: "left", lineHeight: "16px", marginTop: "5px" }}>
              {i18n.t("Dashboard.Configuration.Fields.FitColsDanger")}
            </div>
          </Col>
        </Row>
        {!this.state.isColSizeMustConstant ? <>
          <Row style={{ marginBottom: "5px" }}>
            <Col span={2}></Col>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={7}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ColumnWidth")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"columnWidth"}
                defaultValue={this.state.columnWidth}
                value={this.state.columnWidth}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col span={2}></Col>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={7}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ColumnHeight")}
              </Text>
            </Col>
            <Col span={12}>
              <Input
                id={"columnHeight"}
                defaultValue={this.state.columnHeight}
                value={this.state.columnHeight}
                onChange={this.handleChange}
              />
            </Col>
          </Row></> : null}
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.RowColor")}
            </Text>
          </Col>
          <Col span={10}>
            <Button
              id={"rowColorBackgroundOnChange"}
              onClick={this.rowColorBackgroundColorOnChange}
              style={{ width: "100%" }}
            >
              {this.state.rowColorBackgroundOnChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.rowColorBackgroundOnChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.rowColor}
                onChange={this.rowColorOnChange}
                id={"rowColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
          <Col span={2}>
            {/* selected Color */}
            <div
              onClick={this.rowColorBackgroundColorOnChange}
              style={{
                marginLeft: "5px",
                height: "32px",
                border: "1px solid #d7d6d6",
                boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor: this.state.rowColor,
              }}
            ></div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ColColor")}
            </Text>
          </Col>
          <Col span={10}>
            <Button
              id={"colColorBackgroundOnChange"}
              onClick={this.colColorBackgroundColorOnChange}
              style={{ width: "100%" }}
            >
              {this.state.colColorBackgroundOnChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.colColorBackgroundOnChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.colColor}
                onChange={this.colColorOnChange}
                id={"colColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
          <Col span={2}>
            {/* selected Color */}
            <div
              onClick={this.colColorBackgroundColorOnChange}
              style={{
                marginLeft: "5px",
                height: "32px",
                border: "1px solid #d7d6d6",
                boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor: this.state.colColor,
              }}
            ></div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col
            style={{ textAlign: "center" }}
            span={this.props.titleIcon ? this.props.titleIcon : 3}
          >
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col
            style={{ textAlign: "left" }}
            span={this.props.titleColumn ? this.props.titleColumn : 9}
          >
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.ConditionalFormatting.HeaderFontStyle")}
            </Text>
          </Col>
          <Col style={{ textAlign: "left" }} span={12}>
            <span>
              <Button
                onClick={this.handleCheckFontStyle}
                style={{
                  backgroundColor: this.state.headerFontWeight ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
                id={"headerFontWeight"}
              >
                <BoldOutlined
                  style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                />
              </Button>
            </span>

            <span>
              <Button
                onClick={this.handleCheckFontStyle}
                style={{
                  marginLeft: "20px",
                  backgroundColor: this.state.headerFontStyle ? "#9ed3ff" : "initial",
                  color: "black",
                  borderColor: "lightgrey",
                }}
                id={"headerFontStyle"}
              >
                <ItalicOutlined
                  style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                />
              </Button>
            </span>
          </Col>
        </Row>
        <Row style={{ marginBottom: "9px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.DifferentDrillColours")}
            </Text>
          </Col>
          <Col span={1}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"defaultDrilldownColor"}
              defaultChecked={this.state.defaultDrilldownColor}
              checked={this.state.defaultDrilldownColor}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
          <Col span={11}>
            <div style={{ color: "rgb(255, 165, 34)", fontSize: "10px", textAlign: "left", lineHeight: "16px", marginTop: "5px" }}>
              {i18n.t("Dashboard.Configuration.Fields.DifferentDrillDanger")}
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.DrilldownColumnHeaderColor")}
            </Text>
          </Col>
          <Col span={10}>
            <Button
              id={"drillColorBackgroundOnChange"}
              onClick={this.drillColorBackgroundColorOnChange}
              style={{ width: "100%" }}
            >
              {this.state.drillColorBackgroundOnChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.drillColorBackgroundOnChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.settedDrilldownColor}
                onChange={this.drillColorOnChange}
                id={"settedDrilldownColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
          <Col span={2}>
            {/* selected Color */}
            <div
              onClick={this.drillColorBackgroundColorOnChange}
              style={{
                marginLeft: "5px",
                height: "32px",
                border: "1px solid #d7d6d6",
                boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor: this.state.settedDrilldownColor,
              }}
            ></div>
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.NullValue")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"nullValue"}
              defaultValue={this.state.nullValue}
              value={this.state.nullValue}
              onChange={this.handleChangeNullValue}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AutoSize")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"autoSize"}
              defaultChecked={this.state.autoSize}
              checked={this.state.autoSize}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.AllowControls")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"controls"}
              defaultChecked={this.state.controls}
              checked={this.state.controls}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.SubTotal")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"subTotal"}
              defaultChecked={this.state.subTotal}
              checked={this.state.subTotal}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.WrapHeader")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"wrapHeader"}
              defaultChecked={this.state.wrapHeader}
              checked={this.state.wrapHeader}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"condFormat"}
              defaultChecked={this.state.condFormat}
              checked={this.state.condFormat}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <TitleSettingsOfPlugin
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          keyForTitleSize={this.state.keyForTitleSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          changedTitleFontSize={this.state.changedTitleFontSize}
          handleChangeBothOfValues={this.handleChangeBothOfValues}
        />
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.SelectSum")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              id={"selectSum"}
              style={{ width: "100%" }}
              placeholder={i18n.t("Dashboard.Configuration.Fields.SelectSum")}
              onChange={this.sumSelectorOnChange}
              getPopupContainer={trigger => trigger.parentNode}
              dropdownMenuStyle={{ textAlign: "left" }}
              value={this.state.sumSelector === "Both" ? "ShowBoth" : this.state.sumSelector}
            >
              <Option name={"showBoth"} value="ShowBoth">{i18n.t("Dashboard.Configuration.Fields.ShowBoth")}</Option>
              <Option name={"justRow"} value="JustRow">{i18n.t("Dashboard.Configuration.Fields.JustRow")}</Option>
              <Option name={"justColumn"} value="JustColumn">{i18n.t("Dashboard.Configuration.Fields.JustColumn")}</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowCornerTotal")}
            </Text>
          </Col>
          <Col span={12}>
            {this.state.sumSelector !== "ShowBoth" && this.state.sumSelector !== "Both" ? (
              <Tooltip
                title={i18n.t("Dashboard.Configuration.Fields.ShowCornerCheckboxInfo")} >
                <Checkbox
                  style={{ float: "left", marginTop: "9px" }}
                  id={"showCornerTotal"}
                  defaultChecked={false}
                  disabled={true} />
              </Tooltip>
            ) : (
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"showCornerTotal"}
                defaultChecked={this.state.showCornerTotal}
                checked={this.state.showCornerTotal}
                onChange={(e) => this.showCornerTotalOnchange(e.target.checked)} />
            )}
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Renderer")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              id={"pivotTableRanderer"}
              style={{ width: "100%" }}
              placeholder={i18n.t("Dashboard.Configuration.Fields.Renderer")}
              onChange={this.rendererOnChange}
              defaultValue={this.state.renderer}
              getPopupContainer={trigger => trigger.parentNode}
              dropdownMenuStyle={{ textAlign: "left" }}
            >
              <Option name={"table"} value="Table">{i18n.t("Dashboard.Configuration.Fields.Table")}</Option>
              <Option name={"table Barchart"} value="Table Barchart">{i18n.t("Dashboard.Configuration.Fields.TableBarchart")}</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ThemeColour")}
            </Text>
          </Col>
          <Col span={10}>
            <Button
              id={"pivotThemeColour"}
              onClick={this.themeColourChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.themeColourChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.themeColourChange ? (
              <ColorPickerPanel
                alpha={100}
                themeColour={this.state.themeColour}
                onChange={this.themeColourOnChange}
                id={"themeColour"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
          <Col span={2}>
            {/* selected Color */}
            <div
              onClick={this.themeColourChangeVisible}
              style={{
                marginLeft: "5px",
                height: "32px",
                border: "1px solid #d7d6d6",
                boxShadow: "0 2px 0 rgba(0, 0, 0,}",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor: this.state.themeColour,
              }}
            ></div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.PivotTableDescription")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"pivotTableDescription"}
              defaultValue={this.state.pivotTableDescription}
              value={this.state.pivotTableDescription}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip
              title={
                <>
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                  {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}