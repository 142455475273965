import $ from "jquery";
import _ from "lodash";
import React, { Component } from "react";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { getPluginsAllColumnsByField } from "../../../../Utils/PluginOperations";
import i18n from "../../../../Utils/i18next";
import { calculatePluginInlineHeight, calculateTitleSize } from "../../../../ui/DrillDown/PluginHeightWithDrilldown";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { convertHTMLRuletoRule } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import NavigationContent from "../../../Navigation/NavigationContent";
import PluginError from "../../PluginError";
import { shadeColor } from "../ButtonFilter/getButtonComponent";
import {
  renderConditionalFormatting,
  renderConfig,
  renderData,
  renderNavigation
} from "../PluginsCommonComponents";
import {
  getColumnMapping,
  onComponentWillMount,
  onComponentWillReceiveProps,
} from "../common";
import { getFormattedValue } from "../format";
import MeasureFooter from "./MeasureItems/MeasureFooter";
import MeasureTileConfiguration from "./MeasureTileConfiguration";
import MeasureTileData from "./MeasureTileData";
import MeasureTileMainContainer from "./MeasureTileMainContainer";
import { store } from "../../../..";
import uuid from "react-uuid";
import { deepCopy } from "../../../../Utils/Global";
import { showNotificationWithIcon } from "../../../../Utils/Notification";
import { changePluginLoaderVisibility } from "../../../GeneralComponents/PluginLoader/PluginLoaderAction";

const data = JSON.parse('[{"measure":12378822380,"hidden":[]}]');
const columnMap = JSON.parse(
  `{"measure":{"Code":"\'ucusAlias\'.\'bagajtoplamAlias\'","Name":"bagajtoplam","DataType":"double","Table":"ucusAlias","Measure":"sum(ucus.bagajtoplam)","ID":"ucusAlias.bagajtoplam","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"asc","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""},"hidden":[]}`
);
const condFormats = [];
const pluginName = "measure-tile";
const description =
  "Measure tile displaying aggregated numerical figures. Has built in conditional formatting (i.e. not using the generic framework yet) between configured values which display up/down arrows and fade to the configured colours. Animates the number count from 0 to the value.";
const icon = "hashtag";

const configurationParameters = [
  {
    targetProperty: "isPluginNewPlugin",
    label: "isPluginNewPlugin",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "headerTextSize",
    label: "headerTextSize",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "16"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "headerFont",
    label: "headerFont",
    inputType: "font",
    inputOptions: { defaultValue: "Verdana" },
    desc: "desc147"
  },
  {
    targetProperty: "headerFontDescription",
    label: "headerFont",
    inputType: "input",
    inputOptions: { defaultValue: "" },
    desc: "desc147"
  },
  {
    targetProperty: "secondaryColour",
    label: "SecondaryColour",
    inputType: "SecondaryColour",
    inputOptions: {
      defaultValue: "#2127ad50"
    },
    desc: "desc146"
  },
  {
    targetProperty: "iconSize",
    label: "IconSize",
    inputType: "IconSize",
    inputOptions: {
      defaultValue: "27"
    },
    desc: "desc146"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "size",
    label: "TextSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 8,
      max: 72,
      defaultValue: null
    },
    desc: "desc148"
  },
  {
    targetProperty: "responsive",
    label: "Responsive",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc148"
  },
  {
    targetProperty: "numFont",
    label: "NumFont",
    inputType: "font",
    inputOptions: { defaultValue: "Consolas" },
    desc: "desc147"
  },
  {
    targetProperty: "colour",
    label: "Colour",
    inputType: "colour",
    inputOptions: {
      defaultValue: "#2127ad"
    },
    desc: "desc146"
  },
  {
    targetProperty: "icon",
    label: "Icon",
    inputType: "icon",
    inputOptions: { defaultValue: "" },
    desc: "desc145"
  },
  {
    targetProperty: "animation",
    label: "AnimationTime",
    inputType: "textbox",
    inputOptions: { subtype: "number", defaultValue: 0 },
    desc: "desc144"
  },
  {
    targetProperty: "many",
    label: "Many",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc143"
  },
  {
    targetProperty: "hideLabel",
    label: "HideLabel",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc142"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "condFormat",
    label: "CondFormat",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc162"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "duration",
    label: "Duration",
    inputType: "textbox",
    inputOptions: { defaultValue: "1" },
    desc: "desc94"
  },
  {
    targetProperty: "titleAlign",
    label: "Title Align",
    inputType: "dropdown",
    inputOptions: {
      defaultValue: "center",
      multiSelect: false,
      values: ["center", "left", "right"]
    },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgba(255,255,255, 1)" },
    desc: "desc62"
  },
  {
    targetProperty: "isImage",
    label: "IsImage",
    inputType: "checkbox",
    inputOptions: { defaultValue: false },
    desc: "desc141"
  },
  {
    targetProperty: "imgSrc",
    label: "ImgSrc",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc140"
  },
  {
    targetProperty: "imgWidth", // Name of the property in config object of render function
    label: "ImgWidth", // Name the user will see
    inputType: "textbox", // Indicates the UI element for setting this parameter
    inputOptions: {
      // Sub-parameters for the UI element
      subtype: "number",
      defaultValue: 50
    },
    desc: "desc139" // Description displayed to the user
  },
  {
    targetProperty: "imgHeight", // Name of the property in config object of render function
    label: "ImgHeight", // Name the user will see
    inputType: "textbox", // Indicates the UI element for setting this parameter
    inputOptions: {
      // Sub-parameters for the UI element
      subtype: "number",
      defaultValue: 50
    },
    desc: "desc138" // Description displayed to the user
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
];

const actions = [
  {
    trigger: "click",
    type: "click",
    name: "Tıklama",
    output: ["measure"],
    description: ""
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const pluginConditionalFormatOptions = {
  color: {
    title: i18n.t("Dashboard.ConditionalFormatting.Color"),
    type: "COLOR",
    defaultValue: "#000001"
  },
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe",
  },
  icon: {
    title: i18n.t("Dashboard.ConditionalFormatting.Icon"),
    type: "ICON",
    defaultValue: "",
  },
};

const conditionalFormatColumnMap = new Set(["measure", "defaultFilter", "hidden"]);
const conditionalFormatTargetMap = new Set(["measure"])

export default class MeasureTile extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      defaultConditionalFormatColumn: {},
      isLockedTargetValue: true,
      pluginData: [],
      compareType: "single-column",
      calculatedPluginHeight: 0,
      configArray: this.props.plugin.config ? this.props.plugin.config.configArray == undefined ? [] : this.props.plugin.config.configArray : [],
      compareLength: [0],
      paginationIndex: 0,
      pluginColumnMap: [],
      pluginErrorHashMap: new Map(),
      defaultFilterColumn: {},
      isRenderStatusStarted: false
    })

    this.rerenderProcessStarted = false;
    this.callBackObject = {};
  }

  /*
  * Adds measure tile errors
  */
  measureTileErrorStatus = (erroredQueryObj, error, isEmptyColumn = false) => {
    let copiedPluginError = new Map(this.state.pluginErrorHashMap)
    let isErrorColumnNotFound = false

    if (!isEmptyColumn) {
      if (erroredQueryObj.selectColumns[0]) {
        copiedPluginError.set(erroredQueryObj.selectColumns[0].uniqeColumnId, {
          message: error,
          type: "error"
        })
      }
    } else {
      isErrorColumnNotFound = true
    }

    if (isErrorColumnNotFound) {
      if (this.props.plugin.columnMap.measure.data.length === 0) {
        let dumpData = [
          {
            "category": "Kargo Toplam",
            "categoryDisplayName": "Kargo Toplam",
            "categoryValue": "Kargo Toplam",
            "measureValue": "5610072926",
            "measurePureValue": "5610072926",
            "isDefaultData": true
          },
        ]

        this.setState({
          ...this.state,
          pluginData: dumpData,
          compareLength: [dumpData[0].category],
          compareType: "single-column",
          defaultFilterColumn: {},
          paginationIndex: 0
        })
      } else if (this.props.plugin.columnMap.measure.data.length === this.props.plugin.columnMap.measure.data.filter(col => col.isDisabledColumn).length) {
        copiedPluginError.set("ColumnMapError", {
          message: error,
          type: "error"
        })

        let reduxState = store.getState()

        if (reduxState.PluginLoaderReducer.waitForLoadPlugins.has(this.props.plugin.id)) {
          reduxState.PluginLoaderReducer.waitForLoadPlugins.delete(this.props.plugin.id)
        }

        setTimeout(() => showNotificationWithIcon(i18n.t("ColumnMapValidError", { pluginName: i18n.t("Plugins." + "measure-tile" + ".Name") }), "", "error"), 1)

        this.setState({
          ...this.state,
          pluginErrorHashMap: copiedPluginError,
        })
      }
    } else {
      this.setState({
        ...this.state,
        pluginErrorHashMap: copiedPluginError,
      })
    }
  }

  // Sets dump data to plugin on first render
  componentDidMount() {
    let configArr = []
    let config = { ...this.props.plugin.config }
    let dumpData = [
      {
        "category": "Kargo Toplam",
        "categoryDisplayName": "Kargo Toplam",
        "categoryValue": "Kargo Toplam",
        "measureValue": "5610072926",
        "measurePureValue": "5610072926",
        "isDefaultData": true
      },
    ]

    if (this.props.plugin?.config?.configArray && this.props.plugin?.config?.configArray?.length === 0) {
      for (let i = 0; i < dumpData.length; i++) {
        configArr[i] = this.returnConfigObj(this.props.plugin, i)
      }
  
      config["configArray"] = configArr
    } else {
      configArr = this.props.plugin?.config?.configArray ? this.props.plugin?.config?.configArray : [this.returnConfigObj()]
    }

    this.setState({
      ...this.state,
      pluginData: dumpData,
      compareLength: [dumpData[0].category],
      compareType: "single-column",
      configArray: configArr,
      defaultFilterColumn: {},
      paginationIndex: 0
    }, () => this.props.updateConfig(config))
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      this.measureTileComparedStatus,
      this.measureTileErrorStatus,
      this.resetMeasureData,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  // Triggers navigation with default filter selection
  triggerNavigation = (e, compareValue, pluginData) => {
    let divId = this.props.plugin.id
    let container = $("#" + divId)[0];
    let mousePosition = { x: e.pageX, y: e.pageY }
    let plugin = _.cloneDeep(this.props.plugin)
    let gettedColumn = this.state.pluginData.find(data => data.id === pluginData.id)

    if (this.state.compareType === "default-filter") {
      let clickedDatasColumn = pluginData.column

      plugin["compareValue"] = compareValue
      plugin["columnMap"]["measure"]["data"] = plugin.columnMap.measure.data.filter(column => column.uniqeColumnId === clickedDatasColumn.uniqeColumnId)
    } else if (this.state.compareType === "multiple-column") {
      let clickedDatasColumn = pluginData.column
      
      plugin["columnMap"]["measure"]["data"] = plugin.columnMap.measure.data.filter(column => column.uniqeColumnId === clickedDatasColumn.uniqeColumnId)
    }

    if (!gettedColumn.isMeasureNoData) {
      createTrigger(
        actions,
        columnMap,
        container,
        'click',
        data, // datum
        this.props.plugin.id,
        this.props.interactions,
        this.props.navigations,
        mousePosition,
        null, // filterOperator
        null, // drillDowns
        null, // clickedColumn
        plugin,
        this.props.model,
        [] // datumForNavigation
      );
    } else {
      showNotificationWithIcon(i18n.t("Error"), i18n.t("Dashboard.Configuration.Fields.NavigationNoDataFound"), "warn")
    }
  }

  // Returns dump config object
  returnConfigObj = () => {
    let configObj = {
      isImage: false,
      imgSrc: "",
      imageName: "",
      imagePath: "",
      imageUpload: "",
      colour: "rgb(98, 98, 187)",
      secondaryColour: shadeColor("#6262bb", 0, 70),
      iconSize: 27,
      measureHeader: "",
      measureHeaderAlign: "left",
      headerFontWeight: true,
      headerFontStyle: false,
      headerTextDecor: false,
      headerFont: "Verdana",
      headerTextSize: 16,
      headerColor: "#e50606",
      headerFontDescription: "",
      headerFontDescriptionPosition: "bottom",
      headerDescriptionFontWeight: false,
      headerDescriptionFontStyle: false,
      headerDescriptionTextDecor: false,
      headerDescriptionFont: "Verdana",
      headerDescriptionTextSize: 11,
      measurePosition: "left",
      measureFontWeight: true,
      measureFontStyle: false,
      measureTextDecor: false,
      measureFont: "Verdana",
      measureTextSize: 22,
      measureComparePosition: "left",
      measureCompareFontWeight: false,
      measureCompareFontStyle: false,
      measureCompareTextDecor: false,
      measureCompareFont: "Verdana",
      measureCompareTextSize: 11,
      noDataTitle: ""
    }

    return configObj
  }

  // Gets data by default filter
  getDataByQueryForDefaultFilter = (data, queryObj, pluginColMap, copiedErrorKeys) => {
    let category = queryObj.filterColumns.find(column => column.compared && column.filterArea === "DefaultFilters")
    let index = category.compareIndex
    let copiedData = [...this.state.pluginData]
    let config = this.props.plugin.config
    let configArr = this.state.configArray == undefined ? [] : this.state.configArray
    let copiedConfig = [...configArr]
    let compareArray = [...this.state.compareLength]
    let pluginColumnMap = [...this.state.pluginColumnMap]
    let copiedErrorHash = new Map(copiedErrorKeys)
    let isMeasureNoData = (data.length > 1 || typeof data[0]?.measure === "object"
      ? typeof data[0]?.measure === "string" || typeof data[0]?.measure === "number" || data[0]?.measure[0]?.value
      : data[0]?.measure)
      ? false
      : true
    let reduxState = store.getState()
    let noDataContent = copiedConfig[index]
      ? copiedConfig[index].noDataTitle && copiedConfig[index].noDataTitle !== ""
        ? copiedConfig[index].noDataTitle
        : reduxState.SettingNoDataTitleReducer.data && reduxState.SettingNoDataTitleReducer.data !== ""
          ? reduxState.SettingNoDataTitleReducer.data
          : i18n.t("NoDataContent.NoData")
      : i18n.t("NoDataContent.NoData")
    let allColumns = this.props.plugin?.columnMap?.measure?.data.filter(column => column.isDisabledColumn)

    for (let i = 0; i < allColumns.length; i++) {
      let disabledColumns = allColumns[i]

      if (copiedErrorHash.has(disabledColumns.uniqeColumnId)) {
        copiedErrorHash.delete(disabledColumns.uniqeColumnId)
  
        this.props.plugin.errors && this.props.plugin.errors.delete(disabledColumns.uniqeColumnId)
      }
    }

    if (copiedErrorHash.has(queryObj.selectColumns[0].uniqeColumnId)) {
      copiedErrorHash.delete(queryObj.selectColumns[0].uniqeColumnId)

      this.props.plugin.errors && this.props.plugin.errors.delete(queryObj.selectColumns[0].uniqeColumnId)
    }

    pluginColumnMap[0] = pluginColMap.measure[0]

    let dataObj = {
      id: uuid(),
      column: typeof data[0]?.measure === "object" ? pluginColumnMap[0] : this.props.plugin.columnMap.measure.data[0],
      defaultFilterCategory: category.aliasName,
      defaultFilterCategoryDisplayName: category.displayName,
      category: pluginColMap.measure[0].aliasName,
      categoryDisplayName: pluginColMap.measure[0].displayName,
      categoryValue: category.lastValue[0],
      measurePureValue: typeof data[0]?.measure === "object" ? data[0]?.measure[0].value : data[0]?.measure,
      measureValue: typeof data[0]?.measure === "object" ? getFormattedValue(pluginColumnMap[0], data[0]?.measure[0]?.value) : getFormattedValue(this.props.plugin.columnMap.measure.data[0], data[0]?.measure),
      isMeasureNoData: isMeasureNoData,
      noDataTitle: noDataContent,
      isDefaultData: false
    }

    if (data[0]?.hidden?.length > 0) {
      for (let i = 0; i < data[0].hidden.length; i++) {
        dataObj[data[0]?.hidden[i].name] = data[0]?.hidden[i].value
      }
    }

    dataObj[category.displayName] = category.lastValue[0]
    dataObj["measure"] = typeof data[0]?.measure === "object" ? data[0]?.measure[0]?.value : data[0]?.measure
    dataObj[queryObj.selectColumns[0].displayName] = typeof data[0]?.measure === "object" ? data[0]?.measure[0]?.value : data[0]?.measure
    dataObj[queryObj.selectColumns[0].aliasName] = typeof data[0]?.measure === "object" ? data[0]?.measure[0]?.value : data[0]?.measure

    copiedData.length = category.compareLength

    copiedData[index] = dataObj
    compareArray[index] = dataObj.categoryValue
    copiedConfig.length = category.compareLength
    compareArray.length = category.compareLength

    if (copiedConfig[index] == undefined || copiedConfig[index] == null) {
      copiedConfig[index] = this.returnConfigObj()

      config["configArray"] = copiedConfig
    }

    let isRenderStatusStarted = compareArray.length !== copiedData.filter(data => data?.isDefaultData !== true && data).length ? true : false

    if (isRenderStatusStarted && !reduxState.PluginLoaderReducer.waitForLoadPlugins.has(this.props.plugin.id)) {
      changePluginLoaderVisibility(this.props.plugin.id, true)
    } else if (!isRenderStatusStarted && reduxState.PluginLoaderReducer.waitForLoadPlugins.has(this.props.plugin.id)) {
      changePluginLoaderVisibility(this.props.plugin.id, false)
    }

    this.setState({
      ...this.state,
      pluginData: copiedData,
      compareLength: compareArray,
      compareType: "default-filter",
      configArray: copiedConfig,
      pluginColumnMap: pluginColumnMap,
      pluginErrorHashMap: copiedErrorHash,
      defaultFilterColumn: queryObj.filterColumns.find(column => column.compared),
      paginationIndex: 0,
      isRenderStatusStarted: isRenderStatusStarted
    }, () => this.props.updateConfig(config))
  }

  // Gets data by multiple column
  getDataByQueryForMultipleColumn = (data, queryObj, pluginColMap, copiedErrorKeys) => {
    let copiedData = [...this.state.pluginData]

    let gettedData = queryObj.selectColumns.filter(column => column.locationFieldName !== "hidden")[0]
    let index = gettedData.compareIndex
    let compareLength = gettedData.compareLength
    let config = this.props.plugin.config
    let configArr = this.state.configArray == undefined ? [] : this.state.configArray
    let copiedConfig = [...configArr]
    let compareArray = [...this.state.compareLength]
    let pluginColumnMap = [...this.state.pluginColumnMap]
    let copiedErrorHash = new Map(copiedErrorKeys)
    let isMeasureNoData = data.length > 1 || (data[0] && data[0][gettedData.displayName]) ? false : true
    let reduxState = store.getState()
    let noDataContent = copiedConfig[index]
      ? copiedConfig[index].noDataTitle && copiedConfig[index].noDataTitle !== ""
        ? copiedConfig[index].noDataTitle
        : reduxState.SettingNoDataTitleReducer.data && reduxState.SettingNoDataTitleReducer.data !== ""
          ? reduxState.SettingNoDataTitleReducer.data
          : i18n.t("NoDataContent.NoData")
      : i18n.t("NoDataContent.NoData")
    let allColumns = this.props.plugin?.columnMap?.measure?.data.filter(column => column.isDisabledColumn)

    for (let i = 0; i < allColumns.length; i++) {
      let disabledColumns = allColumns[i]

      if (copiedErrorHash.has(disabledColumns.uniqeColumnId)) {
        copiedErrorHash.delete(disabledColumns.uniqeColumnId)
  
        this.props.plugin.errors && this.props.plugin.errors.delete(disabledColumns.uniqeColumnId)
      }
    }

    if (copiedErrorHash.has(queryObj.selectColumns[0].uniqeColumnId)) {
      copiedErrorHash.delete(queryObj.selectColumns[0].uniqeColumnId)

      this.props.plugin.errors && this.props.plugin.errors.delete(queryObj.selectColumns[0].uniqeColumnId)
    }

    pluginColumnMap[index] = pluginColMap.measure[0]

    let totalData = 0

    if (data.length > 0) {
      totalData = this.getTotalValueForMultiple(data, gettedData.displayName)
    }

    let dataObj = {}

    if (!copiedData[index]) {
      dataObj = {
        id: uuid(),
        column: pluginColumnMap[index],
        category: gettedData.aliasName,
        categoryDisplayName: gettedData.displayName,
        categoryValue: gettedData.displayName,
        measureValue: getFormattedValue(pluginColumnMap[index], totalData),
        measurePureValue: totalData,
        isMeasureNoData: isMeasureNoData,
        noDataTitle: noDataContent,
        isDefaultData: false
      }
    } else {
      dataObj = {
        ...copiedData[index],
        id: uuid(),
        column: pluginColumnMap[index],
        category: gettedData.aliasName,
        categoryDisplayName: gettedData.displayName,
        categoryValue: gettedData.displayName,
        measureValue: getFormattedValue(pluginColumnMap[index], totalData),
        measurePureValue: totalData,
        isMeasureNoData: isMeasureNoData,
        noDataTitle: noDataContent,
        isDefaultData: false
      }
    }

    if (data[0]?.hidden?.length > 0) {
      for (let i = 0; i < data[0].hidden.length; i++) {
        dataObj[data[0].hidden[i].name] = data[0].hidden[i].value
      }
    }

    dataObj[gettedData.displayName] = totalData
    dataObj["measure"] = totalData

    copiedData.length = compareLength

    copiedData[index] = dataObj
    compareArray[index] = dataObj.categoryValue
    configArr.length = compareLength
    copiedConfig.length = gettedData.compareLength
    compareArray.length = gettedData.compareLength

    for (let i = 0; i < copiedData.length; i++) {
      if (copiedData[i]) {
        copiedData[i][gettedData.displayName] = totalData
      } else {
        copiedData[i] = undefined

        // copiedData[i][gettedData.displayName] = totalData
      }
    }

    if (!copiedConfig[index] && copiedConfig[index] == undefined && copiedConfig[index] == null) {
      copiedConfig[index] = this.returnConfigObj()

      config["configArray"] = copiedConfig
    }

    let isRenderStatusStarted = compareArray.length !== copiedData.filter(data => data?.isDefaultData !== true && data).length ? true : false

    if (isRenderStatusStarted && !reduxState.PluginLoaderReducer.waitForLoadPlugins.has(this.props.plugin.id)) {
      changePluginLoaderVisibility(this.props.plugin.id, true)
    } else if (!isRenderStatusStarted && reduxState.PluginLoaderReducer.waitForLoadPlugins.has(this.props.plugin.id)) {
      changePluginLoaderVisibility(this.props.plugin.id, false)
    }

    this.setState({
      ...this.state,
      pluginData: copiedData,
      compareType: "multiple-column",
      compareLength: compareArray,
      configArray: copiedConfig,
      pluginColumnMap: pluginColumnMap,
      pluginErrorHashMap: copiedErrorHash,
      defaultFilterColumn: {},
      paginationIndex: 0,
      isRenderStatusStarted: isRenderStatusStarted
    }, () => this.props.updateConfig(config))
  }

  /*
  * Gets data single column
  */
  getDataByQueryForSingleColumn = (data, queryObj, columnMapForPlugin, controlledProps, copiedErrorKeys, pluginColumns) => {
    let config = { ...controlledProps.props.plugin.config }
    let copiedErrorHash = new Map(copiedErrorKeys)
    let isPluginNewPlugin = this.props.plugin.config.isPluginNewPlugin == undefined || !this.props.plugin.config.isPluginNewPlugin ? false : true
    let reduxState = store.getState()

    if (config["configArray"] && config["configArray"].length > 0) {
      config["configArray"] = [config.configArray[0]]
    } else {
      config["configArray"] = [this.returnConfigObj()]
    }

    if (config.isImage) {
      config["configArray"][0]["isImage"] = true
      config["configArray"][0]["image"] = config.image
      config["configArray"][0]["imageName"] = config.imageName
      config["configArray"][0]["imagePath"] = config.imagePath
      config["configArray"][0]["imgSrc"] = config.imgSrc && config.imgSrc !== "" ? config.imgSrc : ""
    }

    let dataObj = []

    if (data.length === 0 || !+data[0].measure) {
      let isMeasureNoData = data.length === 0
        ? true
        : (data.length > 1 || ((typeof data[0]?.measure === "string" && data[0].measure !== "") || typeof data[0]?.measure === "number" || data[0]?.measure[0]?.value) && (data.length > 0 || data[0]?.measure !== ""))
          ? false
          : true
      let reduxState = store.getState()
      let noDataContent = config
        ? config.noDataTitle && config.noDataTitle !== ""
          ? config.noDataTitle
          : reduxState.SettingNoDataTitleReducer.data && reduxState.SettingNoDataTitleReducer.data !== ""
            ? reduxState.SettingNoDataTitleReducer.data
            : i18n.t("NoDataContent.NoData")
        : i18n.t("NoDataContent.NoData")

      let measure = Array.isArray(columnMapForPlugin.measure) ? columnMapForPlugin.measure.filter(column => !column.isDisabledColumn)[0] : columnMapForPlugin.measure
      let measureControlledValue = typeof data[0]?.measure === "object" ? data[0]?.measure[0]?.value : data[0]?.measure

      dataObj.push({
        id: uuid(),
        column: [measure],
        category: queryObj.selectColumns[0].aliasName,
        categoryDisplayName: queryObj.selectColumns[0].displayName,
        categoryValue: isMeasureNoData ? controlledProps.props.plugin.columnMap.measure.data[0].displayName : data[0].measure[0]?.name ? data[0].measure[0]?.name : queryObj.selectColumns[0]?.displayName,
        measureValue: (isMeasureNoData || data.length > 1) && isPluginNewPlugin
          ? ""
          : data.length === 1
            ? getFormattedValue(measure, measureControlledValue)
            : getFormattedValue(measure, this.getTotalValueForMultiple(data, measure.displayName)),
        measurePureValue: (isMeasureNoData || data.length > 1) && isPluginNewPlugin
          ? ""
          : data.length === 1
            ? measureControlledValue
            : this.getTotalValueForMultiple(data, measure.displayName),
        measure: isMeasureNoData && isPluginNewPlugin ? "" : data[0]?.measure[0]?.value ? data[0]?.measure[0]?.value : data[0]?.measure,
        isMeasureNoData: isMeasureNoData,
        noDataTitle: noDataContent,
        isDefaultData: false
      })

      if (data.length > 0) {
        dataObj[0][data[0].measure[0]?.name] = isMeasureNoData ? "" : data[0].measure[0]?.value
        dataObj[0]["measure"] = isMeasureNoData ? "" : data[0].measure[0]?.value
      }
    } else {
      let totalValue = this.getTotalValue(data)
      let isMeasureNoData = data.length > 1 || totalValue ? false : true
      let reduxState = store.getState()
      let noDataContent = config
        ? config.noDataTitle && config.noDataTitle !== ""
          ? config.noDataTitle
          : reduxState.SettingNoDataTitleReducer.data && reduxState.SettingNoDataTitleReducer.data !== ""
            ? reduxState.SettingNoDataTitleReducer.data
            : i18n.t("NoDataContent.NoData")
        : i18n.t("NoDataContent.NoData")

      let measure = typeof columnMapForPlugin.measure === "object" ? columnMapForPlugin.measure : columnMapForPlugin.measure[0]

      dataObj.push({
        id: uuid(),
        column: [measure],
        categoryId: queryObj.selectColumns[0].aliasName.uniqeColumnId,
        category: queryObj.selectColumns[0].aliasName,
        categoryDisplayName: queryObj.selectColumns[0].displayName,
        categoryValue: controlledProps.props.plugin.columnMap.measure.data[0].displayName,
        measureValue: getFormattedValue(measure, totalValue),
        measurePureValue: totalValue,
        measure: totalValue,
        isMeasureNoData: isMeasureNoData,
        noDataTitle: noDataContent,
        isDefaultData: false
      })

      dataObj[0][controlledProps.props.plugin.columnMap.measure.data[0].displayName] = totalValue
      dataObj[0]["measure"] = totalValue
    }

    if (data.length > 0 && data[0].hidden?.length > 0) {
      for (let i = 0; i < data[0].hidden.length; i++) {
        dataObj[0][data[0].hidden[i].name] = data[0].hidden[i].value
      }
    }

    if (data.length > 0) {
      dataObj[0]["measureValue"] = getFormattedValue(dataObj[0].column[0], dataObj[0]["measurePureValue"])
    }

    for (let i = 0; i < pluginColumns.length; i++) {
      if (copiedErrorHash.has(pluginColumns[i].uniqeColumnId)) {
        copiedErrorHash.delete(pluginColumns[i].uniqeColumnId)

        this.props.plugin.errors && this.props.plugin.errors.delete(pluginColumns[i].uniqeColumnId)
      }
    }

    if (reduxState.PluginLoaderReducer.waitForLoadPlugins.has(this.props.plugin.id)) {
      changePluginLoaderVisibility(this.props.plugin.id, false)
    }

    this.setState({
      ...this.state,
      pluginData: dataObj,
      compareType: "single-column",
      compareLength: [dataObj[0].categoryDisplayName],
      configArray: config.configArray,
      pluginColumnMap: [columnMapForPlugin.measure[0]],
      pluginErrorHashMap: copiedErrorHash,
      defaultFilterColumn: {},
      paginationIndex: 0,
      isRenderStatusStarted: false
    }, () => this.props.updateConfig(config))
  }

  getTotalValueForMultiple = (data, displayName) => {
    let total = 0

    if (data.length > 1) {
      for (let i = 0; i < data.length; i++) {
        if (data[i][displayName]) {
          total += +data[i][displayName]
        }
      }

      if (isNaN(total)) {
        return " "
      }

      return total
    }

    return data[0] ? data[0][displayName] : ""
  }

  // Gets total value for hidden areas
  getTotalValue = (data) => {
    let total = 0

    for (let i = 0; i < data.length; i++) {
      let perData = data[i]

      total += perData.measure
    }

    return total
  }

  // Checks measure tile compared status
  measureTileComparedStatus = (data, queryObj, isCompared = false, controlledProps, columnMapForPlugin) => {
    let copiedErrorKeys = new Map(this.state.pluginErrorHashMap)
    let errorKeys = Array.from(this.state.pluginErrorHashMap.keys())
    let pluginColumns = getPluginsAllColumnsByField(this.props.plugin.columnMap)

    for (let i = 0; i < errorKeys.length; i++) {
      let errorKey = errorKeys[i]

      if (pluginColumns.filter(column => column.uniqeColumnId === errorKey).length === 0) {
        copiedErrorKeys.delete(errorKey)
      }
    }

    if (isCompared) {
      if (controlledProps.comparedType === "default-filter") {
        this.getDataByQueryForDefaultFilter(data, queryObj, columnMapForPlugin, copiedErrorKeys)
      } else if (controlledProps.comparedType === "multiple-column") {
        this.getDataByQueryForMultipleColumn(data, queryObj, columnMapForPlugin, copiedErrorKeys)
      }
    } else {
      this.setState({
        ...this.state,
        pluginData: [],
        pluginColumnMap: [],
        compareLength: 0,
        paginationIndex: 0
      }, () => this.getDataByQueryForSingleColumn(data, queryObj, columnMapForPlugin, controlledProps, copiedErrorKeys, pluginColumns))
    }
  }

  // Changes pagination index
  increaseDecreasePaginationIndex = (type, e) => {
    let paginationIndex = this.state.paginationIndex

    if (type === "inc") {
      e.preventDefault()

      if (this.state.paginationIndex + 1 < this.state.pluginData.length - 1) {
        paginationIndex = paginationIndex + 1
      } else {
        paginationIndex = 0
      }
    } else if (type === "dec") {
      if (this.state.paginationIndex - 1 >= 0) {
        paginationIndex = paginationIndex - 1
      } else {
        paginationIndex = this.state.pluginData.length - 2
      }
    }

    this.setState({
      ...this.state,
      paginationIndex: paginationIndex
    })
  }

  /*
  * Removes data from measure tile
  */
  resetMeasureData = () => {
    this.setState({
      ...this.state,
      pluginData: [],
      compareType: "",
    })
  }

  pluginHeight = 0

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    let copiedPluginData = [...this.state.pluginData]
    let configArray = this.props.plugin?.config?.configArray ? this.props.plugin.config.configArray : []

    let changeStatus = false

    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject,
      this.measureTileComparedStatus,
      this.measureTileErrorStatus,
      this.resetMeasureData
    );

    if (nextProps.plugin.config.configArray && !_.isEqual(nextProps.plugin.config.configArray, this.state.configArray)) {
      configArray = nextProps.plugin.config.configArray

      changeStatus = true
    }

    if (nextProps.noDataTitle !== this.props.noDataTitle) {
      for (let i = 0; i < copiedPluginData.length; i++) {
        if (nextProps.noDataTitle !== "" && (this.state.configArray[i].noDataTitle === "" || !this.state.configArray[i].noDataTitle)) {
          copiedPluginData[i].noDataTitle = nextProps.noDataTitle
        } else if (this.state.configArray[i].noDataTitle && this.state.configArray[i].noDataTitle !== "") {
          copiedPluginData[i].noDataTitle = this.state.configArray[i].noDataTitle
        } else {
          copiedPluginData[i].noDataTitle = i18n.t("NoDataContent.NoData")
        }
      }

      changeStatus = true
    }

    if (changeStatus) {
      this.setState({
        ...this.state,
        pluginData: copiedPluginData,
        configArray: configArray,
      })
    }

    let calculatedPluginHeight = calculatePluginInlineHeight(this.props.plugin.id)

    this.pluginHeight = calculatedPluginHeight
  }

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
        compareType={this.state.compareType}
      />
    );
  };

  /*
  * Changes no data title 
  */
  changeNoDataTitleForMeasure = (activeIndex, value) => {
    let copiedPluginData = [...this.state.pluginData]
    let activeData = copiedPluginData[activeIndex]

    if (value !== "") {
      activeData.noDataTitle = value
    } else if (store.getState().SettingNoDataTitleReducer.data !== "") {
      activeData.noDataTitle = store.getState().SettingNoDataTitleReducer.data
    } else {
      activeData.noDataTitle = i18n.t("NoDataContent.NoData")
    }

    this.setState({
      ...this.state,
      pluginData: copiedPluginData
    })
  }

  getConfigComponent = props => {
    if (props.config) {
      let isConfigOpenedOnce = this.state.isOpenedConfig === false ? true : false
      let isConfigSizeGreaterCalculatedSize = props.config.size >= this.state.fontSize ? true : false
      let isConfigSizeNull = props.config.size === null ? true : false
      let isConfigConditionsEqualResponsiveSize = isConfigOpenedOnce && isConfigSizeGreaterCalculatedSize ? true : false

      if (isConfigConditionsEqualResponsiveSize || isConfigSizeNull) {
        props.config.size = this.state.fontSize

        if (this.state.isOpenedConfig === false) {
          this.setState({ isOpenedConfig: true })
        }
      }

      return (
        <MeasureTileConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          compareLength={this.state.compareLength}
          configArray={this.state.configArray}
          changeNoDataTitleForMeasure={this.changeNoDataTitleForMeasure}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  /*
  * Gets conditional format component
  */
  getConditionalFormattingComponent = props => {
    let copiedColumnMap = deepCopy({ ...this.props.plugin.columnMap })

    if (this.state.defaultFilterColumn && Object.keys(this.state.defaultFilterColumn).length > 0) {
      copiedColumnMap["defaultFilter"] = {}

      copiedColumnMap.defaultFilter["data"] = [this.state.defaultFilterColumn]
    }

    let columnMap = copiedColumnMap

    if (copiedColumnMap.measure.data.length > 0) {
      copiedColumnMap.measure.data = copiedColumnMap.measure.data.filter(column => column.isDisabledColumn !== true)
    }

    if (copiedColumnMap.hidden.data.length > 0) {
      copiedColumnMap.hidden.data = copiedColumnMap.hidden.data.filter(column => column.isDisabledColumn !== true)
    }

    if (this.state.compareType === "single-column" || this.state.compareType === "default-filter") {
      if (copiedColumnMap.measure.data.length > 0 && this.state.compareType === "default-filter") {
        copiedColumnMap.measure.data = [copiedColumnMap.measure.data[0]]
        copiedColumnMap.hidden.data = []
      } else {
        copiedColumnMap.measure.data = [copiedColumnMap.measure.data[0]]
      }
    }

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        measureCompareType={this.state.compareType}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        pluginId={props.plugin.id}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
        isTargetColumnAllColumns={false}
        isLockedTargetValue={false}
      />
    );
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    )

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    if (!columnMap["measure"].multiple) {
      columnMap["measure"].multiple = true
    }

    return (
      <MeasureTileData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        navigations={props.navigations}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        type: "dim",
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        conditionalFormat: "icon",
        data: []
      },
      hidden: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Name"),
        type: "hidden",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Hidden.Desc"),
        multiple: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  convertFormatConditionalFormatting = (condFormats, columns) => {
    let condFormatList = [];

    condFormats.map(condItem => {
      condItem.targetColumns.map(targetColumn => {
        let conditionalFormat = {};
        let columnsMap = new Map();

        columns.map(column => {
          if (column && Object.keys(column).length > 0) {
            let columnId = column.uniqeColumnId ? column.uniqeColumnId : column.filterId ? column.filterId : undefined

            columnsMap.set(columnId, column);
          }
        })

        conditionalFormat.RightRule = condItem.rule.rightRule.rule;
        conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
        conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName === "{undefined}" ? convertHTMLRuletoRule(condItem.rule.leftRule).ruleColumnName : condItem.rule.leftRule.ruleColumnName;
        conditionalFormat.RightRuleColumnName = condItem.rule.rightRule.ruleColumnName
        conditionalFormat.Columns = columnsMap;
        conditionalFormat.TargetID = targetColumn.TargetID;
        conditionalFormat.TargetName = targetColumn.TargetName;
        conditionalFormat.Operator = condItem.rule.operator;
        conditionalFormat.id = condItem.id;

        conditionalFormat.Style = {
          colour: condItem.options.color,
          icon: condItem.options.icon,
          background: { colour: condItem.options.backgroundColor },
          text: {
            bold: condItem.options.text.bold,
            italic: condItem.options.text.italic,
          },
        };

        condFormatList.push(conditionalFormat);
      });
    });

    return condFormatList;
  };

  /*
  * Plugin render, rerender jquery processes
  */
  rerenderProcesses = () => {
    this.addCondFormLegend()
    calculateTitleSize(this.props.plugin)
  }

  /*
  * Adds conditional format legends
  */
  addCondFormLegend() {
    if (this.props.plugin?.config.condFormat && (this.props.plugin.h >= 3 || this.props.plugin.w >= 4)) {
      let showCondForm = $('<div id="showCondForm" style="display:flex; flex-wrap:wrap; justify-content:center;margin-top: 7px"></div>')

      if ($("#" + this.props.plugin.id).find("#showCondForm").length > 0) {
        $("#" + this.props.plugin.id).find("#showCondForm").remove()
      }

      if (this.props.plugin.conditionalFormats) {
        this.props.plugin.conditionalFormats.forEach(function (d, i) {
          let condLegend = document.createElement("div");
          condLegend.setAttribute("style", "margin: 0px 5px; display: flex;");

          let condLegendMarker = document.createElement("div");
          condLegendMarker.setAttribute("style", "background: " + d.options.backgroundColor + "; color:" + d.options.backgroundColor + "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px; margin-right: 5px; border: 1px solid white;");

          let condLegendMarkerForTextColor = document.createElement("div");
          condLegendMarkerForTextColor.setAttribute("style", "background: " + d.options.color + "; color:" + d.options.color + "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px; margin-right: 2px; border: 1px solid white;");

          let condLegendText = document.createElement("span");
          condLegendText.setAttribute("class", "apexcharts-legend-text");
          condLegendText.setAttribute("style", "color: rgb(55, 61, 63); font-size: 12px; font-family: Helvetica, Arial, sans-serif;");

          let ruleDescription = d?.rule?.conditionalFormatRule;
          // if rule description is defined in text area, legend will use user input.

          let textNode = document.createTextNode(
            ruleDescription ? " " + ruleDescription  : " " + d.rule.leftRule.ruleColumnName + " " + d.rule.operator + " " + d.rule.rightRule.ruleColumnName + ""
          );

          condLegendText.appendChild(textNode);
          condLegend.appendChild(condLegendMarkerForTextColor);
          condLegend.appendChild(condLegendMarker);
          condLegend.appendChild(condLegendText);

          showCondForm.append(condLegend);
        })

        $("#" + this.props.plugin.id).append(showCondForm);
      }
    } else {
      $("#" + this.props.plugin.id).find("#showCondForm").remove()
    }
  }

  render() {
    let configComponent = null;

    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let conditionalFormatComponent = null;

    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height = calculatePluginInlineHeight(this.props.plugin.id)

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    let pluginData = this.state.pluginData.filter(data => data !== undefined)
    let convertedConditionalFormats = this.convertFormatConditionalFormatting(this.props.plugin.conditionalFormats ? this.props.plugin.conditionalFormats : [], [...getPluginsAllColumnsByField(this.props.plugin.columnMap), this.state.defaultFilterColumn])
    let config = this.props.config
    let mustCompareShow = !config.showPaginationButtons
      && config.compare
      && this.props.plugin.w > 4
      && this.props.plugin.h > 1
      && (config.pagination || this.state.pluginData.length === 2)
      && this.state.pluginData.length > 1
    let isErrorAvailable = this.state.pluginErrorHashMap && this.state.pluginErrorHashMap.size > 0
    let mainContainerStyle = !this.props.config.showPaginationButtons && !this.props.config.compare ? { height: "80%" } : {}

    return (
      <>
        {!isErrorAvailable ?
          <div id={this.props.plugin.id} style={mainContainerStyle}>
            <MeasureTileMainContainer
              plugin={this.props.plugin}
              pluginData={pluginData}
              config={this.props.plugin.config}
              configArray={this.state.configArray}
              pluginId={this.props.plugin.id}
              compareType={this.state.compareType}
              pluginH={this.props.plugin.h}
              pluginW={this.props.plugin.w}
              pluginHeight={this.pluginHeight}
              paginationIndex={this.state.paginationIndex}
              increaseDecreasePaginationIndex={this.increaseDecreasePaginationIndex}
              triggerNavigation={this.triggerNavigation}
              condFormats={convertedConditionalFormats}
              compareLength={this.state.compareLength}
              columnMap={this.props.plugin.columnMap}
              isRenderStatusStarted={this.state.isRenderStatusStarted} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              {this.props.config.showPaginationButtons ? <>
                {this.props.plugin.w > 4 && this.props.plugin.h > 1 && this.state.pluginData.length > 2
                  ? <div className="measure-pagination-buttons">
                    {this.state.pluginData.map((data, i) => (
                      <>
                        {Math.round(this.state.pluginData.length / 2) > i
                          ? <div className={i * 2 === this.state.paginationIndex || (i * 2) + 1 === this.state.paginationIndex
                            ? "measure-tile-pagination-per-button active"
                            : "measure-tile-pagination-per-button"}
                            onClick={() => { this.setState({ ...this.state, paginationIndex: i * 2 }) }}>
                          </div>
                          : null}
                      </>
                    ))}
                  </div> : null}
              </> :
                <>
                  {mustCompareShow ?
                    <MeasureFooter pluginData={this.state.pluginData} paginationIndex={this.state.paginationIndex} config={config} />
                    : null}
                </>}
            </div>
          </div> : <PluginError errors={this.state.pluginErrorHashMap} plugin={this.props.plugin} />}
        {configComponent}
        {dataComponent}
        {navigationComponent}
        {conditionalFormatComponent}
        {this.rerenderProcesses()}
      </>
    );
  }
}
