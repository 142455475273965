import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import { Row, Col, Input, Select, Checkbox, Divider, Button, Tooltip } from "antd";
import { UpdateConfig } from "../../UpdateConfig";
import {
  FontSizeOutlined,
  NumberOutlined,
  BgColorsOutlined,
  EyeInvisibleOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../Utils/i18next";
import FontTypeSelector from "../../../GeneralComponents/FontTypeSelector/FontTypeSelector";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import $ from "jquery"
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const { TextArea } = Input;
const { Option } = Select;

export default class TableConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RowHeight: this.props.config.RowHeight,
      RowWidth: this.props.config.RowWidth,
      autoSize: this.props.config.autoSize,
      backgroundColor: this.props.config.backgroundColor,
      columnHeight: this.props.config.columnHeight,
      enableTotal: this.props.config.enableTotal,
      font: this.props.config.font,
      fontSize: this.props.config.fontSize,
      height: this.props.config.height,
      hideFiltering: this.props.config.hideFiltering,
      imgField: this.props.config.imgField,
      linkDesc: this.props.config.linkDesc,
      linkField: this.props.config.linkField,
      pageNumber: this.props.config.pageNumber,
      pagination: this.props.config.pagination,
      pageOffset: this.props.config.pageOffset,
      paginationPageSize: this.props.config.paginationPageSize,
      showHideButton: this.props.config.showHideButton,
      tableDescription: this.props.config.tableDescription,
      themeColour: this.props.config.themeColour,
      title: this.props.config.title,
      wrapCells: this.props.config.wrapCells,
      wrapHeader: this.props.config.wrapHeader,
      titleAlign: this.props.config.titleAlign,
      condFormat: this.props.config.condFormat,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      keyForTitleSize: null,
      columnHeaderTextFontSize: this.props.config.columnHeaderTextFontSize === undefined ? 12 : this.props.config.columnHeaderTextFontSize,
      columnHeaderTextFont: this.props.config.columnHeaderTextFont === undefined ? "Verdana" : this.props.config.columnHeaderTextFont,
      columnHeaderTextColor: this.props.config.columnHeaderTextColor === undefined ? "#000000" : this.props.config.columnHeaderTextColor,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        RowHeight: nextProps.config.RowHeight,
        RowWidth: nextProps.config.RowWidth,
        autoSize: nextProps.config.autoSize,
        backgroundColor: nextProps.config.backgroundColor,
        columnHeight: nextProps.config.columnHeight,
        enableTotal: nextProps.config.enableTotal,
        font: nextProps.config.font,
        fontSize: nextProps.config.fontSize,
        height: nextProps.config.height,
        hideFiltering: nextProps.config.hideFiltering,
        imgField: nextProps.config.imgField,
        linkDesc: nextProps.config.linkDesc,
        linkField: nextProps.config.linkField,
        pageNumber: nextProps.config.pageNumber,
        pagination: nextProps.config.pagination,
        pageOffset: nextProps.config.pageOffset,
        paginationPageSize: nextProps.config.paginationPageSize,
        showHideButton: nextProps.config.showHideButton,
        tableDescription: nextProps.config.tableDescription,
        themeColour: nextProps.config.themeColour,
        title: nextProps.config.title,
        wrapCells: nextProps.config.wrapCells,
        wrapHeader: nextProps.config.wrapHeader,
        titleAlign: nextProps.config.titleAlign,
        condFormat: nextProps.config.condFormat,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
        columnHeaderTextFontSize: nextProps.config.columnHeaderTextFontSize === undefined ? 12 : nextProps.config.columnHeaderTextFontSize,
        columnHeaderTextFont: nextProps.config.columnHeaderTextFont === undefined ? "Verdana" : nextProps.config.columnHeaderTextFont,
        columnHeaderTextColor: nextProps.config.columnHeaderTextColor === undefined ? "#000000" : nextProps.config.columnHeaderTextColor,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle
      });
    }
  }

  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  handleChangeNumeric = e => {
    let value = parseInt(e.target.value);

    if (isNaN(value)) {
      value = 0;
    }

    this.setState({
      ...this.state,
      [e.target.id]: value
    });
    
    this.setConfigToPlugin({ type: e.target.id, value: value });
  };

  handleReset = e => {
    this.props.setPluginRerender(true, this.props.pluginId, true);
  };

  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });
    
    if (e.target.id === "pagination") {
      this.handleReset(e);
    }
    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

   setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
    }
  }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  colorChangeVisible = () => {
    this.setState({ ...this.state, colorChange: !this.state.colorChange });
  };

  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  themeColourOnChange = obj => {
    this.setState({
      ...this.state,
      themeColour: obj.color
    });

    this.setConfigToPlugin({ type: "themeColour", value: obj.color });
  };

  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  //added change font-family function
  fontOnChange = obj => {
    this.setState({
      ...this.state,
      font: obj
    });

    this.setConfigToPlugin({ type: "font", value: obj });
  };

  /**
   * On Change Column Header Font Type call this function
   */
  columnHeaderTextFontOnChange = (obj) => {
    this.setState({
      ...this.state,
      columnHeaderTextFont: obj,
    });

    this.setConfigToPlugin({ type: "columnHeaderTextFont", value: obj });
  };

  /**
   * Toggle function, for Color Picker of Column Header visibility
   */
  columnHeaderTextColorChangeOnVisibile = () => {
    this.setState({
      ...this.state,
      columnHeaderTextColorChange: !this.state.columnHeaderTextColorChange,
    });
  };

  /**
   * On change Column Header Text Color call this function
   */
  columnHeaderTextColourOnChange = (obj) => {
    this.setState({
      ...this.state,
      columnHeaderTextColor: obj.color,
    });

    this.setConfigToPlugin({ type: "columnHeaderTextColor", value: obj.color });
  };

   //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
   setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({type: "changedTitleFontSize", value: newConfig.titleFontSize})
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        { 
          type: "titleFontSize", 
          value: constantValue
        }, 
        {
          type: "changedTitleFontSize", 
          value: changedValue
        }
      ]);
  }

  themeColourChangeVisible = () => {
    this.setState({
      ...this.state,
      themeColourChange: !this.state.themeColourChange
    });
  };

  // helps to open and close title colour picker
  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  render() {
    return (
      <div style={{ color: "#505050" }}>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.ColumnHeader")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.RowHeight")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"RowHeight"}
              defaultValue={this.state.RowHeight}
              value={this.state.RowHeight}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ColumnHeaderHeight")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"columnHeight"}
              defaultValue={this.state.columnHeight}
              value={this.state.columnHeight}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <NumberOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ColumnHeaderFont")}
            </Text>
          </Col>
          <Col span={12}>
            <FontTypeSelector
              placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
              onChange={this.columnHeaderTextFontOnChange}
              value={this.state.columnHeaderTextFont}
            />
          </Col>
        </Row>
        {/* font family */}
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ColumnHeaderFontSize")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"columnHeaderTextFontSize"}
              defaultValue={this.state.columnHeaderTextFontSize}
              value={this.state.columnHeaderTextFontSize}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ColumnHeaderFontColor")}
            </Text>
          </Col>
          <Col span={12}>
            <Button
              id={"openColorPicker"}
              onClick={this.columnHeaderTextColorChangeOnVisibile}
              style={{ width: "100%" }}
            >
              {this.state.columnHeaderTextColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.columnHeaderTextColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.columnHeaderTextColor}
                onChange={this.columnHeaderTextColourOnChange}
                id={"backgroundColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px",
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ColumnHeaderThemeColour")}
            </Text>
          </Col>
          <Col span={12}>
            <Button
              id={
                this.state.themeColourChange
                  ? i18n.t("closeThemeColour")
                  : i18n.t("openThemeColour")
              }
              onClick={this.themeColourChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.themeColourChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.themeColourChange ? (
              <ColorPickerPanel
                alpha={100}
                themeColour={this.state.themeColour}
                onChange={this.themeColourOnChange}
                id={"themeColour"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.TextStyling")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
          <NumberOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Font")}
            </Text>
          </Col>
          <Col span={12}>
            <FontTypeSelector
              placeholder={i18n.t("Dashboard.Configuration.Fields.FontStyle")}
              onChange={this.fontOnChange}
              value={this.state.font}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.FontSize")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"fontSize"}
              defaultValue={this.state.fontSize}
              value={this.state.fontSize}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowColumnTotal")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"enableTotal"}
              defaultChecked={this.state.enableTotal}
              checked={this.state.enableTotal}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>

        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ShowConditionalFormatting")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"condFormat"}
              defaultChecked={this.state.condFormat}
              checked={this.state.condFormat}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Pagination")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.EnablePaging")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"pagination"}
              defaultChecked={this.state.pagination}
              checked={this.state.pagination}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.PaginationPageSize")}
            </Text>
          </Col>
          <Col span={8}>
            <Input
              id={"paginationPageSize"}
              defaultValue={this.state.paginationPageSize}
              value={this.state.paginationPageSize}
              onChange={this.handleChangeNumeric}
            />
          </Col>
          <Col span={4} style= {{textAlign: "center" }}>
            <Button
              id="applyButton"
              className={"general-button"}
              onClick={this.handleReset}
              style={{
                width: "94%",
                borderBottom: "0",
                height: "fit-content",
                padding: "4px 0",
                marginBottom: "0",
                top: "1px"
              }}
            >
              {i18n.t("Apply")}
            </Button>
          </Col>
        </Row>

        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
            </Text>
          </Col>
          <Col span={12}>
            <Button
              id={"openColorPicker"}
              onClick={this.backgroundColorChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.backgroundColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.backgroundColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.backgroundColor}
                onChange={this.backgroundColorOnChange}
                id={"backgroundColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.HideFiltering")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"hideFiltering"}
              defaultChecked={this.state.hideFiltering}
              checked={this.state.hideFiltering}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.WrapHeader")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"wrapHeader"}
              defaultChecked={this.state.wrapHeader}
              checked={this.state.wrapHeader}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.WrapCells")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"wrapCells"}
              defaultChecked={this.state.wrapCells}
              checked={this.state.wrapCells}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        <TitleSettingsOfPlugin 
          plugin={this.props.plugin}
          handleChange={this.handleChange}
          commonTitleConfig={this.props.commonTitleConfig}
          updateCommonTitleConfig={this.props.updateCommonTitleConfig}
          isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
          setDefaultsForTitle={this.setDefaultsForTitle}
          title={this.state.title}
          titleAlign={this.state.titleAlign}
          titleColour={this.state.titleColour}
          titleColourChange={this.state.titleColourChange}
          titleColourChangeVisible={this.titleColourChangeVisible}
          titleFont={this.state.titleFont}
          titleFontSize={this.state.titleFontSize}
          keyForTitleSize={this.state.keyForTitleSize}
          titleFontStyle={this.state.titleFontStyle}
          titleFontWeight={this.state.titleFontWeight}
          titleTextDecor={this.state.titleTextDecor}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          changedTitleFontSize= {this.state.changedTitleFontSize}
          handleChangeBothOfValues= {this.handleChangeBothOfValues}
        />
        <Divider className={"config-divider"} orientation="left">
          {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.PageNumber")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"pageNumber"}
              defaultValue={this.state.pageNumber}
              value={this.state.pageNumber}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.TableDescription")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"tableDescription"}
              defaultValue={this.state.tableDescription}
              value={this.state.tableDescription}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.LinkField")}
              <Tooltip
                overlayClassName={"table-plugin-links"}
                title={i18n.t("Dashboard.Configuration.Fields.LinkFieldInfo")}
              >
                <span
                  className={"table-plugin-link-info"}
                >
                  (?)
                </span>
              </Tooltip>
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"linkField"}
              defaultValue={this.state.linkField}
              value={this.state.linkField}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.LinkDescription")}
              <Tooltip
                overlayClassName={"table-plugin-links"}
                title={i18n.t("Dashboard.Configuration.Fields.LinkDescriptionInfo")}
              >
                <span
                  className={"table-plugin-link-info"}
                >
                  (?)
                </span>
              </Tooltip>
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"linkDesc"}
              defaultValue={this.state.linkDesc}
              value={this.state.linkDesc}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.ImageField")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"imgField"}
              defaultValue={this.state.imgField}
              value={this.state.imgField}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.RowWidth")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"RowWidth"}
              defaultValue={this.state.RowWidth}
              value={this.state.RowWidth}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left", display: "flex" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Settings.NoDataConfiguration")}
            </Text>
            <Tooltip 
              title={
                <>
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                </>
              }
            >
              <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} /> 
            </Tooltip>
          </Col>
          <Col span={12}>
            <TextArea
              allowClear
              id={"noDataTitle"}
              defaultValue={this.state.noDataTitle}
              value={this.state.noDataTitle}
              onChange={this.handleChange}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={i18n.t("NoDataContent.NoData") + " " +
                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
