import { store } from "../../../src/index";
import $ from "jquery"
import { aggregatableDataTypes, dateDataTypes } from "../Plugins/DataComponents/DataConfigure";
import { showNotificationWithIcon } from "../../Utils/Notification"
import i18n from "../../Utils/i18next"
import { deepCopy } from "../../Utils/Global";
import { getPluginsAllColumnsByField } from "../../Utils/PluginOperations";

const clone = require("rfdc")();

/*
 * Reorders drag,drop items(etc hat, ayadi to ayadi, hat)
 */
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const setInteractionForChangedSingleColumn = (sourceItem, sourceIndex, destinationItem, destinationIndex, interactions, pluginId, setInteraction, columnMap, destinationItemArea) => {
  let filteredInteractions = interactions.filter(interaction => interaction.sourceId === pluginId)

  for (let i = 0; i < filteredInteractions.length; i++) {
    let interaction = filteredInteractions[i]
    let newInteractionItem = []

    interaction.columns.map((column) => {
      if (column.uniqeColumnId === destinationItem[0].uniqeColumnId) {
        newInteractionItem.push(sourceItem[sourceIndex])
      } else {
        newInteractionItem.push(column)
      }
    })

    if (interaction.columns.filter(column => destinationItem[0].uniqeColumnId === column.uniqeColumnId).length > 0) {
      columnMap[destinationItemArea].data.filter(column => destinationItem[0].uniqeColumnId === column.uniqeColumnId)[0]["isActiveForInteraction"] = true
    } else {
      columnMap[destinationItemArea].data.filter(column => destinationItem[0].uniqeColumnId === column.uniqeColumnId)[0]["isActiveForInteraction"] = false
    }

    interaction.columns = newInteractionItem
  }

  setInteraction(interactions)
}

/*
 * Prepares news columnMap by draggedColumn locations and prepares redux object etc (ayadi -> rows to ayadi -> columns)
 */
export const changeColumnPosition = (columnMap, result, pluginId, multipleAreas, interactions, setInteraction) => {
  let clonedColumnMap = clone(columnMap);

  if (clonedColumnMap[result.source.droppableId] && clonedColumnMap[result.destination.droppableId]) {
    let sourceItems = clonedColumnMap[result.source.droppableId].data;
    let destinationItems = clonedColumnMap[result.destination.droppableId].data;
    let filteredSourceItems = sourceItems.filter(
      (sourceItem) => sourceItem.uniqeColumnId !== result.draggableId
    );
    let sourceItem = sourceItems.filter(
      (sourceItem) => sourceItem.uniqeColumnId === result.draggableId
    );
    let isColumnMultiple = multipleAreas.includes(result.destination.droppableId)

    if (isColumnMultiple) {
      sourceItem[0]["locationFieldName"] = result.destination.droppableId;

      if (result.destination.droppableId === "hidden") {
        sourceItem[0]["isDisabledColumn"] = true;
      }

      if (result.source.droppableId === "hidden") {
        sourceItem[0]["isDisabledColumn"] = false;
      }

      sourceItem[0]["locationFieldName"] = result.destination.droppableId;

      destinationItems.splice(result.destination.index, 0, sourceItem[0]);
    
      clonedColumnMap[result.source.droppableId].data = clone(filteredSourceItems);
      clonedColumnMap[result.destination.droppableId].data = clone(destinationItems);
    
      prepareReduxForDragDrop(result, sourceItem, pluginId);
    } else {
      if (result.source.droppableId === "hidden" && clonedColumnMap[result.destination.droppableId].data.length > 0) {
        setInteractionForChangedSingleColumn(sourceItems, result.source.index, destinationItems, result.destination.index, interactions, pluginId, setInteraction, clonedColumnMap, result.destination.droppableId)

        let changedData = clonedColumnMap[result.destination.droppableId].data[0]
        
        changedData["isDisabledColumn"] = true
        changedData["locationFieldName"] = "hidden"

        filteredSourceItems.push(changedData)
      }

      sourceItem[0]["locationFieldName"] = result.destination.droppableId;
      sourceItem[0]["isDisabledColumn"] = false

      destinationItems = [sourceItem[0]];
    
      clonedColumnMap[result.source.droppableId].data = clone(filteredSourceItems);
      clonedColumnMap[result.destination.droppableId].data = clone(destinationItems);
    }

    return clonedColumnMap;
  }
};

/*
* Prepares Redux object by changed column
*/
export const prepareReduxForDragDrop = (result, sourceItem, pluginId) => {
  let reduxState = store.getState();
  let drillDownsMap = reduxState.DrillDownReducer.drillDowns;
  let getDrilldownColumn = drillDownsMap.get(pluginId)
  let allDrilldownColumnsInPlugin = getDrilldownColumn ? getDrilldownColumn["allDrillDownColumnsInPlugin"] : new Map()
  let allDrilldownsColumnsInPluginValues = getDrilldownColumn ? Array.from(allDrilldownColumnsInPlugin.values()) : []
  let drillDownColumnsForParentColumns = getDrilldownColumn ? getDrilldownColumn["drillDownColumnsForParentColumns"] : new Map()
  let drillDownColumnsForParentColumnsValues = drillDownColumnsForParentColumns.get(result.draggableId)

  if (drillDownColumnsForParentColumnsValues) {
    allDrilldownsColumnsInPluginValues.map(drilldownColumn => {
      let isDrillsCurrentColumnEqualSourceId = drilldownColumn["drillDownParentColumnId"] === sourceItem[0].uniqeColumnId || drilldownColumn["uniqeColumnId"] === sourceItem[0].uniqeColumnId

      if (isDrillsCurrentColumnEqualSourceId) {
        let newDrillColumn = {...drilldownColumn}
  
        newDrillColumn.locationFieldName = result.destination.droppableId
        getDrilldownColumn["allDrillDownColumnsInPlugin"].set(newDrillColumn.uniqeColumnId, newDrillColumn)
      }
    })

    drillDownColumnsForParentColumnsValues.map(drillColumn => {
      let isDrillsCurrentColumnEqualSourceId = drillColumn.drillDownParentColumnId === sourceItem[0].uniqeColumnId || drillColumn.uniqeColumnId === sourceItem[0].uniqeColumnId ? true : false

      if (isDrillsCurrentColumnEqualSourceId) {
        drillColumn.locationFieldName = result.destination.droppableId
      }
    })
  }
};

/*
* Gets dragged column
*/
export const getDraggedColumn = () => {
  let draggedColumn;
  let draggableColumns = $("[draggable=false]");

  if (draggableColumns.length > 0) {
    draggableColumns.each(function (i, el) {
      if ($(el).css("position") === "absolute") {
        draggedColumn = $(el).attr("data-rbd-draggable-id");
      }
    });

    return draggedColumn;
  } else {
    return "";
  }
}

/*
* Controls is column type accepts current column
*/
export const isColumnAggregatableDestinationColumn = (provided, isDraggingOver, colList, aggrDataTypes, isContainerAcceptAllColumn, draggedCol) => {
  let droppableId = provided.droppableProps["data-rbd-droppable-id"]
  let droppableColumnElement =  $(`[data-rbd-droppable-id='${droppableId}']`)
  let columnList = colList ? colList : []
  let draggedElementId = draggedCol
  let draggedElement = columnList.filter(col => col.uniqeColumnId === draggedElementId)[0]
  let isDraggedElHasDataType = draggedElement ? draggedElement : {dataType: ""} 
  let aggregatableDataTypes = aggrDataTypes ? aggrDataTypes : new Map()
  let isColumnAggregatable = aggregatableDataTypes.has(isDraggedElHasDataType.dataType) || draggedElement?.aggrRule?.toLowerCase().startsWith("count(")?true:false;
  let isColumnAcceptsAllColumns = isContainerAcceptAllColumn

  if (isDraggingOver) {  
    if (isColumnAcceptsAllColumns || isColumnAggregatable) {
      $(droppableColumnElement).closest(".accordion-holder").css("background-color", "rgba(0, 139, 139, 0.2)").css("border", "2px dashed rgba(0, 139, 139, 0.4)")
    } else if (draggedElementId) {
      $(droppableColumnElement).closest(".accordion-holder").css("background-color", "#f9ffc3").css("border", "2px dashed #f7f73e")
    }
  } else {
    $(droppableColumnElement).closest(".accordion-holder").css("background-color", "").css("border", "")
  }
}

/*
* Controls is column type accepts current column
*/
export const isColumnsDataTypeDate = (provided, isDraggingOver, colList, dateTypes, isContainerAcceptAllColumn, draggedCol) => {
  let droppableId = provided.droppableProps["data-rbd-droppable-id"]
  let droppableColumnElement =  $(`[data-rbd-droppable-id='${droppableId}']`)
  let columnList = colList ? colList : []
  let draggedElementId = draggedCol
  let draggedElement = columnList.filter(col => col.uniqeColumnId === draggedElementId)[0]
  let isDraggedElHasDataType = draggedElement ? draggedElement : {dataType: ""} 
  let dateDataTypes = dateTypes ? dateTypes : new Map()
  let isColumnDate = dateDataTypes.has(isDraggedElHasDataType.dataType)

  if (isDraggingOver) {  
    if (isContainerAcceptAllColumn || isColumnDate) {
      $(droppableColumnElement).closest(".accordion-holder").css("background-color", "rgba(0, 139, 139, 0.2)").css("border", "2px dashed rgba(0, 139, 139, 0.4)")
    } else if (draggedElementId) {
      $(droppableColumnElement).closest(".accordion-holder").css("background-color", "#f9ffc3").css("border", "2px dashed #f7f73e")
    }
  } else {
    $(droppableColumnElement).closest(".accordion-holder").css("background-color", "").css("border", "")
  }
}

/*
* Reorders items in same columnmap area for example = columns -> ay-adi, hat to columns -> hat, ay adi
*/
const reorderItemsForDragDrop = (colMap, result, plugin) => {
  let items = reorder(colMap[result.destination.droppableId].data, result.source.index, result.destination.index)

  return {...plugin.updateColumnMapData(items, result.destination.droppableId)};
}

/*
* Reorders items for sort area
*/
const reorderItemsForSortArea = (props, result) => {
  let items = reorder(props.sortedColumnList, result.source.index, result.destination.index)

  return items
}

/*
* For move different column area for example columns -> columns ay-adi to rows ay-adi
*/
const changeColumnPositionForDragDrop = (state, result, multipleColumnAreas, props, interactions, setInteraction) => {
  let newColumnMap = changeColumnPosition(state.columnMap, result, state.pluginId, multipleColumnAreas, interactions, setInteraction);
  let columnList = getPluginsAllColumnsByField(newColumnMap);
  let allColumns = new Map(columnList.map(col => [col.uniqeColumnId, col]));
  let newSortedColumnList = props.sortedColumnList
    .map(col => allColumns.get(col.uniqeColumnId))
    .filter(col => col !== undefined);
  
  props.updateColumnMap("columnMap", {
    columnMap: newColumnMap,
    sortedColumnList: newSortedColumnList,
    pluginId: state.pluginId,
  });

  return { newColumnMap, newSortedColumnList };
}

/*
* If dragged area is not in drag drop container's inside
*/
const turnBackColumnMap = (colMap, result, plugin) => {
  if (colMap[result.source.droppableId] && colMap[result.source.droppableId].data) {
    let items = reorder(
      colMap[result.source.droppableId].data,
      result.source.index,
      result.source.index
    );

    return {...plugin.updateColumnMapData(items, result.source.droppableId)};
  }
}

/*
* This function runs on drag end. Compiles columnMap data.
*/
export const rerenderColumnMapForDragAndDrop = (plugin, result, multipleColumnAreas, numericalColumnAreas = [], dateColumnAreas = [], interactions = [], setInteractionsForCustomDashboard = () => {}) => {
  let state = plugin.state
  let props = plugin.props
  let newState = {state};
  let colMap = state.columnMap
  let isDestinationNull = result.destination == null || result.destination == undefined || !result.destination ? true : false
  let draggedElementId = result.draggableId
  let draggedColumn = props.sortedColumnList.filter(col => col.uniqeColumnId === draggedElementId || col.uniqeColumnId + "-sort" === draggedElementId)[0] ||
      getPluginsAllColumnsByField(colMap).filter(col => col.uniqeColumnId === draggedElementId || col.uniqeColumnId + "-sort" === draggedElementId)[0];
  let isDraggedColumnsDataTypeNumeric = aggregatableDataTypes.has(draggedColumn.dataType)
  let isDraggedColumnsDataTypeDate = dateDataTypes.has(draggedColumn.dataType)
  let targetColumnArea = result.destination ? result.destination.droppableId : null
  let isCountOrCountDistinct = draggedColumn.aggrRule?.toLowerCase().startsWith("count(")?true:false;
  let isDraggedColumnSuitableForMeasureArea = !(isDraggedColumnsDataTypeNumeric || isCountOrCountDistinct) && numericalColumnAreas.includes(targetColumnArea)
  let isDraggedColumnSuitableForDateArea = !isDraggedColumnsDataTypeDate && dateColumnAreas.includes(targetColumnArea)

  if (!isDestinationNull) {
    let isDestinationEqualSource = result.destination.droppableId === result.source.droppableId ? true : false

    if (isDestinationEqualSource) {
      if (result.destination.droppableId !== "sort-area") {
        newState.columnMap = reorderItemsForDragDrop(colMap, result, plugin)
      } else {
        let items = reorderItemsForSortArea(props, result)

        plugin.updateSortedList(items)
      }
    } else if (result.destination.droppableId !== "sort-area" && result.source.droppableId !== "sort-area") {
      let { newColumnMap, newSortedColumnList } = changeColumnPositionForDragDrop(state, result, multipleColumnAreas, props, interactions, setInteractionsForCustomDashboard)

      newState.columnMap = newColumnMap
      newState.state.sortedColumnList = newSortedColumnList
    }
  } else if (isDestinationNull) {
    newState.columnMap = turnBackColumnMap(colMap, result, plugin)
  }

  if (isDraggedColumnSuitableForMeasureArea) {
    showNotificationWithIcon(
      i18n.t("Warning"),
      i18n.t("Errors.MeasureAreaMustBeMeasure"),
      "warning"
    );
  }

  if (isDraggedColumnSuitableForDateArea) {
    showNotificationWithIcon(
      i18n.t("Warning"),
      i18n.t("Errors.DateAreaMustBeDate"),
      "warning"
    );
  }

  $(".containerIcon").parent().css("overflow", "auto")
  $("#root").css("overflow", "auto")

  return newState
}

/*
* Runs on drag start, this function locks container and main scroll
*/
export const onDragStartInContainer = () => {
  $(".containerIcon").parent().css("overflow", "hidden")
  
  $("#root").css("overflow", "hidden")
}
