import React, { Component } from "react";
import PopupContainer from "../../../../GeneralComponents/PopupContainer/PopupContainer";
import "./dashboardComments.css"
import DashboardCommentsHeader from "./DashboardCommentsHeader";
import DashboardCommentsBody from "./DashboardCommentsBody";
import DashboardCommentAddCommentArea from "./DashboardCommentAddCommentArea";
import { del, get, post, put } from "../../../../../Utils/WebService";
import { API_BASE } from "../../../../../config";
import $ from "jquery"
import Cookies from "js-cookie";
import Button from "../../../../GeneralComponents/Button/Button";
import i18n from "../../../../../Utils/i18next";
import { CommentOutlined } from "@ant-design/icons";
import { checkIsCommentOnlySpace, generateAndAppendUserColors } from "./userColors";
import _ from "lodash";
import { deepCopy, getIcon } from "../../../../../Utils/Global";
import { showNotificationWithIcon } from "../../../../../Utils/Notification";
import { isValidWriteRoles } from "../../../RoleStore";
import WebSocketApp from "../../../../WebSocket/WebSocketApp";

export default class DashboardComments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pinned: false,
      dashboardComments: [],
      userList: [],
      comment: "",
      isTypedMention: false,
      typedWordCounter: 0,
      typedWord: "",
      filteredUsers: [],
      visible: false,
      usersWithColours: new Map(),
      isPinned: false,
      sortDir: "updatedDesc",
      loading: false,
      operationType: "",
      searchInput: "",
      searchType: "",
      applyedFilterData: "",
      topics: [
        `/topic/addDashboardComment-${this.props.mainDashboardId ? this.props.mainDashboardId : this.props.dashboard.id}`, // Add dashboard comment socket
        `/topic/removeDashboardComment-${this.props.mainDashboardId ? this.props.mainDashboardId : this.props.dashboard.id}`, // Remove dashboard comment socket
        `/topic/removeDashboardCommentReply-${this.props.mainDashboardId ? this.props.mainDashboardId : this.props.dashboard.id}`, // Remove dashboard comment reply socket
        `/topic/replyDashboardComment-${this.props.mainDashboardId ? this.props.mainDashboardId : this.props.dashboard.id}`, // Replies dashboard comment socket
        `/topic/updateDashboardComment-${this.props.mainDashboardId ? this.props.mainDashboardId : this.props.dashboard.id}` // Update dashboard comment socket
      ],
      newDashboardCommentCounter: 0,
      user: Cookies.get("authenticatedUser")
    };

    this.wrapperRef = React.createRef();
  }

  /*
  * Sorts dashboard comments by user's selected sort direction
  */
  sortDashboardComments = (dashboardComments, sortDir = this.state.sortDir) => {
    let comments = deepCopy(dashboardComments)

    if (sortDir === "asc" || sortDir === "desc") {
      comments.sort(function (a, b) {
        let firstDateFromNumber = new Date(a.sendDate).getTime()
        let secondDateFromNumber = new Date(b.sendDate).getTime()

        if (sortDir === "asc") {
          return firstDateFromNumber - secondDateFromNumber
        } else if (sortDir === "desc") {
          return secondDateFromNumber - firstDateFromNumber
        }
      })
    } else if (sortDir === "updatedDesc" || sortDir === "updatedAsc") {
      comments.sort(function (a, b) {
        let firstLastUpdateDate = new Date(a.updateDate).getTime()
        let secondLastUpdateDate = new Date(b.updateDate).getTime()

        if (a.replies.length > 0) {
          for (let i = 0; i < a.replies.length; i++) {
            let reply = a.replies[i]

            if (new Date(reply.updateDate).getTime() >= firstLastUpdateDate) {
              firstLastUpdateDate = new Date(reply.updateDate).getTime()
            }
          }
        }

        if (b.replies.length > 0) {
          for (let i = 0; i < b.replies.length; i++) {
            let reply = b.replies[i]

            if (new Date(reply.updateDate).getTime() >= firstLastUpdateDate) {
              secondLastUpdateDate = new Date(reply.updateDate).getTime()
            }
          }
        }

        if (sortDir === "updatedAsc") {
          return firstLastUpdateDate - secondLastUpdateDate
        } else if (sortDir === "updatedDesc") {
          return secondLastUpdateDate - firstLastUpdateDate
        }
      })
    }

    return comments
  }

  /*
  * Gets dashboard comments
  */
  getDashboardComments = (dashboard = this.props.dashboard, nextProps) => {
    let THIS = this

    if (dashboard) {
      let dashboardId = THIS.props.mainDashboardId && nextProps.isCustomDashboard
        ? nextProps.mainDashboardId
        : dashboard.id

      let url = `${API_BASE}/dashboardComment/` + dashboardId;

      const succesFunc = (dashboardResponse) => {
        let sortedResponse = this.sortDashboardComments(dashboardResponse.data)

        THIS.setState({
          ...THIS.state,
          dashboardComments: sortedResponse,
          comment: "",
          loading: false
        })
      }

      let errorFunc = () => {

      }

      let newTopics = [
        `/topic/addDashboardComment-${dashboardId}`, // Add dashboard comment socket
        `/topic/removeDashboardComment-${dashboardId}`, // Remove dashboard comment socket
        `/topic/removeDashboardCommentReply-${dashboardId}`, // Remove dashboard comment reply socket
        `/topic/replyDashboardComment-${dashboardId}`, // Replies dashboard comment socket
        `/topic/updateDashboardComment-${dashboardId}` // Update dashboard comment socket
      ]

      this.setState(
        {
          ...this.state,
          loading: true,
          topics: newTopics
        },
        () =>
          this.commentFilter(
            this.state.searchInput,
            this.state.searchType
          )
      );

      get(url, succesFunc, errorFunc, false);
    }
  }

  /*
  * Gets users list
  */
  getUserList = () => {
    let url = `${API_BASE}/user/tenant`;

    const succesFunc = (userResponse) => {
      let usersWithColours = generateAndAppendUserColors(userResponse.data)

      this.setState({
        ...this.state,
        userList: userResponse.data,
        usersWithColours: usersWithColours
      })
    }

    let errorFunc = () => {

    }

    get(url, succesFunc, errorFunc, false);
  }

  /*
  * Triggered when on comment change
  */
  onChangeComment = (event) => {
    let value = event

    this.setState({
      ...this.state,
      comment: value,
    })
  }

  /*
  * Triggered when comments send
  */
  sendComment = () => {
    let pattern = /@\w+/g;
    let matches = this.state.comment.match(pattern)
    let relatedUsers = matches && matches.length > 0 ? matches.map(match => match.slice(1)) : [];
    let url = `${API_BASE}/dashboardComment`;

    if (checkIsCommentOnlySpace(this.state.comment)) {
      let postObj = {
        comment: this.state.comment,
        dashboardId: this.props.mainDashboardId,
        username: Cookies.get("authenticatedUser"),
        relatedUsers: relatedUsers
      }

      let succesFunc = () => {
        this.commentFilter(this.state.searchInput, this.state.searchType);
      }

      let errorFunc = (e) => {
        showNotificationWithIcon(i18n.t("DashboardCommentsErrors." + e), null, "error");
      }

      post(url, postObj, succesFunc, errorFunc, false)
    } else {
      showNotificationWithIcon(i18n.t("DashboardCommentsErrors.DCE00004"), null, "error");
    }
  }

  /*
  * Triggered when reply sends
  */
  sendReply = (reqBody) => {
    if (checkIsCommentOnlySpace(reqBody.comment)) {
      let url = `${API_BASE}/dashboardComment/reply`;

      let succesFunc = () => {
        this.commentFilter(this.state.searchInput, this.state.searchType);
      }

      let errorFunc = (e) => {
        showNotificationWithIcon(i18n.t("DashboardCommentsErrorss." + e), null, "error");
      }

      post(url, reqBody, succesFunc, errorFunc, false)
    }
  }

  /*
  * Listens input and when enter typed trigger
  */
  sendCommentWithEnter = (e) => {
    if (e.key === "Enter") {
      this.sendComment()
    }
  }

  /*
  * Gets user list and comments when component first mount
  */
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);

    let operationType = Cookies.get("operationTypes") ? Cookies.get("operationTypes") : ""

    this.setState({
      ...this.state,
      operationType: operationType
    }, () => {
      this.getDashboardComments(undefined, this.props)
      this.getUserList()
      this.commentFilter(this.state.searchInput, this.state.searchType)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible !== this.state.visible && this.state.visible === true) {
      this.setState({
        ...this.state,
        newDashboardCommentCounter: 0
      })
    }
  }

  /**
 * If click outside hide switcher area.
 */
  handleClickOutside = event => {
    if (!this.state.isPinned) {
      let isTargetWrapper = this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target) ? true : false
      let isTargetTooltip = !$(event.target).closest(".dashboard-comment-tooltip").length > 0
      let isTargetAntSelect = !$(event.target).closest(".ant-select-dropdown-menu").length > 0 ? true : false
      let isTargetSelectItem = !$(event.target).closest(".ant-mentions-dropdown-menu-item").length > 0 ? true : false

      let willPopupClose = isTargetAntSelect && isTargetWrapper && isTargetTooltip && isTargetSelectItem ? true : false

      if (willPopupClose) {
        if (!$(event.target).closest("#loginPopup").length > 0) {
          this.changeDashboardCommentVisible(false)
        }
      }
    }
  };

  /*
  * Changes sort direction
  */
  handleChangeSortDir = (sortDir) => {
    let copiedState = [...this.state.dashboardComments]

    if (sortDir !== this.state.sortDir) {
      copiedState = this.sortDashboardComments(copiedState, sortDir)

      this.setState(
        {
          ...this.state,
          sortDir: sortDir,
          dashboardComments: copiedState,
        },
        () =>
          this.commentFilter(
            this.state.searchInput,
            this.state.searchType
          )
      );
    }
  }

  /*
  * Changes dashboard comment visibility
  */
  changeDashboardCommentVisible = (status) => {
    if (status !== this.state.visible) {
      this.setState({
        ...this.state,
        visible: status,
        applyedFilterData: "",
      })
    }
  }

  /*
  * Removes comment
  */
  removeComment = (mainComment, commentId, replyId) => {
    let url

    if (mainComment) {
      url = `${API_BASE}/dashboardComment/${this.props.mainDashboardId}/comment/${commentId}`;
    } else {
      url = `${API_BASE}/dashboardComment/${this.props.mainDashboardId}/comment/${commentId}/reply/${replyId}`;
    }

    let succesFunc = () => {
      this.commentFilter(this.state.searchInput);
    }

    let errorFunc = (e) => {
      showNotificationWithIcon(i18n.t("DashboardCommentsErrors." + e), null, "error");
    }

    del(url, null, succesFunc, errorFunc, false, {}, true, false)
  }

  /*
  * Changes pin status
  */
  changePinStatus = () => {
    this.setState({
      ...this.state,
      isPinned: !this.state.isPinned
    })
  }

  /*
  * Edits comment
  */
  editComment = (requestBody) => {
    if (checkIsCommentOnlySpace(requestBody.comment)) {
      let url = `${API_BASE}/dashboardComment`;

      let succesFunc = () => {
        this.commentFilter(this.state.searchInput);
      }

      let errorFunc = (e) => {
        showNotificationWithIcon(i18n.t("DashboardCommentsErrors." + e), null, "error");
      }

      put(url, requestBody, succesFunc, errorFunc, false, {}, true, false)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.dashboard, nextProps.dashboard)) {
      this.getDashboardComments(nextProps.dashboard, nextProps)
    }
  }


  /**
   * Search input on change.
   * @param {*} input 
   */
  searchInputChange = (input) => {
    if (input != this.state.searchInput) {
      this.setState({
        searchInput: input
      })
    }
  }

  /**
   * Search input type on change
   * @param {*} type 
   */
  searchTypeChange = (type) => {
    if (type != this.state.searchType) {
      this.setState({
        searchType: type
      })
    }
  }

  /**
   * Filter commments with input and type
   */
  commentFilter = (input = "", type = "") => {
    this.searchInputChange(input);
    this.searchTypeChange(type);

    if (input !== "") {
      let filteredData = [];
      let commentsAll = deepCopy(this.state.dashboardComments);

      if (input.length > 1 && input.startsWith("@")) {
        input = input.replace("@", "");

        this.commentFilterForUsers(commentsAll, input);
      } else if (type === "Users") {
        this.commentFilterForUsers(commentsAll, input);
      } else if (type === "Context") {
        commentsAll.forEach((mainComment) => {
          if (mainComment.comment.includes(input)) {
            let subComments = [];

            mainComment.replies.forEach((subComment) => {
              if (subComment.comment.includes(input)) {
                subComments.push(subComment);
              }
            });

            mainComment.replies = subComments;
            filteredData.push(mainComment);
          } else {
            let subComments = [];

            mainComment.replies.forEach((subComment) => {
              if (subComment.comment.includes(input)) {
                subComments.push(subComment);
              }
            });

            if (subComments.length > 0) {
              let commentBlock = mainComment;
              commentBlock.replies = subComments;
              filteredData.push(commentBlock);
            }
          }
        });

        this.setState({
          applyedFilterData: filteredData,
        });
      } else if (type === "" && !input.startsWith("@")) {
        let foundWithUserName = [];
        let foundWithCommentContext = [];

        commentsAll.forEach((mainComment) => {
          let replyCheck = (mainComment, input, type) => {
            let hasInput = false;

            if (type === "Context") {
              mainComment.replies.forEach((subComment) => {
                if (subComment.comment.includes(input)) {
                  hasInput = true;
                }
              });
            } else {
              mainComment.replies.forEach((subComment) => {
                if (subComment.username && subComment.username.startsWith(input)) {
                  hasInput = true;
                }
              });
            }
            return hasInput;
          };

          let hasContext =
            mainComment.comment.includes(input) ||
            replyCheck(mainComment, input, "Context");
          let hasUserName =
            mainComment.username && mainComment.username.startsWith(input) ||
            replyCheck(mainComment, input, "User");

          if (hasContext) {
            let subComments = [];

            mainComment.replies.forEach((subComment) => {
              if (subComment.comment.includes(input)) {
                subComments.push(subComment);
              }
            });

            mainComment.replies = subComments;
            foundWithCommentContext.push(mainComment);
          } else if (hasUserName) {
            let subComments = [];

            mainComment.replies.forEach((subComment) => {
              if (subComment.username && subComment.username.startsWith(input)) {
                subComments.push(subComment);
              }
            });

            mainComment.replies = subComments;
            foundWithUserName.push(mainComment);
          }
        });

        if (this.state.sortDir === "asc") {
          foundWithUserName.forEach((commentBlock) => {
            filteredData.push(commentBlock);
          });
          foundWithCommentContext.forEach((commentBlock) => {
            filteredData.push(commentBlock);
          });
        } else {
          foundWithCommentContext.forEach((commentBlock) => {
            filteredData.push(commentBlock);
          });
          foundWithUserName.forEach((commentBlock) => {
            filteredData.push(commentBlock);
          });
        }

        this.setState({
          applyedFilterData: filteredData,
        });
      } else if (input.startsWith("@") && input.length === 1) {
        this.applyedFilterNoData();
      }
    } else {
      this.applyedFilterNoData();
    }
  };

  /**
   * If no data filtered call this function
   */
  applyedFilterNoData = () => {
    this.setState(
      { applyedFilterData: "" }
    )
  }
  /**
   * if filtering is user based call this function
   * @param {*} commentsAll allcomment on dashboard
   * @param {*} input input on search
   */
  commentFilterForUsers = (commentsAll, input) => {
    let filteredData = [];

    commentsAll.forEach((mainComment) => {
      if (mainComment.username && mainComment.username.startsWith(input)) {
        let subComments = [];

        mainComment.replies.forEach((subComment) => {
          if (subComment.username.startsWith(input)) {
            subComments.push(subComment);
          }
        });

        mainComment.replies = subComments;
        filteredData.push(mainComment);
      } else {
        let subComments = [];

        mainComment.replies.forEach((subComment) => {
          if (subComment.username && subComment.username.startsWith(input)) {
            subComments.push(subComment);
          }
        });

        if (subComments.length > 0) {
          let commentBlock = mainComment;

          commentBlock.replies = subComments;
          filteredData.push(commentBlock);
        }
      }
    });

    this.setState({
      applyedFilterData: filteredData,
    });
  }

  /*
  * Listens all dashboard comment CRUD operation topics and changes dashboard comment or new dashboard comment counter
  */
  listenDashboardCommentTopic = (comment, destination) => {
    let dashboardId = this.props.mainDashboardId ? this.props.mainDashboardId : this.props.dashboard.id
    let dashboardComments = deepCopy(this.state.dashboardComments);
    let newDashboardCommentCounter = this.state.newDashboardCommentCounter
    let isCommentRowMustEmpty = false

    if (destination === "/topic/addDashboardComment-" + dashboardId) {
      if (comment.dashboardId === dashboardId) {
        if (this.state.visible === false) {
          newDashboardCommentCounter = newDashboardCommentCounter + 1
        }

        dashboardComments.push(comment);
      }
    } else if (destination === "/topic/removeDashboardComment-" + dashboardId) {
      dashboardComments = dashboardComments.filter(dashComment => dashComment.id !== comment);
    } else if (destination === `/topic/removeDashboardCommentReply-${dashboardId}`) {
      let findedMainDashboardComment = dashboardComments.find(dComment => dComment.id === comment.mainCommentId)

      if (findedMainDashboardComment) {
        let indexOfMainDashboard = dashboardComments.indexOf(findedMainDashboardComment)

        dashboardComments[indexOfMainDashboard]["replies"] = dashboardComments[indexOfMainDashboard]["replies"].filter(reply => reply.id !== comment.id)
      }
    } else if (destination === `/topic/replyDashboardComment-${dashboardId}`) {
      if (this.state.visible === false) {
        newDashboardCommentCounter = newDashboardCommentCounter + 1
      }

      let findedMainDashboardComment = dashboardComments.find(dComment => dComment.id === comment.mainCommentId)

      if (findedMainDashboardComment) {
        let indexOfMainDashboard = dashboardComments.indexOf(findedMainDashboardComment)

        dashboardComments[indexOfMainDashboard]["replies"].push(comment)
      }
    } else if (destination === `/topic/updateDashboardComment-${dashboardId}`) {
      if (comment.baseComment == undefined || comment.baseComment) {
        let findedMainDashboardComment = dashboardComments.find(dComment => dComment.id === comment.id)

        if (findedMainDashboardComment) {
          let indexOfMainDashboard = dashboardComments.indexOf(findedMainDashboardComment)

          dashboardComments[indexOfMainDashboard] = comment
        }
      } else if (comment.baseComment === false) {
        let findedMainDashboardComment = dashboardComments.find(dComment => dComment.id === comment.mainCommentId)

        if (findedMainDashboardComment) {
          let indexOfMainDashboard = dashboardComments.indexOf(findedMainDashboardComment)

          for (let i = 0; i < dashboardComments[indexOfMainDashboard]["replies"].length; i++) {
            let dashboardCommentReply = dashboardComments[indexOfMainDashboard]["replies"][i]

            if (dashboardCommentReply.id === comment.id) {
              dashboardComments[indexOfMainDashboard]["replies"][i] = comment
            }
          }
        }
      }
    }

    if (comment.username === this.state.user) {
      isCommentRowMustEmpty = true
    }

    dashboardComments = this.sortDashboardComments(dashboardComments)

    this.setState({
      ...this.state,
      comment: isCommentRowMustEmpty ? "" : this.state.comment,
      loading: false,
      dashboardComments: dashboardComments,
      newDashboardCommentCounter: newDashboardCommentCounter
    });
  }

  render() {
    let buttonTheme = this.props.theme?.buttons?.dashboardCommentsButton;
    let isUserOperationWrite = this.state.operationType === "WRITE" || this.state.operationType.includes("WRITE") || isValidWriteRoles() ? true : false
    let isCommentLengthGreaterThanZero = this.state.dashboardComments.length > 0 ? true : false
    let canViewerSeeDBButtons = !isUserOperationWrite && !isCommentLengthGreaterThanZero ? false : true
    let commentsOnDashboard = this.state.applyedFilterData === "" ? this.state.dashboardComments : this.state.applyedFilterData;

    return (
      <div style={{ position: "relative", float: "right" }}>
        {canViewerSeeDBButtons && this.props.mainDashboardId !== undefined ?
          <Button
            id="dashboardCommentOpenButton"
            className="dashboard-button btn btn-new circle-button"
            tooltip={i18n.t("DashboardComments.DashboardComments")}
            onClick={() => this.changeDashboardCommentVisible(!this.state.visible)}
            style={{ float: "right" }}
          >
            <style>
              {`
                #dashboardCommentOpenButton {
                    background-color: ${buttonTheme?.backgroundColor};
                    color: ${buttonTheme?.textColor};
                    border-color: ${buttonTheme?.textColor}33;
                }

                #dashboardCommentOpenButton:hover,
                #dashboardCommentOpenButton:focus,
                #dashboardCommentOpenButton:active {
                    background-color: ${buttonTheme?.backgroundColorActive};
                    color: ${buttonTheme?.textColorActive};
                    border-color: ${buttonTheme?.textColorActive}33;
                }
              `}
            </style>
            <WebSocketApp
              listenTopic={this.listenDashboardCommentTopic}
              dashboardId={this.props.dashboard.id}
              topics={this.state.topics}
            />
            {
              getIcon(
                buttonTheme?.icon, 
                <CommentOutlined style={{ fontSize: "25px" }} />,
                { fontSize: "25px" }
              )
            }
            {" "}
            {this.state.newDashboardCommentCounter > 0 ?
              <div className="new-message-icon">{this.state.newDashboardCommentCounter < 10 ? this.state.newDashboardCommentCounter : "9+"}</div>
              : null}
          </Button> : null}
        {this.state.visible ?
          <div
            className={"dashboard-comments-popup"}
            ref={this.wrapperRef}>
            <PopupContainer
              className="dashboard-comments-popup-container"
              width="470px"
              constantHeight=" 90vh"
              position="left"
              textAlign="left"
              background={"rgb(251, 251, 251)"}
              color="#505050">
              <DashboardCommentsHeader
                getDashboardComments={this.getDashboardComments}
                closeCommentPopup={this.changeDashboardCommentVisible}
                isPinned={this.state.isPinned}
                handleChangeSortDir={this.handleChangeSortDir}
                changePinStatus={this.changePinStatus}
                commentFilter={this.commentFilter}
                sortDir={this.state.sortDir}
                isCustomDashboard={this.props.isCustomDashboard}
                mainDashboardId={this.props.mainDashboardId} />
              <DashboardCommentsBody
                dashboardComments={commentsOnDashboard}
                dashboard={this.props.dashboard}
                usersWithColours={this.state.usersWithColours}
                removeComment={this.removeComment}
                users={this.state.userList}
                sendReply={this.sendReply}
                editComment={this.editComment}
                operationType={this.state.operationType}
                mainDashboardId={this.props.mainDashboardId}
                searchVisible={this.state.searchVisible} />
              <DashboardCommentAddCommentArea
                sendCommentWithEnter={this.sendCommentWithEnter}
                sendComment={this.sendComment}
                onChangeComment={this.onChangeComment}
                comment={this.state.comment}
                users={this.state.userList}
                loading={this.state.loading}
                operationType={this.state.operationType} />
            </PopupContainer>
          </div> : null}
      </div>
    );
  }
}
