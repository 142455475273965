/* eslint-disable array-callback-return */
import $ from "jquery";
import {
  post
} from "../../../Utils/WebService";
import {
  API_BASE,
  QUERY_URL
} from "../../../config";
import Cookies from "js-cookie";
import { store } from "../../../index";
import { columnMapValidation } from "./PluginColumnMapValidation";
import { format, getDefaultFormatType } from "./format"
import { showNotificationWithIcon } from "../../../Utils/Notification";
import i18n from "../../../Utils/i18next";
import { addErrorInPlugin, removeErrorFromPlugin, getPluginImages } from "./common";
import { setTriggeredDrillDowns } from "../../DrillDown/DrillDownAction"
import { checkAndFindDataSourceKeyToColumn } from "./columnDataSourceKeyOperations";
import { deepCopy } from "../../../Utils/Global";
import { getPluginsAllColumnsByField } from "../../../Utils/PluginOperations";
import moment from "moment"
import evaluate from "../../../Utils/Evaluate";

const clone = require("rfdc")();
const sessionVariableMatchRule = /\$\s*\{\s*session\.\w+\s*\}/g;
const sessionVariableNameMatchRule = /\$\s*\{\s*session\.(\w+)\s*\}/g;

/**
 * Checks need join in formula tables
 * @param {*} joins
 * @param {*} mustJoinTableSet
 * @returns
 */
const checkJoinList = (joins, mustJoinTableSet) => {
  let joinFactsMap = new Map();

  if (mustJoinTableSet.size > 1) {
    if (joins.length > 0) {
      for (let i = 0; i < joins.length; i++) {
        let join = joins[i];

        if (joinFactsMap.has(join.factTableAlias)) {
          let dimensionTableAliasList = joinFactsMap.get(join.factTableAlias);
          dimensionTableAliasList.push(join.dimensionTableAlias);

          joinFactsMap.set(join.factTableAlias, dimensionTableAliasList);
        } else {
          joinFactsMap.set(join.factTableAlias, [join.dimensionTableAlias]);
        }
      }

      let mustJoinTableList = [...mustJoinTableSet.keys()];
      let hasAnyFact = undefined;
      let factsDims = [];

      for (let i = 0; i < mustJoinTableList.length; i++) {
        let mustJoinTable = mustJoinTableList[i];

        if (joinFactsMap.has(mustJoinTable)) {
          if (hasAnyFact !== undefined) {
            return { status: false, message: i18n.t("Model.MoreThanOneFact") };
          }

          hasAnyFact = mustJoinTable;
          factsDims = [...new Set(joinFactsMap.get(mustJoinTable))]
        }
      }

      let mustJoinTableSetHasJoin = new Set(mustJoinTableSet);

      if (hasAnyFact !== undefined) {
        let tableCount = 1;

        for (let i = 0; i < factsDims.length; i++) {
          if (mustJoinTableSet.has(factsDims[i])) {
            tableCount++;
            mustJoinTableSetHasJoin.delete(factsDims[i])
          }
        }

        if (tableCount === mustJoinTableSet.size) {
          return { status: true };
        } else {
          return { status: false, message: i18n.t("Model.MustAddJoin") };
        }
      } else {
        let isAnyMatch = false;
        let dimTableListByFact = [...joinFactsMap.values()]

        for (let i = 0; i < dimTableListByFact.length; i++) {
          let tableCount = 0;
          let dimTableList = dimTableListByFact[i]

          for (let j = 0; j < dimTableList.length; j++) {
            if (mustJoinTableSet.has(dimTableList[j])) {
              tableCount++;
            }
          }

          if (tableCount === mustJoinTableSet.size) {
            isAnyMatch = true
          }
        }

        if (isAnyMatch) {
          return { status: true };
        } else {
          return { status: false, message: i18n.t("Model.MustAddJoin") };
        }
      }
    } else {
      return { status: false, message: i18n.t("Model.MustAddJoin") };
    }
  } else {
    return { status: true };
  }
}

  /** Returns new value for column. Replaces what if parameters in column formulas */
  const getColumnValueForWhatIf = (column, whatIf) => {
    let value = column.value;

    if (whatIf && Object.keys(whatIf).length > 0) {
      let parameters = [...whatIf.parameters]

      if (value) {
        parameters.map(p => {
          let parameterName = p.name;
          let parameterValue = p.currentValue;

          if (p.dataType === "percent") {
            parameterValue /= 100;
          }

          if (value.includes(parameterName)) {
            let regex = new RegExp(parameterName, "g");
            value = value.replace(regex, parameterValue);
          }
        })
      }

      return value;
    }

    return value;
  }

/**
 * Prepare data for query
 *
 * @param {*} nextProps
 * @param {*} success
 * @param {*} error
 * @param {*} queryParameters
 * @param {*} isColumnMapValidation
 * @param {*} renderCallBack
 * @param {*} queryLimit
 * @param {*} isInteraction
 * @param {*} isExcelExport
 * @param {*} isCompared
 * @param {*} compareCallback
 * @param {*} controlledProps
 * @param {*} comparedErrorCallbackObject
 * @param {*} measureErrorHashMap
 * @param {*} isPaginationAndHasOwnLimit
 * @param {*} excelExportCallback
 * @param {*} comesFromTable
 */
export const bindData = async (
  nextProps,
  success = undefined,
  error = undefined,
  queryParameters = undefined,
  isColumnMapValidation = undefined,
  renderCallBack = undefined,
  queryLimit = undefined,
  isInteraction = undefined,
  isExcelExportBigSized = false,
  isCompared = false,
  compareCallback = undefined,
  controlledProps = undefined,
  comparedErrorCallbackObject = undefined,
  measureErrorHashMap = undefined,
  isPaginationAndHasOwnLimit = undefined
) => {
  let selectList = [];

  let tempPlugin = {
    ...nextProps.plugin,
  };

  let isValidColumnMap = isColumnMapValidation;

  if (isValidColumnMap === undefined) {
    isValidColumnMap = columnMapValidation(tempPlugin);
  }

  if (nextProps.plugin.key === "measure-tile") {
    if (nextProps.plugin.columnMap.measure.data.filter(column => !column.isDisabledColumn).length <= 0) {
      comparedErrorCallbackObject(controlledProps, i18n.t("ColumnMapValidError", { pluginName: i18n.t("Plugins.measure-tile.Name") }), true);

      isValidColumnMap = false
    }
  }

  let reduxState = store.getState();
  let sessionVariables = new Map(reduxState.SessionVariableReducer.sessionVariables);
  let sessionVariablesShouldExecuteNow = [];
  let usedSessionVariables = new Map(nextProps.plugin.usedSessionVariables);
  let dashboardSessionVariables = reduxState.SessionVariableReducer.dashboard;

  const setUsedSessionVariables = (data) => {
    let matchedSessionVariables = `${data.lsqlValue} ${data.value} ${data.Code}`?.match(sessionVariableMatchRule) || [];

    for (let d of matchedSessionVariables) {
      let name = d.replace(sessionVariableNameMatchRule, (m, p1) => p1);
      let variable = sessionVariables.get(name);
      let value = variable?.defaultValue;

      if (variable?.scope === "dashboard") {
        value = dashboardSessionVariables.get(variable.name);
      } else if (variable) {
        sessionVariablesShouldExecuteNow.push(variable._id);
      } else {
        value = undefined;
      }

      usedSessionVariables.set(name, value);
    }
  }

  for (let field of Object.values(tempPlugin.columnMap)) {
    if (field.data) field.data.forEach(column => setUsedSessionVariables(column));
  }

  if (tempPlugin.defaultFilters) {
    for (let filter of tempPlugin.defaultFilters) { // eslint-disable-line
      const isFilterTypeSessionVariable = filter.lsql === true && filter.lsqlType === "session-variable"; // eslint-disable-line
      const isFilterPredicateIsNullOrIsNotNull = filter.filterPredicate === "is null" || filter.filterPredicate === "is not null"

      if (isFilterTypeSessionVariable === true && isFilterPredicateIsNullOrIsNotNull === false) setUsedSessionVariables(filter);
    }
  }

  if (tempPlugin.key === "title" ? tempPlugin.config?.title_ : tempPlugin.config?.title) {
    setUsedSessionVariables({
      value: tempPlugin.key === "title" ? tempPlugin.config.title_ : tempPlugin.config.title
    });
  }

  if (tempPlugin.key === "measure-tile") {
    for (let config of tempPlugin.config?.configArray || []) {
      setUsedSessionVariables({
        value: config.measureHeader
      });
    }
  }

  /**
   * Prepare data for query process
   */
  const bindDataProcess = () => {
    tempPlugin.usedSessionVariables = usedSessionVariables;

    if (isValidColumnMap === true) {
      const filters = getFilters(nextProps, tempPlugin, usedSessionVariables, renderCallBack);
      const limit = (queryLimit === undefined ? getLimit(nextProps, isPaginationAndHasOwnLimit) : queryLimit);
      let offset = getOffset(nextProps);

      if (queryParameters) {
        if (queryParameters.selectList) {
          selectList = [...queryParameters.selectList];
        }

        if (queryParameters.offset) {
          offset = queryParameters.offset;
        }
      }

      /*
      * Controls select list for orderBy
      */
      const controlReplacedSelectList = (selectList) => {
        let newSelectList = deepCopy(selectList)

        for (let i = 0; i < newSelectList.length; i++) {
          let column = newSelectList[i];

          if (column && column.orderBy && column.orderBy.columnIndex !== i + 1) {
            column.orderBy.columnIndex = i + 1
          }

          if (column && column.orderBy && column.orderBy.order !== i) {
            column.orderBy.order = i
          }
        }

        return newSelectList
      }

      /**
       * This function preparing sortDir object for sorting.
       * @param {*} mapSortedList
       */
      const prepareOrderByMap = (mapSortedList) => {
        if (nextProps.plugin?.sortedColumnList) {
          let sortedColumnList = [...nextProps.plugin.sortedColumnList]

          for (let i = 0; i < sortedColumnList.length; i++) {
            let column = sortedColumnList[i]
            if (column.sortDir === undefined || column.sortDir == null) {
              mapSortedList.set(column.uniqeColumnId, null)
            } else {
              mapSortedList.set(column.uniqeColumnId, {
                direction: column.sortDir,
                order: i + 1
              })
            }
          }
        }
      }

      let state = store.getState();
      let whatIf = {};

      if (state && state.WhatIfReducer) {
        whatIf = state.WhatIfReducer.whatIf;
      }

      if (selectList.length === 0) {
        let mapSortedList = new Map();
        prepareOrderByMap(mapSortedList);

        let columnMap = nextProps.plugin.columnMap;

        for (const key in columnMap) {
          const columnMappingField = columnMap[key];
          if (hasColumnInField(columnMappingField)) {
            if (columnMappingField.data) {
              for (let i = 0; i < columnMappingField.data.length; i++) {
                let column = columnMappingField.data[i];
                column.orderBy = mapSortedList.get(column.uniqeColumnId);

                if (columnMappingField.multiple) {
                  column.multiple = columnMappingField.multiple;
                }

                if (column.orderBy !== null && column.orderBy !== undefined) {
                  column.orderBy.columnIndex = selectList.length + 1;
                }

                if (column.isDisabledColumn !== true) {
                  selectList.push(column);
                }

              }
            } else {
              let column = { ...columnMappingField };
              column.orderBy = mapSortedList.get(column.uniqeColumnId);

              if (column.orderBy !== null) {
                column.orderBy.columnIndex = selectList.length + 1;
              }

              if (column.isDisabledColumn !== true) {
                selectList.push(column);
              }
            }
          }
        }

        if (
          state &&
          state.DrillDownReducer.triggeredDrillDowns.get(nextProps.plugin.id)
        ) {
          let drillDownParentHashMap = new Map();
          let drillDownColumns = state.DrillDownReducer.triggeredDrillDowns.get(
            nextProps.plugin.id
          ).selectedColumns;

          let pluginColumnClickedAndShouldBeRemoved = state.DrillDownReducer.pluginColumnClickedAndShouldBeRemoved
          let columnClickedMap = pluginColumnClickedAndShouldBeRemoved.get(nextProps.plugin.id)

          drillDownParentHashMap = getDrillDownColumnParentMap(
            drillDownParentHashMap,
            drillDownColumns
          );

          let newSelectList = [];

          selectList.map((column) => {
            if (!columnClickedMap || !columnClickedMap.get(column.uniqeColumnId)) {
              newSelectList.push(column);
            }

            drillDownParentHashMap.get(column.uniqeColumnId) &&
              drillDownParentHashMap.get(column.uniqeColumnId).map((item) => {
                if (!columnClickedMap || !columnClickedMap.get(item.uniqeColumnId)) {
                  newSelectList.push(item);
                }
              });
          });

          selectList = newSelectList;
        }
      }

      let replacedSelectList = [];
      let filterListWithFormula = [];

      selectList.map(column => {
        let tempColumn = clone(column);

        if (typeof tempColumn.value === "string") {
          tempColumn.value = tempColumn.value.replace(/"/g, '`');
        }

        if (typeof tempColumn.Code === "string") {
          tempColumn.Code = tempColumn.Code.replace(/"/g, '`');
        }

        tempColumn.dataSourceKey = checkAndFindDataSourceKeyToColumn(tempColumn.dataSourceKey, tempColumn.tableAliasName, nextProps.model)

        replacedSelectList.push(tempColumn);
      })

      filters.map(column => {
        let tempColumn = clone(column);

        if (typeof tempColumn.Code === "string") {
          tempColumn.Code = tempColumn.Code.replace(/"/g, '`');
        }

        tempColumn.formula = "";
        tempColumn.isFormula = false;

        let columnFullName = '`' + tempColumn.tableAliasName + '`.`' + tempColumn.aliasName + '`';
        let isCodeDifferentFromFullName = tempColumn.Code && columnFullName !== tempColumn.Code;

        if (isCodeDifferentFromFullName) {
          tempColumn.formula = tempColumn.Code;
          tempColumn.isFormula = true;
        }

        tempColumn.dataSourceKey = checkAndFindDataSourceKeyToColumn(tempColumn.dataSourceKey, tempColumn.tableAliasName, nextProps.model)

        filterListWithFormula.push(tempColumn);
      })

      let reduxState = store.getState()
      let allDrillDownsMap = reduxState.DrillDownReducer.drillDowns
      let currentDrillObject = allDrillDownsMap.get(nextProps.plugin.id)
      let category = nextProps.plugin?.sortedColumnList?.filter(col => col.locationFieldName === "category")[0]
      let categoryId = category && category.uniqeColumnId ? category.uniqeColumnId : ""

      let drillColumn = currentDrillObject && currentDrillObject.drillDownColumnsForParentColumns.has(categoryId) ? currentDrillObject.drillDownColumnsForParentColumns.get(categoryId)[0] : null
      let isPluginPieChart = nextProps.plugin.key === "pie-chart-enhanced" || nextProps.plugin.key === "pie-chart" ? true : false
      let isPluginInsideDrilldown = currentDrillObject !== undefined && (currentDrillObject.drillDownTypes === "inside-plugin" || currentDrillObject.drillDownTypes === "explode-pie-chart") ? true : false
      let isPieChartStatusTrue = isPluginPieChart && isPluginInsideDrilldown && drillColumn !== null ? true : false

      if (isPieChartStatusTrue) {
        if (replacedSelectList.filter(col => col.uniqeColumnId === drillColumn.uniqeColumnId).length === 0) {
          drillColumn.dataSourceKey = checkAndFindDataSourceKeyToColumn(drillColumn.dataSourceKey, drillColumn.tableAliasName, nextProps.model);

          replacedSelectList.push(drillColumn)
        }
      }

      let isCachedPlugin = reduxState.CacheModeReducer.savedCachedPluginsMap.has(nextProps.plugin.id) && reduxState.CacheModeReducer.savedCachedPluginsMap.get(nextProps.plugin.id).status
      let cacheOptions = reduxState.CacheModeReducer.savedCachedPluginsMap.has(nextProps.plugin.id) && reduxState.CacheModeReducer.savedCachedPluginsMap.get(nextProps.plugin.id).status ? reduxState.CacheModeReducer.savedCachedPluginsMap.get(nextProps.plugin.id).cacheOptions : {}

      cacheOptions = { ...cacheOptions, status: isCachedPlugin }

      //Get select list formula columns and find tables for join
      let mustJoinTableSet = new Set()

      replacedSelectList.forEach((col, index) => {
        if (col.value) {
          //Find `. end string with regex
          const codePattern = /\b\w+\b(?=\s*`\.)/g;
          const codeMatches = col.value.match(codePattern);

          codeMatches && codeMatches.forEach(match => {
            mustJoinTableSet.add(match);
          });

          replacedSelectList[index] = setSessionVariableValues(col, usedSessionVariables, false);
        }
      });

      let joinStatus = { status: true };

      if (mustJoinTableSet.size > 0) {
        joinStatus = checkJoinList(nextProps.join, mustJoinTableSet)
      }

      if (!joinStatus.status) {
        console.log(joinStatus.message)

        addErrorInPlugin(tempPlugin, joinStatus.message, "getData", "error");
        showNotificationWithIcon(i18n.t("Error"), joinStatus.message, "error");

        renderUpdate(nextProps, tempPlugin);

        if (renderCallBack !== undefined) {
          renderCallBack(false);
        }
      } else {
        const queryObj = createQuery(
          nextProps.model.displayName,
          controlReplacedSelectList(replacedSelectList),
          filterListWithFormula,
          limit,
          offset,
          cacheOptions
        );

        // if excel is big sized only query object neeeded
        if (isExcelExportBigSized) {
          return queryObj
        }

        const successFunc = (response) => {
          let data = mapResults(selectList, response.data);
          let drillDownColumnMap = getDrillDownColumnMap(nextProps.plugin.id, tempPlugin.columnMap)

          data = mapData(drillDownColumnMap ? drillDownColumnMap : tempPlugin.columnMap, data);

          removeErrorFromPlugin(tempPlugin, "getData")

          let reduxState = store.getState();
          let pluginImages = reduxState.DefaultPluginsReducer ? reduxState.DefaultPluginsReducer.pluginsMap : undefined;
          let isRequested = reduxState.DefaultPluginsReducer.isRequested;
          let isPluginImagesNotExists = data.length === 0 && (!pluginImages || pluginImages.size === 0) && isRequested === false;

          if (isPluginImagesNotExists) {
            getPluginImages();
          }

          if (isCompared) {
            nextProps.updatePlugin("data", {
              data: data,
              columnMapForPlugin: mapColumnMap(clone(tempPlugin.columnMap)),
              pluginId: tempPlugin.id,
              isInteraction: isInteraction !== undefined ? isInteraction : tempPlugin.isInteraction,
              drillDownColumnMap: mapColumnMap(clone(drillDownColumnMap)),
              usedSessionVariables: usedSessionVariables
            });

            let isCompared = controlledProps.type === "compared" ? true : false

            if (compareCallback) {
              compareCallback(response.data, queryObj, isCompared, controlledProps, mapColumnMap(clone(tempPlugin.columnMap)))
            }
          } else {
            nextProps.updatePlugin("data", {
              data: data,
              columnMapForPlugin: mapColumnMap(clone(tempPlugin.columnMap)),
              pluginId: tempPlugin.id,
              isInteraction: isInteraction !== undefined ? isInteraction : tempPlugin.isInteraction,
              drillDownColumnMap: mapColumnMap(clone(drillDownColumnMap)),
              usedSessionVariables: usedSessionVariables
            });
          }

          if (renderCallBack !== undefined) {
            renderCallBack(false);
          }
        };

        const errorFunc = (err, erroredQueryObj, pluginErrorHash) => {
          showNotificationWithIcon(i18n.t("Error"), err, "error");

          if (!isCompared) {
            addErrorInPlugin(tempPlugin, err, "getData", "error")
          } else if (comparedErrorCallbackObject) {
            let copiedPluginErrorHash = new Map(pluginErrorHash)

            if (erroredQueryObj.selectColumns[0]) {
              copiedPluginErrorHash.set(`${erroredQueryObj.selectColumns[0].uniqeColumnId}`, err)
            } else {
              copiedPluginErrorHash.set(`empty-columnMap`, err)
            }

            comparedErrorCallbackObject(erroredQueryObj, err)
          }

          renderUpdate(nextProps, tempPlugin);

          if (renderCallBack !== undefined) {
            renderCallBack(false);
          }
        };

        getData(
          queryObj,
          success ? success : successFunc,
          error ? error : (err) => errorFunc(err, queryObj, measureErrorHashMap),
          undefined, //nextProps.cancelTokenSource ? nextProps.cancelTokenSource.token : undefined,
          undefined,
          nextProps.model
        );
      }

    } else {
      // isValidColumnMap is false query object will not be returned
      if (isExcelExportBigSized) {
        return null;
      }

      if (renderCallBack) {
        renderCallBack(false);
      }

      renderUpdate(nextProps, tempPlugin);
    }
  }

  if (sessionVariablesShouldExecuteNow.length) {
    let url = `${API_BASE}/session-variable/variable/value`;

    let successFunc = result => {
      if (result.data) {
        let resultList = Array.from(Object.values(result.data));

        for (let obj of resultList) {
          if (obj.status !== "success") {
            let title = `${i18n.t("SessionVariable")}: ${obj.name}`
            let message = obj.code
              ? i18n.t("SessionVariables.Errors." + obj.code)
              : obj.message
                ? obj.message
                : i18n.t("DataConnections.CouldNotFetch");

            showNotificationWithIcon(title, message, "warning");
          }

          usedSessionVariables.set(obj.name, obj.result);
        }

        return bindDataProcess();
      }
    }

    let errorFunc = error => {
      showNotificationWithIcon(i18n.t("SessionVariables.Title"), error, "error");

      return bindDataProcess();
    }

    return post(url, sessionVariablesShouldExecuteNow, successFunc, errorFunc, false);
  } else {
    return bindDataProcess();
  }
};

/**
 *
 * @param {*} drillDownParentHashMap
 * @param {*} drillDownColumns
 * @returns
 * Adds drillDown columns to the map according to their parent id
 */
const getDrillDownColumnParentMap = (
  drillDownParentHashMap,
  drillDownColumns
) => {
  drillDownColumns.map((column) => {
    let columns = drillDownParentHashMap.get(column.drillDownParentColumnId)
      ? drillDownParentHashMap.get(column.drillDownParentColumnId)
      : [];

    column.orderBy = null;
    columns.push(column);

    drillDownParentHashMap.set(
      column.drillDownParentColumnId,
      columns
    );
  });

  return drillDownParentHashMap;
};

/**
 *
 * @param {*} state
 * @param {*} pluginId
 * @param {*} columnMap
 * @returns
 * Prepares new column map according to added drill down columns
 */
export const getDrillDownColumnMap = (pluginId, columnMap) => {
  let state = store.getState();

  let drilldownColumnMap;
  let drillDownParentHashMap = new Map();

  if (state && state.DrillDownReducer.triggeredDrillDowns.get(pluginId)) {
    let drillDownColumns =
      state.DrillDownReducer.triggeredDrillDowns.get(pluginId).selectedColumns;
    drilldownColumnMap = clone(columnMap);

    drillDownParentHashMap = getDrillDownColumnParentMap(
      drillDownParentHashMap,
      drillDownColumns
    );

    for (const [key, value] of drillDownParentHashMap) {
      let locationFieldName = value[0].locationFieldName;
      let data = [];
      let pluginColumnClickedAndShouldBeRemoved = state.DrillDownReducer.pluginColumnClickedAndShouldBeRemoved
      let columnClickedMap = pluginColumnClickedAndShouldBeRemoved.get(pluginId)

      drilldownColumnMap[locationFieldName].data.map((column) => {
        if (!columnClickedMap || !columnClickedMap.get(column.uniqeColumnId)) {
          data.push(column);
        }

        if (column.uniqeColumnId === key) {
          value.map((item) => {
            if (!columnClickedMap || !columnClickedMap.get(item.uniqeColumnId)) {
              data.push(item);
            }
          });
        }
      });

      drilldownColumnMap[locationFieldName].data = data;
    }

    state.DrillDownReducer.triggeredDrillDowns.get(pluginId).drillDownColumnMap = drilldownColumnMap;

    store.dispatch(setTriggeredDrillDowns(state.DrillDownReducer.triggeredDrillDowns))
  }

  return drilldownColumnMap;
};

const renderUpdate = (nextProps, tempPlugin) => {
  nextProps.updatePlugin("renderUpdate", tempPlugin);
};

const getLimit = (nextProps, isPaginationAndHasOwnLimit) => {
  if (nextProps.plugin.config?.pagination &&
    nextProps.plugin.config?.pagination === true) {
    return parseInt(nextProps.plugin.config.paginationPageSize)
  }

  return isPaginationAndHasOwnLimit ? isPaginationAndHasOwnLimit : nextProps.limit
};

const getOffset = (nextProps) => {
  if (
    nextProps.plugin.config?.pagination &&
    nextProps.plugin.config?.pagination === true
  ) {
    return parseInt(nextProps.plugin.config.pageOffset);
  }

  return 0;
};

/**
 * Checks data type and lsql type and decides to add quoates
 * @param {*} dataType
 * @param {*} filterValue
 * @param {*} column
 */
const decideValueByDataFormat = (dataType, filterValue, column = undefined) => {
  let value = filterValue
  let isDataTypeNumeric = (dataType === "integer" || dataType === "double");
  let isLsqlTypeFormula = column && column.lsql === true && column.lsqlType === "formula";

  if (!isDataTypeNumeric && !isLsqlTypeFormula) {
    value = "'" + filterValue + "'";
  }

  return value;
}

/**
 * Decide filter value and return for query
 * @param {*} filter
 * @param {*} output
 * @returns
 */
const getFilterValueForQuery = (filter, output = undefined) => {
  let filterValue = "("
  if (output === undefined) {
    if (Array.isArray(filter.value) && filter.value.length > 1) {
      filter.value.map((value) => {
        filterValue = filterValue + decideValueByDataFormat(filter.dataType, value, filter) + ",";
      })

      filterValue = filterValue.slice(0, filterValue.length - 1) + ")";

      return filterValue
    } else {
      filterValue = Array.isArray(filter.value) ? filter.value[0] : filter.value;
      filterValue = decideValueByDataFormat(filter.dataType, filterValue, filter)

      return filterValue
    }
  }
  else {
    filter.map(value => {
      const val = value[output];

      filterValue = filterValue + decideValueByDataFormat(filter.dataType, val, filter) + ",";
    });

    filterValue = filterValue.slice(0, filterValue.length - 1) + ")";

    return filterValue
  }
}

/**
 * Decide filter predicate and return for query
 * @param {*} filter
 * @returns
 */
const getFilterPredicateForQuery = (filter, isCustomValue) => {
  let isFilterPredicateMustInOrNotIn = (Array.isArray(filter.value) && filter.value.length > 1)
  let isCustomAndPredicateIn = false
  let isCustomAndPredicateNotIn = false

  if (!isFilterPredicateMustInOrNotIn && isCustomValue) {
    if (typeof filter.value === "string") {
      let isStringValueHasEnter = filter.value.split("\n")
      let filteredValue = isStringValueHasEnter.filter(val => val !== "")

      if (filteredValue.length === 1) {
        if (filter.filterPredicate === "=" || filter.filterPredicate === "in") {
          filter.filterPredicate = "in"
        } else if (filter.filterPredicate === "notIn") {
          filter.filterPredicate = "notIn"
        }
      } else if (filteredValue.length > 1) {
        if (filter.filterPredicate === "=" || filter.filterPredicate === "in") {
          filter.filterPredicate = "="

          isCustomAndPredicateIn = true
        } else if (filter.filterPredicate === "notIn") {
          filter.filterPredicate = "notIn"

          isCustomAndPredicateNotIn = true
        }
      }
    }
  }

  if (isFilterPredicateMustInOrNotIn || isCustomAndPredicateIn || isCustomAndPredicateNotIn) {
    if (filter.filterPredicate === "=") {
      return "in"
    } else if (filter.filterPredicate === "notIn") {
      return "not in"
    }
  } else {
    if (filter.filterPredicate === "in") {
      return "="
    } else if (filter.filterPredicate === "notIn") {
      return "!="
    }
  }

  return filter.filterPredicate;
}

/*
* Adds attributes to columns for plugin filter show component's showed filters.
*/
const addAttributesForPluginFilterShow = (columns, area) => {
  let copiedColumns = [...columns]

  copiedColumns.map(column => {
    column["filterArea"] = area
  })

  return copiedColumns
}

//If column has formula in interaction, use usedColumn
const columnChangeForFormula = (column, pluginInteractionFilter) => {
  let columnKey = column.dataSourceKey + ":" + column.tableAliasName + ":" + column.aliasName

  for (let i = 0; i < pluginInteractionFilter.usedInteraction.columns.length; i++) {
    let useColumn = pluginInteractionFilter.usedInteraction.columns[i]
    let useColumnKey = useColumn.dataSourceKey + ":" + useColumn.tableAliasName + ":" + useColumn.aliasName

    if (useColumnKey === columnKey) {
      let newColumn = deepCopy(column);

      if (useColumn["Code"]) {
        newColumn["Code"] = useColumn["Code"];
      }

      column = deepCopy(newColumn);
      break;
    }
  }

  return column;
}

/**
 * Replaces the session variable identifiers to their values
 *
 * @param {*} data
 * @param {*} sessionVariables
 * @returns
 */
export const setSessionVariableValues = (data, usedSessionVariables, isColumnFilter = false) => {
  let column = deepCopy(data);
  let filterPredicate = column.filterPredicate;
  let isColumnSessionVariableFilter = column.lsql === true && column.lsqlType === "session-variable";
  let reduxState = store.getState();
  let sessionVariables = reduxState.SessionVariableReducer.sessionVariables;
  let sessionVariableMatches = new Set(`${column.lsqlValue} ${column.value} ${column.Code}`.match(sessionVariableMatchRule) || []);

  /**
   * Evaluates the value
   *
   * @param {*} value 
   * @returns 
   */
  function evalValue(value) {
    try {
      return evaluate(value);
    } catch (e) {
      return value;
    }
  };

  sessionVariableMatches.forEach(match => {
    let name = match.replace(sessionVariableNameMatchRule, (m, p1) => p1);
    let variable = sessionVariables.get(name);

    if (variable) {
      let value = usedSessionVariables?.get(variable.name) || variable.defaultValue;
      let isValueArray = value instanceof Array;
      let isValueMultiple = isValueArray && value.length > 1;

      value = isValueArray && !isValueMultiple ? value[0] : value;

      if (value !== undefined) {
        let lastValue = value;

        switch (variable.dataType) {
          case "text":
            value = isValueMultiple
              ? value.map(v => v === null ? "''" : v)
              : value === null ? "''" : value;

            break;

          case "date":
            let format = "YYYY-MM-DD";

            value = isValueMultiple
              ? value.map(v => v !== null && `'${moment(v).format(format)}'`)
              : value === null ? "NULL" : `'${moment(value).format(format)}'`;

            break;

          case "number":
            let isValueNaN = (isValueMultiple && value.some(v => isNaN(v) === true && v !== null)) || (!isValueMultiple && isNaN(value) && value !== null);

            if (isValueNaN) {
              let title = `${i18n.t("SessionVariable")}: ${variable.name}`;
              let message = i18n.t("SessionVariables.TypeMismatch")
                .replace("[column]", column.displayName || column.aliasName || column.name)
                .replace("[sessionVariable]", variable.name);

              showNotificationWithIcon(title, message, "warning");
            }

            if (isValueMultiple) {
              value = value.filter(v => v !== null);
              value = value.map(v => isNaN(v) ? v : Number(evalValue(v)));
            } else {
              value = value === null ? "NULL" : isNaN(value) ? value : Number(evalValue(value));
            }

            break;
          default:
            break;
        }

        if (value && isValueMultiple) {
          if (isColumnSessionVariableFilter) {
            lastValue = value;

            if (filterPredicate === "=") {
              column.filterPredicate = "in";
            } else if (filterPredicate === "!=" || filterPredicate === "notIn") {
              column.filterPredicate = "not in";
            }
          }

          value = "(" + value?.join(",") + ")";
        } else {
          if (isColumnSessionVariableFilter) {
            lastValue = value;

            if (filterPredicate === "in") {
              column.filterPredicate = "=";
            } else if (filterPredicate === "not in" || filterPredicate === "notIn") {
              column.filterPredicate = "!=";
            }
          }
        }

        if (isColumnFilter === false) {
          column.value = column.value?.replaceAll(match, value);
        } else if (isColumnSessionVariableFilter === true) {
          column.value = column.value?.replaceAll(match, value);
          column.lastValue = lastValue;
        }

        column.Code = column.Code?.replaceAll(match, value);
      }
    }
  });

  return column;
}

/**
 * Prepares filter list
 *
 * @param {*} nextProps
 * @param {*} defaultFilters
 * @param {*} sessionVariables
 * @returns
 */
const getFilters = (nextProps, tempPlugin, sessionVariables, renderCallBack) => {
  let defaultFilters = tempPlugin.defaultFilters
  let filters = [];
  let copiedFiltersForPluginFilterShow = []

  let state;
  state = store.getState();

  let interactionColumns = new Set();
  let defaultFiltersMustBeIgnored = new Set();
  let affectedDefaultFilters = new Set();

  // For Default Filters
  if (defaultFilters !== undefined) {
    let validDefaultFilters = []

    defaultFiltersMustBeIgnored.forEach(defaultFilterId => {
      let filter = defaultFilters.find(filter => filter.filterId === defaultFilterId)

      if (typeof filter === "undefined" || filter.protected === true) {
        defaultFiltersMustBeIgnored.delete(defaultFilterId)
      }
    });

    defaultFilters = defaultFilters.filter(filter => !defaultFiltersMustBeIgnored.has(filter.filterId));
    defaultFilters = defaultFilters.filter(filter => (!interactionColumns.has(filter.aliasName) || filter.compared) || affectedDefaultFilters.has(filter.filterId));

    let protedtedDefaultFilters = defaultFilters.filter(filter => filter.protected === true)
    let unProtedtedDefaultFilters = defaultFilters.filter(filter => filter.protected !== true)

    copiedFiltersForPluginFilterShow = copiedFiltersForPluginFilterShow.concat(addAttributesForPluginFilterShow(unProtedtedDefaultFilters, "DefaultFilters"))
    copiedFiltersForPluginFilterShow = copiedFiltersForPluginFilterShow.concat(addAttributesForPluginFilterShow(protedtedDefaultFilters, "ProtectedDefaultFilters"))

    defaultFilters.map((filter) => {
      const isFilterValueExist = filter.lsql === true
        ? filter.lsqlValue !== undefined && filter.lsqlValue !== null && filter.lsqlValue !== ""
        : filter.value !== undefined && filter.value !== null && filter.value !== "";
      const isFilterValueNotArray = !Array.isArray(filter.value);
      const isFilterValueArrayCheck = (Array.isArray(filter.value) && filter.value.length > 0)
      const isFilterPredicateIsNullOrIsNotNull = filter.filterPredicate === "is null" || filter.filterPredicate === "is not null"
      const isFilterValueValid = isFilterValueNotArray || isFilterValueArrayCheck;

      let tempFilter = setSessionVariableValues(filter, sessionVariables, true);
      
      filter.lastValue = tempFilter.lastValue;

      if (isFilterValueExist && isFilterPredicateIsNullOrIsNotNull === false) {
        if (isFilterValueValid) {
          if (tempFilter.lsqlType !== "session-variable" || isFilterPredicateIsNullOrIsNotNull === true) {
            let isLsqlAndGetCustomPredicate = false
            let isCustomValue = typeof tempFilter.lsqlValue === "string" && tempFilter.lsqlValue.split("\n").length > 1 && tempFilter.lsqlType === "value"

            if (isFilterPredicateIsNullOrIsNotNull) {
              tempFilter.value = "";
            } else if (isCustomValue) {
              let splittedValue = tempFilter.lsqlValue.split("\n").filter(val => val !== "")

              tempFilter.value = splittedValue
              tempFilter.value = getFilterValueForQuery(tempFilter);

              isLsqlAndGetCustomPredicate = true
            } else {
              tempFilter.value = getFilterValueForQuery(filter);
            }

            tempFilter.filterPredicate = getFilterPredicateForQuery(filter, isLsqlAndGetCustomPredicate)
          }

          if (tempFilter.protected === true) {
            validDefaultFilters.push(tempFilter)
          } else {
            if (isValidDefaultFilter(tempFilter, filters) === true || tempFilter.compared) {
              validDefaultFilters.push(tempFilter)
            }
          }
        }
      } else if (isFilterPredicateIsNullOrIsNotNull) {
        tempFilter.value = "";
        tempFilter.lastValue = [`${filter.filterPredicate}`]

        validDefaultFilters.push(tempFilter)
      }
    });

    filters = filters.concat(validDefaultFilters);
  }

  // For Navigation Filters
  if (
    state &&
    state.NavigationReducer.navigation.dashboardInformation &&
    state.NavigationReducer.navigation.dashboardInformation.filters
  ) {
    filters = filters.concat(
      state.NavigationReducer.navigation.dashboardInformation.filters
    );

    copiedFiltersForPluginFilterShow = copiedFiltersForPluginFilterShow.concat(addAttributesForPluginFilterShow(state.NavigationReducer.navigation.dashboardInformation.filters, "Navigation"))
  }

  // For Drilldown Filters
  if (state && state.DrillDownReducer.triggeredDrillDowns.get(nextProps.plugin.id)) {
    let drillDownFilters = state.DrillDownReducer.triggeredDrillDowns.get(nextProps.plugin.id).filters
    let drilldowns = state.DrillDownReducer.drillDowns.get(nextProps.plugin.id)
    let isDrilldownInsidePlugin = drilldowns.drillDownTypes && (drilldowns.drillDownTypes === "inside-plugin" || drilldowns.drillDownTypes === "explode-pie-chart") ? true : false

    if (!isDrilldownInsidePlugin) {
      let preserveDefaultFilters = true;
      let currentDrillDownColumn = drillDownFilters[drillDownFilters.length - 1];

      if (drilldowns.preserveDefaultFilters instanceof Map) {
        if (drilldowns.preserveDefaultFilters.has(currentDrillDownColumn.uniqeColumnId)) {
          preserveDefaultFilters = drilldowns.preserveDefaultFilters.get(currentDrillDownColumn.uniqeColumnId)
        } else {
          for (let [parentColumnId, drillDownColumns] of drilldowns.drillDownColumnsForParentColumns) {
            if (drillDownColumns.filter(column => column.uniqeColumnId === currentDrillDownColumn.uniqeColumnId).length > 0) {
              preserveDefaultFilters = drilldowns.preserveDefaultFilters.get(parentColumnId)

              break;
            }
          }
        }
      }

      if (defaultFilters?.length > 0 && preserveDefaultFilters === false) {
        defaultFilters = defaultFilters.filter(filter => filter.protected === true)
      }

      copiedFiltersForPluginFilterShow = copiedFiltersForPluginFilterShow.concat(addAttributesForPluginFilterShow(drillDownFilters, "Drilldown"))
      filters = filters.concat(drillDownFilters)
    }
  }

  //For Interaction Filters
  if (
    state &&
    state.PluginTriggerReducer.pluginInteractionFilters.has(nextProps.plugin.id)
  ) {
    state.PluginTriggerReducer.pluginInteractionFilters
      .get(nextProps.plugin.id)
      .map((pluginInteractionFilter) => {
        // TODO
        // Burası çok önemli
        // Bazı durumlarda, plugin eklendiğinde ya da silindiğinde ya da duplicate edildiğinde, interaction filtrelerinde targetId veya sourceId'ler karışmaktadır.
        // Burası ayrıca incelenecektir. Auto-Interaction kaynaklı olduğu, ve yeni plugin eklendiği senaryoda çalışmadığı görülmektedir.
        // İncelenmesi gerekir. - Oğulcan E.
        if (state.FilterAutoInteractionReducer.filterAutoInteractions.has(pluginInteractionFilter.usedInteraction.sourceId)) {
          let autoInteractionObject = state.FilterAutoInteractionReducer.filterAutoInteractions.get(pluginInteractionFilter.usedInteraction.sourceId)
          let filteredInteractionObject = autoInteractionObject.find(autoInteraction => autoInteraction.targetId === nextProps.plugin.id)

          for (let i = 0; i < filteredInteractionObject?.ignoredDefaultFilters?.length; i++) {
            defaultFiltersMustBeIgnored.add(filteredInteractionObject.ignoredDefaultFilters[i])
          }

          for (let i = 0; i < filteredInteractionObject?.affectedDefaultFilters?.length; i++) {
            affectedDefaultFilters.add(filteredInteractionObject.affectedDefaultFilters[i])
          }
        } else {
          if (Array.isArray(pluginInteractionFilter.usedInteraction.ignoredDefaultFilters)) {
            pluginInteractionFilter.usedInteraction.ignoredDefaultFilters.forEach(defaultFiltersMustBeIgnored.add, defaultFiltersMustBeIgnored);
          }

          if (Array.isArray(pluginInteractionFilter.usedInteraction.affectedDefaultFilters)) {
            pluginInteractionFilter.usedInteraction.affectedDefaultFilters.forEach(affectedDefaultFilters.add, affectedDefaultFilters);
          }
        }

        for (let column of pluginInteractionFilter.usedInteraction.columns) {
          interactionColumns.add(column.aliasName);
        }

        let outputList = pluginInteractionFilter.usedInteraction.actions.filter(action =>
          action.trigger === pluginInteractionFilter.event
        )[0].output;

        let hashSetSelectedColumnsIds = new Set();

        for (let i = 0; i < pluginInteractionFilter.usedInteraction.columns.length; i++) {
          hashSetSelectedColumnsIds.add(pluginInteractionFilter.usedInteraction.columns[i].uniqeColumnId);
        }

        /** actions output list iterate for apply all filters. */
        outputList.map(output => {
          let filterValue;
          let column = deepCopy(pluginInteractionFilter.columnMap[output]);

          /** Set filter value object or array of value by length */
          if (pluginInteractionFilter.value.length === 1) {
            if (pluginInteractionFilter?.plugin?.key !== "map-choropleth") {
              filterValue = pluginInteractionFilter.value[0][output];
            } else {
              filterValue = pluginInteractionFilter.value[0][output].filter(value => pluginInteractionFilter.value[0][value.name] !== "notCurrentTopojson" && pluginInteractionFilter.value[0][value.name] !== "\"notCurrentCode\"");
            }
          } else {
            filterValue = pluginInteractionFilter.value;
          }

          const isFilterValueExist = filterValue !== undefined && filterValue !== null && (filterValue.length > 0 || typeof (filterValue) === "number");

          if (isFilterValueExist) {
            if (Array.isArray(column)) {
              column.map((columnItem, index) => {
                let plugin = state.PluginTriggerReducer.plugins.get(pluginInteractionFilter.pluginId)
                let columns = getPluginsAllColumnsByField(plugin.columnMap)
                let column = columns.find(col => col.uniqeColumnId === columnItem.uniqeColumnId);

                if (column && (column.Code === undefined || column.Code === "")) {
                  column.Code = `"${column.tableAliasName}"."${column.aliasName}"`
                }

                columnItem = column ? deepCopy(columnChangeForFormula(column, pluginInteractionFilter)) : deepCopy(columnChangeForFormula(columnItem, pluginInteractionFilter));
                columnItem = setSessionVariableValues(columnItem, plugin.usedSessionVariables, true);

                let filterVal = filterValue[index] && filterValue[index]?.value
                  ? filterValue[index].value
                  : (filterValue[index]?.length === 1 && typeof filterValue[index] === "string" && filterValue[index] !== "") || typeof filterValue === "number"
                    ? filterValue
                    : ""
                let filterAndCopiedFilterObject = validateAndAddColumnToFilters(columnItem, hashSetSelectedColumnsIds, filterVal, filters, output, pluginInteractionFilter.filterOperator);

                filters = filterAndCopiedFilterObject.filters
                copiedFiltersForPluginFilterShow = copiedFiltersForPluginFilterShow.concat(addAttributesForPluginFilterShow(filterAndCopiedFilterObject.onlyInteractionFilters, "Interaction"))
              })
            } else {
              let plugin = state.PluginTriggerReducer.plugins.get(pluginInteractionFilter.pluginId)
              let columns = getPluginsAllColumnsByField(plugin?.columnMap)
              let newColumn = columns.find(col => col.uniqeColumnId === column.uniqeColumnId)

              if (newColumn && (newColumn.Code === undefined || newColumn.Code === "")) {
                newColumn.Code = `"${column.tableAliasName}"."${column.aliasName}"`
              }

              column = newColumn ? deepCopy(columnChangeForFormula(newColumn, pluginInteractionFilter)) : deepCopy(columnChangeForFormula(column, pluginInteractionFilter));
              column = setSessionVariableValues(column, plugin.usedSessionVariables, true);

              let filterAndCopiedFilterObject = validateAndAddColumnToFilters(column, hashSetSelectedColumnsIds, filterValue, filters, output, pluginInteractionFilter.filterOperator)

              filters = filterAndCopiedFilterObject.filters
              copiedFiltersForPluginFilterShow = copiedFiltersForPluginFilterShow.concat(addAttributesForPluginFilterShow(filterAndCopiedFilterObject.onlyInteractionFilters, "Interaction"))
            }
          }
        })
      });
  }

  filters = filters.filter(filter => !defaultFiltersMustBeIgnored.has(filter.filterId))
  copiedFiltersForPluginFilterShow = copiedFiltersForPluginFilterShow.filter(filter => !defaultFiltersMustBeIgnored.has(filter.filterId))

  defaultFiltersMustBeIgnored = new Set()
  affectedDefaultFilters = new Set()

  state.PluginTriggerReducer.allPluginsAllFilters.set(nextProps.plugin.id, copiedFiltersForPluginFilterShow)

  return filters;
};

/**
 *
 * @param {*} defaultFilter
 * @param {*} filters
 * @returns
 *
 * If protected = false, it checks whether there is another filter related to
 * the relevant column from interaction or navigation,
 * and if it is, it returns false
 */
const isValidDefaultFilter = (defaultFilter, filters) => {
  for (let filter in filters) {
    if (
      filters[filter].tableAliasName === defaultFilter.tableAliasName &&
      filters[filter].aliasName === defaultFilter.aliasName
    ) {
      return false;
    }
  }

  return true;
};

const isNotValidFilterValue = (filterValue) => {
  return filterValue === undefined || filterValue === null || (typeof (filterValue) !== "number" && Object.keys(filterValue).length === 0)
}

/*
* Pushes filter column's filter object values a array
*/
const prepareFilterValues = (filters) => {
  let filterArray = []

  for (let i = 0; i < filters.length; i++) {
    filterArray.push(filters[i].filter)
  }

  return filterArray
}

/** Validation and push filters to query object */
const validateAndAddColumnToFilters = (column, hashSetSelectedColumnsIds, filterValue, filters, output, filterOperator = null) => {
  let onlyInteractionFilters = []

  if (isNotValidFilterValue(filterValue) && !["is null", "is not null"].includes(filterOperator)) {
    return {
      filters: filters,
      onlyInteractionFilters: onlyInteractionFilters
    };
  }

  let isFilterWillPush = false;
  let betweenValues = []; // selected between for filter operator

  /**
   * If value has multiple values use "in" predicate
   * Else validate and use equals predicate.
   */
  if (Array.isArray(filterValue) && filterOperator !== "between") {
    if (column.uniqeColumnId && hashSetSelectedColumnsIds.has(column.uniqeColumnId)) {
      column.value = getFilterValueForQuery(filterValue, output)
      column["lastValue"] = prepareFilterValues(filterValue)
      column.filterPredicate = "in";

      isFilterWillPush = true;
    }
  } else if (Array.isArray(filterValue) && filterOperator === "between") {
    if (column.uniqeColumnId && hashSetSelectedColumnsIds.has(column.uniqeColumnId)) {
      if (column.Code && column.Code !== "") {
        let columnGreaterThan = { ...column }
        let columnLessThan = { ...column }

        columnGreaterThan.value = decideValueByDataFormat(columnGreaterThan.dataType, filterValue[0].filter, columnGreaterThan);
        columnGreaterThan.filterPredicate = ">=";

        betweenValues.push(columnGreaterThan)

        columnLessThan.value = decideValueByDataFormat(columnLessThan.dataType, filterValue[1].filter, columnLessThan);
        columnLessThan.filterPredicate = "<=";

        betweenValues.push(columnLessThan)

        isFilterWillPush = true;
      }
    }
  } else {
    if (column.uniqeColumnId && hashSetSelectedColumnsIds.has(column.uniqeColumnId)) {
      if (column.Code && column.Code !== "") {
        column.value = decideValueByDataFormat(column.dataType, filterValue, column);
        column["lastValue"] = [filterValue]
        column.filterPredicate = "=";
        isFilterWillPush = true;
      } else {
        // I'm not sure this can be like this - Oğulcan
        column.value = decideValueByDataFormat(column.dataType, filterValue, column);
        column["lastValue"] = [filterValue]
        column.filterPredicate = "=";
        isFilterWillPush = true;
      }
    }
  }

  if (filterOperator !== null && filterOperator !== "between") {
    column.filterPredicate = filterOperator

    if (["is null", "is not null"].includes(filterOperator)) {
      column.value = ""
    }
  }

  if (!column.remove && isFilterWillPush === true) {
    if (betweenValues.length > 0) {
      filters = filters.concat(betweenValues);

      onlyInteractionFilters = betweenValues
    } else {
      filters.push(column);

      onlyInteractionFilters = [column]
    }
  }

  return {
    filters: filters,
    onlyInteractionFilters: onlyInteractionFilters
  }
}

const hasColumnInField = (getColumnMappingField) => {
  if (getColumnMappingField === undefined) {
    return false;
  }

  if (getColumnMappingField.data) {
    return getColumnMappingField.data.length > 0;
  }

  if (getColumnMappingField.tableAliasName) {
    return true;
  }

  return false;
};

const createQuery = (modelDisplayName, select, filter, limit, offset, cacheOptions) => {
  return {
    modelDisplayName: modelDisplayName,
    selectColumns: select,
    filterColumns: filter,
    limit: limit,
    offset: offset,
    queryCache: cacheOptions
  };
};

export const mapResults = (columnMap, outputData) => {
  if (typeof outputData === "undefined") {
    outputData = [];
  } else {
    for (var i = 0; i < outputData.length; i++) {
      // eslint-disable-next-line no-loop-func
      columnMap.forEach(function (criterium, j) {
        var key = j + 1;
        criterium.format = format; //For add format method to columns
        let renameValue = criterium.displayName;

        if (typeof outputData[i] === "object" && key in outputData[i]) {
          outputData[i] = renameProperty(outputData[i], key, renameValue);
        } else {
          outputData[i][key] = null;
        }
      });
    }
  }
  return outputData;
};

export const mapData = (columnMap, data) => {
  for (let i = 0; i < data.length; i++) {
    for (let prop in columnMap) {
      let columnData = columnMap[prop].data;
      if (columnMap[prop].multiple === true) {
        // For grouped attributes, transform to sub-array
        let valueArray = [];
        for (var j = 0; j < columnData.length; j++) {
          let value = {
            name: columnData[j].displayName,
            value: data[i][columnData[j].displayName],
          };

          if ($.inArray(columnData[j].dataType, ["integer", "double"]) > -1) {
            value.value = value.value ? +value.value : data[i][prop];
          }

          if (columnMap[prop].data[j].isDisabledColumn !== true) {
            valueArray.push(value);
          }
        }
        data[i][prop] = valueArray;
      } else {
        columnData = columnData[0];

        if (columnData && columnData.hasOwnProperty("displayName")) {
          data[i] = renameProperty(data[i], columnData.displayName, prop);
        }

        if (columnData && columnData.isDisabledColumn !== true) {
          if (
            columnData &&
            ($.inArray(columnData.dataType, ["integer", "double"]) > -1)
          ) {
            data[i][prop] = data[i][prop] && !isNaN(+data[i][prop]) ? +data[i][prop] : data[i][prop];
          }
        }
      }
    }
  }

  return data;
};

const mapColumnMap = (columnMap) => {
  for (const key in columnMap) {
    if (columnMap[key] !== undefined) {
      if (columnMap[key].multiple !== true) {
        if (columnMap[key].data.length > 0) {
          if (columnMap[key]["dataField"]) {
            columnMap[key].data[0] = {
              ...columnMap[key].data[0],
              ...columnMap[key]["dataField"],
            };
          }
          columnMap[key] = columnMap[key].data[0];
          columnMap[key].Measure =
            columnMap[key].aggrRule ? columnMap[key].aggrRule : "none";
          columnMap[key].Locale = Cookies.get("i18next").toUpperCase();
          columnMap[key].DataType = columnMap[key].dataType;
          columnMap[key].Name = columnMap[key].displayName;
          columnMap[key].Code = '"' + columnMap[key].tableAliasName + '"."' + columnMap[key].aliasName + '"';
          columnMap[key].Type = "Column";
          if (columnMap[key].DataFormat === undefined) {
            let dataFormat = getDefaultFormatType(columnMap[key]).dataFormat;
            columnMap[key].DataFormat = dataFormat;
          }
        }
      } else {
        columnMap[key] = columnMap[key].data.map((d) => {
          if (columnMap[key]["dataField"]) {
            d = {
              ...d,
              ...columnMap[key]["dataField"]
            };
          }

          d.Measure = d.aggrRule ? d.aggrRule : "none";
          d.Locale = Cookies.get("i18next").toUpperCase();
          d.DataType = d.dataType;
          d.Name = d.displayName;
          d.Config = {
            Plugin: "pivot-table",
            aggregator: "Sum",
            hide: false,
          };
          d.Code = '"' + d.tableAliasName + '"."' + d.aliasName + '"';
          d.Type = "Column";

          if (d.DataFormat === undefined) {
            let dataFormat = getDefaultFormatType(d).dataFormat;
            d.DataFormat = dataFormat;
          }

          return d;
        });
      }
    }
  }

  return columnMap;
};

let getFormulaColumns = (formula) => {
  if (!formula) {
    return
  }

  // eslint-disable-next-line no-useless-escape
  const regex = /\`([^`]+)\`\.\`([^`]+)\`/g;

  let match;
  let columnArray = []

  while ((match = regex.exec(formula)) !== null) {
    columnArray.push("`" + match[1] + "`.`" + match[2] + "`")
  }

  return columnArray
}

/*
* Finds column value
*/
let findColumnValue = (column, newColumnList) => {
  let columnKey = column.tableAliasName + "." + column.aliasName

  let columnValue = newColumnList.find(col => col.key === columnKey)

  column.columnDefaultFormula = columnValue?.value ? columnValue.value : ""

  return columnValue?.value ? columnValue.value : ""
}

let hasFormulaColumnHasNewColumn = (column, code, newColumnList, comesFromFilter = false, counterForRecursive, errorStatus) => {
  counterForRecursive++

  if (counterForRecursive > 100) {
    column["isInfiniteRecursive"] = true

    return column
  }

  let isColumnHasNewColumnFormula = !comesFromFilter
    ? code
      ? getFormulaColumns(code)
      : column.columnDefaultFormula
        ? getFormulaColumns(column.columnDefaultFormula.replaceAll(/"/g, '`'))
        : undefined
    : column.Code
      ? getFormulaColumns(column.Code)
      : findColumnValue(column, newColumnList)
        ? getFormulaColumns(findColumnValue(column, newColumnList).replaceAll(/"/g, '`'))
        : getFormulaColumns(findColumnValue(column, newColumnList))

  let flag = true

  if (isColumnHasNewColumnFormula && isColumnHasNewColumnFormula.length) {
    for (let i = 0; i < isColumnHasNewColumnFormula.length; i++) {
      let findedColumn = newColumnList?.find(col => col.key === isColumnHasNewColumnFormula[i] || isColumnHasNewColumnFormula[i] === "`" + col.key.split(".")[0] + "`.`" + col.key.split(".")[1] + "`")

      if (findedColumn) {
        let gettedColumnFormula = findedColumn.value
        let splittedFormula = isColumnHasNewColumnFormula[i].split(".")

        code = code.replaceAll('`' + splittedFormula[0] + '`.`' + splittedFormula[1] + '`', gettedColumnFormula)
        code = code.replaceAll(isColumnHasNewColumnFormula[i], gettedColumnFormula)
        code = code.replaceAll(/"/g, '`')

        if (!comesFromFilter) {
          column.Code = code
          column.value = code

          flag = false

          hasFormulaColumnHasNewColumn(column, code, newColumnList, false, counterForRecursive, errorStatus)
        } else if (comesFromFilter) {
          column.formula = code
          column.Code = code

          flag = false

          hasFormulaColumnHasNewColumn(column, code, newColumnList, true, counterForRecursive, errorStatus)
        }

      } if (i + 1 === isColumnHasNewColumnFormula.length && flag) {
        column.Code = code

        if (comesFromFilter && (column.formula === "" || column.formula === undefined) && column.columnDefaultFormula) {
          flag = false

          column.formula = column.columnDefaultFormula.replaceAll(/"/g, '`')
          column.Code = column.columnDefaultFormula.replaceAll(/"/g, '`')
        }

        if (!comesFromFilter) column.value = code
      }
    }

    if (flag) {
      column.Code = code

      if (!comesFromFilter) column.value = code
    }
  }

  return column
}

// Is column a newColumn, replaces code to newColumn value
export const checkTableIsNewColumnOrHasNewColumnFormula = (columnList, newColumnList, comesFromFilter = false) => {
  let counterForRecursive = 0

  for (let i = 0; i < columnList.length; i++) {
    let column = columnList[i]
    let columnKey = '`' + column.tableAliasName + '`.`' + column.aliasName + '`'
    let isColumnNewColumn = newColumnList?.find(col => col.key === columnKey || col.key === column.tableAliasName + '.' + column.aliasName)
    let columnValue = !comesFromFilter ?
      column.Code
        ? column.Code
        : '`' + column.tableAliasName + '`.`' + column.aliasName + '`'
          ? '`' + column.tableAliasName + '`.`' + column.aliasName + '`'
          : undefined
      : column.Code
        ? column.Code
        : '`' + column.tableAliasName + '`.`' + column.aliasName + '`'

    let newColumn = hasFormulaColumnHasNewColumn(column, columnValue, newColumnList, comesFromFilter, counterForRecursive)

    if (isColumnNewColumn) {
      if (!comesFromFilter) {
        column.value = newColumn.value
        column.Code = newColumn.value
      } else {
        let value = newColumnList?.find(col => col.key === columnKey) ? newColumnList?.find(col => col.key === columnKey).value.replaceAll(/"/g, '`') : column.columnDefaultFormula.replaceAll(/"/g, '`')

        if (column.Code !== value) {
          value = column.Code
        }

        column.formula = value
        column.Code = value
        column.isFormula = true

        column.value = newColumn.value
      }
    }
  }

  return columnList
}

// Loops selected and filtered columns and sets final what if value
const setColumnValuesForWhatIf = (columnList, whatIf) => {
  for (let i = 0; i < columnList.length; i++) {
    columnList[i].value = getColumnValueForWhatIf(columnList[i], whatIf)
  }

  return columnList
}

// Checks is what if scenerio available and if available replaces column value
const checkWhatIfSceneriosInNewColumn = (queryObj) => {
  let reduxState = store.getState()
  let whatIf = reduxState.WhatIfReducer.whatIf

  queryObj.selectColumns = setColumnValuesForWhatIf(queryObj.selectColumns, whatIf)
  queryObj.filterColumns = setColumnValuesForWhatIf(queryObj.filterColumns, whatIf)

  return queryObj
}

// Checks has column is the new column
const checkColumnsHasNewColumn = (queryObj, model) => {
  let copiedQueryObj = queryObj
  let reduxState = store.getState()

  let newColumnList = reduxState.NewColumnEditorReducer.contentObject.newColumnList
  let selectColumns = checkTableIsNewColumnOrHasNewColumnFormula(queryObj.selectColumns, newColumnList, false)
  let filterColumns = checkTableIsNewColumnOrHasNewColumnFormula(queryObj.filterColumns, newColumnList, true)

  copiedQueryObj["selectColumns"] = selectColumns
  copiedQueryObj["filterColumns"] = filterColumns

  return copiedQueryObj
}

const getData = (queryObj, successFunc, errorFunc, cancelToken = undefined, preventStopLoading = false, model) => {

  let url = QUERY_URL + "/v3";

  let newColumnQueryObj = checkColumnsHasNewColumn(queryObj, model)
  let whatIfQueryObj = checkWhatIfSceneriosInNewColumn(newColumnQueryObj)

  let hasSelectColumnsHasInfiniteRecursive = whatIfQueryObj.selectColumns.filter(column => column.isInfiniteRecursive === true).length > 0 ? true : false
  let hasFilterColumnsHasInfiniteRecursive = whatIfQueryObj.filterColumns.filter(column => column.isInfiniteRecursive === true).length > 0 ? true : false

  if (!hasSelectColumnsHasInfiniteRecursive && !hasFilterColumnsHasInfiniteRecursive) {
    post(url, whatIfQueryObj, successFunc, errorFunc, false, false, undefined, true, cancelToken, preventStopLoading);
  } else {
    showNotificationWithIcon(i18n.t("NewColumn.InfiniteRecursiveError"), null, "warning")
  }
}


const renameProperty = (obj, oldName, newName) => {
  // Do nothing if the names are the same
  if (oldName === newName) {
    return obj;
  }
  // Check for the old property name to avoid a ReferenceError in strict mode.
  if (obj.hasOwnProperty(oldName)) {
    obj[newName] = obj[oldName];
    delete obj[oldName];
  }
  return obj;
};
