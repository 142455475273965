import React, { Component } from "react";
import MeasureTile from "../ui/Plugins/PluginComponents/MeasureTile/MeasureTile";
import Table from "../ui/Plugins/PluginComponents/Table/Table";
import Title from "../ui/Plugins/PluginComponents/Title/Title";
import VegaLitePlugin from "../ui/Plugins/PluginComponents/VegaLite/VegaLitePlugin";
import BarChart from "../ui/Plugins/PluginComponents/BarChart/BarChart";
import Flag from "../ui/Plugins/PluginComponents/Flag/Flag";
import Sunburst from "../ui/Plugins/PluginComponents/Sunburst/Sunburst";
import MapBubbles from "../ui/Plugins/PluginComponents/MapBubbles/MapBubbles";
import MultiAxisLineChart from "../ui/Plugins/PluginComponents/MultiAxisLineChart/MultiAxisLineChart";
import MapChoropleth from "../ui/Plugins/PluginComponents/MapChoropleth/MapChoropleth";
import MapHeatmap from "../ui/Plugins/PluginComponents/MapHeatmap/MapHeatmap";
import Radar from "../ui/Plugins/PluginComponents/Radar/Radar";
import ChordDiagram from "../ui/Plugins/PluginComponents/ChordDiagram/ChordDiagram";
import Image from "../ui/Plugins/PluginComponents/Image/Image";
import Sankey from "../ui/Plugins/PluginComponents/Sankey/Sankey";
import TreeMap from "../ui/Plugins/PluginComponents/TreeMap/TreeMap";
import WordCloud from "../ui/Plugins/PluginComponents/WordCloud/WordCloud";
import TurkeyMap from "../ui/Plugins/PluginComponents/TurkeyMap/TurkeyMap";
import LiquidGauge from "../ui/Plugins/PluginComponents/LiquidGauge/LiquidGauge";
import CylinderBar from "../ui/Plugins/PluginComponents/CylinderBar/CylinderBar";
import LineBar from "../ui/Plugins/PluginComponents/LineBar/LineBar";
import LineBarChart from "../ui/Plugins/PluginComponents/LineBarChart/LineBarChart";
import TurkishDistrictMap from "../ui/Plugins/PluginComponents/TurkishDistrictMap/TurkishDistrictMap";
import WorldMap from "../ui/Plugins/PluginComponents/WorldMap/WorldMap";
import IFrame from "../ui/Plugins/PluginComponents/IFrame/IFrame";
import RadialProgress from "../ui/Plugins/PluginComponents/RadialProgress/RadialProgress";
import RouteMap2 from "../ui/Plugins/PluginComponents/RouteMap2/RouteMap2";
import RouteMap from "../ui/Plugins/PluginComponents/RouteMap/RouteMap";
import GlassedTitle from "../ui/Plugins/PluginComponents/GlassedTitle/GlassedTitle";
import MapCluster from "../ui/Plugins/PluginComponents/MapCluster/MapCluster";
import ScatterChart from "../ui/Plugins/PluginComponents/ScatterChart/ScatterChart";
import MindGapper from "../ui/Plugins/PluginComponents/MindGapper/MindGapper";
import FilterFactory from "../ui/Plugins/PluginComponents/Filter/FilterFactory";
import PivotTableBase from "../ui/Plugins/PluginComponents/PivotTable/PivotTableBase";
import PieChartEnhanced from "../ui/Plugins/PluginComponents/PieChartEnhanced/PieChartEnhanced"
import ButtonFilter from "../ui/Plugins/PluginComponents/ButtonFilter/ButtonFilter";
import EnhancedFilter from "../ui/Plugins/PluginComponents/EnhancedFilter/EnhancedFilter";
import TimelineChart from "../ui/Plugins/PluginComponents/TimelineChart/TimelineChart";
import NetworkGraph from "../ui/Plugins/PluginComponents/NetworkGraph/NetworkGraph";
import GaugeChart from "../ui/Plugins/PluginComponents/GuageChart/GaugeChart";
import AgePyramid from "../ui/Plugins/PluginComponents/AgePyramidEnhanced/AgePyramid";

export const pluginSelector = (
  plugin,
  model,
  interactions,
  navigations,
  rerender,
  setPluginRerender,
  updatePlugin,
  settings,
  updateDefaultFilterForPlugin,
  updateConfig,
  config,
  configVisibility,
  changeConfigVisibility,
  dataVisibility,
  changeDataVisibility,
  getData,
  join, 
  addJoinToJoinList, 
  joinErrorVisibility, 
  changeJoinErrorVisibility, 
  clickedRefresh, 
  setClickedRefresh, 
  hasNotJoinedData, 
  changeHasNotJoinedData, 
  refreshedPluginId, 
  changeRefreshedPluginId,
  conditionalFormattingVisibility,
  changeConditionalFormattingVisibility,
  doesPluginHasNotJoinedTable,
  changeDoesPluginHasNotJoinedTable,
  updateModelTablesForJoin,
  cancelTokenSource,
  changeNavigationVisibility,
  navigationVisibility,
  dashboardInformation,
  popupsRef,
  mobileSize,
  columnMapWithLocationFieldName,
  isNewPlugin,
  updateCommonTitleConfig,
  commonTitleConfig,
  setDefaultForPluginTitle,
  setCurrentAppliedConfig,
  currentAppliedConfig,
  changeConfigOptionsForMountedPlugin,
  titleChangeStatus,
  refreshPlugin,
  pinStatusChange,
  isPinned,
  setInteractions,
  noDataTitle,
  limit,
  setDataLimitForPlugin,
  reReturnThemeSettings,
  excelExport,
  lastRefreshedPlugin,
  isExcelExportStyled,
  excelExportProgress,
  isExcelExportBigSized,
  title
) => {
  let props = {
    plugin: plugin,
    updatePlugin: updatePlugin,
    model: model,
    interactions: interactions,
    updateConfig: updateConfig,
    updateDefaultFilterForPlugin: updateDefaultFilterForPlugin,
    navigations: navigations,
    rerender: rerender,
    setPluginRerender: setPluginRerender,
    config: config,
    settings: settings,
    configVisibility: configVisibility,
    changeConfigVisibility: changeConfigVisibility,
    dataVisibility: dataVisibility,
    changeDataVisibility: changeDataVisibility,
    conditionalFormattingVisibility: conditionalFormattingVisibility,
    changeConditionalFormattingVisibility: changeConditionalFormattingVisibility,
    getData: getData,
    join: join,
    addJoinToJoinList: addJoinToJoinList, 
    joinErrorVisibility: joinErrorVisibility, 
    changeJoinErrorVisibility: changeJoinErrorVisibility, 
    clickedRefresh: clickedRefresh, 
    setClickedRefresh: setClickedRefresh, 
    hasNotJoinedData: hasNotJoinedData,
    changeHasNotJoinedData: changeHasNotJoinedData, 
    refreshedPluginId: refreshedPluginId, 
    changeRefreshedPluginId: changeRefreshedPluginId,
    doesPluginHasNotJoinedTable: doesPluginHasNotJoinedTable,
    changeDoesPluginHasNotJoinedTable: changeDoesPluginHasNotJoinedTable,
    updateModelTablesForJoin: updateModelTablesForJoin,
    cancelTokenSource: cancelTokenSource,
    changeNavigationVisibility: changeNavigationVisibility,
    navigationComponentVisibility: navigationVisibility,
    dashboardInformation: dashboardInformation,
    popupsRef: popupsRef,
    mobileSize: mobileSize,
    columnMapWithLocationFieldName: columnMapWithLocationFieldName,
    isNewPlugin: isNewPlugin,
    updateCommonTitleConfig: updateCommonTitleConfig,
    commonTitleConfig: commonTitleConfig,
    setDefaultForPluginTitle: setDefaultForPluginTitle,
    setCurrentAppliedConfig: setCurrentAppliedConfig,
    currentAppliedConfig: currentAppliedConfig,
    changeConfigOptionsForMountedPlugin: changeConfigOptionsForMountedPlugin,
    titleChangeStatus: titleChangeStatus,
    refreshPlugin,
    pinStatusChange: pinStatusChange,
    isPinned: isPinned,
    setInteractions: setInteractions,
    noDataTitle: noDataTitle,
    limit: limit,
    setDataLimitForPlugin: setDataLimitForPlugin,
    reReturnThemeSettings: reReturnThemeSettings,
    excelExport: excelExport,
    lastRefreshedPlugin: lastRefreshedPlugin,
    isExcelExportStyled: isExcelExportStyled,
    excelExportProgress: excelExportProgress,
    isExcelExportBigSized : isExcelExportBigSized,
    title: title
  };

  switch (plugin.key) {
    case "gauge-chart":
      return <GaugeChart {...props} />;
    case "network-graph":
      return <NetworkGraph {...props} />;
    case "timeline-chart":
      return <TimelineChart {...props} />;
    case "measure-tile":
      return <MeasureTile {...props} />;
    case "radio-button-filter":
    case "selection-box":
      return <ButtonFilter {...props} />;
    case "button-filter":
      return <EnhancedFilter {...props} />;
    case "table":
      return <Table {...props} />;
    case "title":
      return <Title {...props} />;
    case "age-pyramid":
      return <AgePyramid {...props} />;
    case "bar-chart":
      return <BarChart {...props}></BarChart>;
    case "pie-chart":
    case "pie-chart-enhanced":
      return <PieChartEnhanced {...props}></PieChartEnhanced>;
    case "pivot-table":
      return <PivotTableBase {...props}></PivotTableBase>;
    case "pivot-table-enhance":
      return <PivotTableBase {...props}></PivotTableBase>;
    case "flag":
      return <Flag {...props}></Flag>;
    case "sunburst":
      return <Sunburst {...props}></Sunburst>;
    case "map-bubbles":
      return <MapBubbles {...props}></MapBubbles>;
    case "multi-axis-line-chart":
      return <MultiAxisLineChart {...props}></MultiAxisLineChart>;
    case "map-choropleth":
      return <MapChoropleth {...props}></MapChoropleth>;
    case "heatmap":
      return <MapHeatmap {...props}></MapHeatmap>;
    case "radar":
      return <Radar {...props}></Radar>;
    case "chord-diagram":
      return <ChordDiagram {...props}></ChordDiagram>;
    case "image":
      return <Image {...props}></Image>;
    case "sankey-chart":
      return <Sankey {...props}></Sankey>;
    case "filter":
      return <FilterFactory {...props}></FilterFactory>;
    case "tree-map":
      return <TreeMap {...props}></TreeMap>;
    case "word-cloud":
      return <WordCloud {...props}></WordCloud>;
    case "turkey-map":
      return <TurkeyMap {...props}></TurkeyMap>;
    case "liquid-gauge":
      return <LiquidGauge {...props}></LiquidGauge>;
    case "cylinder-bar":
      return <CylinderBar {...props}></CylinderBar>;
    case "line-bar":
      return <LineBar {...props}></LineBar>;
    case "line-bar-chart":
      return <LineBarChart {...props}></LineBarChart>;
    case "turkish-district-map":
      return <TurkishDistrictMap {...props}></TurkishDistrictMap>;
    case "world-map":
      return <WorldMap {...props}></WorldMap>;
    case "i-frame":
      return <IFrame {...props}></IFrame>;
    case "radial-progress":
      return <RadialProgress {...props}></RadialProgress>;
    case "route-map-2":
      return <RouteMap2 {...props}></RouteMap2>;
    case "glassed-title":
      return <GlassedTitle {...props}></GlassedTitle>;
    case "map-cluster":
      return <MapCluster {...props}></MapCluster>;
    case "scatter-chart":
      return <ScatterChart {...props}></ScatterChart>;
    case "route-map":
      return <RouteMap {...props}></RouteMap>;
    case "mind-gapper":
      return <MindGapper {...props}></MindGapper>;
    case "custom":
      return <VegaLitePlugin {...props}></VegaLitePlugin>;
    default:
      return "Plugin not found...";
  }
};
