export const configuration = {
    ShowLineValueOption: false,
    ValueOnBarOption: false,
    ShowYAxisStylingOption: false,
    ShowXAxisStylingOption: false,
    condFormat: true,
    ColoredLineValues: false,
    pointSize: 2,
    strokeWidth: 2,
    legend: true,
    axisNumber: "Multi",
    yAxisFontStyle: false,
    yAxisSingleTitleShow: true,
    showEllipsis: true,
    showEllipsisOnXAxis: false,
    yAxisSingleTitle: false,
    yAxisFontWeight: false,
    xAxisFontStyle: false,
    xAxisTextDecoration: false,
    xAxisFontWeight: false
};

export const actions = [
    {
        trigger: "sectionClick",
        type: "click",
        name: "Tıklama - Bölüm",
        output: ["category", "vary", "lines"],
        description: "SectionClickDesc"
    },
    {
        trigger: "sectionHover",
        type: "hover",
        name: "Hover - Bölüm",
        output: ["category", "vary"],
        description: "SectionHover2Desc"
    },
    {
        trigger: "pointClick",
        type: "click",
        output: ["category", "vary"],
        name: "Tıklama - Nokta",
        description: "PointClickDesc"
    },
    {
        trigger: "pointHover",
        type: "hover",
        output: ["category", "vary"],
        name: "Hover - Nokta",
        description: "PointHoverDesc"
    },
    {
        trigger: "brushSelect",
        type: "brush",
        name: "Fırça Seçimi",
        output: ["category"],
        description: "BrushSelectDesc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["category", "columns", "lines", "vary"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
};