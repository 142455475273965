import $ from "jquery";
/*
 * function take old title content and put same spot with math equasions
 * close display old title
 * create new div with text element and add this div outside of svg element
 */
export const calculateLegendTitlePosition = (divId) => {
  let container = $(`#${divId}`);
  let containerWidth = container.width();
  let containerHeight = container.height();
  let titleEl = $(container.children()[0]);
  let titleValue = container.find(".title").text(); // take title content at old title
  let legendContainer = container.find(".legend");
  let legendScaleAttr = legendContainer.parent().attr("transform"); // understand which scale factor is using

  legendContainer.css("display", "none");
  let sunburstPluginHeight = container
    .find(`#svg-${divId}`)
    .children()[0]
    .getBoundingClientRect().height;
  legendContainer.css("display", "");

  let titleLocationLeftArrangeForWidth =
    ((containerHeight - 141) / 75) * 31.5 + (containerWidth / 2 + 60); // math equasion where plugin width is bigger than plugin height
  let titleLocationLeftArrangeForHeight = (containerWidth * 4) / 5;
  let titleLocationTopArrangeForHeight =
    containerHeight / 2 + sunburstPluginHeight / 2 + 25; // math equasion where height is bigger than width
  let constantForWidthBiggerThanHeight = "translate(100, 150)";
  let constantForHeightBiggerThanWidth = "translate(170, 150)";

  function arrangeLeftPadding() {
    if (legendScaleAttr == constantForWidthBiggerThanHeight) {
      return titleLocationLeftArrangeForWidth;
    } else if (legendScaleAttr == constantForHeightBiggerThanWidth) {
      return titleLocationLeftArrangeForHeight;
    }
  }

  function arrangeTopPadding() {
    if (legendScaleAttr == constantForWidthBiggerThanHeight) {
      return "auto";
    } else if (legendScaleAttr == constantForHeightBiggerThanWidth) {
      return titleLocationTopArrangeForHeight + "px";
    }
  }

  function arrangeMaxWidth() {
    if (legendScaleAttr == constantForWidthBiggerThanHeight) {
      return containerWidth - titleLocationLeftArrangeForWidth - 10;
    } else if (legendScaleAttr == constantForHeightBiggerThanWidth) {
      return containerWidth - titleLocationLeftArrangeForHeight - 10;
    }
  }

  //create new div and text elements
  let newTitleEl =
    '<div title="' +
    titleValue +
    '"><text class="newTitle" id="newTitle" style="left:' +
    arrangeLeftPadding() +
    "px; position: absolute; bottom:" +
    arrangeTopPadding() +
    "; font-weight: bold; max-width:" +
    arrangeMaxWidth() +
    'px; text-align: left; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;"> ' +
    titleValue +
    "</text></div>";

  //add text into newTitleEl
  titleEl.prepend(newTitleEl);

  //close display old title
  $(container.find(".legend").children()[0]).css("display", "none");

  changeFontSizeAndPaddingLegend(divId);
};

/*
 * change new title font size with looking legend font size and scale with them
 * push legend a bit down for prevent overflows
 */
export const changeFontSizeAndPaddingLegend = (divId) => {
  let container = $(`#${divId}`);
  let textContainer = container.find(".newTitle");
  let scaleConstantForText =
    ($(container.find(".key")).find("text")[0].getBoundingClientRect().height /
      9) *
    10;

  //change text font size for resize plugin (9 is basic font size for legend, 10 is basic font size for title text)
  textContainer.css("font-size", scaleConstantForText + "px");

  //push legend down for a new title should fit inside of plugin
  container
    .find(".legend")
    .attr(
      "transform",
      "translate(140, " + (-125 + textContainer[0].clientHeight / 2) + ")"
    );
};
