import {
  BgColorsOutlined,
  BoldOutlined,
  BorderVerticleOutlined,
  EyeInvisibleOutlined,
  FontSizeOutlined,
  InfoCircleOutlined,
  ItalicOutlined,
  NumberOutlined,
  UnderlineOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, InputNumber, Row, Select, Tooltip } from "antd";
import $ from "jquery";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import React, { Component } from "react";
import i18n from "../../../../Utils/i18next";
import FontTypeSelector from "../../../GeneralComponents/FontTypeSelector/FontTypeSelector";
import Text from "../../../GeneralComponents/Text/Text";
import ThemePalette from "../../../GeneralComponents/ThemePalette/ThemePalette";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";
import { InsightsConfig } from "../../RenderJs/config";
import { themeFields } from "../common";

const clone = require("rfdc")();

const { TextArea } = Input;
const { Option } = Select;

export default class EnhancedFilterConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      size: this.props.config.size,
      colour: this.props.config.colour,
      font: this.props.config.font,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      backgroundColor: this.props.config.backgroundColor,
      colorChange: false,
      backgroundColorChange: false,
      titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
      titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
      titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
      titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
      titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
      titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
      changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
      roundCorner: this.props.config.roundCorner === undefined ? true : this.props.config.roundCorner,
      spaceBetween: this.props.config.spaceBetween,
      paletteColours: InsightsConfig.Palettes[this.props.config.colours == undefined ? "defaultEnhancedPalette" : this.props.config.colours]
        ? InsightsConfig.Palettes[this.props.config.colours == undefined ? "defaultEnhancedPalette" : this.props.config.colours]
        : this.props.config.colours,
      textColor: this.props.config.textColor !== undefined ? this.props.config.textColor : "rgba(75, 75, 75, 1)",
      threeD: this.props.config.threeD,
      colours: this.props.config.colours == undefined ? "defaultEnhancedPalette" : this.props.config.colours,
      isMultiple: this.props.config.isMultiple,
      font: this.props.config.font !== undefined ? this.props.config.font : "Verdana",
      textDecor: this.props.config.textDecor !== undefined ? this.props.config.textDecor : false,
      fontStyle: this.props.config.fontStyle !== undefined ? this.props.config.fontStyle : false,
      fontWeight: this.props.config.fontWeight !== undefined ? this.props.config.fontWeight : false,
      fontSize: this.props.config.fontSize,
      keyForTitleSize: null,
      wrapText: this.props.config.wrapText,
      showPaddingErr: false,
      showFontSizeErr: false,
      isPaddingGreaterThanFourty: false,
      isFontSizeGreaterThanTwenty: false,
      noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        colour: nextProps.config.colour,
        font: nextProps.config.font,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        backgroundColor: nextProps.config.backgroundColor,
        titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
        titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
        changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
        titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
        titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
        titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
        titleColour: nextProps.config.titleColour == undefined ? "black" : nextProps.config.titleColour,
        roundCorner: nextProps.config.roundCorner == undefined ? true : nextProps.config.roundCorner,
        threeD: nextProps.config.threeD,
        paletteColours: InsightsConfig.Palettes[nextProps.config.colours == undefined ? "defaultEnhancedPalette" : nextProps.config.colours]
          ? InsightsConfig.Palettes[nextProps.config.colours == undefined ? "defaultEnhancedPalette" : nextProps.config.colours]
          : nextProps.config.colours,
        colours: nextProps.config.colours == undefined ? "defaultEnhancedPalette" : nextProps.config.colours,
        isMultiple: nextProps.config.isMultiple,
        spaceBetween: nextProps.config.spaceBetween,
        textColor: nextProps.config.textColor !== undefined ? nextProps.config.textColor : "rgba(75, 75, 75, 1)",
        font: nextProps.config.font !== undefined ? nextProps.config.font : "Verdana",
        textDecor: nextProps.config.textDecor !== undefined ? nextProps.config.textDecor : false,
        fontStyle: nextProps.config.fontStyle !== undefined ? nextProps.config.fontStyle : false,
        fontWeight: nextProps.config.fontWeight !== undefined ? nextProps.config.fontWeight : false,
        fontSize: nextProps.config.fontSize,
        wrapText: nextProps.config.wrapText,
        noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,
      });
    }
  }

  //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
  setDefaultsForTitle = () => {
    let newConfig = this.props.setDefaultForPluginTitle();

    this.setState(
      {
        ...this.state,
        title: newConfig.title,
        titleAlign: newConfig.titleAlign,
        titleFont: newConfig.titleFont,
        titleFontSize: newConfig.titleFontSize,
        titleFontStyle: newConfig.titleFontStyle,
        titleFontWeight: newConfig.titleFontWeight,
        titleTextDecor: newConfig.titleTextDecor,
        titleColour: newConfig.titleColour,
        changedTitleFontSize: newConfig.titleFontSize
      }, () => {
        this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
      }
    );

    if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
      $("#titleFontSize").css("background", "")
    }
  }

  /*
  * Changes title colour change visible
  */
  titleColourChangeVisible = () => {
    this.setState({
      ...this.state,
      titleColourChange: !this.state.titleColourChange
    });
  };

  /*
  * Changes event's value
  */
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /*
  * Changes event's value
  */
  handleChangeFontSize = (value) => {
    let showFontSizeErr = false
    let isFontSizeGreaterThanTwenty = false

    if (value > 15 || value < 3) {
      showFontSizeErr = true
    }

    if (value > 15) {
      isFontSizeGreaterThanTwenty = true
      value = 15;
    } else if (value < 3) {
      isFontSizeGreaterThanTwenty = false
      value = 3
    }

    this.setState({
      ...this.state,
      fontSize: value,
      showFontSizeErr: showFontSizeErr,
      isFontSizeGreaterThanTwenty: isFontSizeGreaterThanTwenty
    })

    this.setConfigToPlugin({ type: "fontSize", value: value });
  };

  /*
  * Changes theme
  */
  coloursOnChange = value => {
    this.setState({
      ...this.state,
      colours: value
    });

    this.setConfigToPlugin({ type: "colours", value: value });
  };

  handleReset = e => {
    this.props.setPluginRerender(true, this.props.pluginId, true);
  };

  /*
  * Handles checkbox's target property
  */
  handleCheck = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked,
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /*
  * Changes text value
  */
  handleCheckText = e => {
    let val = this.state[e.target.id]

    this.setState({
      ...this.state,
      [e.target.id]: !val
    });

    this.setConfigToPlugin({ type: e.target.id, value: !val });
  };

  /**
 * Updates state attributes in RouteMap plugin
 */
  setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
      }

      if (tempState.imgSrc != "") {
        tempState.imageName = "";
        tempState.imagePath = "";
        tempState.imageUpload = "";
      } else if (tempState.imgSrc == "" && tempState.imageName == "") {
        tempState.imageName = "";
        tempState.imagePath = "";
        tempState.imageUpload = "";
        tempState.imgSrc = "";
      }
    }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /*
  * Changes data's space
  */
  onChangeMaxPadding = value => {
    let showPaddingErr = false
    let isPaddingGreaterThanFourty = false

    if (value > 40 || value < 0) {
      showPaddingErr = true
    }

    if (value > 40) {
      isPaddingGreaterThanFourty = true
      value = 40;
    } else if (value < 0) {
      isPaddingGreaterThanFourty = false
      value = 0
    }

    this.setState({
      ...this.state,
      spaceBetween: value,
      showPaddingErr: showPaddingErr,
      isPaddingGreaterThanFourty: isPaddingGreaterThanFourty
    })

    this.setConfigToPlugin({ type: "spaceBetween", value: value });
  }

  /*
  * Changes color
  */
  colorOnChange = (obj) => {
    this.setState({
      ...this.state,
      colour: obj.color,
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  titleAlignOnChange = (value) => {
    this.setState({
      ...this.state,
      titleAlign: value,
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  colorChangeVisible = () => {
    this.setState({ ...this.state, colorChange: !this.state.colorChange });
  };

  backgroundColorOnChange = (obj) => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color,
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange,
    });
  };

  //added this function to change the font-family
  fontOnChange = (obj) => {
    this.setState({
      ...this.state,
      font: obj,
    });

    this.setConfigToPlugin({ type: "font", value: obj });
  };

  /**
   * Changes the background colour attribute in Radio button filter plugin use backgroundColorOnChange
   */
  textColorOnChange = obj => {
    this.setState({
      ...this.state,
      textColor: obj.color
    });

    this.setConfigToPlugin({ type: "textColor", value: obj.color });
  };

  /*
  * Text color change visible
  */
  textColorChangeVisible = () => {
    this.setState({
      ...this.state,
      textColorChange: !this.state.textColorChange
    });
  };

  /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
  handleChangeBothOfValues = (constantValue, changedValue) => {
    this.setState({
      ...this.state,
      titleFontSize: constantValue,
      changedTitleFontSize: changedValue
    })

    this.setConfigToPlugin(
      [
        {
          type: "titleFontSize",
          value: constantValue
        },
        {
          type: "changedTitleFontSize",
          value: changedValue
        }
      ]);
  }

  render() {
    let tooSmallWarning = "* " + i18n.t("Dashboard.Configuration.Fields.CantReduceMeasureSize", { size: `0` });
    let tooBigWarning = "* " + i18n.t("Dashboard.Configuration.Fields.CantChangeMeasureSize", { size: `40` });

    let tooSmallWarningText = "* " + i18n.t("Dashboard.Configuration.Fields.CantReduceMeasureSize", { size: `3` });
    let tooBigWarningText = "* " + i18n.t("Dashboard.Configuration.Fields.CantChangeMeasureSize", { size: `15` });

    return (
      <>
        <ReturnToTheme
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
        />

        <div style={{ color: "#505050" }}>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.IsMulti")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"isMultiple"}
                defaultChecked={this.state.isMultiple}
                checked={this.state.isMultiple}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.BackgroundColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.backgroundColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.backgroundColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.backgroundColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.backgroundColor}
                  onChange={this.backgroundColorOnChange}
                  id={"backgroundColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Palette")}
              </Text>
            </Col>
            <Col span={4} style={{ paddingRight: "10px" }}>
              <Select
                id={"colours"}
                defaultValue={this.state.colours}
                value={this.state.colours}
                style={{ width: "100%" }}
                onChange={this.coloursOnChange}>
                <Option id={"defaultEnhancedPalette"} value="defaultEnhancedPalette">{i18n.t("Dashboard.Configuration.Fields.defaultEnhancedPalette")}</Option>
                <Option id={"flatUIPalette"} value="Flat-UI">{i18n.t("Dashboard.Configuration.Fields.FlatUI")}</Option>
                <Option id={"flatUISoftPalette"} value="Flat-UI-Soft">{i18n.t("Dashboard.Configuration.Fields.FlatUISoft")}</Option>
                <Option id={"coolScalePalette"} value="Cool-Scale">{i18n.t("Dashboard.Configuration.Fields.CoolScale")}</Option>
                <Option id={"warmScalePalette"} value="Warm-Scale">{i18n.t("Dashboard.Configuration.Fields.WarmScale")}</Option>
                <Option id={"heatmapPalette"} value="Heatmap">{i18n.t("Dashboard.Configuration.Fields.Heatmap")}</Option>
              </Select>
            </Col>
            <Col span={8}>
              <ThemePalette
                colours={this.state.paletteColours}
                update={this.setConfigToPlugin}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BorderVerticleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                type="span"
                style={{
                  whiteSpace: "normal",
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.SpaceBetweenButtons")}:
              </Text>
            </Col>
            <Col span={12}>
              <InputNumber
                id="spaceBetween"
                style={{ width: "100%", background: this.state.showPaddingErr ? "rgba(255, 0, 0, 0.26)" : "none" }}
                type={"number"}
                min={0}
                max={40}
                defaultValue={this.state.spaceBetween}
                onChange={this.onChangeMaxPadding}
              />
            </Col>
          </Row>
          {this.state.showPaddingErr ?
            <Row style={{ marginBottom: "5px" }}>
              <Col span={12}></Col>
              <Col span={12}>
                <div style={{ fontSize: "11px", textAlign: "left", color: "red" }}>
                  {this.state.isPaddingGreaterThanFourty ? tooBigWarning : tooSmallWarning}
                </div>
              </Col>
            </Row> : null}
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.RoundCorners")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"roundCorner"}
                defaultChecked={this.state.roundCorner}
                checked={this.state.roundCorner}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.ThreeDVisulisation")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"threeD"}
                defaultChecked={this.state.threeD}
                checked={this.state.threeD}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.TextStyling")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.FontSize")}
              </Text>
            </Col>
            <Col span={12}>
              <InputNumber
                style={{ width: "100%", background: this.state.showFontSizeErr ? "rgba(255, 0, 0, 0.26)" : "none" }}
                id={"fontSize"}
                defaultValue={this.state.fontSize}
                onChange={this.handleChangeFontSize}
                type={"number"}
                min={3}
                max={15}
              />
            </Col>
          </Row>
          {this.state.showFontSizeErr ?
            <Row>
              <Col span={12}></Col>
              <Col span={12}>
                <div style={{ fontSize: "11px", textAlign: "left", color: "red" }}>
                  {this.state.isFontSizeGreaterThanTwenty ? tooBigWarningText : tooSmallWarningText}
                </div>
              </Col>
            </Row> : null}
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.TextColor")}
              </Text>
            </Col>
            <Col span={12}>
              <Button
                onClick={this.textColorChangeVisible}
                style={{ width: "100%" }}
              >
                {this.state.textColorChange
                  ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                  : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
              </Button>
              {this.state.textColorChange ? (
                <ColorPickerPanel
                  alpha={100}
                  color={this.state.textColor}
                  onChange={this.textColorOnChange}
                  id={"textColor"}
                  style={{ marginTop: "5px", position: "absolute", right: 0 }}
                />
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <NumberOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px",
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.Font")}
              </Text>
            </Col>
            <Col span={12}>
              <FontTypeSelector
                placeholder={i18n.t("Dashboard.Configuration.Fields.Font")}
                onChange={this.fontOnChange}
                value={this.state.font}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col
              style={{ textAlign: "center" }}
              span={this.props.titleIcon ? this.props.titleIcon : 3}
            >
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col
              style={{ textAlign: "left" }}
              span={this.props.titleColumn ? this.props.titleColumn : 9}
            >
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.ConditionalFormatting.Font")}
              </Text>
            </Col>
            <Col style={{ textAlign: "left" }} span={12}>
              {this.state.fontWeight !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleCheckText}
                    id={"fontWeight"}
                    style={{
                      backgroundColor: this.state.fontWeight ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <BoldOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.fontStyle !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleCheckText}
                    id={"fontStyle"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: this.state.fontStyle ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <ItalicOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}

              {this.state.textDecor !== undefined ? (
                <span>
                  <Button
                    onClick={this.handleCheckText}
                    id={"textDecor"}
                    style={{
                      marginLeft: "20px",
                      backgroundColor: this.state.textDecor ? "#9ed3ff" : "initial",
                      color: "black",
                      borderColor: "lightgrey",
                    }}
                  >
                    <UnderlineOutlined
                      style={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}
                    />
                  </Button>
                </span>
              ) : null}
            </Col>
          </Row>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Configuration.Fields.WrapText")}
              </Text>
            </Col>
            <Col span={12}>
              <Checkbox
                style={{ float: "left", marginTop: "9px" }}
                id={"wrapText"}
                defaultChecked={this.state.wrapText}
                checked={this.state.wrapText}
                onChange={this.handleCheck}
              ></Checkbox>
            </Col>
          </Row>
          <TitleSettingsOfPlugin
            plugin={this.props.plugin}
            handleChange={this.handleChange}
            commonTitleConfig={this.props.commonTitleConfig}
            updateCommonTitleConfig={this.props.updateCommonTitleConfig}
            isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
            setDefaultsForTitle={this.setDefaultsForTitle}
            title={this.state.title}
            titleAlign={this.state.titleAlign}
            titleColour={this.state.titleColour}
            titleColourChange={this.state.titleColourChange}
            titleColourChangeVisible={this.titleColourChangeVisible}
            titleFont={this.state.titleFont}
            titleFontSize={this.state.titleFontSize}
            keyForTitleSize={this.state.keyForTitleSize}
            titleFontStyle={this.state.titleFontStyle}
            titleFontWeight={this.state.titleFontWeight}
            titleTextDecor={this.state.titleTextDecor}
            setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
            currentAppliedConfig={this.props.currentAppliedConfig}
            changedTitleFontSize={this.state.changedTitleFontSize}
            handleChangeBothOfValues={this.handleChangeBothOfValues}
          />
          <Divider className={"config-divider"} orientation="left">
            {i18n.t("Dashboard.Configuration.Fields.Others")}
          </Divider>
          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }} span={3}>
              <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
            </Col>
            <Col style={{ textAlign: "left", display: "flex" }} span={9}>
              <Text
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  marginTop: "2px"
                }}
              >
                {i18n.t("Dashboard.Settings.NoDataConfiguration")}
              </Text>
              <Tooltip
                title={
                  <>
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                  </>
                }
              >
                <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <TextArea
                allowClear
                id={"noDataTitle"}
                defaultValue={this.state.noDataTitle}
                value={this.state.noDataTitle}
                onChange={this.handleChange}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={i18n.t("NoDataContent.NoData") + " " +
                  i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
