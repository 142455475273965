import React, { Component } from "react";
import Tooltip from "../../../GeneralComponents/Tooltip/Tooltip";
import DataColumn from "../../DataComponents/DataColumn/DataColumn";
import PropTypes from "prop-types";
import i18n from "../../../../Utils/i18next";
import { Droppable } from "react-drag-and-drop";
import Text from "../../../GeneralComponents/Text/Text";
import $ from "jquery";
import VerticalDragDrop from "../../../GeneralComponents/VerticalDragDrop";
import { showNotificationWithIcon } from "../../../../Utils/Notification";
import { aggregatableDataTypes } from "../../DataComponents/DataConfigure";
import DataConstantContent from "../../DataComponents/DataConstantContent/DataConstantContent";
import { dataColumnOnDrop } from "../../../../Utils/DataColumnOnDrop";
import DefaultFilters from "../../DataComponents/DefaultFilters/DefaultFilters";
import Button from "../../../GeneralComponents/Button/Button";
import uuid from "react-uuid";
import { addFilterForDataComponent } from "../../DataComponents/DefaultFilters/DefaultFiltersForDataComponent"
import { updateDefaultFiltersForDataComponent } from "../../DataComponents/DefaultFilters/DefaultFiltersForDataComponent"
import {
  DragDropContext,
  Droppable as DNDDroppable,
} from "react-beautiful-dnd";
import EmptyColumn from "../../../GeneralComponents/EmptyColumnMapArea/EmptyColumn"
import { rerenderColumnMapForDragAndDrop, onDragStartInContainer } from "../../../GeneralComponents/ColumnMapDragDrop"
import { verticalDragDropItem } from "../../../GeneralComponents/VerticalDragDropItem";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const clone = require("rfdc")();
const multipleColumnAreas = ["hidden"]
const numericalColumnAreas = ["measure"]

/**
 * Custom data component for liquid gauge
 *
 * Includes DataConstantContent and own columnMapping areas.
 * It should be define in liquid gauge component (in componentWillReciveProps).
 * This component should notify the base component (with props.updateColumnMap method) about changes made in columnMap and sortedColumnList
 */
export default class LiquidGaugeData extends Component {
  constructor(props) {
    super(props);

    this.prototypes = {
      updateColumnMap: PropTypes.func,
      model: PropTypes.object,
      sortedColumnList: PropTypes.array,
      columnMap: PropTypes.object,
      pluginId: PropTypes.string
    };

    this.state = {
      model: this.props.model,
      columnMap: this.props.columnMap,
      sortedColumnList: this.props.sortedColumnList,
      pluginId: this.props.pluginId,
      closeThreePointPopup: false,
      exceptPopupIndex: -1,
      whileDragging: false,
      tableColumnHeight: 396,
      comesFrom: "",
    };
  }

  componentWillMount() {
    this.createDefaultSortList();
  }

  componentDidMount() {
    /** adjusts the height of the table & data to the column map area */
    let tableColumnHeight = $("#column-map-area").height();

    this.setState({
      ...this.state,
      tableColumnHeight: tableColumnHeight,
      showDefaultFilters: false,
      defaultFilters: this.props.defaultFilters ? this.props.defaultFilters : []
    });
  }

  /** default sort list = columnMap values */
  createDefaultSortList = () => {
    if (
      this.props.sortedColumnList == undefined ||
      this.props.sortedColumnList.length == 0
    ) {
      let sortedColumnList = [];
      let columnMap = this.state.columnMap;

      for (let field in columnMap) {
        columnMap[field].data.map(col => {
          sortedColumnList.push(col);
        });
      }

      this.setState({
        ...this.state,
        sortedColumnList: sortedColumnList
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state }

    if (this.props.defaultFilters !== nextProps.defaultFilters) {
      newState.defaultFilters = nextProps.defaultFilters
    }

    /**
     * If change the selected model, empty column map fields.
     */
    const isModelChanged = this.props.model && (this.props.model.name !== nextProps.model.name || this.props.model.tables !== nextProps.model.tables)

    if (isModelChanged) {
      let tempColumnMap = clone(nextProps.columnMap);

      for (let field in tempColumnMap) {
        tempColumnMap[field].data = [];
      }

      newState.model = nextProps.model
      newState.columnMap = tempColumnMap
    } else if (this.props.model !== nextProps.model) {
      newState.model = nextProps.model
    } else if (this.props.columnMap !== nextProps.columnMap) {
      newState.columnMap = nextProps.columnMap
    }

    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  setDefaultFilter = (column, tableAliasName, tableDisplayName) => {
    return (
      <a
        href="javascript:;"
        style={{ position: "relative", top: "4px" }}
        onClick={() => this.addFilter(column, tableAliasName, tableDisplayName)}
      >
        <i
          name={column.displayName + " filter"}
          class="fa fa-filter"
          style={{
            fontSize: "14px",
            color: "rgb(152, 152, 152)"
          }}
        ></i>
      </a>
    );
  };

  addFilter = (column, tableAliasName, tableDisplayName) => {
    //Adds and set state to filters.
    let tempDefaultFilters = addFilterForDataComponent(column, tableAliasName, tableDisplayName, this.props.model, this.state.defaultFilters)

    this.setState({
      ...this.state,
      defaultFilters: tempDefaultFilters
    })

    this.props.updateDefaultFilterForPlugin(tempDefaultFilters, this.props.pluginId);
  };

  updateDefaultFilters = (filter, status) => {
    //Updates and set state to filters
    let tempDefaultFilters = updateDefaultFiltersForDataComponent(filter, status, this.state.defaultFilters)

    this.setState({
      ...this.state,
      defaultFilters: tempDefaultFilters
    })

    this.props.updateDefaultFilterForPlugin(tempDefaultFilters, this.props.pluginId);
  }

  showDefaultFilters = status => {
    //Shows default filters
    this.setState({
      ...this.state,
      showDefaultFilters: status
    });
  };

  /**
   * To close for extra popups of columns in column map
   */
  closeOtherThreePointPopup = (status, index) => {
    this.setState({
      ...this.state,
      closeThreePointPopup: status,
      exceptPopupIndex: index
    });
  };
    
  /* This function updates the states based on three parameters to open or close the relevant pop-ups. 
  //It calls a callback for async process. */
  closeOtherPopups = (status, index, comesFrom) => {
    this.setState({
      ...this.state,
      closeDrillDownPopup: status,
      isCloseAllDrillDownPopup: status,
      exceptDrillDownPopupIndex: index,
      closeThreePointPopup: status,
      exceptPopupIndex: index,
      comesFrom: comesFrom
    }, () => {
      this.setState({
        ...this.state,
        isCloseAllDrillDownPopup: false,
        comesFrom: ""
      })
    });
  }

  /**
   * @param column //column object
   * @param status //boolean -> true = to add, false = to remove
   * @param sortedColumnList //array -> current sortedColumnList (to be processed)
   * returns sortedList
   */
  fillSortedList = (
    column,
    status,
    sortedColumnList = this.props.sortedColumnList
  ) => {
    let sortedList = sortedColumnList ? clone(sortedColumnList) : [];

    if (status) {
      sortedList.push(column);
    } else {
      sortedList = sortedList.filter(col => {
        return col.uniqeColumnId !== column.uniqeColumnId;
      });
    }

    return sortedList;
  };

  /**
   * To update sorted list (without add or delete)
   * About reorder or changed fields.
   */
  updateSortedList = list => {
    let sortedColumnList = clone(list);

    this.setState({
      ...this.state,
      sortedColumnList: sortedColumnList
    });

    this.props.updateColumnMap("columnMap", {
      columnMap: this.state.columnMap,
      sortedColumnList: sortedColumnList,
      pluginId: this.state.pluginId
    });
  };

  /**
   * update and notify to base component current columnMap
   */
  updateColumnMapData = (object, mapObject) => {
    let columnMap = clone(this.state.columnMap);
    columnMap[mapObject].data = clone(object);

    this.setState({
      ...this.state,
      columnMap: columnMap
    });

    this.props.updateColumnMap("columnMap", {
      columnMap: columnMap,
      sortedColumnList: this.props.sortedColumnList,
      pluginId: this.state.pluginId
    });
  };

  /**
   * On drop method from selected models columns to plugins column map area
   * Controls about liquid gauge plugin.
   */
  onDrop = (data, mapObject, key, aggr = false) => {
    let onDropResult = dataColumnOnDrop(data, mapObject, key, aggr, this);
  };

  /**
   * Remove column in column map
   */
  removeColumnOnMapping = (column, mapObject, conditionalFormats) => {
    let columnMap = { ...this.state.columnMap };

    /** removes with unique column id */
    columnMap[mapObject].data = columnMap[mapObject].data.filter(data => {
      return data.uniqeColumnId !== column.uniqeColumnId;
    });

    let sortedList = clone(this.fillSortedList(column, false));
    let tableColumnHeight = $("#column-map-area").height() - 45;

    this.setState({
      ...this.state,
      columnMap: columnMap,
      sortedColumnList: sortedList,
      tableColumnHeight: tableColumnHeight
    });

    let objectToUpdate = {
      columnMap: columnMap,
      sortedColumnList: sortedList,
      pluginId: this.state.pluginId,
    }

    if (conditionalFormats !== undefined) {
      objectToUpdate.conditionalFormats = conditionalFormats
    }

    this.props.updateColumnMap("columnMap", objectToUpdate);
  };

  //when dragging specify target areas.
  onDragStart = type => {
    this.setState({
      ...this.state,
      whileDragging: "drop-" + type
    });
  };

  //On dragging end restore target areas.
  onDragEnd = () => {
    this.setState({
      ...this.state,
      whileDragging: false
    });
  };

  /** update sorted list with column properties */
  updateSortedListForChangedProperties = (column, columnMap) => {
    let list = clone(this.props.sortedColumnList);
    let tempList = [];

    list.map(col => {
      if (col.uniqeColumnId === column.uniqeColumnId) {
        tempList.push(column);
      } else {
        tempList.push(col);
      }
    });

    this.setState({
      ...this.state,
      columnMap: columnMap,
      sortedColumnList: clone(tempList)
    });

    this.props.updateColumnMap("columnMap", {
      columnMap: columnMap,
      sortedColumnList: clone(tempList),
      pluginId: this.state.pluginId
    });
  };

  /**
   * change to column properties
   * @param columnProperties //properties of column used in column map
   * @param type //type of column map (ex: measure or hidden)
   * @param column //changed column with properties.
   */
  changeColumnProperties = (columnProperties, type, column) => {

    let columnMap = { ...this.state.columnMap };
    let tempColumn;
    columnMap[type].data.map(col => {
      if (col.uniqeColumnId === column.uniqeColumnId) {
        col.displayName = columnProperties.displayName;
        col.DataFormat = columnProperties.DataFormat;
        col.DataFormatType = columnProperties.DataFormatType;
        col.DecimalPlaces = columnProperties.DecimalPlaces;
        col.Use1000Separator = columnProperties.Use1000Separator;
        col.DataFormatTypeSubTitle = columnProperties.DataFormatTypeSubTitle;
        col.FormatExample = columnProperties.FormatExample;
        col.RoundingNumber = columnProperties.RoundingNumber;
        col.Code = columnProperties.Code;
        col.locale = columnProperties.locale;
        col.Locale = columnProperties.locale;
        col.value = columnProperties.Code;
        col.align = columnProperties.align;
        col.dataType = columnProperties.dataType
        col.originalDataType = columnProperties.originalDataType
        
        tempColumn = clone(col);
      }
    });

    this.updateSortedListForChangedProperties(tempColumn, columnMap);
  };

  /*
* Rerenders columnMap by drag drop object
*/
  rerenderColumnMap = (result) => {
    let newState = rerenderColumnMapForDragAndDrop(this, result, multipleColumnAreas, numericalColumnAreas, [], this.props.interactions, this.props.setInteractions)

    $(".droppableArea").css("background-color", "").css("border", "none")

    this.setState(newState.state);
  }

  render() {
    return (
      <>
        <DragDropContext onDragEnd={this.rerenderColumnMap} onDragStart={onDragStartInContainer}>
          <DataConstantContent
            model={this.state.model}
            columnMap={this.state.columnMap}
            sortedColumnList={this.props.sortedColumnList}
            updateSortedList={this.updateSortedList}
            defaultActiveKey={this.props.defaultActiveKey}
            conditionalFormats={this.props.conditionalFormats}
            tableColumnHeight={this.state.tableColumnHeight}
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
            defaultFilters={this.props.defaultFilters}
            pluginId={this.props.pluginId}
            updateDefaultFilterForPlugin={this.props.updateDefaultFilterForPlugin}
            join={this.props.join}
            clickedRefresh={this.props.clickedRefresh}
            setClickedRefresh={this.props.setClickedRefresh}
            hasNotJoinedData={this.props.hasNotJoinedData}
            changeHasNotJoinedData={this.props.changeHasNotJoinedData}
            changeJoinErrorVisibility={this.props.changeJoinErrorVisibility}
            refreshedPluginId={this.props.refreshedPluginId}
            doesPluginHasNotJoinedTable={this.props.doesPluginHasNotJoinedTable}
            setDefaultFilter={this.setDefaultFilter}
            addFilter={this.addFilter}
            changeDoesPluginHasNotJoinedTable={
              this.props.changeDoesPluginHasNotJoinedTable
            }
            updateModelTablesForJoin={this.props.updateModelTablesForJoin}
            limit={this.props.limit}
            setDataLimitForPlugin={this.props.setDataLimitForPlugin}
          >
            <div id="column-map-area" className={"data-area-overflow"}>
              <div className={"shadowed-area"}>
                <div
                  className={
                    this.state.whileDragging == "drop-aggr"
                      ? "accordion-holder droppableArea onDragDroppableStyle"
                      : this.state.whileDragging == false
                        ? "accordion-holder droppableArea"
                        : "accordion-holder droppableArea onDragDroppableInvalid"
                  }
                >
                  <Droppable
                    types={["column"]} // <= allowed drop types
                    onDrop={val => this.onDrop(val, "measure", "column", true)}
                  >
                    <div
                      className={"accordion-header card-header"}
                      style={{ background: "#fff" }}
                    >
                      <Text type={"span"}>
                        {i18n.t("Plugins.liquid-gauge.ColumnMap.Measure.Name")}
                      </Text>
                    </div>
                    <div className={"card-body"} style={{ minHeight: "20px" }}>
                      {this.state.columnMap.measure.data.length > 0 ? (
                        <VerticalDragDrop
                          content={verticalDragDropItem}
                          componentTHIS={this}
                          type={"measure"}
                          data={this.state.columnMap.measure.data}
                          updateColumnMapData={this.updateColumnMapData}
                          showDefaultFilters={this.showDefaultFilters}
                          addFilter={this.addFilter}
                          columnList={this.props.sortedColumnList}
                          draggedColumn={this.state.draggedColumn}
                          aggregatableDataTypes={aggregatableDataTypes}
                        ></VerticalDragDrop>
                      ) : (
                        <EmptyColumn
                          type={"measure"}
                          translate={i18n.t("Plugins.liquid-gauge.ColumnMap.Measure.Desc")}
                          columnList={this.props.sortedColumnList}
                          draggedColumn={this.state.draggedColumn}
                          aggregatableDataTypes={aggregatableDataTypes}
                        />
                      )}
                    </div>
                  </Droppable>
                </div>
              </div>
              <div className={"shadowed-area"}>
                <div
                  className={
                    this.state.whileDragging !== false
                      ? "accordion-holder droppableArea onDragDroppableStyle"
                      : "accordion-holder droppableArea"
                  }
                >
                  <Droppable
                    types={["column"]} // <= allowed drop types
                    onDrop={val => this.onDrop(val, "target", "column")}
                  >
                    <div
                      className={"accordion-header card-header"}
                      style={{ background: "#fff" }}
                    >
                      <Text type={"span"}>
                        {i18n.t("Plugins.liquid-gauge.ColumnMap.Target.Name")}
                      </Text>
                    </div>
                    <div className={"card-body"} style={{ minHeight: "20px" }}>
                      {this.state.columnMap.target.data.length > 0 ? (
                        <VerticalDragDrop
                          content={verticalDragDropItem}
                          componentTHIS={this}
                          type={"target"}
                          data={this.state.columnMap.target.data}
                          updateColumnMapData={this.updateColumnMapData}
                          showDefaultFilters={this.showDefaultFilters}
                          addFilter={this.addFilter}
                          columnList={this.props.sortedColumnList}
                          draggedColumn={this.state.draggedColumn}
                          isContainerAcceptAllColumn={true}
                        ></VerticalDragDrop>
                      ) : (
                        <EmptyColumn
                          type={"target"}
                          translate={i18n.t("Plugins.liquid-gauge.ColumnMap.Target.Desc")}
                          columnList={this.props.sortedColumnList}
                          draggedColumn={this.state.draggedColumn}
                          isContainerAcceptAllColumn={true}
                        />
                      )}
                    </div>
                  </Droppable>
                </div>
              </div>
              <div className={"shadowed-area"}>
                <div
                  className={
                    this.state.whileDragging !== false
                      ? "accordion-holder droppableArea onDragDroppableStyle"
                      : "accordion-holder droppableArea"
                  }
                >
                  <Droppable
                    types={["column"]} // <= allowed drop types
                    onDrop={val => this.onDrop(val, "hidden", "column")}
                  >
                    <div
                      className={"accordion-header card-header"}
                      style={{ background: "#fff" }}
                    >
                      <Text type={"span"}>
                        {i18n.t("Plugins.liquid-gauge.ColumnMap.Hidden.Name")}
                      </Text>
                    </div>
                    <div className={"card-body"} style={{ minHeight: "20px" }}>
                      {this.state.columnMap.hidden.data.length > 0 ? (
                        <VerticalDragDrop
                          content={verticalDragDropItem}
                          componentTHIS={this}
                          type={"hidden"}
                          data={this.state.columnMap.hidden.data}
                          updateColumnMapData={this.updateColumnMapData}
                          showDefaultFilters={this.showDefaultFilters}
                          addFilter={this.addFilter}
                          columnList={this.props.sortedColumnList}
                          draggedColumn={this.state.draggedColumn}
                          isContainerAcceptAllColumn={true}
                        ></VerticalDragDrop>
                      ) : (
                        <EmptyColumn
                          type={"hidden"}
                          translate={i18n.t("Plugins.liquid-gauge.ColumnMap.Hidden.Desc")}
                          columnList={this.props.sortedColumnList}
                          draggedColumn={this.state.draggedColumn}
                          isContainerAcceptAllColumn={true}
                        />
                      )}
                    </div>
                  </Droppable>
                </div>
              </div>
            </div>
          </DataConstantContent>
        </DragDropContext>

        <DefaultFilters
          showDefaultFilters={this.showDefaultFilters}
          visible={this.state.showDefaultFilters}
          modelDisplayName={this.props.model.displayName}
          pluginId={this.props.pluginId}
          filters={this.state.defaultFilters}
          updateDefaultFilters={this.updateDefaultFilters}
          pluginKey={this.props.plugin.key}
        ></DefaultFilters>
        {this.state.defaultFilters && this.state.defaultFilters.length > 0 ? (
          <Button
            className={"show-default-filters"}
            onClick={() => this.showDefaultFilters(true)}
            style={isValidWriteRoles() ? {} : {display: "none"}}
          >
            <i className={"fa fa-filter"}></i>
          </Button>
        ) : null}
      </>
    );
  }
}
