import React, { Component } from "react";
import $ from "jquery";
import "jquery-ui";
import "./pivot-table.css";
import { pivotExtensions } from "./pivot.js";
import * as d3 from "d3";
import { rmvpp } from "../../RenderJs/rmvpp";
import { vispeahen } from "../../RenderJs/vispeahen";
import PivotTableConfiguration from "./PivotTableConfiguration";
import PivotTableData from "./PivotTableData";
import i18n from "../../../../Utils/i18next";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import {
  renderConfig,
  renderData,
  renderNavigation,
  renderConditionalFormatting,
} from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { getFormattedValue } from "../format";
import NavigationContent from "../../../Navigation/NavigationContent";
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { findColumnMapHasAnyColumn } from "../../../ConditionalFormatting/RenderConditionalListName"
import { pivotExcelExport } from "./PivotExcelExport";
import uuid from "react-uuid";
import { convertHTMLRuletoRule } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import jsPDF from "jspdf";
import { vispeahenLogo, ROBOTOREGULAR } from "../Table/TablePdfContent.js";
import "./Roboto-Regular-normal.js"
import "./Roboto-Bold-bold.js"
import "./Roboto-BoldItalic-bolditalic.js"
import "./Roboto-Italic-normal"

const clone = require("rfdc")();

const data = [
  {
    "ay adi": "Ağustos",
    kargotoplam: "4.8844078E+8",
    rows: [{ name: "ay adi", value: "Ağustos" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 488440780 }],
    hidden: [],
  },
  {
    "ay adi": "Aralık",
    kargotoplam: "5.023852E+8",
    rows: [{ name: "ay adi", value: "Aralık" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 502385200 }],
    hidden: [],
  },
  {
    "ay adi": "Ekim",
    kargotoplam: "493789318",
    rows: [{ name: "ay adi", value: "Ekim" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 493789318 }],
    hidden: [],
  },
  {
    "ay adi": "Eylül",
    kargotoplam: "478800672",
    rows: [{ name: "ay adi", value: "Eylül" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 478800672 }],
    hidden: [],
  },
  {
    "ay adi": "Haziran",
    kargotoplam: "476167436",
    rows: [{ name: "ay adi", value: "Haziran" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 476167436 }],
    hidden: [],
  },
  {
    "ay adi": "Kasım",
    kargotoplam: "500703756",
    rows: [{ name: "ay adi", value: "Kasım" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 500703756 }],
    hidden: [],
  },
  {
    "ay adi": "Mart",
    kargotoplam: "4.7940583E+8",
    rows: [{ name: "ay adi", value: "Mart" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 479405830 }],
    hidden: [],
  },
  {
    "ay adi": "Mayıs",
    kargotoplam: "485081738",
    rows: [{ name: "ay adi", value: "Mayıs" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 485081738 }],
    hidden: [],
  },
  {
    "ay adi": "Nisan",
    kargotoplam: "466192048",
    rows: [{ name: "ay adi", value: "Nisan" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 466192048 }],
    hidden: [],
  },
  {
    "ay adi": "Ocak",
    kargotoplam: "384458844",
    rows: [{ name: "ay adi", value: "Ocak" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 384458844 }],
    hidden: [],
  },
  {
    "ay adi": "Şubat",
    kargotoplam: "4.0228677E+8",
    rows: [{ name: "ay adi", value: "Şubat" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 402286770 }],
    hidden: [],
  },
  {
    "ay adi": "Temmuz",
    kargotoplam: "452360534",
    rows: [{ name: "ay adi", value: "Temmuz" }],
    columns: [],
    measures: [{ name: "kargotoplam", value: 452360534 }],
    hidden: [],
  },
];

const columnMap = {
  rows: [
    {
      Code: "'ucusAlias'.'ay_adiAlias'",
      Name: "ay adi",
      name: "ay adi",
      DataType: "varchar",
      Table: "ucusAlias",
      Measure: "none",
      ID: "ucusAlias.ay adi",
      SubjectArea: "BIGDATA",
      SortKey: false,
      Sorting: false,
      SortDirection: "asc",
      SortOrder: 0,
      Locale: "TR",
      DataFormat: "%s",
      Config: {},
      Verified: true,
      Type: "Column",
      Description: "",
    },
  ],
  columns: [],
  measures: [
    {
      Code: "sum('ucusAlias'.'kargotoplamDataAlias')",
      Name: "kargotoplam",
      name: "kargotoplam",
      DataType: "double",
      Table: "ucusAlias",
      Measure: "none",
      ID: "ucusAlias.kargotoplam",
      SubjectArea: "BIGDATA",
      SortKey: false,
      Sorting: false,
      SortDirection: "asc",
      SortOrder: 0,
      Locale: "TR",
      DataFormat: ".3s",
      Config: { aggregator: "Sum", hide: false, Plugin: "pivot-table" },
      Verified: true,
      Type: "Column",
      Description: "",
    },
  ],
  hidden: [],
};

const condFormats = [];
const pluginName = "pivot-table";
const id = pluginName;
const displayName = pluginName; // "Pivot Table";
const description =
  "Pivot table created using a modified version of Nicolas Kruchten's [JS plugin](https://github.com/nicolaskruchten/pivottable). The pivot table has a fixed height and width and the content is scrollable both horizontally and vertical when necessary.";
const icon = "table";
const group = "pluginGroups.Table";

const pluginConditionalFormatOptions = {
  color: {
    title: i18n.t("Dashboard.ConditionalFormatting.Color"),
    type: "COLOR",
    defaultValue: "#000001",
  },
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe",
  },
  icon: {
    title: i18n.t("Dashboard.ConditionalFormatting.Icon"),
    type: "ICON",
    defaultValue: "",
  },
  text: {
    title: i18n.t("Dashboard.ConditionalFormatting.Font"),
    type: "TEXT",
    defaultValue: {
      italic: false,
      bold: false,
    },
  },
};

const conditionalFormatColumnMap = new Set(["rows", "columns", "measures"]);
const conditionalFormatTargetMap = new Set(["measures"]);

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300,
    },
    desc: "desc174",
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 300,
    },
    desc: "desc175",
  },
  {
    targetProperty: "columnWidth",
    label: "ColumnWidth",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 100,
    },
    desc: "desc176",
  },
  {
    targetProperty: "isRowSizeMustConstant",
    label: "isRowSizeMustConstant",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true,
    },
    desc: "isRowSizeMustConstant",
  },
  {
    targetProperty: "subTotal",
    label: "isColSizeMustConstant",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true,
    },
    desc: "isColSizeMustConstant",
  },
  {
    targetProperty: "defaultDrilldownColor",
    label: "defaultDrilldownColor",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true,
    },
    desc: "defaultDrilldownColor",
  },
  {
    targetProperty: "rowWidth",
    label: "RowWidth",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 100,
    },
    desc: "desc176",
  },
  {
    targetProperty: "rowHeight",
    label: "RowHeight",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 14,
    },
    desc: "desc177",
  },
  {
    targetProperty: "headerFont",
    label: "HeaderFont",
    inputType: "font",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "desc166"
  },
  {
    targetProperty: "headerFontSize",
    label: "HeaderFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 8,
      max: 72,
      defaultValue: 12
    },
    desc: "desc165"
  },
  {
    targetProperty: "colColor",
    label: "colColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgba(0,0,1,1)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "totalFontSize",
    label: "totalFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 8,
      max: 72,
      defaultValue: 12
    },
    desc: "desc165"
  },
  {
    targetProperty: "totalFontColour",
    label: "totalFontColour",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgba(0,0,1,1)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "rowColor",
    label: "rowColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgba(0,0,1,1)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "valueFontSize",
    label: "valueFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 8,
      max: 72,
      defaultValue: 11
    },
    desc: "desc165"
  },
  {
    targetProperty: "valueFontFamily",
    label: "valueFontFamily",
    inputType: "font",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "desc166"
  },
  {
    targetProperty: "totalFontFamily",
    label: "totalFontFamily",
    inputType: "font",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "desc167"
  },
  {
    targetProperty: "valueColor",
    label: "valueColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgba(0,0,1,1)"
    },
    desc: "desc62"
  },
  {
    targetProperty: "subTotal",
    label: "pivotTableSubTotal",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false,
    },
    desc: "pivotTableSubTotal",
  },
  {
    targetProperty: "columnHeight",
    label: "ColumnHeight",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 14,
    },
    desc: "desc177",
  },
  {
    targetProperty: "autoSize",
    label: "AutoSize",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true,
    },
    desc: "desc178",
  },
  {
    targetProperty: "controls",
    label: "AllowControls",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true,
    },
    desc: "desc179",
  },
  {
    targetProperty: "valuesAsCols",
    label: "ValuesasColumns",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true,
    },
    desc: "desc180",
  },
  {
    targetProperty: "showSum",
    label: "ShowSum",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true,
    },
    desc: "ShowHideTotal",
  },
  {
    targetProperty: "wrapHeader",
    label: "WrapHeader",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false,
    },
    desc: "desc162",
  },
  {
    targetProperty: "sumSelector",
    label: "SumSelector",
    inputType: "dropdown",
    inputOptions: {
      defaultValue: "ShowBoth",
      multiSelect: false,
      values: ["ShowBoth", "JustRow", "JustColumn"],
    },
    desc: "sumSelector",
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false,
    },
    desc: "desc230",
  },
  {
    targetProperty: "renderer",
    label: "Renderer",
    inputType: "radio",
    inputOptions: {
      defaultValue: "Table",
      values: ["Table", "Table Barchart"],
    },
    desc: "desc181",
  },
  {
    targetProperty: "themeColour",
    label: "ThemeColour",
    inputType: "colour",
    inputOptions: {
      defaultValue: "#67b7dc",
    },
    desc: "desc182",
  },
  {
    targetProperty: "pivotTableDescription",
    label: "TableDescription",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "",
    },
    desc: "desc183",
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0,
    },
    desc: "desc89",
  },
  {
    targetProperty: "condFormat",
    label: "CondFormat",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true,
    },
    desc: "desc162",
  },
];

const actions = [
  {
    trigger: "click",
    type: "click",
    output: ["columns", "rows"],
    name: "Click",
    description: "PivotTableClickDesc",
  },
];

const reactions = [
  {
    id: "filter",
    name: "Filter",
    description: "desc87",
    type: "general",
  },
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const constspecialCondFormats = [
  // Special conditional format presets for this plugin
  {
    id: "heatmap",
    name: "Heatmap",
    noValue: true, // No comparison value is required
    description: "desc219",
  },
];

export default class PivotTable extends Component {
  constructor(props) {
    super(props);
    this.rerenderProcessStarted = false;
    this.callBackObject = {};
    this.columnMap = {};
    this.container = {};
    this.pluginId = "";
    this.interactions = [];
    this.navigations = [];
    this.drillDowns = {};

    this.wrapperRef = React.createRef();
  }

  /**
   ** Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    this.setPluginInformation(this.props);

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  /**
   *
   * @param {*} props
   * Sets some plugin information for trigger operation
   */
  setPluginInformation = (props) => {
    this.pluginId = props.plugin.id;
    this.interactions = props.interactions;
    this.navigations = props.navigations;
    this.drillDowns = props.plugin.drillDowns;
  };

  /**
   *
   * @param {*} data
   * @returns
   * Arranges the clicked data for trigger operation
   */
  getConvertData = (data) => {
    let columns = [];
    let rows = [];

    for (let item in data) {
      if (item.includes("columns")) {
        let index = item.split("columns")[1];
        let columnName = this.columnMap["columns"][index].name;
        let column = { name: columnName, value: data[item] };

        columns.push(column);
      } else if (item.includes("rows")) {
        if (this.columnMap["rows"].length > 0) {
          let index = item.split("rows")[1];
          let columnName = this.columnMap["rows"][index].name;
          let column = { name: columnName, value: data[item] };

          rows.push(column);
        }
      }
    }

    return { columns: columns, rows: rows };
  };

  /**
   *
   * @param {*} columnMap
   * @returns
   * Arranges the column map in the appropriate format for the trigger operation
   */
  getColumnMap = (columnMap) => {
    let columnMapData = {};

    for (let item in columnMap) {
      columnMapData[item] = columnMap[item];
    }

    return columnMapData;
  };

  /**
   *
   * @param {*} element
   * @returns
   *
   * Returns the clicked column information
   */
  getClickedColumn = (element) => {
    let columnHeader = $(element).attr("pvt-header");
    let type = $(element).attr("header-type");
    let dataColumn = this.columnMap[type];
    let column = dataColumn.filter((clmn) => clmn.displayName === columnHeader);

    return column;
  };

  // converts affix value to original value
  convertColumnMap = (columns, rows, data) => {
    for (let dataRow of data.rows) {
      for (let r of rows) {
        if (r.name === dataRow.name && r.affixValue) {
          dataRow.value = r.affixAlign === "right" ? dataRow.value.slice(0, (dataRow.value.length - r.affixValue.length)) : dataRow.value.slice(r.affixValue.length);
        }
      }
    }

    for (let dataColumn of data.columns) {
      for (let c of columns) {
        if (c.name === dataColumn.name && c.affixValue) {
          dataColumn.value = c.affixAlign === "right" ? dataColumn.value.slice(0, (dataColumn.value.length - c.affixValue.length)) : dataColumn.value.slice(c.affixValue.length);
        }
      }
    }

    return data;
  };

  /**
   *
   * @param {*} event
   * It allows the plugin to be triggered according to the clicked location.
   */
  clickCreateTrigger = (event, THIS) => {
    // If not left click, return.
    if (event?.which !== 1) return;

    if (event?.srcElement.closest("div[id='" + this.props.plugin.id + "']")) {
      if (event.srcElement.parentElement?.__data__ !== undefined) {
        let mousePosition = { x: event.pageX, y: event.pageY };
        let data = this.getConvertData(event.srcElement.parentElement.__data__);

        data = this.convertColumnMap(THIS.columnMap.columns, THIS.columnMap.rows, data);
        
        createTrigger(
          actions,
          this.getColumnMap(this.columnMap),
          this.container,
          "click",
          data,
          this.pluginId,
          this.props.interactions,
          this.props.navigations,
          mousePosition,
          null,
          this.drillDowns
        );
      } else if (
        event.srcElement &&
        event.srcElement.closest("th[pvt-header]")
      ) {
        let mousePosition = { x: event.pageX, y: event.pageY };
        let element = event.srcElement.closest("th[pvt-header]");

        if (!$(element.closest("th")).hasClass("rowTotal")) {
          let value = $(element).attr("realvalue");
          let type = $(element).attr("header-type");
          let dataColumn = $(element).attr("pvt-header");
          let clickedColumn = this.getClickedColumn(element);
          let pluginData = [...THIS.props.plugin.data];
          let clickedData = undefined;

          for (let datum of pluginData) {
            if (datum["formatted##" + dataColumn] === value) {
              clickedData = datum;
              
              break;
            }
          }

          if (clickedData) {
            value = clickedData["original##" + dataColumn]
          }

          let data = { columns: [], rows: [] };

          if (dataColumn === "Values") {
            return;
          }

          this.columnMap[type].map((item) => {
            let column = {};

            column.name = item.displayName;

            if (dataColumn === item.displayName) {
              column.value = value;
            } else {
              column.value = "";
            }

            data[type].push(column);
          });

          createTrigger(
            actions,
            this.getColumnMap(this.columnMap),
            this.container,
            "click",
            data,
            this.pluginId,
            this.props.interactions,
            this.props.navigations,
            mousePosition,
            null,
            this.drillDowns,
            clickedColumn
          );
        }
      }
    }
  };

  /** Mouse listener for click outside */
  componentDidMount() {
    document.addEventListener("mousedown", (event) => this.clickCreateTrigger(event, this));
  }

  /** Mouse listener for click outside */
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickCreateTrigger);
  }

  /**
   *
   * @param {*} plugin
   * Calculate the height of the plugin
   */
  calculatePluginHeight = (plugin, settings) => {
    let containerHeight = settings.grid.rowHeight * plugin.h;
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let pluginMinHeightDifference = 15;
    let settingHeight = 24;
    let totalGridBottom = 25;
    let maxHeight =
      containerHeight -
      (pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        parseInt(pluginTitleContainer.css("padding-bottom")) +
        settingHeight +
        pluginContainerBorder +
        totalGridBottom +
        pluginMinHeightDifference);

    return maxHeight;
  };

  changeStatusRerenderProcessStarted = (status) => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };

    this.setCallBackObject({});

    return tmpCallBackObject;
  };

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );

    if (this.props.plugin.interactions !== nextProps.plugin.interactions) {
      this.setPluginInformation(nextProps);
    }
  }

  getNavigationComponent = (props) => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  getConfigComponent = (props) => {
    if (props.config) {
      return (
        <PivotTableConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig= {this.props.setCurrentAppliedConfig}
          currentAppliedConfig = {this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = (props) => {
    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <PivotTableData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={props.plugin.columnMap}
        pluginId={props.plugin.id}
        plugin={props.plugin}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(
          this.props.join,
          this.props.plugin.columnMap,
          this.props.refreshedPluginId,
          this.props.plugin.id,
          true
        )}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={
          props.changeDoesPluginHasNotJoinedTable
        }
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        drillDowns={this.props.plugin.drillDowns}
        navigations={props.navigations}
        refreshPlugin={this.props.refreshPlugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = (tempPlugin) => {
    let columnMapping = {
      rows: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Rows.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Rows.Desc"),
        type: "any",
        data: [],
      },
      columns: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Columns.Name"),
        multiple: true,
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Columns.Desc"),
        type: "any",
        data: [],
      },
      measures: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measures.Name"),
        multiple: true,
        minimumColumnSize: 1,
        type: "fact",
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measures.Desc"),
        required: true,
        conditionalFormat: true,
        config: [
          {
            targetProperty: "aggregator",
            label: "Default Aggregator",
            inputType: "dropdown",
            inputOptions: {
              multiSelect: false,
              values: [
                "Count",
                "Count Unique Values",
                "List Unique Values",
                "Sum",
                "Integer Sum",
                "Mean",
                "Median",
                "Minimum",
                "Maximum",
                "Sum as % of Total",
                "Sum as % of Rows",
                "Sum as % of Columns",
                "Count as % of Total",
                "Count as % of Rows",
                "Count as % of Columns",
              ],
              defaultValue: "Sum",
            },
            desc: "desc172",
          },
          {
            targetProperty: "hide",
            label: "Hide Values",
            inputType: "checkbox",
            inputOptions: {
              defaultValue: false,
            },
            desc: "desc173",
          },
        ],
        data: [],
        dataField: {
          Config: {
            //TODO: This area is set statically and will be changed later.
            Plugin: "pivot-table",
            aggregator: "Sum",
            hide: false,
          },
        },
      },
      hidden: {
        name: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.HiddenMeasures.Name"
        ),
        type: "hidden",
        desc: i18n.t(
          "Plugins." + tempPlugin.key + ".ColumnMap.HiddenMeasures.Desc"
        ),
        multiple: true,
        minimumColumnSize: 0,
        data: [],
      },
    };
    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  getConditionalFormattingComponent = (props) => {
    let columnMap = this.props.plugin.columnMap
    
    // Eski silinen kolonlar id ile geldiği için comment yapılmıştır. #1112 issue. 
    // let hasColumnMapAnyColumn = findColumnMapHasAnyColumn(props.plugin.columnMap)
    // let isPluginNewPlugin = this.props.isNewPlugin ? true : false

    // if (hasColumnMapAnyColumn) {
    //   if (isPluginNewPlugin) {
    //     columnMap = props.plugin.columnMap
    //   } else {

    //     columnMap = this.props.columnMapWithLocationFieldName
    //   }
    // } else {
    //   columnMap = props.plugin.columnMap
    // }

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        pluginId={props.plugin.id}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
      />
    );
  };

  convertMeasureName = (measures) => {
    let measureNamesArray = [];
    let perMeasureName = "";
    let status = false;

    for (let i = 0; i < measures.length; i++) {
      if (measures[i] === ".") {
        status = true;
      }

      if (status === true && measures[i] !== "." && measures[i] !== "}") {
        perMeasureName += measures[i];
      }

      if (measures[i] === "}") {
        status = false;
        measureNamesArray.push(perMeasureName);
        perMeasureName = "";
      }
    }

    return measureNamesArray;
  };

  /**
   * To convert general conditional format object to plugin requirements.
   */
  convertFormatConditionalFormatting = (condFormats, columns) => {
    let condFormatList = [];

    condFormats.map((condItem) => {
      condItem.targetColumns.map((targetColumn) => {
        let conditionalFormat = {};
        let columnsMap = new Map();

        columns.map((column) => {
          columnsMap.set(column.uniqeColumnId, column);
        });
        
        conditionalFormat.RightRule = condItem.rule.rightRule.rule;
        conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
        conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName  === "{undefined}" ? convertHTMLRuletoRule(condItem.rule.leftRule).ruleColumnName : condItem.rule.leftRule.ruleColumnName;
        conditionalFormat.RightRuleColumnName =
          condItem.rule.rightRule.ruleColumnName;
        conditionalFormat.Columns = columnsMap;
        conditionalFormat.TargetID = targetColumn.TargetID;
        conditionalFormat.TargetName = targetColumn.TargetName;
        conditionalFormat.TargetColumnId = targetColumn.uniqeColumnId
        conditionalFormat.TargetColumnName = targetColumn.locationFieldName;
        conditionalFormat.SelectedLeftRules = this.convertMeasureName(
          condItem.rule.leftRule.ruleColumnName
        );
        conditionalFormat.Operator = condItem.rule.operator;
        conditionalFormat.id = condItem.id;

        conditionalFormat.Style = {
          colour: condItem.options.color,
          icon: condItem.options.icon,
          background: { colour: condItem.options.backgroundColor },
        };

        if (condItem.options.text) {
          conditionalFormat.Style.text = {
            bold: condItem.options.text.bold,
            italic: condItem.options.text.italic
          };
        } else {
          conditionalFormat.Style.text = {
            bold: false,
            italic: false 
          }
        }
        
        condFormatList.push(conditionalFormat);
      });
    });

    return condFormatList;
  };

  pluginRender = (
    divId,
    data,
    columnMap,
    config,
    condFormats,
    filters
  ) => {
    if (condFormats !== undefined && condFormats.length > 0) {
      let columnMapsAllValues = [];

      const loopThroughColumnMap = (measureColumn) => {
        for (let i = 0; i < measureColumn.length; i++) {
          columnMapsAllValues.push(measureColumn[i]);
        }
      };

      loopThroughColumnMap(columnMap.measures);
      loopThroughColumnMap(columnMap.rows);
      loopThroughColumnMap(columnMap.columns);

      condFormats = this.convertFormatConditionalFormatting(
        condFormats,
        columnMapsAllValues
      );
    } else {
      condFormats = [];
    }

    if(config.isColSizeMustConstant == undefined){
      config.isColSizeMustConstant = true
    }

    // Set html empty and set this html to container.
    $("#" + divId).html("");
    let container = $("#" + divId)[0];
    this.container = container;
    this.columnMap = columnMap;

    // pivotExtensions();

    var THIS = this
    var sortedAttrs = rmvpp.uniqueDims(data, columnMap); // Get object of unique dimension attributes, ordered as by OBIEE

    $(container).append('<div class="pivot-table"></div>');
    var pivotTable = $(container).find(".pivot-table");
    let currentRow = 0;
    let currentSum = 0;
    var pivotData = [];
    var subTotalData = [];
    let rowGroupChanged = false;
    let prevRowValue = "";
    let counter = data.length - 1;
    
    function getSubDataForTable(subTotalData) {
      for (let i = 0; i < subTotalData.length; i++)
        pivotData.push(subTotalData[i]);
    }
    
    data.forEach(function (row) {
      let rowObj = {};
      let temp = {};

      if (
        row.rows[0] != undefined &&
        row.rows[0].value != currentRow &&
        row.rows[0].value != undefined
      ) {
        currentRow = row.rows[0].value;
        rowGroupChanged = true;
        currentSum = 0;
      }

      row.rows.forEach(function (r, i) {
        rowObj[r.name] = getFormattedValue(columnMap.rows[i], r.value);

        row["original##" + r.name] = r.value;
        row["formatted##" + r.name] = getFormattedValue(columnMap.rows[i], r.value);

        if (r.name === columnMap.rows[0].Name)
          temp[r.name] = currentRow + " " + i18n.t("Total") + "#SubTotal#";
        else {
          temp[r.name] = "";
        }
      });

      row.columns.forEach(function (c, i) {
        let currentColumn = columnMap.columns[i];
        let formattedValue = getFormattedValue(currentColumn, c.value);
        rowObj[c.name] = formattedValue;

        row["original##" + c.name] = c.value;
        row["formatted##" + c.name] = getFormattedValue(currentColumn, c.value);

        temp[c.name] = formattedValue;
      });

      row.measures.forEach(function (m, i) {
        rowObj[m.name] = m.value;

        row["original##" + m.name] = m.value;
        row["formatted##" + m.name] = getFormattedValue(columnMap.measures[i], m.value);

        if (rowGroupChanged) {
          currentSum = m.value;
          temp[m.name] = currentSum;
        } else currentSum += m.value;
      });

      row.hidden.forEach(function (h, i) {
        rowObj[h.name] = h.value;

        row["original##" + h.name] = h.value;
        row["formatted##" + h.name] = getFormattedValue(columnMap.hidden[i], h.value);

        temp[h.name] = h.value;
      });

      currentSum = 0;

      if (config.subTotal && columnMap.rows.length != 0) {
        if (prevRowValue != currentRow) {
          if (counter != data.length - 1) {
            getSubDataForTable(subTotalData);
          }

          pivotData.push(rowObj);
          subTotalData = [];
        } else {
          pivotData.push(rowObj);

          if (counter == 0) {
            subTotalData.push(temp);
            getSubDataForTable(subTotalData);
          }
        }

        counter--;
        prevRowValue = currentRow;
        subTotalData.push(temp);
      } else {
        pivotData.push(rowObj);
      }
    });

    var measureAggregators = {},
      hideVals = [];
    columnMap.measures.forEach(function (m) {
      measureAggregators[m.Name] = m.Config.aggregator;
      if (m.Config.hide) hideVals.push(m.Name);
    });

    if (config.autoSize) {
      config.width = "100%";
    }

    sizeToFitOrAutoSize();
    
    // Render pivot table
    pivotTable.pivotUI(pivotData, 
      {
      vals: columnMap.measures.map(function (d) {
        return d.Name;
      }),
      rows: columnMap.rows.map(function (d) {
        return d.Name;
      }),
      cols: columnMap.columns.map(function (d) {
        return d.Name;
      }),
      hidden: columnMap.hidden.map(function (d) {
        return d.Name;
      }),
      columnMapCopy: this.props.plugin.columnMap,
      valueCol: config.valuesAsCols,
      sortedAttrs: sortedAttrs,
      aggregatorName: config.defaultaggregator,
      measureAggregators: measureAggregators,
      hideVals: hideVals,
      rendererOptions: {
        config: config,
        autoSizePivot: this.props.plugin.columnMap.columns.autoSize,
        sizeToFitPivot: this.props.plugin.columnMap.columns.sizeToFit,
        columnMap: columnMap,
        pluginId: this.props.plugin.id,
        colour: config.themeColour,
        rawData: data,
        condFormats: condFormats,
        height: config.height,
        columnWidth: config.isColSizeMustConstant == undefined || config.isColSizeMustConstant ? "" : config.columnWidth,
        columnHeight: config.isColSizeMustConstant == undefined || config.isColSizeMustConstant ? "" : config.columnHeight,
        valueFontSize: config.valueFontSize == undefined || config.valueFontSize == "" 
          ? "11" 
          : +config.valueFontSize >= 12 
            ? config.valueFontSize 
            : 12,
        valueFontWeight: config.valueFontWeight == undefined ? false : config.valueFontWeight,
        valueFontStyle: config.valueFontStyle == undefined ? false : config.valueFontStyle,
        valueFontDecor: config.valueFontDecor == undefined ? false : config.valueFontDecor,
        headerFontWeight: config.headerFontWeight == undefined ? false : config.headerFontWeight,
        headerFontStyle: config.headerFontStyle == undefined ? false : config.headerFontStyle,
        headerFontDecor: config.headerFontDecor == undefined ? false : config.headerFontDecor,
        totalFontWeight: config.totalFontWeight == undefined ? false : config.totalFontWeight,
        totalFontStyle: config.totalFontStyle == undefined ? false : config.totalFontStyle,
        totalFontDecor: config.totalFontDecor == undefined ? false : config.totalFontDecor,
        headerFont: config.headerFont == undefined ? "Verdana" : config.headerFont,
        totalFontColour: config.totalFontColour == undefined ? "rgba(1, 0, 0, 1)" : config.totalFontColour,
        totalFontSize: config.totalFontSize == undefined ? "rgba(1, 0, 0, 1)" : config.totalFontSize,
        totalFontFamily: config.totalFontFamily == undefined ? "Verdana" : config.totalFontFamily,
        colColor: config.colColor == undefined ? "rgba(1,0,0,1)" : config.colColor,
        rowColor: config.rowColor == undefined ? "rgba(1,0,0,1)" : config.rowColor,
        valueFontFamily: config.valueFontFamily == undefined ? "Verdana" : config.valueFontFamily,
        valueColor: config.valueColor == undefined ? "rgba(1,0,0,1)" : config.valueColor,
        defaultDrilldownColor: config.defaultDrilldownColor == undefined ? true : config.defaultDrilldownColor,
        settedDrilldownColor: config.settedDrilldownColor == undefined ? "rgba(255, 255, 255, 1)" : config.settedDrilldownColor,
        valuesAsCols: config.valuesAsCols,
        autoSize: config.autoSize,
        wrapHeader: config.wrapHeader,
        showSum: config.showSum,
        sumSelector: config.sumSelector,
        subTotal: config.subTotal,
        showCornerTotal: config.showCornerTotal
      },
      rendererName: config.renderer,
    });

    var tooltip = new rmvpp.Tooltip(container);

    var pivotContainer = $(container).find(".pivot-table");
    var pivotId = uuid();
    pivotContainer.attr("id", "pivot-table-" + pivotId);
    pivotContainer.find(".pvtAxisContainer").hide();
    pivotContainer.find(".pvtVals").hide();
    pivotContainer.find(".pvtRenderer").hide();
    pivotContainer.find(".excludeLabel").hide();
    if (config.controls) {
      // Add controls toolbar
      var toolbar = $('<div class="pvtToolbar"></div>');
      //let configurationKey = window.i18next.t('ConfigurationKey');
      let configurationKey = i18n.t("Dashboard.Configuration.Configuration");

      rmvpp.iconButton(
        toolbar,
        "cog",
        configurationKey,
        tooltip,
        "#1581F1",
        function () {
          let pvtUI = pivotContainer.find(".pvtUi");
          if (pvtUI.find(".pvtAxisContainer").css("display") == "none") {
            pvtUI
              .find(".pvtAxisContainer, .pvtVals, .pvtRenderer, .excludeLabel")
              .show();
          } else {
            pvtUI
              .find(".pvtAxisContainer, .pvtVals, .pvtRenderer, .excludeLabel")
              .hide();
          }
        }
      );

      $(toolbar).append('<span style="margin-right: 10px; opacity: 0.8; cursor: default;" class="toolbar-divider">|</span>');
     
      rmvpp.renderButton(
        toolbar,
        '<i style="margin-right: 10px; position: static; right: 30px; cursor: pointer;" class="fa fa-magic"></i>',
        i18n.t("Dashboard.Configuration.Fields.AutoSize"),
        tooltip,
        autoSizeAll
      );

      rmvpp.renderButton(
        toolbar,
        '<i style="cursor: pointer;position: static; right: 10px;" class="fa fa-arrows-alt-h"></i>',
        i18n.t("Dashboard.Configuration.Fields.SizeToFit"),
        tooltip,
        sizeToFit
      );

      pivotContainer.prepend(toolbar);
    }

    let visWidth = "100%";

    if (condFormats.length > 0 && config.condFormat) {
      let showCondFormHeight = config.columnHeight + 10;
      let showCondForm = $(
        '<div id="showCondForm" style="height: ' +
          showCondFormHeight +
          "px" +
          "; width: " +
          visWidth +
          ';" class="apexcharts-legend left position-bottom "><div id="showCondFormInline" style="width: 90%"></div></div>');
      
      $(container).append(showCondForm);
      $(container)
        .find("#showCondForm")
        .css("height", "")
        .css("margin", "10px 0px 10px 0px");

      addCondFormLegend();
    }

    if (config.pivotTableDescription.length > 0) {
      $(container).find(".noteSection").remove();
      $(container).append(
        `<ul class="noteSection" style="width: ${visWidth}; list-style-type: none; padding: 0; margin: 0;"></li>`
      );

      config.pivotTableDescription.split("#").map((item) =>
        $(container).find(".noteSection")
          .append(`<li class="ag-rm ag-cell-no-focus" 
          style="border: 1px solid #ddd; margin-top: -1px; background-color: white; padding: 4px; word-wrap: break-word; white-space: normal;">
          ${item} </li>`)
      );
    }

    if (vispeahen.Edit) {
      // Get column from its name, regardless of whether row or column originally
      function getColFromName(name) {
        var col = columnMap.rows.filter(function (r) {
          return r.Name == name;
        });
        if (col.length > 0) return col[0];

        var col = columnMap.columns.filter(function (r) {
          return r.Name == name;
        });
        if (col.length > 0) return col[0];
      }

      // Check for refresh events from the pivot table and updates the column map
      $(container)
        .find("div.pivot-table")
        .on("refreshPivot", function (e, opts) {
          var rows = [],
            columns = [];
          opts.rows.forEach(function (row) {
            if (row != "Values") rows.push(getColFromName(row));
            else config.valuesAsCols = false;
          });

          opts.cols.forEach(function (col) {
            if (col != "Values") columns.push(getColFromName(col));
            else config.valuesAsCols = true;
          });

          columnMap.rows = rows;
          columnMap.columns = columns;

          // Update measure aggregator from UI
          $(container)
            .find(".pvtVals>li")
            .each(function () {
              var measure = $(this).find(".pvtAttr").text();
              var measureCol = columnMap.measures.filter(function (m) {
                return m.Name == measure;
              })[0];
              measureCol.Config.Plugin = pluginName; // Mark column config with the plugin (necessary to tie with UI)
              measureCol.Config.aggregator = $(this)
                .find(".pvtAggregator")
                .val();
              measureCol.Config.hide = $(this)
                .find(".toggleVal")
                .hasClass("hideVal");
            });

          //autoSize for pivot
          if (config.autoSize) {
            config.width = $(container)[0].clientWidth;
            config.height = $(container)[0].clientHeight;
          }

          //wrapHeader for pivot
          if (config.wrapHeader) {
            var pivotContainer = $(container).find(".pivot-table");
            var headers = pivotContainer.find(".pvtColLabel");
            var increaseHeader = false,
              scales = [];

            for (let i = 0; i < headers.length; i++) {
              let label = $(headers[i]).find(".textbox");
              var origHeight = label.height();
              label.css("white-space", "nowrap");
              scales.push(Math.round(origHeight / label.height()));
              label.css("white-space", "normal");
            }

            var maxScale = d3.max(scales);
            var newHeight = maxScale * 17 + 8;
            if (config.columnHeight != newHeight) {
              config.columnHeight = newHeight;
              //  $(container).find('.ag-body').css('padding-top', gridOptions.headerHeight + 'px');
              //  $(container).find('.ag-header, .ag-header-row').css('height', gridOptions.headerHeight + 'px');
            }
          }

          config.renderer = opts.rendererName;
          vispeahen.applyChanges();
        });
    }

    $(container).find(".fa-magic").css("color", this.props.plugin.columnMap.columns.autoSize  && config.isColSizeMustConstant  ? "#FFA500" : "black");
    $(container).find(".fa-arrows-alt-h").css({"color": this.props.plugin.columnMap.columns.sizeToFit   && config.isColSizeMustConstant ? "#FFA500" : "black"});
    
     /**
     * Updates columnMap for columns auto size and size to fit
     *
     * @param {*} columnMap
     */
    function updatePlugin(columnMap) {
      let updatePlugin = {
        columnMap: columnMap,
        columnMapForPlugin: THIS.props.plugin.columnMapForPlugin,
        sortedColumnList: THIS.props.plugin.sortedColumnList,
        pluginId: divId,
        conditionalFormats: THIS.props.plugin.conditionalFormats,
      };

      THIS.props.updatePlugin("columnMap", updatePlugin);
    }

    /**
     * Auto size columns
     *
     * @param {*} toggle
     */
    function autoSizeAll(toggle = true) {
      let columnMapCopy = { ...THIS.props.plugin.columnMap };

      columnMapCopy.columns.sizeToFit = false;
      columnMapCopy.columns.autoSize = toggle ? !columnMapCopy.columns.autoSize : true;

      $(container).find(".fa-arrows-alt-h").css("color", "black");
      $(container).find(".fa-magic").css("color", columnMapCopy.columns.autoSize === true ? "#FFA500" : "black");

      if (!toggle && !config.isColSizeMustConstant) {
        columnMapCopy.columns.autoSize = false;
        columnMapCopy.columns.sizeToFit = false;
      }

      updatePlugin(columnMapCopy);

      let newPlugin = THIS.props.plugin;

      newPlugin.rerender = true;

      if (toggle) {
        newPlugin.config.isColSizeMustConstant = true;
      }

      THIS.props.updatePlugin("renderUpdate", newPlugin);
    }

    /**
     * Size columns to fit table
     *
     * @param {*} toggle
     */
    function sizeToFit(toggle = true) {
      let columnMapCopy = { ...THIS.props.plugin.columnMap };

      columnMapCopy.columns.autoSizeReApply = true;
      columnMapCopy.columns.autoSize = false;
      columnMapCopy.columns.sizeToFit = toggle ? !columnMapCopy.columns.sizeToFit : true;

      $(container).find(".fa-magic").css("color", "black");
      $(container).find(".fa-arrows-alt-h").css("color", columnMapCopy.columns.sizeToFit === true ? "#FFA500" : "black");

      if (!toggle && !config.isColSizeMustConstant) {
        columnMapCopy.columns.autoSize = false;
        columnMapCopy.columns.sizeToFit = false;
      }

      updatePlugin(columnMapCopy);

      let newPlugin = THIS.props.plugin;

      newPlugin.rerender = true;

      if (toggle) {
        newPlugin.config.isColSizeMustConstant = true;
      }

      THIS.props.updatePlugin("renderUpdate", newPlugin);
    }

    function sizeToFitOrAutoSize() {
      if (THIS.props.plugin.columnMap.columns.sizeToFit !== false) {
        sizeToFit(false);
      } else if (THIS.props.plugin.columnMap.columns.autoSize === true) {
        autoSizeAll(false);
      }
    }

    /**
     * Callback function for preparing table pdf title and generated date.
     * @param {*} pdf 
     * @param {*} a4Sizes 
     * @param {*} newPluginTitle 
     * @param {*} exportDate 
     */
    let callBackOfPivotPdfExport = (
      pdf,
      a4Sizes,
      newPluginTitle,
      exportDate
    ) => {
      let pagesOfPdf = pdf.getNumberOfPages();
      let titlePositionY = 40;

      pdf.addFileToVFS("Roboto-Regular.ttf", ROBOTOREGULAR);
      pdf.addFont("Roboto-Regular.ttf", "Roboto-Regular-normal", "normal");
      pdf.setTextColor(config.titleColour);
      pdf.setFontSize(15);
      pdf.setPage(1);
      
      if (config.titleFontWeight && config.titleFontStyle) {
        pdf
          .setFont(undefined, 'bolditalic')
      } else if (config.titleFontStyle) {
        pdf
          .setFont(undefined, 'italic')
      } else if (config.titleFontWeight) {
        pdf
          .setFont(undefined, 'bold')
      }

      newPluginTitle = pdf.splitTextToSize(this.props.title, 775); 
      let titleColumnSize = newPluginTitle.length > 3 ? 3 : newPluginTitle.length;

      for (let i = 0; i < titleColumnSize; i++) {
        if (newPluginTitle[i] !== undefined) {
          if (newPluginTitle.length > 3 && i === 2) {
            newPluginTitle[2] += "...";
          }

          pdf.text(newPluginTitle[i], 10, titlePositionY);

          if (config.titleTextDecor) {
            let textWidth = pdf.getTextWidth(newPluginTitle[i]);
            
            pdf.setDrawColor(config.titleColour);
            pdf.setLineWidth(1);  
            pdf.line(10, titlePositionY + 2, 10 + textWidth, titlePositionY + 2);
          }
          
          titlePositionY += 14;
        }
      }
      
      pdf
        .setFont(undefined, 'normal')
        .setTextColor('black');

      pdf.text(
        exportDate + ": " + rmvpp.generateDate(),
        a4Sizes.width - 260,
        20
      );

      for (; pagesOfPdf > 0; pagesOfPdf--) {
        pdf.setPage(pagesOfPdf);
        pdf.addImage(
          vispeahenLogo,
          "PNG",
          a4Sizes.width - 120,
          a4Sizes.height - 25
        );
      }
    };

    /**
     * Pivot table pdf export.
     */
    const pdfExport = () => { 
      let pdf, a4Sizes, landScape, title; 
      let pluginTitle = this.props.title; 
      let realTable = $(container).find("table[class=pvtTable]")[0]; 
      let exportDate = i18n.t("Dashboard.Configuration.Fields.ExportDate"); 
      let tableSelect = document.createElement("table"); 
      let topBorderControlIndex = 0;
      let columnsByProps = THIS.props.plugin.columnMap.columns
       
      tableSelect.id = "copy-pivot"; 

      $(container).find("table[class=pvtTable]").parent().append(tableSelect); 
      $(tableSelect) 
        .addClass("pvtTable") 
        .addClass("pvtAxisContainer") 
        .html($(realTable).html()); 

      function textSpaced(targetHeader) {
        $(tableSelect).find(targetHeader).each(function() {
          $(this).find("th").each(function () {
            let innerText = $(this).text();
            let textSpaced = innerText.replace(/ /g, '<span style="visibility : hidden;">__ </span>');
            let text = $(this).html()
            let newText = text.replace(innerText, textSpaced);
  
            $(this).html(newText);
          })
        });
      }

      textSpaced(".colHeader>table>tbody>tr");
      textSpaced(".rowHeader>table>tbody>tr");
      
      $(tableSelect).find("th,.textbox,td").css("overflow", "hidden");
      
      $(tableSelect).find(".columnName").each(function() {
        let fontSize = config.valueFontSize && config.valueFontSize >= 11 ? config.valueFontSize : 11;
        let scrollWidthColumn = $(this).width()
        let limitCharacterOfColumn = scrollWidthColumn / fontSize;
        let innerText = $(this).text();
        let text = $(this).html();
        let shouldGiveThreePoint = innerText.length > limitCharacterOfColumn && !config.wrapHeader && !columnsByProps.autoSize
        
        if (shouldGiveThreePoint) {
          let textOfColumn = innerText.substring(0, limitCharacterOfColumn) + "...";

          text = text.replace(innerText, textOfColumn);
        }
        
        let newText = text.replace(/ /g, '<span style="visibility : hidden;">__ </span>');

        newText = newText.replace("...",'<span style="visibility : hidden;">-</span>...');

        $(this).html(newText);
      });
       
      if (config.wrapHeader) {
        let heightOfHeaders = new Array(2).fill(0);
        let headerLayersAll = $(tableSelect)
          .find(".colHeader>table>tbody")
          .children();

        $(tableSelect)
          .find(".firstTd>.columnName")
          .each(function () {
            let height = this.scrollHeight;

            heightOfHeaders[0] = Math.max(heightOfHeaders[0], height);
          });

        $(headerLayersAll).each(function () {
          heightOfHeaders[1] += $(this).height();
        });

        let heightOfHeader = d3.max(heightOfHeaders);

        $(tableSelect).find(".firstTd").css({
          "max-height": heightOfHeader,
          "min-height": heightOfHeader,
        });

        $(tableSelect).find(".colHeader").css({
          "max-height": heightOfHeader,
          "min-height": heightOfHeader,
        });

        $(tableSelect).find(".columnName").css({ overflow: "" });

        if (heightOfHeaders[0] >= heightOfHeaders[1]) {
          $(tableSelect).find(".firstTd").css({
            "align-items": "",
          });

          let innerHeightLayers = 0;

          $(headerLayersAll).each(function () {
            innerHeightLayers += $(this).height();
          });

          let moreHeightForColumns =
            (heightOfHeader - innerHeightLayers) / headerLayersAll.length;

          $(headerLayersAll).each(function () {
            let headerLayerHeight = $(this).height();
            let height = headerLayerHeight + moreHeightForColumns;

            $(this)
              .find("th")
              .each(function () {
                $(this).css({
                  height: height,
                });
              });
          });
        } else if (heightOfHeaders[1] >= heightOfHeaders[0]) {
          $(headerLayersAll).each(function () {
            let headerLayerHeight = $(this).height();

            $(this)
              .find("th")
              .each(function () {
                $(this).css({
                  height: headerLayerHeight,
                });
              });
          });
        }
      }  

      const pivotPdfBorderStyle = "1px solid #CDCDCD"; 
      let scrollHeightOfTable = tableSelect.scrollHeight; 
      let scrollWidthOfTable = tableSelect.scrollWidth; 

      landScape = scrollWidthOfTable > scrollHeightOfTable; 

      if (landScape === true) { 
        a4Sizes = { width: 842, height: 585 }; 
        pdf = new jsPDF("l", "pt", "a4", true); 
      } else { 
        a4Sizes = { width: 585, height: 842 }; 
        pdf = new jsPDF("p", "pt", "a4", true); 
      } 

      $(tableSelect).find(".fab").remove(); 
      
      if (columnMap.columns.length === 0) {
        $(tableSelect).find(".rowTotals").remove();
      }

      let fontFamilyForValues =
        config.valueFontWeight && config.valueFontStyle
          ? "Roboto-BoldItalic"
          : config.valueFontWeight
            ? "Roboto-Bold"
            : config.valueFontStyle
              ? "Roboto-Italic-normal"
              : "Roboto-Regular";

      tableSelect.querySelectorAll(".pvtVal").forEach((element) => { 
        element.style.fontFamily = fontFamilyForValues;
        element.style.textAlign = "center"; 
        element.style.borderleft = pivotPdfBorderStyle; 
        element.style.borderBottom = pivotPdfBorderStyle; 
        element.style.borderRight = pivotPdfBorderStyle; 
        element.style.pading = "0px";

        if (topBorderControlIndex === 0) { 
          element.parentElement.style.borderTop = pivotPdfBorderStyle; 
          topBorderControlIndex++; 
        } 
      }); 

      $(tableSelect)
        .find(".pvtVal")
        .find(".textbox")
        .css("text-decoration", config.valueFontDecor ? "underline" : "");

      let fontFamilyForRowCol =
        config.headerFontWeight && config.headerFontStyle
          ? "Roboto-BoldItalic"
          : config.headerFontWeight
            ? "Roboto-Bold"
            : config.headerFontStyle
              ? "Roboto-Italic-normal"
              : "Roboto-Regular";

      tableSelect.querySelectorAll(".pvtColLabel").forEach((element) => {
        element.style.fontFamily = fontFamilyForRowCol;
      }); 

      tableSelect.querySelectorAll("th").forEach((element) => {
        element.style.fontFamily = fontFamilyForRowCol;
        element.style.textAlign = "left";
      });

      tableSelect.querySelectorAll(".pvtTotalLabel").forEach((element) => {
        element.style.textAlign = "center";
      });

      tableSelect.querySelectorAll(".columnName").forEach((element) => {
        element.style.textAlign = "center";
        element.style.fontFamily = fontFamilyForRowCol;
      });

      let widthOfPvtVal;
      
      if (!config.isColSizeMustConstant) {
        widthOfPvtVal = $(tableSelect)
          .find(".pvtVal")
          .find(".textbox")
          .css("width");
        widthOfPvtVal = parseInt(
          widthOfPvtVal.substring(0, widthOfPvtVal.indexOf("p"))
        );
      }

      topBorderControlIndex = 0; 
      let fontFamilyForTotals =
        config.totalFontWeight && config.totalFontStyle
          ? "Roboto-BoldItalic"
          : config.totalFontWeight
            ? "Roboto-Bold"
            : config.totalFontStyle
              ? "Roboto-Italic-normal"
              : "Roboto-Regular";
      let widthOfRowTotal = $(tableSelect)
        .find(".pvtTotal.rowTotal")
        .css("width");

      tableSelect.querySelectorAll(".pvtTotal.rowTotal").forEach((element) => {
        element.style.fontFamily = fontFamilyForTotals;
        element.style.textAlign = "center";
        element.style.width = widthOfRowTotal;
        element.style.borderleft = pivotPdfBorderStyle; 
        element.style.borderBottom = pivotPdfBorderStyle; 
        element.style.borderRight = pivotPdfBorderStyle; 
 
        if (topBorderControlIndex === 0) {
          element.parentElement.style.borderTop = pivotPdfBorderStyle; 
        } 
 
        topBorderControlIndex++; 
      }); 
 
      let countColumnTotalsRow = $(tableSelect).find(".colTotals>table>tbody>tr").length;

      topBorderControlIndex = 1;

      tableSelect.querySelectorAll(".pvtTotal.colTotal").forEach((element) => { 
        element.style.fontFamily = fontFamilyForTotals;
        element.style.textAlign = "center"; 
        element.style.height = "29px"; 

        if (countColumnTotalsRow !== 1) {
          let isLastRow =topBorderControlIndex / countColumnTotalsRow < countColumnTotalsRow;

          if (isLastRow) {
            element.style.borderleft = pivotPdfBorderStyle;
            element.style.borderRight = pivotPdfBorderStyle;
            element.style.borderTop = pivotPdfBorderStyle;
          } else {
            element.style.borderTop = pivotPdfBorderStyle;
            element.style.borderleft = pivotPdfBorderStyle;
            element.style.borderBottom = pivotPdfBorderStyle;
            element.style.borderRight = pivotPdfBorderStyle;
          }
        } else {
          element.style.borderleft = pivotPdfBorderStyle;
          element.style.borderBottom = pivotPdfBorderStyle;
          element.style.borderRight = pivotPdfBorderStyle;
          element.style.borderTop = pivotPdfBorderStyle;
        }
        
        topBorderControlIndex++;
      });

      let countColumnOfCornerTotals = $($(tableSelect).find(".cornerTotals>table>tbody>tr")[0]).children().length;

      topBorderControlIndex = 0;

      if (config.showCornerTotal) {
        tableSelect.querySelectorAll(".cornerTotal").forEach((element) => { 
          element.style.fontFamily = fontFamilyForTotals;
          element.style.textAlign = "center"; 
          element.style.height = "29px"; 
          element.style.pading = "0px";

          if (countColumnOfCornerTotals !== 1) {
            if (topBorderControlIndex % countColumnOfCornerTotals === 0) {
              element.style.border = pivotPdfBorderStyle;
            } else {
              element.style.borderBottom = pivotPdfBorderStyle;
              element.style.borderRight = pivotPdfBorderStyle;
              element.style.borderTop = pivotPdfBorderStyle;
            }
          } else {
            if (topBorderControlIndex === 0) {
              element.style.border = pivotPdfBorderStyle;
            } else {
              element.style.border = pivotPdfBorderStyle;
              element.style.borderTop = "";
            }
          }
          
          topBorderControlIndex++;
        });
      }

      let widthForColTotals = [];

      $($(tableSelect).find(".content>table>tbody>tr")[0])
        .find("td")
        .each(function (i) {
          let width = $(this).outerWidth();

          widthForColTotals[i] = width;
        });

      let sizeToFitColumnMap = columnsByProps.sizeToFit;

      if (sizeToFitColumnMap) {
        tableSelect
          .querySelectorAll(".pvtTotal.colTotal")
          .forEach((element, i) => {
            element.style.width = widthForColTotals[i];
          });
      }

      $(tableSelect).find(".content").css("borderBottom", "0px"); 
      $(tableSelect).find("div[class = rowTotals]").css({ 
        position: "relative", 
        top: "-0.7px", 
        left: "-1px", 
      }); 

      $(tableSelect).find("div[class = cornerTotals]").css({ 
        position: "relative",  
        left: "-1px", 
      }); 
 
      let newPluginTitle = pdf.splitTextToSize(pluginTitle, a4Sizes.width); 
      let maringTopTable = newPluginTitle.length > 1 ? 75 : 50; 
      let witdhOfTableOnPage = landScape 
        ? a4Sizes.width - 20 
        : scrollWidthOfTable > 250 
          ? a4Sizes.width - 20 
          : a4Sizes.width - 250; 
      let worker = pdf.html(tableSelect, { 
        callback: function (pdf) {
          callBackOfPivotPdfExport(pdf, a4Sizes, newPluginTitle, exportDate);
        },
        x: 0, 
        y: 0, 
        autoPaging: true, 
        width: witdhOfTableOnPage, 
        margin: [maringTopTable, 10, 55, 10], 
        // If huge table is wanted to print to pdf we should clip table because it doesnt fill in a page and the table couldnt print correctly on pdf page. So the limit is this If table bigger than 7000px we take 
        // 4450 px of the table
        windowWidth: scrollWidthOfTable > 7000 ? 4450 : scrollWidthOfTable, 
      }); 

      /** Pdf file name */
      if (pluginTitle === i18n.t("TitleNotSet") || pluginTitle === "") {
        title = "pivot-table.pdf";
      } else {
        title = pluginTitle + ".pdf";
      }

      worker.save(title); 
      $("#copy-pivot").remove(); 
    }

    function prepareConditionalLegends(conditionalLegendDiv) {
      conditionalLegendDiv.setAttribute("class", "apexcharts-legend-series");
      conditionalLegendDiv.setAttribute("style", "margin: 0px 5px;");
    }

    function prepareConditionalLegendMarkers(conditionalLegendMarkersDiv, d) {
      conditionalLegendMarkersDiv.setAttribute("class", "dot");
      conditionalLegendMarkersDiv.setAttribute(
        "style",
        "background: " +
          d.Style.colour +
          "; color:" +
          d.Style.colour +
          "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 10px; border-color: rgb(255, 255, 255); border-radius: 10px; display: inline-block; border: 1px solid white;"
      );
    }

    function addCondFormLegend() {
      let usedCondFormatIds = new Set();

      condFormats.forEach(function (d, i) {
        if (usedCondFormatIds.has(d.id)) {
          return;
        } else {
          usedCondFormatIds.add(d.id);
        }

        let condLegend = document.createElement("div");
        prepareConditionalLegends(condLegend);

        let condLegendMarker = document.createElement("span");
        prepareConditionalLegendMarkers(condLegendMarker, d);

        let condLegendMarkerBackground = document.createElement("span");

        if (d.Style.background) {
          condLegendMarkerBackground.setAttribute("class", "dot");
          condLegendMarkerBackground.setAttribute(
            "style",
            "background: " +
              d.Style.background.colour +
              "; color:" +
              d.Style.background.colour +
              "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 10px; border-color: rgb(255, 255, 255); border-radius: 10px; display: inline-block; margin-left: 2px;  border: 1px solid white;"
          );
        }

        let condLegendText = document.createElement("span");
        condLegendText.setAttribute("class", "text");
        condLegendText.setAttribute(
          "style",
          "color: rgb(55, 61, 63); font-size: 12px; font-family: Helvetica, Arial, sans-serif;"
        );

        let ruleDescription = THIS.props.plugin.conditionalFormats[i]?.rule?.conditionalFormatRule;
        // if rule description is defined in text area, legend will use user input.

        let textNode = document.createTextNode(
          ruleDescription ? "\u00A0" + ruleDescription  : "\u00A0" + d.LeftRuleColumnName + " " + d.Operator + " " + d.RightRuleColumnName + ""
        );
        condLegendText.appendChild(textNode);

        condLegend.appendChild(condLegendMarker);
        condLegend.appendChild(condLegendMarkerBackground);
        condLegend.appendChild(condLegendText);

        $(container).find("#showCondFormInline").append(condLegend);
      });
    }

    if (config.showHideButton) {
      let hideButtonToggleKey = [".pvtTable"];
      rmvpp.hideButton(container, hideButtonToggleKey);
    }

    container.XLSX = () => pivotExcelExport("pivot-table-" + pivotId, this.props.title);
    container.PDF = pdfExport;

    this.props.setPluginRerender(
      false,
      this.props.plugin.id,
      false,
      this.props.plugin.isInteraction
    );
    
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status;
  };

  render() {
    let configComponent = null;
    let sortControlErr = true;

    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;

    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent,
        sortControlErr
      );
    }

    let conditionalFormatComponent = null;

    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    let navigationComponent = null;

    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        this.calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <div style={{height: "100%"}} className={"pivot-sub-area"}>
        {renderContent(
          isRerender,
          this.pluginRender,
          this.props.plugin,
          data,
          columnMap,
          pluginConfig,
          this.props.plugin.conditionalFormats,
          this.props.setPluginRerender,
          this.lastContent,
          this.updateLastContent
        )}
        {configComponent}
        {dataComponent}
        {conditionalFormatComponent}
        {navigationComponent}
      </div>
    );
  }
}
