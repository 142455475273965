import React, { Component } from "react";
import Text from "../../../GeneralComponents/Text/Text";
import { Row, Col, Input, Select, Checkbox, Divider, Button } from "antd";
import { UpdateConfig } from "../../UpdateConfig";
import {
  FontSizeOutlined,
  NumberOutlined,
  BgColorsOutlined,
  CrownOutlined,
  HeatMapOutlined,
  EyeInvisibleOutlined,
  FileImageOutlined,
  ColumnWidthOutlined,
  ColumnHeightOutlined,
  ReloadOutlined,
  FormatPainterOutlined
} from "@ant-design/icons";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import i18n from "../../../../Utils/i18next"

const { Option } = Select;

/**
* Configuration file of TurkishDistrictMap plugin
*/
export default class TurkishDistrictMapConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: this.props.config.backgroundColor,
      height: this.props.config.height,
      isMulti: this.props.config.isMulti,
      selectedColor: this.props.config.selectedColor,
      hideLabel: this.props.config.hideLabel,
      summary: this.props.config.summary,
      theme: this.props.config.theme,
      title: this.props.config.title,
      titleAlign: this.props.config.titleAlign,
      toggleCriteria: this.props.config.toggleCriteria,
      width: this.props.config.width,
    };
  }

  /**
   * Plugin compenents receive it's created properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this.setState({
        backgroundColor: nextProps.config.backgroundColor,
        height: nextProps.config.height,
        isMulti: nextProps.config.isMulti,
        selectedColor: nextProps.config.selectedColor,
        hideLabel: nextProps.config.hideLabel,
        summary: nextProps.config.summary,
        theme: nextProps.config.theme,
        title: nextProps.config.title,
        titleAlign: nextProps.config.titleAlign,
        toggleCriteria: nextProps.config.toggleCriteria,
        width: nextProps.config.width,
      });
    }
  }

  /**
   * Changes the value of text attributes in TurkishDistrictMap plugin use handleChange
   */
  handleChange = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
  };

  /**
   * Checks whether checkbox is checked or not in TurkishDistrictMap plugin use handleCheck
   */
  handleCheck = e => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.checked
    });

    this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
  };

  /**
   * Updates state attributes in TurkishDistrictMap plugin
   */
   setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
    let tempState = { ...this.state };

    if (Array.isArray(changedObj)) {
      for (let i = 0; i < changedObj.length; i++) {
        let object = changedObj[i];
        tempState[object.type] = object.value;
      }
    } else {
      tempState[changedObj.type] = changedObj.value;
    }

    if (propertyListToDelete !== undefined) {
      for (let item of propertyListToDelete) {
        delete tempState[item]
    }
  }

    this.props.updateConfig(
      tempState,
      this.props.pluginId,
      propertyListToDelete
    );
  };

  /**
   * Changes the icon attribute in TurkishDistrictMap plugin use iconOnChange
   */
  iconOnChange = value => {
    this.setState({
      ...this.state,
      icon: value
    });

    this.setConfigToPlugin({ type: "icon", value: value });
  };

  /**
   * Changes the title attribute in TurkishDistrictMap plugin use titleAlignOnChange
   */
  titleAlignOnChange = value => {
    this.setState({
      ...this.state,
      titleAlign: value
    });

    this.setConfigToPlugin({ type: "titleAlign", value: value });
  };

  /**
   * Changes the color attribute in TurkishDistrictMap plugin use colorOnChange
   */
  colorOnChange = obj => {
    this.setState({
      ...this.state,
      colour: obj.color
    });

    this.setConfigToPlugin({ type: "colour", value: obj.color });
  };

  /**
   * Changes the background colour attribute in TurkishDistrictMap plugin use backgroundColorOnChange
   */
  backgroundColorOnChange = obj => {
    this.setState({
      ...this.state,
      backgroundColor: obj.color
    });

    this.setConfigToPlugin({ type: "backgroundColor", value: obj.color });
  };

  /**
   * Demonstrates how the choosen color will affect the interface in TurkishDistrictMap plugin
   */
  backgroundColorChangeVisible = () => {
    this.setState({
      ...this.state,
      backgroundColorChange: !this.state.backgroundColorChange
    });
  };

  /**
   * Changes the selected colour attribute in TurkishDistrictMap plugin use backgroundColorOnChange
   */
  selectedColorOnChange = obj => {
    this.setState({
      ...this.state,
      selectedColor: obj.color
    });

    this.setConfigToPlugin({ type: "selectedColor", value: obj.color });
	};
	
	/**
   * Demonstrates how the choosen color will affect the interface in TurkishDistrictMap plugin
   */
  selectedColorChangeVisible = () => {
    this.setState({
      ...this.state,
      selectedColorChange: !this.state.selectedColorChange
    });
  };

  /**
   * Changes the theme attribute in TurkishDistrictMap plugin use colorOnChange
   */
 themeOnChange = obj => {
    this.setState({
      ...this.state,
      theme: obj
    });

    this.setConfigToPlugin({ type: "theme", value: obj });
  };

  render() {
    return (
        <div style={{ color: "#505050" }}>       
        <Divider className={"config-divider"} orientation="left">
        {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FormatPainterOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Theme")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a theme"
              optionFilterProp="children"
              onChange={this.themeOnChange}
              defaultValue={this.state.theme}
            >
              <Option value="None">{i18n.t("Dashboard.Configuration.Fields.None")}</Option>
              <Option value="Dark">{i18n.t("Dashboard.Configuration.Fields.Dark")}</Option>
              <Option value="Chalk">{i18n.t("Dashboard.Configuration.Fields.Chalk")}</Option>
              <Option value="Light">{i18n.t("Dashboard.Configuration.Fields.Light")}</Option>
			  <Option value="Patterns">{i18n.t("Dashboard.Configuration.Fields.Patterns")}</Option>
			  <Option value="Black">{i18n.t("Dashboard.Configuration.Fields.Black")}</Option>
            </Select>
          </Col>
        </Row>
		<Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <BgColorsOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.SelectedColor")}
            </Text>
          </Col>
          <Col span={12}>
            <Button
              onClick={this.selectedColorChangeVisible}
              style={{ width: "100%" }}
            >
              {this.state.selectedColorChange
                ? i18n.t("Dashboard.Configuration.Fields.CloseColorPicker")
                : i18n.t("Dashboard.Configuration.Fields.OpenColorPicker")}
            </Button>
            {this.state.selectedColorChange ? (
              <ColorPickerPanel
                alpha={100}
                color={this.state.selectedColor}
                onChange={this.selectedColorOnChange}
                id={"selectedColor"}
                style={{ marginTop: "5px", position: "absolute", right: 0 }}
              />
            ) : null}
          </Col>
        </Row>
        <Divider className={"config-divider"} orientation="left">
        {i18n.t("Dashboard.Configuration.Fields.Others")}
        </Divider>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.Title")}
            </Text>
          </Col>
          <Col span={12}>
            <Input
              id={"title"}
              defaultValue={this.state.title}
              value={this.state.title}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.TitleAlign")}
            </Text>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder= {i18n.t("Dashboard.Configuration.Fields.SelectTitleAlign")}
              optionFilterProp="children"
              onChange={this.titleAlignOnChange}
              defaultValue={this.state.titleAlign}
            >
              <Option value="center">{i18n.t("Dashboard.Configuration.Fields.Center")}</Option>
              <Option value="left">{i18n.t("Dashboard.Configuration.Fields.Left")}</Option>
              <Option value="right">{i18n.t("Dashboard.Configuration.Fields.Right")}</Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col style={{ textAlign: "center" }} span={3}>
            <EyeInvisibleOutlined style={{ fontSize: "16px" }} />{" "}
          </Col>
          <Col style={{ textAlign: "left" }} span={9}>
            <Text
              style={{
                fontSize: "16px",
                display: "inline-block",
                marginTop: "2px"
              }}
            >
              {i18n.t("Dashboard.Configuration.Fields.IsMulti")}
            </Text>
          </Col>
          <Col span={12}>
            <Checkbox
              style={{ float: "left", marginTop: "9px" }}
              id={"isMulti"}
              defaultChecked={this.state.isMulti}
              checked={this.state.isMulti}
              onChange={this.handleCheck}
            ></Checkbox>
          </Col>
        </Row>
        
      </div>
    );
  }
}
