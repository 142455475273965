import React, { Component } from "react";
import i18n from "../../../../../Utils/i18next";
import { Col, Row, Input, Button, Popover, Tooltip } from "antd";
import Select from "react-select";
import $ from "jquery";
import FilterPredicates from "./FilterPredicates";
import { store } from "../../../../../index";
import _ from 'lodash'
import { Empty } from 'antd';

const customStyles = {
  control: base => ({
    ...base,
    height: "30px",
    minHeight: "30px",
    backgroundColor : "#f5f5f5",
  }),
  placeholder: styles => {
    return {
      ...styles,
      color: "rgba(0, 0, 0, 0.65)",
      top: "40%"
    };
  },
  menuPortal: base => ({ ...base, zIndex: 9999 })
};
const colourStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.65)",
    overflow: "hidden",
    borderColor: "#d9d9d9",
    height: "30px",
    minHeight: "30px",
    cursor: "pointer",
    ":active": {
      height: "30px",
      minHeight: "30px"
    },
    ":focus": {
      height: "30px",
      minHeight: "30px"
    }
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: styles => {
    return {
      ...styles,
      color: "rgba(0, 0, 0, 0.65)",
      top: "48%"
    };
  },
  multiValue: styles => {
    return {
      ...styles,
      marginTop: "0px",
      minWidth: "40px",
      height: "22px",
      lineHeight: "15px"
    };
  },
  placeholder: styles => {
    return {
      ...styles,
      color: "rgba(0, 0, 0, 0.65)",
      top: "40%"
    };
  },
  valueContainer: styles => {
    return {
      ...styles,
      height: "30px",
      flexWrap: "nowrap",
      paddingLeft: "4px",
    };
  },
  indicatorsContainer: styles => {
    return {
      ...styles,
      height: "30px"
    };
  },
  indicatorSeparator: styles => {
    return {
      ...styles,
      display: "none"
    };
  },
  clearIndicator: styles => {
    return {
      ...styles,
      color: "rgba(0, 0, 0, 0.65)"
    };
  },
  dropdownIndicator: styles => {
    return {
      ...styles,
      color: "rgba(0, 0, 0, 0.65)"
    };
  },
  menu: styles => {
    return {
      ...styles,
      backgroundColor: "#fff",
      borderColor: "#d9d9d9",
      color: "rgba(0, 0, 0, 0.65)",
      zIndex: 999
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      display: "block !important",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      height: "30px",
      backgroundColor: isDisabled ? null : isSelected ? "#ddd" : isFocused,
      borderBottom: "1px solid #fff",
      color: isDisabled
        ? "rgba(0, 0, 0, 0.65)"
        : isSelected
          ? "rgba(0, 0, 0, 0.65)"
          : isFocused,
      ":hover": {
        backgroundColor: "#e6f7ff",
        color: "rgba(0, 0, 0, 0.65)"
      },
      ":active": {
        backgroundColor: "#e6f7ff",
        color: "rgba(0, 0, 0, 0.65)"
      },
      fontWeight: isDisabled ? "normal" : isSelected ? "bold" : isFocused
    };
  }
};

const SELECT_ALL_VALUE = "*";
const optionSelectAll = { label: i18n.t("SelectAll"), value: SELECT_ALL_VALUE };

/**
 * Number filter with predicate content
 */
export default class NumberFilterContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textNumber: "",
      textNumberSecond: "",
      currentOperator:
        this.props.config && this.props.config["filterDefaultOperator"]
          ? this.props.config["filterDefaultOperator"]
          : "=",
      styleConfiguration: colourStyles,
      numberDisable : this.isNumberDisable(this.props.config["filterDefaultOperator"]),
      hovered: false,
      optionData: [],
      filterValue: [],
      selectedValue: []
    };

    this.wrapperRef = React.createRef();
  }

  /**
   * 
   * Controls the current operator isNull or isNotNull
   *
   */
  operatorIsNullOrIsNotNull = (currentOperator) => {
    return (currentOperator === "isNull" || currentOperator === "isNotNull");
  }

  /**
   *
   * @param {*} filterOperator
   * @returns
   *
   * If the selected filter operator is isNull or isNotNull, it disables the number.
   */
  isNumberDisable = (filterOperator) => {
    let numberDisable = false;

    if (this.operatorIsNullOrIsNotNull(filterOperator)) {
      numberDisable = true;
    }

    return numberDisable;
  };

  /** Mouse listener for click outside */
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickPlugin);

    let pluginContainer = $("#" + this.props.plugin.id)
    let textBoxInput = pluginContainer.find(".usetextbox-input")
    let textBoxContainer = pluginContainer.find(".usetextbox-container")

    textBoxInput.focus(function () {
      textBoxContainer.addClass("boxshadow")
    }).focusout(function () {
      textBoxContainer.removeClass("boxshadow")
    })

    let reduxState = store.getState()
    let sourcePluginsWithValues = reduxState.PluginTriggerReducer.sourcePluginsWithValues;
    let gettedValues = sourcePluginsWithValues.has(this.props.plugin.id) ? sourcePluginsWithValues.get(this.props.plugin.id) : []
    let filterValue = []
    let filterPredicate = this.state.currentOperator
    let textNumber = ""
    let textNumberSecond = ""
    let numberDisable = false

    for (let i = 0; i < gettedValues.length; i++) {
      let filterObj = {}

      if (gettedValues[i].filter !== "is null" && gettedValues[i].filter !== "is not null") {
        if (!isNaN(+gettedValues[i].filter)) {
          filterObj = {
            label: `${gettedValues[i].filter}`,
            value: gettedValues[i].filter
          }
        }
      }

      if (Object.values(filterObj).length > 0) {
        filterValue.push(filterObj)
      }

      filterPredicate = gettedValues[i].filterPredicate

      if (!isNaN(+gettedValues[i].filter)) {
        if (i === 0) {
          textNumber = gettedValues[i].filter
        } else if (i === 1) {
          textNumberSecond = gettedValues[i].filter
        }
      } else {
        if (i === 0) {
          if (gettedValues[i].filter !== "is null" && gettedValues[i].filter !== "is not null") {
            textNumber = ""
          } else {
            textNumber = gettedValues[i].filter
          }
        } else if (i === 1) {
          textNumberSecond = ""
        }
      }
    }

    if (filterPredicate === "in") {
      filterPredicate = "="
    } else if (filterPredicate === "not in") {
      filterPredicate = "!="
    }

    if (textNumber !== "is null" && textNumber !== "is not null" && filterPredicate !== "isNull" && filterPredicate !== "isNotNull") {
      this.setState({
        ...this.state,
        selectedValue: filterValue,
        filterValue: filterValue,
        textNumber: textNumber,
        currentOperator: filterPredicate,
        textNumberSecond: textNumberSecond,
        numberDisable: false
      })
    } else {
      if (textNumber === "is null" || filterPredicate === "isNull") {
        numberDisable = true
        filterPredicate = "isNull"
      } else if (textNumber === "is not null" || filterPredicate === "isNotNull") {
        numberDisable = true
        filterPredicate = "isNotNull"
      }

      this.setState({
        ...this.state,
        currentOperator: filterPredicate,
        numberDisable: numberDisable
      })
    }

    if (this.state.currentOperator !== "<=>" && !this.props.plugin.config.useTextBox) {
      textBoxInput.focus(function () {
        textBoxContainer.addClass("boxshadow")
      }).focusout(function () {
        textBoxContainer.removeClass("boxshadow")
      })
    }
  }

  /** Mouse listener for click outside */
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickPlugin);
  }

  /**
   * Handles plugin click and prepare z index for popups position
   * Removed because of issue number #1376
   */
  handleClickPlugin = event => {
    // let isTargetNumberFilterContainer = $(event.target).closest("#number-filter-container-" + this.props.plugin.id).length > 0 ? true : false
    // let isTargetNumberFiltersPluginTools = $(event.target).closest("#plugin-tools-" + this.props.plugin.id).length > 0 ? true : false
    // let isTargetNumberFilterPlugin = $(event.target).closest("#pluginNumberFilter-" + this.props.plugin.id).length > 0 ? true : false
    // let isTargetNumberFilterContents = $(event.target).closest("#plugin-" + this.props.plugin.id).length > 0 ? true : false
    // let isTargetNumberFilterDropdown = $(event.target).closest(`.${this.props.plugin.id}`).length > 0 ? true : false
    // let isTargetNumberFilterDataTooltip = $(event.target).closest(`.accordion-content-${this.props.plugin.id}`).length > 0 ? true : false
    // let isTargetNumberFilterDefaultFilter = $(event.target).closest(`.single-filter-${this.props.plugin.id}`).length > 0 ? true : false
    // let isTargetNumberFiltersDefaultFilterSelect = $(event.target).closest(`.default-filter-select-${this.props.plugin.id}`).length > 0 ? true : false
    // let isTargetNumberFilterArea = $(event.target).closest(`.default-filter-select-${this.props.plugin.id}`).length > 0 ? true : false
    // let isTargetDefaultFilterOperatorTooltip = $(event.target).closest(`.default-filter-operator-tooltip`).length > 0 ? true : false

    // let isTargetNumberFilter = isTargetNumberFilterContainer
    //   || isTargetNumberFiltersPluginTools
    //   || isTargetNumberFilterPlugin
    //   || isTargetNumberFilterContents
    //   || isTargetNumberFilterDropdown
    //   || isTargetNumberFilterDataTooltip
    //   || isTargetNumberFilterDefaultFilter
    //   || isTargetNumberFiltersDefaultFilterSelect
    //   || isTargetNumberFilterArea
    //   || isTargetDefaultFilterOperatorTooltip
    //   ? true
    //   : false

    // // if (isTargetNumberFilter) {
    // //   $("#grid-" + this.props.plugin.id).css("z-index", 1)
    // // } else {
    // //   $("#grid-" + this.props.plugin.id).css("z-index", 0)
    // // }
  };

  componentWillMount() {
    let styleConfiguration = this.applyStyleConfiguration();
    let textboxStyleConfiguration = this.applyTextboxStyleConfiguration();
    let optionData = [];
    let numberDisable = false;

    if (this.operatorIsNullOrIsNotNull(this.state.currentOperator)) {
      numberDisable = true;
    }

    if (this.props.data) {
      optionData = [...this.props.data];
    }

    this.setState({
      ...this.state,
      styleConfiguration: styleConfiguration,
      textboxStyleConfiguration: textboxStyleConfiguration,
      optionData: optionData,
      numberDisable: numberDisable
    });
  }

  componentWillReceiveProps(nextProps) {
    let newState = { ...this.state };
    let changed = false;
    let numberDisable = false;

    if (this.props.config.filterDefaultOperator === undefined) {
      this.props.config.filterDefaultOperator = "="
    }

    if (nextProps.config.filterDefaultOperator === undefined) {
      nextProps.config.filterDefaultOperator = "="
    }

    if (nextProps.config.showOperatorOptions !== this.props.config.showOperatorOptions) {
      newState.textNumber = "";
      newState.textNumberSecond = "";
      newState.selectedValue = []
    }

    if (
      nextProps.config["filterDefaultOperator"] !==
      this.props.config["filterDefaultOperator"]
    ) {
      newState.currentOperator = nextProps.config["filterDefaultOperator"];

      if (this.operatorIsNullOrIsNotNull(newState.currentOperator)) {
        numberDisable = true;
      }

      changed = true;
      newState.numberDisable = numberDisable
    }

    let isConfigExist = nextProps.config && this.props.config;
    let isMultiChanged = nextProps.config.isMulti !== this.props.config.isMulti;
    let isRerenderTrue = nextProps.plugin.rerender === true;
    let isMultiTurnedTrue = nextProps.config.isMulti !== this.props.config.isMulti && nextProps.config.isMulti === true;
    let isMultiTrue = nextProps.config.isMulti;
    let isWidthChanged = this.props.plugin.w !== nextProps.plugin.w;
    let isHeightChanged = this.props.plugin.h !== nextProps.plugin.h;
    let isMoved = (this.props.plugin.x !== nextProps.plugin.x || this.props.plugin.y !== nextProps.plugin.y);
    let refresh = nextProps.clickedRefresh
    let configure = false;
    let dontChange = false;
    let isUseTextboxChanged = this.props.config.useTextBox !== nextProps.config.useTextBox;

    if (isMoved || isHeightChanged || isWidthChanged) {
      dontChange = true;
    }

    if (isConfigExist && nextProps.config !== this.props.config && !dontChange) {
      newState.styleConfiguration = this.applyStyleConfiguration();
      newState.textboxStyleConfiguration = this.applyTextboxStyleConfiguration();
      this.setState(newState)
    }

    let isOtherConfigsChanged = ( this.props.config.filterDefaultOperator !== nextProps.config.filterDefaultOperator ||
      this.props.config.useTextBox !== nextProps.config.useTextBox || isMultiChanged )

    if (this.props.config.filterDefaultOperator !== nextProps.config.filterDefaultOperator && !this.operatorIsNullOrIsNotNull(newState.currentOperator)) {
      isOtherConfigsChanged = false
      changed = false;
    }

    if (this.props.config.filterDefaultOperator !== nextProps.config.filterDefaultOperator) {
      newState.selectedValue = [];
    }

    if (refresh && isConfigExist && (isMultiChanged || isRerenderTrue)) {
      if (this.operatorIsNullOrIsNotNull(nextProps.config["filterDefaultOperator"])) {
        numberDisable = true;
      }
      newState.filterValue = [];
      newState.selectedValue = [];
      newState.textNumber = "";
      newState.textNumberSecond = "";
      newState.numberDisable = numberDisable;
      newState.currentOperator =
        nextProps.config["filterDefaultOperator"] || "=";
      changed = false;
    }

    if (nextProps.data !== this.props.data) {
      newState.optionData = [...nextProps.data];
    }

    if (isMultiTurnedTrue) {
      newState.currentOperator = "=";
    }

    if (isMultiTrue && (nextProps.currentOperator !== "=" || nextProps.currentOperator !== "!=")) {
      newState.currentOperator = "=";
    }

    if (isMultiChanged && !isMultiTrue) {
      newState.currentOperator = nextProps.config["filterDefaultOperator"];

      if (this.operatorIsNullOrIsNotNull(newState.currentOperator)) {
        numberDisable = true;
      }

      newState.numberDisable = numberDisable
    }

    let shouldRender = changed && !_.isEqual(this.props.plugin.config, nextProps.plugin.config)

    if (isMoved || isHeightChanged || isWidthChanged) {
      changed = false;
    }

    if (isOtherConfigsChanged && !isMultiChanged && !isMultiTrue) {
      newState.currentOperator = nextProps.config.filterDefaultOperator
      newState.textNumber = ""
      newState.textNumberSecond = ""
    }

    let isThereValue = this.state.selectedValue.length !== 0

    if (isOtherConfigsChanged && (isMultiChanged || isUseTextboxChanged) && !isThereValue) {
      isOtherConfigsChanged = false;
    }

    if (isMultiChanged) {
      newState.textNumber = "";
      newState.textNumberSecond = "";
    }

    if (isMultiTrue) {
      newState.numberDisable = false;
    }

    let dontRender = false;
    let isOpChangedToNullOrNotNullWhenMultiTrue = isOtherConfigsChanged && isMultiTrue && this.operatorIsNullOrIsNotNull(nextProps.config.filterDefaultOperator);

    if (isOpChangedToNullOrNotNullWhenMultiTrue) {
      let isFilterDefaultOperatorChanged = this.props.config.filterDefaultOperator !== nextProps.config.filterDefaultOperator

      if (isFilterDefaultOperatorChanged) {
        dontRender = true;
      }
    }


    if ((shouldRender || isOtherConfigsChanged) && !dontRender) {
      this.setState(newState, () => {
        this.applyFilter([], newState.currentOperator)
      });
    } else if ((refresh || configure) && !dontChange) {
      this.setState(newState)
    }
  }

  /**
   * Apply text box style configuration
   */
  applyTextboxStyleConfiguration = () => {
    let config = { ...this.props.config };

    return {
      backgroundColor: config.textBackgroundColor,
      borderColor: "#d9d9d9",
      color: config.placeholderColour,
      border: "none",
      height: "100%"
    };
  };

  /**
   * Applies component style by config
   */
  applyStyleConfiguration = () => {
    let styleConfig = { ...this.state.styleConfiguration };
    let config = { ...this.props.config };

    let newStyleConfig = {
      control: styles => {
        return {
          ...styles,
          backgroundColor: config.textBackgroundColor,
          borderColor: "#d9d9d9",
          color: config.placeholderColour,
          height: "30px",
          overflow: "hidden",
          minHeight: "30px",
          cursor: "pointer",
          ":active": {
            height: "30px",
            minHeight: "30px"
          },
          ":focus": {
            height: "30px",
            minHeight: "30px"
          }
        };
      },
      singleValue: styles => {
        return {
          ...styles,
          color: config.placeholderColour,
          top: "48%"
        };
      },
      dropdownIndicator: styles => {
        return {
          ...styles,
          color: config.arrowColor
        };
      },
      clearIndicator: styles => {
        return {
          ...styles,
          color: config.arrowColor
        };
      },
      indicatorSeparator: styles => {
        return {
          ...styles,
          display: "none"
        };
      },
      placeholder: styles => {
        return {
          ...styles,
          color: config.placeholderColour,
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "99%",
          fontSize: "0.93vw"
        };
      }
    };

    styleConfig.control = newStyleConfig.control;
    styleConfig.dropdownIndicator = newStyleConfig.dropdownIndicator;
    styleConfig.clearIndicator = newStyleConfig.clearIndicator;
    styleConfig.indicatorSeparator = newStyleConfig.indicatorSeparator;
    styleConfig.singleValue = newStyleConfig.singleValue;
    styleConfig.placeholder = newStyleConfig.placeholder;

    return styleConfig;
  };

  /**
   * Sets and appies filter operator
   */
  setFilterOperator = value => {
    let numberDisable = false;
    let beforeOperator = this.state.currentOperator;

    if (this.operatorIsNullOrIsNotNull(value)) {
      numberDisable = true;
    }

    this.setState(
      {
        ...this.state,
        currentOperator: value,
        numberDisable: numberDisable
      },
      () => {
        this.applySelectedValueWhenFilterOperatorChanged(value, beforeOperator);
        this.props.changeFilterOperator(value);
      }
    );
  };

  /**
   * When filter operator changed, decide value by text input or select and applies.
   * @operator - current operator for filter
   */
  applySelectedValueWhenFilterOperatorChanged = (operator, beforeOperator) => {
    let valueObject = { value: this.state.textNumber };
    let config = { ...this.props.config };
    let betweenOperator = "<=>";
    let newState = {...this.state }

    if (!config.useTextBox) {
      valueObject = this.state.selectedValue;
    }

    if (beforeOperator !== betweenOperator && operator === betweenOperator) {
      newState.selectedValue = [];
      valueObject = [];
      newState.textNumber = "";
      newState.textNumberSecond = "";
      newState.filterValue = []
    } else if (beforeOperator === betweenOperator || beforeOperator === "between" && operator !== betweenOperator) {
      newState.selectedValue = [];
      valueObject = [];
      newState.textNumber = "";
      newState.textNumberSecond = "";
      newState.filterValue = []
    }

    if (beforeOperator == betweenOperator  && operator === betweenOperator) {
      valueObject = [
        { value: this.state.textNumber },
        { value: this.state.textNumberSecond },
      ];

      if (
        this.operatorIsNullOrIsNotNull(operator) ||
        (valueObject.length !== 0 &&
          valueObject[0].value != "" &&
          valueObject[1].value != "")
      ) {
        this.applyFilter(valueObject, "between");
      }
    } else {
      if (
        this.operatorIsNullOrIsNotNull(operator) ||
        valueObject.length !== 0
      ) {
        this.applyFilter(valueObject, operator);
      }
    }

    this.setState(newState);
  };

  /**
   * Applies text box value
   */
  applyTextBoxValue = () => {
    if (this.state.currentOperator === "<=>" || this.state.currentOperator === "between" ) {
      if(this.state.textNumber !== "" && this.state.textNumberSecond !== ""){
        let textNumberValues = [
          { value: this.state.textNumber },
          { value: this.state.textNumberSecond },
        ];

        this.applyFilter(textNumberValues, "between");
      }

    } else {
      this.applyFilter(
        { value: this.state.textNumber },
        this.state.currentOperator
      );
    }
  };

  /**
   * Handles text number change.
   */
  onChangeTextNumber = event => {
    let numberDisable = false;

    if (this.operatorIsNullOrIsNotNull(this.state.currentOperator)) {
      numberDisable = true;
    }

    this.setState({
      ...this.state,
      textNumber: event.target.value,
      numberDisable: numberDisable
    });
  };

  /**
  * Handles textNumberSecond change.
  */
  onChangeTextNumberSecond = event => {
    let numberDisable = false;

    if (this.operatorIsNullOrIsNotNull(this.state.currentOperator)) {
      numberDisable = true;
    }

    this.setState({
      ...this.state,
      textNumberSecond: event.target.value,
      numberDisable: numberDisable,
    });
  };

  /**
   * Process variables for create trigger in selected value
   */
  processIfValuesArray = (values, filterOperator) => {
    let filter;
    let optionData = [...this.state.optionData];
    let orderOptionDataBySelected = [];
    let valuesSet = new Set();
    let selectedValue = [];
    filter = [];

    values.map(item => {
      if (item.value !== SELECT_ALL_VALUE) {
        filter.push({ filter: item.value });
        orderOptionDataBySelected.push(item);
        selectedValue.push(item);
      }

      valuesSet.add(item.value);
    });

    optionData.map(datum => {
      if (valuesSet.has(SELECT_ALL_VALUE)) {
        //This is select all.
        orderOptionDataBySelected = optionData;
        selectedValue = optionData;
        filter.push({ filter: datum.value });
      } else {
        if (!valuesSet.has(datum.value)) {
          orderOptionDataBySelected.push(datum);
        }
      }
    });

    if (filter.length > 1 && filterOperator === "=") {
      filterOperator = "in";
    } else if (filter.length > 1 && filterOperator === "!=") {
      filterOperator = "not in";
    }

    return {
      selectedValue: selectedValue,
      orderOptionDataBySelected: orderOptionDataBySelected,
      filterOperator: filterOperator,
      filter: filter
    };
  };

  /*
  * Return left or right value is valid
  */
  leftOrRightIsValid = (leftOrRight) => {
    return (leftOrRight && leftOrRight !== null && leftOrRight !== undefined && leftOrRight !== "");
  }

  /*
  * If between Operator is selected, left and right value set on state one by one and call old function selectedValue with array
  */
  prepareBetweenValuesForFilter = (leftValue, rightValue) => {
    let valuesArray = [];
    let valueSelected = this.state.selectedValue;
    let newState = { ...this.state };
    let numberDisable = false;

    if (valueSelected.length === 2) {
      valuesArray[0] = valueSelected[0];
      valuesArray[1] = valueSelected[1];
    } else if (valueSelected.length === 1) {
      valuesArray[0] = valueSelected[0];
    }

    if (this.leftOrRightIsValid(leftValue)) {
      valuesArray[0] = leftValue;
    } else if (leftValue == null) {
      valuesArray[0] = undefined;
    }

    if (this.leftOrRightIsValid(rightValue)) {
      valuesArray[1] = rightValue;
    } else if (rightValue == null) {
      valuesArray[1] = undefined;
    }

    if (this.operatorIsNullOrIsNotNull(this.state.currentOperator)) {
      numberDisable = true;
    }

    newState.selectedValue = valuesArray;
    newState.numberDisable = numberDisable;
    newState.filterValue = valuesArray;

    this.setState(newState);

    if (valuesArray[0] !== undefined && valuesArray[1] !== undefined) {
      this.applyFilter(valuesArray, "between");
    } else if (valuesArray[0] == undefined && valuesArray[1] == undefined) {
      this.applyFilter([], "between");
    }
  };

  /**
   * Prepare selected values for interaction
   */
  applyFilter = (values, filterOperator) => {
    let filter;
    let selectedValue = [];
    let isApplyCreateTrigger = true;
    let orderOptionDataBySelected = [];
    let newState = { ...this.state };
    let numberDisable = false;

    if (values) {
      if (filterOperator === "isNull") {
        filter = { filter: "is null" };
        filterOperator = "";
        selectedValue = this.state.selectedValue;
      } else if (filterOperator === "isNotNull") {
        filter = { filter: "is not null" };
        filterOperator = "";
        selectedValue = this.state.selectedValue;
      } else {
        if (Array.isArray(values)) {
          let processResult = this.processIfValuesArray(values, filterOperator);

          selectedValue = processResult.selectedValue;
          orderOptionDataBySelected = processResult.orderOptionDataBySelected;
          filterOperator = processResult.filterOperator;
          filter = processResult.filter;
        } else {
          let isValueEmptyAndChanged =
            values.value === "" && this.state.filterValue.value !== "";
          let isValueNotEmpty = values.value !== "";

          filter = { filter: values.value };
          selectedValue = values;

          if (!(isValueNotEmpty || isValueEmptyAndChanged)) {
            isApplyCreateTrigger = false;
          }
        }
      }

      if (this.operatorIsNullOrIsNotNull(this.state.currentOperator)) {
        numberDisable = true;
      }

      newState.filterValue = values;
      newState.optionData = orderOptionDataBySelected;
      newState.selectedValue = selectedValue;
      newState.numberDisable = numberDisable;

      this.setState(newState);

      if (isApplyCreateTrigger) {
        this.props.applyCreateTrigger(filter, filterOperator);
      }
    } else {
      newState.filterValue = [];
      newState.selectedValue = [];
      newState.numberDisable = false;

      this.setState(newState, () => {
        this.props.applyCreateTrigger([], filterOperator);
      });
    }
  };

  /**
   * Decide placeholder text for multi select selected count
   */
  decidePlaceholderText = () => {
    const config = { ...this.props.config };
    let placholder = config.placeholder || "";
    let isMulti = config.isMulti === true;

    if (isMulti) {
      let selectedValue =
        this.state.selectedValue && this.state.selectedValue.length > 0;

      if (selectedValue) {
        placholder = this.state.selectedValue.length + " " + i18n.t("Selected");
      }
    }

    return placholder;
  };

  /**
   * Decide selected values tooltip for multi select selected count
   */
  decideSelectedValuesTooltip = () => {
    const config = { ...this.props.config };
    let isMulti = config.isMulti === true;

    if (isMulti) {
      let selectedValue =
        this.state.selectedValue && this.state.selectedValue.length > 0;

      if (selectedValue) {
        return true;
      }
    }

    return false;
  };

  /**
   * Get selected values tooltip content
   */
  selectedValuesTooltipContent = status => {
    let content = undefined;

    if (status) {
      content = this.selectedValuesContent(this.state.selectedValue);
    }

    return content;
  };

  /**
   * Selected values tooltip content
   */
  selectedValuesContent = data => {
    return (
      <div
        style={{ maxHeight: "150px", overflowY: "auto", overflowX: "hidden" }}
      >
        {data.map(datum => {
          return <span style={{ display: "block" }}>{datum.label}</span>;
        })}
      </div>
    );
  };

  /**
   * Handles hover selects for tooltip
   */
  handleHoverChange = visible => {
    let status = this.decideSelectedValuesTooltip();
    let numberDisable = false;

    if (this.operatorIsNullOrIsNotNull(this.state.currentOperator)) {
      numberDisable = true;
    }

    if (status) {
      this.setState({
        ...this.state,
        hovered: visible,
        numberDisable: numberDisable
      });
    } else {
      this.setState({
        ...this.state,
        hovered: false,
        numberDisable: numberDisable
      });
    }
  };

  /**
   * If click placeholder span, focus this input
   */
  onClickInputPlaceholder = event => {
    $(event.target)
      .closest("div")
      .find("input")
      .focus();
  };

  /*
  * Calculates span height for three dot
  */
  calculateSpanHeight = () => {
    let pluginId = this.props.plugin.id
    let plugin = $("#" + pluginId)
    let pluginWidth = plugin.width()
    let maxSpanWidth = (pluginWidth * 40) / 100

    return maxSpanWidth - 6 + "px"
  }

  /**
   *  If Input have any thing call this function for return empty input
   */
  isTextHaveAny(config,currentOperator){
    return (
      <span
        onClick={(event) => this.onClickInputPlaceholder(event)}
        className={"number-input-placeholder"}
        style={{
          color: config.placeholderColour,
          width: (this.calculateSpanHeight().replace("px","") / 2) - 5 + "px",
          cursor: this.operatorIsNullOrIsNotNull(currentOperator)
            ? "no-drop"
            : "text",
        }}
      >
        {config.placeholder}
      </span>
    );
  }

  /*
  * Decides filter value checks if value undefined, returns null
  */
  decideValue = (value) => {
    if (value == undefined) {
      return null
    }

    return value
  }

  render() {
    let valueCheckForTextBox = this.state.currentOperator === "isNull" || this.state.currentOperator === "isNotNull" ? "" : this.state.textNumber;
    let valueCheckForTextBoxSecond = this.state.currentOperator === "isNull" || this.state.currentOperator === "isNotNull" ? "" : this.state.textNumberSecond;
    let valueCheckForSelect = this.state.currentOperator === "isNull" || this.state.currentOperator === "isNotNull" ? "" : this.state.selectedValue;
    let styleValue = this.state.numberDisable ? customStyles : this.state.styleConfiguration;
    let styleValueForTextBox = this.state.numberDisable ? customStyles : this.state.styleConfiguration;
    const { config } = this.props;
    let placeholderText = this.decidePlaceholderText();
    let tooltipStatus = this.decideSelectedValuesTooltip();
    let selectedValuesTooltipContent = this.selectedValuesTooltipContent(
      tooltipStatus
    );
    let optionData = config.isMulti
      ? [optionSelectAll, ...this.state.optionData]
      : this.props.data;
    let textNumber = this.state.textNumber ? true : false
    let textNumberSecond = this.state.textNumberSecond ? true : false
    let currentOperator = this.state.currentOperator === "between" ? "<=>" : this.state.currentOperator
    let shouldShowOperator = this.props.config.showOperatorOptions == undefined ? true : this.props.config.showOperatorOptions;

    const CustomClearIndicator = (props) => {
      const {
        innerProps: { ref, ...restInnerProps },
      } = props;

      return (
        <div {...restInnerProps} ref={ref} style={{ cursor: 'pointer', fontWeight: "bold", marginRight: "3px" }}>
          ×
        </div>
      );
    };

    const customDropdownIndicator = () => {
      return (
        <svg width="20" height="26" viewBox="0 0 20 26" style={{ display: 'block', marginLeft: '-4px', marginTop: '-2px' }}>
          <path d="M10,16.8 L6,12 L14,12 Z" fill="currentColor"></path>
        </svg>
      );
    };

    return (
      <div
        id={"number-filter-container-" + this.props.plugin.id}
        ref={this.wrapperRef}
      >
        <Row
          gutter={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

          }}
        >
          {shouldShowOperator ?
            <Col span={6} style={{ zIndex: "1" }}>
              <FilterPredicates
                value={currentOperator}
                styleConfiguration={this.state.styleConfiguration}
                setFilterOperator={this.setFilterOperator}
                isMulti={config.isMulti}
                pluginId={this.props.plugin.id}
              />
            </Col> : null }
          {config.useTextBox ? (
            currentOperator === "<=>" ? (
              <Col
                span={shouldShowOperator ? 18 : 24}
                className={
                  this.props.plugin.h === 1
                    ? "container-textboxUse-between"
                    : "container-textboxUse-between zero-height"
                }
                style={{
                  background: this.operatorIsNullOrIsNotNull(currentOperator)
                    ? "#f5f5f5"
                    : config.textBackgroundColor,
                }}
              >
                <Col span={7} style={{ padding: "0px", marginLeft: "3px" }}>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "1",
                      right: "6px",
                      top: "5px",
                    }}
                  ></div>
                  {textNumber
                    ? null
                    : this.isTextHaveAny(config, currentOperator)}
                  <Input
                    className={"input-textboxUse"}
                    style={{
                      ...styleValueForTextBox,
                      backgroundColor: this.operatorIsNullOrIsNotNull(
                        currentOperator
                      )
                        ? "#f5f5f5"
                        : this.props.config.textBackgroundColor,
                    }}
                    value={valueCheckForTextBox}
                    onChange={this.onChangeTextNumber}
                    disabled={this.state.numberDisable}
                    onPressEnter={this.applyTextBoxValue}
                    placeholder={""}
                  />
                </Col>
                <Col
                  span={1}
                  style={{
                    textAlign: "center",
                    padding: "2px",
                    display: "block",
                  }}
                >
                  ~
                </Col>
                <Col span={7} style={{ padding: "0px", marginLeft: "6px" }}>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "1",
                      right: "6px",
                      top: "5px",
                    }}
                  ></div>
                  {textNumberSecond
                    ? null
                    : this.isTextHaveAny(config, currentOperator)}
                  <Input
                    className={"input-textboxUse"}
                    style={{
                      ...styleValueForTextBox,
                      backgroundColor: this.operatorIsNullOrIsNotNull(
                        currentOperator
                      )
                        ? "#f5f5f5"
                        : this.props.config.textBackgroundColor,
                    }}
                    value={valueCheckForTextBoxSecond}
                    onChange={this.onChangeTextNumberSecond}
                    disabled={this.state.numberDisable}
                    onPressEnter={this.applyTextBoxValue}
                    placeholder={""}
                  />
                </Col>
                <Col span={9}>
                  <div className="number-filter-input-main-div">
                    <Tooltip title={config.applyButtonText}>
                      <Button
                        onClick={() => this.applyTextBoxValue()}
                        className={"number-filter-button"}
                        style={{
                          margin: "0",
                          background: this.state.numberDisable
                            ? "#f5f5f5"
                            : config.applyButtonBackgroundColor,
                          borderColor: config.applyButtonBackgroundColor,
                          color: config.applyButtonTextColor,
                          height: "30px",
                          width: "calc(100% - 10px)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "-5px",
                        }}
                      >
                        {config.applyButtonText ? (
                          <span className="number-filter-apply-button">
                            {config.applyButtonText}
                          </span>
                        ) : (
                          <span className="number-filter-apply-button">
                            {i18n.t("Apply")}
                          </span>
                        )}
                      </Button>
                    </Tooltip>
                  </div>
                </Col>
              </Col>
            ) : (
              <Col
                span={shouldShowOperator ? 18 : 24}
                className={
                  this.props.plugin.h === 1
                    ? "usetextbox-container"
                    : "usetextbox-container zero-height"
                }
                style={{
                  background: this.operatorIsNullOrIsNotNull(currentOperator)
                    ? "#f5f5f5"
                    : config.textBackgroundColor,
                  width: shouldShowOperator? "calc(74% - 2px)" : "99%"
                }}
              >
                <Col span={14} style={{ padding: "0px" }}>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "1",
                      right: "6px",
                      top: "5px",
                    }}
                  ></div>
                  {textNumber
                    ? null
                    : <span
                      onClick={(event) => this.onClickInputPlaceholder(event)}
                      className={"number-input-placeholder"}
                      style={{
                        color: config.placeholderColour,
                        width: this.calculateSpanHeight(),
                        cursor: this.operatorIsNullOrIsNotNull(currentOperator)
                          ? "no-drop"
                          : "text",
                      }}
                    >
                      {config.placeholder}
                    </span>}
                  <Input
                    className={"usetextbox-input"}
                    style={{
                      ...styleValueForTextBox,
                      backgroundColor: this.operatorIsNullOrIsNotNull(
                        currentOperator
                      )
                        ? "#f5f5f5"
                        : this.props.config.textBackgroundColor,
                    }}
                    value={valueCheckForTextBox}
                    onChange={this.onChangeTextNumber}
                    disabled={this.state.numberDisable}
                    onPressEnter={this.applyTextBoxValue}
                    placeholder={""}
                  />
                </Col>
                <Col span={10}>
                  <div className="number-filter-input-main-div">
                    <Tooltip title={config.applyButtonText}>
                      <Button
                        onClick={() => this.applyTextBoxValue()}
                        className={"number-filter-button"}
                        style={{
                          margin: "0",
                          background: this.state.numberDisable
                            ? "#f5f5f5"
                            : config.applyButtonBackgroundColor,
                          borderColor: config.applyButtonBackgroundColor,
                          color: config.applyButtonTextColor,
                          height: "23px",
                          width: "calc(100% - 10px)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {config.applyButtonText ? (
                          <span className="number-filter-apply-button">
                            {config.applyButtonText}
                          </span>
                        ) : (
                          <span className="number-filter-apply-button">
                            {i18n.t("Apply")}
                          </span>
                        )}
                      </Button>
                    </Tooltip>
                  </div>
                </Col>
              </Col>
            )
          ) : (
            <Col span={shouldShowOperator ? 18 : 24}>
              <Popover
                content={selectedValuesTooltipContent}
                title={i18n.t("Dashboard.Configuration.Fields.SelectedValues")}
                trigger="hover"
                visible={this.state.hovered}
                placement={"right"}
                onVisibleChange={this.handleHoverChange}
              >
                <div>
                  {currentOperator === "<=>" ? (
                    <Row>
                      <div
                        style={{
                          display: "inline-block",
                          width: this.props.plugin.w <= 5 ? "45%" : "48%",
                        }}
                      >
                        <Select
                          id={"numberFilter-between-1"}
                          placeholder={placeholderText}
                          onChange={(value) =>
                            this.prepareBetweenValuesForFilter(value, this.state.filterValue[1])
                          }
                          value={this.decideValue(this.state.selectedValue[0])}
                          isMulti={false}
                          options={this.state.selectedValue[1] ? optionData.filter(val => val.value < this.state.selectedValue[1].value): optionData}
                          styles={this.state.styleConfiguration}
                          noOptionsMessage = {() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                          closeMenuOnSelect={true}
                          controlShouldRenderValue={true}
                          hideSelectedOptions={false}
                          isClearable={true}
                          components={{ ClearIndicator: CustomClearIndicator, DropdownIndicator: customDropdownIndicator }}
                          menuPortalTarget={document.body}
                        ></Select>
                      </div>
                      <div
                        style={{
                          paddingTop: "4px",
                          textAlign: "center",
                          display: "inline-block",
                          width: this.props.plugin.w <= 5 ? "10%" : "4%",
                        }}
                      >
                        ~
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          width: this.props.plugin.w <= 5 ? "45%" : "48%",
                        }}
                      >
                        <Select
                          id={"numberFilter-between-2"}
                          placeholder={placeholderText}
                          onChange={(value) =>
                            this.prepareBetweenValuesForFilter(this.state.filterValue[0], value)
                          }
                          value={this.decideValue(this.state.selectedValue[1])}
                          isMulti={false}
                          options={this.state.selectedValue[0] ? optionData.filter(val => val.value > this.state.selectedValue[0].value) : optionData}
                          styles={this.state.styleConfiguration}
                          noOptionsMessage = {() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                          closeMenuOnSelect={true}
                          controlShouldRenderValue={true}
                          hideSelectedOptions={false}
                          isClearable={true}
                          components={{ ClearIndicator: CustomClearIndicator, DropdownIndicator: customDropdownIndicator }}
                          menuPortalTarget={document.body}
                        ></Select>
                      </div>
                    </Row>
                  ) : (
                    <Select
                      id={"numberFilter-" + this.props.plugin.id}
                      placeholder={placeholderText}
                      onChange={(value) =>
                        this.applyFilter(value, currentOperator)
                      }
                      value={valueCheckForSelect}
                      isDisabled={this.state.numberDisable}
                      isMulti={config.isMulti}
                      options={optionData}
                      styles={styleValue}
                      closeMenuOnSelect={config.isMulti ? false : true}
                      controlShouldRenderValue={config.isMulti ? false : true}
                      hideSelectedOptions={false}
                      isClearable={true}
                      components={{ ClearIndicator: CustomClearIndicator, DropdownIndicator: customDropdownIndicator }}
                      menuPortalTarget={document.body} 
                    ></Select>
                  )}
                </div>
              </Popover>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
