/* eslint-disable no-unused-expressions */

import { store } from "../../.."
import { deepCopy } from "../../../Utils/Global";
import { changeOriginalInteractions, changeOriginalPlugins, closePluginCopyPastePopup } from "../ContextMenu/ContextMenuAction"
import { plugins, configurations, actions } from "./conversionList";
import { setPluginsDrillDowns, setTriggeredDrillDowns, setpluginColumnClickedAndShouldBeRemoved } from "../../DrillDown/DrillDownAction";
import i18n from "../../../Utils/i18next";
import { convertDrillDownStringToMap } from "../../Plugins/PluginComponents/common";
import { drillDownPlugins, exceptInteractionComponent, exceptNavigationComponent } from "../../../config";
import $ from "jquery";
import { InsightsConfig } from "../../Plugins/RenderJs/config";

/**
 * Gives the desired plugin's conversion list
 * 
 * @param {*} key 
 * @param {*} original 
 * @returns 
 */
export const getConversionList = (key, original) => {
    if (plugins[key]) {
        let conversionList = Object.keys(plugins[key]);

        return conversionList.filter(p => p !== key && p !== original);
    } else {
        return null;
    }
}

/**
 * Gives the desired conversion map
 * 
 * @param {*} current 
 * @param {*} to 
 * @returns 
 */
export const getConversionMap = (current, to) => {
    if (plugins[current] && plugins[current][to])
        return plugins[current][to];
    else
        return null;
}

/**
 * Converts the plugin to an other plugin
 * 
 * @param {*} plugin 
 * @param {*} to 
 */
export const convertPlugin = (updatePlugin, plugin, to, interactions, callback) => {
    let reduxState = store.getState();
    let { drillDowns, triggeredDrillDowns, pluginColumnClickedAndShouldBeRemoved } = reduxState.DrillDownReducer;
    let { originalPlugins, originalInteractions } = reduxState.ContextMenuReducer;

    let newPlugin = deepCopy(plugin);

    if (to === "pie-chart-enhanced") to = "pie-chart";

    let convertedLocationFields = {};
    let conversionMap = getConversionMap(plugin.key, to);
    let returnToDefault = originalPlugins.has(plugin.id) && to === null;
    let convertToDifferent = conversionMap && !returnToDefault;

    if (convertToDifferent) {
        // Store the original form of the plugin for turning back later
        if (!originalPlugins?.has(newPlugin.id)) {
            let originalPlugin = deepCopy(newPlugin);

            if (!exceptInteractionComponent.has(newPlugin.key)) {
                let pluginInteractions = [];

                for (let interaction of interactions) {
                    if (interaction.sourceId === newPlugin.id) {
                        let pluginInteraction = deepCopy(interaction);

                        pluginInteractions.push(pluginInteraction);
                    }
                }

                originalInteractions.set(originalPlugin.id, pluginInteractions);
            }

            originalPlugins.set(originalPlugin.id, originalPlugin);

            dispatch(changeOriginalPlugins(originalPlugins), true);
            dispatch(changeOriginalInteractions(originalInteractions), true);
        }

        // Set the plugin key
        newPlugin.key = to;

        if (["bar-chart", "line-bar"].includes(to)) newPlugin.key = "multi-axis-line-chart";

        delete newPlugin.config.size;
        delete newPlugin.config.width;
        delete newPlugin.config.height;
        delete newPlugin.originalConfig.size;
        delete newPlugin.originalConfig.width;
        delete newPlugin.originalConfig.height;

        // Merge the old and the new configuration objects
        newPlugin.config = {
            colours: "Flat-UI",
            paletteColours: InsightsConfig.Palettes["Flat-UI"],
            ...configurations[newPlugin.key],
            ...newPlugin.config
        };

        // Merge the old and the new original configuration objects
        newPlugin.originalConfig = {
            ...configurations[newPlugin.key],
            ...newPlugin.originalConfig
        };

        // Set the hidden data field
        newPlugin.columnMap = {
            hidden: {
                type: "hidden",
                Name: i18n.t(`Plugins.${newPlugin.key}.ColumnMap.Hidden.Name`),
                Desc: i18n.t(`Plugins.${newPlugin.key}.ColumnMap.Hidden.Desc`),
                multiple: true,
                minimumColumnSize: 0,
                required: false,
                data: deepCopy(plugin.columnMap.hidden?.data || [])
            }
        };

        // Add the hidden columns to convertedLocationFields
        newPlugin.columnMap.hidden.data.forEach(column => {
            convertedLocationFields[column.uniqeColumnId] = "hidden";
        });

        // Remap and update the columns according to the conversion map
        for (let field in conversionMap.columnMap) {
            let fieldConversionObj = conversionMap.columnMap[field];
            let from = fieldConversionObj.from || [];
            let columns = [];
            
            if (from instanceof Object) {
                if (from instanceof Array) {
                    // Get all columns in the old field
                    columns = from.map(old => plugin.columnMap[old]?.data)
                } else {
                    // Get the columns with given indexes
                    Object.entries(from)?.forEach(entry => {
                        let [old, list] = entry;
                        let allColumnsInField = plugin.columnMap[old]?.data || [];

                        columns.push(...list?.map(i => allColumnsInField[i]));
                    });
                }
            }
            
            columns = columns?.filter(c => c)?.flat() || [];

            let newField = field === "hidden" ? newPlugin.columnMap[field] : {
                type: field,
                Name: i18n.t(`Plugins.${newPlugin.key}.ColumnMap.${fieldConversionObj.name}.Name`),
                Desc: i18n.t(`Plugins.${newPlugin.key}.ColumnMap.${fieldConversionObj.name}.Desc`),
                multiple: fieldConversionObj?.multiple,
                minimumColumnSize: fieldConversionObj?.minimumColumnSize,
                required: fieldConversionObj?.required,
                data: []
            };

            if (fieldConversionObj.dependentColumns?.length) {
                newField.dependentColumns = fieldConversionObj.dependentColumns.map(columnName => {
                    return i18n.t(`Plugins.${to}.ColumnMap.${columnName}.Name`);
                });
            }

            for (let index in columns) {
                let column = columns[index];

                column.locationFieldName = field;
                column.isDisabledColumn = field === "hidden" ? true : column.isDisabledColumn;

                convertedLocationFields[column.uniqeColumnId] = field;

                newField?.data?.push(column);

                if (!newField?.multiple) {
                    break;
                }
            }

            if (field === "measure" && newField?.data?.length > 1 && newPlugin.key === "measure-tile") {
                newPlugin.config.compareLength = newField?.data?.map(c => c.displayName)
            }

            newPlugin.columnMap[field] = newField;
        }

        // All the old columns list
        let oldColumns = Object.values(plugin.columnMap).map(field => field?.data);
        oldColumns = oldColumns.filter(c => c)?.flat();
        
        // Push the remaining old columns to the new hidden field
        for (let column of oldColumns) {
            let newColumn = deepCopy(column);

            if (!convertedLocationFields[newColumn.uniqeColumnId]) {
                newColumn.locationFieldName = "hidden";
                newColumn.isDisabledColumn = true;

                newPlugin.columnMap.hidden.data.push(newColumn);
                convertedLocationFields[newColumn.uniqeColumnId] = "hidden";
            }
        }

        // All the new columns list
        let newColumns = Object.values(newPlugin.columnMap)?.map(field => field?.data);
        newColumns = newColumns.filter(c => c).flat();

        // Update the sorted column list
        newPlugin.sortedColumnList = newPlugin.sortedColumnList?.map(column => {
            let newColumn = newColumns?.find(c => c?.uniqeColumnId === column?.uniqeColumnId);

            column.locationFieldName = newColumn?.locationFieldName;
            column.isDisabledColumn = newColumn?.isDisabledColumn;

            return column;
        });

        // Get the new navigation action
        let navigationAction = actions[newPlugin.key]?.find(action => action.type === "click");

        if (navigationAction && !exceptNavigationComponent.has(newPlugin.key)) {
            // Filter valid navigations
            newPlugin.navigations = newPlugin.navigations?.filter(navigation => navigation?.dashboardInformation?.columns?.length > 0);
            
            // Update the navigation actions
            for (let navigation of newPlugin.navigations || []) {
                navigation.dashboardInformation.actions = [navigationAction || actions[newPlugin.key][0]];
            }
        } else {
            newPlugin.navigations = [];
        }

        // Update the conditional formats
        for (let condFormat of newPlugin.conditionalFormats || []) {
            condFormat.options = {
                ...condFormat.options,
                backgroundColor: condFormat.options?.backgroundColor || "#FFFFFF",
                color: condFormat.options?.color || "#000000",
                text: {
                    ...condFormat.options?.text,
                    bold: condFormat.options?.text?.bold || false,
                    iconSetting: condFormat.options?.text?.iconSetting || "Right",
                    italic: condFormat.options?.text?.italic || false,
                }
            };

            condFormat.targetColumns = condFormat?.targetColumns?.map(target => {
                if (target?.uniqeColumnId) {
                    let column = newColumns?.find(column => column.uniqeColumnId === target.uniqeColumnId);

                    target = {
                        ...target,
                        locationFieldName: column?.locationFieldName,
                        TargetId: column?.uniqeColumnId,
                        TargetName: column?.name,
                    };
                }

                return target;
            });
        }
    } else if (returnToDefault) {
        // Get the original plugin
        let originalPlugin = originalPlugins.get(newPlugin.id);

        // Revert to the original plugin
        newPlugin = deepCopy(originalPlugin);

        // Clear saved original plugin and interactions
        originalPlugins.delete(newPlugin.id);

        dispatch(changeOriginalPlugins(originalPlugins), true);
    }

    // Disable the cache status
    newPlugin.isCached = false;

    // All the columns list
    let columns = Object.values(newPlugin.columnMap)?.map(field => field?.data);
    columns = columns.filter(c => c).flat();

    // Update the plugin's drilldown
    if (newPlugin.drillDowns) {
        // Get drilldown object from redux
        if (convertToDifferent) {
            newPlugin.drillDowns = deepCopy(drillDowns.get(newPlugin.id));
            newPlugin.drillDowns.drillDownTypes = "normal";
        }

        // Convert drilldown properties from string to map
        newPlugin.drillDowns = convertDrillDownStringToMap(newPlugin.drillDowns);

        let drillDownFields = drillDownPlugins[newPlugin.key];

        if (Array.isArray(drillDownFields)) {
            /* The new plugin has drilldown feature */

            if (convertToDifferent) {
                // Update the drilldown columns' location field name
                newPlugin.drillDowns?.allDrillDownColumnsInPlugin?.forEach(column => {
                    let drillDownField = convertedLocationFields[column.drillDownParentColumnId || column.uniqeColumnId];
                    
                    if (!newPlugin.drillDowns) return;

                    if (drillDownFields.includes(drillDownField)) {
                        column.locationFieldName = drillDownField
                        convertedLocationFields[column.uniqeColumnId] = drillDownField;
                    } else {
                        delete newPlugin.drillDowns;
                    }
                });

                newPlugin.drillDowns?.drillDownColumnsForParentColumns?.forEach(drilldownColumns => {
                    drilldownColumns.forEach(column => {
                        column.locationFieldName = convertedLocationFields[column.uniqeColumnId];
                    });
                });
            }

            // Deactivate all the drilldown layers
            newPlugin.drillDowns?.drillDownLayerMap.forEach(layer => {
                layer.hasDrillDownOperation = false;
            });
        } else if (convertToDifferent) {
            /* The new plugin does not have drilldown feature */

            // Add the drilldown columns to the column map
            newPlugin.drillDowns.allDrillDownColumnsInPlugin?.forEach(column => {
                if (!columns.find(c => c.uniqeColumnId === column.uniqeColumnId)) {
                    // Get the column's location field name
                    let field = convertedLocationFields[column.drillDownParentColumnId]

                    // If the field is multiple, move the column to the field, otherwise move the column to the hidden field.
                    if (newPlugin.columnMap[field]?.multiple) {
                        column.locationFieldName = field;
                        column.isDisabledColumn = column.locationFieldName === "hidden" || column.isDisabledColumn;

                        newPlugin.columnMap[column.locationFieldName]?.data?.push(column);
                    } else {
                        column.locationFieldName = "hidden";
                        column.isDisabledColumn = true;

                        newPlugin.columnMap.hidden?.data?.push(column);
                    }

                    // Push the column to the sortedColumnList, columns and convertedLocationFields
                    convertedLocationFields[column.uniqeColumnId] = column.locationFieldName

                    newPlugin.sortedColumnList?.push(column);
                    columns?.push(column);
                }
            });

            // Delete the plugin's drilldowns object
            delete newPlugin.drillDowns;
        }
    }

    let tempInteractions = [];

    if (convertToDifferent) {
        if (!exceptInteractionComponent.has(newPlugin.key)) {
            for (let interaction of interactions) {
                let copiedInteraction = deepCopy(interaction);

                if (interaction.sourceId === newPlugin.id) {
                    let selectedInteractionColumns = copiedInteraction.columns?.map(c => c.uniqeColumnId);

                    copiedInteraction.columns = [];
                    copiedInteraction.allColumns = [];

                    delete copiedInteraction.selectedColumnIds;
                    delete copiedInteraction.selectedColumnsNames;
                    delete copiedInteraction.columnsHashMap;
                    delete copiedInteraction.selectedActionsNames;
                    delete copiedInteraction.selectedReactionsNames;

                    // Update actions
                    if (copiedInteraction?.actions?.length) {
                        for (let index in copiedInteraction.actions) {
                            let oldAction = copiedInteraction.actions[index];
                            let newAction = actions[newPlugin.key]
                                ?.find(action => action?.type === (oldAction?.type === "mouseover" ? "hover" : oldAction?.type)) || actions[newPlugin.key][0];

                            copiedInteraction.actions[index] = deepCopy(newAction);
                        }
                    } else {
                        copiedInteraction.actions = [deepCopy(actions[newPlugin.key][0])]
                    }

                    // Update columns
                    for (let field of Object.values(newPlugin.columnMap)) {
                        for (let column of field?.data) {
                            let isColumnValid = column.aggregatable === false && column.locationFieldName !== "hidden";
                            
                            if (isColumnValid) {
                                copiedInteraction.allColumns.push(column);

                                if (selectedInteractionColumns?.includes(column.uniqeColumnId)) {
                                    copiedInteraction.columns.push(column);
                                }
                            }
                        }
                    }
                }

                tempInteractions.push(copiedInteraction);
            };
        } else {
            for (let interaction of interactions) {
                if (interaction.sourceId !== newPlugin.id) {
                    let copiedInteraction = deepCopy(interaction);

                    tempInteractions.push(copiedInteraction);
                }
            }
        }
    } else if (returnToDefault) {
        // Clear interactions of the converted plugin
        tempInteractions = tempInteractions.filter(interaction => interaction.sourceId !== newPlugin.id);

        for (let interaction of interactions) {
            if (interaction.sourceId !== newPlugin.id) {
                let copiedInteraction = deepCopy(interaction);

                tempInteractions.push(copiedInteraction);
            }
        }

        // Set interactions of the original plugin
        if (!exceptInteractionComponent.has(newPlugin.key)) {
            for (let interaction of originalInteractions.get(newPlugin.id)) {
                let copiedInteraction = deepCopy(interaction);

                tempInteractions.push(copiedInteraction);
            }
        }

        originalInteractions.delete(newPlugin.id);
        dispatch(changeOriginalInteractions(originalInteractions), true);
    }

    // Clear the plugin's drilldown
    triggeredDrillDowns.delete(newPlugin.id);
    pluginColumnClickedAndShouldBeRemoved.delete(newPlugin.id);
    drillDowns.delete(newPlugin.id);

    // Set the new drilldown
    if (Array.isArray(drillDownPlugins[newPlugin.key]) && newPlugin.drillDowns) {
        drillDowns.set(newPlugin.id, newPlugin.drillDowns);
    }

    // Update drilldowns
    dispatch(setPluginsDrillDowns(drillDowns), true)
    dispatch(setTriggeredDrillDowns(triggeredDrillDowns), true);
    dispatch(setpluginColumnClickedAndShouldBeRemoved(pluginColumnClickedAndShouldBeRemoved), true);

    // Restore plugin grid size
    newPlugin.w = plugin.w;
    newPlugin.h = plugin.h;

    newPlugin.data = undefined

    // Update the plugin and interactions
    updatePlugin(newPlugin, undefined, tempInteractions);

    if (typeof callback === "function") {
        callback();
    }

    dispatch(closePluginCopyPastePopup(false));

    $("#plugin-" + newPlugin.id)?.css("background", "");
}

/**
 * Dispatches an action. It is the only way to trigger a state change.
 * 
 * The reducer function, used to create the store, will be called with the current state tree and the given action. Its return value will be considered the next state of the tree, and the change listeners will be notified.
 * 
 * @param {*} action 
 */
const dispatch = (action, queued = false) => {
    if (action && queued) {
        setTimeout(store.dispatch, undefined, action);
    } else if (action) {
        store.dispatch(action);
    }
}