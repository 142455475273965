import React, { Component } from "react";
import FilterOperators from "./FilterOperators";
import { Row, Col, Select, Button, InputNumber } from "antd";
import DateTimePicker from "./DateTimePicker";
import moment from "moment";
import Text from "../../../GeneralComponents/Text/Text";
import { showError } from "../../../../Utils/Notification";
import i18n from "../../../../Utils/i18next";
import { store } from "../../../..";
import { deepCopy } from "../../../../Utils/Global";

const { Option } = Select;

export default class FilterSelection extends Component {
  constructor(props) {
    super(props);

    this.timestampType =
      props.dataType &&
      (props.dataType === "timestamp" ||
        props.dataType === "timestamp without time zone");

    let dualCalender =
      this.props.filterDefaultOperator === "between" ? true : false;

    let column = props.columnMap.filter;
    let isTimestampDefaultFormatChanged =
      this.timestampType === true &&
        column.DataFormat.trim() !== "%d/%m/%Y %H:%M:%S"
        ? true
        : false;

    this.state = {
      selectedFilterOperator: this.props.filterDefaultOperator,
      selectedDate: null,
      selectedDateString: "",
      dualCalender: dualCalender,
      showCustomDate: "none",
      lastOrFuture: "Last",
      datePeriod: "days",
      dateInput: "",
      openPicker: false,
      isTimestampDefaultFormatChanged: isTimestampDefaultFormatChanged,
      calenderDisable: this.isCalenderDisable(this.props.filterDefaultOperator),
      isFirstMount: true,
      dropdownAppearance: this.props.dropdownAppearance === undefined ? false : this.props.dropdownAppearance
    };
  }

  /** 
   *
   * @param {*} filterOperator
   * @returns
   *
   * If the selected filter operator is isNull or isNotNull, it disables the calender.
   */
  isCalenderDisable = (filterOperator) => {
    let calenderDisable = false;
    let value;

    if (filterOperator === "isNull") {
      calenderDisable = true;
      value = "is null";
    } else if (filterOperator === "isNotNull") {
      calenderDisable = true;
      value = "is not null";
    }

    if (value === "is null" || value === "is not null") {
      this.props.selectedDate("", "", value);
    }

    return calenderDisable;
  };

  /**
   *
   * @param {*} dates
   * @param {*} dateStrings
   * @param {*} filterOperator
   * @returns
   *
   * Edits the selected date for the timestamp column with the data format changed
   */
  getFormatChangeForSelectedDate = (dates, dateStrings, filterOperator) => {
    let dateStringList = [];

    if (
      this.state.isTimestampDefaultFormatChanged === true &&
      dateStrings !== "" &&
      this.state.dateFormat === "YYYY-MM-DD"
    ) {
      if (filterOperator === "between" || filterOperator === "=") {
        if (Array.isArray(dateStrings)) {
          dateStringList.push(
            moment(dateStrings[0]).format("YYYY-MM-DD 00:00:00")
          );
          dateStringList.push(
            moment(dateStrings[1]).format("YYYY-MM-DD 23:59:59")
          );
          dateStrings = dateStringList;
          filterOperator = "between";
        } else {
          dateStringList.push(
            moment(dateStrings).format("YYYY-MM-DD 00:00:00")
          );
          dateStringList.push(
            moment(dateStrings).format("YYYY-MM-DD 23:59:59")
          );
          dateStrings = dateStringList;
          filterOperator = "between";
        }
      }
    }

    return { dates, dateStrings, filterOperator };
  };

  /**
   * Controls whether current format is timestamp or not
  */
  isTimestamp = (props) => {
    let column = props.plugin.columnMap.filter.data[0];
    let columnForPlugin = props.plugin?.columnMapForPlugin?.filter;

    let dataType = column?.dataType ? column.dataType : columnForPlugin.dataType;
    let dataFormatTypeSubTitle = column?.DataFormatTypeSubTitle ? column.DataFormatTypeSubTitle : columnForPlugin.DataFormatTypeSubTitle;
    let dataFormat = column?.DataFormat ? column.DataFormat : columnForPlugin.DataFormat;

    if (
      dataType === "date" ||
      dataFormatTypeSubTitle === "date" ||
      /%[HMSs]/.test(dataFormat) !== true
    ) {
      return false;
    }

    return true;
  };

  /**
   * Sets default time format.
   */
  setDefaultTimeFormat = (dateFormat = "") => {
    let format = "";
    let isTimestampPart = false;

    for (let i = 0; i < dateFormat.length - 1; i++) {
      if (dateFormat[i] === "%") {
        if (dateFormat[i + 1].toUpperCase() === "Y") {
          format += "/" + dateFormat[i + 1].toUpperCase().repeat(4)
        } else {
          if (i > 0 && dateFormat[i - 1] === " ") {
            format += " " + dateFormat[i + 1].toUpperCase().repeat(2)
            isTimestampPart = true;
          } else {
            format += isTimestampPart ? ":" : "/"
            format += dateFormat[i + 1].toUpperCase().repeat(2)
          }
        }
      }
    }

    return format.slice(1);
  }

  /**
   * Sets default custom time format.
   */
  setDefaultCustomFormat = (column) => {
    return column.DataFormat.trim().toLowerCase().replace("%d", "DD").replace("%m", "MM").replace("%y", "YYYY")
  }

  /**
   * Checks whether custom data type is selected or not.
   */
  isCustomSelected = (props) => {
    let columnData = props?.plugin?.columnMap?.filter?.data;
    let isCustomSelected = columnData && columnData[0] ? columnData[0].DataFormatType === "custom" : false;

    return isCustomSelected;
  }

  componentWillReceiveProps(nextProps) {
    let newState = deepCopy(this.state);
    let isStateCleared = false;

    if (!newState.dateFormat && nextProps?.plugin?.columnMapForPlugin?.filter) {
      newState.dateFormat = this.getDefaultFormat(nextProps);
    }

    if (this.isCustomSelected(nextProps) && nextProps.dropdownAppearance) {
      let refresh = nextProps.clickedRefresh
      let timeFormat = this.setDefaultTimeFormat(nextProps?.plugin?.columnMapForPlugin?.filter?.DataFormat?.trim())

      if (refresh) {
        newState.dateFormat = timeFormat;
      }
    }

    let refresh = nextProps.clickedRefresh && (this.isTimestamp(nextProps) !== this.isTimestamp(this.props))

    if (this.isCustomSelected(this.props) !== this.isCustomSelected(nextProps) && nextProps.clickedRefresh) {
      refresh = true;
    }

    if (refresh) {
      newState.dateFormat = this.getDefaultFormat(nextProps);
    }

    if (this.props.filterDefaultOperator !== nextProps.filterDefaultOperator) {
      this.setFilterOperator(nextProps.filterDefaultOperator, newState);

      return;
    }

    let isColumnMapNotEqualNextColumnMap = this.props.plugin.columnMapForPlugin
      && this.props.plugin.columnMapForPlugin.filter.uniqeColumnId !== nextProps.plugin.columnMapForPlugin.filter.uniqeColumnId
      && nextProps.plugin.columnMapForPlugin ? true : false

    if (isColumnMapNotEqualNextColumnMap) {
      this.clearState(newState, nextProps);

      isStateCleared = true;
    }

    if (nextProps.refreshPluginStatus === true && this.props.refreshPluginStatus !== nextProps.refreshPluginStatus && nextProps.clickedRefresh) {
      this.clearState(newState, nextProps, false);

      isStateCleared = true;
    }

    if (this.props.dropdownAppearance !== nextProps.dropdownAppearance) {
      this.clearState(newState, nextProps, false)

      isStateCleared = true;
    }

    if (typeof newState.dateFormat !== "undefined" && isStateCleared !== true) {
      this.setState(newState);
    }
  }

  /**
   * When Calender Popup filtered with month, day etc. Call this function.
   * 
   * @param {*} time 
   */
  extraFooterClicked = (time) => {
    let dates = [moment().startOf(time), moment().endOf(time)];
    let dateStrings = [
      moment().startOf(time).format(this.state.dateFormat),
      moment().endOf(time).format(this.state.dateFormat),
    ];

    this.setState({
      showCustomDate: "none",
      dualCalender: true,
      selectedFilterOperator: "between",
      selectedDate: dates,
      selectedDateString: dateStrings,
    });
  };

  /**
   * Change future or last
   * 
   * @param {*} value 
   */
  handleChangeFutureOrLast = (value) => {
    this.setState(
      {
        lastOrFuture: value,
      },
      () => this.customDateApply()
    );
  };

  /**
   * Change date period
   * 
   * @param {*} value 
   */
  handleChangeDatePeriod = (value) => {
    this.setState(
      {
        datePeriod: value,
      },
      () => this.customDateApply()
    );
  };

  /*
  * Commented for date filter. After number filter issue this comment will open.
  */
  componentWillMount() {
    let reduxState = store.getState()
    let sourcePluginsWithValues = reduxState.PluginTriggerReducer.sourcePluginsWithValues;
    let gettedValues = sourcePluginsWithValues.has(this.props.plugin.id) ? sourcePluginsWithValues.get(this.props.plugin.id) : []
    let filterValue = []
    let filterPredicate = this.props.filterDefaultOperator;
    let textNumber = ""
    let dualCalender = true
    let calenderDisable = false
    let previousData = JSON.parse(sessionStorage.getItem(this.props.plugin.id + "-preData"))

    if (previousData) {
      let values = previousData[1];
      let isSessionOpetorNullOrNotNull = ["isNull", "isNotNull", "is null", "is not null"].includes(previousData[0]);
      let areValuesValid = values || isSessionOpetorNullOrNotNull

      if (areValuesValid && sourcePluginsWithValues.has(this.props.plugin.id)) {
        filterPredicate = previousData[0];

        if (Array.isArray(values)) {
          for (let value of values) {
            filterValue.push({
              label: `${value}`,
              value: value
            });
          }
        } else if (values) {
          filterValue.push({
            label: `${values}`,
            value: values
          });
        }
      } else {
        sessionStorage.removeItem(this.props.plugin.id + "-preData")
      }
    }
    else {
      for (let i = 0; i < gettedValues.length; i++) {
        let filterObj = {
          label: `${gettedValues[i].filter}`,
          value: gettedValues[i].filter
        }

        filterValue.push(filterObj)
        textNumber = gettedValues[i].filter
        filterPredicate = gettedValues[i].filterPredicate
      }
    }

    if (filterPredicate === "between") {
      dualCalender = true
    } else {
      dualCalender = false
    }

    if (filterPredicate === "isNull" || filterPredicate === "is null") {
      dualCalender = false;
      filterPredicate = "is null";
      filterValue = null
      textNumber = ""
      calenderDisable = true
    } else if (filterPredicate === "isNotNull" || filterPredicate === "is not null") {
      dualCalender = false;
      filterPredicate = "is not null";
      filterValue = null
      textNumber = ""
      calenderDisable = true
    }

    if (filterValue == null || filterValue.length === 1) {
      this.setState({
        ...this.state,
        textNumber: textNumber,
        selectedFilterOperator: filterPredicate,
        dualCalender: dualCalender,
        selectedDate: filterValue != null
          ? moment(filterValue[0].value)
          : null,
        selectedDateString: filterValue != null
          ? filterValue[0].value
          : "",
        calenderDisable: calenderDisable
      })
    } else if (filterValue && filterValue.length === 2) {
      this.setState({
        ...this.state,
        textNumber: textNumber,
        selectedFilterOperator: filterPredicate,
        dualCalender: dualCalender,
        selectedDate: filterValue.map(v => moment(v.value)),
        selectedDateString: filterValue.map(v => v.value),
        calenderDisable: calenderDisable
      })
    }
  }

  /**
   * Clears state
   * 
   * @param {*} props 
   * @param {*} clearedForOperatorChange 
   * @param {*} operator 
   */
  clearState = (state, props, clearedForOperatorChange = false, operator = "=") => {
    let newState = deepCopy(state)
    let column = props.plugin.columnMap.filter.data[0];

    let dualCalender = props.filterDefaultOperator === "between" ? true : false;
    let isTimestampDefaultFormatChanged = this.timestampType === true && column?.DataFormat?.trim() !== "%d/%m/%Y %H:%M:%S" ? true : false;

    this.timestampType = props.dataType && (props.dataType === "timestamp" || props.dataType === "timestamp without time zone");

    newState.selectedDate = null
    newState.selectedDateString = ""
    newState.dualCalender = dualCalender
    newState.showCustomDate = "none"
    newState.lastOrFuture = "Last"
    newState.datePeriod = "days"
    newState.dateInput = ""
    newState.openPicker = false
    newState.isTimestampDefaultFormatChanged = isTimestampDefaultFormatChanged
    newState.calenderDisable = this.isCalenderDisable(props.filterDefaultOperator)
    newState.dateFormat = this.getDefaultFormat(props)

    if (!clearedForOperatorChange) {
      newState.selectedFilterOperator = props.filterDefaultOperator
    } else {
      newState.selectedFilterOperator = operator
    }

    sessionStorage.removeItem(props.plugin.id + "-preData");

    this.setState(newState)
  };

  /**
   * Get default format
   * 
   * @param {*} props 
   * @returns 
   */
  getDefaultFormat = (props) => {
    let columnData = props.plugin.columnMap.filter.data;
    let isCustomSelected = columnData[0] ? columnData[0].DataFormatType === "custom" : false;

    if (isCustomSelected && props.dropdownAppearance) {
      let columnData = props.plugin.columnMap.filter.data;
      let column = columnData ? columnData[0] : null;

      return this.setDefaultTimeFormat(column.DataFormat.trim())
    } else {
      return this.isTimestamp(props)
        ? "YYYY-MM-DD HH:mm:ss"
        : "YYYY-MM-DD"
    }
  }

  /*
  * Controls timestamp type
  */
  isTimestampType = (timestampType, props) => {
    if (props.plugin.columnMapForPlugin) {
      let column = props.plugin.columnMapForPlugin.filter;

      if (timestampType === true) {
        return column.DataFormat.trim() === "%d/%m/%Y %H:%M:%S" ? true : false;
      }
    }

    return timestampType;
  };

  /**
   * Show custom date change
   */
  showCustomDateChange = () => {
    let showCustomDate;

    if (this.state.showCustomDate === "none") {
      showCustomDate = "block";
    } else {
      showCustomDate = "none";
    }

    this.setState(
      {
        showCustomDate: showCustomDate,
      },
      () => {
        if (this.state.dualCalender !== true) {
          this.setFilterOperator("between");
        }
      }
    );
  };

  /**
   * Change custom date input
   * 
   * @param {*} value 
   */
  onChangeInput = (value) => {
    this.setState(
      {
        dateInput: value,
      },
      () => this.customDateApply()
    );
  };

  /**
   * Validate custom date
   * @returns 
   */
  customValidation = () => {
    if (this.state.lastOrFuture === "") {
      showError("DateFilter.LastOrFutureNotEmpty");

      return false;
    } else if (this.state.dateInput === "") {
      showError("DateFilter.DateInputNotEmpty");

      return false;
    } else if (this.state.datePeriod === "") {
      showError("DateFilter.DatePeriodNotEmpty");

      return false;
    }

    return true;
  };

  /**
   * Set custom date
   * 
   * @param {*} dates 
   * @param {*} dateStrings 
   */
  setCustomDate = (dates, dateStrings) => {
    if (this.state.dateInput !== "" && this.state.dateInput !== null) {
      let selectDate;
      let stringToday = moment().startOf("today").format(this.state.dateFormat);

      if (this.state.lastOrFuture === "Last") {
        let startOfDate;
        let startOfDateString;

        if (this.state.datePeriod === "days") {
          selectDate = moment();
          selectDate = selectDate.subtract(this.state.dateInput, "days");
          selectDate = selectDate.format(this.state.dateFormat);
          startOfDate = moment(selectDate);
          startOfDateString = selectDate;
        } else {
          selectDate = moment()
            .subtract(this.state.dateInput, this.state.datePeriod)
            .calendar();

          startOfDate = moment(
            moment(selectDate).format(this.state.dateFormat)
          );
          startOfDateString = moment(selectDate).format(this.state.dateFormat);
        }

        dates.push(startOfDate);
        dates.push(moment().startOf("today"));

        dateStrings.push(startOfDateString);
        dateStrings.push(stringToday);
      } else if (this.state.lastOrFuture === "Future") {
        let endOfDate;
        let endOfDateString;
        if (this.state.datePeriod === "days") {
          selectDate = moment();
          selectDate = selectDate.add(this.state.dateInput, "days");
          selectDate = selectDate.format(this.state.dateFormat);

          endOfDate = moment(selectDate);
          endOfDateString = selectDate;
        } else {
          selectDate = moment()
            .add(this.state.dateInput, this.state.datePeriod)
            .calendar();

          endOfDate = moment(moment(selectDate).format(this.state.dateFormat));
          endOfDateString = moment(selectDate).format(this.state.dateFormat);
        }

        dates.push(moment().startOf("today"));
        dates.push(endOfDate);

        dateStrings.push(stringToday);
        dateStrings.push(endOfDateString);
      }
    }
  };

  /**
   * Apply custom date to filter
   * 
   * @param {*} callback 
   */
  customDateApply = (callback) => {
    let dates = [];
    let dateStrings = [];

    this.setCustomDate(dates, dateStrings);

    this.setState(
      {
        selectedFilterOperator: "between",
        dualCalender: true,
        selectedDate: dates,
        selectedDateString: dateStrings,
        openPicker: true,
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  /**
   * On Ok button click
   * 
   * @returns 
   */
  onOk = () => {
    if (this.state.showCustomDate === "block") {
      if (this.customValidation() === false) {
        return;
      }
    }

    this.setState(
      {
        openPicker: false,
        showCustomDate: "none",
      },
      () => {
        if (this.state.showCustomDate === "block") {
          this.customDateApply(this.applySelectedDate);
        } else {
          this.applySelectedDate();
        }
      }
    );
  };

  /**
   * Apply selected date to filter
   */
  applySelectedDate = () => {
    let selectedDate = { ...this.state.selectedDate };
    let selectedDateString = this.state.selectedDateString;
    let selectedFilterOperator = this.state.selectedFilterOperator;
    let isSelectedDateEmpty = this.state.selectedDateString == undefined && Object.keys(selectedDate)?.length === 0 ? true : false

    let changedFormatDates = this.getFormatChangeForSelectedDate(
      selectedDate,
      selectedDateString,
      selectedFilterOperator
    );

    this.props.selectedDate(
      changedFormatDates.dates,
      changedFormatDates.dateStrings,
      changedFormatDates.filterOperator,
      undefined,
      isSelectedDateEmpty
    );
  };

  /**
   * Return Calender's filter context 
   * 
   * @returns
   */
  getExtraFooter = () => {
    let isTimestampType = this.isTimestampType(this.timestampType, this.props);

    return (
      <div>
        <div
          style={{ marginBottom: "5px" }}
          className="ant-calendar-footer-extra ant-calendar-range-quick-selector"
        >
          <span
            onClick={() => this.extraFooterClicked("day")}
            className="ant-tag ant-tag-blue"
          >
            {i18n.t("Plugins.date-filter.Today")}
          </span>
          <span
            onClick={() => this.extraFooterClicked("week")}
            className="ant-tag ant-tag-blue"
          >
            {i18n.t("Plugins.date-filter.ThisWeek")}
          </span>
          <span
            onClick={() => this.extraFooterClicked("month")}
            className="ant-tag ant-tag-blue"
          >
            {i18n.t("Plugins.date-filter.ThisMonth")}
          </span>
          <span
            onClick={() => this.extraFooterClicked("year")}
            className="ant-tag ant-tag-blue"
          >
            {i18n.t("Plugins.date-filter.ThisYear")}
          </span>
          <span
            onClick={this.showCustomDateChange}
            className="ant-tag ant-tag-blue"
          >
            {i18n.t("Plugins.date-filter.CustomDate")}
          </span>
        </div>
        <div
          style={{ width: "100%" }}
          className="ant-calendar-footer-extra ant-calendar-range-quick-selector"
        >
          <Row>
            <div
              style={{
                position: "relative",
                border: "1px solid #e4dfdf",
                borderRadius: "5px",
                padding: "7px",
                background: "white",
                width: "100%",
                marginBottom: "5px",
                display: this.state.showCustomDate,
              }}
            >
              <Row>
                <Col span={12}>
                  <Text>{i18n.t("DateFilter.LastFuture")}</Text>
                </Col>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.lastOrFuture}
                    onChange={this.handleChangeFutureOrLast}
                  >
                    <Option value="Last">{i18n.t("DateFilter.Last")}</Option>
                    <Option value="Future">
                      {i18n.t("DateFilter.Future")}
                    </Option>
                  </Select>
                </Col>
              </Row>
              <Row style={{ top: "6px" }}>
                <Col span={12}>
                  <Text>{i18n.t("DateFilter.Quantity")}</Text>
                </Col>
                <Col span={12}>
                  <InputNumber
                    id="dateInput"
                    value={this.state.dateInput}
                    onChange={this.onChangeInput}
                    style={{ width: "100%" }}
                    min={1}
                  />
                </Col>
              </Row>
              <Row style={{ top: "12px", marginBottom: "11px" }}>
                <Col span={12}>
                  <Text>{i18n.t("DateFilter.DatePeriod")}</Text>
                </Col>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.datePeriod}
                    onChange={this.handleChangeDatePeriod}
                  >
                    <Option value="days">{i18n.t("DateFilter.Day")}</Option>
                    <Option value="Week">{i18n.t("DateFilter.Week")}</Option>
                    <Option value="Month">{i18n.t("DateFilter.Month")}</Option>
                    <Option value="Year">{i18n.t("DateFilter.Year")}</Option>
                  </Select>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
        {isTimestampType !== true ? (
          <div
            style={{ width: "100%" }}
            className="ant-calendar-footer-extra ant-calendar-range-quick-selector"
          >
            <Row>
              <Button
                className={"ant-calendar-ok-btn"}
                style={{
                  float: "right",
                  marginBottom: "10px",
                }}
                onClick={() => this.onOk()}
              >
                {i18n.t("OK")}
              </Button>
            </Row>
          </div>
        ) : null}
      </div>
    );
  };

  /**
   * Set filter operator
   * 
   * @param {*} value 
   */
  setFilterOperator = (value, state = this.state) => {
    let newState = deepCopy(state);

    let dualCalender = false;
    let selectedDate = state.selectedDate;
    let selectedDateString = state.selectedDateString;
    let calenderDisable = false;
    let dateInput = state.dateInput;

    // The custom input field is cleared when the operator changes
    if (value !== state.selectedFilterOperator) {
      dateInput = "";
    }

    if (
      value === "between" &&
      state.selectedFilterOperator !== "between"
    ) {
      selectedDate = null;
      selectedDateString = "";
      dualCalender = true;
    } else if (
      value !== "between" &&
      state.selectedFilterOperator === "between"
    ) {
      selectedDate = null;
      selectedDateString = "";
      dualCalender = false;
    }

    if (value === "isNull") {
      calenderDisable = true;
      value = "is null";
      selectedDate = null
      selectedDateString = ""
    } else if (value === "isNotNull") {
      calenderDisable = true;
      value = "is not null";
      selectedDate = null
      selectedDateString = ""
    }

    newState.dualCalender = dualCalender;
    newState.selectedDate = selectedDate;
    newState.selectedDateString = selectedDateString;
    newState.calenderDisable = calenderDisable;
    newState.dateInput = dateInput;
    newState.selectedFilterOperator = value;

    this.setState(
      newState,
      () => {
        if (selectedDate !== null && selectedDate !== "") {
          let changedFormatDates = this.getFormatChangeForSelectedDate(
            selectedDate,
            selectedDateString,
            value
          );
          this.props.selectedDate(
            changedFormatDates.dates,
            changedFormatDates.dateStrings,
            changedFormatDates.filterOperator,
            this.state.dateFormat
          );
        } else if (value === "is null" || value === "is not null") {
          this.props.selectedDate("", "", value);
        }
      }
    );
  };

  /**
   * Set selected date
   * 
   * @param {*} dates 
   * @param {*} dateStrings 
   */
  setSelectedDate = (dates, dateStrings) => {
    this.setState({
      selectedDate: dates,
      selectedDateString: dateStrings,
    });
  };

  /**
   * Handle popup open change
   */
  onOpenChange = () => {
    let status = this.state.openPicker;
    let showCustomDate = this.state.showCustomDate;

    if (status === true) {
      // If the popup is closing, it will also close the custom date field
      showCustomDate = "none";
    }

    this.setState(
      {
        openPicker: !status,
        showCustomDate: showCustomDate,
      },
      () => {
        if (status === true) {
          this.onOk();
        }
      }
    );
  };

  render() {
    let dateTimePickerValue = this.state.selectedFilterOperator !== "is null" || this.state.selectedFilterOperator !== "is not null" ? this.state.selectedDate : null
    let shouldShowOperator = this.props.config.showOperatorOptions == undefined ? true : this.props.config.showOperatorOptions;

    return (
      <div id={`filter-selection-${this.props.plugin.id}`}>
        <Row gutter={4}>
          {shouldShowOperator ?
            <Col span={6}>
              <FilterOperators
                value={this.state.selectedFilterOperator}
                setFilterOperator={this.setFilterOperator}
                style={{ width: "100%" }}
                dropdownAppearance={this.props.dropdownAppearance}
              ></FilterOperators>
            </Col> : <Col span={1} />}
          <Col span={shouldShowOperator ? 18 : 24}>
            <DateTimePicker
              showTime={
                this.isTimestampType(this.timestampType, this.props)
                  ? {
                    defaultOpenValue: moment("00:00:00", "HH:mm:ss"),
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                  }
                  : false
              }
              dualCalender={this.state.dualCalender}
              onChange={this.setSelectedDate}
              onOk={this.onOk}
              open={this.state.openPicker}
              onOpenChange={this.onOpenChange}
              format={this.state.dateFormat}
              value={dateTimePickerValue}
              disabled={this.state.calenderDisable}
              renderExtraFooter={this.getExtraFooter}
              selectedDate={this.state.selectedDate}
              selectedFilterOperator={this.state.selectedFilterOperator}
              filterSelectionProps={this.props}
              pluginId={this.props.plugin.id}
              clickedRefresh={this.props.clickedRefresh}
              isClearable={true}
            ></DateTimePicker>
          </Col>
        </Row>
      </div>
    );
  }
}
