export const configuration = {
    spiral: "Archimedean",
    scale: "Linear",
    minSize: 12,
    maxSize: 36,
    font: "Open Sans",
    orientations: 5,
    fromAngle: -60,
    toAngle: 60,
    width: 400,
};

export const actions = [
    {
        trigger: "wordClick",
        type: "click",
        output: ["word"],
        name: "Tıklama - Kelime",
        description: "WordClickDesc"
    },
    {
        trigger: "wordHover",
        type: "hover",
        output: ["word"],
        name: "Hover - Kelime",
        description: "WordHoverDesc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["word", "freq"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
        }
    }
};