export const actions = [
    {
        trigger: "pointClick",
        type: "click",
        name: "Tıklama - Nokta",
        output: ["group", "varyColour", "animation"],
        description: "PointClickDesc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const configuration = {
    fillTargets: "area",
    showTargets: false,
    targets: [
        {
            color: "#FF0000"
        }
    ],
    minPointSize: 2,
    maxPointSize: 6,
    legend: true,
    condFormat: true,
    showHideButton: false,
    lr: true,
    xTitleFontSize: "12",
    xTitleFontStyle: false,
    xTitleFontWeight: false,
    xTitleTextDecor: false,
    yTitleFontSize: "12",
    yTitleFontStyle: false,
    yTitleFontWeight: false,
    yTitleTextDecor: false,
    animationTitleOversizeBehaviour: "fit",
    animationLabel: true,
    scrollbarX: false,
    scrollbarY: false,
    xAxisAlignment: "bottom",
    yAxisAlignment: "left",
    duration: 1
};

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["animation", "group", "varyColour", "varySize", "measureX", "measureY"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        }
    },
    "chord-diagram": {
        columnMap: {
            entities: {
                name: "Entities",
                from: ["animation", "group", "varyColour"],
                multiple: true,
                minimumColumnSize: 2,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measureX"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["measureY", "varySize"]
            }
        }
    },
    "sankey-chart": {
        columnMap: {
            level: {
                name: "Level",
                from: ["animation", "group", "varyColour"],
                multiple: true,
                minimumColumnSize: 2,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measureX"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["measureY", "varySize"]
            }
        }
    },
    "cylinder-bar": {
        columnMap: {
            label: {
                name: "Label",
                from: ["group"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            values: {
                name: "Measure",
                from: ["measureX", "measureY"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["varyColour", "varySize", "animation"]
            }
        }
    },
    "age-pyramid": {
        columnMap: {
            category: {
                name: "Category",
                from: ["group"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            either: {
                name: "Either",
                from: ["measureX"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            animation: {
                name: "Animation",
                from: ["animation"],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            other: {
                name: "Other",
                from: ["measureY"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["varyColour", "varySize"]
            }
        }
    },
    "multi-axis-line-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["group"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: ["measureX"],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: ["measureY"],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: ["varyColour"],
                multiple: false,
                minimumColumnSize: 0,
            },
            hidden: {
                from: ["varySize", "animation"]
            }
        }
    },
    "bar-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["group"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: ["measureX", "measureY"],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: [],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: ["varyColour"],
                multiple: false,
                minimumColumnSize: 0,
            },
            hidden: {
                from: ["varySize", "animation"]
            }
        }
    },
    "line-bar": {
        columnMap: {
            category: {
                name: "Category",
                from: ["group"],
                multiple: false,
                minimumColumnSize: 1,
            },
            columns: {
                name: "Columns",
                from: [],
                dependentColumns: ["Lines"],
                multiple: true,
                minimumColumnSize: 1,
            },
            lines: {
                name: "Lines",
                from: ["measureX", "measureY"],
                dependentColumns: ["Columns"],
                multiple: true,
                minimumColumnSize: 1,
            },
            vary: {
                name: "Vary",
                from: ["varyColour"],
                multiple: false,
                minimumColumnSize: 0,
            },
            hidden: {
                from: ["varySize", "animation"]
            }
        }
    },
    "measure-tile": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measureX", "measureY", "varySize"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["group", "varyColour", "animation"]
            }
        }
    },
    "pie-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["group"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measureX"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["measureY", "varySize", "varyColour", "animation"]
            }
        }
    },
    "radial-progress": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measureX"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            target: {
                name: "Target",
                from: ["measureY"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["group", "varyColour", "varySize", "animation"]
            }
        }
    },
    "sunburst": {
        columnMap: {
            level: {
                name: "Level",
                from: ["animation", "group", "varyColour"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measureX"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["measureY", "varySize"]
            }
        }
    },
    "gauge-chart": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measureX"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            minimum: {
                name: "Minimum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            maximum: {
                name: "Maximum",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            target: {
                name: "Target",
                from: [],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["measureY", "group", "varyColour", "varySize", "animation"]
            }
        }
    },
    "liquid-gauge": {
        columnMap: {
            measure: {
                name: "Measure",
                from: ["measureX"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            target: {
                name: "Target",
                from: ["measureY"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["group", "varyColour", "varySize", "animation"]
            }
        }
    },
    "radar": {
        columnMap: {
            category: {
                name: "Category",
                from: ["group"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measureX", "measureY"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["varySize", "varyColour", "animation"]
            } 
        }
    },
    "tree-map": {
        columnMap: {
            group: {
                name: "Group",
                from: ["group"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measureX"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            vary: {
                name: "Vary",
                from: ["varyColour"],
                multiple: false,
                minimumColumnSize: 0,
                required: false
            },
            hidden: {
                from: ["measureY", "varySize", "animation"]
            }
        }
    },
    "word-cloud": {
        columnMap: {
            word: {
                name: "Word",
                from: ["group"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            freq: {
                name: "Frequency",
                from: ["measureX"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["measureY", "varySize", "varyColour", "animation"]
            }
        }
    },
    "pivot-table": {
        columnMap: {
            rows: {
                name: "Rows",
                from: ["group"],
                multiple: true,
                minimumColumnSize: 0
            },
            columns: {
                name: "Columns",
                from: ["animation", "varyColour"],
                multiple: true,
                minimumColumnSize: 0
            },
            measures: {
                name: "Columns",
                from: ["measureX", "measureY"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            hidden: {
                from: ["varySize"]
            }
        }
    }
};