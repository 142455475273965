import "antd/dist/antd.css";
import "../../css/bootstrap.min.css";
import "./PopupContainer.css";
import React, { Component } from "react";

class PopupContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: this.props.height ? this.props.height : "max-content",
      position: this.props.position ? this.props.position : "right",
      constantHeight: this.props.constantHeight
        ? this.props.constantHeight
        : "max-content",
      additionalPosition: this.props.additionalPosition
        ? this.props.additionalPosition
        : "0px",
      top: this.props.top ? this.props.top : "0",
      navbar: this.props.navbar ? true : false
    };
  }

  render() {
    const popupContainerWidth = this.props.width ? this.props.width : "150px";
    const popupPositionCalibration = this.props.positionCalibration
              ? this.props.positionCalibration
              : popupContainerWidth + " - " + this.state.additionalPosition 
    return (
      <div
        className={
          this.props.className
            ? "popupContainer " + this.props.className
            : "popupContainer"
        }
        style={{
          ...this.props.style,
          position: this.props.position ? this.props.position : "",
          right: this.props.right ? this.props.right : "",
          paddingRight: "5px",
          width: popupContainerWidth,
          top: this.props.top ? this.props.top : "0",
          backgroundColor: this.props.background ? this.props.background : "#fff",
          color: this.props.color ? this.props.color : "initial", 
          overflow: this.props.overflow ? this.props.overflow : "visible",
          boxShadow: this.props.shadow ? this.props.shadow : "0 1px 2px 0 rgba(60, 64, 67, .30), 0 2px 6px 2px rgba(60, 64, 67, .15)",
          marginBottom: this.props.marginBottom ? this.props.marginBottom : "0",
          zIndex: this.props.zIndex ? this.props.zIndex : "1000",
          [this.state.position]: !this.state.navbar ? "calc(-5px - " + popupPositionCalibration + ")" : "50px",
        }}
        ref={this.props.popupRef ? this.props.popupRef : undefined}
      >
        <div
          className={
            this.props.before != "noBefore"
              ? "innerPopup"
              : "innerPopup noBefore"
          }
          onMouseOver={this.props.onMouseOver}
          style={{
            width: "100%",
            maxHeight: this.state.height,
            height: this.state.constantHeight,
            textAlign: this.props.textAlign ? this.props.textAlign : "center",
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PopupContainer;
