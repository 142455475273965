import React, { Component } from "react";
import { BASE_URL_REPORTS, BASE_URL_UI, fullViewWidth } from "../../config";
import Button from "../GeneralComponents/Button/Button";
import i18n from "../../Utils/i18next";
import { TagsOutlined } from "@ant-design/icons";
import LabelFilter from "./LabelFilter";
import { isValidWriteRoles } from "./RoleStore"
import Text from "../GeneralComponents/Text/Text";

export default class DashboardArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleSelector: false,
    };
  }

  /**
   * open labelfilter
   */
  openFilter = () => {
    if (this.state.visibleSelector != true) {
      this.setState({
        visibleSelector: true,
      });
    }
  };

  /**
   * close labelfilter
   */
  closeFilter = () => {
    this.setState({ ...this.state, visibleSelector: false });
  };

  render() {
    let theme = this.props.themeObj?.topBar;

    return (
      <div
        class="dashboard-setting"
        style={{
          display: "flex",
          backgroundColor: theme?.backgroundColor,
          color: theme?.textColor,
          borderColor: theme?.textColor + "4d"
        }}
      >
        <style>
          {theme && `
            #newDashboardButton,
            #newReportButton,
            #labelButton {
              color: ${theme.textColor};
              background-color: ${theme.backgroundColor};
              border-color: ${theme.textColor}33;
            }

            #newDashboardButton:hover,
            #newDashboardButton:active,
            #newDashboardButton:focus,
            #newReportButton:hover,
            #newReportButton:active,
            #newReportButton:focus,
            #labelButton:hover,
            #labelButton:active,
            #labelButton:focus {
              color: ${theme.backgroundColor};
              background-color: ${theme.textColor};
              border-color: ${theme.backgroundColor}33;
              box-shadow: none;
              -ms-box-shadow: none;
            }
          `}
        </style>

        <div className={"hide-on-mobile"}>
          {isValidWriteRoles() ?
            <>
              <a href={BASE_URL_UI + "/dashboard"} style={{ display: "contents" }}>
                <Button
                  id="newDashboardButton"
                  className="btn btn-new new-button"
                  tooltip={i18n.t("LandingPage.NewDashboard")}
                >
                  {i18n.t("LandingPage.NewDashboard")}
                </Button>
              </a>
              <a href={BASE_URL_REPORTS + "/designer"} style={{ display: "contents" }}>
                <Button
                  id="newReportButton"
                  className="btn btn-new new-button"
                  tooltip={i18n.t("LandingPage.NewReport")}
                >
                  <span className={"label-text"}>
                    {i18n.t("LandingPage.NewReport")}
                  </span>
                </Button>
              </a>
            </> : null
          }
        </div>

        <Button
          id="labelButton"
          className="btn btn-new circle-button hide-on-mobile"
          tooltip={i18n.t("LandingPage.Label.Title")}
          onClick={this.openFilter}
        >
          <TagsOutlined className="label-icon" />
          <Text className="label-text">{i18n.t("Labels")}</Text>
          <LabelFilter
            visibleSelector={this.state.visibleSelector}
            closeFilter={this.closeFilter}
            selectedLabels={this.props.selectedLabels}
            changeSelectedLabels={this.props.changeSelectedLabels}
          ></LabelFilter>
        </Button>
      </div>
    );
  }
}
