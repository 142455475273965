import { rmvpp } from "../../ui/Plugins/RenderJs/rmvpp";
import { obiee } from "../../ui/Plugins/RenderJs/obiee";
import XLSX from "xlsx";
import $ from "jquery";
import { saveAs } from "file-saver";
import i18n from "../../Utils/i18next";
import { showNotificationWithIcon } from "../../Utils/Notification";
import Cookies from "js-cookie";
import { post } from "../WebService";
import { API_BASE } from "../../config";
import { deepCopy, loadingScreen } from "../Global";
import { isNaN } from "lodash";
import React from "react"
/**
 * Converts a dataset(array of arrays) to an Excel worksheet format
 */
let warningCount = 0;
let missingDataPlugins = [];

export const pluginDataExcelFormatter = (data, columns, visual) => {
  if (data != undefined) {
    let newData = []
    
    for (let i = 0; i < data.length; i++) {
      let columnMapKeys = Object.keys(visual.columnMap)
      let dataKeys = Object.keys(data[i]).filter(d => columnMapKeys.includes(d))
      let dataObj = {}
      
      for (let j = 0; j < dataKeys.length; j++) {
        if (Array.isArray(data[i][dataKeys[j]])) {
          for (let k = 0; k < data[i][dataKeys[j]].length; k++) {
               if (visual.columnMap[dataKeys[j]].data[k]) {
                dataObj[visual.columnMap[dataKeys[j]].data[k].displayName] = typeof data[i][dataKeys[j]][k].value === "null" || typeof data[i][dataKeys[j]][k].value === "undefined" ? "" : data[i][dataKeys[j]][k].value
              }
          }
        } else {
          let displayName = Array.isArray(visual.columnMap[dataKeys[j]].data) ? visual.columnMap[dataKeys[j]].data[0].displayName : visual.columnMap[dataKeys[j]].data.displayName;

          dataObj[displayName] = typeof data[i][dataKeys[j]] === "null" || typeof data[i][dataKeys[j]] === "undefined" ? "" : data[i][dataKeys[j]]
        }
      }
    
      newData.push(dataObj)
    }  

    data = newData
    
    if (visual.key === "pivot-table" && data.length > 0) {
      //&& 1 === 2) {
      // Function for pivot table sheet
      function sheetFromData(dataArg, columnsArg, visualArg) {
        // Converts date to an Excel number date format
        function datenum(v, date1904) {
          if (date1904) v += 1462;
          var epoch = Date.parse(v);
          return (
            (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000)
          );
        }

        //round method
        function roundTo(n, digits) {
          if (digits === undefined) {
            digits = 0;
          }
        }

        var ws = {};
        var range = {
          s: {
            c: 10000000,
            r: 10000000
          },
          e: {
            c: 0,
            r: 0
          }
        }; // Maximum sheet size

        function setRange(R, C) {
          if (range.s.r > R) range.s.r = R;
          if (range.s.c > C) range.s.c = C;
          if (range.e.r < R) range.e.r = R;
          if (range.e.c < C) range.e.c = C;
        }

        //Headers for timestamp
        let exportDate = i18n.t("ExportDate");

        R = 0;
        C = 0;

        setRange(R, C);
        var cell = {
          v: exportDate + ": " + rmvpp.generateDate(),
          t: "s",
          s: {
            font: {
              bold: true
            },
            alignment: {
              horizontal: "center"
            }
          }
        };
        var cell_ref = XLSX.utils.encode_cell({
          c: C,
          r: R
        });
        ws[cell_ref] = cell;
        R++;

        R = 1;
        C = 0;
        let dataBeginningRow = 2; //row where data will begin to show (first row for date so data will start from second row)

        //Title for Export
        let title = visualArg.config.title;

        if (title && title.length > 0) {
          setRange(R, C);

          var cell = {
            v: title,
            t: "s",
            s: {
              font: {
                bold: true
              },
              alignment: {
                horizontal: "center"
              }
            }
          };
          var cell_ref = XLSX.utils.encode_cell({
            c: C,
            r: R
          });
          ws[cell_ref] = cell;
          R = 2;
          C = 0;
          dataBeginningRow = 3; // row where data will begin to show (first row for date, second row for title so data will start from third row)
        }

        for (let col in dataArg[0]) {
          setRange(R, C);
          var cell = {
            v: col,
            t: "s",
            s: {
              font: {
                bold: true
              },
              alignment: {
                horizontal: "center"
              }
            }
          };
          var cell_ref = XLSX.utils.encode_cell({
            c: C,
            r: R
          });
          ws[cell_ref] = cell;
          C++;
        }

        // Add data
        for (
          var R = dataBeginningRow;
          R != dataArg.length + dataBeginningRow;
          ++R
        ) {
          // the starting row of the data is determined above
          var C = 0; // Column index

          for (let col in dataArg[R - dataBeginningRow]) {
            setRange(R, C);

            var colObj = columnsArg.filter(function (c) {
              return c.Name == col;
            });

            if (colObj.length > 0) {
              colObj = colObj[0];
            } else {
              colObj = false;
            }

            var cell = {};

            if (
              dataArg[R - dataBeginningRow][col] &&
              $.inArray(colObj.DataType, [
                "integer",
                "double",
                "numeric",
                "float",
                "double precision",
                "bigint"
              ]) > -1
            ) {
              let dataFormatRegex = /(\d.*)(f)/;
              let m = dataFormatRegex.exec(colObj.DataFormat);

              if (m && m.length > 0 && m[1] != null) {
                cell.v = +roundTo(dataArg[R - dataBeginningRow][col], m[1]);
              } else {
                cell.v = +dataArg[R - dataBeginningRow][col];
              }
            } else {
              cell.v = dataArg[R - dataBeginningRow][col];
            }

            if (isNaN(cell.v)) {
              cell.v = dataArg[R - dataBeginningRow][col];
            }

            if (cell.v == null) {
              continue;
            }

            var cell_ref = XLSX.utils.encode_cell({
              c: C,
              r: R
            });

            if (typeof cell.v === "number") cell.t = "n";
            // Numbers
            else if (typeof cell.v === "boolean") cell.t = "b";
            // Booleans
            else if (colObj.DataType == "date") {
              // Handle dates
              var dtVal = new Date(dataArg[R - dataBeginningRow][col]);
              cell.t = "n";
              cell.z = XLSX.SSF._table[14];
              cell.v = datenum(dtVal);
            } else if (
              colObj.DataType == "timestamp" ||
              colObj.DataType == "timestamp without time zone"
            ) {
              // Handle timestamps
              cell.t = "s";
              cell.v = colObj.format(cell.v, undefined, {
                DataType: "timestamp",
                Locale: Cookies.get("i18next").toUpperCase()
              });
            } else cell.t = "s";

            ws[cell_ref] = cell;
            C++;
          }
        }

        if (range.s.c < 10000000) ws["!ref"] = XLSX.utils.encode_range(range);
        return ws;
      }

      var pivotTableData = [];
      var obj = {};
      var vis = visual;
      let rowNames = visual.data[0].rows;
      let colNames = visual.data[0].columns;
      let measureNames = visual.data[0].measures;
      let colKeysMap = new Map();
      let rowKeysMap = new Map();
      let colKeys = new Array();
      let rowKeys = new Array();
      let colTotals = new Array();
      let rowTotals = new Array();
      let values = new Array();

      /*
      Column and row keys are stored in map object to match with their datas
      also column and row keys are stored in array 
      */
      for (let i = 0; i < visual.data.length; i++) {
        var colName = "";
        let colAndMeasureArray = new Array();

        for (let colKey = 0; colKey < colNames.length; colKey++) {
          colAndMeasureArray.push(data[i][colNames[colKey].name]);
        }

        for (
          let measureKey = 0;
          measureKey < measureNames.length;
          measureKey++
        ) {
          colAndMeasureArray.push(measureNames[measureKey].name);
          colName = colAndMeasureArray.join("");

          if (colKeysMap.has(colName) !== true) {
            colKeysMap.set(colName);
            let colAndMeasureArrayClone = [...colAndMeasureArray];
            colKeys.push(colAndMeasureArrayClone);
          }

          colAndMeasureArray.pop(measureNames[measureKey].name);
        }

        var rowName = "";
        let rowKeysArray = new Array();

        for (let rowKey = 0; rowKey < rowNames.length; rowKey++) {
          rowKeysArray.push(data[i][rowNames[rowKey].name]);
        }

        rowName = rowKeysArray.join("");

        if (rowKeysMap.has(rowName) !== true) {
          rowKeysMap.set(rowName);

          if (!rowKeysArray.length == 0) {
            rowKeys.push(rowKeysArray);
          }
        }
      }

        let rowTotalMeasureValue;
        let colTotalMeasureValue;
        let hiddenColumnsTotalValue;
        /*
        Row keys are matched with their datas. Row and column total is calculated and stored in array 
        */
        for (
          let measureIndex = 0;
          measureIndex < measureNames.length;
          measureIndex++
        ) {
          let rowKeysMapClone = new Map(rowKeysMap);
          let colTotal = new Map();
          let rowTotal = new Map();

          for (let i = 0; i < visual.data.length; i++) {
            let measureValue;
            let measureStringValue;
            let rowName = "";
            measureStringValue = data[i][measureNames[measureIndex].name];

            if (measureStringValue === "") {
              measureValue = 0;
            } else {
              measureValue = parseFloat(measureStringValue);
            }

            for (let j = 0; j < rowNames.length; j++) {
              rowName = rowName.concat(data[i][rowNames[j].name]);
            }

            //if rows mapping has no value to key, giving value to a new Map object for value
            if (rowKeysMapClone.get(rowName) === undefined) {
              rowKeysMapClone.set(rowName, new Map());
              let columnsMap = rowKeysMapClone.get(rowName);
              let colName = ""; // = colKeys[i].join('');

              for (let k = 0; k < colNames.length; k++) {
                colName = colName.concat(data[i][colNames[k].name]);                      
              }
             
              colName = colName.concat(measureNames[measureIndex].name);
              
              // if a new Map object that is given has not column measure pair, adding it
              if (columnsMap.has(colName) !== true) {
                columnsMap.set(colName, measureValue);
              } else {
                hiddenColumnsTotalValue = parseFloat(columnsMap.get(colName));

                if (isNaN(hiddenColumnsTotalValue)) {
                  hiddenColumnsTotalValue = 0;
                }

                columnsMap.set(colName, measureValue + hiddenColumnsTotalValue);
              }

              rowTotalMeasureValue = 0;
              colTotalMeasureValue = parseFloat(colTotal.get(colName));

              if (isNaN(colTotalMeasureValue)) {
                colTotalMeasureValue = 0;
              }

              colTotal.set(colName, measureValue + colTotalMeasureValue);
              rowTotal.set(rowName, measureValue + rowTotalMeasureValue);
            } else {
              let columnsMap = rowKeysMapClone.get(rowName);
              let colName = "";

              for (let k = 0; k < colNames.length; k++) {
                colName = colName.concat(data[i][colNames[k].name]);
              }

              colName = colName.concat(measureNames[measureIndex].name);

              if (columnsMap.has(colName) !== true) {
                columnsMap.set(colName, measureValue);
              } else {
                hiddenColumnsTotalValue = parseFloat(columnsMap.get(colName));

                if (isNaN(hiddenColumnsTotalValue)) {
                  hiddenColumnsTotalValue = 0;
                }

                columnsMap.set(colName, measureValue + hiddenColumnsTotalValue);
              }

              colTotalMeasureValue = parseFloat(colTotal.get(colName));

              if (isNaN(colTotalMeasureValue)) {
                colTotalMeasureValue = 0;
              }

              colTotal.set(colName, measureValue + colTotalMeasureValue);
              rowTotalMeasureValue = parseFloat(rowTotal.get(rowName));
              rowTotal.set(rowName, measureValue + rowTotalMeasureValue);
            }
          }

          colTotals.push(colTotal);
          rowTotals.push(rowTotal);
          values.push(rowKeysMapClone);
        }

      //For Headers
      for (let rowName of rowNames) {
        obj[rowName.name] = "";
      }

      //if Row Length is 0 then giving the column cell for first cell for coloumn names
      if (rowNames.length == 0) {
        let colCell = " ";
        obj[colCell] = " ";
      }

      for (const keys of colKeys) {
        let colHeader = "",
          length = keys.length,
          index = 0;

        for (const key of keys) {
          index++;
          colHeader += key;
          if (index != length) colHeader += "\n";
        }

        obj[colHeader] = "";
      }

      //For Row Totals Header
      let isTotalRowSelected = ["ShowBoth", "JustRow", "Both"].includes(vis.config.sumSelector);
      let enableTotalRow = vis.config.showSum && isTotalRowSelected;

      if (rowNames.length != 0 && enableTotalRow) {
        obj[i18n.t("Total")] = obj[i18n.t("Total")] || "";
      }

      //For Values
      for (const keys of rowKeys) {
        obj = {};
        var rowKey = "";

        for (let i = 0; i < rowNames.length; i++) {
          obj[rowNames[i].name] = keys[i];
          rowKey += keys[i];
        }

        //Fill obj with column keys
        for (const colKey of colKeys) {
          let colName = "";

          for (const key of colKey) {
            colName += key;
          }

          let k = 0;
          obj[colName] = "";
          k++;
        }

        for (let colValues of values) {
          //Fill obj with values
          let columnKeysMap = colValues.get(rowKey);
          let columnsKeyNames = Array.from(columnKeysMap.keys());

          for (let i = 0; i < columnsKeyNames.length; i++) {
            let subKey = columnsKeyNames[i];
            let valuesItem = columnKeysMap.get(subKey);
            let subKeys = subKey.split("\u0000");
            let value = parseFloat(valuesItem);
            let formattedValue = value;

            if (!isNaN(+formattedValue)) {
              value = +formattedValue;
            } else {
              value = valuesItem;
            }

            obj[subKeys.join("")] = value;
          }
        }

        // If pivot has column for row totals
        if (
          (measureNames.length > 1 || colKeys.length > 1) &&
          rowTotals.length > 0 &&
          colNames.length > 0 &&
          enableTotalRow
        ) {
          let rowTotalIndex = 0;
          let sumCols = 0;

          for (let rowTotal of rowTotals) {
            let rowTotalValue = rowTotal.get(rowKey);
            let formattedValue = rowTotalValue;

            if (!isNaN(+formattedValue)) {
              rowTotalValue = +formattedValue;
            }

            // When there is more than one total value, the value name is added to the total header
            if (measureNames.length > 1) {
              obj[`${measureNames[sumCols].name} ${i18n.t("Total")}`] = rowTotalValue;
            } else {
              obj[i18n.t("Total")] = rowTotalValue;
            }

            rowTotalIndex++;
            sumCols++;
          }
        }

        pivotTableData.push(obj);
      }

      let isTotalColumnSelected = ["ShowBoth", "JustColumn", "Both"].includes(vis.config.sumSelector);
      let enableTotalColumn = vis.config.showSum && isTotalColumnSelected;

      //For Column Totals
      if (colTotals.length > 0 && enableTotalColumn) {
        obj = {};

        if (colNames[0] == undefined) {
          obj[visual.columnMap.measures.name] = i18n.t("Total");
        } else {
          obj[colNames[0].name] = i18n.t("Total");
        }

        for (let i = 1; i < rowNames.length; i++) {
          obj[rowNames[i].name] = "";
        }
        
        let colTotalIndex = 0;

        for (const colKey of colKeys) {
          for (const colTotal of colTotals) {
            var colName = "";

            for (let i = 0; i < colKey.length; i++) {
              colName = colName.concat(colKey[i]);
            }

            const item = colTotal.get(colName);

            if (typeof item !== "undefined" && !isNaN(item)) {
              let colTotalValue = item;
              let formattedValue = colTotalValue;

              if (!isNaN(+formattedValue)) {
                colTotalValue = +formattedValue;
              }

              obj[colName] = colTotalValue;
              colTotalIndex++;
            }
          }
        }

        pivotTableData.push(obj);
      }

      let visObjects = Object.values(vis.columnMap);
      let columns = [];

      for (let i = 0; i < visObjects.length; i++) {
        for (let j = 0; j < visObjects[i].data.length; j++) {
          columns.push(visObjects[i].data[j]);
        }
      }

      var ws = sheetFromData(pivotTableData, columns, vis);
    } else {
      var dataFormatRegex = /(\d.*)(f)/;
      // Converts date to an Excel number date format
      function datenum(v, date1904) {
        if (date1904) v += 1462;

        let epoch = Date.parse(v);  
              
        return (
          (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000)
        );
      }

      var roundTo = (n, digits) => {
        if (digits === undefined) {
          digits = 0;
        }

        let multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        return (Math.round(n) / multiplicator).toFixed(digits);
      };

      var ws = {};
      var range = {
        s: {
          c: 10000000,
          r: 10000000
        },
        e: {
          c: 0,
          r: 0
        }
      }; // Maximum sheet size

      function setRange(R, C) {
        if (range.s.r > R) range.s.r = R;
        if (range.s.c > C) range.s.c = C;
        if (range.e.r < R) range.e.r = R;
        if (range.e.c < C) range.e.c = C;
      }

      // Headers (date -> title -> columnNames)

      let exportDate = i18n.t("ExportDate"); //Headers for timestamp -->

      let R = 0;
      let C = 0;

      setRange(R, C);
      var cell = {
        v: exportDate + ": " + rmvpp.generateDate(),
        t: "s",
        s: {
          font: {
            bold: true
          },
          alignment: {
            horizontal: "center"
          }
        }
      };

      var cell_ref = XLSX.utils.encode_cell({
        c: C,
        r: R
      });
      ws[cell_ref] = cell;
      R++;

      // <--Headers for timestamp

      R = 1;
      C = 0;
      let dataBeginningRow = 2; //row where data will begin to show (first row for date so data will start from second row)

      //Title for Export
      let title = visual.config.title;

      if (title && title.length > 0) {
        setRange(R, C);

        var cell = {
          v: title,
          t: "s",
          s: {
            font: {
              bold: true
            },
            alignment: {
              horizontal: "center"
            }
          }
        };

        var cell_ref = XLSX.utils.encode_cell({
          c: C,
          r: R
        });
        ws[cell_ref] = cell;
        R = 2;
        C = 0;
        dataBeginningRow = 3; // row where data will begin to show (first row for date, second row for title so data will start from third row)
      }

      let columnNameArray = [];
      let columnDisplayNames = [];
      let columnName = Object.keys(visual.columnMap);

      for (let i = 0; i < columnName.length; i++) {
        let column = visual.columnMap[columnName[i]];
        let columnData = column.isDrilldownActive ? column.data : column.data[0];

        let isColumnAvailable = column && columnData && column.type !== "hidden";

        if (isColumnAvailable) {
          if (column.multiple == undefined || column.multiple == false) {
            if (!columnDisplayNames.includes(columnData.displayName)) {
              columnNameArray[columnNameArray.length] = {
                displayName: columnData.displayName,
                columnName: columnData.name,
                columnMap: columnName[i]
              };

              columnDisplayNames.push(columnData.displayName);
            }
          }

          if (column.multiple == true) {
            for (let j = 0; j < column.data.length; j++) {
              if (!columnDisplayNames.includes(column.data[j].displayName)) {
                columnNameArray[columnNameArray.length] = {
                  displayName: column.data[j].displayName,
                  columnName: column.data[j].name
                };

                columnDisplayNames.push(column.data[j].displayName);
              }
            }
          }
        }
      }

      let enableTotal = (visual.key === "table" && visual.config.enableTotal) || visual.key !== "table";

      if (enableTotal) {
        C = 1;
      }

      if (data.length > 0) {
        for (obiee.col in data[0]) {
          let displayName = obiee.col;

          setRange(R, C);

          for (let i = 0; i < columnNameArray.length; i++) {
            let column = columnNameArray[i];

            if (column.columnMap === obiee.col) {
              displayName = column.displayName;
            }
          }

          var cell = {
            v: displayName,
            t: "s",
            s: {
              font: {
                bold: true
              },
              alignment: {
                horizontal: "center"
              }
            }
          };

          var cell_ref = XLSX.utils.encode_cell({
            c: C,
            r: R
          });
          let columnSizeControl = Object.keys(ws);

          if (columnSizeControl.length < columnNameArray.length + 2) {
            ws[cell_ref] = cell;
            cell.x = true;
          }

          C++;
        }
      } else {
        let displayName = ""

        for (let i = 0; i < columnNameArray.length; i++) {
          let column = columnNameArray[i];

          displayName = column.displayName;

          setRange(R, C);

          var cell = {
            v: displayName,
            t: "s",
            s: {
              font: {
                bold: true
              },
              alignment: {
                horizontal: "center"
              }
            }
          };

          var cell_ref = XLSX.utils.encode_cell({
            c: C,
            r: R
          });
          let columnSizeControl = Object.keys(ws);

          if (columnSizeControl.length < columnNameArray.length + 2) {
            ws[cell_ref] = cell;
            cell.x = true;
          }

          C++;
        }
      }

      //-----Headers end
      let totalObj = {};
      let pluginData = deepCopy(data);
      let controlLength = columnNameArray.length;

      if (enableTotal) {
        for (let dataIndex in pluginData) {
          for (obiee.col in pluginData[dataIndex]) {
            if (totalObj[obiee.col] === "") {
              continue;
            }

            let dataValue = Number(pluginData[dataIndex][obiee.col]);

            if (isNaN(dataValue)) {
              totalObj[obiee.col] = "";
              
              continue;
            }

            if (Object.keys(totalObj).includes(obiee.col)) {
              let gettedValue = totalObj[obiee.col];
  
                totalObj[obiee.col] = gettedValue + parseFloat(dataValue);
            } else {
              totalObj[obiee.col] = parseFloat(dataValue);
            }
          }
        }
          
        pluginData.push(totalObj);
      }

      // Add data
      for (R = dataBeginningRow; R != pluginData.length + dataBeginningRow; ++R) {
        // the starting row of the data is determined above

        let dataIndex = R - dataBeginningRow;
        C = enableTotal ? 1 : 0; // Column index

        for (obiee.col in pluginData[dataIndex]) {
          setRange(R, C);

          if (controlLength >= C) {
            var colObj = columns.filter(function (c) {
              return c.name == obiee.col;
            });

            let notHiddenCol =
              columnNameArray.filter(column => {
                return (
                  column.columnMap === obiee.col ||
                  column.displayName === obiee.col
                );
              }).length > 0;

            if (colObj.length > 0) {
              colObj = colObj[0];
            } else {
              colObj = false;
            }

            var cell = {};

            function dataTypeChecker() {
              //returns true if the data contains integer, double, numeric, float, double precision, bigint
              if (
                pluginData[dataIndex][obiee.col] &&
                $.inArray(colObj.DataType, [
                  "integer",
                  "double",
                  "numeric",
                  "float",
                  "double precision",
                  "bigint"
                ]) > -1
              ) {
                return true;
              }
              return false;
            }

            if (dataTypeChecker()) {
              let m = dataFormatRegex.exec(colObj.DataFormat);

              if (m && m.length > 0 && m[1] != null) {
                cell.v = +roundTo(pluginData[dataIndex][obiee.col], m[1]);
              } else {
                cell.v = +pluginData[dataIndex][obiee.col];
              }
            } else if (notHiddenCol) {
              cell.v = pluginData[dataIndex][obiee.col];
            }

            if (isNaN(cell.v) && notHiddenCol) {
              cell.v = pluginData[dataIndex][obiee.col];
            }

            if (cell.v == null) {
              continue;
            }

            if (typeof cell.v == "object") {
              continue;
            }

            var cell_ref = XLSX.utils.encode_cell({
              c: C,
              r: R
            });

            if (typeof cell.v === "number") cell.t = "n";
            // Numbers
            else if (typeof cell.v === "boolean") cell.t = "b";
            // Booleans
            else if (colObj.DataType == "date") {
              // Handle dates
              var dtVal = new Date(pluginData[dataIndex][obiee.col]);
              cell.t = "n";
              cell.z = XLSX.SSF._table[14];
              cell.v = datenum(dtVal);
            } else if (
              colObj.DataType == "timestamp" ||
              colObj.DataType == "timestamp without time zone"
            ) {
              // Handle timestamps
              cell.t = "s";
              cell.v = colObj.format(cell.v, undefined, {
                DataType: "timestamp",
                Locale: Cookies.get("i18next").toUpperCase()
              });
            } else cell.t = "s";

            ws[cell_ref] = cell;
            C++;
          }
        }
      }

      if (enableTotal) {
        C = 0;
        R = (dataBeginningRow + pluginData.length) - 1;

        setRange(R, C);

        var cell_ref = XLSX.utils.encode_cell({
          c: C,
          r: R
        });
        ws[cell_ref] = {v: i18n.t("Total")};
      }

      if (range.s.c < 10000000) ws["!ref"] = XLSX.utils.encode_range(range);
    }
    return ws;
  } else {
    if (visual.name !== undefined) {
        
      const pluginName = visual.key;
      missingDataPlugins[pluginName] = (missingDataPlugins[pluginName] || 0) + 1;
    }
  }
};

/* removes hidden elements from plugin.columnMap and sets pluginSortedColumnList without hidden columns */
const removeHiddenInformations = (
  pluginData,
  pluginSortedColumnList,
  plugin
) => {
  if (pluginData !== undefined && pluginData.length !== 0) {
    if (Array.isArray(pluginData[0].hidden)) {
      plugin.sortedColumnList.forEach(column => {
        if (column.locationFieldName !== "hidden") {
          pluginSortedColumnList.push(column);
        }
      });
    }

    if (pluginData[0].hidden) {
      delete plugin.columnMap.hidden;
    }
  }
};

/** 
  set plugin and if there is drillDown in the originalPlugin, 
  assign drillDown columns to plugin.columnMap and plugin.sortedColumnList
*/
const setPlugin = (plugin, originalPlugin) => {
  plugin.name = originalPlugin.name;
  plugin.key = originalPlugin.key;
  plugin.data = originalPlugin.data;
  plugin.config = {
    title: originalPlugin.config.title,
    enableTotal: originalPlugin.config.enableTotal,
    showSum: originalPlugin.config.showSum,
    sumSelector: originalPlugin.config.sumSelector
  };

  if (originalPlugin.drillDownColumnMap) {
    plugin.columnMap = {};
    plugin.sortedColumnList = [];

    let columnMapFields = Object.keys(originalPlugin.columnMap);

    for (let columnsField of columnMapFields) {
      let columnsFieldData = originalPlugin.drillDownColumnMap[columnsField];
      let isMultiple = originalPlugin.columnMap[columnsField].multiple;

      plugin.columnMap[columnsField] = {
        data: columnsFieldData,
        multiple: isMultiple,
        isDrilldownActive: true
      };
    }

    let drillDownColumnMapFields = Object.keys(
      originalPlugin.drillDownColumnMap
    );

    for (let columnsField of drillDownColumnMapFields) {
      if (typeof originalPlugin.drillDownColumnMap[columnsField] === "object") {
        plugin.sortedColumnList.push(
          originalPlugin.drillDownColumnMap[columnsField]
        );
      } else {
        for (let column of originalPlugin.drillDownColumnMap[columnsField]) {
          plugin.sortedColumnList.push(column);
        }
      }
    }
  } else {
    plugin.columnMap = { ...originalPlugin.columnMap };
    plugin.sortedColumnList = originalPlugin.sortedColumnList;
  }

  return plugin;
};

export const exportCustomExcel = (plugins, dashboardDetail, customExcelId, callback = undefined) => {
  let wbName;

  if (dashboardDetail.settings != undefined) {
    wbName = dashboardDetail.settings.title;
  } else {
    wbName = "Dashboard";
  }

  if (plugins && plugins.length > 0) {
    let exportObject = [];

    plugins.map(plugin => {
      let title = plugin.config.title;

      /*
      lower case
      replace whitespace with underscore
      non ascii chars remove
      */
      title = title.toLowerCase().replace(/\s+/g, "_").replace(/[^a-z0-9_]/g, "").replace(/[_]+$/g, "")

      if (plugin.data && plugin.data.length > 0) {
        exportObject.push({
          data: plugin.data,
          name: title
        });
      }
    });

    getCustomExcel(wbName, customExcelId, exportObject, callback);
  }
};

const getCustomExcel = (wbName, customExcelId, exportObject, callback) => {
  let url = `${API_BASE}/custom-excel/${customExcelId}`;

  const success = response => {
    let url = `${API_BASE}/custom-excel/g/${response.data}`;

    let event = new MouseEvent("click");
    let downloadLink;

    // Create download link element
    downloadLink = document.createElement("a");
    downloadLink.setAttribute("href", url);
    downloadLink.setAttribute("target", "_blank");
    downloadLink.setAttribute("download", wbName + ".xlsx");
    downloadLink.dispatchEvent(event);

    if (callback) {
      callback();
    }
  };

  post(url, exportObject, success);
};

/*
 ** Triggers when Excel Export button is clicked
 */
export const exportToXLSX = (plugins, dashboardDetail, callback = undefined, pluginExclusive = false) => {
  let wbName;
  warningCount = 0;
  missingDataPlugins = [];

  if (dashboardDetail.settings != undefined) {
    wbName = dashboardDetail.settings.title;
  } else {
    wbName = "Dashboard";
  }

  // Initialise Excel workbook object
  function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();
    this.SheetNames = [];
    this.Sheets = {};
    this.isPluginExist = false;
  }

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  let i = 0,
    ws;
  let wb = new Workbook();
  let pluginseperator = "";

  for (i = 0; i < plugins.length; i++) {
    if (plugins[i] !== undefined) {
      let pluginData = plugins[i].data;
      let pluginSortedColumnList = [];
      let plugin = setPlugin({}, plugins[i]);
  
      if (!plugin.name) {
        let words = plugins[i].key.split("-");
        words = words.map(
          word => word.charAt(0).toUpperCase() + word.substring(1)
        );
        plugin.name = words.toString().replace(/,/g, " ");
      } else {
        plugin.name = plugin.name[0].toUpperCase() + plugin.name.substring(1);
      }
  
      removeHiddenInformations(pluginData, pluginSortedColumnList, plugin);
      ws = pluginDataExcelFormatter(pluginData, pluginSortedColumnList, plugin);    
  
      if (plugins[i].data != undefined) {
        let pluginKey = plugins[i].key == "age-pyramid" ? "pyramid-chart" : plugins[i].key
  
        pluginseperator = "(" + (wb.SheetNames.length + 1) + ")";
        wb.SheetNames.push(pluginKey + " " + pluginseperator);
        wb.Sheets[pluginKey + " " + pluginseperator] = ws;
      } else {
        wb.isPluginExist = true
      }
    }
  }

  if (Object.keys(missingDataPlugins).length > 0) {
    let style;
    
    warningCount++;
  
    if ($(".ant-notification.ant-notification-topRight").length === 0) {
      style = {
        top: warningCount * 97,
      };
      
      warningCount++;
    }
  
    let missingDataPluginList = Object.entries(missingDataPlugins).map(([pluginKey, count]) => {
      let formattedName = pluginKey.split("-").map(
        word => word.charAt(0).toUpperCase() + word.substring(1)
      ).join(" ");
  
      return count > 1 ? `${formattedName} (${count})` : formattedName;
    });
  
    let messageKey = Object.keys(missingDataPlugins).length === 1 ? "DataNotFound" : "DataNotFoundMultiple";

    showNotificationWithIcon(
      i18n.t("Error"),
      <>
        <div>{i18n.t("Dashboard.ToolsSection." + messageKey)}</div>
          <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <ul style={{ marginLeft: 0, paddingLeft: '20px' }}>
              {missingDataPluginList.map(plugin => (
                <li style={{ margin: '0px' }}>{i18n.t(plugin)}</li>
              ))}
            </ul>
          </div>
      </>,
      "warn",
      style
    );
  }

  if (wb.SheetNames.length !== 0) {
    var wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary"
    });

    saveAs(
      new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
      }),
      wbName + ".xlsx"
    );

    if (callback) {
      callback()
    }
  } else if (wb.isPluginExist === false) {
    showNotificationWithIcon(
      i18n.t("Error"),
      i18n.t("Dashboard.ToolsSection.CantExportXLSX"),
      "error"
    );
  }

  loadingScreen(false);
};
