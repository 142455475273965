import React, { Component } from "react";
import { Row, Col, Input, Select, Divider, Checkbox, Tooltip } from "antd";
import Text from "../../../GeneralComponents/Text/Text";
import i18n from "../../../../Utils/i18next";
import $ from "jquery"
import { FontSizeOutlined, FormatPainterOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { JsonEditor as Editor } from 'jsoneditor-react';
import Ajv from 'ajv';
import ace from "brace";
import "brace/mode/json";
import "brace/theme/twilight";
import TitleSettingsOfPlugin from "../../../GeneralComponents/TitleSettingsOfPlugin/TitleSettingsOfPlugin";
import ReturnToTheme from "../../PluginToolsComponents/ReturnToTheme";

const ajv = new Ajv({ allErrors: true, verbose: true });

const { TextArea } = Input;
const { Option } = Select;

export default class VegaLiteConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vegaSpec: this.props.config.vegaSpec,
            title: this.props.config.title,
            titleAlign: this.props.config.titleAlign,
            topoJson: this.props.config.topoJson,
            isCurrentPluginAMap: this.props.config.isCurrentPluginAMap,
            titleFontStyle: this.props.config.titleFontStyle === undefined ? false : this.props.config.titleFontStyle,
            titleFontWeight: this.props.config.titleFontWeight === undefined ? false : this.props.config.titleFontWeight,
            titleFontSize: this.props.config.titleFontSize === undefined ? 15 : this.props.config.titleFontSize,
            titleFont: this.props.config.titleFont === undefined ? "Verdana" : this.props.config.titleFont,
            titleColour: this.props.config.titleColour === undefined ? "black" : this.props.config.titleColour,
            titleTextDecor: this.props.config.titleTextDecor === undefined ? false : this.props.config.titleTextDecor,
            changedTitleFontSize: this.props.config.changedTitleFontSize === undefined ? 15 : this.props.config.changedTitleFontSize,
            keyForTitleSize: null,
            noDataTitle: this.props.config.noDataTitle === undefined ? "" : this.props.config.noDataTitle
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.config !== this.props.config) {
            this.setState({
                vegaSpec: nextProps.config.vegaSpec,
                title: nextProps.config.title,
                titleAlign: nextProps.config.titleAlign,
                topoJson: nextProps.config.topoJson,
                isCurrentPluginAMap: nextProps.config.isCurrentPluginAMap,
                titleFont: nextProps.config.titleFont === undefined ? "Verdana" : nextProps.config.titleFont,
                titleFontSize: nextProps.config.titleFontSize === undefined ? 15 : nextProps.config.titleFontSize,
                titleFontStyle: nextProps.config.titleFontStyle === undefined ? false : nextProps.config.titleFontStyle,
                titleFontWeight: nextProps.config.titleFontWeight === undefined ? false : nextProps.config.titleFontWeight,
                titleTextDecor: nextProps.config.titleTextDecor === undefined ? false : nextProps.config.titleTextDecor,
                titleColour: nextProps.config.titleColour === undefined ? "black" : nextProps.config.titleColour,
                changedTitleFontSize: nextProps.config.changedTitleFontSize === undefined ? 15 : nextProps.config.changedTitleFontSize,
                noDataTitle: nextProps.config.noDataTitle === undefined ? "" : nextProps.config.noDataTitle,
            });
        }
    }

    //sets defaults we get from plugin.jsx. callback for fontsizekey because it should keep changing to trigger default revert
    setDefaultsForTitle = () => {
        let newConfig = this.props.setDefaultForPluginTitle();

        this.setState(
            {
                ...this.state,
                title: newConfig.title,
                titleAlign: newConfig.titleAlign,
                titleFont: newConfig.titleFont,
                titleFontSize: newConfig.titleFontSize,
                titleFontStyle: newConfig.titleFontStyle,
                titleFontWeight: newConfig.titleFontWeight,
                titleTextDecor: newConfig.titleTextDecor,
                titleColour: newConfig.titleColour,
                changedTitleFontSize: newConfig.titleFontSize
            }, () => {
                this.setConfigToPlugin({ type: "changedTitleFontSize", value: newConfig.titleFontSize })
            }
        );

        if (newConfig.titleFontSize > 10 || newConfig.titleFontSize < 30) {
            $("#titleFontSize").css("background", "")
        }
    }

    /*
  * Changes both of constant and changed value 
  * constant = between 10 and 30 values
  * changed value = all of values
  */
    handleChangeBothOfValues = (constantValue, changedValue) => {
        this.setState({
            ...this.state,
            titleFontSize: constantValue,
            changedTitleFontSize: changedValue
        })

        this.setConfigToPlugin(
            [
                {
                    type: "titleFontSize",
                    value: constantValue
                },
                {
                    type: "changedTitleFontSize",
                    value: changedValue
                }
            ]);
    }

    titleColourChangeVisible = () => {
        this.setState({
            ...this.state,
            titleColourChange: !this.state.titleColourChange
        });
    };

    componentDidMount() {
        $(`#${this.props.pluginId}-outer-box`)
            .css("display", "")
            .css("position", "")
            .css("box-sizing", "")
            .css("text-align", "initial")
            .css("font-size", "15px")

        let jsonEditorMenuContainer = $(".jsoneditor-menu")
        let jsonEditorContainer = $(".jsoneditor-mode-code")

        jsonEditorContainer.css("height", "400px")

        jsonEditorMenuContainer.children().each(function (index) {
            this.remove()
        });
    }

    setConfigToPlugin = (changedObj, propertyListToDelete = undefined) => {
        let tempState = { ...this.state };

        if (Array.isArray(changedObj)) {
            for (let i = 0; i < changedObj.length; i++) {
                let object = changedObj[i];
                tempState[object.type] = object.value;
            }
        } else {
            tempState[changedObj.type] = changedObj.value;
        }

        if (propertyListToDelete !== undefined) {
            for (let item of propertyListToDelete) {
                delete tempState[item]
            }
        }

        this.props.updateConfig(
            tempState,
            this.props.pluginId,
            propertyListToDelete
        );
    };

    changeVegaSpec = (value) => {
        if (value) {
            let jsonValue = JSON.stringify(value)
            this.setState({
                vegaSpec: jsonValue
            })

            this.setConfigToPlugin({ type: "vegaSpec", value: jsonValue });
        }
    }

    titleAlignOnChange = value => {
        this.setState({
            ...this.state,
            titleAlign: value
        });

        this.setConfigToPlugin({ type: "titleAlign", value: value });
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });

        this.setConfigToPlugin({ type: e.target.id, value: e.target.value });
    };

    handleCheck = e => {
        this.setState({
            ...this.state,
            isCurrentPluginAMap: e.target.checked
        })

        this.setConfigToPlugin({ type: e.target.id, value: e.target.checked });
    }

    topojsonOnChange = (val) => {
        this.setState({
            ...this.state,
            topoJson: val
        })

        this.setConfigToPlugin({ type: "topoJson", value: val });
    }

    render() {
        let vegaSpec = JSON.parse(this.state.vegaSpec)
        let mapPluginConfiguration = this.state.isCurrentPluginAMap ? <>
            <Col span={11}>
                <Select
                    showSearch
                    id={"topojson"}
                    style={{ width: "100%" }}
                    placeholder={i18n.t(
                        "Dashboard.Configuration.Fields.SelectATopojson"
                    )}
                    optionFilterProp="children"
                    onChange={val =>
                        this.topojsonOnChange(val)
                    }
                    value={this.state.topoJson}
                >
                    <Option name={"uk_nuts1"} value="uk/nuts1.json">
                        UK - NUTS 1
                    </Option>
                    <Option name={"us_states"} value="us/states.json">
                        {i18n.t("Dashboard.Settings.TopoJSON.UsStates")}
                    </Option>
                    <Option name={"us_states-mainland"} value="us/states-mainland.json">
                        {i18n.t("Dashboard.Settings.TopoJSON.UsStatesMainland")}
                    </Option>
                    <Option name={"us_countries"} value="us/counties.json">
                        {i18n.t("Dashboard.Settings.TopoJSON.UsCountries")}
                    </Option>
                    <Option name={"world"} value="world.json">
                        {i18n.t("Dashboard.Settings.TopoJSON.WorldCountries")}
                    </Option>
                    <Option name={"turkey_Iller"} value="turkey/Iller.topojson">
                        {i18n.t("Dashboard.Settings.TopoJSON.TurkeyProvinces")}
                    </Option>
                    <Option name={"turkey_Ilceler"} value="turkey/Ilceler.topojson">
                        {i18n.t("Dashboard.Settings.TopoJSON.TurkeyDistricts")}
                    </Option>
                    <Option name={"turkey_ankarailce"} value="turkey/ankarailce.json">
                        {i18n.t("Dashboard.Settings.TopoJSON.AnkaraDistricts")}
                    </Option>
                    <Option name={"turkey_ankarailcemahalle"} value="turkey/ankarailcemahalle.json">
                        {i18n.t("Dashboard.Settings.TopoJSON.AnkaraNeighborhood")}
                    </Option>
                    <Option name={"us_10m"} value="us10m.json">
                        {i18n.t("Dashboard.Settings.TopoJSON.US10M")}
                    </Option>
                    <Option name={"world_10m"} value="world-10m.json">
                        {i18n.t("Dashboard.Settings.TopoJSON.World10M")}
                    </Option>
                </Select>
            </Col></> : null
        return (
            <div style={{ color: "#505050" }}>
                <ReturnToTheme
                    reReturnThemeSettings={this.props.reReturnThemeSettings}
                    preservedConfigFieldsArray={this.props.plugin.preservedConfigFields ? Array.from(this.props.plugin.preservedConfigFields) : []}
                />

                <Divider className={"config-divider"} orientation="left">
                    {i18n.t("Dashboard.Configuration.Fields.ChangePluginType")}
                </Divider>
                <Row style={{ width: "665px" }}>
                    <Editor
                        value={vegaSpec}
                        onChange={this.changeVegaSpec}
                        ajv={ajv}
                        ace={ace}
                        mode={"code"}
                        defaultValue={this.state.topoJson}
                    />
                </Row>
                <TitleSettingsOfPlugin
                    plugin={this.props.plugin}
                    handleChange={this.handleChange}
                    commonTitleConfig={this.props.commonTitleConfig}
                    updateCommonTitleConfig={this.props.updateCommonTitleConfig}
                    isReturnToDefaultforTitleVisible={this.props.isReturnToDefaultforTitleVisible}
                    setDefaultsForTitle={this.setDefaultsForTitle}
                    title={this.state.title}
                    titleAlign={this.state.titleAlign}
                    titleColour={this.state.titleColour}
                    titleColourChange={this.state.titleColourChange}
                    titleColourChangeVisible={this.titleColourChangeVisible}
                    titleFont={this.state.titleFont}
                    titleFontSize={this.state.titleFontSize}
                    keyForTitleSize={this.state.keyForTitleSize}
                    titleFontStyle={this.state.titleFontStyle}
                    titleFontWeight={this.state.titleFontWeight}
                    titleTextDecor={this.state.titleTextDecor}
                    setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
                    currentAppliedConfig={this.props.currentAppliedConfig}
                    changedTitleFontSize={this.state.changedTitleFontSize}
                    handleChangeBothOfValues={this.handleChangeBothOfValues}
                />
                <Divider className={"config-divider"} orientation="left">
                    {i18n.t("Dashboard.Configuration.Fields.GeneralAppearance")}
                </Divider>
                <Row style={{ marginBottom: "5px" }}>
                    <Col style={{ textAlign: "center" }} span={3}>
                        <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                    </Col>
                    <Col style={{ textAlign: "left" }} span={9}>
                        <Text
                            style={{
                                fontSize: "16px",
                                display: "inline-block",
                                marginTop: "2px"
                            }}
                        >
                            {i18n.t("Dashboard.Configuration.Fields.CurrentPluginIsAMap")}
                        </Text>
                    </Col>
                    <Col span={1}>
                        <Checkbox
                            id={"isCurrentPluginAMap"}
                            defaultChecked={this.state.isCurrentPluginAMap}
                            onChange={this.handleCheck}
                            style={{
                                marginTop: "7px"
                            }} />
                    </Col>
                    {mapPluginConfiguration}
                </Row>
                <Divider className={"config-divider"} orientation="left">
                    {i18n.t("Dashboard.Configuration.Fields.Others")}
                </Divider>
                <Row style={{ marginBottom: "5px" }}>
                    <Col style={{ textAlign: "center" }} span={3}>
                        <FontSizeOutlined style={{ fontSize: "16px" }} />{" "}
                    </Col>
                    <Col style={{ textAlign: "left", display: "flex" }} span={9}>
                        <Text
                            style={{
                                fontSize: "16px",
                                display: "inline-block",
                                marginTop: "2px"
                            }}
                        >
                            {i18n.t("Dashboard.Settings.NoDataConfiguration")}
                        </Text>
                        <Tooltip
                            title={
                                <>
                                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Info")} <br />
                                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.GraphicConfiguration")} <br />
                                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.Settings")} <br />
                                    {i18n.t("Dashboard.Settings.NoDataConfigurationInfo.DefaultText")} <br />
                                </>
                            }
                        >
                            <InfoCircleOutlined style={{ position: 'relative', marginTop: '5px', marginLeft: "10px" }} />
                        </Tooltip>
                    </Col>
                    <Col span={12}>
                        <TextArea
                            allowClear
                            id={"noDataTitle"}
                            defaultValue={this.state.noDataTitle}
                            value={this.state.noDataTitle}
                            onChange={this.handleChange}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            placeholder={i18n.t("NoDataContent.NoData") + " " +
                                i18n.t("NoDataContent.PluginCouldNotBeVisualized")}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}