export const actions = [
    {
        trigger: "clickSlice",
        type: "click",
        name: "Click Slice",
        output: ["category"],
        description: "ActionClickDesc"
    },
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["category",
                    "measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        },
    },
    "sunburst": {
        columnMap: {
            level: {
                name: "Level",
                from: ["category"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        },
    },
};