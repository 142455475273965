import React, { Component } from "react";
import { dateFormat } from "../../Utils/Global";
import { BASE_URL_REPORTS, BASE_URL_UI } from "../../config";
import reportThumbnail from "../../images/report-thumbnail.png";
import GenerateReport from "../DashboardPage/GenerateReport/GenerateReport";
import i18n from "../../Utils/i18next";
import { Badge } from "antd";
import { isValidWriteRoles } from "../DashboardPage/RoleStore";


/**
 * Landing page search result list component
 */
export default class SearchedDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleModal: false
    };
  }

  /**
   * When VIEWER USER click to report on search area list. This function opens generate popup Modal
   * @param {*} status
   */
  toggleGenerateModal = (status) => {
    this.setState({
      visibleModal : status
    })
  }

  render() {
    let thumbnail = "/resource/api/resource/" + this.props.data.thumbnail;
    let path = BASE_URL_UI + "/dashboard/" + this.props.data.id;
    let isReport = this.props.data && this.props.data.type === "report";

    if (isReport) {
      thumbnail = reportThumbnail;
      path = BASE_URL_REPORTS + "/designer/" + this.props.data.id;
    }
    
    const details = (
      <div className="detail">
        <p id="resultTitle" style={{ fontWeight: "bold" }}>{this.props.data.title}</p>
        <p id="resultDate" class="creationDate">
          {dateFormat(this.props.data.creationDate, "/")}
        </p>
        <p id="resultDescription" style={{ display: "block", fontSize: "13px" }}>
          {this.props.data.description}
        </p>
        {this.props.data.labels && this.props.data.labels.length > 0 ? (
          <div id="resultLabels" className="labels">
            {this.props.data.labels.map((l) => (
              <Badge
                count={i18n.t(l.displayName)}
                style={{ backgroundColor: l.color, marginRight: "3px" }}
              />
            ))}
          </div>
        ) : null}
      </div>
    );

    return isReport && !isValidWriteRoles() ? (
      <div class="searchedDashboard" onClick={() => this.toggleGenerateModal(true)} >
        <img id="resultThumbnail" src={thumbnail} alt="" />
        {details}
        <div className="action" onClick={(e) => e.preventDefault()}
        >
          <GenerateReport
            visibleModal={this.state.visibleModal}
            toggleGenerateModal={this.toggleGenerateModal}
            reportId={this.props.data.id}
            innerClass="generate-report"
          >
            <div className="generate-button">
              <i className="fa fa-download"></i>
            </div>
          </GenerateReport>
        </div>
      </div>
    ) : (
      <a class="searchedDashboard" href={path} target="_blank" rel="noreferrer">
        <img id="resultThumbnail" src={thumbnail} alt="" />
        {details}
        {isReport ? (
          <div className="action" onClick={e => e.preventDefault()}>
            <GenerateReport reportId={this.props.data.id} innerClass="generate-report">
              <div className="generate-button">
                <i className="fa fa-download"></i>
              </div>
            </GenerateReport>
          </div>
        ) : null}
      </a>
    );
  }
}
