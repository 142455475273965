export const configuration = {
    size: 300
};

export const actions = [
    {
        trigger: "sectionClick",
        type: "click",
        name: "Tıklama - Bölüm",
        output: ["level"],
        description: "SectionClick2Desc"
    },
    {
        trigger: "sectionHover",
        type: "hover",
        name: "Hover - Bölüm",
        output: ["level"],
        description: "SectionHover3Desc"
    }
];

export const reactions = [
    {
        id: "filter",
        name: "Filtre",
        description: "desc87",
        type: "general"
    }
];

export const conversionMap = {
    "table": {
        columnMap: {
            columns: {
                name: "Columns",
                from: ["level", "measure"],
                multiple: true,
                minimumColumnSize: 1,
                required: true
            }
        },
    },
    "pie-chart": {
        columnMap: {
            category: {
                name: "Category",
                from: ["level"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            },
            measure: {
                name: "Measure",
                from: ["measure"],
                multiple: false,
                minimumColumnSize: 1,
                required: true
            }
        }
    }
};