import { Button, Modal } from "antd"
import React, { Component } from "react";
import JoinCreate from "../../Joining/JoinCreate/JoinCreate"
import i18n from "../../../Utils/i18next"
import { checkTableJoins } from "../../GeneralComponents/Join/Join"

export default class JoinPopup extends Component {
    constructor(props) {
        super(props);

        this.onModelChanged = this.onModelChanged.bind(this);

        this.state = {
            showAddJoinScreen: false, //for add join screen.
            prevFactAlias: "", //selected fact table value for createJoin
            prevDimensionAlias: "", //selected dimension table value createJoin
            selectedTables: [], //selected tables for create join 
            modelTableList: []
        }
    }

    componentDidUpdate() {
        if (this.props.columnMap) {
            let notJoinedDataTables = checkTableJoins(this.props.join, this.props.columnMap, this.props.refreshedPluginId, this.props.pluginId, true)
            let findValidJoin = true
            
            if (notJoinedDataTables.length === 2 && notJoinedDataTables[0]?.dataSourceKey?.split(".")[0] !== notJoinedDataTables[1]?.dataSourceKey?.split(".")[0]) {
                findValidJoin = false
            } 

            if (notJoinedDataTables.length === 0 && this.props.doesPluginHasNotJoinedTable === false) {
                this.props.changeDoesPluginHasNotJoinedTable(true)
            } else if (notJoinedDataTables.length !== 0 && this.props.doesPluginHasNotJoinedTable === true) {
                this.props.changeDoesPluginHasNotJoinedTable(false, findValidJoin)
            }

            if (notJoinedDataTables.length > 0 && this.props.clickedRefresh === true && this.props.visible !== true) {
                this.props.setJoinErrorAndClickedRefresh(true, false, findValidJoin)
            }
        }
    }

    prepareModalTablesForJoinPopup = () => {
        //checks has join and set tables
        let modalTables = this.props.model.tables
        let didNotJoinedTables = this.props.didNotJoinedTables
        let didNotJoinedTablesWithTables = []

        if (didNotJoinedTables.length != 0) {
            const didNotJoinedTablesMap = new Map();
            for (let i = 0; i < didNotJoinedTables.length; i++) {
                didNotJoinedTablesMap.set(didNotJoinedTables[i].tableAliasName, { aliasName: didNotJoinedTables[i].tableAliasName })
            }

            for (let i = 0; i < modalTables.length; i++) {
                if (didNotJoinedTablesMap.has(modalTables[i].aliasName)) {
                    didNotJoinedTablesWithTables.push(modalTables[i])
                }
            }
            return didNotJoinedTablesWithTables
        }
    }

    handleOk = () => {
        let table = this.prepareModalTablesForJoinPopup()

        this.setState({
            showAddJoinScreen: true,
            table: [...table]
        })
        this.props.onCancelModal()
    }

    onCancel = () => {
        this.setState({
            showAddJoinScreen: false
        })
    }

    onModelChanged(selectedTables, alias, isFact) {
        if (alias && isFact === true) {
            this.setState({ ...this.state, selectedTables, prevFactAlias: alias });
        } else if (alias && isFact === false) {
            this.setState({
                ...this.state,
                selectedTables,
                prevDimensionAlias: alias,
            });
        } else {
            this.setState({ ...this.state, selectedTables });
        }
    }

    setModalErrorMessage = data => {
        const isDataLengthTwo = data.length == 2 ? true : false
        const isDataLengthMoreThanThree = data.length >= 3 ? true : false

        if (!this.props.findValidJoin) {
            return (
                <div>
                    {i18n.t("Model.DifferentDatasourceError")}
                </div>
            )
        }

        return (
            <div>
                {isDataLengthTwo ?
                    <p>{i18n.t("Model.JoinErrorNotJoinTwoTable", { table1: `${data[0].tableDisplayName}`, table2: `${data[1].tableDisplayName}` })}</p>
                    : isDataLengthMoreThanThree
                        ? <div>
                            {i18n.t("Model.CantFoundJoinThisTables")}
                            <ul>
                                {data.map(d => (
                                    <li>{d.tableDisplayName}</li>
                                ))}
                            </ul>
                        </div>
                        : null}
            </div>
        )
    }

    render() {
        return (
            <>
            {this.props.findValidJoin ? 
            <Modal
                    title={i18n.t("Model.CantFoundJoin")}
                    visible={this.props.visible}
                    onCancel={this.props.onCancelModal}
                    onOk={this.handleOk}
                    okText={i18n.t("OK")}
                    cancelText={i18n.t("Cancel")}>
                    {this.setModalErrorMessage(this.props.didNotJoinedTables)}
                </Modal> : 
                <Modal
                    title={i18n.t("Model.DifferentDatasourceErrorTitle")}
                    visible={this.props.visible}
                    onCancel={this.props.onCancelModal}
                    cancelText={i18n.t("Cancel")}
                    footer={[
                        <Button key="back" onClick={this.props.onCancelModal}>
                          {i18n.t("Cancel")}
                        </Button>,
                      ]}>
                    {this.setModalErrorMessage(this.props.didNotJoinedTables)}
                </Modal>}


                {this.state.showAddJoinScreen == true ?
                    <JoinCreate
                        onCancel={this.onCancel}
                        visible={this.state.showAddJoinScreen}
                        tables={this.state.table}
                        onModelChanged={this.onModelChanged}
                        selectedModel={this.props.model}
                        prepareModalTablesForJoinPopup={this.prepareModalTablesForJoinPopup}
                        getJoinData={this.props.getJoinData}
                        comeFromPluginData={true}
                        operationType="create"
                        addJoinToJoinList={this.props.addJoinToJoinList}
                        onCancelModal={this.props.onCancelModal}
                        didNotJoinedTables={this.props.didNotJoinedTables}
                        changeJoinErrorVisibility={this.props.changeJoinErrorVisibility}
                        updateModelTablesForJoin={this.props.updateModelTablesForJoin}
                        getModelTables={this.props.getModelTables}
                    /> : null}
            </>
        )
    }
}