import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
import { vispeahen } from "../../RenderJs/vispeahen";
import debounce from 'lodash.debounce';
import "select2/dist/js/select2";
import "select2/dist/css/select2.css";
import "./filter.css";
import FilterConfiguration from "./FilterConfiguration/FilterConfiguration";
import FilterData from "./FilterData";
import i18n from "../../../../Utils/i18next";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  calculatePluginHeight
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import { renderConfig, renderData } from "../PluginsCommonComponents";
import { renderContent } from "../renderContent";
import { getFormattedValue } from "../format";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import { calculateTitleSize } from "../../../DrillDown/PluginHeightWithDrilldown"
import { store } from "../../../..";
import { deepCopy } from "../../../../Utils/Global";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";

const data = JSON.parse(
  `[{"filter":"Adana"},{"filter":"Adıyaman"},{"filter":"Amasya Merzifon"},{"filter":"Ankara Esenboğa"},{"filter":"Antalya"},{"filter":"Aydın Çıldır"},{"filter":"Ağrı Ahmed-i Hani"},{"filter":"Balıkesir Koca Seyit"},{"filter":"Balıkesir Merkez"},{"filter":"Batman"},{"filter":"Bingöl"},{"filter":"Bursa Yenişehir"},{"filter":"Denizli Çardak"},{"filter":"Diyarbakır"},{"filter":"Elazığ"},{"filter":"Erzincan"},{"filter":"Erzurum"},{"filter":"Eskişehir Hasan Polatkan"},{"filter":"Gaziantep"},{"filter":"Gazipaşa Alanya"},{"filter":"Hakkari Yüksekova Selahaddin Eyyubi"},{"filter":"Hatay"},{"filter":"Isparta Süleyman Demirel"},{"filter":"Iğdır Şehit Bülent Aydın"},{"filter":"Kahramanmaraş"},{"filter":"Kapadokya"},{"filter":"Kars Harakani"},{"filter":"Kastamonu"},{"filter":"Kayseri"},{"filter":"Kocaeli Cengiz Topel"},{"filter":"Konya"},{"filter":"Malatya"},{"filter":"Mardin"},{"filter":"Muğla Dalaman"},{"filter":"Muğla Milas-Bodrum"},{"filter":"Muş"},{"filter":"Ordu-Giresun"},{"filter":"Samsun Çarşamba"},{"filter":"Siirt"},{"filter":"Sinop"},{"filter":"Sivas Nuri Demirağ"},{"filter":"Tekirdağ Çorlu"},{"filter":"Tokat"},{"filter":"Trabzon"},{"filter":"Uşak"},{"filter":"Van Ferit Melen"},{"filter":"Zafer"},{"filter":"Zonguldak Çaycuma"},{"filter":"Çanakkale"},{"filter":"Çanakkale Gökçeada"},{"filter":"İstanbul Atatürk"},{"filter":"İstanbul Sabiha Gökçen"},{"filter":"İzmir Adnan Menderes"},{"filter":"Şanlıurfa GAP"},{"filter":"Şırnak Şerafettin Elçi"}]`
);
const config = JSON.parse(
  `{"width":400,"title":"","backgroundColor":"rgb(255,255,255)","arrowColor":"rgb(255, 255, 255)","textBackgroundColor":"rgb(84, 132, 241)","isMulti":false,"placeholder":"","refresh":0,"placeholderColour":"#000000"}`
);
const columnMap = JSON.parse(
  `{"filter":{"Code":"\'ucusAlias\'.\'meydan_adiAlias\'","Name":"meydan_adi","DataType":"varchar","Table":"ucusAlias","Measure":"none","ID":"ucusAlias.meydan_adi","SubjectArea":"BIGDATA","SortKey":false,"Sorting":false,"SortDirection":"","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":false,"Type":"Column","Description":""}}`
);
const condFormats = [];
const filters = [];
const pluginName = "filter";

const description =
  "Line chart visualisation for continuous data, can be used as an area chart by setting the appropriate configuration setting. Secondary Y axes can be used when there are two measures to scale them appropriately regarding their values. When a date column is used as the category property the dataset will be modified to be continuous. This means that if OBIEE is missing records for certain days, they will be assigned a value of 0.";

const columnMappingParameters = [
  {
    targetProperty: "filter",
    formLabel: "FilterArea",
    type: "dim",
    required: true,
    desc: "FilterArea"
  }
];

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "operatorsForStringFilter",
    label: "operatorsForStringFilter",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "="
    },
    desc: "operatorsForStringFilter"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "showOperatorOptions",
    label: "showOperatorOptions",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "showOperatorOptions"
  },
  {
    targetProperty: "showDefaultFilterIcon",
    label: "showDefaultFilterIcon",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "showDefaultFilterIcon"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc89"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "desc94"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgb(255,255,255)"
    },
    desc: "desc62"
  },
  // {
  //     "targetProperty": "textBackgroundColor",
  //     "label": "Text Arkaplan Rengi",
  //     "inputType": "dropdown",
  //     "inputOptions": {
  //         "multiSelect": false,
  //         "values": ["red", "green", "blue" ] // static array

  //         ,"defaultSelection": 2 // green
  //     }
  // },
  {
    targetProperty: "arrowColor",
    label: "ArrowColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "rgba(0, 0, 0, 0.65)"
    },
    desc: "ArrowColor"
  },
  {
    targetProperty: "textBackgroundColor",
    label: "TextBackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: "#fff"
    },
    desc: "textBackgroundColor"
  },
  {
    //Multiselect topojson control
    targetProperty: "isMulti",
    label: "IsMulti",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "IsMulti"
  },
  {
    targetProperty: "placeholder",
    label: "PlaceholderFilter",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: {
      defaultValue: ""
    },
    desc: "PlaceholderFilter"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  },
  {
    targetProperty: "placeholderColour",
    label: "PlaceHolderColour",
    inputType: "colour",
    inputOptions: {
      defaultValue: "rgba(0, 0, 0, 0.65)"
    },
    desc: "desc167"
  },
];

const actions = [
  {
    trigger: "select",
    type: "click",
    name: "select",
    output: ["filter"],
    description: ""
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc78",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const syncFilter = function (output, container) {
  const col = output[0].col.Name;
  let sets;
  let set = _.get(sets, col);
  const values = output[0].values;

  set = set ? new Set([...values, ...set]) : new Set(values);
  _.set(sets, col, set);

  $(container)
    .find(".smc")
    .val(null)
    .trigger("change");
  $(container)
    .find(".smc")
    .val(Array.from(set))
    .trigger("change");
};

/**
 * Creates and return map for configuration
 */
const createConfiguraitonMap = () => {
  let configurationMap = new Map();

  configurationMap.set("width", {});
  configurationMap.set("title", {});
  configurationMap.set("titleAlign", {});
  configurationMap.set("backgroundColor", {});
  configurationMap.set("textBackgroundColor", {});
  configurationMap.set("placeholder", {});
  configurationMap.set("placeholderColour", {});
  configurationMap.set("titleFontWeight", {});
  configurationMap.set("titleFontStyle", {});
  configurationMap.set("arrowColor", {});
  configurationMap.set("isMulti", {});
  configurationMap.set("refresh", {});
  configurationMap.set("titleFont", {})
  configurationMap.set("titleFont", {})
  configurationMap.set("titleFontSize", {})
  configurationMap.set("titleColour", {})
  configurationMap.set("titleTextDecor", {})
  configurationMap.set("useTextBox", {})
  configurationMap.set("operatorsForStringFilter", { defaultValue: "=" })
  configurationMap.set("showOperatorOptions", { defaultValue: true });
  configurationMap.set("showDefaultFilterIcon", { defaultValue: true });

  return configurationMap;
};

/**
 * Creates and return map for configuration categories
 */
const createConfigurationCategoryMap = () => {
  let categoryMap = new Map();

  categoryMap.set("generalAppearance", {});
  categoryMap.set("others", {});

  return categoryMap;
}

const configurationMap = createConfiguraitonMap();
const configurationCategoryMap = createConfigurationCategoryMap();

/**
 * renders Filter plugin in Vispeahen V3
 */
export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterValue: "",
    }

    this.rerenderProcessStarted = false;
    this.callBackObject = {};
    this.onChangeDebounced = debounce(this.onChangeDebounced, 700)
  }

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    tempPlugin.syncFilter = syncFilter;

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );

    let reduxState = store.getState()

    if (!_.isEqual(nextProps.plugin.config, this.props.plugin.config)) {
      if (reduxState.PluginTriggerReducer.sourcePluginsWithValues.has(nextProps.plugin.id)) {
        reduxState.PluginTriggerReducer.sourcePluginsWithValues.delete(nextProps.plugin.id)
      }
    }

    let isConfigAvailableAndOperatorChanged = this.props.plugin.config && this.props.plugin.config.operatorsForStringFilter !== nextProps.plugin.config.operatorsForStringFilter ? true : false
    let isConfigAvailableAndMultipleStatusChanged = this.props.plugin.config && this.props.plugin.config.isMulti !== nextProps.plugin.config.isMulti

    if (isConfigAvailableAndOperatorChanged) {
      let reduxState = store.getState()

      this.controlledGettedValue.filterPredicate = nextProps.plugin.config.operatorsForStringFilter
      this.controlledGettedValue.filter = ""

      reduxState.PluginTriggerReducer.sourcePluginsWithValues.set(this.props.plugin.id, [])
    }

    if (isConfigAvailableAndMultipleStatusChanged && this.props.plugin.columnMapForPlugin) {
      let reduxState = store.getState()

      reduxState.PluginTriggerReducer.sourcePluginsWithValues.set(this.props.plugin.id, [])

      createTrigger(
        actions,
        this.props.plugin.columnMapForPlugin,
        $("#" + this.props.plugin.id),
        "select",
        [],
        this.props.plugin.id,
        this.props.interactions,
        this.props.navigations,
        { x: 0, y: 0 },
        $("#select-" + this.props.plugin.id).val() ? $("#select-" + this.props.plugin.id).val() : "="
      );
    }
  }

  getConfigComponent = props => {
    if (props.config) {
      return (
        <FilterConfiguration
          config={{ ...props.config }}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          configurationTemplate={configurationMap}
          configurationCategoryTemplate={configurationCategoryMap}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <FilterData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      filter: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Filter.Name"),
        type: "dim",
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Filter.Desc"),
        required: true,
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  preparePlaceholder = (conf) => {
    return `<span class="select2-selection__placeholder">${conf}</span>`
  }

  prepareHtmlArrow = (conf) => {
    return `<span class="select2-selection__arrow" role="presentation"><b role="presentation" style="border-color: ${conf} transparent transparent;"></b></span>`;
  }

  /*
  * When count timer ends, this function triggers
  */
  setTargetValue = (event) => {
    if (event.target.value.length > 0) {
      $("#" + this.props.plugin.id).find(".number-input-placeholder").css("display", "none")
    } else {
      $("#" + this.props.plugin.id).find(".number-input-placeholder").css("display", "inline-block")
    }

    this.onChangeDebounced(event)
  }

  /*
  * Triggers when apply button clicked
  */
  onApplyButtonClick = (config) => {
    let val = $("#filter-input-" + this.props.plugin.id).val()

    createTrigger(
      actions,
      this.props.plugin.columnMapForPlugin,
      $("#" + this.props.plugin.id),
      "select",
      [{ filter: val, filterPredicate: $("#select-" + this.props.plugin.id).val() ? $("#select-" + this.props.plugin.id).val() : "=" } ],
      this.props.plugin.id,
      this.props.interactions,
      this.props.navigations,
      { x: 0, y: 0 },
      $("#select-" + this.props.plugin.id).val() ? $("#select-" + this.props.plugin.id).val() : "="
    );
  }

  /*
  * When trigger finish end, this will triggers
  */
  onChangeDebounced = (event) => {
    createTrigger(
      actions,
      this.props.plugin.columnMapForPlugin,
      $("#" + this.props.plugin.id),
      "select",
      [{ filter: event.target.value, filterPredicate: $("#select-" + this.props.plugin.id).val() ? $("#select-" + this.props.plugin.id).val() : "=" }],
      this.props.plugin.id,
      this.props.interactions,
      this.props.navigations,
      { x: 0, y: 0 },
      $("#select-" + this.props.plugin.id).val() ? $("#select-" + this.props.plugin.id).val() : "="
    );
  }

  controlledGettedValue

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    var THIS = this;
    let reduxState = store.getState()
    let sourcePluginsWithValues = reduxState.PluginTriggerReducer.sourcePluginsWithValues;
    let gettedValues = sourcePluginsWithValues.has(this.props.plugin.id) ? sourcePluginsWithValues.get(this.props.plugin.id) : []
    this.controlledGettedValue = gettedValues.length > 0
      ? gettedValues[0]
      : {
        filter: "",
        filterPredicate: $("#select-" + divId).val() && $("#select-" + divId).val() !== "" && $("#select-" + divId).val() !== null
          ? $("#select-" + divId).val()
          : config.operatorsForStringFilter != undefined
            ? config.operatorsForStringFilter
            : "="
      }

    if (config.showOperatorOptions == undefined) {
      config.showOperatorOptions = true;
    }

    var isBeforePredicateIsEqual = $("#inline-" + divId).find("select").length > 1 ? true : false

    $("#" + divId).html("");
    $("#" + divId).append(`<div id='inline-${divId}'></div>`)

    let container = $("#inline-" + divId)[0];

    $(container).css("width", "100%").css("display", "flex")

    $(container).append(`
    <select id="select-${divId}" class="filter-select">
        <option value="=">${i18n.t("FilterOperators.=")}</option>
        <option value="!=">${i18n.t("FilterOperators.!=")}</option>
        <option value="CONTAINS">${i18n.t("FilterOperators.CONTAINS")}</option>
        <option value="ENDS_WITH">${i18n.t("FilterOperators.ENDS_WITH")}</option>
        <option value="STARTS_WITH">${i18n.t("FilterOperators.STARTS_WITH")}</option>
    </select>`)

    $("#select-" + divId).select2()
    $(container).children()[1].attributes.style.nodeValue = "width: 25%"

    let operator = this.controlledGettedValue.filterPredicate === "in"
      ? "="
      : this.controlledGettedValue.filterPredicate === "not in"
        ? "!="
        : this.controlledGettedValue.filterPredicate

    $("#select-" + divId).val(operator); // Select the option with a value of 'US'
    $("#select-" + divId).trigger('change'); // Notify any JS components that the value changed

    let $eventSelect = $("#select-" + divId);

    $eventSelect.select2();
    $(container).children()[1].attributes.style.nodeValue = "width: 25%"

    $("#select2-select-" + divId + "-container").css("color", config.placeholderColour)
    // To reach main parent of operator div and give it a display
    $("#select2-select-" + divId + "-container").parent().parent().parent().css("display",config.showOperatorOptions ? "" : "none")
    $($(container).find(".select2-container--default .select2-selection--single .select2-selection__rendered")[0]).parent().css("line-height", "20px").css("background", config.textBackgroundColor)
    $($(container).find(".select2-selection__rendered")[0]).parent().css("background", config.textBackgroundColor)

    $("#select-" + divId).css("height", config.isMulti ? "32px!important" : "28px!important")

    $eventSelect.on("select2:opening", function (e) {
      if ($(container).find(".select2-selection__arrow b").children().length > 0) {
        $(container).find(".select2-selection__arrow b").children().css("border-color", `${config.arrowColor} transparent`);
      }

      $(container).find(".select2-selection__arrow b").css("border-color", `${config.arrowColor} transparent`);
    })

    $eventSelect.on("select2:select", function (e) {
      if ($(container).find(".select2-selection__arrow b").children().length > 0) {
        $(container).find(".select2-selection__arrow b").children().css("border-color", `${config.arrowColor} transparent`);
      }

      $(container).find(".select2-selection__arrow b").css("border-color", `${config.arrowColor} transparent`);

      let input = $("#filter-input-" + divId)

      let reduxState = store.getState()
      let sourcePluginsWithValues = reduxState.PluginTriggerReducer.sourcePluginsWithValues;
      let gettedValues = sourcePluginsWithValues.has(THIS.props.plugin.id) ? sourcePluginsWithValues.get(THIS.props.plugin.id) : []
      let controlledGettedValue = gettedValues.length > 0 ? gettedValues[0] : {filter: "", filterPredicate: "="}
      let isInputAvailableAndFilterValueIsEqualOrNotEqual = (input
        && input.val()
        && input.val() !== ""
        && e.params.data.id !== "="
        && controlledGettedValue.filterPredicate !== "="
        && e.params.data.id !== "!="
        && controlledGettedValue.filterPredicate !== "!=") || config.useTextBox
      let isFilterPredicateEqualOrNotEqual = controlledGettedValue.filterPredicate === "="
        || controlledGettedValue.filterPredicate === "!="
        || controlledGettedValue.filterPredicate === "in"
        || controlledGettedValue.filterPredicate === "not in"
      let isNewFilterPredicateIncludesETC = e.params.data.id === "CONTAINS" || e.params.data.id === "ENDS_WITH" || e.params.data.id === "STARTS_WITH"

      let newConfig = deepCopy(THIS.props.config)

      newConfig["operatorFlag"] = !newConfig["operatorFlag"]

      THIS.controlledGettedValue.filterPredicate = e.params.data.id
      newConfig.filter = THIS.controlledGettedValue.filter

      if(isFilterPredicateEqualOrNotEqual && controlledGettedValue.filter === ""){
        THIS.props.updateConfig(newConfig)
      }

      if (gettedValues && gettedValues.length > 0) {
        if (isInputAvailableAndFilterValueIsEqualOrNotEqual) {
          createTrigger(
            actions,
            THIS.props.plugin.columnMapForPlugin,
            $("#" + THIS.props.plugin.id),
            "select",
            [{ filter: input.val(), filterPredicate: e.params.data.id }],
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            { x: 0, y: 0 },
            e.params.data.id
          );
        } else if (isFilterPredicateEqualOrNotEqual && !isNewFilterPredicateIncludesETC) {
          let valueArr = [...gettedValues]

          for (let i = 0; i < valueArr.length; i++) {
            valueArr[i]["filterPredicate"] = e.params.data.id
          }

          let operator = valueArr.length > 1
            ? e.params.data.id === "="
              ? "in"
              : "not in"
            : e.params.data.id

          valueArr = valueArr.filter(val => val.filter != "")

          if (valueArr.length > 0) {
            createTrigger(
              actions,
              THIS.props.plugin.columnMapForPlugin,
              $("#" + THIS.props.plugin.id),
              "select",
              valueArr,
              THIS.props.plugin.id,
              THIS.props.interactions,
              THIS.props.navigations,
              { x: 0, y: 0 },
              operator
            );
          }
        } else if (controlledGettedValue.filter !== "") {
          createTrigger(
            actions,
            THIS.props.plugin.columnMapForPlugin,
            $("#" + THIS.props.plugin.id),
            "select",
            [{ filter: "", filterPredicate: e.params.data.id }],
            THIS.props.plugin.id,
            THIS.props.interactions,
            THIS.props.navigations,
            { x: 0, y: 0 },
            e.params.data.id
          );

          THIS.props.updateConfig(newConfig)
        }
      }
    });

    $("#" + divId).css("top", "50%")
      .css("position", "absolute")
      .css("width", "100%")
      
    let isFilterPredicateEqualOrNotEqual = THIS.controlledGettedValue.filterPredicate === "="
      || THIS.controlledGettedValue.filterPredicate === "!="
      || THIS.controlledGettedValue.filterPredicate === "in"
      || THIS.controlledGettedValue.filterPredicate === "not in"
      || THIS.controlledGettedValue.filterPredicate == undefined

    let isConfigUseTextbox = config.useTextBox === true ? true : false

    if (isFilterPredicateEqualOrNotEqual && !isConfigUseTextbox) {
      calculateTitleSize(this.props.plugin)

      $(container).find("#filter-input-" + divId).val(THIS.controlledGettedValue.filter)

      $("#" + divId).css("display", "flex")

      let plugin = $("#plugin-" + divId)
      let title = $("#title-" + divId).length > 0 ? $("#title-" + divId) : false
      let holder = $("[index=" + divId + "]") //main div
      let titleHeight = title ? title[0].scrollHeight : 30
      let pluginInlineHeight = holder.height() - titleHeight - 2 //We calculated plugin size without title and bottom margin, so we can calculate plugin's inline height, this calculation fixes height bug.
      let widthPlaceholder = $(container).css("width")

      widthPlaceholder = (widthPlaceholder.substring(0, widthPlaceholder.indexOf("p"))) - 20 + "px"

      let placeholderSpanForMultipleSelect = `<span class="placeholder" style="color: ${config.placeholderColour}; width: 96%; position:relative; left: -10px; top: 2px; display: inline-block;">${config.placeholder || ""}</span>`
      let placeholderSpanForSingleSelect = `<span class="placeholder" style="color: ${config.placeholderColour}; position: relative; left: 5px; top: 0px; display: inline-block;  width: 89%;">${config.placeholder || ""}</span>`

      plugin.parent().parent().css("min-height", "50px");
      plugin.css("height", pluginInlineHeight);

      let filterName = columnMap.filter.Name;
      let sets = {};
      sortItems(filterName, data);
      let html;

      /* replaces special characters including space character with '_' in the data column name */
      let dataColNameWithoutSpecialCharacters = columnMap.filter.Name.replace(/[^\w]/gi, '_');
      let id = `select-${dataColNameWithoutSpecialCharacters}-${divId}`;

      // Get width and height variables based on gridScope
      let visWidth = "100%";

      //Single choice request or multi choise request
      if (config.isMulti) {
        if(config.showOperatorOptions){
          html = `<div style="width: 75%"><select id="${id}" name="${id}" class='smc js-example-basic-multiple' style='width:${visWidth}; background-color: white' multiple='multiple'><option value=''></option>`;
        } else {
          html = `<div style="width: 100%; margin:auto"><select id="${id}" name="${id}" class='smc js-example-basic-multiple' style='width:${visWidth}; background-color: white' multiple='multiple'><option value=''></option>`;
        }
      } else {
        if (config.showOperatorOptions) {
          html = `<div style="width: 75%"><select id="${id}" name="${id}" class='smc js-example-basic-single' style='width:${visWidth}; background-color: white'><option value=''></option>`;
        } else {
          html = `<div style="width: 100%; margin:auto"><select id="${id}" name="${id}" class='smc js-example-basic-single' style='width:${visWidth}; background-color: white'><option value=''></option>`;
        }
      }

      data.forEach(d => {
        let value = getFormattedValue(columnMap.filter, d.filter);

        if (value == null || value == "") {
          value = d.filter;
        }

        html += `<option value="${d.filter}" style="color:${config.textBackgroundColor}">${value}</option>`;
      });
      html += "</select></div>";

      $(container).append(html);
      $(container)
        .find(".smc")
        .select2({
          // templateSelection: formatState,
          placeholder: "",
          allowClear: true
          // , templateResult: formatState
        });
      if (config.isMulti) {
        let arrowHtml = this.prepareHtmlArrow(config.arrowColor)
        let placeholderHtml = this.preparePlaceholder(config.placeholder)
        $(container)
          .find(
            ".select2-container--default .select2-selection--multiple .select2-selection__rendered"
          )
          .append(arrowHtml);
        $($(container).find(".select2-selection__rendered")[1]).append(placeholderHtml);
      }

      function sortItems(type, data) {
        let customOrder = [];
        if (
          $.inArray(type.toLocaleUpperCase(), [
            "GUN",
            "GÜN",
            "GUNLER",
            "GÜNLER"
          ]) != -1
        )
          customOrder = ["PAZARTESİ", "SALI", "ÇARŞAMBA", "PERŞEMBE", "CUMA"];
        else if (
          $.inArray(type.toLocaleUpperCase(), [
            "AY",
            "AYLAR",
            "AYADI",
            "AYADİ",
            "AY_ADI",
            "AY_ADİ"
          ]) != -1
        )
          customOrder = [
            "OCAK",
            "ŞUBAT",
            "MART",
            "NİSAN",
            "MAYIS",
            "HAZİRAN",
            "TEMMUZ",
            "AĞUSTOS",
            "EYLÜL",
            "EKİM",
            "KASIM",
            "ARALIK"
          ];
        else if (
          $.inArray(type.toLocaleUpperCase(), [
            "İL_ADI",
            "İL_ADİ",
            "IL_ADI",
            "İLADI",
            "İLADİ",
            "ILADI",
            "İL",
            "IL",
            "İL_KODU",
            "IL_KODU",
            "İLKODU",
            "ILKODU",
            "İLLER",
            "ILLER"
          ]) != -1
        ) {
          customOrder = [
            "ADANA",
            "ADIYAMAN",
            "AFYONKARAHİSAR",
            "AFYON",
            "AĞRI",
            "AKSARAY",
            "AMASYA",
            "ANKARA",
            "ANTALYA",
            "ARDAHAN",
            "ARTVİN",
            "AYDIN",
            "BALIKESİR",
            "BARTIN",
            "BATMAN",
            "BAYBURT",
            "BİLECİK",
            "BİNGÖL",
            "BİTLİS",
            "BOLU",
            "BURDUR",
            "BURSA",
            "ÇANAKKALE",
            "ÇANKIRI",
            "ÇORUM",
            "DENİZLİ",
            "DİYARBAKIR",
            "DÜZCE",
            "EDİRNE",
            "ELAZIĞ",
            "ERZİNCAN",
            "ERZURUM",
            "ESKİŞEHİR",
            "GAZİANTEP",
            "GİRESUN",
            "GÜMÜŞHANE",
            "HAKKARİ",
            "HATAY",
            "IĞDIR",
            "ISPARTA",
            "İSTANBUL",
            "İZMİR",
            "KAHRAMAN MARAŞ",
            "KAHRAMANMARAŞ",
            "KARABÜK",
            "KARAMAN",
            "KARS",
            "KASTAMONU",
            "KAYSERİ",
            "KIRIKKALE",
            "KIRKLARELİ",
            "KIRŞEHİR",
            "KİLİS",
            "KOCAELİ",
            "KONYA",
            "KÜTAHYA",
            "MALATYA",
            "MANİSA",
            "MARDİN",
            "MERSİN",
            "MUĞLA",
            "MUŞ",
            "NEVŞEHİR",
            "NİĞDE",
            "ORDU",
            "OSMANİYE",
            "RİZE",
            "SAKARYA",
            "SAMSUN",
            "SİİRT",
            "SİNOP",
            "SİVAS",
            "ŞANLIURFA",
            "ŞIRNAK",
            "TEKİRDAĞ",
            "TOKAT",
            "TRABZON",
            "TUNCELİ",
            "UŞAK",
            "VAN",
            "YALOVA",
            "YOZGAT",
            "ZONGULDAK"
          ];
        }

        if (customOrder.length > 0) {
          data.sort(function (a, b) {
            if (a.filter && b.filter && isNaN(a.filter) && isNaN(b.filter)) {
              let aU = a.filter.toLocaleUpperCase("tr-TR");
              let bU = b.filter.toLocaleUpperCase("tr-TR");
              let first = customOrder.indexOf(aU);
              let second = customOrder.indexOf(bU);
              return first - second;
            }
          });
        } else {
          data.sort(function (a, b) {
            if (a.filter && b.filter && isNaN(a.filter) && isNaN(b.filter)) {
              let aU = a.filter.toLocaleUpperCase("tr-TR");
              let bU = b.filter.toLocaleUpperCase("tr-TR");
              return aU.localeCompare(bU);
            }
          });
        }
      }

      //Single choice request or multi choise request
      if (config.isMulti) {
        $(container).find(".select2-container--default .select2-selection--multiple .select2-selection__placeholder").css("color", config.placeholderColour);
        $(container).find(".select2-selection__arrow b")
          .css(
            "border-color",
            config.arrowColor + " transparent transparent transparent"
          );
        $(container).find(".select2-container--default .select2-selection--multiple ")
          .css("background-color", config.textBackgroundColor);
        $(container).find(".select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__clear")
          .css("background-color", config.arrowColor);
        $(container).find(".select2-selection__clear")
          .css("color", config.arrowColor);
        $(container).find(".select2-search--inline").css("float", "unset");
      } else {
        $(container).find(".select2-container--default .select2-selection--single .select2-selection__placeholder")
          .css("color", config.placeholderColour);
        $(container).find(".select2-container--default .select2-selection--single .select2-selection__arrow b")
          .css(
            "border-color",
            config.arrowColor + " transparent transparent transparent"
          );
        $(container).find(".select2-container--default .select2-selection--single ")
          .css("background-color", config.textBackgroundColor);
        $(container).find(
          ".select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__clear"
        )
          .css("background-color", config.arrowColor);
        $(container).find(".select2-selection__clear")
          .css("color", config.arrowColor);
      }

      //Text arkaplanının renklenmesi için aç -- Ayrıca select2.min.css den select2-result__option içindekileri de aç
      // $(container).find(".select2-container--default .select2-selection--multiple ")
      //     .click(() => {
      //         // if ( $("html").find(".select2-dropdown--below").css("background-color") == "rgb(255, 255, 255)" )
      //             $("html").find(".select2-dropdown--below").css("background-color", config.textBackgroundColor);
      //             $("html").find(".select2-dropdown--above").css("background-color", config.textBackgroundColor);
      //             // $("html").find(".select2-results__option").css("color","white");
      //             // $("html").find(".select2-results__option").css("mix-blend-mode","difference");
      //     });

      /* adds search field to dropdown and removes inline search field in the multiple filter */
      function multipleAddDropdownSearch() {
        /* remove inline search */
        $(container).find(".select2-search--inline").remove();

        /* convert filter field (<ul> element) to <span> element like in single search field */
        const ulSelectionRendered = '<span class="select2-selection__rendered" style="color: rgb(0, 0, 0);"><li class="select2-search select2-search--inline"><input class="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" placeholder="" style="width: 0.75em;"></li><span class="select2-selection__arrow" role="presentation"><b role="presentation" style="border-color: rgb(255, 255, 255) transparent transparent;"></b></span></span>';
        $($(container).find(".select2-selection__rendered")[1]).remove();
        const spanSelectionRendered = $.parseHTML(ulSelectionRendered.replace("ul", "span"));
        $(container).find(".select2-selection--multiple").prepend(spanSelectionRendered);
        $(container).find(".select2-selection--multiple").css("cursor", "pointer");
        $($(container).find(".select2-search")[0]).append(placeholderSpanForMultipleSelect)

        let widthPlaceholderMulti = $($(container)
          .find(".select2-selection__rendered")[1])
          .css("width");
        widthPlaceholderMulti =
          widthPlaceholderMulti.substring(0, widthPlaceholderMulti.indexOf("p")) -
          20 +
          "px";

        if (config.isMulti) {
          $(container).find(".placeholder").css({
            width: widthPlaceholderMulti,
          });
        }

        $(container).find(".select2-selection__arrow").children().css("border-color", `${config.arrowColor} transparent transparent`)
        /* add dropdown search to dropdown of multiple selection */
        $(container)
          .find(".select2-selection--multiple")
          .on("click", e => {
            if ($(".select2-search--dropdown").length === 0) {
              let dropdownSearch = '<span class="select2-search select2-search--dropdown"><input class="select2-search__field display-true" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" aria-controls="select2-select-meydan_adi-15aee14-2d4-48f1-ecf6-f16d62d0bc-results" aria-activedescendant="select2-select-meydan_adi-15aee14-2d4-48f1-ecf6-f16d62d0bc-result-e4iu-Antalya"></span>';
              $(".select2-dropdown").prepend(dropdownSearch);
              $(".select2-search__field").on("input", function () {
                let inputValue = this.value.toLowerCase();

                for (let i = 0; i < $(".select2-results__option").length; i++) {
                  let dataValue = $(".select2-results__option").eq(i).text().toLowerCase();

                  if (dataValue.includes(inputValue)) {
                    $(".select2-results__option").eq(i).css("display", "block");
                  } else {
                    $(".select2-results__option").eq(i).css("display", "none");
                  }
                }
              });
            } else {
              $(".select2-search--dropdown").find(".select2-search__field")[0].value = null;
            }
          });
      }

      if (config.isMulti) {
        let arrowHtml = this.prepareHtmlArrow(config.arrowColor);
        multipleAddDropdownSearch();

        $(container)
          .find(".smc")
          .on("select2:select", e => {
            $(container)
              .find(".select2-selection__clear")
              .css("color", config.arrowColor);
            $(container)
              .find(
                ".select2-container--default .select2-selection--multiple .select2-selection__rendered"
              )
              .append(arrowHtml);
            $(container)
              .find(
                ".select2-container--default .select2-selection--multiple .select2-selection__rendered"
              )
              .css("color", config.placeholderColour);
            $(container)
              .find(
                ".select2-container--default .select2-selection--multiple .select2-selection__choice__remove"
              )
              .css("color", config.placeholderColour);
            $(container)
              .find(
                ".select2-container--default .select2-selection--multiple .select2-selection__choice"
              )
              .css("color", config.placeholderColour);
            if (!_.has(sets, columnMap.filter.Name)) {
              _.set(sets, columnMap.filter.Name, new Set());
            }

            if (vispeahen.filterGlobalArrayFlag == true) {
              let set = _.get(sets, columnMap.filter.Name);
              for (let m = 0; m < vispeahen.filterGlobalArray.length; m++) {
                set.add(
                  data.find(
                    d => d.filter == vispeahen.filterGlobalArray[m].filter
                  )
                );
              }
              vispeahen.filterGlobalArrayFlag = false;
            }

            let selectedValParentId = $($(container).find(":selected")[1]).parent().attr("id");
            let selectedValues = $(container).find(`[id='${selectedValParentId}']`).find(":selected")
            let set = _.get(sets, columnMap.filter.Name);

            set.add(data.find(d => d.filter == e.params.data.id));

            for (let i = 0; i < selectedValues.length; i++) {
              let selectedValue = $(selectedValues[i]).attr("value")

              if (selectedValue !== "") {
                set.add(data.find(d => d.filter == selectedValue));
              }
            }

            let array = set.size > 0 ? Array.from(set) : [];

            columnMap.filter.remove = false;

            let operator = $("#select-" + divId).val() && $("#select-" + divId).val() !== "" && $("#select-" + divId).val() !== null
              ? $("#select-" + divId).val()
              : config.operatorsForStringFilter != undefined
                ? config.operatorsForStringFilter
                : "="

            if (array.length > 1 && operator == "=") {
              operator = "in"
            } else if (array.length > 1 && operator == "!=") {
              operator = "not in"
            }

            createTrigger(
              actions,
              columnMap,
              container,
              "select",
              array,
              THIS.props.plugin.id,
              THIS.props.interactions,
              THIS.props.navigations,
              { x: 0, y: 0 },
              operator
            );
          });

        $(container).find(".select2 select2-container select2-container--default").css("width", "70%")

        $(container)
          .find(".smc")
          .on("select2:unselect", e => {
            $(container)
              .find(".select2-selection__clear")
              .css("color", config.arrowColor);
            $(container)
              .find(
                ".select2-container--default .select2-selection--multiple .select2-selection__rendered"
              )
              .append(arrowHtml);
            $(container)
              .find(
                ".select2-container--default .select2-selection--multiple .select2-selection__choice__remove"
              )
              .css("color", config.placeholderColour);
            $(container)
              .find(
                ".select2-container--default .select2-selection--multiple .select2-selection__choice"
              )
              .css("color", config.placeholderColour);
            if (!_.has(sets, columnMap.filter.Name)) {
              _.set(sets, columnMap.filter.Name, new Set());
            }

            if (vispeahen.filterGlobalArrayFlag == true) {
              let set = _.get(sets, columnMap.filter.Name);
              for (let m = 0; m < vispeahen.filterGlobalArray.length; m++) {
                set.add(
                  data.find(
                    d => d.filter == vispeahen.filterGlobalArray[m].filter
                  )
                );
              }
              vispeahen.filterGlobalArrayFlag = false;
            }

            let set = _.get(sets, columnMap.filter.Name);
            if (e.params.data.text != "") {
              set.delete(data.find(d => d.filter == e.params.data.id));
            }
            let array = set.size > 0 ? Array.from(set) : [];
            if (array == data) {
              columnMap.filter.remove = true;
            }
            createTrigger(
              actions,
              columnMap,
              container,
              "select",
              array,
              THIS.props.plugin.id,
              THIS.props.interactions,
              THIS.props.navigations
            );
          });
        $(container)
          .find(".smc")
          .on("select2:clear", e => {
            $(container)
              .find(".select2-selection__clear")
              .css("color", config.arrowColor);
            $(container)
              .find(
                ".select2-container--default .select2-selection--multiple .select2-selection__rendered"
              )
              .append(arrowHtml);
            $(container)
              .find(
                ".select2-container--default .select2-selection--multiple .select2-selection__choice__remove"
              )
              .css("color", config.placeholderColour);
            $(container)
              .find(
                ".select2-container--default .select2-selection--multiple .select2-selection__choice"
              )
              .css("color", config.placeholderColour);
            _.set(sets, columnMap.filter.Name, new Set());
            let set = _.get(sets, columnMap.filter.Name);
            let array = set.size > 0 ? Array.from(set) : [];
            if (array == data) {
              columnMap.filter.remove = true;
            }

            if ($("#" + divId).find(".select2-search__field").length === 1) {
              $("#" + divId).find(".select2-search__field").remove()
            }

            createTrigger(
              actions,
              columnMap,
              container,
              "select",
              array,
              THIS.props.plugin.id,
              THIS.props.interactions,
              THIS.props.navigations
            );
          });

        $("#" + divId).find(".selection")[1].addEventListener("mouseover", () => {
          let selectedValParentId = $($(container).find(":selected")[1]).parent().attr("id");
          let selectedValues = $(container).find(`[id='${selectedValParentId}']`).find(":selected")
          let filteredSelectedValues = []

          if (selectedValues.length > 0) {
            selectedValues.each(i => {
              if (selectedValues[i].outerText != "") {
                filteredSelectedValues.push(selectedValues[i].outerText)
              }
            })
          }

          if (filteredSelectedValues.length > 0) {
            if ($(`#selection-tooltip-${divId}`).length === 1) {
              $(`#selection-tooltip-${divId}`).html("")
            }

            if ($("#selection-tooltip-" + divId).length === 0) {
              $($("#" + divId).find(".selection")[1]).append(`<div id="selection-tooltip-${divId}" class="selection-tooltip"></div>`)
              $("#selection-tooltip-" + divId)
                .css("width", "170px")
                .css("min-height", "30px")
                .css("border", "1px solid #aaa")
                .css("position", "absolute")
                .css("left", "100%")
                .css("top", "0%")

              $("#selection-tooltip-" + divId)
                .css("attr", "left")

              if ($("#selection-tooltip-" + divId).offset().left + 150 < $("#mainDiv").width()) {
                $("#selection-tooltip-" + divId).css("left", "100%").css("right", "")
                $("#selection-tooltip-" + divId).attr("pos", "left")
              } else {
                $("#selection-tooltip-" + divId).css("right", "100%").css("left", "").css("top", "90%")
                $("#selection-tooltip-" + divId).attr("pos", "right")
              }
            }

            $("#selection-tooltip-" + divId).append(`<div class='selection-tooltip-selected-item'>${i18n.t("Dashboard.Configuration.Fields.SelectedValues")}</div>`)
            $("#selection-tooltip-" + divId).append("<div class='selection-tooltip-selected-items-list'></div>")

            for (let i = 0; i < filteredSelectedValues.length; i++) {
              if (filteredSelectedValues[i] !== "" && filteredSelectedValues[i] != null && filteredSelectedValues[i] != undefined) {
                $("#selection-tooltip-" + divId).find(".selection-tooltip-selected-items-list").append(`<span class="placeholder-per-item">${filteredSelectedValues[i]}</span>`)
              }
            }
          } else {
            $("#selection-tooltip-" + divId).remove()
          }
        })
      } else {
        $($(container).find(".select2-selection__rendered")[1]).append(placeholderSpanForSingleSelect);

        let widthPlaceholderSingele = $($(container)
          .find(".select2-selection__rendered")[1])
          .css("width");
        widthPlaceholderSingele =
          widthPlaceholderSingele.substring(0, widthPlaceholderSingele.indexOf("p")) -
          40 +
          "px";

        if (!config.isMulti) {
          $(container).find(".placeholder").css({
            position: "relative",
            width: widthPlaceholderSingele,
            overflow: "hidden",
            "text-overflow": "ellipsis",
            // display: "block",
          });
        }

        $(container)
          .find(".smc")
          .on("select2:select", e => {
            $(container)
              .find(".select2-selection__clear")
              .css("color", config.arrowColor);
            $(container)
              .find(
                ".select2-container--default .select2-selection--single .select2-selection__rendered"
              )
              .css("color", config.placeholderColour);

            if (!_.has(sets, columnMap.filter.Name)) {
              _.set(sets, columnMap.filter.Name, new Set());
            }

            if (vispeahen.filterGlobalArrayFlag == true) {
              let set = _.get(sets, columnMap.filter.Name);
              for (let m = 0; m < vispeahen.filterGlobalArray.length; m++) {
                set.add(
                  data.find(
                    d => d.filter == vispeahen.filterGlobalArray[m].filter
                  )
                );
              }
              vispeahen.filterGlobalArrayFlag = false;
            }

            let set = _.get(sets, columnMap.filter.Name);
            if (set.size > 0) {
              set.clear();
            }
            set.add(data.find(d => d.filter == e.params.data.id));
            let array = set.size > 0 ? Array.from(set) : [];
            columnMap.filter.remove = false;

            let operator = $("#select-" + divId).val() && $("#select-" + divId).val() !== "" && $("#select-" + divId).val() !== null
              ? $("#select-" + divId).val()
              : config.operatorsForStringFilter != undefined
                ? config.operatorsForStringFilter
                : "="

            createTrigger(
              actions,
              columnMap,
              container,
              "select",
              array,
              THIS.props.plugin.id,
              THIS.props.interactions,
              THIS.props.navigations,
              null,
              operator
            );
          });

        //unselect for single select
        $(container)
          .find(".smc")
          .on("select2:unselect", e => {
            $(container)
              .find(".select2-selection__clear")
              .css("color", config.arrowColor);
            $(container)
              .find(
                ".select2-container--default .select2-selection--single .select2-selection__rendered"
              )
              .css("color", config.placeholderColour);

            if (!_.has(sets, columnMap.filter.Name)) {
              _.set(sets, columnMap.filter.Name, new Set());
            }

            if (vispeahen.filterGlobalArrayFlag == true) {
              let set = _.get(sets, columnMap.filter.Name);
              for (let m = 0; m < vispeahen.filterGlobalArray.length; m++) {
                set.add(
                  data.find(
                    d => d.filter == vispeahen.filterGlobalArray[m].filter
                  )
                );
              }
              vispeahen.filterGlobalArrayFlag = false;
            }

            let set = _.get(sets, columnMap.filter.Name);
            if (e.params.data.text != "") {
              set.delete(data.find(d => d.filter == e.params.data.id));
            }
            let array = set.size > 0 ? Array.from(set) : [];
            columnMap.filter.remove = true;
            createTrigger(
              actions,
              columnMap,
              container,
              "select",
              array,
              THIS.props.plugin.id,
              THIS.props.interactions,
              THIS.props.navigations
            );
          });
      }

      let arrowHtmlFunc = this.prepareHtmlArrow(config.arrowColor)
      let placeholderHtmlFunc = this.preparePlaceholder(config.placeholder)

      $("#" + id).change(function (e) {
        if ($("#" + divId).find('.select2-selection__clear').length !== 0) {
          $("#" + divId).find('.select2-selection__clear')[0].title = i18n.t("Plugins.filter.RemoveAllItems");
        }

        if (config.isMulti) {
          let selectionLength = $("#" + divId).find(".select2-selection__choice").length;

          if ($("#" + divId).find('.select2-selection__choice').length === 0) {
            $("#" + divId).find(".select2-selection__clear").remove()

            if ($("#" + divId).find(".select2-search--inline").find(".placeholder").length === 0) {
              if ($("#select-" + divId).val() === "=" || $("#select-" + divId).val() === "!=") {
                $($("#" + divId).find(".select2-search--inline")[0]).append(placeholderSpanForMultipleSelect)
              }

              $("#" + divId).find(".select2-search--inline").append(arrowHtmlFunc)
            }
          } else {
            $("#" + divId).find(".select2-search--inline").find(".placeholder").remove()
          }

          /** If there are more clear button than selected filter */
          $("#" + divId).find(".select2-selection__choice").each(function () {
            if ($(this)[0].title === "") {
              $(this).remove();
              selectionLength -= 1;
            }
          });

          let moreAreaShowLimit = 2;
          const fontSize = parseInt($("#" + divId).css("font-size"));
          const isSmallArea = $("#" + divId).width() < (fontSize * 10) && selectionLength > 1;

          /* If filter area is very small, then show only 1 filter element, otherwise show 2 filter elements */
          moreAreaShowLimit = isSmallArea ? 1 : 2;

          if (selectionLength > moreAreaShowLimit) {

            /* If filter area is smaller than (fontSize*10) or smaller than (fontSize*15) but there are more than 2 filters, then show 1 element */
            const filterLimit = isSmallArea || ($("#" + divId).width() < (fontSize * 15) && selectionLength > 2) ? 1 : 2;

            $("#" + divId).find(".select2-selection__choice").each(function (index) {
              if (index >= filterLimit) {
                $(this).css("display", "none");
              } else {
                $(this).addClass("multiple-more-items");
              }
            });

            $("#" + divId).find(".select2-selection--multiple").find(".multiple-more-span").remove();
            let moreSpan = "<span class='multiple-more-span' multiple='multiple' style='color: " + config.placeholderColour + "'>" + (selectionLength - filterLimit) + " " + i18n.t("More") + "</span>";
            $("#" + divId).find(".select2-selection--multiple").append(moreSpan);
          } else {
            let dataWidth;

            if (selectionLength == 1) {
              dataWidth = '70%';
            } else {
              dataWidth = '40%';
            }

            if ($("#" + divId).width() < (fontSize * 10)) {
              if (selectionLength == 1) {
                dataWidth = '75%';
              } else {
                dataWidth = '35%'
              }
            }

            $("#" + divId).find(".select2-selection__choice").each(function () {
              $(this).css("display", "inline-block");
              $(this).removeClass("multiple-more-items");
              $(this).addClass('show-multiple-items');
              $(this).css("width", dataWidth);
            });

            $("#" + divId).find(".select2-selection--multiple").find(".multiple-more-span").remove();
          }
        } else {
          let isSelectAvailable = $("#" + divId).find(".select2-selection__clear").length === 1 ? true : false;

          if ($(container).find(".placeholder").length === 0 && isSelectAvailable === false) {
            if ($("#select-" + divId).val() === "=" || $("#select-" + divId).val() === "!=") {
              $($(container).find(".select2-selection__rendered")[1]).append(placeholderSpanForSingleSelect)
            }
          }
        }
      });
    } else {
      if (config.useTextBox) {
        let gettedValues = THIS.controlledGettedValue

        let applyButtonBackgroundColor = config.applyButtonBackgroundColor ? config.applyButtonBackgroundColor : "white"
        let applyButtonTextColor = config.applyButtonTextColor ? config.applyButtonTextColor : "rgba(0, 0, 0, 0.65)"
        let applyButtonTranslate = config.applyButtonText ? config.applyButtonText : i18n.t("Apply")

        $(container).append(`<div id='select-button-${divId}' class='filter-customized-input'></div>`)
        $(container).find("#select-button-" + divId).append(`<input class="string-filter-input" id='filter-input-${divId}'></input>`)
        $(container).find("#select-button-" + divId).append(`<button id='filter-button-${divId}' class='filter-customized-input'>${applyButtonTranslate}</button>`)

        $(container).find("#select-button-" + divId)
          .css("width", "100%")
          .css("display", "flex")
          .css("background", "white")
          .css("font-size", "14px")
          .css("border", "1px solid #aaa")
          .children().css("border-radius", "inherit")

        $(container).find("#filter-input-" + divId)
          .css("width", "65%").css("outline", "none")
          .css("border", "none")
          .css("padding-left", "5px")
          .css("background", config.textBackgroundColor)
          .css("color", config.placeholderColour)

        $(container).find("#filter-button-" + divId)
          .css("width", "calc(40% - 5px)")
          .css("height", "90%")
          .css("margin-right", "5px")
          .css("background", applyButtonBackgroundColor)
          .css("color", applyButtonTextColor)
          .css("max-width", "135px")
          .css("display", "inline-block")
          .css("white-space", "nowrap")
          .css("overflow", "hidden")
          .css("text-overflow", "ellipsis")

        $(container).find("#select-" + divId).css("width", "33%")

        $(container).find("#filter-button-" + divId)[0].addEventListener("click", () => this.onApplyButtonClick(config))

        let inlineWidth = $("#inline-" + divId).width();
        let selectWidth = $("#" + divId).find(".select2-container").children().width();
        let leftPos = selectWidth + 5
        let buttonWidth = $("#filter-button-" + divId).width()
        let maxWidth = inlineWidth - (selectWidth + 25 + buttonWidth)
        // let placeholderSpanForSingleSelect = `<span class="number-input-placeholder" style="color: ${config.placeholderColour}; width: ${maxWidth}px; left: ${leftPos}px">${config.placeholder || ""}</span>`

        // $(container).find("#select-button-" + divId).append(placeholderSpanForSingleSelect) //Removed placeholder from input

        if ((gettedValues && gettedValues.filter && gettedValues.filterPredicate !== "=" && gettedValues.filterPredicate !== "!=" && !isBeforePredicateIsEqual) || config.useTextBox) {
          $(container).find("#filter-input-" + divId).val(gettedValues.filter)

          if (gettedValues.filter.length > 0) {
            $("#" + divId).find(".number-input-placeholder").css("display", "none")
          } else {
            $("#" + divId).find(".number-input-placeholder").css("display", "inline-block")
          }
        }

        // $("#" + divId).find(".number-input-placeholder").click(function() {
        //   $("#" + divId).find("#filter-input-" + divId).focus();
        // });

        // $(container).find("#filter-input-" + divId)[0].addEventListener("input", function(event) {
        //   if (event.target.value.length > 0) {
        //     $("#" + divId).find(".number-input-placeholder").css("display", "none")
        //   } else {
        //     $("#" + divId).find(".number-input-placeholder").css("display", "inline-block")
        //   }
        // })
      } else {
        let gettedValues = THIS.controlledGettedValue

        $(container).append(`<div id='select-button-${divId}' class='filter-customized-input'></div>`)
        $(container).find("#select-button-" + divId).append(`<input class="string-filter-input" id='filter-input-${divId}'></input>`)

        $(container).find("#select-button-" + divId)
          .css("width", "100%")
          .css("display", "flex")
          .css("background", "white")
          .css("font-size", "14px")
          .css("border", "1px solid #aaa")
          .css("margin", "auto")
          .children().css("border-radius", "inherit")
          .css("line-height", "24px")

        $(container).find("#filter-input-" + divId)
          .css("width", "100%").css("outline", "none")
          .css("border", "none")
          .css("padding-left", "5px")
          .css("background", config.textBackgroundColor)
          .css("color", config.placeholderColour)

        $(container).find("#select-" + divId).css("width", "33%")

        $(container).find("#filter-input-" + divId)[0].addEventListener("input", this.setTargetValue)

        // let inlineWidth = $("#inline-" + divId).width();
        // let selectWidth = $("#" + divId).find(".select2-container").children().width();
        // let leftPos = selectWidth + 5
        // let maxWidth = inlineWidth - (selectWidth + 25)
        //let placeholderSpanForSingleSelect = `<span class="number-input-placeholder" style="color: ${config.placeholderColour}; width: ${maxWidth}px; left: ${leftPos}px">${config.placeholder || ""}</span>`

        //$(container).find("#select-button-" + divId).append(placeholderSpanForSingleSelect) //Removed placeholder from input

        if (gettedValues
          && gettedValues.filter
          && gettedValues.filterPredicate !== "="
          && gettedValues.filterPredicate !== "!="
          && !isBeforePredicateIsEqual
          && gettedValues.filterPredicate !== "in"
          && gettedValues.filterPredicate !== "not in") {
          $(container).find("#filter-input-" + divId).val(gettedValues.filter)

          // if (gettedValues.filter.length > 0) {
          //   $("#" + divId).find(".number-input-placeholder").css("display", "none")
          // } else {
          //   $("#" + divId).find(".number-input-placeholder").css("display", "inline-block")
          // }
        }

        $("#" + divId).find(".number-input-placeholder").click(function() {
          $("#" + divId).find("#filter-input-" + divId).focus();
        });
      }

      if ($(container).find(".select2-selection__arrow b").children().length > 0) {
        $(container).find(".select2-selection__arrow b").children().css("border-color", `${config.arrowColor} transparent`);
      }

      $(container).find(".select2-selection__arrow b").css("border-color", `${config.arrowColor} transparent`);

      
    }

    $(`#select-button-${divId}`)
      .css("background", config.textBackgroundColor)
      .css("color", config.placeholderColour)

    $('select').on('select2:open', function (e) {
      let searchInput = $(`[aria-controls='select2-select-${divId}-results']`)
      let isTargetOptionSelect = $(e.target).hasClass("js-example-basic-single") || $(e.target).hasClass("js-example-basic-multiple")

      if (isTargetOptionSelect) {
        $(".select2-dropdown").find(".select2-search__field").addClass("display-true")
      } else {
        $(".select2-dropdown").find(".select2-search__field").removeClass("display-true")
      }

      searchInput.parent().parent().find(".select2-results").addClass("select2-result-style").css("font-size", "11px!important")
    });

    if ($("#" + divId).find(".select2-search__field").length === 1) {
      $("#" + divId).find(".select2-search__field").remove()
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction, false, config);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let pluginId = this.props.plugin.id;

    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + pluginId),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + pluginId),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };

    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + pluginId).css("padding")
      );

      pluginConfig.height =
        calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={pluginId}></div>
        </div>
      )
    }

    return (
      <>
        <div id={pluginId} className="filterClass display-flex"
          onMouseLeave={(e) => this.props.offHoverInputClass(e, this.props.plugin)}
          onMouseOver={(e) => this.props.onHoverInputClass(e, this.props.plugin)}></div>
        {renderContent(
          isRerender,
          this.pluginRender,
          this.props.plugin,
          data,
          columnMap,
          pluginConfig,
          condFormats,
          this.props.setPluginRerender,
          this.lastContent,
          this.updateLastContent,
          this
        )}
        {configComponent}
        {dataComponent}
      </>
    );
  }
}