import React, { Component } from "react";
import { Input, InputNumber, Select } from "antd";
import { deepCopy } from "../../../../../Utils/Global";
import Text from "../../../../GeneralComponents/Text/Text";
import i18n from "../../../../../Utils/i18next";


export default class PageSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paperFormats: [
                {
                    name: "A3",
                    value: 8
                },
                {
                    name: "A4",
                    value: 9
                },
                {
                    name: "A5",
                    value: 11
                },
                {
                    name: "A6",
                    value: 70
                },
                {
                    name: "Letter",
                    value: 1
                },
                {
                    name: "Custom",
                    value: 0
                },
            ]
        }
    }
    /**
     * Sets paper format, width and height
     * 
     * @param {*} value 
     */
    setPaperFormat = (index) => {
        let options = deepCopy(this.props.options);
        let format = this.state.paperFormats[index];

        if (format.value === 0) {
            options.paperWidth = options.paperWidth ? options.paperWidth : 0;
            options.paperHeight = options.paperHeight ? options.paperHeight : 0;
        } else {
            options.paperWidth = null;
            options.paperHeight = null;
        }

        options.paperSize = format.value;

        this.props.setOptions(options);
    }

    /**
     * Sets page orientation & swaps height and width
     * 
     * @param {*} value 
     */
    setOrientation = (value) => {
        let options = deepCopy(this.props.options);

        if (options.orientation !== value) {
            options.orientation = value;

            this.props.setOptions(options);
        }
    }

    /**
     * Sets paper width or height
     * 
     * @param {*} dim 
     * @param {*} value 
     */
    setPaperDimension = (dim, value) => {
        let options = deepCopy(this.props.options);

        if (dim === "height") {
            options.paperHeight = value;
        }

        if (dim === "width") {
            options.paperWidth = value;
        }

        options.paperSize = 0;

        this.props.setOptions(options);
    }


    render() {
        const { options, measurementUnit } = this.props;

        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "40%"
                    }}
                >
                    <Text
                        style={{
                            marginBottom: 8,
                            fontWeight: 600
                        }}
                    >
                        {i18n.t("Designer.PaperFormat.Title")}
                    </Text>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            marginBottom: 16,
                            paddingLeft: 8
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: 8
                            }}
                        >
                            <Text
                                style={{
                                    height: 32,
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.PaperFormat.Format")}:
                            </Text>
                            {
                                options.paperSize === 0 ? (
                                    <>
                                        <Text
                                            style={{
                                                height: 32,
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: 4,
                                                marginLeft: 0
                                            }}
                                        >
                                            {i18n.t("Designer.PaperFormat.Width")}:
                                        </Text>
                                        <Text
                                            style={{
                                                height: 32,
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: 8,
                                                marginLeft: 0
                                            }}
                                        >
                                            {i18n.t("Designer.PaperFormat.Height")}:
                                        </Text>
                                    </>
                                ) : null
                            }
                            <Text
                                style={{
                                    height: 32,
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 8,
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.Orientation.Title")}:
                            </Text>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%"
                            }}
                        >
                            <Select
                                value={this.state.paperFormats.findIndex(format => format.value === options.paperSize)}
                                onSelect={(value) => this.setPaperFormat(value)}
                                style={{
                                    height: 32,
                                    marginBottom: 4,
                                    width: "100%"
                                }}
                            >
                                {
                                    Object.values(this.state.paperFormats).map((format, index) => (
                                        <Select.Option value={index}>
                                            {i18n.t(`Designer.PaperFormat.${format.name}`)}
                                        </Select.Option>
                                    ))
                                }
                            </Select>

                            {
                                options.paperSize === 0 ? (
                                    <>
                                        <Input.Group compact style={{ textAlign: "left" }}>
                                            <InputNumber
                                                step={options.measurementUnit === "pt" ? 1 : 0.10}
                                                min={options.measurementUnit === "pt" ? 1 : 0.10}
                                                value={options.paperWidth}
                                                onChange={(value) => this.setPaperDimension("width", value)}
                                                style={{
                                                    height: 32,
                                                    marginBottom: 4
                                                }}
                                            />
                                            <Input
                                                placeholder={measurementUnit}
                                                value={measurementUnit}
                                                disabled={true}
                                                style={{ width: 70 }}
                                            />

                                        </Input.Group>
                                        <Input.Group compact style={{ textAlign: "left" }}>
                                            <InputNumber
                                                step={options.measurementUnit === "pt" ? 1 : 0.10}
                                                min={options.measurementUnit === "pt" ? 1 : 0.10}
                                                value={options.paperHeight}
                                                onChange={(value) => this.setPaperDimension("height", value)}
                                                style={{
                                                    height: 32,
                                                    marginBottom: 4
                                                }}
                                            />
                                            <Input
                                                placeholder={measurementUnit}
                                                value={measurementUnit}
                                                disabled={true}
                                                style={{ width: 70 }}
                                            />
                                        </Input.Group>
                                    </>
                                ) : null
                            }

                            <Select
                                showArrow
                                value={options.orientation}
                                onSelect={(value) => this.setOrientation(value)}
                                style={{
                                    height: 32,
                                    marginTop: 8,
                                    marginBottom: 4,
                                    width: "100%"
                                }}
                            >
                                <Select.Option value={"portrait"}>{`${i18n.t("Designer.Orientation.Portrait")}`}</Select.Option>
                                <Select.Option value={"landscape"}>{`${i18n.t("Designer.Orientation.Landscape")}`}</Select.Option>
                            </Select>
                        </div>
                    </div>

                    <Text
                        style={{
                            marginBottom: 8,
                            fontWeight: 600
                        }}
                    >
                        {i18n.t("Designer.Margins.Title")}
                    </Text>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            paddingLeft: 8
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: 8
                            }}
                        >
                            <Text
                                style={{
                                    height: 32,
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.Margins.Left")}:
                            </Text>
                            <Text
                                style={{
                                    height: 32,
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.Margins.Right")}:
                            </Text>
                            <Text
                                style={{
                                    height: 32,
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.Margins.Top")}:
                            </Text>
                            <Text
                                style={{
                                    height: 32,
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                    marginLeft: 0
                                }}
                            >
                                {i18n.t("Designer.Margins.Bottom")}:
                            </Text>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                justifyContent: "center"
                            }}
                        >
                            <Input.Group compact style={{ textAlign: "left" }}>
                                <InputNumber
                                    min={0}
                                    step={options.measurementUnit === "pt" ? 1 : 0.10}
                                    value={options.pageMarginLeft}
                                    onChange={(value) => this.props.setOption("pageMarginLeft", value)}
                                    style={{
                                        marginBottom: 4
                                    }}
                                />
                                <Input
                                    placeholder={measurementUnit}
                                    value={measurementUnit}
                                    disabled={true}
                                    style={{ width: 70 }}
                                />
                            </Input.Group>
                            <Input.Group compact style={{ textAlign: "left" }}>
                                <InputNumber
                                    min={0}
                                    step={options.measurementUnit === "pt" ? 1 : 0.10}
                                    value={options.pageMarginRight}
                                    onChange={(value) => this.props.setOption("pageMarginRight", value)}
                                    style={{
                                        marginBottom: 4
                                    }}
                                />
                                <Input
                                    placeholder={measurementUnit}
                                    value={measurementUnit}
                                    disabled={true}
                                    style={{ width: 70 }}
                                />
                            </Input.Group>
                            <Input.Group compact style={{ textAlign: "left" }}>
                                <InputNumber
                                    min={0}
                                    step={options.measurementUnit === "pt" ? 1 : 0.10}
                                    value={options.pageMarginTop}
                                    onChange={(value) => this.props.setOption("pageMarginTop", value)}
                                    style={{
                                        marginBottom: 4
                                    }}
                                />
                                <Input
                                    placeholder={measurementUnit}
                                    value={measurementUnit}
                                    disabled={true}
                                    style={{ width: 70 }}
                                />
                            </Input.Group>
                            <Input.Group compact style={{ textAlign: "left" }}>
                                <InputNumber
                                    min={0}
                                    step={options.measurementUnit === "pt" ? 1 : 0.10}
                                    value={options.pageMarginBottom}
                                    onChange={(value) => this.props.setOption("pageMarginBottom", value)}
                                    style={{
                                        marginBottom: 4
                                    }}
                                />
                                <Input
                                    placeholder={measurementUnit}
                                    value={measurementUnit}
                                    disabled={true}
                                    style={{ width: 70 }}
                                />
                            </Input.Group>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}