import React, { Component } from "react";
import { Select, Icon } from "antd";
import "antd/dist/antd.css";
const { Option } = Select;

export default class SelectModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modelList: [],
      value: "",
    };
  }

  // componentWillReceiveProps(nextProps){
  //   const { modelList } = nextProps;
  //   this.setState({
  //     modelList
  //   })
  // }

  onChange = value => {
    this.props.handleSelection(value);
    this.setState({
      value
    });
  };

  onBlur = () => {
    // console.log("blur");
  };

  onFocus = () => {
    // console.log("focus");
  };

  onSearch = val => {
    // console.log("search:", val);
  };

  sortDropdown = (modelList) => {
    if (modelList && modelList.length > 0) {
      modelList.sort(function (a, b) {
        if (a.name.toUpperCase() > b.name.toUpperCase())
          return 1;
        if (a.name.toUpperCase() < b.name.toUpperCase())
          return -1;
        return 0;
      });
    }
  };

  render() {
    const { modelList } = this.props;
    this.sortDropdown(modelList);
    return modelList && modelList.length > 0 ? (
      <div>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select a model"
          optionFilterProp="children"
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}
          // defaultValue={modelList[0].id}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {modelList.map((model, key) => (
            <Option value={model.id} key={key}>
              <Icon id={model.displayName} type="profile" /> &nbsp; {model.name}
            </Option>
          ))}
        </Select>
      </div>
    ) : (
        ""
      );
  }
}
