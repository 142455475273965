import React, { Component } from "react";
import { Table, Button, Popconfirm, Input, Select, Empty, Row, Col } from "antd";
import { del } from "../../../Utils/WebService";
import { showNotificationWithIcon } from "../../../Utils/Notification";
import { API_BASE } from "../../../config";
import i18n from "../../../Utils/i18next";
import { updatePaginationLocale } from "../../../Utils/TablePaginationRename";
import Cookies from "js-cookie";
import JoinCreate from "../JoinCreate/JoinCreate";
import Text from "../../GeneralComponents/Text/Text";

const host = API_BASE;
const api = "/join";
const url = host + api;
const Search = Input.Search;
const { Option } = Select;

class JoinList extends Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);

    this.state = {
      baseData: this.props.joins,
      factTableDisplayNameSearchData: "",
      factColumnDisplayNameSearchData: "",
      dimensionTableDisplayNameSearchData: "",
      dimensionColumnDisplayNameSearchData: "",
      joinTypeSearchData: "",
      labelInner: i18n.t("JoinTable.Inner"),
      labelOuter: i18n.t("JoinTable.Outer"),
      showModal: false,
      showUpdateModal: false,
      selectedModel: "",
      factTableDataSourceKey: "",
      factTableAlias: "",
      factColumnAlias: "",
      factTableDisplayName: "",
      factColumnDisplayName: "",
      dimensionDataSourceKey: "",
      dimensionTableAlias: "",
      dimensionColumnAlias: "",
      dimensionTableDisplayName: "",
      dimensionColumnDisplayName: "",
      operationType: "",
      joinType: ""
    }

    this.filterObj = {
      "factTableDisplayName": "filteredFactTable",
      "factColumnDisplayName": "filteredFactColumn",
      "dimensionTableDisplayName": "filteredDimensionTable",
      "dimensionColumnDisplayName": "filteredDimensionColumn",
      "joinType": "filteredJoinType"
    }
  }


  /**
   * To find the intersection of the data searched in each column.
   */
  intersection = (state) => {
    let {
      baseData,
      filteredFactTable,
      filteredFactColumn,
      filteredDimensionTable,
      filteredDimensionColumn,
      filteredJoinType
    } = state;

    let stateArray = [
      filteredFactTable?.map(i => i.id),
      filteredFactColumn?.map(i => i.id),
      filteredDimensionTable?.map(i => i.id),
      filteredDimensionColumn?.map(i => i.id),
      filteredJoinType?.map(i => i.id),
    ].map(i => i)

    let filteredJoinIds = stateArray.reduce((commonElements, currentArray) => {
      if (Array.isArray(currentArray)) {
        let filteredElements = commonElements.filter((element) => currentArray.includes(element));
        return filteredElements;
      }
      else {
        return commonElements;
      }
    });

    return filteredJoinIds
      ? baseData.filter(join => filteredJoinIds.includes(join.id))
      : baseData;
  }

  /**
   * To find the intersection of the data searched in each column.
   */
  findIntersection = (newState) => {
    newState["searchedData"] = this.intersection(newState);

    this.setState(newState, () => {
      if (newState.joinTypeSearchData.length > 1) {
        this.updateLabels();
      } else {
        setTimeout(() => {
          this.updateLabels();
        }, 250);
      }
    });
  }

  /**
   * Update option labels when both options are selected.
   */
  updateLabels() {
    let language = Cookies.get("i18next") === "en" ? "en" : "tr";
    let newState = { ...this.state };

    newState.labelInner = language === "en" && newState.joinTypeSearchData.length > 1 ? "In" : i18n.t("JoinTable.Inner");
    newState.labelOuter = language === "en" && newState.joinTypeSearchData.length > 1 ? "Out" : i18n.t("JoinTable.Outer");

    this.setState(newState);
  }

  componentWillReceiveProps(nextProps) {
    let isWindowOpened = (this.props.joins !== nextProps.joins && this.props.visible !== false);
    let isWindowReopened = (this.props.visible !== nextProps.visible && nextProps.visible === false);

    if (isWindowOpened) {
      let baseData = nextProps.joins;
      let newState = this.state;

      for (let [key, name] of Object.entries(this.filterObj)) {
        let searchValue = newState[key + "SearchData"];

        if (searchValue.length > 0) {
          let searchedData = baseData.filter(join => {
            if (searchValue instanceof Array) {
              return searchValue.some(element => join[key].includes(element))
            }

            return join[key].toLowerCase().includes(searchValue.toLowerCase())
          });

          newState[name] = searchedData
        } else {
          newState[name] = baseData;
        }
      }

      newState.baseData = baseData;
      newState["searchedData"] = this.intersection(newState);

      this.setState(newState);
    } else if (isWindowReopened) {
      this.setState({
        ...this.state,
        searchedData: this.state.baseData,
        factTableDisplayNameSearchData: "",
        factColumnDisplayNameSearchData: "",
        dimensionTableDisplayNameSearchData: "",
        dimensionColumnDisplayNameSearchData: "",
        joinTypeSearchData: [],
        filteredFactTable: nextProps.joins,
        filteredFactColumn: nextProps.joins,
        filteredDimensionTable: nextProps.joins,
        filteredDimensionColumn: nextProps.joins,
        filteredJoinType: nextProps.joins,
      })
    }
  }

  /**
   * To determine which property of join will be searched.
   */
  handleSearch = (e, key) => {
    let name = e.target.name;

    if (e.target.value || e.target.value === "") {
      let newState = { ...this.state }

      newState[key + "SearchData"] = e.target.value

      this.filteredData(e.target.value.toLowerCase(), name, key, newState);
    }
  };

  /**
   * Filters joins by their types.
   */
  filterJoinType = (value) => {
    let newState = { ...this.state }
    let joins = this.state.baseData;

    const searchedData = value.length > 0 ? joins.filter(join => value.some(type => join["joinType"].includes(type))) : this.state.baseData;

    newState["filteredJoinType"] = searchedData
    newState["joinTypeSearchData"] = value

    this.findIntersection(newState)
  }

  /**
   * To filter joins by searched value in a specific column.
   */
  filteredData = (searchValue, name, key, newState) => {
    let joins = this.state.baseData;
    let searchedData = joins.filter(join => join[key].toLowerCase().includes(searchValue.toLowerCase()));

    newState[name] = searchedData

    this.findIntersection(newState)
  }

  onDelete(record) {
    let urlWithParameters = url + "/" + record.id;

    if (record["joinType"] === "Dış") {
      record["joinType"] = "Outer"
    } else if (record["joinType"] === "İç") {
      record["joinType"] = "Inner"
    }

    del(
      urlWithParameters,
      record,
      response => {
        showNotificationWithIcon(i18n.t("Model.JoinDeletedSuccessfull"), null, "success");
        this.props.onDelete();
        this.props.getModelTables(record.modelId)
      },
      error => {
        showNotificationWithIcon(i18n.t("Model.JoinCanNotBeDeleted"), null, "error");
      }
    );
  }

  columnSorter(a, b) {
    if (a.toUpperCase() > b.toUpperCase())
      return -1;
    if (a.toUpperCase() < b.toUpperCase())
      return 1;
    return 0;
  }

  // Handle Edit join event
  handleEditJoin = (record) => {
    this.setState({
      ...this.state,
      showModal: true,
      selectedModel: record,
      factTableDataSourceKey: record.factTableDataSourceKey,
      factTableAlias: record.factTableAlias,
      factColumnAlias: record.factColumnAlias,
      factTableDisplayName: record.factTableDisplayName,
      factColumnDisplayName: record.factColumnDisplayName,
      dimensionDataSourceKey: record.dimensionTableDataSourceKey,
      dimensionTableAlias: record.dimensionTableAlias,
      dimensionColumnAlias: record.dimensionColumnAlias,
      dimensionTableDisplayName: record.dimensionTableDisplayName,
      dimensionColumnDisplayName: record.dimensionColumnDisplayName,
      joinType: record.joinType,
      joinId: record.id,
      operationType: "update",
    })
  }

  // Handle cancel event
  handleCancel = () => {
    this.setState({
      ...this.state,
      showModal: false,
      showUpdateModal: false,
      factTableDataSourceKey: "",
      factTableAlias: "",
      factColumnAlias: "",
      factTableDisplayName: "",
      factColumnDisplayName: "",
      dimensionDataSourceKey: "",
      dimensionTableAlias: "",
      dimensionColumnAlias: "",
      dimensionTableDisplayName: "",
      dimensionColumnDisplayName: "",
      joinType: "",
      joinId: "",
      operationType: "",
    })
  }

  render() {
    const columns = [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()} style={{ textAlign: "center" }}>
            <div>
              {i18n.t("JoinTable.FactTable")}
              <Search
                style={{ width: 130, marginTop: "10px" }}
                onChange={(e) => this.handleSearch(e, "factTableDisplayName")}
                type="text"
                size="small"
                value={this.state.factTableDisplayNameSearchData}
                placeholder={i18n.t("Search")}
                name="filteredFactTable"
                className={'search-area join-search-area'}
              />
            </div>
          </div>
        ),
        dataIndex: "factTableDisplayName",
        width: "calc(20% - 20px)",
        align: "center",
        elipsis: true,
        key: "factTableDisplayName",
        sorter: (a, b, c) => {
          if (c && !c.isSearch) {
            return this.columnSorter(a.factTableDisplayName, b.factTableDisplayName);
          }
          return 0;
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()} style={{ textAlign: "center" }}>
            <div>
              {i18n.t("JoinTable.FactColumn")}
              <Search
                style={{ width: 100, marginTop: "10px" }}
                onChange={(e) => this.handleSearch(e, "factColumnDisplayName")}
                type="text"
                size="small"
                value={this.state.factColumnDisplayNameSearchData}
                placeholder={i18n.t("Search")}
                name="filteredFactColumn"
                className={'search-area join-search-area'}
              />
            </div>
          </div>
        ),
        dataIndex: "factColumnDisplayName",
        width: "calc(20% - 20px)",
        align: "center",
        key: "factColumnDisplayName",
        sorter: (a, b, c) => {
          if (c && !c.isSearch) {
            return this.columnSorter(a.factColumnDisplayName, b.factColumnDisplayName);
          }
          return 0;
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()} style={{ textAlign: "center" }}>
            <div>
              {i18n.t("JoinTable.DimensionTable")}
              <Search
                style={{ width: 130, marginTop: "10px" }}
                onChange={(e) => this.handleSearch(e, "dimensionTableDisplayName")}
                type="text"
                size="small"
                value={this.state.dimensionTableDisplayNameSearchData}
                placeholder={i18n.t("Search")}
                name="filteredDimensionTable"
                className={'search-area join-search-area'}
              />
            </div>
          </div>
        ),
        dataIndex: "dimensionTableDisplayName",
        width: "calc(20% - 20px)",
        key: "dimensionTableDisplayName",
        align: "center",
        sorter: (a, b, c) => {
          if (c && !c.isSearch) {
            return this.columnSorter(a.dimensionTableDisplayName, b.dimensionTableDisplayName);
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()} style={{ textAlign: "center" }}>
            <div>
              {i18n.t("JoinTable.DimensionColumn")}
              <Search
                style={{ width: 130, marginTop: "10px" }}
                onChange={(e) => this.handleSearch(e, "dimensionColumnDisplayName")}
                type="text"
                size="small"
                value={this.state.dimensionColumnDisplayNameSearchData}
                placeholder={i18n.t("Search")}
                name="filteredDimensionColumn"
                className={'search-area join-search-area'}
              />
            </div>
          </div>
        ),
        dataIndex: "dimensionColumnDisplayName",
        width: "calc(20% - 20px)",
        align: "center",
        key: "dimensionColumnDisplayName",
        sorter: (a, b, c) => {
          if (c && !c.isSearch) {
            return this.columnSorter(a.dimensionColumnDisplayName, b.dimensionColumnDisplayName);
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              position: "relative"
            }}
          >
            {i18n.t("JoinTable.JoinType")}
            <Select
              marginTop="10px"
              showArrow={true}
              placeholder={i18n.t("JoinTable.SelectPlaceholder")}
              style={{ width: 130, marginTop: "10px" }}
              mode="multiple"
              size="small"
              defaultValue={[]}
              value={this.state.joinTypeSearchData ? this.state.joinTypeSearchData : []}
              onChange={(value) => this.filterJoinType(value)}
              optionLabelProp="label"
            >
              <Option value={i18n.t("JoinTable.Inner")} label={this.state.labelInner}>{i18n.t("JoinTable.Inner")}</Option>
              <Option value={i18n.t("JoinTable.Outer")} label={this.state.labelOuter}>{i18n.t("JoinTable.Outer")}</Option>
            </Select>
          </div>
        ),
        dataIndex: "translateName",
        width: "calc(20% - 20px)",
        align: "center",
        key: "joinType",
      },
      {
        title: i18n.t("Actions"),
        key: "Action",
        width: 100,
        align: "center",
        elipsis: true,
        render: (text, record) => (
          <div
            style={{
              width: "100%",
              height: "fit-content",

              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              id={
                "joinUpdate"
                + "_"
                + record.factTableDisplayName
                + "_"
                + record.factColumnDisplayName
                + "_"
                + record.dimensionTableDisplayName
                + "_"
                + record.dimensionColumnDisplayName
                + "_"
                + record.joinType
              }
              onClick={() => this.handleEditJoin(record)}
              ghost
              type="primary"
              icon="edit"
              size="small"
              shape="circle"
              style={{
                fontSize: 12,
                marginRight: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <Popconfirm
              title={i18n.t("Model.AreYouSureDeleteJoinDefinition")}
              onConfirm={() => {
                this.onDelete(record);
              }}
              okText={i18n.t("Yes")}
              okButtonProps={{ id: "deleteJoinAcceptButton" }}
              cancelText={i18n.t("No")}
              cancelButtonProps={{ id: "deleteJoinCancelButton" }}
            >
              <Button
                id={
                  "joinDelete"
                  + "_"
                  + record.factTableDisplayName
                  + "_"
                  + record.factColumnDisplayName
                  + "_"
                  + record.dimensionTableDisplayName
                  + "_"

                  + record.dimensionColumnDisplayName
                  + "_"
                  + record.joinType
                }
                ghost
                type="danger"
                icon="delete"
                size="small"
                shape="circle"
                style={{
                  fontSize: 12,
                  marginLeft: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Popconfirm>
          </div>
        )
      }
    ];

    let getDataSourceFromJoin = (data) => {
      let coppiedData = [...data]

      for (let i = 0; i < coppiedData.length; i++) {
        if (coppiedData[i].joinType === "Inner") {
          coppiedData[i]["joinType"] = i18n.t("Inner")
        } else if (coppiedData[i].joinType === "Outer") {
          coppiedData[i]["joinType"] = i18n.t("Outer")
        }
      }

      return coppiedData
    }

    let isJoinExist = this.props.joins.length > 0 ? true : false
    let data = this.state.searchedData ? this.state.searchedData : this.props.joins
    let dataSource = isJoinExist ? getDataSourceFromJoin(data) : []

    return (
      <>
        <Table
          columns={columns}
          dataSource={dataSource}
          tableLayout="fixed"
          bordered={true}
          size="middle"
          pagination={{ pageSize: 7, ...updatePaginationLocale() }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={i18n.t("JoinTable.NoData")} /> }}
        />
        <JoinCreate
          onModelChanged={this.props.onModelChanged}
          selectedModel={this.props.selectedModel}
          onCancel={() =>
            this.setState({
              ...this.state,
              showModal: false,
              showUpdateModal: false,
              factTableDataSourceKey: "",
              factTableAlias: "",
              factColumnAlias: "",
              factTableDisplayName: "",
              factColumnDisplayName: "",
              dimensionDataSourceKey: "",
              dimensionTableAlias: "",
              dimensionColumnAlias: "",
              dimensionTableDisplayName: "",
              dimensionColumnDisplayName: "",
              joinType: "",
              joinId: "",
              operationType: "",
            })
          }
          models={this.state.models}
          tables={this.props.selectedModel.tables}
          updateModelTablesForJoin={this.props.updateModelTablesForJoin}
          onSave={(joins) => this.props.onSave(this.props.selectedModel)}
          getModelTables={this.props.getModelTables}
          visible={this.state.showModal}
          factTableDataSourceKey={this.state.factTableDataSourceKey}
          factTableAlias={this.state.factTableAlias}
          factColumnAlias={this.state.factColumnAlias}
          factTableDisplayName={this.state.factTableDisplayName}
          factColumnDisplayName={this.state.factColumnDisplayName}
          dimensionDataSourceKey={this.state.dimensionDataSourceKey}
          dimensionTableAlias={this.state.dimensionTableAlias}
          dimensionColumnAlias={this.state.dimensionColumnAlias}
          dimensionTableDisplayName={this.state.dimensionTableDisplayName}
          dimensionColumnDisplayName={this.state.dimensionColumnDisplayName}
          joinType={this.state.joinType}
          joinId={this.state.joinId}
          operationType={this.state.operationType}
          join={this.props.joins}
        />
      </>
    );
  }
}

export default JoinList;
