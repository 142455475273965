import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { rmvpp } from "../../RenderJs/rmvpp";
import TreeMapConfiguration from "./TreeMapConfiguration";
import TreeMapData from "./TreeMapData";
import i18n from "../../../../Utils/i18next";
import {
  onComponentWillMount,
  onComponentWillReceiveProps,
  getColumnMapping,
  getCurrentDateTime,
  getContrastColor
} from "../common";
import { calculatePopupPosition } from "../../../../Utils/PagePopupConfigure";
import {
  renderConfig,
  renderData,
  renderNavigation,
  renderConditionalFormatting
} from "../PluginsCommonComponents";
import { createTrigger } from "../../../Interaction/CreateTrigger";
import { renderContent } from "../renderContent";
import { checkTableJoins } from "../../../GeneralComponents/Join/Join"
import NavigationContent from "../../../Navigation/NavigationContent";
import { findColumnMapHasAnyColumn } from "../../../ConditionalFormatting/RenderConditionalListName"
import ConditionalFormatting from "../../../ConditionalFormatting/ConditionalFormatting";
import { loadingScreen } from "../../../../Utils/Global";
import { compare } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import { convertHTMLRuletoRule } from "../../../ConditionalFormatting/ConditionalFormattingCommon";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import { calculatePluginInlineHeight } from "../../../DrillDown/PluginHeightWithDrilldown";
import { store } from "../../../..";
import { vispeahenLogo } from "../Table/TablePdfContent";
import { InsightsConfig } from "../../RenderJs/config";
import { getFormattedValue } from "../format";
import { isValidWriteRoles } from "../../../DashboardPage/RoleStore";
import { changePluginLoaderVisibility } from "../../../GeneralComponents/PluginLoader/PluginLoaderAction";

const data = JSON.parse(
  `[{"group":"2014","measure":8078164}, {"group":"2015","measure":8276144}, {"group":"2016","measure":5378664},  {"group":"2017","measure":3378664}]`
);
const config = JSON.parse(
  `{"width":"500","height":400,"showHideButton":false,"roundCorners":true,"fontSize":12,"colours":"Flat-UI","toggleCriteria":"","title":"","summary":"","backgroundColor":"rgb(255,255,255)","refresh":0}`
);
const columnMap = JSON.parse(
  `{"group":{"Code":"\'ucus_PBWWSJXEAR'.'ay_adi'","Name":"ay_adi","DataType":"varchar","Table":"ucus_PBWWSJXEAR","Measure":"none","ID":"ucus_PBWWSJXEAR.ay_adi","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"","SortOrder":0,"Locale":"TR","DataFormat":"%s","Config":{},"Verified":true,"Type":"Column","Description":""},"measure":{"Code":"'ucus_PBWWSJXEAR'.'yolcutoplam'","Name":"yolcutoplam","DataType":"double","Table":"ucus_PBWWSJXEAR","Measure":"sum('ucus'.'yolcutoplam')","ID":"ucus_PBWWSJXEAR.yolcutoplam","SubjectArea":"deneme","SortKey":false,"Sorting":false,"SortDirection":"","SortOrder":0,"Locale":"TR","DataFormat":".3s","Config":{},"Verified":false,"Type":"Column","Description":""}}`
);

const condFormats = [];
const filters = [];

var pluginName = "treemap";

const configurationParameters = [
  {
    targetProperty: "titleAlign",
    label: "titleAlign",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "center"
    },
    desc: "titleAlign"
  },
  {
    targetProperty: "legend",
    label: "Legend",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc98"
  },
  {
    targetProperty: "legendPos",
    label: "Legend Position",
    inputType: "dropdown",
    inputOptions: {
      defaultValue: "bottom"
    },
    desc: "desc98"
  },
  {
    targetProperty: "titleFont",
    label: "titleFont",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "Verdana"
    },
    desc: "titleFont"
  },
  {
    targetProperty: "titleFontStyle",
    label: "titleFontStyle",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontStyle"
  },
  {
    targetProperty: "titleFontWeight",
    label: "titleFontWeight",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleFontWeight"
  },
  {
    targetProperty: "titleTextDecor",
    label: "titleTextDecor",
    inputType: "textbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "titleTextDecor"
  },
  {
    targetProperty: "titleFontSize",
    label: "titleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 10,
      max: 30,
      defaultValue: 15
    },
    desc: "titleFontSize"
  },
  {
    targetProperty: "changedTitleFontSize",
    label: "changedTitleFontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 15
    },
    desc: "changedTitleFontSize"
  },
  {
    targetProperty: "titleColour",
    label: "titleColour",
    inputType: "textbox",
    inputOptions: {
      defaultValue: "black"
    },
    desc: "titleColour"
  },
  {
    targetProperty: "width",
    label: "Width",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 500
    },
    desc: "desc89"
  },
  {
    targetProperty: "height",
    label: "Height",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 400
    },
    desc: "desc90"
  },
  {
    targetProperty: "condFormat",
    label: "CondFormat",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: true
    },
    desc: "desc162"
  },
  {
    targetProperty: "showHideButton",
    label: "Show Hide Button",
    inputType: "checkbox",
    inputOptions: {
      defaultValue: false
    },
    desc: "desc230"
  },
  {
    targetProperty: "roundCorners",
    label: "RoundCorners",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc156"
  },
  {
    targetProperty: "showValuesOnTree",
    label: "showValuesOnTree",
    inputType: "checkbox",
    inputOptions: { defaultValue: true },
    desc: "desc156"
  },
  {
    targetProperty: "fontSize",
    label: "FontSize",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      defaultValue: 12,
      min: 6,
      max: 72
    },
    desc: "desc155"
  },
  {
    targetProperty: "colours",
    label: "Colours",
    inputType: "palette",
    inputOptions: { defaultValue: "Flat-UI" },
    desc: "desc93"
  },
  {
    targetProperty: "toggleCriteria",
    label: "ToggleCriteria",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc59"
  },
  {
    targetProperty: "title",
    label: "Title",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc94"
  },
  {
    targetProperty: "summary",
    label: "Summary",
    inputType: "textbox",
    inputOptions: { defaultValue: "" },
    desc: "desc61"
  },
  {
    targetProperty: "backgroundColor",
    label: "BackgroundColor",
    //inputType: 'colour',
    inputType: "textbox",
    inputOptions: { defaultValue: "rgb(255,255,255)" },
    desc: "desc62"
  },
  {
    targetProperty: "refresh",
    label: "RefreshPeriod",
    inputType: "textbox",
    inputOptions: {
      subtype: "number",
      min: 0,
      defaultValue: 0
    },
    desc: "desc89"
  }
];

const actions = [
  {
    trigger: "tileClick",
    type: "click",
    name: "Click - Tile",
    output: ["group", "vary"],
    description: "TitleClickDesc"
  },
  {
    trigger: "tileHover",
    type: "hover",
    name: "Hover - Tile",
    output: ["group", "vary"],
    description: "TitleHoverDesc"
  }
];

const reactions = [
  {
    id: "filter",
    name: "Filtre",
    description: "desc87",
    type: "general"
  }
];

// Title reaction when intercation active
const titleReactions = [
  {
    id: "none",
    name: i18n.t("Dashboard.Configuration.Fields.None"),
    description: "desc232",
    type: "private",
    method: "none"
  },
  {
    id: "updateTitle",
    name: i18n.t("Interaction.UpdateTitle"),
    description: "desc232",
    type: "private",
    method: "updateTitle"
  },
  {
    id: "resetTitle",
    name: i18n.t("Interaction.ResetTitle"),
    description: "desc233",
    type: "private",
    method: "resetTitle"
  }
];

const pluginConditionalFormatOptions = {
  backgroundColor: {
    title: i18n.t("Dashboard.ConditionalFormatting.BackgroundColor"),
    type: "COLOR",
    defaultValue: "#fffffe"
  },
};

const conditionalFormatColumnMap = new Set(["group", "measure", "vary"]);
const conditionalFormatTargetMap = new Set(["group"])
/**
 * renders TreeMap plugin in Vispeahen V3
 */
export default class TreeMap extends Component {
  constructor(props) {
    super(props);

    this.state = ({
      defaultConditionalFormatColumn: {},
      isLockedTargetValue: true
    })

    this.rerenderProcessStarted = false;
    this.columnMap = {};
    this.callBackObject = {};
    this.drillDowns = {};
  }

  calculatePluginHeight = (plugin, settings) => {
    let containerHeight = settings.grid.rowHeight * plugin.h;
    let pluginTitleContainer = $("#title-" + plugin.id);
    let pluginContainerBorder = -2;
    let maxHeight =
      containerHeight -
      (pluginTitleContainer.outerHeight() +
        parseInt(pluginTitleContainer.css("margin-bottom")) +
        parseInt(pluginTitleContainer.css("padding-bottom")) +
        pluginContainerBorder);
    return maxHeight;
  };

  /**
   * Plugin compenent receive its initial id, config etc..
   */
  componentWillMount() {
    let tempPlugin = { ...this.props.plugin };

    this.drillDowns = this.props.plugin.drillDowns;

    onComponentWillMount(
      this.props,
      tempPlugin,
      reactions,
      actions,
      configurationParameters,
      null,
      null,
      this.prepareColumnMapping,
      null,
      null,
      null,
      titleReactions
    );
  }

  changeStatusRerenderProcessStarted = status => {
    this.rerenderProcessStarted = status;
  };

  setCallBackObject = (callBackObject) => {
    this.callBackObject = callBackObject;
  };

  getCallBackObject = () => {
    let tmpCallBackObject = { ...this.callBackObject };
    this.setCallBackObject({})

    return tmpCallBackObject;
  }

  /**
   * For each property change like update, delete etc... Code block will update the current properties of compenent
   */
  componentWillReceiveProps(nextProps) {
    onComponentWillReceiveProps(
      nextProps,
      this.props,
      this.changeStatusRerenderProcessStarted,
      this.rerenderProcessStarted,
      this.setCallBackObject,
      this.callBackObject,
      this.getCallBackObject
    );
  }

  getNavigationComponent = props => {
    return (
      <NavigationContent
        navigations={this.props.navigations}
        setNavigations={this.props.updatePlugin}
        plugin={this.props.plugin}
        dashboardInformation={this.props.dashboardInformation}
      />
    );
  };

  getConfigComponent = props => {
    if (props.config) {
      return (
        <TreeMapConfiguration
          config={{ ...props.config }}
          updateCommonTitleConfig={props.updateCommonTitleConfig}
          plugin={props.plugin}
          commonTitleConfig={props.commonTitleConfig}
          setDefaultForPluginTitle={props.setDefaultForPluginTitle}
          isReturnToDefaultforTitleVisible={props.isReturnToDefaultforTitleVisible}
          pluginId={props.plugin.id}
          updateConfig={props.updateConfig}
          setPluginRerender={props.setPluginRerender}
          setCurrentAppliedConfig={this.props.setCurrentAppliedConfig}
          currentAppliedConfig={this.props.currentAppliedConfig}
          reReturnThemeSettings={this.props.reReturnThemeSettings}
          refreshPlugin={this.props.refreshPlugin}
        />
      );
    }

    return null;
  };

  /*
  * Converts columnMap with data object for conditional format component
  */
  convertColumnMapForConditionalFormat = (cmMap) => {
    let newColumnMap = []
    let reduxState = store.getState()
    let group = cmMap.group.data.length > 0 ? cmMap.group.data[0] : null

    if (cmMap.group.data.length > 0) {
      newColumnMap.push(cmMap.group.data[0])
    }

    if (cmMap.measure.data.length > 0) {
      newColumnMap.push(cmMap.measure.data[0])
    }

    if (cmMap.vary.data.length > 0) {
      newColumnMap.push(cmMap.vary.data[0])
    }

    if (reduxState.DrillDownReducer.drillDowns.has(this.props.plugin.id) && group) {
      let drillDownsReducer = reduxState.DrillDownReducer.drillDowns.get(this.props.plugin.id).drillDownColumnsForParentColumns;
      let drillCols = drillDownsReducer.has(group.uniqeColumnId)
        ? drillDownsReducer.get(group.uniqeColumnId)
        : []

      if (drillCols) {
        newColumnMap = newColumnMap.concat(drillCols)
      }
    }

    return newColumnMap
  }

  // gets conditional formatting pop up for the plugin
  getConditionalFormattingComponent = props => {
    let columnMap = props.plugin.columnMap

    return (
      <ConditionalFormatting
        pluginConditionalFormatOptions={pluginConditionalFormatOptions}
        conditionalFormatColumnMap={conditionalFormatColumnMap}
        pluginId={props.plugin.id}
        conditionalFormatTargetMap={conditionalFormatTargetMap}
        conditionalFormats={props.plugin.conditionalFormats}
        columnMap={columnMap}
        updateConditionalFormat={props.updatePlugin}
        isNecessaryAllColumns={this.isNecessaryAllColumns}
        defaultConditionalFormatColumn={this.state.defaultConditionalFormatColumn}
        isLockedTargetValue={this.state.isLockedTargetValue}
        columnMapWithDrills={this.convertColumnMapForConditionalFormat(columnMap)}
      />
    );
  };

  getDataComponent = props => {
    let columnMap = getColumnMapping(
      this.props,
      props,
      this.prepareColumnMapping
    );

    if (!columnMap["hidden"]) {
      columnMap["hidden"] = {
        data: [],
        desc: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Desc`,
        minimumColumnSize: 0,
        multiple: true,
        type: "hidden",
        name: `Plugins.${props.plugin.key}.ColumnMap.Hidden.Name`,
      }
    }

    return (
      <TreeMapData
        updateColumnMap={props.updatePlugin}
        conditionalFormats={props.plugin.conditionalFormats}
        model={props.model}
        sortedColumnList={props.plugin.sortedColumnList}
        columnMap={columnMap}
        pluginId={props.plugin.id}
        defaultFilters={props.plugin.defaultFilters}
        updateDefaultFilterForPlugin={props.updateDefaultFilterForPlugin}
        join={props.join}
        clickedRefresh={props.clickedRefresh}
        setClickedRefresh={props.setClickedRefresh}
        hasNotJoinedData={props.hasNotJoinedData}
        changeHasNotJoinedData={props.changeHasNotJoinedData}
        changeJoinErrorVisibility={props.changeJoinErrorVisibility}
        didNotJoinedTables={checkTableJoins(this.props.join, this.props.plugin.columnMap, this.props.refreshedPluginId, this.props.plugin.id, true)}
        setInteractions={this.props.setInteractions}
        interactions={this.props.interactions}
        doesPluginHasNotJoinedTable={props.doesPluginHasNotJoinedTable}
        changeDoesPluginHasNotJoinedTable={props.changeDoesPluginHasNotJoinedTable}
        updateModelTablesForJoin={props.updateModelTablesForJoin}
        refreshedPluginId={props.refreshedPluginId}
        changeRefreshedPluginId={props.changeRefreshedPluginId}
        navigations={props.navigations}
        plugin={props.plugin}
        limit={this.props.limit}
        setDataLimitForPlugin={this.props.setDataLimitForPlugin}
      />
    );
  };

  /**
   * To set column map this plugin
   */
  prepareColumnMapping = tempPlugin => {
    let columnMapping = {
      group: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Group.Name"),
        type: "dim",
        required: true,
        conditionalFormat: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Group.Desc"),
        data: []
      },
      measure: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Name"),
        type: "fact",
        required: true,
        minimumColumnSize: 1,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Measure.Desc"),
        data: []
      },
      vary: {
        name: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Vary.Name"),
        type: "dim",
        minimumColumnSize: 0,
        desc: i18n.t("Plugins." + tempPlugin.key + ".ColumnMap.Vary.Desc"),
        data: []
      }
    };

    tempPlugin.columnMap = columnMapping;
    return { plugin: tempPlugin, columnMap: columnMapping };
  };

  // get cond formats that user gives and converts and adds them to condFormatsList
  convertFormatConditionalFormatting = (condFormats, columns) => {
    let condFormatList = [];

    condFormats.map(condItem => {
      condItem.targetColumns.map(targetColumn => {
        let conditionalFormat = {};
        let columnsMap = new Map();
        let columnsFieldNameMap = new Map(); //to set column-locationFieldName pairs so we can get locationFieldName correctly

        columns.map(column => {
          columnsMap.set(column.uniqeColumnId, column);
          columnsFieldNameMap.set(column.uniqeColumnId, column.locationFieldName);
        })

        let condItemLeftRuleColumnId = condItem.rule.leftRule.rule.replace("{", "").replace("}", "").replaceAll("_", "-");

        conditionalFormat.RightRule = condItem.rule.rightRule.rule;
        conditionalFormat.LeftRule = condItem.rule.leftRule.rule;
        conditionalFormat.LeftRuleColumnName = condItem.rule.leftRule.ruleColumnName === "{undefined}" ? convertHTMLRuletoRule(condItem.rule.leftRule).ruleColumnName : condItem.rule.leftRule.ruleColumnName;
        conditionalFormat.RightRuleColumnName = condItem.rule.rightRule.ruleColumnName
        conditionalFormat.leftRuleColumnId = condItemLeftRuleColumnId;
        conditionalFormat.Columns = columnsMap;
        conditionalFormat.TargetID = targetColumn.TargetID;
        conditionalFormat.TargetName = targetColumn.TargetName;
        conditionalFormat.locationFieldName = columnsFieldNameMap.get(condItemLeftRuleColumnId);
        conditionalFormat.Operator = condItem.rule.operator;
        conditionalFormat.id = condItem.id;

        conditionalFormat.Style = {
          background: { colour: condItem.options.backgroundColor }
        };

        condFormatList.push(conditionalFormat);
      });
    });

    return condFormatList;
  };

  pluginRender = (divId, data, columnMap, config, condFormats, filters) => {
    var THIS = this

    this.contrastColor = getContrastColor(config.backgroundColor)
    let pluginId = THIS.props.plugin.id;
    let newColumnMap = []
    let isColumnMapVaryDataList = typeof columnMap.vary === "object" && columnMap.vary.data && columnMap.vary?.data?.length > 0 ? true : false;
    let isColumnMapVaryObject = typeof columnMap.vary === "object" && !columnMap.vary.data && Object.keys(columnMap.vary).length > 0 ? true : false;
    let isColumnMapVaryList = Array.isArray(columnMap?.vary) && columnMap?.vary.length > 0;

    let isVaryAvailable = 
    isColumnMapVaryObject || isColumnMapVaryDataList || isColumnMapVaryList
        ? true
        : false

    newColumnMap.push(columnMap.group)
    newColumnMap.push(columnMap.measure)

    if (isVaryAvailable) {
      newColumnMap.push(columnMap.vary)
    }

    if (condFormats) {
      condFormats = this.convertFormatConditionalFormatting(condFormats, newColumnMap)
    }

    let inlineHeight = calculatePluginInlineHeight(divId)
    let copiedData = [...data]
    let container = $("#" + divId)[0];
    let chartContainer = $("#" + divId)

    if (condFormats && config.condFormat && condFormats.length > 0) {
      chartContainer.css("height", inlineHeight - 20)
    } else {
      chartContainer.css("height", inlineHeight)
    }

    const convertDataToColumnMap = (data) => {
      let newData = {}

      newData[columnMap.group.displayName] = data.group
      newData[columnMap.measure.displayName] = data.measure

      if (columnMap.vary && typeof columnMap.vary === "object") {
        newData[columnMap.vary.displayName] = data.vary
      }

      return newData
    }

    if (chartContainer.css("width") === "" || chartContainer.css("width") === "0px") {
      let containerWidth = $("#plugin-" + divId).css("width")

      chartContainer.css("width", containerWidth)
    }
    
    var chart = am4core.create(divId, am4charts.TreeMap);

    am4core.useTheme(am4themes_animated);

    let showCondForm = $('<div id="showCondForm" style="display:flex; position:relative; bottom:5px; flex-wrap:wrap;"></div>')

    if (condFormats && condFormats.length > 0 && config.condFormat) {
      addCondFormLegend();
    }

    function prepareConditionalLegends(conditionalLegendDiv) {
      //sets conditional format div's for find legend div
      conditionalLegendDiv.setAttribute("class", "apexcharts-legend-series");
      conditionalLegendDiv.setAttribute("style", "margin: 0px 5px;");
    }

    function addCondFormLegend() {
      //adds conditional format legends to plugins bottom
      let usedCondFormatIds = new Set();

      condFormats.forEach(function (d, i) {
        if (usedCondFormatIds.has(d.id)) {
          return;
        } else {
          usedCondFormatIds.add(d.id);
        }

        let condLegend = document.createElement("div");
        prepareConditionalLegends(condLegend)

        let condLegendMarker = document.createElement("span");
        // prepareConditionalLegendMarkers(condLegendMarker, d) //for conditional format color dot.

        let condLegendMarkerBackground = document.createElement("span");

        if (d.Style.background) {
          condLegendMarkerBackground.setAttribute("class", "dot");
          condLegendMarkerBackground.setAttribute(
            "style",
            "background: " +
            d.Style.background.colour +
            "; color:" +
            d.Style.background.colour +
            "; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 10px; border-color: rgb(255, 255, 255); border-radius: 10px; display: inline-block; margin-left: 2px;  border: 1px solid white;"
          );
        }

        let condLegendText = document.createElement("span");
        condLegendText.setAttribute("class", "text");
        condLegendText.setAttribute(
          "style",
          "color:" + 
          getContrastColor(config.backgroundColor) +
          "; font-size: 12px; font-family: Helvetica, Arial, sans-serif;"
        );

        let ruleDescription = THIS.props.plugin.conditionalFormats[i]?.rule?.conditionalFormatRule;
        // if rule description is defined in text area, legend will use user input.

        let textNode = document.createTextNode(
          ruleDescription ? " " + ruleDescription  : " " + d.LeftRuleColumnName + " " + d.Operator + " " + d.RightRuleColumnName + ""
        );
        condLegendText.appendChild(textNode);

        condLegend.appendChild(condLegendMarker);
        condLegend.appendChild(condLegendMarkerBackground);
        condLegend.appendChild(condLegendText);

        showCondForm.append(condLegend);
      });
    }

    let exportDownloadSupport = chart.exporting.downloadSupport();

    if (exportDownloadSupport) {
      let title = `${this.props.title}`;

      let exportImageOptions = chart.exporting.getFormatOptions("png");
      let exportPDFOptions = chart.exporting.getFormatOptions("pdf");

      // Image exporting properties
      exportImageOptions.quality = 1;
      exportImageOptions.minWidth = 4320;
      exportImageOptions.minHeight = 4320;

      // PDF exporting properties
      exportPDFOptions.quality = 1;
      exportPDFOptions.addURL = false;
      exportPDFOptions.minWidth = 4320;
      exportPDFOptions.minHeight = 4320;
      exportPDFOptions.align = "center";

      // General exporting properties
      chart.exporting.title = title;
      chart.exporting.filePrefix = title;
      chart.exporting.useRetina = true;
      chart.exporting.showTimeout = () => {};

      chart.exporting.setFormatOptions("png", exportImageOptions);
      chart.exporting.setFormatOptions("pdf", exportPDFOptions);

      container.PNG = () => chart.exporting.export("png");
      container.PDF = () => chart.exporting.export("pdf");
      container.XLSX = () => this.props.excelExport();;

      /**
       * Chart PDF document handler
       */
      chart.exporting.adapter.add("pdfmakeDocument", function (pdf, target) {
        let { content } = pdf.doc;
        let [title, image] = content;

        if (pdf.options.pageOrientation === "landscape") {
          image.fit[1] = image.fit[0] - 60;
          image.fit[0] = 782;
        }

        pdf.doc.header = {
          columns: [
            {
              text: `${i18n.t("Dashboard.Configuration.Fields.ExportDate")}: ${rmvpp.generateDate()}`,
              bold: false,
              margin: [0, 5, 30, 0],
              alignment: 'right'
            }
          ]
        }

        pdf.doc.content[0] = title;
        pdf.doc.content[1] = image;

        pdf.doc.footer = {
          image: vispeahenLogo,
          margin: [0, 0, 15, 5],
          alignment: 'right',
        };

        pdf.compress = false;

        return pdf;
      });

      /** excel export implemented here */
      chart.exporting.adapter.add("xlsxWorkbook", workbook => {
        let sheetName = Object.keys(workbook.workbook.Sheets)[0];
        let worksheet = workbook.workbook.Sheets[sheetName];

        let datetime = getCurrentDateTime();

        if (columnMap.vary?.displayName !== undefined) {
          worksheet["!ref"] = `A1:C${data.length + 3}`;
        } else {
          worksheet["!ref"] = `A1:B${data.length + 3}`;
        }
        worksheet["A1"] = { v: datetime, t: "s" };
        worksheet["A2"] = { v: config.title, t: "s" }; 
        
        if (!worksheet['!merges']) worksheet['!merges'] = [];
        
        if(columnMap.vary?.displayName !== undefined){
          worksheet['!merges'].push({s: {r: 0, c: 0}, e: {r: 0, c: columnMap.vary.displayName ? 2 : 1}});
          worksheet['!merges'].push({s: {r: 1, c: 0}, e: {r: 1, c: columnMap.vary.displayName ? 2 : 1}});
        } else {
          worksheet['!merges'].push({s: {r: 0, c: 0}, e: {r: 0, c: " " ? 2 : 1}});
          worksheet['!merges'].push({s: {r: 1, c: 0}, e: {r: 1, c: " " ? 2 : 1}});
        }
        
        worksheet["A3"] = { v: columnMap.group.Name, t: "s" };
        worksheet["B3"] = { v: columnMap.measure.Name, t: "s" };

        if (columnMap.vary?.displayName !== undefined) {
          worksheet["C3"] = { v: columnMap.vary.displayName, t: "s" };
        }
      
        data.forEach((e,i) => {
          let cell = String.fromCharCode(65);

          worksheet[cell+""+(i+4)] = {v: e.group, t:"s"};

          cell = String.fromCharCode(66);
          worksheet[cell+""+(i+4)] = {v: e.measure, t:"s"};

          if (columnMap.vary?.displayName !== undefined) {
            cell = String.fromCharCode(67);
            worksheet[cell+""+(i+4)] = {v: e.vary, t:"s"};
          }
        })
        
        return workbook;
      });

      /**
       * Before the export operation handler
       */
      chart.exporting.events.on("exportstarted", (event) => {
        let headerSection = document.getElementsByClassName("header-section")[0];
        let toolsSection = document.getElementsByClassName("dashboard-setting")[0];

        headerSection.style.position = "relative";
        headerSection.style.zIndex = 1000;

        toolsSection.style.position = "relative";
        toolsSection.style.zIndex = 1000;

        container.style.visibility = "hidden";
        container.style.position = "fixed";

        changePluginLoaderVisibility(pluginId, true)
        
        loadingScreen(true);

        let height = chartContainer.height();
        let width = chartContainer.width();

        let legendHeight = 0;
        let isChartPosLeftOrRight = config.legendPos === "right" || config.legendPos === "left";

        let chartNode = chart.element.node;
        let chartRect = chartNode.getBoundingClientRect();
        let chartHeight = chartRect.height;
        let chartWidth = chartRect.width;

        let measuredHeight = chartHeight;
        let measuredWidth = chartWidth;

        if (config.legend) {
          if (chart.legend.disabled) return;

          switch (config.legendPos) {
            case "right":
              chart.legend.marginLeft = 30;
              chart.legend.marginRight = 0;
              
              break;
            case "left":
              chart.legend.marginRight = 30;
              chart.legend.marginLeft = 0;
              
              break;
            case "top":
              chart.legend.marginBottom = 0;
              chart.legend.marginTop = 30;
              
              break;
            case "bottom":
              chart.legend.marginBottom = 30;
              chart.legend.marginTop = 0;
              
              break;
            default:
              break;
          }

          legendHeight = chart.legend.contentHeight;
          
          chart.legend.height = legendHeight;

          if (!isChartPosLeftOrRight && height / 2.7 < legendHeight) {
            height = legendHeight * 2.7
          } else if (isChartPosLeftOrRight) {
            if (height < legendHeight) height = legendHeight;
            
            measuredHeight = legendHeight;
          };
        }

        width = Math.max(width, measuredWidth) + 60;
        height = Math.max(height, measuredHeight) + 60;

        let radiusRef = Math.min(width, height);
        let newRadius = radiusRef / 4;

        chart.radius = newRadius;

        if (config.innerRadius && config.innerRadius > 0) {
          chart.innerRadius = newRadius * config.innerRadius / 100;
        }
        
        height += width > height && !isChartPosLeftOrRight ? 60 : 0;
        width += height > width && isChartPosLeftOrRight ? 60 : 0;

        container.style.minHeight = height + "px";
        container.style.minWidth = width + "px";

        if (event.format === "pdf") chart.exporting.getFormatOptions("pdf").pageOrientation = height > width ? "portrait" : "landscape";
      });

      /**
       * After the export operation handler
       */
      chart.exporting.events.on("exportfinished", event => {
        setTimeout(() => {
          let headerSection = document.getElementsByClassName("header-section")[0];
          let toolsSection = document.getElementsByClassName("dashboard-setting")[0];

          headerSection.style.position = "";
          headerSection.style.zIndex = "";

          toolsSection.style.position = "";
          toolsSection.style.zIndex = "";

          container.style.minHeight = "";
          container.style.minWidth = "";
          container.style.position = "";
          container.style.zIndex = "";

          store.dispatch(changePluginLoaderVisibility(pluginId, false));
          loadingScreen(false);

          container.style.visibility = "";
        });
      });
    }

    // Value is converted to affix version
    function affixFormat(value, columnMap) {
      if (columnMap.affixAlign === "right") {
        return value + "'" + columnMap.affixValue + "'";
      } else if (columnMap.affixAlign === "left") {
        return "'" + columnMap.affixValue + "'" + value;
      }

      return value;
    }

    chartContainer.append(showCondForm);

    $("[shape-rendering='auto']").parent().parent().css("display", "none")

    if (!isVaryAvailable) {
      for (let i = 0; i < copiedData.length; i++) {
        let measureDataFormat = columnMap["measure"].DataFormat
        let formattedMeasureValue = getFormattedValue(columnMap["measure"], data[i].measure)
        let formattedCategoryValue = getFormattedValue(columnMap["group"], data[i].group)
        let isDataFormatDotCommaNumberF = measureDataFormat[0] === "," && measureDataFormat[1] === "." && measureDataFormat[measureDataFormat.length - 1] === "f" ? true : false
        let isDataFormatDotCommaF = measureDataFormat[0] === "." && measureDataFormat[measureDataFormat.length - 1] === "f"

        copiedData[i]["formattedCategory"] = formattedCategoryValue

        if (columnMap["measure"].DataFormat === ".3s") { //for .3s
          chart.numberFormatter.numberFormat = affixFormat("#.0a", columnMap["measure"]);
        } else if (measureDataFormat !== ".f" && isDataFormatDotCommaNumberF) { //for ,.5s
          let copiedMeasureValue = formattedMeasureValue;
          let zeroCount = parseInt(measureDataFormat.substring(measureDataFormat.indexOf(".") + 1, measureDataFormat.lastIndexOf("f")))

          zeroCount = isNaN(zeroCount) ? 0 : zeroCount

          let zeroArr = Array(zeroCount + 1).join("0")

          copiedMeasureValue = copiedMeasureValue.split(".").length > 0 ? copiedMeasureValue.split(".").join("") : copiedMeasureValue
          copiedMeasureValue = copiedMeasureValue.replace(",", ".")

          copiedData[i]["formattedMeasure"] = copiedMeasureValue
          chart.numberFormatter.numberFormat = affixFormat("#,###." + zeroArr, columnMap["measure"]);
        } else if (measureDataFormat === ".f") { //for .f
          chart.numberFormatter.numberFormat = affixFormat("####.########", columnMap["measure"]);
        } else if (isDataFormatDotCommaF) {
          let copiedMeasureValue = formattedMeasureValue;
          let zeroCount = parseInt(measureDataFormat.substring(measureDataFormat.indexOf(".") + 1, measureDataFormat.lastIndexOf("f")))
          let zeroArr = Array(zeroCount + 1).join("0")

          copiedMeasureValue = copiedMeasureValue.split(".").length > 1 ? copiedMeasureValue.split(".").join("") : copiedMeasureValue
          copiedMeasureValue = copiedMeasureValue.replace(",", ".")

          copiedData[i]["formattedMeasure"] = copiedMeasureValue
          chart.numberFormatter.numberFormat = affixFormat("####." + zeroArr, columnMap["measure"]);
        }

        let paletteColours = 
          (Array.isArray(THIS.props.plugin.config.colours) && THIS.props.plugin.config.colours) || 
          InsightsConfig.Palettes[THIS.props.plugin.config.colours] ||
          InsightsConfig.Palettes[THIS.props.plugin.config.paletteColours] ||
          (Array.isArray(THIS.props.plugin.config.paletteColours) && THIS.props.plugin.config.paletteColours)

        if (Array.isArray(paletteColours) && paletteColours[i]) {
          copiedData[i]["color"] = paletteColours[i]
          copiedData[i]["contrastColor"] = getContrastColor(paletteColours[i])
        } else {
          let randomColorList = [
            "#67b7dc",
            "#6771dc",
            "#a367dc",
            "#dc67ce",
            "#dc6788",
            "#dc8c67",
            "#dcaf67",
            "#dcd267",
            "#c3dc67",
            "#a0dc67",
            "#7cdc67",
            "#67dc75",
            "#67dc98",
            "#67dcbb",
            "#67dadc"
          ]

          let newRandomColor = randomColorList[i] ? randomColorList[i] : randomColorList[i % randomColorList.length]

          copiedData[i]["color"] = newRandomColor
          copiedData[i]["contrastColor"] = getContrastColor(newRandomColor)
        }

        if (condFormats && condFormats.length > 0) {
          for (let j = 0; j < condFormats.length; j++) {
            let comparedData = compare(convertDataToColumnMap(copiedData[i]), condFormats[j])

            if (comparedData.status === true) {
              copiedData[i]["color"] = condFormats[j].Style.background.colour
              copiedData[i]["contrastColor"] = getContrastColor(condFormats[j].Style.background.colour)
            }
          }
        }
      }

      chart.hiddenState.properties.opacity = 1; // this makes initial fade in effect
      chart.maxLevels = 1;
      chart.dataFields.value = "measure";
      chart.dataFields.name = "formattedCategory";
      chart.dataFields.color = 'color'
      chart.data = copiedData;

      let level = chart.seriesTemplates.create("0");
      let level_bullet = level.bullets.push(new am4charts.LabelBullet());

      level_bullet.locationY = 0.5;
      level_bullet.locationX = 0.5;
      level_bullet.label.text = config.showValuesOnTree == undefined || config.showValuesOnTree ? "{formattedCategory}\n{value}" : "{formattedCategory}";
      level_bullet.label.fill = am4core.color("#fff");
      level_bullet.label.fontSize = config.fontSize ? config.fontSize : 11;


      let levelSeries = chart.seriesTemplates.create("0");

      chart.zoomOutButton.disabled = true;

      chartContainer.css("background", config.backgroundColor)

      levelSeries.tooltip.dy = -15;
      levelSeries.tooltip.pointerOrientation = "vertical";
      levelSeries.columns.template.propertyFields.fill = "color"

      if (config.roundCorners) {
        levelSeries.columns.template.column.cornerRadius(10, 10, 10, 10);
        levelSeries.columns.template.stroke = am4core.color(config.backgroundColor);
        levelSeries.columns.template.strokeWidth = 5;
      }

      levelSeries.columns.template.events.on('hit', function (ev) {
        let mousePosition = { x: window.event.pageX, y: window.event.pageY }

        createTrigger(
          actions,
          columnMap,
          container,
          "tileClick",
          ev.target.dataItem._dataContext._dataContext,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition,
          null,
          THIS.drillDowns,
          [columnMap.group],
          THIS.props.plugin,
          THIS.props.model,
        );
      });

      levelSeries.columns.template.events.on('over', function (ev) {
        let mousePosition = { x: window.event.pageX, y: window.event.pageY }
        let hoverStatus = false

        for (let i = 0; i < THIS.props.interactions.length; i++) {
          for (let j = 0; j < THIS.props.interactions[i].actions.length; j++) {
            if (THIS.props.interactions[i].actions[j].trigger === "tileHover") {
              hoverStatus = true
            }
          }
        }

        if (hoverStatus) {
          createTrigger(
            actions,
            columnMap,
            container,
            "tileHover",
            ev.target.dataItem._dataContext._dataContext,
            THIS.props.plugin.id,
            THIS.props.interactions,
            [],
            mousePosition,
            null,
            [],
            [columnMap.group, columnMap.vary],
            THIS.props.plugin,
            THIS.props.model,
          );
        }
      });

      if (columnMap.group !== undefined && columnMap.group !== null && columnMap.group.uniqeColumnId !== undefined) {
        let columnMapCategory = columnMap.group

        this.setState({
          defaultConditionalFormatColumn: columnMapCategory
        })
      }
    } else {
      const getPaletteColour = (i) => {
        let paletteColours = 
          (Array.isArray(THIS.props.plugin.config.colours) && THIS.props.plugin.config.colours) || 
          InsightsConfig.Palettes[THIS.props.plugin.config.colours] ||
          InsightsConfig.Palettes[THIS.props.plugin.config.paletteColours] ||
          (Array.isArray(THIS.props.plugin.config.paletteColours) && THIS.props.plugin.config.paletteColours)

        if (Array.isArray(paletteColours) && paletteColours[i]) {
          return paletteColours[i]
        } else {
          let randomColorList = [
            "#67b7dc",
            "#6771dc",
            "#a367dc",
            "#dc67ce",
            "#dc6788",
            "#dc8c67",
            "#dcaf67",
            "#dcd267",
            "#c3dc67",
            "#a0dc67",
            "#7cdc67",
            "#67dc75",
            "#67dc98",
            "#67dcbb",
            "#67dadc"
          ]

          let newRandomColor = randomColorList[i] ? randomColorList[i] : randomColorList[i % randomColorList]

          return newRandomColor
        }
      }

      let newData = []
      let dataHash = new Map()
      let newCopiedDataForConditional = [...copiedData]

      for (let i = 0; i < newCopiedDataForConditional.length; i++) {
        let measureDataFormat = columnMap["measure"].DataFormat
        let formattedMeasureValue = getFormattedValue(columnMap["measure"], data[i].measure)
        let formattedCategoryValue = getFormattedValue(columnMap["group"], data[i].group)
        let isDataFormatDotCommaNumberF = measureDataFormat[0] === "," && measureDataFormat[1] === "." && measureDataFormat[measureDataFormat.length - 1] === "f" ? true : false
        let isDataFormatDotCommaF = measureDataFormat[0] === "." && measureDataFormat[measureDataFormat.length - 1] === "f"

        copiedData[i]["formattedCategory"] = formattedCategoryValue

        if (columnMap["measure"].DataFormat === ".3s") { //for .3s
          chart.numberFormatter.numberFormat = affixFormat("#.0a", columnMap["measure"]);
        } else if (measureDataFormat !== ".f" && isDataFormatDotCommaNumberF) { //for ,.5s
          let copiedMeasureValue = formattedMeasureValue;
          let zeroCount = parseInt(measureDataFormat.substring(measureDataFormat.indexOf(".") + 1, measureDataFormat.lastIndexOf("f")))

          zeroCount = isNaN(zeroCount) ? 0 : zeroCount

          let zeroArr = Array(zeroCount + 1).join("0")

          copiedMeasureValue = copiedMeasureValue.split(".").length > 0 ? copiedMeasureValue.split(".").join("") : copiedMeasureValue
          copiedMeasureValue = copiedMeasureValue.replace(",", ".")

          copiedData[i]["formattedMeasure"] = copiedMeasureValue
          chart.numberFormatter.numberFormat = affixFormat("#,###." + zeroArr, columnMap["measure"]);
        } else if (measureDataFormat === ".f") { //for .f
          chart.numberFormatter.numberFormat = affixFormat("####.########", columnMap["measure"]);
        } else if (isDataFormatDotCommaF) {
          let copiedMeasureValue = formattedMeasureValue;
          let zeroCount = parseInt(measureDataFormat.substring(measureDataFormat.indexOf(".") + 1, measureDataFormat.lastIndexOf("f")))
          let zeroArr = Array(zeroCount + 1).join("0")

          copiedMeasureValue = copiedMeasureValue.split(".").length > 1 ? copiedMeasureValue.split(".").join("") : copiedMeasureValue
          copiedMeasureValue = copiedMeasureValue.replace(",", ".")

          copiedData[i]["formattedMeasure"] = copiedMeasureValue
          chart.numberFormatter.numberFormat = affixFormat("####." + zeroArr, columnMap["measure"]);
        }

        let varyValue = newCopiedDataForConditional[i]["vary"];
        let filteredData = newData.filter(nData => nData.originalName === varyValue)
        let perData = newCopiedDataForConditional[i]

        dataHash.set(`${newCopiedDataForConditional[i]["formattedCategory"]}___${newCopiedDataForConditional[i]["measure"]}`, newCopiedDataForConditional[i])

        if (filteredData.length > 0) {
          filteredData[0].children.push({
            name: getFormattedValue(columnMap["group"], perData.group),
            originalName: perData.group,
            measure: perData["measure"],
          })
        } else {
          newData.push({
            name: getFormattedValue(columnMap["vary"], perData.vary),
            originalName: perData.vary,
            children: [{
              name: getFormattedValue(columnMap["group"], perData.group),
              originalName: perData.group,
              measure: perData["measure"],
            }],
            color: getPaletteColour(newData.length)
          })

          dataHash.set(perData.vary, perData.vary)
        }
      }

      /*
      * This must be n^3 because every parent has children and every children has color. 
      * We need to loop through all children and set conditional color.
      */
     if (condFormats) {
      for (let i = 0; i < newData.length; i++) {
        let condFormatStatus = false
        
        newData[i][columnMap.vary.displayName] = newData[i].originalName

        for (let k = 0; k < condFormats.length; k++) {
          if (condFormats[k].locationFieldName && condFormats[k].locationFieldName === "vary") {
            let comparedData = compare(newData[i], condFormats[k])

            if (comparedData.status) {
              newData[i]["color"] = condFormats[k].Style.background.colour
  
              condFormatStatus = true
            } else if (!condFormatStatus) {
              newData[i]["color"] = getPaletteColour(i)
            }
          }
        }

        for (let j = 0; j < newData[i].children.length; j++) {
          let childrenData = newData[i].children[j]

          childrenData[columnMap.group.displayName] = childrenData.originalName
          childrenData[columnMap.measure.displayName] = childrenData.measure
          childrenData[columnMap.vary.displayName] = newData[i].originalName

          newData[i].children[j].color = getPaletteColour(i)

            for (let k = 0; k < condFormats.length; k++) {
              let comparedData = compare(childrenData, condFormats[k])
  
              if (comparedData.status) {
                newData[i].children[j].color = condFormats[k].Style.background.colour
              }
            }
        }
      }
     }

      /* Define data fields */
      chart.dataFields.value = "measure";
      chart.dataFields.name = "name";
      chart.dataFields.children = "children";
      chart.dataFields.color = 'color'
      chart.data = newData
      chart.maxLevels = 2;
      chart.zoomable = false

      /* Create top-level series */
      var levelSeries = chart.seriesTemplates.create("0");
      levelSeries.tooltip.dy = -15;
      levelSeries.tooltip.pointerOrientation = "vertical";
      levelSeries.className = "levelSeries"
      levelSeries.columns.template.fillOpacity = 0;
      levelSeries.columns.template.strokeOpacity = 0;

      /* Create second-level series */
      var level2Series = chart.seriesTemplates.create("1");
      level2Series.tooltip.dy = -15;
      level2Series.tooltip.pointerOrientation = "vertical";
      level2Series.columns.template.propertyFields.fill = "color"
      level2Series.className = "level2series"
      level2Series.columns.template.adapter.add("fill", function (fill, target) {
        return fill;
      });

      if (config.roundCorners) {
        level2Series.columns.template.column.cornerRadius(6, 6, 6, 6);
        level2Series.columns.template.stroke = am4core.color(config.backgroundColor);
        level2Series.columns.template.strokeWidth = 3;
        level2Series.columns.template.strokeOpacity = 2;
      }

      var level_bullet = level2Series.bullets.push(new am4charts.LabelBullet());
      level_bullet.locationY = 0.5;
      level_bullet.locationX = 0.5;
      level_bullet.label.text = config.showValuesOnTree == undefined || config.showValuesOnTree ? "{name}\n{value}" : "{name}";;
      level_bullet.label.truncate = true
      level_bullet.label.fill = am4core.color("#fff");
      level_bullet.label.fontSize = config.fontSize ? config.fontSize : 11;

       level_bullet.label.adapter.add("fill", function(fill, target) {
        //Changes the label contrast 
        let bgColor = am4core.color(target.dataItem.dataContext.color);
        let contrastColor = getContrastColor(bgColor.hex);
        return am4core.color(contrastColor);
    });

      chartContainer.css("background", config.backgroundColor)

      level2Series.columns.template.events.on('hit', function (event) {
        let mousePosition = { x: window.event.pageX, y: window.event.pageY }
        let itemContext = event.target.dataItem._dataContext._dataContext
        let clickedData = dataHash.has(`${itemContext["name"]}___${itemContext["measure"]}`) ? dataHash.get(`${itemContext["name"]}___${itemContext["measure"]}`) : []

        createTrigger(
          actions,
          columnMap,
          container,
          "tileClick",
          clickedData,
          THIS.props.plugin.id,
          THIS.props.interactions,
          THIS.props.navigations,
          mousePosition,
          null,
          THIS.drillDowns,
          [columnMap.group, columnMap.vary],
          THIS.props.plugin,
          THIS.props.model,
        );
      });

      level2Series.columns.template.events.on('over', function (event) {
        let mousePosition = { x: window.event.pageX, y: window.event.pageY }
        let itemContext = event.target.dataItem._dataContext._dataContext
        let clickedData = dataHash.has(`${itemContext["name"]}___${itemContext["measure"]}`) ? dataHash.get(`${itemContext["name"]}___${itemContext["measure"]}`) : []
        let hoverStatus = false

        for (let i = 0; i < THIS.props.interactions.length; i++) {
          for (let j = 0; j < THIS.props.interactions[i].actions.length; j++) {
            if (THIS.props.interactions[i].actions[j].trigger === "tileHover") {
              hoverStatus = true
            }
          }
        }

        if (hoverStatus) {
          createTrigger(
            actions,
            columnMap,
            container,
            "tileHover",
            clickedData,
            THIS.props.plugin.id,
            THIS.props.interactions,
            [],
            mousePosition,
            null,
            [],
            [columnMap.group, columnMap.vary],
            THIS.props.plugin,
            THIS.props.model,
          );
        }
      });

      if (columnMap.group !== undefined && columnMap.group !== null && columnMap.group.uniqeColumnId !== undefined) {
        let columnMapCategory = columnMap.group

        this.setState({
          defaultConditionalFormatColumn: columnMapCategory
        })
      }
    }

    if (config.legend || config.legend == undefined) {
      let isChartPosLeftOrRight = config.legendPos === "right" || config.legendPos === "left" ? true : false
      let pluginInlineHeight = condFormats && condFormats.length === 0 ? calculatePluginInlineHeight(this.props.plugin.id) - 20 : calculatePluginInlineHeight(this.props.plugin.id) - 50

      chart.legend = new am4charts.Legend();
      chart.legend.fontSize = 12;
      chart.legend.markers.template.height = 10;
      chart.legend.maxHeight = isChartPosLeftOrRight ? pluginInlineHeight : pluginInlineHeight / 2.7 > 80 ? 80 : pluginInlineHeight / 2.7 //maxHeight of chart's legend
      chart.legend.minHeight = 15
      chart.legend.scrollable = true;
      chart.legend.position = config.legendPos; //legend position
      chart.legend.markers.template.children.getIndex(0).cornerRadius(12, 12, 12, 12)
      chart.legend.scrollbar.properties.minWidth = 7
      chart.legend.fontSize = 10;
      chart.legend.labels.template.fill = am4core.color(this.contrastColor)
      chart.legend.valueLabels.template.fill = am4core.color(this.contrastColor)
    }

    this.props.setPluginRerender(false, this.props.plugin.id, false, this.props.plugin.isInteraction);
  };

  currentHeight;
  lastContent = undefined;

  updateLastContent = (status) => {
    this.lastContent = status
  }

  render() {
    let configComponent = null;
    if (this.props.configVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      configComponent = renderConfig(
        popupPosition,
        this.props,
        this.getConfigComponent
      );
    }

    let conditionalFormatComponent = null;

    if (this.props.conditionalFormattingVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      conditionalFormatComponent = renderConditionalFormatting(
        popupPosition,
        this.props,
        this.getConditionalFormattingComponent
      );
    }

    let dataComponent = null;
    if (this.props.dataVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        isValidWriteRoles() ? 700 : 350,
        600
      );
      dataComponent = renderData(
        popupPosition,
        this.props,
        this.getDataComponent
      );
    }

    let navigationComponent = null;
    if (this.props.navigationComponentVisibility === true) {
      let popupPosition = calculatePopupPosition(
        $("#grid-" + this.props.plugin.id),
        700,
        600
      );
      navigationComponent = renderNavigation(
        popupPosition,
        this.props,
        this.getNavigationComponent
      );
    }

    let isRerender = this.props.plugin.rerender;
    let pluginConfig = { ...this.props.plugin.config };
    
    if (this.props.plugin.config) {
      let pluginContainerPadding = parseInt(
        $("#grid-" + this.props.plugin.id).css("padding")
      );

      pluginConfig.height =
        this.calculatePluginHeight(this.props.plugin, this.props.settings) -
        pluginContainerPadding * 2;

      if (isNaN(pluginConfig.height)) {
        pluginConfig.height = this.currentHeight;
      }

      if (pluginConfig.height != this.currentHeight) {
        this.currentHeight = pluginConfig.height;
        isRerender = true;
      }
    } else {
      return (
        <div>
          <div id={this.props.plugin.id}></div>
        </div>
      );
    }

    return (
      <>
        <div style={{ height: "100%" }}>
          <div id={this.props.plugin.id} className="treeMap"></div>
          {renderContent(
            isRerender,
            this.pluginRender,
            this.props.plugin,
            data,
            columnMap,
            pluginConfig,
            this.props.plugin.conditionalFormats,
            this.props.setPluginRerender,
            this.lastContent,
            this.updateLastContent,
          )}
          {configComponent}
          {dataComponent}
          {navigationComponent}
          {conditionalFormatComponent}
        </div>
      </>
    );
  }
}
	